import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useT } from "../../i18n";
import { fetchShopOrderReports } from '../../redux/actions';
import { StoreState } from '../../redux/reducers';
import { Order } from "../../redux/reducers/order.reducer";
import { OrderReport } from "../../redux/reducers/orderReport.reducer";
import { AppDispatch } from '../../redux/store';
import style from '../../styles/components/modal-with-header.module.scss';
import { currentDate, datesArray, initDate } from "../../utils";
import ReportBarChart from "../charts/ReportBarChart";
import ReportLineChart from "../charts/ReportLineChart";
import Button from "../utils/Button";
import FormInput from "../utils/FormInput";

const Report: React.FC = () => {
    const t = useT()
    const orderReports = useSelector<StoreState, OrderReport[]>(state => state.orderReports);
    const order = useSelector<StoreState, Order[]>(state => state.orders);
    const dispatch = useDispatch<AppDispatch>()
    const [totalCount, setTotalCount] = useState<{ name: string, data: number[] }>({ name: "", data: [] })
    const [totalPrice, setTotalPrice] = useState<{ name: string, data: number[] }>({ name: "", data: [] })
    const [dataSets, setDataSets] = useState<{ name: string, data: { count: number, price: number, date: string }[] }[]>([])
    const [fromDate, setFromDate] = useState(initDate(6))
    const [toDate, setToDate] = useState(initDate())
    const [arrayDates, setArrayDates] = useState<string[]>([]);
    const [colors, setColors] = useState<{ count: string[], price: string[] }>({ count: [], price: [] })

    const fetchOrderReports = (fromDate: string, toDate: string) => {
        const endDate = currentDate(toDate, 1)
        dispatch(fetchShopOrderReports(fromDate, endDate))
    }

    React.useEffect(() => {
        let TotalCount: number[] = []
        let TotalPrice: number[] = []

        //category Total Counts
        for (let i in arrayDates) {
            let total = 0
            let price = 0
            for (let orderReport of orderReports) {
                let filter = orderReport.data.filter((d) => d.date === arrayDates[i])[0]
                total = filter ? total + filter.count : total + 0;
                price = filter ? price + filter.price : price + 0;
            }
            TotalCount[i] = total
            TotalPrice[i] = price
        }

        const dataSets = orderReports.map((orderReport: any) => {
            let obj = {
                name: orderReport._id.category[0].names[0].name,
                data: arrayDates.map((arrayDate) => {
                    let filter = orderReport.data.filter((data: any) => data.date === arrayDate)[0]
                    if (filter)
                        return { count: filter.count, price: filter.price, date: arrayDate }
                    else
                        return { count: 0, price: 0, date: arrayDate }
                }
                )
            }
            return obj
        })
        setDataSets(dataSets)
        setTotalCount({ name: "Total Orders", data: TotalCount })
        setTotalPrice({ name: "Total Amount", data: TotalPrice })

    }, [orderReports, fromDate, toDate, arrayDates])
    React.useEffect(() => {
        fetchOrderReports(fromDate, toDate)
    }, [])
    React.useEffect(() => {
        let countColors = [];
        let priceColors = [];
        for (let i = 0; i < orderReports.length; i++) {
            countColors.push('#' + Math.random().toString(10).slice(-6))
            priceColors.push('#' + Math.random().toString(10).slice(-6))
        }
        setColors({ count: countColors, price: priceColors })
    }, [orderReports])
    React.useEffect(() => {
        setArrayDates(datesArray(fromDate, toDate))
        fetchOrderReports(fromDate, toDate)
    }, [fromDate, toDate])

    const formatDate = (dateString: string): string => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    function handleDownloadPdf(): void {
        const doc = new jsPDF({ orientation: "landscape" });

        doc.text("Sales Report", 20, 10);

        const weekChunks = [];
        for (let i = 0; i < arrayDates.length; i += 7) {
            weekChunks.push(arrayDates.slice(i, i + 7));
        }

        let startY = 20;
        weekChunks.forEach((weekDates, weekIndex) => {
            const headers: string[][] = [
                ["Category", ...weekDates, "Total Price", "Total Count"]
            ];

            const body: (string | number)[][] = dataSets.map(dataSet => {
                // Map weekDates to the corresponding dataset entries by matching the date
                const pricesAndCounts = weekDates.map(weekDate => {
                    const dataForDate = dataSet.data.find(item => item.date === weekDate);

                    if (!dataForDate) {
                        return '0 / 0';  // If no data is found for the date, return default
                    }

                    const price = Math.round(dataForDate.price ?? 0);
                    const count = dataForDate.count ?? 0;
                    return `${price} / ${count}`;
                });

                const totalPriceForWeek = dataSet.data
                    .filter(item => weekDates.includes(item.date))  // Filter data within the current week
                    .reduce((sum, item) => sum + Math.round(item.price ?? 0), 0);

                const totalCountForWeek = dataSet.data
                    .filter(item => weekDates.includes(item.date))  // Filter data within the current week
                    .reduce((sum, item) => sum + (item.count ?? 0), 0);

                return [dataSet.name, ...pricesAndCounts, totalPriceForWeek, totalCountForWeek];
            });

            autoTable(doc, {
                head: headers,
                body: body,
                startY: startY,
                theme: 'striped',
            });

            startY = (doc as any).lastAutoTable.finalY + 10;
        });


        const isWithinDateRange = (orderDate: string, fromDate: string, toDate: string): boolean => {
            const orderDateObj = new Date(orderDate);
            const fromDateObj = new Date(fromDate);
            const toDateObj = new Date(toDate);

            return orderDateObj >= fromDateObj && orderDateObj <= toDateObj;
        };

        doc.text('Customer Details', 15, (doc as any).lastAutoTable.finalY + 10);

        const customerHeaders: string[][] = [
            ["Name", "Order Number", "Date", "Bill Amount", "Payment Mode"]
        ];

        const customerBody: (string | number)[][] = order
            .filter(o => isWithinDateRange(o.orderDate, fromDate, toDate))
            .map(o => [
                o.buyer.firstName,
                o.orderNumber,
                formatDate(o.orderDate),
                o.billAmount,
                o.paymentMode
            ]);

        autoTable(doc, {
            head: customerHeaders,
            body: customerBody,
            startY: startY + 10,
            theme: 'striped',
        });

        doc.save("report.pdf");
    }

    return (
        <>
            <div className="row m-2">
                <div className="col-lg-4 col-sm-12">
                    <FormInput
                        type='date'
                        label={t('From Date')}
                        placeholder={t('Order value')}
                        containerClass='mb-4'
                        value={fromDate}
                        onChange={(e) => setFromDate(e.target.value)}
                    />

                </div>
                <div className="col-lg-4 col-sm-12">
                    <FormInput
                        type='date'
                        label={t('To Date')}
                        placeholder={t('Order value')}
                        containerClass='mb-4'
                        value={toDate}
                        onChange={(e) => setToDate(e.target.value)}
                    />
                </div>
                <div className="col-lg-4 col-sm-12 mt-4">
                    <Button size="sm" className={style.saveButton} onClick={() => handleDownloadPdf()}>
                        Download PDF
                    </Button>
                </div>
            </div>
            <div className="card my-3 shadow-sm">
                <div className="card-header bg-white"><h5 className="header-title mb-3">{t("Order Reports (Count)")}</h5></div>
                <div className="card-body">
                    <div className="row">

                        <div className="col-lg-6 col-sm-12">
                            <div className="card m-2 p-2">
                                <ReportBarChart
                                    datasets={dataSets}
                                    colors={colors?.count}
                                    labels={arrayDates}
                                    title={t('count')}
                                />
                            </div>
                        </div>

                        <div className="col-lg-6 col-sm-12">
                            <div className="card m-2 p-2">
                                <ReportLineChart
                                    datasets={[totalCount]}
                                    colors={['#AA3089']}
                                    labels={arrayDates}
                                    title=''
                                />
                            </div>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="card p-4 m-2" style={{ overflowX: "auto" }}>
                                <table className="table" style={{ whiteSpace: "nowrap" }}>
                                    <thead>
                                        <tr>
                                            <th>{t("Category")}</th>
                                            {arrayDates.map((arrayDate) => { return (<th>{arrayDate}</th>) })}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            dataSets.map((dataSet) => {
                                                const counts = dataSet.data.map((d) => (<td>{d.count}</td>))
                                                return (
                                                    <Fragment>
                                                        <tr>
                                                            <td>{dataSet.name}</td>
                                                            {counts}
                                                        </tr>
                                                    </Fragment>
                                                )
                                            })
                                        }
                                        <tr>
                                            <th>{t("Total")}</th>
                                            {totalCount.data.map((total) => (
                                                <th>{total}</th>
                                            ))}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card my-3 shadow-sm">
                <div className="card-header bg-white"><h5 className="header-title mb-3">{t("Order Reports (Amount)")}</h5></div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-lg-6 col-sm-12">
                            <div className="card m-2 p-2">
                                <ReportBarChart
                                    datasets={dataSets}
                                    colors={colors?.price}
                                    labels={arrayDates}
                                    title={t('price')}
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-12">
                            <div className="card m-2 p-2">
                                <ReportLineChart
                                    datasets={[totalPrice]}
                                    colors={['#009ef7']}
                                    labels={arrayDates}
                                    title={t('price')}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="card p-4 m-2" style={{ overflowX: "auto" }}>
                                <table className="table" style={{ whiteSpace: "nowrap" }}>
                                    <thead>
                                        <tr>
                                            <th>{t("Category")}</th>
                                            {arrayDates.map((arrayDate) => { return (<th>{arrayDate}</th>) })}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            dataSets.map((dataSet) => {
                                                const prices = dataSet.data.map((d) => (<td>{Math.round(d.price)}</td>))
                                                return (
                                                    <Fragment>
                                                        <tr>
                                                            <td>{dataSet.name}</td>
                                                            {prices}
                                                        </tr>
                                                    </Fragment>
                                                )
                                            })
                                        }
                                        <tr>
                                            <th>{t("Total")}</th>
                                            {totalPrice.data.map((total) => (
                                                <th>{Math.round(total)}</th>
                                            ))}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Report