import { Dispatch } from 'redux'
import { api, apiErrorHandler, getApiRequestConfig, Response } from '../../api'
import { GetState } from '../reducers'
import { Blog } from '../reducers/blog.reducer'

export enum BlogActionTypes {
    ADD_BLOG = 'ADD_BLOG',
    UPDATE_BLOG = 'UPDATE_BLOG',
    FETCH_BLOGS = 'FETCH_BLOGS',
    DELETE_BLOG = 'DELETE_BLOG'
}

export interface AddBlogAction {
    type: BlogActionTypes.ADD_BLOG
    data: Blog
}

export interface UpdateBlogAction {
    type: BlogActionTypes.UPDATE_BLOG
    data: Blog
}

export interface FetchShopBlogsAction {
    type: BlogActionTypes.FETCH_BLOGS
    data: Blog[]
}

export interface DeleteBlog {
    id: string
}

export interface DeleteBlogAction {
    type: BlogActionTypes.DELETE_BLOG
    data: string
}

export type BlogActions = AddBlogAction | FetchShopBlogsAction | UpdateBlogAction|DeleteBlogAction

interface AddBlog {
    title: string
    description: string
    imageUrl: string
    content: string
    author: string
}

export const addBlog = (input: AddBlog) => async (dispatch: Dispatch, getState: GetState) => {
    const authSuperAdmin = getState().authSuperAdmin
    const config = getApiRequestConfig(authSuperAdmin?.['auth-token'])

    return api.post<Response<Blog>>('blog', input, config).then(response => {
        if (response.status === 200) {
            dispatch<AddBlogAction>({
                type: BlogActionTypes.ADD_BLOG,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || 'Added')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to add'))
    })
}

 export interface UpdateBlog {
    title: string
    description: string
    imageUrl: string
    content: string
    author: string
}

export const updateBlog = (input: UpdateBlog, BlogId: string) => async (dispatch: Dispatch, getState: GetState) => {
    const authSuperAdmin = getState().authSuperAdmin
    const config = getApiRequestConfig(authSuperAdmin?.['auth-token'])

    return api.put<Response<Blog>>(`blog/${BlogId}`, input, config).then(response => {
        if (response.status === 200) {
            dispatch<UpdateBlogAction>({
                type: BlogActionTypes.UPDATE_BLOG,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || 'Updated')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to updated'))
    })
}

export const fetchBlogs = () => async (dispatch: Dispatch, getState: GetState) => {
    const authSuperAdmin = getState().authSuperAdmin
    const config = getApiRequestConfig(authSuperAdmin?.['auth-token'])

    return api.get<Response<Blog[]>>('blog', config).then(response => {
        if (response.status === 200) {
            dispatch<FetchShopBlogsAction>({
                type: BlogActionTypes.FETCH_BLOGS,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || '')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to fetch'))
    })
}

export const deleteBlog = (BlogId: string) => async (dispatch: Dispatch, getState: GetState) => {
    const authSuperAdmin = getState().authSuperAdmin
    const config = getApiRequestConfig(authSuperAdmin?.['auth-token'])

    return api.delete(`blog/${BlogId}`,config).then(response => {
        if (response.status === 200) {
            dispatch<DeleteBlogAction>({
                type: BlogActionTypes.DELETE_BLOG,
                data: BlogId
            })
            return Promise.resolve<string>(response.data.message || 'Delete Blog')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to delete'))
    })
}