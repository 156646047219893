import style from '../../styles/components/minus.module.scss'

interface MinusProps {
    onClick?: () => void
}

const Minus = (props: MinusProps) => {
    return <div className={style.container} onClick={props.onClick}>
        <div className={style.content} />
    </div>
}

export default Minus