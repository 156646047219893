import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AdminLayout from '../../components/sections/AdminLayout'
import AdminPanelHeader from '../../components/sections/AdminPanelHeader'
import Category from '../../components/sections/Category'
import DragAndDrop from '../../components/utils/DragAndDrop'
import { AdminContext, AdminContextType } from '../../context/AdminProvider'
import { useT } from '../../i18n'
import { reorderShopCategories } from '../../redux/actions'
import { StoreState } from '../../redux/reducers'
import { Category as CategoryType } from '../../redux/reducers/category.reducer'
import { AppDispatch } from '../../redux/store'

const Categories = () => {
    const adminContext = React.useContext<AdminContextType | null>(AdminContext)
    const categories = useSelector<StoreState, CategoryType[]>(state => state.categories)

    const dispatch = useDispatch<AppDispatch>()
    const t = useT()
    const categoriesDropHandler = (items: CategoryType[]) => {
        const seqNoUpdatedCategries = items.map((item, index) => ({ ...item, seqNo: index + 1 }))
        dispatch(reorderShopCategories(seqNoUpdatedCategries))
    }

    return <AdminLayout>
        <div className='row mb-4'>
            <AdminPanelHeader
                btnText={t('Add Category')}
                title={t('Categories')}
                onBtnClick={adminContext?.onAddCategory}
            />
            <DragAndDrop
                items={[...categories].sort((a, b) => a.seqNo - b.seqNo)}
                onDrop={categoriesDropHandler}
                marginBottom={1}
                renderItemGetter={item => {
                    return <Category
                        name={item.names[0].name}
                        offerLabel={item.offerLabel}
                        id={item.id}
                        key={item.id}
                    />
                }}
            />
        </div>
    </AdminLayout>
}

export default Categories