import { useT } from "../../i18n";
import { User } from "../../redux/reducers/user.reducer";

interface viewProps {
    data?: User
}


const ViewUser = ({ data }: viewProps) => {
    const t = useT()
    return (
        <div className="p-2 my-2">
            <div className="row">
                <div className='col-sm-6 col-md-4 col-lg-3'>
                    <label className="fw-bold">{t("First Name")}</label>
                    <input type='text' className='form-control text-capitalize mb-3 mt-1' value={data?.firstName} disabled />
                </div>
                <div className='col-sm-6 col-md-4 col-lg-3'>
                    <label className="fw-bold">{t("Last Name")}</label>
                    <input type='text' className='form-control text-capitalize mb-3 mt-1' value={data?.lastName} disabled />
                </div>
                <div className='col-sm-6 col-md-4 col-lg-3'>
                    <label className="fw-bold">{t("Email")}</label>
                    <input type='text' className='form-control mb-3 mt-1' value={data?.email} disabled />
                </div>
                <div className='col-sm-6 col-md-4 col-lg-3'>
                    <label className="fw-bold">{t("Phone Number")}</label>
                    <input type='text' className='form-control text-capitalize mb-3 mt-1' value={data?.phoneNumber} disabled />
                </div>
                <div className='col-sm-6 col-md-4 col-lg-3'>
                    <label className="fw-bold">{t("Address Line 1")}</label>
                    <input type='text' className='form-control text-capitalize mb-3 mt-1' value={data?.address.addressLine1} disabled />
                </div>
                <div className='col-sm-6 col-md-4 col-lg-3'>
                    <label className="fw-bold">{t("Address Line 2")}</label>
                    <input type='text' className='form-control text-capitalize mb-3 mt-1' value={data?.address.addressLine2} disabled />
                </div>
                <div className='col-sm-6 col-md-4 col-lg-3'>
                    <label className="fw-bold">{t("City")}</label>
                    <input type='text' className='form-control text-capitalize mb-3 mt-1' value={data?.address.city} disabled />
                </div>
                <div className='col-sm-6 col-md-4 col-lg-3'>
                    <label className="fw-bold">{t("State")}</label>
                    <input type='text' className='form-control text-capitalize mb-3 mt-1' value={data?.address.state} disabled />
                </div>
                <div className='col-sm-6 col-md-4 col-lg-3'>
                    <label className="fw-bold">{t("Pincode")}</label>
                    <input type='text' className='form-control text-capitalize mb-3 mt-1' value={data?.address.pincode} disabled />
                </div>
                <div className='col-sm-6 col-md-4 col-lg-3'>
                    <label className="fw-bold">{t("Landmark")}</label>
                    <input type='text' className='form-control text-capitalize mb-3 mt-1' value={data?.address.landmark} disabled />
                </div>
            </div>
        </div>

    )
}

export default ViewUser;