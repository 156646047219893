import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { fetchShopVouchers } from '../../redux/actions'
import { StoreState } from '../../redux/reducers'
import { Voucher } from '../../redux/reducers/voucher.reducer'
import { AppDispatch } from '../../redux/store'
import style from '../../styles/components/slide-card.module.scss'
import { date } from '../../utils'

interface SlideCardProps {
    shopId: string
    shopName: string
    bannerImage: string
    description: string
}

const SlideCard = (props: SlideCardProps) => {

    const dispatch = useDispatch<AppDispatch>()
    // const [voucher, setVoucher] = useState<Voucher>()
    // const voucherList = useSelector<StoreState, Voucher[]>(state => state.vouchers)

    // const vouchers = voucherList.filter(v => v.shopId === props.shopId)
    // const NotExpiredVouchers = useMemo<Voucher[]>(() => {
    //     var currentDate = new Date().getTime()

    //     return vouchers.filter(v => {
    //         return new Date(date('Y-Mn-D', v.expiryDate)).getTime() >= currentDate
    //     })
    // }, [vouchers])

    // useEffect(() => {
    //     if (NotExpiredVouchers.length > 0) {
    //         const arr = NotExpiredVouchers.map(e => new Date(date('Y-Mn-D', e.createdAt)).getTime())
    //         let maxTime = Math.max(...arr)
    //         NotExpiredVouchers.forEach(e => {
    //             if (new Date(date('Y-Mn-D', e.createdAt)).getTime() === maxTime) setVoucher(e)
    //         })
    //     }

    // }, [NotExpiredVouchers])

    // useEffect(() => {
    //     dispatch(fetchShopVouchers())
    // }, [])
    
    return <Link to={`/shop/${props.shopId}`} className={style.container}>
        <div className={style.content}>
            <div className={style.leftSection}>
                <div className={style.infoSection}>
                    <div className={style.shopName}>{props.shopName}</div>
                    {/* <div className={style.offerText}>{voucher !== undefined ? voucher.description : ''}</div> */}
                    <div className={style.description}>{props.description}</div>
                </div>
            </div>
            <div className={style.rightSection}>
                <img src={props.bannerImage} alt='' loading='lazy' />
            </div>
        </div>
    </Link>
}

export default SlideCard