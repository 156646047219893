import React from 'react'
import { Button } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Response, api, getApiRequestConfig } from "../../api"
import { StoreState } from '../../redux/reducers'
import { AuthSuperAdmin } from '../../redux/reducers/authSuperAdmin.reducer'
import { Shop } from '../../redux/reducers/shop.reducer'
import { ToastType, useToast } from '../ToastProvider'
import ModalWithHeader from './ModalWithHeader'

interface PlanInformationProps {
    onClose: () => void
    planType?: string
    showbutton?: boolean
    shopId: string
}

const PlanInformation = (props: PlanInformationProps) => {
    const authSuperAdmin = useSelector<StoreState, AuthSuperAdmin | null>(state => state.authSuperAdmin)
    const adminConfig = getApiRequestConfig(authSuperAdmin?.['auth-token'])
    const token = authSuperAdmin?.['auth-token']
    const config = getApiRequestConfig(token)

    const toast = useToast()

    const [plan, setPlan] = React.useState<string>('')
    const [planError, setPlanError] = React.useState<string>('')
    const [password, setPassword] = React.useState<string>('')
    const [passwordError, setPasswordError] = React.useState<string>('')
    const [showModal, setShowModal] = React.useState<Boolean>(false)

    const modalCloseHandler = () => {
        setShowModal(false)
    }

    const planChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setPlan(e.target.value)
        setPlanError('')
    }

    const passwordChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setPassword(e.target.value)
        setPasswordError('')
    }

    const upgradeHandler = (plan: string) => {
        setPlan(plan)
        let error = false


        if ((plan) === '') {
            setPlanError('Plan required')
            error = true
        }

        if (!error) {

            if (props.shopId) {

                const input = {
                    plan: plan,
                }

                api.put<Response<Shop>>(`shop/plan/${props.shopId}`, input, config).then(response => {
                    if (response.status === 200) {
                        toast(response.data?.message!, ToastType.SUCCESS)
                        props.onClose()
                    }
                }).catch((error) => {
                    toast(error, ToastType.ERROR)

                })
            }
        }

    }
    return <>
        <ModalWithHeader
            headerText='Plan Information'
            onClose={props.onClose}
        >
            <div className='m-1'>
                <div className='hstack gap-3'>
                    {<div className={"card p-2 mb-2"} style={props.planType === "Starter" ? { height: '300px', backgroundColor: "lemonchiffon" } : { height: '300px' }}>
                        <h6 className='d-flex justify-content-center'>Starter</h6>
                        <div className='card-body border-0 p-0'>
                            <div>10% commission + 0 &euro;/kk</div>
                            <div>Limited catlog</div>
                            <div>Order Management</div>
                            <div>Pickup order</div>
                            <div>Fexible Payment Option</div>

                        </div>
                        {(props.planType === "Starter" ? props.showbutton === false : props.showbutton === true) && <Button className='bg-info border-0' onClick={() => upgradeHandler('Starter')}>Upgrade</Button>}
                    </div>}
                    {< div className='card p-2 mb-2' style={props.planType === "Economy" ? { height: '300px', backgroundColor: "lemonchiffon" } : { height: '300px' }}>
                        <h6 className='d-flex justify-content-center'>Economy</h6>
                        <div className='card-body border-0 p-0'>
                            <div>15% commission + 0 &euro;/kk</div>
                            <div>Unlimited product catlog</div>
                            <div>Order Management</div>
                            <div>Pickup order</div>
                            <div>Fexible Payment Option</div>

                        </div>
                        {(props.planType === "Economy" ? props.showbutton === false : props.showbutton === true) && <Button className='bg-info border-0' onClick={() => upgradeHandler('Economy')}>Upgrade</Button>}
                    </div>}

                    {<div className='card p-2 mb-2' style={props.planType === "Standard" ? { height: '300px', backgroundColor: "lemonchiffon" } : { height: '300px' }}>
                        <h6 className='d-flex justify-content-center'>Standard</h6>
                        <div className='card-body border-0 p-0'>
                            <div>15% commission + 99 &euro;/kk</div>
                            <div>Unlimited product catlog</div>
                            <div>Order Management</div>
                            <div>Pickup order</div>
                            <div>Shipping API</div>
                            <div>Payment Integration</div>

                        </div>
                        {(props.planType === "Standard" ? props.showbutton === false : props.showbutton === true) && <Button className='bg-info border-0' onClick={() => upgradeHandler('Standard')}>Upgrade</Button>}
                    </div>}
                    {<div className='card p-2 mb-2' style={props.planType === "Premium" ? { height: '300px', backgroundColor: "lemonchiffon" } : { height: '300px' }}>
                        <h6 className='d-flex justify-content-center'>Premium</h6>
                        <div className='card-body border-0 p-0'>
                            <div>20% commission + 999 &euro;/v</div>
                            <div>Unlimited product catlog</div>
                            <div>Order Management</div>
                            <div>Pickup order</div>
                            <div>Shipping API</div>
                            <div>Payment Integration</div>
                            <div>Delivery Management</div>
                            <div>24/7 support</div>

                        </div>
                        {(props.planType === "Premium" ? props.showbutton === false : props.showbutton === true) && <Button className='bg-info border-0' onClick={() => upgradeHandler('Premium')}>Upgrade</Button>}
                    </div>}
                </div>
            </div>
            {/* {showModal && <ModalWithHeader
                buttonText="Plan Upgrade"
                headerText="Plan Upgrade"
                onClose={modalCloseHandler}
                onSave={upgradePlanHandler}
                small
            >

                <div className='hstack gap-3'>
                    <div className="col-md-8 col-lg-5">
                        <FormInput
                            label='Password'
                            placeholder="Password"
                            containerClass='mb-4'
                            value={password}
                            errorText={passwordError}
                            onChange={passwordChangeHandler}
                            required={true}
                        />
                    </div>
                    <div className="col-md-8 col-lg-5">
                        <Select
                            options={PLAN_OPTIONS}
                            label={('Plan')}
                            placeholder={('Select plan')}
                            required={true}
                            errorText={planError}
                            value={plan}
                            onChange={planChangeHandler}
                            containerClass='mb-4'
                        />
                        <FormInput
                            label='Plan'
                            placeholder="Plan"
                            containerClass='mb-4'
                            value={plan}
                            errorText={planError}
                            onChange={planChangeHandler}
                            required={true}
                            readOnly
                        />
                    </div>
                    <Button className={style.planUpgradeBtn} onClick={upgradePlanHandler}>Plan Upgrade</Button>
                </div>
            </ModalWithHeader>} */}
        </ModalWithHeader >
    </>
}

export default PlanInformation
