import React, { MouseEventHandler } from 'react'
import { Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { ToastType, useToast } from '../../components/ToastProvider'
import PasswordResetForm from '../../components/forms/PasswordResetForm'
import ModalWithHeader from '../../components/modals/ModalWithHeader'
import AdminPanelHeader from '../../components/sections/AdminPanelHeader'
import SuperAdminLayout from '../../components/sections/SuperAdminLayout'
import { SuperAdminContext } from '../../context/SuperAdminProvider'
import { useT } from '../../i18n'
import { deleteDivision, fetchDivisions } from '../../redux/actions/division.actions'
import { StoreState } from '../../redux/reducers'
import { Division } from '../../redux/reducers/division.reducer'
import { AppDispatch } from '../../redux/store'

interface TableBody {
    sno: number
    id: string
    name: string
    userName: string
    phoneNumber: string
    email: string
    addressLine1: string
    addressLine2: string
    city: string
    state: string
    pincode: number
    onDelete: MouseEventHandler<HTMLButtonElement>
}

const Divisions = () => {
    const t = useT()
    const toast = useToast()
    const dispatch = useDispatch<AppDispatch>()

    const superAdminContext = React.useContext(SuperAdminContext)
    const divisionList = useSelector<StoreState, Array<Division>>((state => state.division))

    const handleDelete = (value: Division) => {
        if (value.id !== undefined) {
            dispatch(deleteDivision(value.id)).then(text => {
                toast(text, ToastType.SUCCESS)
                fetchDivisions()
            }).catch(text => {
                toast(text, ToastType.ERROR)
            })
        }
    }

    return <SuperAdminLayout>
        <div className='row mb-4'>
            <AdminPanelHeader
                btnText={t('Add Division')}
                title={t('Divisions')}
                onBtnClick={superAdminContext?.onAddDivision}
            />
            <div className="card m-2">
                <div className="card-body">
                    <div className='table-wrapper'>
                        <table className='table'>
                            <thead className="table-primary">
                                <tr>
                                    <th className='text-truncate  align-middle'>S.No</th>
                                    <th className='text-truncate  align-middle'>{t("Name")}</th>
                                    <th className='text-truncate  align-middle'>{t("User Name")}</th>
                                    <th className='text-truncate  align-middle'>{t("Phone Number")}</th>
                                    <th className='text-truncate  align-middle'>{t("Email")}</th>
                                    {/* <th className='text-truncate  align-middle'>Address</th> */}
                                    <th className='text-truncate  align-middle'>{t("Reset Password")}</th>
                                    <th className='text-truncate align-middle'>{t("Action")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {divisionList.map((u, i) => {
                                    return <DivisionBody
                                        sno={i + 1}
                                        id={u.id}
                                        name={u.name}
                                        phoneNumber={u.phoneNumber}
                                        userName={u.userName}
                                        email={u.email}
                                        addressLine1={u.address.addressLine1}
                                        addressLine2={u.address.addressLine2}
                                        city={u.address.city}
                                        state={u.address.state}
                                        pincode={u.address.pincode}
                                        key={i}
                                        onDelete={() => handleDelete(u)}
                                    />
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </SuperAdminLayout>
}

const DivisionBody = ({ sno, name, userName, email, phoneNumber, addressLine1, addressLine2, city, state, pincode, onDelete, id }: TableBody) => {
    const t = useT()
    const superContext = React.useContext(SuperAdminContext)
    const [selectedAdmin, setSelectedAdmin] = React.useState({ id: '', name: '', username: '' });
    const [isPasswordReset, setIsPasswordReset] = React.useState(false);
    const modalCloseHandler = () => {
        setIsPasswordReset(false)
    }
    return <React.Fragment>
        <tr>
            <td className='align-middle text-truncate'>{sno}</td>
            <td className='align-middle text-truncate text-capitalize'>{name}</td>
            <td className='align-middle text-truncate text-capitalize'>{userName}</td>
            <td className='align-middle text-truncate text-capitalize'>{phoneNumber}</td>
            <td className=''>{email}</td>
            {/* <td className='align-middle text-truncate text-capitalize'>{addressLine1}, &nbsp;{addressLine2}, &nbsp;{city}, &nbsp;{state}, &nbsp;{pincode}</td> */}
            <td className='align-middle'>
                <div className='hstack gap-1'>
                    <Button className='btn btn-info text-white btn-sm' onClick={() => { setSelectedAdmin({ id, name, username: userName }); setIsPasswordReset(true) }}>{t("Reset Password")}</Button>
                </div>
            </td>
            <td>
                <div className='hstack gap-1'>
                    <button className='btn btn-sm btn-success' onClick={() => superContext?.onEditDivision?.(id)!}>{t("Edit")}</button>
                </div>
            </td>
        </tr>
        {
            isPasswordReset && <ModalWithHeader onClose={modalCloseHandler} headerText={`Password Reset - ${selectedAdmin.name}`} small={true}>
                <PasswordResetForm endPoint='division/set/password' afterSave={modalCloseHandler} admin={selectedAdmin} />
            </ModalWithHeader>
        }
    </React.Fragment>
}

export default Divisions