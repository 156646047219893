
import React, { Fragment } from 'react'
import { useT } from '../../i18n'
import style from '../../styles/components/added-to-cart.module.scss'
import { Booking, BookingDetails } from '../../redux/reducers/booking.reducer'
import Checkbox from '../utils/Checkbox'
import ModalWithHeader from './ModalWithHeader'
interface Props {
    booking: Booking|null
    bookingDetails:BookingDetails[]|null
    onClose:()=>void
    onSave:()=>void
    bookingStatusChangeHandler:(status:boolean,index:number)=>void
}

const EventRegisterModal: React.FC<Props> = (props) => {
    const t = useT()

    const attendParticipant = React.useMemo(()=>{
        const bookings = props.booking?.bookingDetails.filter((b)=>b.status===false);
        if(bookings?.length!==0)
        return true
        else return false
    },[props])

    return <ModalWithHeader onClose={props.onClose}  buttonText={attendParticipant?'Attend':''} onSave={attendParticipant?props.onSave:undefined} headerText='Reserved Participants' small>
        <div className={style.container}>
            {
            props.booking?.bookingDetails.filter((b)=>b.status===true).map((b:any)=>(
                <Fragment>
                    <div className='hstack justify-content-between'>
                        <div className={style.title}>{b.title}</div>
                        <Checkbox 
                            className=''
                            selectColor={b.status?'#4ab96a':''}
                            checked={b.status}
                        />
                    </div>
                </Fragment>
            ))
            }
            {
            props?.bookingDetails?.length!==0?props?.bookingDetails?.map((b: any, i: number) => (
                <Fragment>
                    <div className='hstack justify-content-between'>
                        <div className={style.title}>{b.title}</div>
                        <Checkbox 
                            className=''
                            selectColor={b.status?'#4ab96a':''}
                            onClick={() =>props.bookingStatusChangeHandler(b.status?false:true,i)}
                            checked={b.status}
                        />
                    </div>
                </Fragment>
            )):
            <div className={style.text}>{`Thank you for participating`}</div>
        }
        </div>
    </ModalWithHeader>
}

export default EventRegisterModal;