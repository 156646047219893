import { Action, EventActionTypes } from '../actions'
import { Category } from './category.reducer'
export type EventType = 'paid' | 'free'
export type EventStatus = 'active' | 'inactive'
export type EventPreviewStatus = 'active' | 'inactive'

export interface EventName {
    language: string
    name: string
    createdAt: string
    updatedAt: string
    id: string
}

export interface EventDescription {
    language: string
    description: string
    createdAt: string
    updatedAt: string
    id: string
}

export interface EventPrice {
    size: string
    price: number
    offerPrice: number | null
    createdAt: string
    updatedAt: string
    id: string
}

export interface Event {
    names: EventName[]
    descriptions: EventDescription[]
    categories: Category[]
    type: EventType
    image: string
    location: string
    additionalImages: string[]
    offerPrice: number | null
    tax: number | null
    offerLabel: string | null,
    fromDate:string,
    fromTime:string,
    toDate:string,
    toTime:string,
    status: EventStatus
    previewStatus:EventPreviewStatus
    shopId: string
    createdAt: string
    updatedAt: string
    foodPreferences:string[]|null
    volunteers:string[]|null
    cultureVouchers:string[]|null
    id: string
    prices: EventPrice[]
    eventRule:string|null
    eventStatus:string|null
    withoutRegistration:string |null
    price: number | null
}

const initialState: Event[] = []

const eventReducer = (state: Event[] = initialState, action: Action): Event[] => {
    switch (action.type) {
        case EventActionTypes.ADD_EVENT: {
            return [
                ...state,
                action.data
            ]
        }

        case EventActionTypes.UPDATE_EVENT: {
            const events = [...state]
            const index = events.findIndex(f => f.id === action.data.id)

            if (index > -1) {
                events[index] = {
                    ...action.data
                }
            }

            return events
        }

        case EventActionTypes.FETCH_EVENTS: {
            return action.data
        }

        default: {
            return state
        }
    }
}

export default eventReducer