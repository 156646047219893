import { nanoid } from '@reduxjs/toolkit'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useT } from '../../i18n'
import { UpdatePickup } from '../../redux/actions'
import { StoreState } from '../../redux/reducers'
import { AuthAdmin } from '../../redux/reducers/authAdmin.reducer'
import { Shop } from '../../redux/reducers/shop.reducer'
import { AppDispatch } from '../../redux/store'
import style from '../../styles/components/form.module.scss'
import { ToastType, useToast } from '../ToastProvider'
import PickupPointForm, { Pickup } from '../forms/PickUpPointForm'
import AddMore from '../utils/AddMore'
import Button from '../utils/Button'
import ErrorText from '../utils/ErrorText'
import PickupBox from '../utils/PickupBox'

const PickupPoint = () => {
    const dispatch = useDispatch<AppDispatch>()
    const toast = useToast()
    const t = useT()

    const authAdmin = useSelector<StoreState, AuthAdmin | null>(state => state.authAdmin)
    const shop = useSelector<StoreState, Shop | null>(state => state.shops.find(s => s.id === authAdmin?.shop.id) || null);
    const [pickups, setPickups] = React.useState<Pickup[]>([])
    const [pickupsError, setPickupsError] = React.useState<string>('')
    const [showPickupForm, setShowPickupForm] = React.useState<boolean>(false)
    const [loading, setLoading] = React.useState<boolean>(false)

    const PickupSaveHandler = (pickup: Pickup) => {
        setPickups(prev => [...prev, pickup])
        setPickupsError('')
    }

    const saveClickHandler = () => {
        setLoading(true)
        let data = pickups.map(pickup => ({
            addressLine1: pickup.addressLine1,
            addressLine2: pickup.addressLine2,
            city: pickup.city,
            state: pickup.state,
            pincode: pickup.pincode,
            phoneNumber: pickup.phoneNumber,
            landmark: pickup.landmark

        }))
        dispatch(UpdatePickup(
            data
        ))

            .then(() => {
                toast(('Pickup Point updated'))
            }).catch(text => {
                toast(text, ToastType.ERROR)
            }).finally(() => {
                setLoading(false)
            })
    }
    console.log(authAdmin?.shop)
    React.useEffect(() => {
        setPickups(shop?.pickup?.map((pickup) => ({
            id: nanoid(),
            addressLine1: pickup.addressLine1,
            addressLine2: pickup.addressLine2,
            city: pickup.city,
            state: pickup.state,
            landmark: pickup.landmark,
            phoneNumber: pickup.phoneNumber,
            pincode: pickup.pincode
        })) || [])
    }, [shop])

    return <React.Fragment>
        {showPickupForm && <PickupPointForm
            id={authAdmin?.id}
            onClose={() => setShowPickupForm(false)}
            onSave={PickupSaveHandler}
        />}

        <div className='col-12 mb-4'>
            {/* <Label>{t("Slides")}</Label> */}
            <div className='hstack gap-2 flex-wrap position-relative'>
                {pickups.map(pickup => {
                    const closeClickHandler = () => {
                        setPickups(prev => prev.filter(p => p.id !== pickup.id))
                    }

                    return <PickupBox
                        addressLine1={pickup.addressLine1}
                        addressLine2={pickup.addressLine2}
                        state={pickup.state}
                        city={pickup.city}
                        pincode={pickup.pincode}
                        phoneNumber={pickup.phoneNumber}
                        landmark={pickup.landmark}
                        key={pickup.id}
                        onClose={closeClickHandler} id={''} />
                })}
                <AddMore
                    onClick={() => setShowPickupForm(true)}
                    title={('Add more Pickups')}
                    errorText={pickupsError}
                />
                <ErrorText
                    errorText={pickupsError}
                    className='mt-1'
                />
            </div>
        </div>
        <div className='mb-4 col-12'>
            <Button
                className={style.saveButton}
                onClick={saveClickHandler}
                loading={loading}>{t("Save")}</Button>
        </div>
    </React.Fragment>
}

export default PickupPoint