import React from 'react'
import { useSelector } from 'react-redux'
import { api, getApiRequestConfig, Response } from '../../api'
import Confirmation from '../../components/modals/Confirmation'
import UserReservation from '../../components/sections/Reservation'
import ShouldAuthenticate from '../../components/sections/ShouldAuthenticate'
import UserLayout from '../../components/sections/UserLayout'
import { AppContext } from '../../context/AppProvider'
import { UserContext } from '../../context/UserProvider'
import { useT } from '../../i18n'
import { StoreState } from '../../redux/reducers'
import { AuthUser } from '../../redux/reducers/authUser.reducer'
import { Booking } from '../../redux/reducers/booking.reducer'
import style from '../../styles/pages/user-orders.module.scss'

type BookingMenu = 'BOOKED' | 'ATTENDED' | 'CANCELLED'

const getBookingMenu = (booking: Booking): BookingMenu | null => {
    if (booking.bookingStatus === 'booked') {

        if (booking.shopId.otherPayments.findIndex(x => x.title === booking.paymentMode) !== -1) {
            return 'BOOKED'
        }

        if (booking.paymentMode === 'paytrail' && booking.paymentStatus === 'paid') {
            return 'BOOKED'
        }

        if (booking.paymentMode === 'free') {
            return 'BOOKED'
        }
    }
    if (booking.bookingStatus === 'attended') {
        return 'ATTENDED'
    }

    if (booking.bookingStatus === 'cancelled') {
        return 'CANCELLED'
    }

    return null
}

const Bookings = () => {
    const authUser = useSelector<StoreState, AuthUser | null>(state => state.authUser)
    const appContext = React.useContext(AppContext)
    const userContext = React.useContext(UserContext)
    const [bookingMenu, setBookingMenu] = React.useState<BookingMenu | null>('BOOKED')
    const t = useT()

    const getUserBookings = () => {
        const config = getApiRequestConfig(authUser?.['auth-token'])
        api.get<Response<Booking[]>>('user/bookings', config).then(response => {
            if (response.status === 200) {
                userContext?.setBookings?.(response.data.data)
            } else {
                // eslint-disable-next-line no-throw-literal
                throw { response }
            }
        }).catch(() => { })
    }
    React.useEffect(() => {
        if (authUser) {
            getUserBookings()
        }
    }, [authUser])
    const updateStatusClickHandler = () => {
        appContext?.showOverlay?.()
        const config = getApiRequestConfig(authUser?.['auth-token'])
        api.put<Response<Booking[]>>(`user/booking/update-status/${userContext?.bookingId}`, { bookingStatus: userContext?.status }, config).then(response => {
            if (response.status === 200) {
                getUserBookings()
                userContext?.setShowConfirm?.(false)
                appContext?.hideOverlay?.()
            } else {
                // eslint-disable-next-line no-throw-literal
                throw { response }
            }
        }).catch(() => { })
    }

    const bookingCancelConfirmation = (status: string, bookingId: string) => {
        userContext?.setShowConfirm?.(true)
        userContext?.setStatus?.(status)
        userContext?.setBookingId?.(bookingId)
    }

    const closeConfirm = () => {
        userContext?.setShowConfirm?.(false)
    }
    return <ShouldAuthenticate>
        <UserLayout>
            <div className={style.title}>{t("My Bookings")}</div>
            <div className='row'>
                <div className='col-sm-12 col-lg-2 col-xl-2 mb-2'>
                    <BookingMenuLink active={bookingMenu === 'BOOKED'} onClick={() => setBookingMenu('BOOKED')}>{t("BOOKED")} ({userContext?.bookings.reverse().filter((booking) => getBookingMenu(booking) === "BOOKED").length})</BookingMenuLink>
                </div>
                <div className='col-sm-12 col-lg-2 col-xl-2 mb-2'>
                    <BookingMenuLink active={bookingMenu === 'ATTENDED'} onClick={() => setBookingMenu('ATTENDED')}>{t("ATTENDED")} ({userContext?.bookings.reverse().filter((booking) => getBookingMenu(booking) === "ATTENDED").length})</BookingMenuLink>
                </div>
                <div className='col-sm-12 col-lg-2 col-xl-2 mb-2'>
                    <BookingMenuLink active={bookingMenu === 'CANCELLED'} onClick={() => setBookingMenu('CANCELLED')}>{t("CANCELLED")} ({userContext?.bookings.reverse().filter((booking) => getBookingMenu(booking) === "CANCELLED").length})</BookingMenuLink>
                </div>
            </div>
            {userContext?.bookings.reverse().filter((booking) => getBookingMenu(booking) === bookingMenu).sort((a, b) =>
                b.bookingDate.split('/').reverse().join().localeCompare(a.bookingDate.split('/').reverse().join())).map(booking => {
                    return <UserReservation
                        key={booking.id}
                        booking={booking}
                        name={booking?.eventId?.names}
                        role='USER'
                        updateBooking={bookingCancelConfirmation}
                        statusLable={{ text: t("Cancel"), value: t("cancelled") }}
                    />
                })}
            {userContext?.showConfirm && <Confirmation onClose={closeConfirm} confirm={updateStatusClickHandler} itemName={t('Payment not refundable for cancellation')} />}
        </UserLayout>
    </ShouldAuthenticate>
}
interface MenuLinkProps {
    children?: React.ReactNode
    active?: boolean
    onClick?: () => void
}

const BookingMenuLink = (props: MenuLinkProps) => {
    return <div className={`${style.bookingMenuLink} ${props.active ? style.active : ''}`} children={props.children} onClick={props.onClick} />
}

export default Bookings