import React from 'react'
import style from '../../styles/components/input.module.scss'

export interface InputProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    rightRenderer?: React.ReactNode
    leftRenderer?: string
    countryCodeHandler?:()=>void | undefined
    leftRendererClassName?:string
    rightRendererClassName?: string
    onRightRendererClick?: React.MouseEventHandler<HTMLDivElement>
    errorText?: string
}

const Input = ({ className, rightRenderer, leftRenderer, errorText, onRightRendererClick,leftRendererClassName, rightRendererClassName, ...props }: InputProps) => {
    const isError = errorText && errorText !== ''
    return <div className='hstack'>
        {leftRenderer && <div className={`${style.leftRenderer} ${isError ? style.error : ''} ${leftRendererClassName}`} onClick={props.countryCodeHandler}>{leftRenderer}</div>}
        <input className={`${style.input} ${leftRenderer ? style.withLeftRenderer : ''} ${rightRenderer ? style.withRightRenderer : ''} ${isError ? style.error : ''} ${className || ''}`} {...props} />
        {rightRenderer && <div className={`${style.rightRenderer} ${isError ? style.error : ''} ${rightRendererClassName}`} onClick={onRightRendererClick}>{rightRenderer}</div>}
    </div>
}

export default Input