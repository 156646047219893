import { t } from 'i18next'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { api, getApiRequestConfig } from '../api'
import assets from '../assets'
import { ToastType, useToast } from '../components/ToastProvider'
import Lottie from '../components/lottie/Lottie'
// import { MenuItem } from '../components/sections/AppMenu'
import CartItem from '../components/sections/CartItem'
import UserLayout from '../components/sections/UserLayout'
import Button from '../components/utils/Button'
import Tooltip from '../components/utils/Tooltip'
import { TranslationLanguage } from '../i18n'
import { addToCart, removeAllCartItems, syncCartWithDB } from '../redux/actions'
import { StoreState } from '../redux/reducers'
import { AuthUser } from '../redux/reducers/authUser.reducer'
import { CartItem as CartItemType } from '../redux/reducers/cart.reducer'
import { Shop } from '../redux/reducers/shop.reducer'
import { AppDispatch } from '../redux/store'
import style from '../styles/components/shopping-cart.module.scss'
import tooltip from '../styles/components/tooltip.module.scss'
import { toCurrencyFormat, toNumber } from '../utils'
// import style1 from '../styles/components/user-header.module.scss'


interface SharingCartProps {
    onClose?: () => void
}

const SharingCart = (props: SharingCartProps) => {
    const toast = useToast()

    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()
    const { shopId } = useParams()
    const { cartId } = useParams()
    const shop = useSelector<StoreState, Shop | null>(state => state.shops.find(s => s.id === shopId) || null)
    const authUser = useSelector<StoreState, AuthUser | null>(state => state.authUser)
    const [cartItems, setCartItems] = React.useState<CartItemType[]>([])
    const translationLanguage = useSelector<StoreState, TranslationLanguage>(state => state.translationLanguage)
    const [isSlideIn, setIsSlideIn] = React.useState<boolean>(false)
    const [loading, setLoading] = React.useState<boolean>(true)
    const [mounted, setMounted] = React.useState<boolean>(false)
    const [canCheckout, setCanCheckout] = React.useState<boolean>(false)

    const netTotal = React.useMemo(() => {
        return cartItems.length > 0
            ? cartItems.reduce((p, c: any) => {
                return p + (c.amount * c.quantity) / (1 + c.tax / 100)
            }, 0) : 0
    }, [cartItems])

    const totalTax = React.useMemo(() => {
        return cartItems.length > 0
            ? cartItems.reduce((p, c: any) => {
                const amount = c.amount * c.quantity
                const tax = (c.tax && ((amount / (1 + c.tax / 100)) - amount) * -1) || 0
                return (p + tax)
            }, 0) : 0
    }, [cartItems])

    const closeClickHandler = () => {
        setIsSlideIn(false)
        setTimeout(() => {
            props.onClose?.()
        }, 300)
    }

    const checkoutClickHandler = () => {
        dispatch(removeAllCartItems())
        if (shopId) {
            cartItems.forEach(async ci => {
                if (ci.type === "detail") {
                    dispatch(addToCart({
                        comment: ci.comment,
                        extraToppings: ci.extraToppings,
                        foodId: ci.foodId,
                        image: ci.image,
                        names: ci.names.map(n => ({ language: (n.language), name: (n.name) })),
                        amount: ci.amount,
                        size: ci.size,
                        spices: ci.spices,
                        tax: ci.tax,
                        type: ci.type,
                        stock: ci.stock,
                        alternateToppings: ci.alternateToppings,
                        status: ci.status,
                        category: ci.category
                    }, shopId))
                } else if (ci.type === "simple") {
                    dispatch(addToCart({
                        comment: ci.comment,
                        extraToppings: [],
                        foodId: ci.foodId,
                        image: ci.image,
                        names: ci.names.map(n => ({ language: (n.language), name: (n.name) })),
                        amount: ci.amount,
                        size: null,
                        spices: [],
                        stock: ci.stock,
                        tax: ci.tax,
                        type: ci.type,
                        alternateToppings: [],
                        status: ci.status,
                        category: ci.category
                    }, shopId))
                } else if (ci.type === "custom") {
                    dispatch(addToCart({
                        comment: ci.comment,
                        extraToppings: [],
                        foodId: ci.foodId,
                        image: ci.image,
                        names: ci.names.map(n => ({ language: (n.language), name: (n.name) })),
                        amount: toNumber(0),
                        size: null,
                        spices: [],
                        stock: ci.stock,
                        tax: ci.tax,
                        type: ci.type,
                        alternateToppings: [],
                        status: ci.status,
                        category: ci.category
                    }, shopId))
                }

            })
        }
        let error: string[] = []
        cartItems.forEach((c) => {
            if (shop?.category === "Product") {
                if (c.quantity <= (c.stock ? parseInt(c.stock.toString()) : 0)) {

                } else {
                    error.push(c.names[0].name + ` - Selected qty : ${c.quantity} Available qty : ${c.stock} Please reduce the qty to proceed.`)
                }
            }
        })
        if (error.length === 0) {
            props.onClose?.()
            navigate('/checkout')
        }
        else {
            error.map(e => {
                return toast(e, ToastType.ERROR)
            })
        }
    }

    React.useEffect(() => {
        setIsSlideIn(true)
        const bodyElement = document.querySelector('body')

        if (!bodyElement) {
            return
        }

        bodyElement.classList.add('overflow-hidden')

        return () => {
            bodyElement.classList.remove('overflow-hidden')
        }
    }, [])

    React.useEffect(() => {
        if (cartItems.length > 0) {
            const foodIds = cartItems.map(i => i.foodId).join('|')
            setTimeout(() => {
                setLoading(true)
                dispatch(syncCartWithDB(foodIds)).finally(() => {
                    setLoading(false)
                })
            }, 300)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        setTimeout(() => {
            setMounted(true)
        }, 300)
    }, [])

    React.useEffect(() => {
        setCanCheckout(!cartItems.some(i => i.status === 'unavailable'))
    }, [cartItems])

    React.useEffect(() => {
        const token = authUser?.['auth-token']
        const config = getApiRequestConfig(token)
        if (cartId) {
            api.get(`cart/${cartId}`, config).then(response => {
                if (response.status === 200) {
                    let result = response.data.data.data
                    setCartItems(result)
                } else {
                    throw { response }
                }
            }).catch((error) => {
            })
        }
    }, [cartId])
    // const languageDropDownMenuList: MenuItem[] = translationLanguages.map(language => {
    //     return {
    //         onClick: () => dispatch(setTranslationLanguage(language)),
    //         text: language
    //     }
    // })
    return <UserLayout>
        {/* <div className='hstack justify-content-between p-3'>
            <Link to='/' className='d-inline-block text-decoration-none'>
                <div className='hstack gap-3'>
                    <div className={style1.logoWrapper}>
                        <img src={assets.images.logo} alt='V-Shops logo' />
                    </div>
                    <div className={style1.logoCaption}>{t("Virtual shopping")},<br />{t("Real value")}!</div>
                </div>
            </Link> */}

        {/* <div>
                <MenuButton
                    icon={<Globe />}
                    label={window.innerWidth > 500 ? translationLanguage : ""}
                    showChevron
                    menuList={languageDropDownMenuList}
                />
            </div> */}
        {/* </div> */}
        <div className={`mt-3 p-3 mb-2 ${style.sharingTitle}`}>{t("Shopping cart")}</div>
        {mounted && <React.Fragment>
            {cartItems.length > 0
                ? <React.Fragment>
                    <div className={`${style.cartItemsContainer} px-4`}>
                        {cartItems.map(item => {
                            return <CartItem
                                name={item.names[0].name}
                                quantity={item.quantity}
                                id={item.id}
                                key={item.id}
                                price={item.amount * item.quantity}
                                image={item.image}
                                size={item.size}
                                staus={item.status}
                                foodId={item.foodId}
                                stock={item.stock ? item.stock : 0}
                            />
                        })}
                    </div>
                    <div className='px-4 pb-4 mt-3'>
                        <div className='d-flex align-items-center justify-content-between mb-1'>
                            <div className={style.sharingNetTotal}>{t("Net total")}</div>
                            <div className={style.sharingNetTotal}>{toCurrencyFormat(netTotal, shop?.currency)}</div>
                        </div>
                        <div className='d-flex align-items-center justify-content-between mb-1'>
                            <div className={style.sharingTax}>{t("Taxes etc")}.</div>
                            <div className={style.sharingTax}>{toCurrencyFormat(totalTax, shop?.currency)}</div>
                        </div>
                        <div className={style.sharingHorizontalLine} />
                        <div className='d-flex align-items-center justify-content-between my-4'>
                            <div className={style.sharingInTotal}>{t("In total") + ' (INC.VAT)'}</div>
                            <div className={style.sharingInTotal}>{toCurrencyFormat(netTotal + totalTax, shop?.currency)}</div>
                        </div>
                        <div className={tooltip.parent}>
                            {!canCheckout && <Tooltip>{t("Some items are currently unavailable")}</Tooltip>}
                            <Button onClick={checkoutClickHandler} className={style.sharingCheckoutButton}>{t("Checkout")}</Button>
                            {/*  <Button onClick={checkoutClickHandler} className={style.checkoutButton} loading={loading} disabled={loading || !canCheckout}>{t("Checkout")}</Button> */}
                        </div>
                    </div>
                </React.Fragment>
                : <div className={style.cartEmpty}>
                    <div className={style.animation}>
                        <Lottie data={assets.json.empty} loop />
                    </div>
                    <div className={style.label}>{t("Your cart is empty")}</div>
                </div>}
        </React.Fragment>}
    </UserLayout>

}

export default SharingCart