import { BlogActionTypes } from '../actions/blog.actions'

export interface Blog {
    title: string
    description: string
    imageUrl: string
    content: string
    author: string
    createdAt: string
    id: string
}

const initialState: Blog[] = []

const BlogReducer = (state: Blog[] = initialState, action:any): Blog[] => {
    switch (action.type) {
        case BlogActionTypes.ADD_BLOG: {
            return [
                ...state,
                action.data
            ]
        }

        case BlogActionTypes.UPDATE_BLOG: {
            const Blogs = [...state]
            const index = Blogs.findIndex(c => c.id === action.data.id)

            if (index > -1) {
                Blogs[index] = {
                    ...action.data
                }
            }

            return Blogs
        }

        case BlogActionTypes.FETCH_BLOGS: {
            return action.data
        }

        case BlogActionTypes.DELETE_BLOG: {
            return state.filter(c => c.id !== action.data)
        }

        default: {
            return state
        }
    }
}

export default BlogReducer