interface InfoProps {
    stroke?: string
    strokeWidth?: number
}

const Info = (props: InfoProps) => {
    return <svg xmlns='http://www.w3.org/2000/svg' width='19' height='19' viewBox='0 0 19 19'>
        <g id='Icon_feather-info' data-name='Icon feather-info' transform='translate(0.5 0.5)'>
            <path id='Path_6' data-name='Path 6' d='M21,12a9,9,0,1,1-9-9A9,9,0,0,1,21,12Z' transform='translate(-3 -3)' fill='none' stroke={props.stroke || '#404040'} strokeLinecap='round' strokeLinejoin='round' strokeWidth={props.strokeWidth || '1'} />
            <path id='Path_7' data-name='Path 7' d='M18,24V18' transform='translate(-9 -10.5)' fill='none' stroke={props.stroke || '#404040'} strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' />
            <path id='Path_8' data-name='Path 8' d='M18,12h0' transform='translate(-9 -7.6)' fill='none' stroke={props.stroke || '#404040'} strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' />
        </g>
    </svg>
}

export default Info