import axios, { CancelTokenSource } from "axios";
import React from "react";
import { useSelector } from "react-redux";
import { Response, api } from "../api";
import CommonLayout from '../components/sections/CommonLayout';
import { SearchBlogResults, SearchResultModal } from "../components/sections/UserHeader";
import Search from "../components/utils/Search";
import { UserContext, UserContextType } from "../context/UserProvider";
import { useT } from "../i18n";
import { StoreState } from "../redux/reducers";
import { Blog as BlogType } from "../redux/reducers/blog.reducer";
import style1 from '../styles/components/user-header.module.scss';
import style from '../styles/pages/blog.module.scss';
import { date } from "../utils";

const Blog = () => {
    const searchContainerRef = React.useRef<HTMLDivElement>(null)
    const t = useT()
    const userProvider = React.useContext<UserContextType | null>(UserContext)
    const blogList = useSelector<StoreState, Array<BlogType>>((state => state.blog))
    const [searchTerm, setSearchTerm] = React.useState<string>('')
    const [showSearchResult, setShowSearchResult] = React.useState<boolean>(false)
    const [searching, setSearching] = React.useState<boolean>(false)
    const [searchResults, setSearchResults] = React.useState<SearchBlogResults[]>([])

    const searchChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        if (e.target.value.trim() !== '') {
            setSearching(true)
        } else {
            setSearching(false)
        }

        setSearchTerm(e.target.value)
    }

    const searchFocusHandler = () => {
        setShowSearchResult(true)
    }
    // const [searchTerm, setSearchTerm] = useState("");
    React.useEffect(() => {
        let timeout: NodeJS.Timeout | undefined
        let source: CancelTokenSource | undefined

        if (searchTerm.trim() !== '') {
            timeout = setTimeout(() => {
                source?.cancel()
                source = axios.CancelToken.source()
                setSearching(true)

                api.get<Response<SearchBlogResults[]>>(`blogSearch/${searchTerm.trim()}`, { cancelToken: source.token }).then(response => {
                    if (response.status === 200) {
                        setSearchResults(response.data.data)
                    } else {
                        // eslint-disable-next-line no-throw-literal
                        throw { response }

                    }
                }).catch(() => { }).finally(() => {
                    setSearching(false)
                })
            }, 300)
        } else {
            setSearchResults([])
        }

        return () => {
            clearTimeout(timeout)
            source?.cancel()
        }
    }, [searchTerm])

    React.useEffect(() => {
        window.addEventListener('click', e => {
            setShowSearchResult(!!searchContainerRef.current?.contains(e.target as Node))
        })
    }, [])


    return <><CommonLayout>
        <div className='container '>
            <h4 className={style.title}>V-Shop {t("Blog")}</h4>
            <h6 className={style.description}>{t("We are a chain of virtual shopping 100+ Individual Entrepreneurs and companies to deliver you the best belongings in town. Our goal is to connect customers to Individual owners and shops virtually and in a more efficient and fairway!")}
            </h6>
        </div>
        <div className=' col-lg-12 col-sm-12 col-md-12 mb-5'>
            <div className={style1.searchContainer} ref={searchContainerRef}>
                <Search
                    onChange={searchChangeHandler}
                    value={searchTerm}
                    onFocus={searchFocusHandler}
                    placeholder={t('Search for Title')}
                />
                {showSearchResult && <SearchResultModal
                    blogItems={searchResults}
                    searchTerm={searchTerm}
                    searching={searching}
                />}
            </div>
        </div>

        <div className="container col-lg-12 col-sm-12 col-md-12">
            <div className="row">
                {blogList.map(blog => {
                    return <div className="p-2 m-2 col-lg-3 col-sm-12 col-md-6 mb-5 vstack gap-1">
                        <div className={style.imagediv}><img src={blog?.imageUrl} alt={'blog name'} className={style.image} /></div>
                        <h5 className={style.blogTitle}>{blog?.title}</h5>
                        <div className={style.blogDescription}>
                            {blog?.description}
                        </div>
                        {/* <div className="row mb-1"> */}
                        <div className="col">
                            <span className={style.blogDate + ' mt-2'}>
                                {date('MON d,Y', blog?.createdAt)}
                            </span> | <span className={style.blogAuthor + ' mt-2'}>{blog?.author}</span>
                        </div>
                        <div className="col">
                            {/* <Link to="/blog-post" className="btn btn-sm btn-primary float-end mt-2 ">Read More</Link> */}
                            <button className="btn btn-sm btn-primary float-start mt-2 " onClick={() => userProvider?.onShowBlogPost?.(blog)}>{t("Read More")}</button>
                        </div>
                        {/* </div> */}
                    </div>
                })}
            </div>
        </div>

    </CommonLayout>
    </>
}

export default Blog;