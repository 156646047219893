import { Dispatch } from 'redux'
import { api, apiErrorHandler, getApiRequestConfig, Response } from '../../api'
import { GetState } from '../reducers'

export enum CountReportsTypes {
    FETCH_COUNT_REPORT = 'FETCH_COUNT_REPORT',
}

export interface CountReport {
    shopCount: string
    userCount: string
    foodOrderCount: string
    productOrderCount: string
    reservationCount: string
    bookingCount: string
}
export interface FetchCountReportsAction {
    type: CountReportsTypes.FETCH_COUNT_REPORT
    data: CountReport
}

export type CountReportActions = FetchCountReportsAction

export const fetchCountReport = () => async (dispatch: Dispatch, getState: GetState) => {
    const authSuperAdmin = getState().authSuperAdmin
    const config = getApiRequestConfig(authSuperAdmin?.['auth-token'])

    return api.get<Response<CountReport>>(`count-reports`, config).then(response => {
        if (response.status === 200) {
            dispatch<CountReportActions>({
                type: CountReportsTypes.FETCH_COUNT_REPORT,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || '')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to fetch'))
    })
}
