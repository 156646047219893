import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useT } from '../../i18n'
import { updatePaytrail } from '../../redux/actions'
import { StoreState } from '../../redux/reducers'
import { AuthAdmin } from '../../redux/reducers/authAdmin.reducer'
import { AppDispatch } from '../../redux/store'
import form from '../../styles/components/form.module.scss'
import { toString } from '../../utils'
import { ToastType, useToast } from '../ToastProvider'
import Button from '../utils/Button'
import FormInput from '../utils/FormInput'
import Radio from '../utils/Radio'
import Switch from '../utils/Switch'

const Paytrail = () => {
    const dispatch = useDispatch<AppDispatch>()
    const toast = useToast()
    const t = useT()

    const authAdmin = useSelector<StoreState, AuthAdmin | null>(state => state.authAdmin)

    const [merchantId, setMerchantId] = React.useState<string>('')
    const [merchantIdError, setMerchantIdError] = React.useState<string>('')
    const [secretKey, setSecretKey] = React.useState<string>('')
    const [secretKeyError, setSecretKeyError] = React.useState<string>('')
    const [commission, setCommission] = React.useState<string>('')
    const [commissionError, setCommissionError] = React.useState<string>('')
    const [paytrailType, setPaytrailType] = React.useState<string>('shopinshop')
    const [paytrailStatus, setPaytrailStatus] = React.useState<boolean>(true)
    const [loading, setLoading] = React.useState<boolean>(false)

    const merchantIdChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setMerchantId(e.target.value)
        setMerchantIdError('')
    }

    const secretKeyChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSecretKey(e.target.value)
        setSecretKeyError('')
    }

    const commissionChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setCommission(e.target.value)
        setCommissionError('')
    }

    const saveClickHandler = () => {
        let error = false

        if (toString(merchantId) === '') {
            setMerchantIdError(t('Merchant ID required'))
            error = true
        }

        if (toString(secretKey) === '' && paytrailType === "normal") {
            setSecretKeyError(t('Secret key required'))
            error = true
        }

        if (toString(commission) === '' && paytrailType === "shopinshop") {
            setCommissionError(t('commission required'))
            error = true
        }

        if (!error) {
            setLoading(true)
            dispatch(updatePaytrail({
                merchantId: toString(merchantId),
                secretKey: toString(secretKey),
                commission: toString(commission),
                paytrailType: toString(paytrailType),
                status:toString(paytrailStatus?'active':'inactive')
            })).then(() => {
                toast(t('Paytrail updated'))
            }).catch(text => {
                toast(text, ToastType.ERROR)
            }).finally(() => {
                setLoading(false)
            })
        }
    }

    React.useEffect(() => {
        setMerchantId(authAdmin?.shop.paytrail.merchantId || '')
        setSecretKey(authAdmin?.shop.paytrail.secretKey || '')
        setCommission(authAdmin?.shop.paytrail.commission || '')
        setPaytrailType(authAdmin?.shop.paytrail.paytrailType || '')
        setPaytrailStatus(authAdmin?.shop.paytrail.status==='active'?true:false)
    }, [authAdmin])

    return <React.Fragment>
        <div className='row'>
            <div className='col-4'>
                <Radio
                    className='mb-4'
                    checked={paytrailType === "shopinshop"}
                    onClick={() => setPaytrailType("shopinshop")}
                >Shop In Shop</Radio>
                <Radio
                    className='mb-4'
                    checked={paytrailType === "normal"}
                    onClick={() => setPaytrailType("normal")}
                >Normal</Radio>
                <Switch
                    onClick={() => setPaytrailStatus(paytrailStatus?false:true)}
                    checked={paytrailStatus}
                    label={`${paytrailStatus?'Active':'Inactive'}`}
                />
            </div>
            <div className='col-8'>
                <FormInput
                    type='text'
                    label={t('Merchant ID')}
                    placeholder={t('Merchant ID')}
                    containerClass='mb-4'
                    uniqueId={2}
                    value={merchantId}
                    onChange={merchantIdChangeHandler}
                    errorText={merchantIdError}
                />
                {paytrailType === "normal" ?
                    <FormInput
                        type='text'
                        label={t('Secret key')}
                        placeholder={t('Secret key')}
                        containerClass='mb-4'
                        value={secretKey}
                        onChange={secretKeyChangeHandler}
                        errorText={secretKeyError}
                    />
                    :
                    <FormInput
                        type='number'
                        label={t('Commission (%)')}
                        containerClass='mb-4'
                        value={commission}
                        onChange={commissionChangeHandler}
                        errorText={commissionError}
                    />
                }
            </div>
            <Button className={form.saveButton} onClick={saveClickHandler} loading={loading}>{t("Save")}</Button>

        </div>

    </React.Fragment>
}

export default Paytrail