import axios, { CancelTokenSource } from 'axios'
import FileDownload from "js-file-download"
import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Response, api } from '../../api'
import assets from '../../assets'
import { API_URL } from '../../config'
import { useT } from '../../i18n'
import { LoginForSuperAdmin, disableShop, enableShop, fetchShops, loginForSuperAdmin } from '../../redux/actions'
import { StoreState } from '../../redux/reducers'
import { AuthAdmin } from '../../redux/reducers/authAdmin.reducer'
import { AuthDivision } from '../../redux/reducers/authDivision.reducer'
import { Division } from '../../redux/reducers/division.reducer'
import { Shop } from '../../redux/reducers/shop.reducer'
import { AppDispatch } from '../../redux/store'
import style from '../../styles/components/shop-card.module.scss'
import style1 from '../../styles/components/user-header.module.scss'
import styleOrder from '../../styles/pages/admin-orders.module.scss'
import { ToastType, useToast } from '../ToastProvider'
import PasswordResetForm from '../forms/PasswordResetForm'
import ModalWithHeader from '../modals/ModalWithHeader'
import PlanInformation from '../modals/Plan'
import ShopMoreDetails from '../modals/ShopMoreDetails'
import ShopPrivate from '../modals/ShopPrivate'
import { SearchResultModal } from '../sections/UserHeader'
import Search from '../utils/Search'
import Select from '../utils/Select'
import Switch from '../utils/Switch'

type ShopMenu = 'FOOD' | 'PRODUCT' | 'EVENT' | 'BOOKING'
export interface ShopCardProps {
    shops?: Shop[]
    onMore?: () => void
    showSearch?: boolean
    from: string
    divisionId?: string
}

type SearchResultType = 'shop'

interface SearchResult {
    type: SearchResultType
    itemId: string
    shopId: string
    shopName: string
    image: string
    name?: string
    businessName?: string
}

const getShopMenu = (shop: Shop): ShopMenu | null => {
    if (shop.category === "Food") return "FOOD";
    if (shop.category === "Product") return "PRODUCT";
    if (shop.category === "Event") return "EVENT";
    if (shop.category === "Booking") return "BOOKING";
    return null
}

const ShopCard = (props: ShopCardProps) => {
    const toast = useToast()
    const t = useT()

    const searchContainerRef = React.useRef<HTMLDivElement>(null)

    const admin = useSelector<StoreState, AuthAdmin | null>(state => state.authAdmin)

    const [shopMenu, setShopMenu] = React.useState<ShopMenu>('FOOD')
    const [shopLists, setShopLists] = React.useState<Shop[] | undefined>([])

    const divisionAdmin = useSelector<StoreState, AuthDivision | null>(state => state.authDivision)

    const isDivisionLogin = divisionAdmin && divisionAdmin['auth-token'] !== ''

    const DivisionList = useSelector<StoreState, Array<Division>>(state => (state.division))


    const [isPasswordReset, setIsPasswordReset] = useState(false);
    const [selectedAdmin, setSelectedAdmin] = useState({ id: '', name: '', username: '' });
    const [selectedDivision, setSelectedDivision] = useState('')
    const [searchTerm, setSearchTerm] = React.useState<string>('')
    const [showSearchResult, setShowSearchResult] = React.useState<boolean>(false)
    const [searching, setSearching] = React.useState<boolean>(false)
    const [searchResults, setSearchResults] = React.useState<SearchResult[]>([])
    const [showShopInformation, setShowShopInformation] = React.useState<boolean>(false)
    const [plan, setPlan] = React.useState<string>('')
    const [shopId, setShopId] = React.useState<string>('')

    const modalCloseHandler = () => {
        setIsPasswordReset(false)
    }

    React.useEffect(() => {
        setShopLists(props.shops);
    }, [])

    const dispatch = useDispatch<AppDispatch>()
    const toggleHandler = (shop: Shop) => {
        if (shop) {
            if (shop.active) {
                dispatch(disableShop(shop.id)).finally(() => {
                    dispatch(fetchShops())
                })
            } else {
                dispatch(enableShop(shop.id)).finally(() => {
                    dispatch(fetchShops())
                })
            }
        }
    }

    const goToShopHanlder = async (shop: Shop) => {
        let adminObject = shop?.admins?.at(0);
        try {
            if (adminObject !== undefined) {
                let username = (adminObject.username !== undefined) ? adminObject.username : ''

                const data: LoginForSuperAdmin = {
                    username: username
                }
                dispatch(loginForSuperAdmin(data))
            }
            if (admin !== null) window.open('/admin', '_blank')

        } catch (exception) {
            toast(t("Invalid shop."));
        }
    }

    const searchChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        if (e.target.value.trim() !== '') {
            setSearching(true)
        } else {
            setSearching(false)
        }

        setSearchTerm(e.target.value)
    }

    const searchFocusHandler = () => {
        setShowSearchResult(true)
    }


    const shopBackupHanlder = (shopName: string, shopId: string, category: string) => {

        axios({
            url: API_URL + "/api/db/backup",
            method: "POST",
            data: { shopId, category },
            responseType: "blob"
        }).then(async (res: any) => {
            FileDownload(res.data, `${shopName} (${category}).zip`)
            toast(t("Backup successfully"), ToastType.SUCCESS)
        });
    }

    React.useEffect(() => {
        let timeout: NodeJS.Timeout | undefined
        let source: CancelTokenSource | undefined

        if (searchTerm.trim() !== '') {
            timeout = setTimeout(() => {
                source?.cancel()
                source = axios.CancelToken.source()
                setSearching(true)
                api.get<Response<SearchResult[]>>(`shopSearch/${shopMenu === 'FOOD' ? 'Food' : (shopMenu === 'PRODUCT' ? 'Product' : (shopMenu === 'BOOKING' ? 'Booking' : 'Event'))}/${searchTerm.trim()}`, { cancelToken: source.token }).then(response => {
                    if (response.status === 200) {
                        setSearchResults(response.data.data)
                    } else {
                        // eslint-disable-next-line no-throw-literal
                        throw { response }
                    }
                    if (props.from === "super") {
                        timeout = setTimeout(() => {
                            source?.cancel()
                            source = axios.CancelToken.source()
                            setSearching(true)
                            api.get<Response<SearchResult[]>>(`shopSearch/${searchTerm.trim()}`, { cancelToken: source.token }).then(response => {
                                if (response.status === 200) {
                                    setSearchResults(response.data.data)
                                } else {
                                    // eslint-disable-next-line no-throw-literal
                                    throw { response }


                                }
                            }).catch(() => { }).finally(() => {
                                setSearching(false)
                            })
                        }, 300)
                    }
                    else if (isDivisionLogin && props.from === "division") {
                        timeout = setTimeout(() => {
                            source?.cancel()
                            source = axios.CancelToken.source()
                            setSearching(true)
                            api.get<Response<SearchResult[]>>(`divisionSearch/${searchTerm.trim()}/${props.divisionId}`, { cancelToken: source.token }).then(response => {
                                if (response.status === 200) {
                                    setSearchResults(response.data.data)
                                } else {
                                    // eslint-disable-next-line no-throw-literal
                                    throw { response }

                                }
                            }).catch(() => { }).finally(() => {
                                setSearching(false)
                            })
                        }, 300)
                    }
                })

            })
        }
        else {
            setSearchResults([])
        }

        return () => {
            clearTimeout(timeout)
            source?.cancel()
        }
    }, [searchTerm])

    React.useEffect(() => {
        window.addEventListener('click', e => {
            setShowSearchResult(!!searchContainerRef.current?.contains(e.target as Node))
        })
    }, [])

    React.useEffect(() => {
        if (props.from === "division") {
            const filteredShopLists = props.shops?.filter((shop) => (selectedDivision ? shop.divisionId === selectedDivision : shop.divisionId === divisionAdmin?.id));
            setShopLists(filteredShopLists);
        }
        else {
            if (selectedDivision)
                setShopLists(props.shops?.filter((shop) => (shop.divisionId === selectedDivision)))
            else
                setShopLists(props.shops)
        }
    }, [props.shops, divisionAdmin, selectedDivision])

    return (
        <>
            {showShopInformation && <PlanInformation planType={plan} onClose={() => setShowShopInformation(false)} showbutton={true} shopId={shopId} />}
            <div className='row'>
                <div className='hstack gap-2 col-sm-12 col-md-8 col-lg-8'>
                    <ShopMenuLink active={shopMenu === 'FOOD'} onClick={() => setShopMenu('FOOD')}>{t("Food")}</ShopMenuLink>
                    <ShopMenuLink active={shopMenu === 'PRODUCT'} onClick={() => setShopMenu('PRODUCT')}>{t("Product")}</ShopMenuLink>
                    <ShopMenuLink active={shopMenu === 'EVENT'} onClick={() => setShopMenu('EVENT')}>{t("Event")}</ShopMenuLink>
                    <ShopMenuLink active={shopMenu === 'BOOKING'} onClick={() => setShopMenu('BOOKING')}>{t("Booking")}</ShopMenuLink>
                </div>
                <div className='col-sm-12 col-md-4 col-lg-4'>
                    {props.from === "super" && <Select
                        containerClass='mb-2 mt-2'
                        value={selectedDivision}
                        placeholder={t('Select Division')}
                        options={[{ text: t("All Shops"), value: "" }, ...DivisionList.map((division) => { return { text: division.name, value: division.id } })]}
                        onChange={(e) => setSelectedDivision(e.target.value)}
                    />}

                </div>
            </div>
            <div className='d-xl-block mb-5 mt-3'>
                {!props.showSearch && <div className={style1.searchContainer} ref={searchContainerRef}>
                    <Search
                        onChange={searchChangeHandler}
                        value={searchTerm}
                        onFocus={searchFocusHandler}
                        placeholder={t("Search for") + ` ${shopMenu === 'FOOD' ? t('Food') : (shopMenu === 'PRODUCT' ? t('Product') : (shopMenu === 'BOOKING' ? t('Booking') : t('Event')))} ` + t("shops")}
                    />
                    {showSearchResult && <SearchResultModal
                        superAdminItems={searchResults}
                        searchTerm={searchTerm}
                        searching={searching}
                    // superAdminSearch='super'
                    />}
                </div>}
            </div>

            <div className='table-wrapper'>
                <table className='table'>
                    <thead className="table-primary">
                        <tr>
                            {props.from === "super" && <th className='text-truncate align-middle'>{t("Division")}</th>}
                            <th className='text-truncate align-middle'>{t("Shop Name")}</th>
                            <th className='text-truncate align-middle'>{t("Username")}</th>
                            <th className='text-truncate align-middle'>{t("Banner Image")}</th>
                            <th className='text-truncate align-middle'>{t("Status")}</th>
                            <th className='text-truncate align-middle'>{t("Featured")}</th>
                            <th className='text-truncate align-middle'>{t("Private")}</th>
                            <th className='text-truncate align-middle'>{t("Admin Password")}</th>
                            <th className='text-truncate align-middle'>{("Plan")}</th>
                            {shopMenu === "FOOD" && <th className='text-truncate align-middle'>{t("Chef Password")}</th>}
                            <th className='text-truncate align-middle'>{t("Backup")}</th>
                        </tr>
                    </thead>
                    <tbody >
                        {
                            isDivisionLogin && props.from === "division"
                                ? shopLists && shopLists.filter((shop) => getShopMenu(shop) === shopMenu)?.map(s => {
                                    let searchTable = s.name.toLowerCase().includes(searchTerm.toLowerCase())
                                    return searchTable ? < tr >
                                        <td className='align-middle text-truncate text-capitalize fw-bold' style={{ fontSize: "14px" }}><div onClick={() => goToShopHanlder(s!)}>{s?.name}</div></td>
                                        <td className='align-middle fw-bold' style={{ fontSize: "14px" }}> {s.admins && s.admins[0] ? s.admins[0].username : ''}</td>
                                        <td className='align-middle'><img src={s?.theme?.bannerImage || assets.images.registerToSellAtVshop} alt={'shop name'} className={style.bannerImage} /></td>
                                        <td className='align-middle'> <Switch
                                            onClick={() => toggleHandler(s)}
                                            checked={s?.active}
                                        /></td>
                                        <td className='align-middle '>
                                            <div>
                                                <ShopMoreDetails
                                                    id={s.id !== undefined ? s.id : null} />
                                            </div>
                                        </td>
                                        <td className='align-middle '>
                                            <div>
                                                <ShopPrivate
                                                    id={s.id !== undefined ? s.id : null} />
                                            </div>
                                        </td>
                                        <td className='align-middle'>
                                            {
                                                s?.admins?.filter(admin => !admin.username.includes('_support') && !admin.username.includes('_chef')).map(admin =>
                                                    <div >
                                                        <Button className=' btn-info text-white btn-sm' onClick={() => { setSelectedAdmin(admin); setIsPasswordReset(true) }}>{t("Reset Password")}</Button>
                                                    </div>
                                                )
                                            }
                                        </td>
                                        <td className='align-middle'>
                                            {
                                                s?.admins?.filter(admin => admin.username.includes('_chef')).map(admin =>
                                                    <div >
                                                        <Button className='btn btn-info text-white btn-sm' onClick={() => { setSelectedAdmin(admin); setIsPasswordReset(true) }}>{t("Reset Password")}</Button>
                                                    </div>
                                                )
                                            }
                                        </td>
                                        <td className='align-middle'><Button className='btn-info text-white btn-sm' onClick={() => shopBackupHanlder(s?.name, s.id, s.category)}>{t("Backup")}</Button></td>
                                    </tr> : <></>
                                })
                                :
                                shopLists && shopLists.filter((shop) => getShopMenu(shop) === shopMenu).map(s => {
                                    let divisionName = DivisionList.find(d => d.id === s.divisionId)?.name
                                    let searchTable = s.name.toLowerCase().includes(searchTerm.toLowerCase())

                                    return searchTable ? < tr >
                                        <td className='align-middle text-truncate text-capitalize fw-bold' style={{ fontSize: "14px" }}>{divisionName}</td>
                                        <td className={`${style.gotoshop} align-middle text-truncate text-capitalize fw-bold `} style={{ fontSize: "14px" }}><div onClick={() => goToShopHanlder(s!)}>{s?.name}</div></td>
                                        <td className='align-middle fw-bold' style={{ fontSize: "14px" }}> {s.admins && s.admins[0] ? s.admins[0].username : ''}</td>
                                        <td className='align-middle'><img src={s?.theme?.bannerImage || assets.images.registerToSellAtVshop} alt={'shop name'} className={style.bannerImage} /></td>
                                        <td className='align-middle'> <Switch
                                            onClick={() => toggleHandler(s)}
                                            checked={s?.active}
                                        /></td>
                                        <td className='align-middle '>
                                            <div>
                                                <ShopMoreDetails
                                                    id={s.id !== undefined ? s.id : null} />
                                            </div>
                                        </td>
                                        <td className='align-middle '>
                                            <div>
                                                <ShopPrivate
                                                    id={s.id !== undefined ? s.id : null} />
                                            </div>
                                        </td>

                                        <td className='align-middle'>
                                            {
                                                s?.admins?.filter(admin => !admin.username.includes('_support') && !admin.username.includes('_chef')).map(admin =>
                                                    <div >
                                                        <Button className='btn btn-info text-white btn-sm' onClick={() => { setSelectedAdmin(admin); setIsPasswordReset(true) }}>{t("Reset Password")}</Button>
                                                    </div>
                                                )
                                            }
                                        </td>
                                        <td className={`${style.gotoshop} align-middle fw-bold`} style={{ fontSize: "14px" }}>
                                            <div onClick={() => {
                                                setShowShopInformation(true)
                                                setPlan(s?.plan!)
                                                setShopId(s?.id)
                                            }}>{s?.plan}</div>
                                        </td>
                                        {shopMenu === "FOOD" && <td className='align-middle'>
                                            {
                                                s?.admins?.filter(admin => admin.username.includes('_chef')).map(admin =>
                                                    <div >
                                                        <Button className='btn btn-info text-white btn-sm' onClick={() => { setSelectedAdmin(admin); setIsPasswordReset(true) }}>{t("Reset Password")}</Button>
                                                    </div>
                                                )
                                            }
                                        </td>}

                                        <td className='align-middle'><Button className='btn-info text-white btn-sm' onClick={() => shopBackupHanlder(s?.name, s.id, s.category)}>{t("Backup")}</Button></td>
                                    </tr> : <></>
                                })

                            //         <th className='text-truncate align-middle'>Division</th>
                            //         <th className='text-truncate align-middle'>Shop Name</th>
                            //         <th className='text-truncate align-middle'>Banner Image</th>
                            //         <th className='text-truncate text-center align-middle'>Status</th>
                            //         <th className='text-truncate text-center align-middle'>Featured</th>
                            //         <th className='align-middle'>Admin Password</th>
                            //         <th className='align-middle'>Chef Password</th>
                            //     </tr>
                            // </thead>
                            // <tbody>
                            //     {isDivisionLogin && props.from === "division"
                            //         ? props.shops?.filter(d => d.divisionId === divisionAdmin.id)?.map(s => {
                            //             let divisionName = DivisionList.find(d => d.id === s.divisionId)?.name
                            //             return < tr >
                            //                 <td className='align-middle text-truncate text-capitalize'>{divisionName}</td>
                            //                 <td className='align-middle text-truncate text-capitalize'><div onClick={() => goToShopHanlder(s!)}>{s?.name}</div></td>
                            //                 <td className='align-middle'><img src={s?.theme?.bannerImage || assets.images.registerToSellAtVshop} alt={'shop name'} className={style.bannerImage} /></td>
                            //                 <td className='align-middle'> <Switch
                            //                     onClick={() => toggleHandler(s)}
                            //                     checked={s?.active}
                            //                 /></td>
                            //                 <td className='align-middle '>
                            //                     <div>
                            //                         <ShopMoreDetails
                            //                             id={s.id !== undefined ? s.id : null} />
                            //                     </div>
                            //                 </td>
                            //                 <td className='align-middle'>
                            //                     {
                            //                         s?.admins?.filter(admin => !admin.username.includes('_support') && !admin.username.includes('_chef')).map(admin =>
                            //                             <div >
                            //                                 <Button className=' btn-info text-white btn-sm' onClick={() => { setSelectedAdmin(admin); setIsPasswordReset(true) }}>Reset Password</Button>
                            //                             </div>
                            //                         )
                            //                     }
                            //                 </td>
                            //                 <td className='align-middle'>
                            //                     {
                            //                         s?.admins?.filter(admin => admin.username.includes('_chef')).map(admin =>
                            //                             <div >
                            //                                 <Button className='btn btn-info text-white btn-sm' onClick={() => { setSelectedAdmin(admin); setIsPasswordReset(true) }}>Reset Password</Button>
                            //                             </div>
                            //                         )
                            //                     }
                            //                 </td>

                            //             </tr>
                            //         })
                            //         : props.shops?.map(s => {
                            //             let divisionName = DivisionList.find(d => d.id === s.divisionId)?.name
                            //             return < tr >
                            //                 <td className='align-middle text-truncate text-capitalize'>{divisionName}</td>
                            //                 <td className='align-middle text-truncate text-capitalize'><div onClick={() => goToShopHanlder(s!)}>{s?.name}</div></td>
                            //                 <td className='align-middle'><img src={s?.theme?.bannerImage || assets.images.registerToSellAtVshop} alt={'shop name'} className={style.bannerImage} /></td>
                            //                 <td className='align-middle'> <Switch
                            //                     onClick={() => toggleHandler(s)}
                            //                     checked={s?.active}
                            //                 /></td>
                            //                 <td className='align-middle '>
                            //                     <div>
                            //                         <ShopMoreDetails
                            //                             id={s.id !== undefined ? s.id : null} />
                            //                     </div>
                            //                 </td>
                            //                 <td className='align-middle'>
                            //                     {
                            //                         s?.admins?.filter(admin => !admin.username.includes('_support') && !admin.username.includes('_chef')).map(admin =>
                            //                             <div >
                            //                                 <Button className=' btn-info text-white btn-sm' onClick={() => { setSelectedAdmin(admin); setIsPasswordReset(true) }}>Reset Password</Button>
                            //                             </div>
                            //                         )
                            //                     }
                            //                 </td>
                            //                 <td className='align-middle'>
                            //                     {
                            //                         s?.admins?.filter(admin => admin.username.includes('_chef')).map(admin =>
                            //                             <div >
                            //                                 <Button className='btn btn-info text-white btn-sm' onClick={() => { setSelectedAdmin(admin); setIsPasswordReset(true) }}>Reset Password</Button>
                            //                             </div>
                            //                         )
                            //                     }
                            //                 </td>

                            //             </tr>
                            //         })
                        }
                    </tbody>
                </table>
                {
                    isPasswordReset && <ModalWithHeader onClose={modalCloseHandler} headerText={`Password Reset - ${selectedAdmin.name}`} small={true}>
                        <PasswordResetForm endPoint='admin/set/password' afterSave={modalCloseHandler} admin={selectedAdmin} />
                    </ModalWithHeader>
                }
            </div >
        </>
    )
}

interface MenuLinkProps {
    children?: React.ReactNode
    active?: boolean
    onClick?: () => void
}

const ShopMenuLink = (props: MenuLinkProps) => {
    return <div className={`${styleOrder.orderMenuLink} ${props.active ? styleOrder.active : ''}`} children={props.children} onClick={props.onClick} />
}
export default ShopCard

// <div className="row border rounded m-1 h-100 px-1 pt-1 pb-2">
//     <div className="col sm-12">
//         <h5 className='mt-2 text-capitalize'>{props.shop?.name}</h5>
//         <img src={shopImage} alt={'shop name'} className={style.bannerImage} />
//         <div className='d-flex justify-content-around hstack gap-2 mt-0'>
//             <div>
//                 <button className={'btn btn-primary'} onClick={() => goToShopHanlder(props.shop!)}>Go to Shop</button>
//             </div>
//             <div >
//                 <Switch
//                     onClick={toggleHandler}
//                     checked={shopIsActive}
//                     label={shopIsActive ? 'Active' : 'Inactive'}
//                 />
//             </div>
//             <div>
//                 <ShopMoreDetails
//                     id={props.shop?.id !== undefined ? props.shop?.id : null} />
//             </div>
//         </div>
//     </div>
//     <div className="col-6">
//         <div className="row">
//             {
//                 props.shop?.admins?.filter(admin => !admin.username.includes('_support')).map(admin =>
//                     <div className="col-12">
//                         <div className='border rounded card m-2 p-2'>
//                             <p>Name: {admin.name}</p>
//                             <p>LoginID: {admin.username}</p>
//                             <button className='btn btn-primary' onClick={() => { setSelectedAdmin(admin); setIsPasswordReset(true) }}>Reset Password</button>
//                         </div>
//                     </div>
//                 )
//             }
//         </div>
//     </div>
//     {
//         isPasswordReset && <ModalWithHeader onClose={modalCloseHandler} headerText={`Password Reset - ${selectedAdmin.name}`} small={true}>
//             <PasswordResetForm endPoint='admin/set/password' afterSave={modalCloseHandler} admin={selectedAdmin} />
//         </ModalWithHeader>
//     }
// </div>