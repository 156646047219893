import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import assets from '../../assets'
import { CheckoutContext, CheckoutContextType } from '../../context/CheckoutProvider'
import { useT } from '../../i18n'
import { StoreState } from '../../redux/reducers'
import { AuthAdmin } from '../../redux/reducers/authAdmin.reducer'
import { CartItem } from '../../redux/reducers/cart.reducer'
import { EventCart } from '../../redux/reducers/eventCart.reducer'
import { FoodType } from '../../redux/reducers/food.reducer'
import { OtherPayments, Shop } from '../../redux/reducers/shop.reducer'
import style from '../../styles/components/checkout-payment-section.module.scss'
import { ToastType, useToast } from '../ToastProvider'
import Button from '../utils/Button'
import FileUpload from '../utils/FileUpload'
import Radio from '../utils/Radio'
import CheckoutEventRules from './CheckoutEventRules'
import SectionWithTitle from './SectionWithTitle'

interface Props {
    otherPayments: OtherPayments[] | null | undefined
    countinueHandler: React.MouseEventHandler<HTMLButtonElement> | undefined
    eventType?: string
    category: string
    paytrailStatus: string | null | undefined
}

const CheckoutPaymentSection: React.FC<Props> = (props) => {
    const t = useT()
    const toast = useToast()

    const eventCart = useSelector<StoreState, EventCart>((state) => state.eventCart);
    const checkoutContext = React.useContext<CheckoutContextType | null>(CheckoutContext)
    const authAdmin = useSelector<StoreState, AuthAdmin | null>(state => state.authAdmin)
    const [width, setWidth] = React.useState<number>(window.innerWidth);
    const [selectedOtherPayment, setSelectedOtherPayment] = useState<string | null>(null); // Track selected payment
    const [uploadedFile, setUploadedFile] = useState<File | null>(null); // Store uploaded file

    const cartItems = useSelector<StoreState, CartItem[]>(state => state.cart.items)
    const cartShop = useSelector<StoreState, Shop | null>(state => state.shops.find(s => s.id === state.cart.shopId) || null)

    const showPaymentMethod = useMemo<boolean>(() => {
        if (cartShop?.category === "Product") {
            const productTypes: FoodType[] = []
            cartItems.forEach((ci) => {
                productTypes.push(ci.type!)
            })
            const type: string[] = []
            productTypes.forEach((e) => {
                if (e === "custom") {
                    type.push("true")
                } else {
                    type.push("false")
                }
            })
            return type.includes("false")
        }
        else return true
    }, [cartItems])

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 600;

    React.useEffect(() => {
        if (props.eventType === "free")
            checkoutContext?.paymentModeChangeHandler?.('free')
    }, [props.eventType])

    React.useEffect(() => {
        if (!showPaymentMethod)
            checkoutContext?.paymentModeChangeHandler?.('free')
    }, [showPaymentMethod])

    const handlePaymentSelect = (paymentTitle: string) => {
        setSelectedOtherPayment(paymentTitle);
        checkoutContext?.paymentModeChangeHandler?.(paymentTitle);
    }

    const UploadImageHandler = (file: File | null) => {
        checkoutContext?.setTransactionImage?.(file)
        setUploadedFile(file)
    }

    // const handleClickContinue = () => {
    //     let imgNeeded = false
    //     if (checkoutContext?.paymentMode !== 'paytrail') {
    //         let obj = props.otherPayments?.find(op => op.title === checkoutContext?.paymentMode)
    //         if (obj) {
    //             imgNeeded = obj.transactionNeeded ? obj.transactionNeeded : false
    //         }
    //     }
    //     console.log(imgNeeded);
    //     console.log(checkoutContext?.transactionImage);

    //     if (!imgNeeded) {
    //         const event = {
    //             preventDefault: () => { },
    //             stopPropagation: () => { },
    //             currentTarget: document.createElement('button'),
    //             target: document.createElement('button'),
    //         } as unknown as React.MouseEvent<HTMLButtonElement>;
    //         props?.countinueHandler!(event as React.MouseEvent<HTMLButtonElement>);
    //     } else if (imgNeeded && uploadedFile) {
    //         const event = {
    //             preventDefault: () => { },
    //             stopPropagation: () => { },
    //             currentTarget: document.createElement('button'),
    //             target: document.createElement('button'),
    //         } as unknown as React.MouseEvent<HTMLButtonElement>;
    //         props?.countinueHandler!(event as React.MouseEvent<HTMLButtonElement>);
    //     } else if (imgNeeded && (checkoutContext?.transactionImage === null || checkoutContext?.transactionImage === undefined)) {
    //         toast("Transaction receipt needed", ToastType.ERROR)
    //     }
    // }
    console.log(checkoutContext?.cultureVoucher);

    const handleClickContinue = () => {
        let imgNeeded = false;

        // Check if the selected payment method is not Paytrail
        if (checkoutContext?.paymentMode !== 'paytrail') {
            const selectedPayment = props.otherPayments?.find(op => op.title === checkoutContext?.paymentMode);
            if (selectedPayment) {
                // Only set imgNeeded to true if the selected payment method requires a transaction receipt
                imgNeeded = selectedPayment.transactionNeeded || false;
            }
        }

        // If Paytrail is selected, continue without validation
        if (checkoutContext?.paymentMode === 'paytrail') {
            const event = {
                preventDefault: () => { },
                stopPropagation: () => { },
                currentTarget: document.createElement('button'),
                target: document.createElement('button'),
            } as unknown as React.MouseEvent<HTMLButtonElement>;
            props?.countinueHandler!(event as React.MouseEvent<HTMLButtonElement>);
        }
        // Proceed if image is not needed or image is uploaded or culture voucher is 'yes'
        else if (!imgNeeded || uploadedFile || checkoutContext?.cultureVoucher === "yes") {
            const event = {
                preventDefault: () => { },
                stopPropagation: () => { },
                currentTarget: document.createElement('button'),
                target: document.createElement('button'),
            } as unknown as React.MouseEvent<HTMLButtonElement>;
            props?.countinueHandler!(event as React.MouseEvent<HTMLButtonElement>);
        }

        // Show error if image is required but not uploaded
        else if (imgNeeded &&
            (checkoutContext?.paymentMode !== 'free') &&
            (checkoutContext?.cultureVoucher !== "yes") &&
            (checkoutContext?.transactionImage === null || checkoutContext?.transactionImage === undefined)) {
            toast("Transaction receipt needed", ToastType.ERROR);
        }
    };

    return (
        <SectionWithTitle
            title={`${(props?.eventType === "free" || checkoutContext?.cultureVoucher !== "no" || !showPaymentMethod) ? '' : t('Choose payment method')}`}
            className='mb-4'>

            {checkoutContext?.paymentModeError &&
                <span className={style.requiredLabel}>( {checkoutContext?.paymentModeError} )</span>}

            {showPaymentMethod && props.paytrailStatus === "active" &&
                (authAdmin?.shop?.plan === 'Premium' || authAdmin?.shop?.plan === 'Standard' || authAdmin?.shop?.plan?.toString() === '' || authAdmin?.shop?.plan === undefined) &&
                <div>
                    <Radio
                        className={`${checkoutContext?.paymentMode === 'paytrail' ? 'mb-1' : 'mb-4'}`}
                        labelClassName={style.label}
                        checked={checkoutContext?.paymentMode === 'paytrail'}
                        onClick={() => checkoutContext?.paymentModeChangeHandler?.('paytrail')}
                    >Paytrail</Radio>
                    {isMobile ? (
                        checkoutContext?.paymentMode === 'paytrail' &&
                        <img src={assets.images.paytrailMobile} alt='paytrail' className={style.paytrailMobile} />
                    ) : (
                        checkoutContext?.paymentMode === 'paytrail' &&
                        <img src={assets.images.paytrailWeb} alt='paytrail' className={!props.eventType ? style.paytrailFoodWeb : style.paytrailEventWeb} />
                    )}
                </div>
            }

            {showPaymentMethod && (props?.otherPayments && (props?.eventType !== "free" && checkoutContext?.cultureVoucher === "no")) &&
                props?.otherPayments.map((otherPayment: OtherPayments) => (
                    <div key={otherPayment.title}>
                        <Radio
                            className='mb-4'
                            labelClassName={style.label}
                            checked={checkoutContext?.paymentMode === otherPayment.title}
                            onClick={() => handlePaymentSelect(otherPayment.title)}
                        >
                            {otherPayment.title} : {otherPayment.detail}
                        </Radio>

                        {/* Conditionally render file upload option when this radio button is selected */}
                        {checkoutContext?.paymentMode === otherPayment.title && otherPayment.transactionNeeded === true && (
                            <FileUpload onFileSelect={(file) => UploadImageHandler(file)} />
                        )}
                    </div>
                ))
            }

            {eventCart.item?.eventRule && eventCart.item?.eventRule.length > 10 && props.category === "Event" &&
                <CheckoutEventRules />
            }

            <Button loading={checkoutContext?.loading} onClick={handleClickContinue} className={style.button}>{t("Continue")}</Button>
        </SectionWithTitle>
    )
}

export default CheckoutPaymentSection;