import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useT } from '../../i18n'
import { updateDeliveryChargeAndDistance } from '../../redux/actions'
import { StoreState } from '../../redux/reducers'
import { AuthAdmin } from '../../redux/reducers/authAdmin.reducer'
import { AppDispatch } from '../../redux/store'
import style from '../../styles/components/form.module.scss'
import { isPositiveFloat, toNumber, toString } from '../../utils'
import { ToastType, useToast } from '../ToastProvider'
import Button from '../utils/Button'
import FormInput from '../utils/FormInput'

const DeliveryChargeAndDistance = () => {
    const dispatch = useDispatch<AppDispatch>()
    const toast = useToast()
    const t = useT()

    const authAdmin = useSelector<StoreState, AuthAdmin | null>(state => state.authAdmin)

    const [deliveryDistanceTo, setDeliveryDistanceTo] = React.useState<string>('')
    const [deliveryDistanceToError, setDeliveryDistanceToError] = React.useState<string>('')

    const [deliveryDistanceFrom, setDeliveryDistanceFrom] = React.useState<string>('')
    const [deliveryDistanceFromError, setDeliveryDistanceFromError] = React.useState<string>('')

    const [orderValueForFreeDeliveryAboveGivenDistance, setOrderValueForFreeDeliveryAboveGivenDistance] = React.useState<string>('')
    const [orderValueForFreeDeliveryAboveGivenDistanceError, setOrderValueForFreeDeliveryAboveGivenDistanceError] = React.useState<string>('')

    const [orderValueForFreeDeliveryUpToGivenDistance, setOrderValueForFreeDeliveryUpToGivenDistance] = React.useState<string>('')
    const [orderValueForFreeDeliveryUpToGivenDistanceError, setOrderValueForFreeDeliveryUpToGivenDistanceError] = React.useState<string>('')

    const [deliveryChargeUpToGivenDistance, setDeliveryChargeUpToGivenDistance] = React.useState<string>('')
    const [deliveryChargeUpToGivenDistanceError, setDeliveryChargeUpToGivenDistanceError] = React.useState<string>('')

    const [deliveryChargeForEveryKmAboveGivenDistance, setDeliveryChargeForEveryKmAboveGivenDistance] = React.useState<string>('')
    const [deliveryChargeForEveryKmAboveGivenDistanceError, setDeliveryChargeForEveryKmAboveGivenDistanceError] = React.useState<string>('')

    const [lateNightDeliveryChargeForEveryKm, setLateNightDeliveryChargeForEveryKm] = React.useState<string>('')
    const [lateNightDeliveryChargeForEveryKmError, setLateNightDeliveryChargeForEveryKmError] = React.useState<string>('')

    const [lateNightDeliveryFrom, setLateNightDeliveryFrom] = React.useState<string>('')
    const [lateNightDeliveryFromError, setLateNightDeliveryFromError] = React.useState<string>('')

    const [lateNightDeliveryTo, setLateNightDeliveryTo] = React.useState<string>('')
    const [lateNightDeliveryToError, setLateNightDeliveryToError] = React.useState<string>('')

    const [loading, setLoading] = React.useState<boolean>(false)

    const disabled = !authAdmin?.shop.delivery.enabled

    const deliveryDistanceToChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setDeliveryDistanceTo(e.target.value)
        setDeliveryDistanceToError('')
    }

    const deliveryDistanceFromChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setDeliveryDistanceFrom(e.target.value)
        setDeliveryDistanceFromError('')
    }

    const orderValueForFreeDeliveryAboveGivenDistanceChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setOrderValueForFreeDeliveryAboveGivenDistance(e.target.value)
        setOrderValueForFreeDeliveryAboveGivenDistanceError('')
    }

    const orderValueForFreeDeliveryUpToGivenDistanceChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setOrderValueForFreeDeliveryUpToGivenDistance(e.target.value)
        setOrderValueForFreeDeliveryUpToGivenDistanceError('')
    }

    const deliveryChargeUpToGivenDistanceChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setDeliveryChargeUpToGivenDistance(e.target.value)
        setDeliveryChargeUpToGivenDistanceError('')
    }

    const deliveryChargeForEveryKmAboveGivenDistanceChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setDeliveryChargeForEveryKmAboveGivenDistance(e.target.value)
        setDeliveryChargeForEveryKmAboveGivenDistanceError('')
    }

    const lateNightDeliveryChargeForEveryKmChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setLateNightDeliveryChargeForEveryKm(e.target.value)
        setLateNightDeliveryChargeForEveryKmError('')
    }

    const lateNightDeliveryFromChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setLateNightDeliveryFrom(e.target.value)
        setLateNightDeliveryFromError('')
    }

    const lateNightDeliveryToChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setLateNightDeliveryTo(e.target.value)
        setLateNightDeliveryToError('')
    }

    const saveClickHandler = () => {
        let error = false

        if (toString(deliveryDistanceTo) === '') {
            setDeliveryDistanceToError(t('Delivery distance required'))
            error = true
        } else if (!isPositiveFloat()(toNumber(deliveryDistanceTo))) {
            setDeliveryDistanceToError(t('Invalid delivery distance'))
            error = true
        }

        if (toString(deliveryDistanceFrom) === '') {
            setDeliveryDistanceFromError(t('Delivery distance required'))
            error = true
        } else if (!isPositiveFloat()(toNumber(deliveryDistanceFrom))) {
            setDeliveryDistanceFromError(t('Invalid delivery distance'))
            error = true
        }

        if (toString(orderValueForFreeDeliveryAboveGivenDistance) === '') {
            setOrderValueForFreeDeliveryAboveGivenDistanceError(t('Order value required'))
            error = true
        } else if (!isPositiveFloat()(toNumber(orderValueForFreeDeliveryAboveGivenDistance))) {
            setOrderValueForFreeDeliveryAboveGivenDistanceError(t('Invalid order value'))
            error = true
        }

        if (toString(orderValueForFreeDeliveryUpToGivenDistance) === '') {
            setOrderValueForFreeDeliveryUpToGivenDistanceError(t('Order value required'))
            error = true
        } else if (!isPositiveFloat()(toNumber(orderValueForFreeDeliveryUpToGivenDistance))) {
            setOrderValueForFreeDeliveryUpToGivenDistanceError(t('Invalid order value'))
            error = true
        }

        if (toString(deliveryChargeUpToGivenDistance) === '') {
            setDeliveryChargeUpToGivenDistanceError(t('Delivery charge required'))
            error = true
        } else if (!isPositiveFloat()(toNumber(deliveryChargeUpToGivenDistance))) {
            setDeliveryChargeUpToGivenDistanceError(t('Invalid delivery charge'))
            error = true
        }

        if (toString(deliveryChargeForEveryKmAboveGivenDistance) === '') {
            setDeliveryChargeForEveryKmAboveGivenDistanceError(t('Delivery charge required'))
            error = true
        } else if (!isPositiveFloat()(toNumber(deliveryChargeForEveryKmAboveGivenDistance))) {
            setDeliveryChargeForEveryKmAboveGivenDistanceError(t('Invalid delivery charge'))
            error = true
        }

        if (toString(lateNightDeliveryChargeForEveryKm) === '') {
            setLateNightDeliveryChargeForEveryKmError(t('Delivery charge required'))
            error = true
        } else if (!isPositiveFloat()(toNumber(lateNightDeliveryChargeForEveryKm))) {
            setLateNightDeliveryChargeForEveryKmError(t('Invalid delivery charge'))
            error = true
        }

        if (toString(lateNightDeliveryFrom) === '') {
            setLateNightDeliveryFromError(t('Late night delivery from required'))
            error = true
        }

        if (toString(lateNightDeliveryTo) === '') {
            setLateNightDeliveryToError(t('Late night delivery to required'))
            error = true
        }

        if (!error) {
            setLoading(true)
            dispatch(updateDeliveryChargeAndDistance({
                deliveryChargeForEveryKmAboveGivenDistance: toNumber(deliveryChargeForEveryKmAboveGivenDistance),
                deliveryChargeUpToGivenDistance: toNumber(deliveryChargeUpToGivenDistance),
                deliveryDistanceFrom: toNumber(deliveryDistanceFrom),
                deliveryDistanceTo: toNumber(deliveryDistanceTo),
                lateNightDeliveryChargeForEveryKm: toNumber(lateNightDeliveryChargeForEveryKm),
                lateNightDeliveryFrom: toString(lateNightDeliveryFrom),
                lateNightDeliveryTo: toString(lateNightDeliveryTo),
                orderValueForFreeDeliveryAboveGivenDistance: toNumber(orderValueForFreeDeliveryAboveGivenDistance),
                orderValueForFreeDeliveryUpToGivenDistance: toNumber(orderValueForFreeDeliveryUpToGivenDistance)
            })).then(() => {
                toast(t('Delivery charge and distance updated'))
            }).catch(text => {
                toast(text, ToastType.ERROR)
            }).finally(() => {
                setLoading(false)
            })
        }
    }

    React.useEffect(() => {
        setDeliveryDistanceTo(authAdmin?.shop.delivery.distance.to.toString() || '')
        setDeliveryDistanceFrom(authAdmin?.shop.delivery.distance.from.toString() || '')
        setOrderValueForFreeDeliveryAboveGivenDistance(authAdmin?.shop.delivery.orderValueForFreeDelivery.aboveGivenDistance.toString() || '')
        setOrderValueForFreeDeliveryUpToGivenDistance(authAdmin?.shop.delivery.orderValueForFreeDelivery.upToGivenDistance.toString() || '')
        setDeliveryChargeUpToGivenDistance(authAdmin?.shop.delivery.charge.upToGivenDistance.toString() || '')
        setDeliveryChargeForEveryKmAboveGivenDistance(authAdmin?.shop.delivery.charge.forEveryKmAboveGivenDistance.toString() || '')
        setLateNightDeliveryChargeForEveryKm(authAdmin?.shop.delivery.lateNightDelivery.chargeForEveryKm.toString() || '')
        setLateNightDeliveryFrom(authAdmin?.shop.delivery.lateNightDelivery.timing.from || '')
        setLateNightDeliveryTo(authAdmin?.shop.delivery.lateNightDelivery.timing.to || '')
    }, [authAdmin])

    return <React.Fragment>
        <div className='col-md-6 col-lg-4'>
            <FormInput
                type='number'
                label={t('Delivery distance from')}
                placeholder={t('Delivery distance')}
                containerClass='mb-4'
                value={deliveryDistanceFrom}
                onChange={deliveryDistanceFromChangeHandler}
                errorText={deliveryDistanceFromError}
                disabled={disabled}
            />
        </div>
        <div className='col-md-6 col-lg-4'>
            <FormInput
                type='number'
                label={t('Delivery distance to')}
                placeholder={t('Delivery distance')}
                containerClass='mb-4'
                value={deliveryDistanceTo}
                onChange={deliveryDistanceToChangeHandler}
                errorText={deliveryDistanceToError}
                disabled={disabled}
            />
        </div>
        <div className='col-md-6 col-lg-4'>
            <FormInput
                type='number'
                label={t('Order value for free delivery up to given distance')}
                placeholder={t('Order value')}
                containerClass='mb-4'
                value={orderValueForFreeDeliveryUpToGivenDistance}
                onChange={orderValueForFreeDeliveryUpToGivenDistanceChangeHandler}
                errorText={orderValueForFreeDeliveryUpToGivenDistanceError}
                disabled={disabled}
            />
        </div>
        <div className='col-md-6 col-lg-4'>
            <FormInput
                type='number'
                label={t('Order value for free delivery above given distance')}
                placeholder={t('Order value')}
                containerClass='mb-4'
                value={orderValueForFreeDeliveryAboveGivenDistance}
                onChange={orderValueForFreeDeliveryAboveGivenDistanceChangeHandler}
                errorText={orderValueForFreeDeliveryAboveGivenDistanceError}
                disabled={disabled}
            />
        </div>
        <div className='col-md-6 col-lg-4'>
            <FormInput
                type='number'
                label={t('Delivery charge up to given delivery distance')}
                placeholder={t('Delivery Charge')}
                containerClass='mb-4'
                value={deliveryChargeUpToGivenDistance}
                onChange={deliveryChargeUpToGivenDistanceChangeHandler}
                errorText={deliveryChargeUpToGivenDistanceError}
                disabled={disabled}
            />
        </div>
        <div className='col-md-6 col-lg-4'>
            <FormInput
                type='number'
                label={t('Delivery charge for every km above given distance')}
                placeholder={t('Delivery Charge')}
                containerClass='mb-4'
                value={deliveryChargeForEveryKmAboveGivenDistance}
                onChange={deliveryChargeForEveryKmAboveGivenDistanceChangeHandler}
                errorText={deliveryChargeForEveryKmAboveGivenDistanceError}
                disabled={disabled}
            />
        </div>
        <div className='col-md-6 col-lg-4'>
            <FormInput
                type='number'
                label={t('Late night delivery charge for every km')}
                placeholder={t('Delivery Charge')}
                containerClass='mb-4'
                value={lateNightDeliveryChargeForEveryKm}
                onChange={lateNightDeliveryChargeForEveryKmChangeHandler}
                errorText={lateNightDeliveryChargeForEveryKmError}
                disabled={disabled}
            />
        </div>
        <div className='col-md-6 col-lg-4'>
            <FormInput
                type='time'
                label={t('Late night delivery from')}
                placeholder={t('Delivery from')}
                containerClass='mb-4'
                value={lateNightDeliveryFrom}
                onChange={lateNightDeliveryFromChangeHandler}
                errorText={lateNightDeliveryFromError}
                disabled={disabled}
            />
        </div>
        <div className='col-md-6 col-lg-4'>
            <FormInput
                type='time'
                label={t('Late night delivery to')}
                placeholder={t('Delivery to')}
                containerClass='mb-4'
                value={lateNightDeliveryTo}
                onChange={lateNightDeliveryToChangeHandler}
                errorText={lateNightDeliveryToError}
                disabled={disabled}
            />
        </div>
        <div className='mb-4'>
            <Button onClick={saveClickHandler} className={style.saveButton} disabled={disabled} loading={loading}>{t("Save")}</Button>
        </div>
    </React.Fragment>
}

export default DeliveryChargeAndDistance