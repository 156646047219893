import React, { Fragment, useEffect, useState } from 'react'
import QRCode from "react-qr-code"
import { useDispatch, useSelector } from 'react-redux'
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import { api, getApiRequestConfig, Response } from '../../api'
import { useT } from '../../i18n'
import { fetchShopBookings, fetchShops } from '../../redux/actions'
import { StoreState } from '../../redux/reducers'
import { AuthAdmin } from '../../redux/reducers/authAdmin.reducer'
import { Booking, Booking as BookingObj } from '../../redux/reducers/booking.reducer'
import { Buyer, Order as OrderObj, PaymentMode, PaymentStatus } from '../../redux/reducers/order.reducer'
import { Shop } from '../../redux/reducers/shop.reducer'
import { AppDispatch } from '../../redux/store'
import style from '../../styles/components/order.module.scss'
import { date, toCurrencyFormat } from '../../utils'
import Confirmation from '../modals/Confirmation'
import Copy from '../svgs/Copy'
import { ToastType, useToast } from '../ToastProvider'

type OrderRole = 'USER' | 'ADMIN'

interface AdminOrderProps {
    booking: BookingObj | any
    name?: { id?: string, language?: string, name?: string, updatedAt?: string, createdAt?: string }[]
    role: OrderRole
    updateBooking: (status: string, bookingId: string) => void
    statusLable: { text: string, value: string }
    resendEmailHandler?: (bookingId: string) => void
}

const Reservation = (props: AdminOrderProps) => {
    const t = useT()
    const authAdmin = useSelector<StoreState, AuthAdmin | null>(state => state.authAdmin)
    const toast = useToast()
    const dispatch = useDispatch<AppDispatch>()
    const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false)
    const [openEmailConfirmModal, setOpenEmailConfirmModal] = useState<boolean>(false)
    const shops = useSelector<StoreState, Shop[] | null>(state => state.shops)
    const bookingShop = shops?.find(s => s.id?.toString() === props.booking.shopId.id?.toString())

    useEffect(() => {
        dispatch(fetchShops())
    }, [])

    const updatePaymentHandler = (value?: string) => {
        props.updateBooking(value ? value : props.statusLable.value, props.booking.id)
    }

    const updateStatusClickHandler = () => {
        const config = getApiRequestConfig(authAdmin?.['auth-token'])
        api.put<Response<Booking[]>>(`user/booking/update-status/${props.booking.id}`, { bookingStatus: "cancelled" }, config).then(response => {
            if (response.status === 200) {
                toast("Reservation cancelled", ToastType.SUCCESS)
                dispatch(fetchShopBookings())

            } else {
                // eslint-disable-next-line no-throw-literal
                throw { response }
            }
        }).catch(() => { })
    }

    const resendEamilHandler = () => {
        props.resendEmailHandler?.(props.booking.id)
        setOpenEmailConfirmModal(false);
    }
    console.log(props.booking);

    return <Fragment>
        {openConfirmModal && <Confirmation
            onClose={() => setOpenConfirmModal(false)}
            confirm={() => updatePaymentHandler(props.statusLable.value)}
            itemName={t(`This action cannot be reverted are you sure you want to proceed?`)}
        />}
        {openEmailConfirmModal && <Confirmation
            onClose={() => setOpenEmailConfirmModal(false)}
            confirm={resendEamilHandler}
            itemName={t(`Are you sure want to resend email?`)}
        />}
        <div className='card my-3 shadow-sm'>
            <div className='card-header bg-white'>
                <div className='row'>
                    <div className='col-sm-4 d-flex align-items-center'>
                        <div className={style.orderNumber}>#{props.booking.bookingNumber}</div>
                    </div>
                    <div className='col-sm-4 d-flex align-items-center my-1'>
                        <div className={style.orderStatus}>{props.booking.bookingStatus}</div>
                    </div>
                    <div className='col-sm-4 d-flex align-items-center'>
                        <div className={style.orderDate}>{date('MON d, Y h:i Z', props.booking.bookingDate)}</div>
                    </div>
                </div>
            </div>
            <div className='card-body'>
                <div className='row'>
                    <div className='col-sm-5'>
                        {props.name && <div className={style.bookingTitle}>{props?.name?.[0].name}</div>}

                        <div className={style.sectionHeader}>{t("Participant Details")}</div>
                        {
                            props.booking.bookingDetails.map((bookingItem: any, index: number) => (

                                <BookingItem
                                    key={bookingItem.id}
                                    price={bookingItem.price}
                                    size={bookingItem.size}
                                    title={bookingItem.title}
                                    foodPreference={bookingItem.foodPreference}
                                    currency={bookingShop?.currency}
                                    transactionImage={props.booking.transactionImage}
                                />
                            ))
                        }
                    </div>
                    <div className='col-sm-7'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <PriceDetails
                                    billAmount={props.booking.billAmount}
                                    totalAmount={props.booking.totalAmount}
                                    totalTax={props.booking.totalTax}
                                    currency={bookingShop?.currency}
                                />
                                {props.booking.paymentMode !== "free" &&
                                    <OtherDetails
                                        paymentMode={props.booking.paymentMode}
                                        paymentStatus={props.booking.paymentStatus}
                                        cultureVoucher={props.booking.cultureVoucher}
                                    />
                                }

                            </div>
                            <div className='col-md-6'>
                                <CustomerDetails
                                    buyer={props.booking.user}
                                    role={props.role}
                                    bookingNumber={props.booking.bookingNumber}
                                    eventId={props.booking.eventId.id}
                                    title={props?.name?.[0].name}
                                    bookingId={props.booking.id}
                                    paymentStatus={props.booking.paymentStatus}
                                    paymentMode={props.booking.paymentMode}
                                    cultureVoucher={props.booking.cultureVoucher}
                                />
                            </div>
                            {(props.booking.bookingStatus === 'booked' || props.booking.paymentStatus === "pending" && props.booking.bookingStatus !== "cancelled" && props.booking.bookingStatus !== "failed") &&
                                <React.Fragment>
                                    <br />
                                    <hr />
                                    {props.role !== "USER" && <div className={style.sectionHeader}>{t("Update Status")}</div>}
                                    <div className='hstack flex-wrap gap-2'>
                                        {props.statusLable.text === "Payment Verify" ?
                                            <div>
                                                <div className={style.bookingStatus} onClick={() => setOpenConfirmModal(true)}>
                                                    <span>{props.statusLable.text}</span>
                                                </div>
                                                <div className='btn btn-danger' onClick={() => updateStatusClickHandler()}>
                                                    <span>Cancel</span>
                                                </div>
                                            </div>
                                            :
                                            <>
                                                <div className={style.bookingStatus} onClick={() => updatePaymentHandler()}>
                                                    <span>{props.statusLable.text}</span>
                                                </div>
                                                {props.statusLable.text === "Attend" && props.role !== "USER" &&
                                                    <div className={style.resendMailContainer}>
                                                        {props.booking.resendEmailCount && <div className={style.countMail}>
                                                            <div className={style.countMailText}>
                                                                {props.booking.resendEmailCount}
                                                            </div>
                                                        </div>}
                                                        <div className={style.bookingStatus} onClick={() => setOpenEmailConfirmModal(true)}>
                                                            <span>{t("Resend Mail")}</span>
                                                        </div>
                                                    </div>
                                                }
                                            </>
                                        }
                                    </div>
                                </React.Fragment>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Fragment>
}

interface BookingItemProps {
    title: string
    size: string | null
    price: number
    foodPreference: string | undefined
    currency?: string
    transactionImage?: string
}

const BookingItem = (props: BookingItemProps) => {
    const t = useT()
    return <React.Fragment>
        <div className={style.itemTitle}>{props.title} {props.size && <span className='text-capitalize'>({props.size})</span>}</div>
        <div className={style.price}><span className='text-capitalize'>{t("Price")} : </span> {toCurrencyFormat(props.price, props.currency)}</div>
        {props.foodPreference && <div className={style.price}><span className='text-capitalize'>{t("Food Preference")} : </span>{props.foodPreference}</div>}
        {props.transactionImage !== "" && <div>
            <img src={props.transactionImage} alt="" style={{ height: "200px", width: "250px" }} />
        </div>}

    </React.Fragment>
}

interface ProcessTimeProps {
    order: OrderObj
}


interface CustomerDetailsProps {
    buyer: Buyer
    role: OrderRole,
    bookingNumber: string
    eventId: string | undefined
    title?: string
    bookingId: string
    paymentStatus: string
    paymentMode: string
    cultureVoucher?: string
}

const CustomerDetails = (props: CustomerDetailsProps) => {
    const t = useT()
    const [copyText, setCopyText] = useState(false);
    const shop = useSelector<StoreState, AuthAdmin | null>(state => state.authAdmin)?.shop

    const paymentDetails = Array.isArray(shop?.otherPayments)
        ? shop?.otherPayments.filter((op) => op.title === props.paymentMode)[0]?.detail
        : null;

    const copyClipBoard = () => {
        setCopyText(true);
        if (paymentDetails) {
            navigator.clipboard.writeText(paymentDetails);
        }
        setTimeout(() => {
            setCopyText(false);
        }, 1000)
    }

    return <React.Fragment>
        {props.role === 'USER' ? (
            props.paymentStatus === "pending" && props.paymentMode !== "free" ? (
                <>
                    <div className={style.emptybox}>{t("QR code will be generate after payment verification successfully")}</div>
                    {props.paymentMode !== 'paytrail' && props.cultureVoucher === "no" && paymentDetails && (
                        <div>
                            <div className={`hstack gap-2 ${style.copyClipText}`}>
                                <div>{shop?.otherPayments?.filter((op) => op.title === props.paymentMode)[0]?.title} : {paymentDetails}</div>
                                <a className={style.copyIcon} onClick={copyClipBoard} data-tooltip-id="copyClip" data-tooltip-content={!copyText ? "Copy" : 'Copied!'}><Copy /></a>
                            </div>
                            <Tooltip id="copyClip" style={{ borderRadius: "15px", textAlign: 'center' }} />
                        </div>
                    )}
                    {props.paymentMode !== 'paytrail' && props.cultureVoucher !== "no" && (
                        <div className={`${style.copyClipText}`}>{t("Please search for")} {shop?.businessName} {t("in your")} {props.cultureVoucher} {t("account and pay")}.</div>
                    )}
                </>
            ) : (
                <QRCode
                    size={140}
                    value={JSON.stringify({ bookingNumber: props.bookingNumber, eventId: props.eventId, bookingId: props.bookingId })}
                    style={{ paddingBottom: "10px" }}
                    viewBox={`0 0 256 256`}
                />
            )
        ) : (
            <>
                <div className={style.sectionHeader}>{props.role === 'ADMIN' ? t('Customer') : t('Contact')} {t("Detail")}</div>
                <div className={style.customerName}>{props.buyer.firstName} {props.buyer.lastName}</div>
                <div className={style.customerDetails}>{props.buyer.address.addressLine1}, {props.buyer.address.addressLine2}, {props.buyer.address.city}, {props.buyer.address.state} - {props.buyer.address.pincode}</div>
                {props.buyer.address.landmark && <div className={style.customerDetails}>{props.buyer.address.landmark}</div>}
                <div className={style.email}>{props.buyer.email}</div>
                <div className={style.customerDetails}>{props.buyer.phoneNumber}</div>
            </>
        )}
    </React.Fragment>
}

interface PriceDetailsProps {
    totalAmount: number
    totalTax: number | null
    billAmount: number
    currency?: string
}

const PriceDetails = (props: PriceDetailsProps) => {
    const t = useT()
    return <React.Fragment>
        <div className={style.sectionHeader}>{t("Price details")}</div>
        <table>
            <tbody>
                <tr>
                    <td className={style.priceLabel}>{t("Total Amount")}:</td>
                    <td className={style.priceValue}>{toCurrencyFormat(props.totalAmount, props.currency)}</td>
                </tr>

            </tbody>
        </table>
        <table className='border-top pt-2 mt-2 d-inline-block'>
            <tbody>
                <tr>
                    <td className={style.billAmountLabel}>{t("Bill Amount")}:</td>
                    <td className={style.billAmount}>{toCurrencyFormat(props.billAmount, props.currency)}</td>
                </tr>
            </tbody>
        </table>
    </React.Fragment>
}

interface OtherDetailsProps {
    paymentStatus: PaymentStatus
    paymentMode: PaymentMode
    cultureVoucher: string
}

const OtherDetails = (props: OtherDetailsProps) => {
    const t = useT()

    return <table className='pt-2 mt-2'>
        <tbody>
            <tr>
                <td className={style.priceLabel}>{t("Payment")}:</td>
                <td className={style.priceValue}>{props.paymentStatus}</td>
            </tr>
            <tr>
                <td className={style.priceLabel}>{t("Mode")}:</td>
                <td className={style.priceValue}>{props.cultureVoucher === "no" ? props.paymentMode : "Culture Voucher"}</td>
            </tr>
            <tr>
                <td className={style.priceLabel}>{t("Applied for Culture voucher :")}</td>
                <td className={style.priceValue}>{props.cultureVoucher}</td>
            </tr>
        </tbody>
    </table>
}

export default Reservation