import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { CheckoutContext, CheckoutContextType } from "../../context/CheckoutProvider";
import { useT } from "../../i18n";
import { StoreState } from "../../redux/reducers";
import { EventCart } from "../../redux/reducers/eventCart.reducer";
import style from '../../styles/pages/checkout.module.scss';
import Checkbox from "../utils/Checkbox";



const CheckoutEventVolunteers = () => {
    const t = useT()
    const eventCart = useSelector<StoreState, EventCart>((state) => state.eventCart);
    const checkoutContext = React.useContext<CheckoutContextType | null>(CheckoutContext)
    return (
        <Fragment>
            <div className='mb-4'>
                <h6 className={style.volunteerLabel}>{t("Select Area of Volunteering")}</h6>{checkoutContext?.volunteerError && <span className={style.requiredLabel}>( {t("At least one option required")} )</span>}
                {eventCart.item?.volunteers.map(volunteer => {
                    return <Checkbox
                        className='mb-1'
                        key={volunteer}
                        onClick={() => checkoutContext?.volunteerCheckHandler?.(volunteer)}
                        checked={checkoutContext?.volunteerCheckedLists?.some(v => v === volunteer)}
                    >{volunteer}</Checkbox>
                })}
            </div>
        </Fragment>
    )
}



export default CheckoutEventVolunteers;