import React from 'react'
import { useSelector } from 'react-redux'
import AdminHeader from '../../components/sections/AdminHeader'
import AdminPanelHeader from '../../components/sections/AdminPanelHeader'
import AdminOrder from '../../components/sections/Order'
import Spinner from '../../components/utils/Spinner'
import { AdminContext, AdminContextType } from '../../context/AdminProvider'
import { useT } from '../../i18n'
import { StoreState } from '../../redux/reducers'
import { Order } from '../../redux/reducers/order.reducer'
import style from '../../styles/pages/admin-orders.module.scss'

type OrderMenu = 'NEW' | 'PROCESS' | 'READY' | 'FAILED'

const getOrderMenu = (order: Order): OrderMenu | null => {
    if (order.paymentStatus === 'failed') {
        return 'FAILED'
    }

    if (order.orderStatus === 'ordered') {
        if (order.paymentMode === 'cash') {
            return 'NEW'
        }

        if (order.paymentMode === 'paytrail' && order.paymentStatus === 'paid') {
            return 'NEW'
        }
    }

    if (order.orderStatus === 'processing') {
        return 'PROCESS'
    }

    if (order.orderStatus === 'ready') {
        return 'READY'
    }

    return null
}

const ChefDashboard = () => {
    const t = useT()
    const adminContext = React.useContext<AdminContextType | null>(AdminContext)
    const orders = useSelector<StoreState, Order[]>(state => state.orders)

    const [orderMenu, setOrderMenu] = React.useState<OrderMenu>('NEW')

    const filteredOrders = orders.filter(order => getOrderMenu(order) === orderMenu)

    return <React.Fragment>
        <div className="container col-lg-12 col-sm-12 col-md-12">
            <AdminHeader />
            <div className='mb-4 mt-2'>
                <AdminPanelHeader title='Chef Dashboard Page' />
                <div className='hstack gap-2'>
                    <OrderMenuLink active={orderMenu === 'NEW'} onClick={() => setOrderMenu('NEW')}>{t("NEW")}</OrderMenuLink>
                    <OrderMenuLink active={orderMenu === 'PROCESS'} onClick={() => setOrderMenu('PROCESS')}>{t("PROCESS")}</OrderMenuLink>
                    <OrderMenuLink active={orderMenu === 'READY'} onClick={() => setOrderMenu('READY')}>{t("READY")}</OrderMenuLink>
                    <OrderMenuLink active={orderMenu === 'FAILED'} onClick={() => setOrderMenu('FAILED')}>{t("FAILED")}</OrderMenuLink>
                </div>
                {adminContext?.fetchingOrders
                    ? <Spinner size={25} color='#ec3b50' className='mt-3' />
                    : filteredOrders.reverse().map(order => {
                        return <AdminOrder
                            key={order.id}
                            order={order}
                            role='ADMIN'
                        />
                    })}
            </div>
        </div>
    </React.Fragment>
}

interface MenuLinkProps {
    children?: React.ReactNode
    active?: boolean
    onClick?: () => void
}

const OrderMenuLink = (props: MenuLinkProps) => {
    return <div className={`${style.orderMenuLink} ${props.active ? style.active : ''}`} children={props.children} onClick={props.onClick} />
}

export default ChefDashboard