import { Action, OrderActionTypes,BookingActionTypes } from '../actions'
import { Category } from './category.reducer'

export interface BookingReports {
    count: number
    date: string
    price: number
}


export interface BookingReport {
   _id:{
    category:Category[]
   }
   data:BookingReports[]
}

const initialState:BookingReport[] = []


const bookingReportReducer = (state: BookingReport[] = initialState, action: Action):BookingReport[] => {
    switch (action.type) {
        case BookingActionTypes.FETCH_BOOKING_REPORTS: {
            return action.data
        }
        default: {
            return state
        }
    }
}

export default bookingReportReducer;