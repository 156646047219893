import style from '../../styles/components/product-card-placeholder.module.scss'

const ProductCardPlaceholder = () => {
    return <div className={style.container}>
        <div className={style.image} />
        <div className='placeholder-glow mt-2'>
            <div className='vstack gap-2 p-1'>
                <div className='placeholder placeholder-sm' style={{ width: '80%' }} />
                <div className='placeholder placeholder-xs' style={{ width: '50%' }} />
                <div className='placeholder placeholder-xs' style={{ width: '60%' }} />
            </div>
        </div>
    </div>
}

export default ProductCardPlaceholder