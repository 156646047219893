import React from 'react'
import { DragDropContext, Draggable, Droppable, OnDragEndResponder } from 'react-beautiful-dnd'

interface Item {
    id: string
}

interface DragAndDropProps<I extends Item> {
    items?: I[]
    renderItemGetter?: (item: I) => React.ReactNode
    onDrop?: (items: I[]) => void
    marginBottom?: number
}

const DragAndDrop = <D extends Item>(props: DragAndDropProps<D>) => {
    const onDragEnd: OnDragEndResponder = (result) => {
        if (result.destination && props.items) {
            const items = [...props.items]
            const [reorderedItems] = items.splice(result.source.index, 1)
            items.splice(result.destination.index, 0, reorderedItems)
            props.onDrop?.(items)
        }
    }

    return <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='drop'>
            {(provided) => {
                return <div {...provided.droppableProps} ref={provided.innerRef}>
                    {props.items?.map((item, index) => {
                        const children = props.renderItemGetter?.(item)

                        return <Draggable draggableId={item.id} index={index} key={item.id} >
                            {(provided) => {
                                return <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                    <div style={{ marginBottom: props.marginBottom }}>{children}</div>
                                </div>
                            }}
                        </Draggable>
                    })}
                    {provided.placeholder}
                </div>
            }}
        </Droppable>
    </DragDropContext>
}

export default DragAndDrop