/* eslint-disable no-throw-literal */
import React from 'react'
import { useSelector } from 'react-redux'
import { api, Response } from '../api'
import { StoreState } from '../redux/reducers'
import { Appointment } from '../redux/reducers/appointment.reducer'
import { Category } from '../redux/reducers/category.reducer'
import { Event } from '../redux/reducers/event.reducer'
import { Favourite } from '../redux/reducers/favourite.reducer'
import { Food } from '../redux/reducers/food.reducer'
import { Voucher } from '../redux/reducers/voucher.reducer'

export interface WithFavourite {
    favourite: boolean
}

export interface ShopContextType {
    shopId?: string
    categories?: Category[]
    currency?:string
    vouchers?: Voucher[]
    activeCategory?: string
    onActiveCategoryChange?: (categoryId?: string) => void
    foods?: (Food & WithFavourite)[]
    events?: (Event & WithFavourite)[]
    appointments?: (Appointment & WithFavourite)[]
    loading?: boolean
}

export const ShopContext = React.createContext<ShopContextType | null>(null)

interface ShopProviderProps {
    children?: React.ReactNode
    shopId: string
}

const favouriteCategory: Category = {
    createdAt: '',
    id: 'favourites',
    names: [
        {
            createdAt: '',
            id: '',
            language: '',
            name: 'My favourites',
            updatedAt: ''
        }
    ],
    offerLabel: '',
    seqNo: 0,
    shopId: '',
    updatedAt: ''
}

const ShopProvider = (props: ShopProviderProps) => {
    const favourites = useSelector<StoreState, Favourite[]>(state => state.favourites)

    const [categories, setCategories] = React.useState<Category[]>([])
    const [foods, setFoods] = React.useState<(Food & WithFavourite)[]>([])
    const [events, setEvents] = React.useState<(Event & WithFavourite)[]>([])
    const [appointments, setAppointments] = React.useState<(Appointment & WithFavourite)[]>([])
    const [foodsLoading, setFoodsLoading] = React.useState<boolean>(true)
    const [eventsLoading, setEventsLoading] = React.useState<boolean>(true)
    const [appointmentsLoading, setAppointmentsLoading] = React.useState<boolean>(true)

    const [categoriesLoading, setCategoriesLoading] = React.useState<boolean>(true)
    const [activeCategory, setActiveCategory] = React.useState<string>('')

    const [vouchers, setVouchers] = React.useState<Voucher[]>([])

    const onActiveCategoryChange = (categoryId?: string) => {
        if (categoryId && activeCategory !== categoryId) {
            setActiveCategory(categoryId)
        }
    }

    React.useEffect(() => {
        setFoodsLoading(true)
        api.get<Response<Food[]>>(`foods/${props.shopId}`).then(response => {
            if (response.status === 200) {
                setFoods(response.data.data.map(food => {
                    return {
                        ...food,
                        favourite: favourites.some(f => f.itemId === food.id)
                    }
                }))
            } else {
                throw { response }
            }
        }).catch(() => { }).finally(() => {
            setFoodsLoading(false)
        })

        setEventsLoading(true)
        api.get<Response<Event[]>>(`events/${props.shopId}`).then(response => {
            if (response.status === 200) {
                setEvents(response.data.data.map(event => {
                    return {
                        ...event,
                        favourite: favourites.some(f => f.itemId === event.id)
                    }
                }))
            } else {
                throw { response }
            }
        }).catch(() => { }).finally(() => {
            setEventsLoading(false)
        })

        setAppointmentsLoading(true)
        api.get<Response<Appointment[]>>(`appointments/${props.shopId}`).then(response => {
            if (response.status === 200) {
                setAppointments(response.data.data.map(appointment => {
                    return {
                        ...appointment,
                        favourite: favourites.some(f => f.itemId === appointment.id)
                    }
                }))
            } else {
                throw { response }
            }
        }).catch(() => { }).finally(() => {
            setAppointmentsLoading(false)
        })

        setCategoriesLoading(true)
        api.get<Response<Category[]>>(`categories/${props.shopId}`).then(response => {
            if (response.status === 200) {
                setCategories(favourites.length > 0
                    ? [
                        favouriteCategory,
                        ...response.data.data
                    ]
                    : response.data.data)
            } else {
                throw { response }
            }
        }).catch(() => { }).finally(() => {
            setCategoriesLoading(false)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.shopId])

    React.useEffect(() => {
        setFoods(prev => {
            return prev.map(p => {
                return {
                    ...p,
                    favourite: favourites.some(f => f.itemId === p.id)
                }
            })
        })
        setEvents(prev => {
            return prev.map(p => {
                return {
                    ...p,
                    favourite: favourites.some(f => f.itemId === p.id)
                }
            })
        })
        setAppointments(prev => {
            return prev.map(p => {
                return {
                    ...p,
                    favourite: favourites.some(f => f.itemId === p.id)
                }
            })
        })
        setCategories(prev => {
            if (favourites.length === 0) {
                return prev.filter(ns => ns.id !== 'favourites')
            } else {
                const index = prev.findIndex(ns => ns.id === 'favourites')

                if (index === -1) {
                    return [
                        favouriteCategory,
                        ...prev
                    ]
                }
            }

            return prev
        })
    }, [favourites])

    React.useEffect(() => {
        if (props.shopId) {
            api.get<Response<Voucher[]>>(`vouchers/${props.shopId}`).then(response => {
                if (response.status === 200) {
                    setVouchers(response.data.data)
                } else {
                    throw { response }
                }
            }).catch(() => { })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <ShopContext.Provider value={{ categories, foods, events, appointments, shopId: props.shopId, loading: foodsLoading || categoriesLoading || eventsLoading || appointmentsLoading, activeCategory, onActiveCategoryChange, vouchers }}>
        {props.children}
    </ShopContext.Provider>
}

export default ShopProvider