import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useT } from '../../i18n'
import { importProducts } from '../../redux/actions'
import { StoreState } from '../../redux/reducers'
import { AuthAdmin } from '../../redux/reducers/authAdmin.reducer'
import { AppDispatch } from '../../redux/store'
import { ToastType, useToast } from '../ToastProvider'
import ModalWithHeader from '../modals/ModalWithHeader'
import FormInput from '../utils/FormInput'


interface FoodFormProps {
    onClose?: () => void
}

const ProductImportForm = (props: FoodFormProps) => {
    const dispatch = useDispatch<AppDispatch>()
    const authAdmin = useSelector<StoreState, AuthAdmin | null>(state => state.authAdmin)
    const toast = useToast()
    const t = useT()
    const [loading, setLoading] = React.useState<boolean>(false)
    const [file, setFile] = React.useState<any>('')

    const handleFileChangeHandler = (e: any) => {
        const files = e.target.files;
        const allowedExtensions = ["csv"];
        if (files.length > 0) {
            const inputFile = files[0];
            const fileExtension = inputFile.type.split("/")[1];
            if (!allowedExtensions.includes(fileExtension)) {
                toast(t('Please import a csv file'), ToastType.ERROR)
                return
            }
            setFile(inputFile);
        }
    }

    const submitHandler = () => {
        if (!file) {
            toast(t('Enter a valid file'), ToastType.ERROR)
            return
        }


        const formData: any = new FormData()
        formData.append("file", file);
        setLoading(true)
        dispatch(importProducts(formData)).then(() => {
            toast(t('Products Imported Successfully'))
            props.onClose?.()
        }).catch(text => {
            toast(text, ToastType.ERROR)
        }).finally(() => {
            setLoading(false)
        })


    }

    return <ModalWithHeader
        buttonText={t('Save')}
        headerText={t('Import Product')}
        loading={loading}
        onClose={props.onClose}
        onSave={submitHandler}
        small
    >
        <div className='row mb-4'>
            <div className='col'>
                <FormInput
                    type='file'
                    label={t('Upload File (.csv)')}
                    placeholder={t('Select file')}
                    containerClass='mb-4'
                    onChange={handleFileChangeHandler}
                />

            </div>
        </div>

    </ModalWithHeader>
}
export default ProductImportForm;