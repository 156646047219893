import CommonLayout from '../components/sections/CommonLayout'
import { useT } from '../i18n'
import style from '../styles/pages/privacy-policy.module.scss'

const PrivacyPolicy = () => {
    const t = useT()
    return <CommonLayout>
        <div>
            <h1 className={style.title}>{t("Privacy policy")}</h1>
            <h1 className={style.subtitle}>{t("DISCLAIMER")}</h1>
            <div>
                <div>
                    <p>
                        {t("This document is intended for")}
                        [<strong>
                            {t("vshops and its subdomains")}
                        </strong>],
                        {t("now known as the website and is referred to as the service that is served by")}
                        [<strong>
                            Cloudronics Solutions Oy, Y-Tunnus:2760646-7
                        </strong>],
                        {t("a Finland corporation and the owner, operator of vshops.fi,vshops.eu now known as the company and referred to as we, us, our in the context")}.
                    </p>
                    <p>
                        {t("This page details the Terms of service and/or Privacy policy that govern the usage of shops, its subdomains and applications that are available (or will be made available) for iOS and Android ecosystem")}.
                    </p>
                    <p>
                        {t(`By using this service, you acknowledge and agree to the Terms ofservice  and Privacy policy , the links to which can be found on this page and which are incorporated herein by reference. These Terms of service constitute a legally binding agreement between you (whether personally or on behalf or an organization) and us. If you are using this service on behalf of an organization or a business or an entity, then you are agreeing to these Terms of service and Privacy policy on behalf of that organization and that you warrant that you have the authority to bind that organization or business or entity to these terms. In all such cases, you and yours refers to that specific organization or business or entity.`)}
                    </p>
                    <p>
                        {t(" Should you disagree with any of our Terms of service and/or Privacy policy, then you are expressly prohibited from using our service and you must discontinue its use immediately. Any references to Law or Local law means the law of the land (the highest administrative unit or judicial authority) where our business is registered and operates from a physical location within that land - in our case: Finland.")}
                    </p>
                    <p>
                        {t(" Changes to the Terms of service and Privacy policy: We can update the Terms of service and Privacy policy from time to time. All changes thereafter will be made available on this website, which is why we encourage you to frequently check this page for any changes. Please note, changes hereto will not prejudice your rights without your consent. We will inform you of any material changes by sending an email or other such applicable way.")}
                    </p>
                    <p>
                        {t("Should you have any questions regarding the service, the Terms of service or the Privacy policy governing the use and storage of data, please feel free to contact us at")} <code>info @ cloudronics.com.</code>
                    </p>
                </div>
            </div>
        </div>
        <div>
            <h2>
                <h1 className={style.subtitle} >
                    {t("PRIVACY POLICY FOR USERS")}
                </h1>
            </h2>
            <div>
                <div>
                    <p>
                        {t("This privacy policy solely applies to all the information that may be collected by our website and mobile applications (for iOS, Android), in order to provide a seamless service to you, in your role as a user (or consumer). In this role, when you visit our website(s) or use our service to order your meal from a restaurant, the following data is collected or processed or stored:")}
                    </p>
                    <h2 className={style.headings}>{t("When visiting our website")}:</h2>
                    <p>
                        {t(" Non-personal data that is collected by external services to determine unique users on website; to determine what type of reference was used to reach our website; to analyse and compile reports related to website activity, including but not limited to - time spent on the pages, sections of pages, features etc. Please note, at this moment, we do not use any service to collect any such data, however, this may change in near future.")}
                    </p>
                    <h2 className={style.headings}>{t("When using our service (Web, iOS, Android)")}:</h2>
                    <p>
                        {t(" Your current location: We collect your current location in form of Latitude, Longitude pair and use this information to show you the list of nearby places that use NoutoAteria for managing their online food ordering portal.This data is collected and processed every time you view the list of nearby restaurants and cannot be used to identify you in any way.We do not store this data in any form.")}
                    </p>
                    <p>
                        {t("Your name, phone number and email address: When you decide to order your meal using NoutoAteria, we collect and store your Name, Phone number and Email address. All of this information is stored securely in our systems and can be seen only by the restaurant where you have placed an order, to an extent that is needed by them to perform their services - like prepare your order, provide Takeaway or Dine-in services to you in relation to the order that was made. Your phone number and email address are also used by us to provide you with regular updates related to your order, for example, when the restaurant has confirmed your order, when the restaurant has started to prepare your order, when your order is ready to for pickup or dine-in. All such information that is collected is stored within the European Economic Area and is not shared with any third-party unless it is required to do so by the law or there is a legal obligation to provide such information on a case to case basis.")}
                    </p>
                    <p>
                        {t("Information relating to your payment instrument(s): At NoutoAteria we use third party payment service provider for processing of payments and we do not collect or store any financial information like your Bank ID or payment card number or any other similar information in any form. We store the transaction ID of the payment instruction that is made by you to the third-party payment service provider. This data is collected and stored so that we can handle your payments or any refunds where applicable.")}
                    </p>
                    <p>
                        {t(" Unique identity of your device: We identify your (mobile) device using the Application ID which is provided by the native platform APIs for Android and iOS. In case you are using a web-browser to access our services, a unique identity is collected using your device's screen size (available via window.screen), browser's user agent (available via window.navigator.userAgent) and the list of plugins available on your browser (available via window.navigator.plugins). In case it is not possible to uniquely identify your (mobile) device using any of the above mentioned methods, we generate a UUID using methods that are RFC4122 version 4 compliant. This unique identity is stored in the local storage of your device (e.g. application local storage or browser local storage) and is sent and stored in our systems when you make an order using our services. This unique id is used to identify all the orders that your (mobile) device has made using our system. We do not use this information to identify you personally or in any form other than identifying the device from which you are using our services.")}
                    </p>
                    <p>
                        {t("PUSH notification id of your device: In order to send you real time notifications, we use Firebase cloud messaging to determine which device to deliver the notifications to. Once this ID is obtained from Firebase, it is stored locally on your (mobile) device and is sent to our systems when you make an order using our services. This information gets stored in our system only when an order has been created. We do not use this information to identify you personally in any form.")}
                    </p>
                    <p>
                        {t("Your responses to service specific questions including any allergies: When you order food using our services and on a case-by-case basis, the partner restaurant from where the food has been ordered needs information including any allergies that you may have. This information is needed so that the partner restaurant can provide you with the best quality service. All of this information is stored securely in our systems, is not shared by any third-party and is accessible only to the restaurant where the order has been made.")}
                    </p>
                    <h2 className={style.headings}>{t("HOW DO WE SECURE THIS DATA")}:</h2>
                    <p>
                        {t(" All the communication that happens between our system and yours, is secured by standard SSL encryption. All the data that is stored in our systems follows the standard industry practices of security and privacy.")}
                    </p>
                    <h2 className={style.headings}>{t("DO WE DISCLOSE THIS INFORMATION TO ANY THIRD-PARTY")}?</h2>
                    <p>
                        {t("No we don't. We never share any of the above mentioned information with any third-party or organization unless it is required to do so by the law. Please however be informed that the information that is collected when an order is made, for example, your name, phone number, email address are accessible to the restaurant where the order has been placed.")}
                    </p>
                    <h2 className={style.headings}>{t("DO WE USE COOKIES")}?</h2>
                    <p>
                        {t("When visiting our website: At this moment, we don't. In near future, we plan to use cookies that are set by Google Analytics to determine user behavior on our website and use this information to make our website and its services better and smarter, at the same time protecting your own privacy.")}
                    </p>
                    <p>
                        {t("When using our services: NO, WE DON'T. We don't use any cookies or such technologies to store or collect any information or analytical data when a user is using our services. We however use user's device's local storage to store their language preferences and other relevant application settings including but not limited to device's Unique identity, PUSH notification id etc.")}
                    </p>
                    <h2 className={style.headings}>{t("DATA COLLECTION POLICY")}</h2>
                    <p>
                        {t(" We collect personal information like name, phone number and email address to fulfil our obligations related to providing you a seamless and hassle free online food ordering service. All such personally identifiable information is collected only when an order is placed using our services. We do collect other information like Unique identity, PUSH notification id of your (mobile) device, your current location - however, this information is not used to identify you personally in any form")}.
                    </p>
                    <h2 className={style.headings}>{t("DATA RETENTION POLICY")}</h2>
                    <p>
                        {t(" We collect personal information like name, phone number and email address when you order a meal using our services. This information is stored together with the order specific data - securely in our systems and is protected by multiple layers of security. By default, all such order related data is retained in our system for the duration the service account for the restaurant remains active. Once the account has been deleted, all personal information, including order related information is removed from our systems with immediate effect. All such data is removed from backup systems within 180 days.")}
                    </p>
                    <h2 className={style.headings}>{t("DATA USAGE POLICY")}</h2>
                    <p>
                        {t("We collect personal and non-personal information when you are using our services to provide you a seamless service. Any data that is collected is used only for the purpose it is collected for. No personal data is used for any marketing or sales or promotional activities, however contextual statistical data related to online orders can and will be used for further statistical and research purposes. We can also use anonymous statistical data for marketing purposes. Examples of such data include (but are not limited to): how many orders in a day, 3-star feedback related to orders, most ordered items from a specific restaurant.")}
                    </p>
                </div>
            </div>
        </div>
        <div>
            <h2>
                <h1 className={style.subtitle}>
                    {t("PRIVACY POLICY FOR BUSINESSES")}
                </h1>
            </h2>
            <div>
                <div>
                    <p>
                        {t("This privacy policy solely applies to all the information that may be collected by our website and mobile applications (for iOS, Android), in order to provide a seamless service to   you, in your role as a restaurant or a restaurant owner (and now referred as restaurant or business). In this role, when you visit our website(s) or use our service to manage the online   food ordering portal for your business, the following data is collected or processed or stored:")}
                    </p>
                    <h2 className={style.headings}>{t("When visiting our website")}:</h2> <p>
                        {t("Non-personal data that is collected by external services to determine unique users on website; to determine what type of reference was used to reach our website; to analyse and compile reports related to website activity, including but not limited to - time spent on the pages, sections of pages, features etc. Please note, at this moment, we do not use any service collect any such data, however, this may change in near future.")}
                    </p>
                    <h2 className={style.headings}>{t("When registering your account")}:</h2> <p>
                        {t("Name of your restaurant (or business) is collected and stored in our system so that your customers are able to identify your business from the list of other such businesses. In addition to this, your name is used in the FROM field of the email when we send the order updates to your customers on your behalf. This information is available in public domain to your customers and anyone who is exploring our services.")}
                    </p>
                    <p>
                        {t(" Address of your restaurant is collected and stored in our system. We use Google Geocoding APIs to process the address provided by you and obtain the Latitude, Longitude pair that represents your business with an accuracy of 50 meters or less. All of this information is stored in our system and is used when a user is looking for places that are nearby their current Latitude, Longitude location. This information is available in public domain to your customers and anyone who is exploring our services.")}
                    </p> <p>
                        {t("Details of the business entity which operates the restaurant. We collect and store the name of the company and it's VAT number. This information is used for creating invoices that are generated towards usage of our services.")}
                    </p>
                    <p>
                        {t("Email address of the person in-charge is collected and stored. This address is used for communication purposes between us (the service provider) and the restaurant (the user of such service), for sending new order notifications and for sending invoices when they are generated.")}
                    </p> <p>
                        {t("Phone number of the kitchen is collected and stored so that we can send them SMS or call notifications when an order is created and there is no response to such order within a reasonable time frame. This number is visible to the customers who make an order so that they can contact the restaurant when it is required to do so. This information is available in public domain to your customers and anyone who is exploring our services.")}
                    </p>
                    <h2 className={style.headings}>{t("When using our service (Web, iOS, Android)")}:</h2> <p>
                        {t("Your food catalog: we collect and store the information related to the items that you offer to your customers. This includes mandatory information like name of the item, brief description, price and other optional information likespice level, food category (e.g. vegan, vegetarian) and also the main ingredients that are a part of that food item. This information is available in public domain to your customers and anyone who is exploring our services.")}
                    </p> <p>
                        {t("Your operating hours: we collect and store the weekly operating hours of your restaurant and show this information to your customers when they visit your page on our system. This information is available in public domain to your customers and anyone who is exploring our services.")}
                    </p>
                    <h2 className={style.headings}>{t("HOW DO WE SECURE THIS DATA")}:</h2>
                    <p>
                        {t("All the communication that happens between our system and ours, is secured by standard SSL encryption. All the data that is stored in our systems follows the standard industry practices of security and privacy.")}
                    </p>
                    <h2 className={style.headings}>{t("DO WE DISCLOSE THIS INFORMATION TO ANY THIRD-PARTY")}?</h2>
                    <p>
                        {t("No we don't. We never share any of the above mentioned information with any third-party or organization unless it is required to do so by the law. Please however be informed, most of the information that we collect from you, is also available in the public domain for your customers to use our services. Such information includes:")}
                    </p>
                    <p>

                        <ul>
                            <li>{t("Name of your restaurant / business")}</li>
                            <li>{t("Address of your restaurant / business")}</li>     <li>{t("Phone Number")}</li>
                            <li>{t("Operating hours")}</li>
                            <li>{t("Menu")}</li>
                        </ul>

                    </p>
                    <p>
                        {t(" All such data is available in public to users who are using or exploring our services to fulfill our obligation related to providing an online (food) ordering services for your business.")}
                    </p>
                    <h2 className={style.headings}>{t("DO WE USE COOKIES")}?</h2>
                    <p>
                        {t(" When visiting our website: At this moment, we don't. In near future, we plan to use cookies that are set by Google Analytics to determine user behavior on our website and use this information to make our website and its services better and smarter, at the same time protecting your own privacy.")}
                    </p>
                    <p>
                        {t("When using our services: NO, WE DON'T. We don't use any cookies or such technologies to store or collect any information or analytical data when a user is using our services. We however use your device's local storage to store your language preferences and other relevant application settings like active order, active order line etc.")}
                    </p>
                    <h2 className={style.headings}>{t("DATA COLLECTION POLICY")}</h2>
                    <p>
                        {t(" All the data / information that is collected by our service is done to fulfill our obligations related to providing your business with a seamless and hassle free online food ordering service. Any such information that is considered sensitive like Email address, Phone number is collected only for the purposes mentioned above and is not used in any other form.")}
                    </p>
                    <h2 className={style.headings}>{t("DATA RETENTION POLICY")}</h2>
                    <p>
                        {t(" All the data / information that is collected by our service, like your Email address, phone number, business name, company name, VAT number, address, operating hours, menu is stored securely in our system and is protected by multiple layers of security. By default, all such data is retained in our system for the duration the restaurant account remains active. Once  the account has been deleted, all such information, including order related information is removed from our systems with immediate effect. All such data is removed from backup systems within 180 days.")}
                    </p>
                    <h2 className={style.headings}>{t("DATA USAGE POLICY")}</h2>
                    <p>
                        {t("All the data / information that is collected is used only for the purpose it is collected for. No personal data is used for any marketing or sales or promotional activities, however   contextual statistical data related to online orders can and will be used for further statistical and research purposes. We can also use anonymous statistical data for marketing purposes. Examples of such data include (but are not limited to): how many orders in a day, 3-star feedback related to orders, most ordered items from a specific restaurant.")}
                    </p>
                    <h2 className={style.headings}>{t("GDPR DECLARATION")}</h2>
                    <p>
                        {t(" Under GDPR (EU) 2016/679, any personal data that is collected during the usage of our service, like Name, Phone number, Email address or Username is stored securely in our systems - following the standard industry practices of security and privacy. Any such data is not shared with any third party or organization, unless it is required to do so by the law. Under GDPR (EU) 2016/679, any personal data that is collected during the usage of our service, is stored for a maximum of 12 months, unless the user has requested a deletion of their personal data. In case of a request for deletion, the data is removed from live and backup systems within 180 days.")}
                    </p>
                </div>
            </div>
        </div>
       
    </CommonLayout >
}

export default PrivacyPolicy