import React from 'react'
import { useSelector } from 'react-redux'
import { api, getApiRequestConfig, Response } from '../../api'
import UserOrder from '../../components/sections/Order'
import ShouldAuthenticate from '../../components/sections/ShouldAuthenticate'
import UserLayout from '../../components/sections/UserLayout'
import { useT } from '../../i18n'
import { StoreState } from '../../redux/reducers'
import { AuthUser } from '../../redux/reducers/authUser.reducer'
import { Order } from '../../redux/reducers/order.reducer'
import style from '../../styles/pages/user-orders.module.scss'

const Orders = () => {
    const t = useT()
    const authUser = useSelector<StoreState, AuthUser | null>(state => state.authUser)

    const [orders, setOrders] = React.useState<Order[]>([])

    React.useEffect(() => {
        if (authUser) {
            const config = getApiRequestConfig(authUser?.['auth-token'])
            api.get<Response<Order[]>>('user/orders', config).then(response => {
                if (response.status === 200) {
                    setOrders(response.data.data)
                } else {
                    // eslint-disable-next-line no-throw-literal
                    throw { response }
                }
            }).catch(() => { })
        }
    }, [authUser])

    return <ShouldAuthenticate>
        <UserLayout>
            <div className={style.title}>{t("My Orders")}</div>
            {orders.reverse().map(order => {
                return <UserOrder
                    order={order}
                    key={order.id}
                    role='USER'
                />
            })}
        </UserLayout>
    </ShouldAuthenticate>
}

export default Orders