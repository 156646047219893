interface CopyProps {
    stroke?: string
    strokeWidth?: number
}

const Copy = (props: CopyProps) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={props.stroke?props.stroke:"#404040"} stroke-width={props.strokeWidth?props.strokeWidth:"1"} stroke-linecap="round" stroke-linejoin="round" className="feather feather-copy"><rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
    </svg>
}

export default Copy;