import { OptionProps } from '../components/utils/Select'
import { AdminPlan } from '../redux/reducers/authAdmin.reducer'
import { OrderType, PaymentMode } from '../redux/reducers/order.reducer'
import { ShopCategory, ShopCurrency } from '../redux/reducers/shop.reducer'
import { VoucherMode } from '../redux/reducers/voucher.reducer'

export const CATEGORY_OPTIONS: OptionProps<ShopCategory, ShopCategory>[] = [
    {
        text: 'Food',
        value: 'Food'
    },
    {
        text: 'Product',
        value: 'Product'
    },
    {
        text: 'Event',
        value: 'Event'
    },
    {
        text: 'Booking',
        value: 'Booking'
    },
    // {
    //     text: 'Event',
    //     value: 'Event'
    // },
    // {
    //     text: 'General',
    //     value: 'General'
    // },
    // {
    //     text: 'Software',
    //     value: 'Software'
    // },
    // {
    //     text: 'Weekly Subscription',
    //     value: 'Weekly Subscription'
    // }
]

export const PLAN_OPTIONS: OptionProps<AdminPlan, AdminPlan>[] = [
    {
        text: 'Starter',
        value: 'Starter'
    },
    {
        text: 'Economy',
        value: 'Economy'
    },
    {
        text: 'Standard',
        value: 'Standard'
    },
    {
        text: 'Premium',
        value: 'Premium'
    },

]
export const CURRENCY: OptionProps<ShopCurrency, ShopCurrency>[] = [{ text: 'EUR', value: 'EUR' }, { text: 'SEK', value: 'SEK' }]
export const DEFAULT_LANGUAGES = ['english', 'finnish']
export const ORDER_TYPES: OrderType[] = ['home', 'pick_up']
export const PAYMENT_MODES: PaymentMode[] = ['card', 'upi', 'cash', 'paytrail', 'bank-transfer']
export const VOUCHER_MODES: VoucherMode[] = ['amount', 'percentage']

export const BLACK = '#000000'

export const MONTH_NAMES = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

export const ONE_SECOND = 1000
export const ONE_MINUTE = 60000