import React, { useEffect, useState } from 'react'
import { Carousel, Col, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { UserContext, UserContextType } from '../../context/UserProvider'
import { useT } from '../../i18n'
import { appointmentAddToCart } from '../../redux/actions'
import { StoreState } from '../../redux/reducers'
import { Appointment } from '../../redux/reducers/appointment.reducer'
import { CartItem } from '../../redux/reducers/cart.reducer'
import { AppDispatch } from '../../redux/store'
import style from '../../styles/components/add-to-cart.module.scss'
import formStyle from '../../styles/components/form.module.scss'
import { dayNames, getAppointmentOfferPrice, getAppointmentPrice, monthNames, toCurrencyFormat, toString } from '../../utils'
import { useToast } from '../ToastProvider'
import Button from '../utils/Button'
import Close from '../utils/Close'
import Modal from './Modal'


interface AddToCartAppointmentProps {
    onClose?: () => void
    cartId: string | null
    appointment: Appointment
}

const AppointmentAddToCart = (props: AddToCartAppointmentProps) => {
    const userProvider = React.useContext<UserContextType | null>(UserContext)
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>()
    const toast = useToast()
    const t = useT()

    const cartItem = useSelector<StoreState, CartItem | null>(state => state.cart.items.find(c => c.id === props.cartId) || null)

    const [actualImage, setActualImage] = useState<string>(props.appointment.image);
    const [showFullDescription, setShowFullDescription] = useState<boolean>(false);
    const descriptionLength = (props.appointment.descriptions[0].description ? props.appointment.descriptions[0].description.length : 0)
    const [appointmentDate, setAppointmentDate] = useState<string>("")
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    // function gmapHandler() {
    //     window.open("https://www.google.com/maps/place/" + props.appointment.location, '_blank')
    // }

    useEffect(() => {
        const fromDate = props.appointment?.date;
        const from = new Date(fromDate ? fromDate : '');
        const date = dayNames[from.getDay()] + ', ' + from.getDate() + 'th ' + monthNames[from.getMonth()] + ', ' + from.getFullYear();
        setAppointmentDate(date)
    }, [props])

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const appointmentAddToCartClickHandler = () => {

        dispatch(appointmentAddToCart({
            appointmentId: props.appointment.id,
            names: props.appointment.names.map((n: { language: string, name: string }) => ({ language: toString(n.language), name: toString(n.name) })),
            prices: props.appointment.prices,
            category: props.appointment.categories[0].id,
            // fromTime: props.appointment.slots[0].fromTime,
            // toTime: props.appointment.slots[1].toTime,
            slots: props.appointment.slots,
            capacity: props.appointment.capacity,
            price: props.appointment.price,
            offerPrice: props.appointment.offerPrice,
            type: props.appointment.type
        }, props.appointment.shopId))
        navigate("/checkout-appointment");
        props.onClose?.()
    }

    return <><Modal animate>

        <div className={style.container}>
            <div className={style.container}>

                <div className='px-4 pt-4'>
                    <div className='d-flex align-items-center justify-content-between mb-3'>

                        <div className={style.headerText}>{t("BOOK APPOINTMENTS")}</div>
                        <Close onClose={props.onClose} />

                    </div>

                    <div className='row mb-3 '>
                        <div className='col-lg-6 col-sm-12 col-xs-12 col-md-6'>
                            {/* <div className=''> */}
                            {/* <div className={style.imageContainer}>
                                <img src={props.food.image} alt={props.food.names[0].name} />
                            </div> */}

                            {/* </div> */}
                            <Row>
                                {width > 500 &&
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        {props.appointment.additionalImages.length !== 0 ?

                                            <Row>
                                                <Col lg={3} md={3} sm={3} xs={3}>
                                                    <p>
                                                        <img
                                                            src={props.appointment.image}
                                                            style={{ cursor: "pointer" }}
                                                            onMouseOver={() => setActualImage(props.appointment.image)}
                                                            alt=""
                                                            width="70px" height="70px" className="img-thumbnail"
                                                        /></p>
                                                    {props.appointment.additionalImages.map((additionalimage: string, index: number) => (


                                                        <p><img
                                                            src={additionalimage !== undefined ? additionalimage : ""}
                                                            onMouseOver={() => setActualImage(additionalimage)}
                                                            style={{ cursor: "pointer" }}
                                                            alt="" width="70px" height="70px" className="img-thumbnail" /></p>

                                                    ))}
                                                </Col>
                                                <Col lg={9} md={9} sm={9} xs={9}>


                                                    <img src={actualImage ? actualImage : ''} alt="icon" className={style.bigImage} />

                                                </Col>
                                            </Row>
                                            :
                                            <img src={props.appointment.image ? props.appointment.image : ''} alt="icon" className={style.bigImage} />
                                        }

                                    </Col>
                                }
                                {width <= 500 &&
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        {props.appointment.additionalImages.length !== 0 ?
                                            <Carousel fade={true} slide={false}>

                                                <Carousel.Item key={0} interval={3000} >

                                                    <img
                                                        className="d-block w-100"
                                                        src={(props.appointment.image && props.appointment.image !== null) ? props.appointment.image : ''}
                                                        alt={`${0} slide`}
                                                    />

                                                    <Carousel.Caption />

                                                </Carousel.Item>

                                                {props.appointment.additionalImages.map((additionalImage: string, index: number) => (
                                                    <Carousel.Item key={index + 1} interval={3000} >

                                                        <img
                                                            className="d-block w-100"
                                                            src={(additionalImage && additionalImage !== null) ? additionalImage : ''}
                                                            alt={`${index + 1} slide`}
                                                        />

                                                        <Carousel.Caption />

                                                    </Carousel.Item>
                                                ))}

                                            </Carousel>
                                            :
                                            <img src={props.appointment.image ? props.appointment.image : ''} alt="icon" className={style.bigImage} />
                                        }

                                    </Col>
                                }
                            </Row>
                        </div>
                        <div className='col-lg-6 col-sm-12 col-xs-12 col-md-6'>
                            <div className={`text-capitalize ${style.productName}`}>{props.appointment.names[0].name}</div>
                            {/* <div className='row'>
                                <div className='row'>
                                    <div className='col-12 mb-2'>
                                        <label className={formStyle.sectionHeader}>Date</label>
                                        <div>
                                            <DatePicker value={date} />
                                        </div>
                                    </div>
                                </div>

                                <label className={formStyle.sectionHeader}>Time</label>
                                <div className='col-6'>
                                    <FormInput
                                        type='time'
                                        label={"From"}
                                        placeholder={"From"}
                                        value={fromTime}
                                        onChange={fromTimeChangeHandler}
                                        errorText={fromTimeError}
                                    // containerClass='mb-4'
                                    />
                                </div>
                                <div className='col-6'>
                                    <FormInput
                                        type='time'
                                        label={"To"}
                                        placeholder={"To"}
                                        value={toTime}
                                        onChange={toTimeChangeHandler}
                                        errorText={toTimeError}
                                    // containerClass='mb-4'
                                    />
                                </div>
                            </div> */}
                        </div>

                        <p className={`my-1 ${style.description} ${!showFullDescription ? style.less : ''}`} dangerouslySetInnerHTML={{ __html: props.appointment.descriptions[0].description || '' }} >
                        </p>

                        {descriptionLength > 150 && <span className='text-primary float-end cursor-pointer' style={{
                            fontSize: '14px',
                            fontWeight: '400',
                        }} role='button' onClick={() => setShowFullDescription(!showFullDescription)}>{showFullDescription ? t('Less') : t('More')}</span>}
                    </div>
                </div>
                <div className={`${style.editOrderContent} px-4`}>
                    {props.appointment.prices.length > 0 && <div className='mb-4'>
                        <div className={formStyle.sectionHeader}>{("Appointment Fees")}:</div>
                        {props.appointment.prices?.map((p: any) => {
                            const priceIndex = props.appointment.prices.findIndex((fp: any) => fp.size === p.size)
                            const price = getAppointmentPrice(props.appointment, priceIndex)
                            const offerPrice = getAppointmentOfferPrice(props.appointment, priceIndex)
                            return (
                                <div className='hstack gap-2 justify-content-between'>
                                    <span className='text-capitalize'>{p.size}</span>
                                    <div className='hstack gap-2'>
                                        {offerPrice > 0
                                            ? <React.Fragment>
                                                <span>
                                                    {'('}
                                                    <span className={style.offerPrice}>{toCurrencyFormat(price)}</span>
                                                </span>
                                                <span>
                                                    {toCurrencyFormat(offerPrice)}{')'}
                                                </span>
                                            </React.Fragment>
                                            : <span>{'('}{toCurrencyFormat(price)}{')'}</span>}
                                    </div>
                                </div>)
                        })}
                    </div>}
                    {/* <div className={formStyle.sectionHeader}>{t("Comments")}:</div>
                    <TextArea
                        onChange={e => setComment(e.target.value)}
                        value={comment}
                    /> */}

                </div>

            </div>

            <div className='px-4 pb-4'>
                <div className='mb-3' />
                <Button className={formStyle.saveButton} onClick={appointmentAddToCartClickHandler}>{t("Book Now")}</Button>
                <div className='mb-3' />

            </div>
        </div>
    </Modal ></>
}

export default AppointmentAddToCart