import i18n from 'i18next'
import { initReactI18next, useTranslation } from 'react-i18next'
import * as resources from './resources'

type TKey = keyof typeof resources.english
export type TranslationLanguage = keyof typeof resources

i18n.use(initReactI18next).init({
    resources: {
        ...Object.entries(resources).reduce((acc, [key, value]) => ({
            ...acc,
            [key]: {
                translation: value,
            },
        }), {}),
    },
    lng: 'english',
    fallbackLng: 'english',
    interpolation: {
        escapeValue: false
    }
})

export const useT = () => {
    const { t } = useTranslation()
    return (key: TKey | TKey[]) => t(key)
}

export const translationLanguages = Object.keys(resources) as TranslationLanguage[]

export default i18n