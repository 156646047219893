
export enum SizeActionTypes {
    ADD_SIZES = 'ADD_SIZES',
    ADD_SIZE = 'ADD_SIZE',
}

export interface AddSizeAction {
    type: SizeActionTypes.ADD_SIZE
    data: string
}

export interface AddSizesAction {
    type: SizeActionTypes.ADD_SIZES
    data: string[]
}

export type SizeActions = AddSizeAction | AddSizesAction

export const addSize = (size: string): AddSizeAction => {
    return {
        type: SizeActionTypes.ADD_SIZE,
        data: size
    }
}

export const addSizes = (sizes: string[]): AddSizesAction => {
    return {
        type: SizeActionTypes.ADD_SIZES,
        data: sizes
    }
}