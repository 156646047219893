import React from 'react'
import { useT } from '../../i18n'
import style from '../../styles/components/admin-layout.module.scss'
import Archive from '../svgs/Archive'
import Home from '../svgs/Home'
import DivisionHeader from './DivisionHeader'
import Footer from './Footer'
import SideNavbar from './SideNavbar'

interface DivisionLayoutProps {
    children?: React.ReactNode
}

const DivisionLayout = (props: DivisionLayoutProps) => {
    const t = useT()
    return <React.Fragment>
        <DivisionHeader />
        <div className={style.container}>
            <div className='d-flex'>
                <SideNavbar
                    isActive={false}
                    navbarItemList={[
                        {
                            label: t('Home'),
                            to: '/division',
                            icon: <Home stroke='#404040' strokeWidth={1} />
                        },
                        {
                            label: t('Shops'),
                            to: '/division/divisionShops',
                            icon: <Archive stroke='#404040' strokeWidth={1} />
                        },
                    ]}
                    light
                />
                <div className={style.mainContent}>
                    <div className='container pt-3'>
                        {props.children}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    </React.Fragment>
}

export default DivisionLayout