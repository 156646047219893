import { nanoid } from '@reduxjs/toolkit'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { api } from '../../api'
import { useT } from '../../i18n'
import { updateShopTheme } from '../../redux/actions'
import { StoreState } from '../../redux/reducers'
import { AuthAdmin } from '../../redux/reducers/authAdmin.reducer'
import { AppDispatch } from '../../redux/store'
import style from '../../styles/components/form.module.scss'
import { isValidHexColor, toString } from '../../utils'
import { ToastType, useToast } from '../ToastProvider'
import SlideForm, { Slide } from '../forms/SlideForm'
import Camera from '../svgs/Camera'
import Edit from '../svgs/Edit'
import AddMore from '../utils/AddMore'
import Button from '../utils/Button'
import ColorPicker from '../utils/ColorPicker'
import ErrorText from '../utils/ErrorText'
import FormInput from '../utils/FormInput'
import Label from '../utils/Label'
import SmallImage from '../utils/SmallImage'

const ShopTheme = () => {
    const dispatch = useDispatch<AppDispatch>()
    const toast = useToast()
    const t = useT()

    const authAdmin = useSelector<StoreState, AuthAdmin | null>(state => state.authAdmin)
    const [onCamera, setOnCamera] = React.useState(false)

    const [bannerImage, setBannerImage] = React.useState<any>()
    const [bannerImageError, setBannerImageError] = React.useState<string>('')
    const [primaryColor, setPrimaryColor] = React.useState<string>('')
    const [primaryColorError, setPrimaryColorError] = React.useState<string>('')
    const [secondaryColor, setSecondaryColor] = React.useState<string>('')
    const [secondaryColorError, setSecondaryColorError] = React.useState<string>('')
    const [slides, setSlides] = React.useState<Slide[]>([])
    const [slidesError, setSlidesError] = React.useState<string>('')
    const [showSlideForm, setShowSlideForm] = React.useState<boolean>(false)
    const [loading, setLoading] = React.useState<boolean>(false)

    const primaryColorChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setPrimaryColor(e.target.value)
        setPrimaryColorError('')
    }

    const secondaryColorChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSecondaryColor(e.target.value)
        setSecondaryColorError('')
    }

    const bannerImageChangeHandler: React.ChangeEventHandler<HTMLInputElement | HTMLFormElement> = async (e) => {
        let response: any
        if (onCamera) {
            const formData: any = new FormData()
            formData.append("file", e.target.files[0]);
            response = await api.post<Response>(`upload-img/banner/${authAdmin?.id}`, formData);
        }
        onCamera ? setBannerImage(response.data.data) : setBannerImage(e.target.value)
        setBannerImageError('')
    }

    const slideSaveHandler = (slide: Slide) => {
        setSlides(prev => [...prev, slide])
        setSlidesError('')
    }

    const saveClickHandler = () => {
        setLoading(true)
        dispatch(updateShopTheme({
            bannerImage: toString(bannerImage) || null,
            primaryColor: toString(primaryColor) && isValidHexColor(primaryColor) ? toString(primaryColor) : null,
            secondaryColor: toString(secondaryColor) && isValidHexColor(secondaryColor) ? toString(secondaryColor) : null,
            slides: slides.map(slide => ({
                image: toString(slide.image),
                productUrl: toString(slide.productUrl),
                title: slide.title,
                description: slide.description
            }))
        })).then(() => {
            toast(t('Theme updated'))
        }).catch(text => {
            toast(text, ToastType.ERROR)
        }).finally(() => {
            setLoading(false)
        })
    }

    React.useEffect(() => {
        setPrimaryColor(authAdmin?.shop.theme.primaryColor || '')
        setSecondaryColor(authAdmin?.shop.theme.secondaryColor || '')
        setBannerImage(authAdmin?.shop.theme.bannerImage || '')
        setSlides(authAdmin?.shop.theme.slides.map(slide => ({
            id: nanoid(),
            image: slide.image,
            productUrl: slide.productUrl,
            title: slide.title,
            description: slide.description
        })) || [])
    }, [authAdmin])

    return <React.Fragment>
        {showSlideForm && <SlideForm
            id={authAdmin?.id}
            onClose={() => setShowSlideForm(false)}
            onSave={slideSaveHandler}
        />}
        <div className='col-sm-6 col-md-4 col-lg-3'>
            <ColorPicker
                label={t('Primary color')}
                placeholder={t('Primary color')}
                containerClass='mb-4'
                value={primaryColor}
                onChange={primaryColorChangeHandler}
                errorText={primaryColorError}
            />
        </div>
        <div className='col-sm-6 col-md-4 col-lg-3'>
            <ColorPicker
                label={t('Secondary color')}
                placeholder={t('Secondary color')}
                containerClass='mb-4'
                value={secondaryColor}
                onChange={secondaryColorChangeHandler}
                errorText={secondaryColorError}
            />
        </div>
        <div className='col-12'>
            <FormInput
                type={onCamera ? 'file' : 'text'}
                label={t('Banner Image')}
                placeholder={t('Banner Image')}
                value={onCamera ? bannerImage.filename : bannerImage}
                onChange={bannerImageChangeHandler}
                rightRenderer={!onCamera ? <Camera strokeWidth={2} /> : <Edit />}
                errorText={bannerImageError}
                onRightRendererClick={() => setOnCamera(onCamera === true ? false : true)}
                containerClass='mb-4'
            />
            {bannerImage && <div className='mb-4'>
                <SmallImage image={bannerImage} />
            </div>}
        </div>
        <div className='col-12 mb-4'>
            <Label>{t("Slides")}</Label>
            <div className='hstack gap-2 flex-wrap position-relative'>
                {slides.map(slide => {
                    const closeClickHandler = () => {
                        setSlides(prev => prev.filter(p => p.id !== slide.id))
                    }

                    return <SmallImage
                        image={slide.image}
                        key={slide.id}
                        onClose={closeClickHandler}
                    />
                })}
                <AddMore
                    onClick={() => setShowSlideForm(true)}
                    title={t('Add more slides')}
                    errorText={slidesError}
                />
                <ErrorText
                    errorText={slidesError}
                    className='mt-1'
                />
            </div>
        </div>
        <div className='mb-4 col-12'>
            <Button className={style.saveButton} onClick={saveClickHandler} loading={loading}>{t("Save")}</Button>
        </div>
    </React.Fragment>
}

export default ShopTheme