import assets from '../../assets'
import style from '../../styles/components/initial-loader.module.scss'
import Lottie from '../lottie/Lottie'

const InitialLoader = () => {
    return <div className={style.container} id='initLoader'>
        <div className={style.loaderWrapper}>
            <Lottie data={assets.json.shoppingLoader} loop />
        </div>
    </div>
}

export default InitialLoader