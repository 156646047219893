/* eslint-disable no-throw-literal */
import { AppointmentType, Slot } from '../reducers/appointment.reducer'
import { AppointmentCartItemPrice } from '../reducers/appointmentCart.reducer'
import { CartItemName } from '../reducers/cart.reducer'
export enum AppointmentCartActionTypes {
    ADD_APPOINTMENT_CART_ITEM = 'ADD_EVET_CART_ITEM',
    REMOVE_APPOINTMENT_CART_ITEM = 'REMOVE_APPOINTMENT_CART_ITEM'
}

interface AddToAppointmentCart {
    appointmentId: string
    names: CartItemName[]
    cultureVouchers?: string[]
    prices: AppointmentCartItemPrice[]
    fromTime?: string
    toTime?: string
    capacity: number
    category: string
    slots: Slot[]
    type: AppointmentType
    price: number
    offerPrice: number
}

export interface AddAppointmentCartItemAction {
    type: AppointmentCartActionTypes.ADD_APPOINTMENT_CART_ITEM
    data: {
        shopId: string
        item: AddToAppointmentCart
    }
}

export interface RemoveAppointmentCartItemAction {
    type: AppointmentCartActionTypes.REMOVE_APPOINTMENT_CART_ITEM
}

export type AppointmentCartActions = AddAppointmentCartItemAction | RemoveAppointmentCartItemAction

export const appointmentAddToCart = (item: AddToAppointmentCart, shopId: string): AddAppointmentCartItemAction => {

    return {
        type: AppointmentCartActionTypes.ADD_APPOINTMENT_CART_ITEM,
        data: {
            shopId,
            item
        }
    }
}

export const appointmentRemoveCartItem = (): RemoveAppointmentCartItemAction => {
    return {
        type: AppointmentCartActionTypes.REMOVE_APPOINTMENT_CART_ITEM
    }
}

