import { Dispatch } from 'redux'
import { api, apiErrorHandler, getApiRequestConfig, Response } from '../../api'
import { GetState } from '../reducers'
import { Rating } from '../reducers/rating.reducer'

export enum RatingActionTypes {
    ADD_RATING = 'ADD_RATING',
    UPDATE_RATING = 'UPDATE_RATING',
    FETCH_RATINGS = 'FETCH_RATINGS',
    DELETE_RATING = 'DELETE_RATING'
}

export interface AddRatingAction {
    type: RatingActionTypes.ADD_RATING
    data: Rating
}

export interface UpdateRatingAction {
    type: RatingActionTypes.UPDATE_RATING
    data: Rating
}

export interface FetchShopRatingsAction {
    type: RatingActionTypes.FETCH_RATINGS
    data: Rating[]
}

export interface DeleteRating {
    id: string
}

export interface DeleteRatingAction {
    type: RatingActionTypes.DELETE_RATING
    data: string
}

export type RatingActions = AddRatingAction | FetchShopRatingsAction | UpdateRatingAction | DeleteRatingAction

interface AddRating {
    rating: number
    userId?: string
}

export const addRating = (input: AddRating) => async (dispatch: Dispatch, getState: GetState) => {
    const authUser = getState().authUser
    const config = getApiRequestConfig(authUser?.['auth-token'])

    return api.post<Response<Rating>>('shop/rating', input, config).then(response => {
        if (response.status === 200) {
            dispatch<AddRatingAction>({
                type: RatingActionTypes.ADD_RATING,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || 'Added')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to add'))
    })
}

export interface UpdateRating {
    Rating: string
    userId?: string
}

export const updateRating = (input: UpdateRating, RatingId: string) => async (dispatch: Dispatch, getState: GetState) => {
    const authAdmin = getState().authAdmin
    const config = getApiRequestConfig(authAdmin?.['auth-token'])

    return api.put<Response<Rating>>(`rating/${RatingId}`, input, config).then(response => {
        if (response.status === 200) {
            dispatch<UpdateRatingAction>({
                type: RatingActionTypes.UPDATE_RATING,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || 'Updated')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to updated'))
    })
}

export const fetchRatings = (shopId: string) => async (dispatch: Dispatch, getState: GetState) => {
    const authUser = getState().authUser
    const config = getApiRequestConfig(authUser?.['auth-token'])

    return api.get<Response<Rating[]>>(`shop/rating/${shopId}`, config).then(response => {
        if (response.status === 200) {
            dispatch<FetchShopRatingsAction>({
                type: RatingActionTypes.FETCH_RATINGS,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || '')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to fetch'))
    })
}

export const deleteRating = (RatingId: string) => async (dispatch: Dispatch, getState: GetState) => {
    const authSuperAdmin = getState().authSuperAdmin
    const config = getApiRequestConfig(authSuperAdmin?.['auth-token'])

    return api.delete(`rating/${RatingId}`, config).then(response => {
        if (response.status === 200) {
            dispatch<DeleteRatingAction>({
                type: RatingActionTypes.DELETE_RATING,
                data: RatingId
            })
            return Promise.resolve<string>(response.data.message || 'Delete Rating')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to delete'))
    })
}