interface TicketOne {
    stroke?: string
    strokeWidth?: number
}


const TicketOne = (props:TicketOne) => {
    return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="24" width="24"><g id="ticket-1--hobby-ticket-event-entertainment-stub-theater-entertainment-culture"><path id="Subtract" stroke="#000" stroke-linecap="round" stroke-linejoin="round" d="M0.8415178571428572 9.916864285714286c0 0.51285 0.41574 0.9285714285714286 0.9285714285714286 0.9285714285714286H11.229957142857144c0.5127571428571429 0 0.9285714285714286 -0.4157214285714286 0.9285714285714286 -0.9285714285714286V8.208571428571428c-0.7524214285714286 -0.2039607142857143 -1.3058500000000002 -0.8916421428571429 -1.3058500000000002 -1.7085714285714286s0.5534285714285714 -1.5046107142857144 1.3058500000000002 -1.7085714285714286V3.083145c0 -0.5128314285714286 -0.4158142857142857 -0.9285714285714286 -0.9285714285714286 -0.9285714285714286H1.7700892857142858c-0.5128314285714286 0 -0.9285714285714286 0.41574 -0.9285714285714286 0.9285714285714286v1.7044114285714287C1.601284285714286 4.986567857142857 2.1618257142857145 5.677824285714286 2.1618257142857145 6.5c0 0.8221757142857143 -0.5605414285714285 1.513432142857143 -1.3203078571428573 1.7124435714285715v1.7044207142857144Z" stroke-width="1"></path><path id="Vector 1870" stroke="#000" stroke-linecap="round" stroke-linejoin="round" d="M8.45871 2.1618257142857145v1.523442142857143" stroke-width="1"></path><path id="Vector 1871" stroke={props.stroke?props.stroke:'#404040'} stroke-linecap="round" stroke-linejoin="round" d="M8.45871 5.738283571428571v1.5234328571428573" stroke-width={props.strokeWidth?props.strokeWidth:'1'}></path><path id="Vector 1872" stroke="#404040" stroke-linecap="round" stroke-linejoin="round" d="M8.45871 9.322021428571428v1.5234142857142858" stroke-width={'1'}></path></g></svg>
}

export default TicketOne