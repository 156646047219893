import { PersonTypes } from '../../context/CheckoutProvider'
import { Action, OtherBookingActionTypes } from '../actions'
import { Category } from './category.reducer'
import { OtherPayments } from './shop.reducer'


export type PaymentModeOtherBooking = 'card' | 'upi' | 'paytrail' | 'bank-transfer' | 'free'
export type PaymentStatus = 'pending' | 'paid' | 'failed'
export type OtherBookingStatus = 'booked' | 'attended' | 'cancelled' | 'failed'


export interface OtherBookingDetails {
    id: string
    title: string
    size: string | null
    price: number
    tax: number | null
    status: boolean | null
    foodPreference: string | undefined
    category: Category[]
    createdAt: string
    updatedAt: string
}

export interface User {
    email: string
}

export interface OtherBooking {
    id: string
    bookingNumber: string
    bookingDate: string
    eventId: { id: string, names: { id: string, language: string, name: string, updatedAt: string, createdAt: string }[], type: string }
    shopId: { id: string, otherPayments: OtherPayments[] }
    user: User
    totalAmount: number
    totalTax: number | null
    billAmount: number
    paymentMode: PaymentModeOtherBooking
    paymentStatus: PaymentStatus
    paidAt: string | null
    bookingStatus: OtherBookingStatus
    bookingDetails: OtherBookingDetails[]
    personTypes?: PersonTypes[]
    cultureVoucher: string
    resendEmailCount:number
    volunteers: string[]
    createdAt: string
    updatedAt: string
    additionalInfo: string
}

const initialState: OtherBooking[] = []

const otherBookingReducer = (state: OtherBooking[] = initialState, action: Action): OtherBooking[] => {
    switch (action.type) {
        case OtherBookingActionTypes.FETCH_OTHER_BOOKINGS: {
            return action.data
        }

        case OtherBookingActionTypes.REPLACE_OTHER_BOOKING: {
            const newState = [...state]
            const index = newState.findIndex(s => s.id === action.data.id)

            if (index > -1) {
                newState[index] = { ...action.data }
            }

            return newState
        }

        default: {
            return state
        }
    }
}

export default otherBookingReducer