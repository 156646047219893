import React from 'react'
import style from '../../styles/components/common-layout.module.scss'
import Footer from './Footer'
import Header from './Header'

interface CommonLayoutProps {
    children?: React.ReactNode
}

const CommonLayout = (props: CommonLayoutProps) => {
    return <React.Fragment>
        <Header />
        <div className={style.container}>
            <div className='container pt-3'>
                {props.children}
            </div>
        </div>
        <Footer />
    </React.Fragment>
}



export default CommonLayout