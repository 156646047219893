import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { ShopContext, ShopContextType, WithFavourite } from '../../context/ShopProvider'
import { UserContext, UserContextType } from '../../context/UserProvider'
import { useT } from '../../i18n'
import { Appointment } from '../../redux/reducers/appointment.reducer'
import { Event } from '../../redux/reducers/event.reducer'
import { Food } from '../../redux/reducers/food.reducer'
import style from '../../styles/components/shop-product-details.module.scss'
import { ResponsiveShopAppointmentCard } from '../cards/ShopAppointmentCard'
import { ResponsiveShopEventCard } from '../cards/ShopEventCard'
import { ResponsiveShopProductCard } from '../cards/ShopProductCard'
import ListPlaceholder from '../placeholders/ListPlaceholder'
import ProductSectionPlaceholder from '../placeholders/ProductSectionPlaceholder'
import Accordion from '../utils/Accordion'
import Search from '../utils/Search'

// const getFilteredFoods = (foods?: (Food & WithFavourite)[], categoryId?: string, searchTerm?: string) => {
//     return foods?.filter(food => {
//         return food.status === 'active' && (categoryId === 'favourites' ? food.favourite : food.categories.some(cat => cat.id === categoryId))
//     }).filter(food => searchTerm !== '' ? food.names.some(n => new RegExp(`^${searchTerm}| ${searchTerm}+`, 'i').test(n.name)) : true) || []
// }

const getFilteredFoods = (foods?: (Food & WithFavourite)[], categoryId?: string, searchTerm?: string) => {
    return foods?.filter(food => {
        return food.status === 'active' && (categoryId === 'favourites' ? food.favourite : food.categories.some(cat => cat.id === categoryId))
    }).filter(food => {
        const nameMatch = searchTerm !== '' ? food.names.some(n => n.name.toLowerCase().includes(searchTerm?.toLowerCase() || '')) : true;
        const codeMatch = searchTerm !== '' ? food.productCode?.toLowerCase().includes(searchTerm?.toLowerCase() || '') : true;
        return nameMatch || codeMatch;
    }) || [];
}

const getFilteredEvents = (events?: (Event & WithFavourite)[], categoryId?: string, searchTerm?: string) => {
    return events?.filter(event => {
        return event.status === 'active' && (categoryId === 'favourites' ? event.favourite : event.categories.some(cat => cat.id === categoryId))
    }).filter(event => searchTerm !== '' ? event.names.some(n => new RegExp(`^${searchTerm}| ${searchTerm}+`, 'i').test(n.name)) : true) || []
}

const getFilteredAppointments = (appointments?: (Appointment & WithFavourite)[], categoryId?: string, searchTerm?: string) => {
    return appointments?.filter(appointment => {
        return appointment.status === 'active' && (categoryId === 'favourites' ? appointment.favourite : appointment.categories.some(cat => cat.id === categoryId))
    }).filter(appointment => searchTerm !== '' ? appointment.names.some(n => new RegExp(`^${searchTerm}| ${searchTerm}+`, 'i').test(n.name)) : true) || []
}
const ShopProductDetails = () => {
    const t = useT()
    const shopContext = React.useContext<ShopContextType | null>(ShopContext)
    const userContext = React.useContext<UserContextType | null>(UserContext)

    const searchRef = React.useRef<HTMLInputElement>(null)
    const [searchQuery, setSearchQuery] = useSearchParams()

    const sortedCategories = shopContext?.categories?.sort((a, b) => a.seqNo - b.seqNo)
    const searchTerm = searchQuery.get('search') || ''

    const searchTermChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchQuery({ search: e.target.value })
    }

    React.useEffect(() => {
        if (searchTerm !== '') {
            searchRef.current?.focus()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return <div className='row'>
        <div className={`col-lg-2 ${style.categorySectionContainer}`}>
            <div className={style.categorySectionContent}>
                <div className={style.categoriesTitle}>{t("Categories")}</div>
                {shopContext?.loading
                    ? <ListPlaceholder />
                    : sortedCategories?.map(cat => {
                        const filteredFoods = getFilteredFoods(shopContext?.foods, cat.id, searchTerm)
                        const filteredEvents = getFilteredEvents(shopContext?.events, cat.id, searchTerm)
                        const filteredAppointments = getFilteredAppointments(shopContext?.appointments, cat.id, searchTerm)
                        return <React.Fragment key={cat.id}>
                            {(filteredFoods.length > 0 || filteredEvents.length > 0 || filteredAppointments.length > 0) && <Category
                                key={cat.id}
                                categoryId={cat.id}
                            >{cat.names[0].name}</Category>}
                        </React.Fragment>
                    })}
            </div>
        </div>
        <div className='col-lg-10'>
            <div className={style.searchInputContainer}>
                <Search
                    onChange={searchTermChangeHandler}
                    value={searchTerm}
                    onFocus={() => {
                        userContext?.mainContentRef?.current?.scrollTo({
                            behavior: 'smooth',
                            top: 270
                        })
                    }}
                    innerRef={searchRef}
                />
            </div>
            {shopContext?.loading
                ? [3, 2].map((size, index) => {
                    return <ProductSectionPlaceholder
                        size={size}
                        key={index}
                    />
                })
                : <React.Fragment>
                    <div className={`${style.foodSectionContent} ${style.smallScreen}`}>
                        <Accordion
                            items={sortedCategories}
                            titleGetter={item => item.names[0].name}
                            showItem={item => !!(shopContext?.foods && getFilteredFoods(shopContext.foods, item.id, searchTerm).length)}
                            renderItemGetter={item => {
                                const filteredFoods = shopContext?.foods && getFilteredFoods(shopContext.foods, item.id, searchTerm)

                                return <div className='row pt-3 px-3'>
                                    {filteredFoods?.map(food => {
                                        return <ResponsiveShopProductCard
                                            key={food.id}
                                            food={food}
                                        />
                                    })}
                                </div>
                            }}
                        />
                    </div>
                    <div className={`${style.foodSectionContent} ${style.largeScreen}`}>
                        {shopContext?.categories?.map(cat => {
                            return <ShopProductCategorySection
                                key={cat.id}
                                title={cat.names[0].name}
                                categoryId={cat.id}
                            />
                        })}
                    </div>

                    {/* Event sectiion */}
                    <div className={`${style.foodSectionContent} ${style.smallScreen}`}>
                        <Accordion
                            items={sortedCategories}
                            titleGetter={item => item.names[0].name}
                            showItem={item => !!(shopContext?.events && getFilteredEvents(shopContext.events, item.id, searchTerm).length)}
                            renderItemGetter={item => {
                                const filteredEvents = shopContext?.events && getFilteredEvents(shopContext.events, item.id, searchTerm)

                                return <div className='row pt-3 px-3'>
                                    {filteredEvents?.map(event => {
                                        return <ResponsiveShopEventCard
                                            key={event.id}
                                            event={event}
                                        />
                                    })}
                                </div>
                            }}
                        />
                    </div>
                    <div className={`${style.foodSectionContent} ${style.largeScreen}`}>
                        {shopContext?.categories?.map(cat => {
                            return <ShopEventCategorySection
                                key={cat.id}
                                title={cat.names[0].name}
                                categoryId={cat.id}
                            />
                        })}
                    </div>
                    {/* Appointment section */}
                    <div className={`${style.foodSectionContent} ${style.smallScreen}`}>
                        <Accordion
                            items={sortedCategories}
                            titleGetter={item => item.names[0].name}
                            showItem={item => !!(shopContext?.appointments && getFilteredAppointments(shopContext.appointments, item.id, searchTerm).length)}
                            renderItemGetter={item => {
                                const filteredAppointments = shopContext?.appointments && getFilteredAppointments(shopContext.appointments, item.id, searchTerm)

                                return <div className='row pt-3 px-3'>
                                    {filteredAppointments?.map(appointment => {
                                        return <ResponsiveShopAppointmentCard
                                            key={appointment.id}
                                            appointment={appointment}
                                        />
                                    })}
                                </div>
                            }}
                        />
                    </div>
                    <div className={`${style.foodSectionContent} ${style.largeScreen}`}>
                        {shopContext?.categories?.map(cat => {
                            return <ShopAppointmentCategorySection
                                key={cat.id}
                                title={cat.names[0].name}
                                categoryId={cat.id}
                            />
                        })}
                    </div>
                </React.Fragment>}
        </div>
    </div>
}

interface CategoryProps {
    children?: React.ReactNode
    categoryId?: string
}

const Category = (props: CategoryProps) => {
    const shopContext = React.useContext(ShopContext)

    return <div className={style.categoryListItem}>
        <a href={'#' + props.categoryId} className={props.categoryId === shopContext?.activeCategory ? style.active : ''}>{props.children}</a>
    </div>
}

interface ShopProductCategorySectionProps {
    title?: string
    categoryId?: string
}

const ShopProductCategorySection = (props: ShopProductCategorySectionProps) => {
    const shopContext = React.useContext<ShopContextType | null>(ShopContext)
    const userContext = React.useContext<UserContextType | null>(UserContext)
    const sectionRef = React.useRef<HTMLDivElement>(null)

    const [searchQuery] = useSearchParams()

    const searchTerm = searchQuery.get('search') || ''
    const filteredFoods = shopContext?.foods && getFilteredFoods(shopContext.foods, props.categoryId || '', searchTerm)

    React.useEffect(() => {
        const handler = () => {
            const rect = sectionRef.current?.getBoundingClientRect()
            if (rect) {
                if (rect.top > (146 - (rect.height + 12)) && rect.top < 146) {
                    shopContext?.onActiveCategoryChange?.(props.categoryId)
                }
            }
        }

        userContext?.mainContentRef?.current?.addEventListener('scroll', handler)
        return () => userContext?.mainContentRef?.current?.removeEventListener('scroll', handler)
    }, [props.categoryId, shopContext, userContext?.mainContentRef])

    return <React.Fragment>
        {!!filteredFoods?.length &&
            <div id={props.categoryId} className={style.categoryTitleContainer} ref={sectionRef}>
                <div className={style.categoryTitle}>{props.title}</div>
                <div className='row'>
                    {filteredFoods?.map(food => {
                        return <ResponsiveShopProductCard
                            key={food.id}
                            food={food}
                        />
                    })}
                </div>
            </div>}
    </React.Fragment>
}

interface ShopEventCategorySectionProps {
    title?: string
    categoryId?: string
}

const ShopEventCategorySection = (props: ShopEventCategorySectionProps) => {
    const shopContext = React.useContext<ShopContextType | null>(ShopContext)
    const userContext = React.useContext<UserContextType | null>(UserContext)
    const sectionRef = React.useRef<HTMLDivElement>(null)

    const [searchQuery] = useSearchParams()

    const searchTerm = searchQuery.get('search') || ''
    const filteredEvents = shopContext?.events && getFilteredEvents(shopContext.events, props.categoryId || '', searchTerm)

    React.useEffect(() => {
        const handler = () => {
            const rect = sectionRef.current?.getBoundingClientRect()
            if (rect) {
                if (rect.top > (146 - (rect.height + 12)) && rect.top < 146) {
                    shopContext?.onActiveCategoryChange?.(props.categoryId)
                }
            }
        }

        userContext?.mainContentRef?.current?.addEventListener('scroll', handler)
        return () => userContext?.mainContentRef?.current?.removeEventListener('scroll', handler)
    }, [props.categoryId, shopContext, userContext?.mainContentRef])

    return <React.Fragment>
        {!!filteredEvents?.length &&
            <div id={props.categoryId} className={style.categoryTitleContainer} ref={sectionRef}>
                <div className={style.categoryTitle}>{props.title}</div>
                <div className='row'>
                    {filteredEvents?.map(event => {
                        return <ResponsiveShopEventCard
                            key={event.id}
                            event={event}
                        />
                    })}
                </div>
            </div>}
    </React.Fragment>
}
interface ShopAppointmentCategorySectionProps {
    title?: string
    categoryId?: string
}
const ShopAppointmentCategorySection = (props: ShopAppointmentCategorySectionProps) => {
    const shopContext = React.useContext<ShopContextType | null>(ShopContext)
    const userContext = React.useContext<UserContextType | null>(UserContext)
    const sectionRef = React.useRef<HTMLDivElement>(null)

    const [searchQuery] = useSearchParams()

    const searchTerm = searchQuery.get('search') || ''
    const filteredAppointments = shopContext?.appointments && getFilteredAppointments(shopContext.appointments, props.categoryId || '', searchTerm)

    React.useEffect(() => {
        const handler = () => {
            const rect = sectionRef.current?.getBoundingClientRect()
            if (rect) {
                if (rect.top > (146 - (rect.height + 12)) && rect.top < 146) {
                    shopContext?.onActiveCategoryChange?.(props.categoryId)
                }
            }
        }

        userContext?.mainContentRef?.current?.addEventListener('scroll', handler)
        return () => userContext?.mainContentRef?.current?.removeEventListener('scroll', handler)
    }, [props.categoryId, shopContext, userContext?.mainContentRef])

    return <React.Fragment>
        {!!filteredAppointments?.length &&
            <div id={props.categoryId} className={style.categoryTitleContainer} ref={sectionRef}>
                <div className={style.categoryTitle}>{props.title}</div>
                <div className='row'>
                    {filteredAppointments?.map(appointment => {
                        return <ResponsiveShopAppointmentCard
                            key={appointment.id}
                            appointment={appointment}
                        />
                    })}
                </div>
            </div>}
    </React.Fragment>
}

export default ShopProductDetails