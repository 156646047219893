import React from 'react'
import style from '../../styles/components/input.module.scss'
import ErrorText from './ErrorText'
import Input, { InputProps } from './Input'
import CountryCode from './CountryCode'

interface FormInputProps extends InputProps {
    label?: string
    labelRightRenderer?: React.ReactNode | React.ReactNode[]
    containerClass?: string
    uniqueId?: number
    countryCode?:(value:string)=>void | undefined
    required?: boolean
}

const FormInput = ({ label, required, labelRightRenderer, containerClass, uniqueId,countryCode, ...props }: FormInputProps) => {
    const [country,setCounty] = React.useState<boolean>(false);

    const countryCodeHandler =()=>{
        country?setCounty(false):setCounty(true);
    }

    return <div className={`${style.container} ${containerClass || ''}`}>
        {(label || labelRightRenderer) && <div className={style.labelContanier}>
            <label title={label} htmlFor={label} className={style.label}>{label} {required && <span className={style.required}>*</span>}</label>
            {labelRightRenderer}
        </div>}
        <Input id={(label || '') + (uniqueId || '')} {...props} countryCodeHandler={countryCode?countryCodeHandler:()=>{}}/>
        {countryCode&&<CountryCode active={country?true:false} setCountryCode={(v)=>{countryCode?.(v); setCounty(false)}}/>}
        <ErrorText errorText={props.errorText} />
    </div>
}

export default FormInput