import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { UserContext } from '../../context/UserProvider'
import { useT } from '../../i18n'
import { StoreState } from '../../redux/reducers'
import { AuthUser } from '../../redux/reducers/authUser.reducer'
import style from '../../styles/components/footer.module.scss'
import Apple from '../svgs/Apple'
import Facebook from '../svgs/Facebook'
import GooglePlayStore from '../svgs/GooglePlayStore'
import Insta from '../svgs/Insta'
import Linkedin from '../svgs/Linkedin'

const Footer = () => {
    const t = useT()
    const authUser = useSelector<StoreState, AuthUser | null>(state => state.authUser)
    const userContext = React.useContext(UserContext)

    const signInClickHandler = () => {
        if (!authUser) userContext?.setShowSignIn?.(true)
    }

    return <footer className={style.container}>
        <div className='container'>
            <div className='row'>
                <div className='col-sm-6 col-lg-3'>
                    <div className={`${style.sectionHeader} mb-2`}>{t("GET TO KNOW US")}</div>
                    <ul className='ps-0 vstack gap-0 m-0 p-0' style={{ listStyleType: 'none' }}>
                        <li>
                            <Link to='/blog' className={style.link}>{t("Blog")}</Link>
                        </li>
                        <li className={style.link}>
                            <Link to='/about-us' className={style.linkLabel}>{t("About us")}</Link>
                        </li>
                        <li className={style.link}>
                            <Link to='/contact-us' className={style.linkLabel}>{t("Contact us")}</Link>
                        </li>
                        <li className={style.link}>
                            <Link to='/admin' className={style.linkLabel}>{t("Restaurant sign up")}</Link>
                        </li>
                        <li className={style.link}>{t("Cookie policy")}</li>
                        <li className={style.link}>
                            <Link to='/privacy-policy' className={style.linkLabel}>{t("Privacy policy")}</Link>
                        </li>
                        {/* <li className={style.link}>Modern slavery statement</li> */}
                        <li className={style.link}>
                            <Link to='/terms-and-conditions' className={style.linkLabel}>{t("Terms and conditions")}</Link>
                        </li>
                    </ul>
                </div>
                <div className='col-sm-6 col-lg-3'>
                    <div className={`${style.sectionHeader} mb-2`}>{t("CUSTOMER SERVICE")}</div>
                    <ul className='ps-0 vstack gap-0 m-0 p-0'>
                        {/* <li className={style.link}>My account</li> */}
                        <li className={style.link} onClick={signInClickHandler}>{t("Sign up")}</li>
                        <li className={style.link}>{t("Help")}</li>
                        {/* <li className={style.link}>Price promise</li> */}
                        <li className={style.link}>{t("Download order management app")}</li>
                    </ul>
                </div>
                <div className='col-lg-6'>
                    <div className={`${style.sectionHeader} mb-2`}>{t("ORDER EVERYTHING YOU LOVE")}</div>
                    <div className={style.placeOrderOnApp}>{t("Place an order on our app. Go ahead, download us.")}</div>

                    <div className='mb-2'>
                        <div className='row'>
                            <div className='col-sm-6 col-md-5 col-lg-12 col-xl-6 col-xxl-5 mb-3'>
                                <div className={style.button}>
                                    <GooglePlayStore />
                                    <div>
                                        <div className={style.downloadFrom}>{t("Download the app from")}</div>
                                        <div className={style.label}>Google Play</div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-sm-6 col-md-5 col-lg-12 col-xl-6 col-xxl-5'>
                                <div className={style.button}>
                                    <Apple />
                                    <div>
                                        <div className={style.downloadFrom}>{t("Download the app from")}</div>
                                        <div className={style.label}>App store</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`${style.sectionHeader} mb-2`}>{t("FOLLOW US")}</div>
                    <div className='hstack gap-3'>
                        <a href='https://www.facebook.com/vshops.fi' target='_blank' rel='noreferrer'>
                            <Facebook />
                        </a>
                        <a href='https://www.linkedin.com/company/cloudronics-solutions-oy/' target='_blank' rel='noreferrer'>
                            <Linkedin />
                        </a>
                        {/* <TwitterX /> */}
                        <a href='https://www.instagram.com/vshopseu/' target='_blank' rel='noreferrer'>
                            <Insta />
                        </a>
                    </div>
                </div>
            </div>
            <div className={style.copyRight}>&copy; vshops.fi 2020 - {new Date().getFullYear()}</div>
        </div>
    </footer>
}

export default Footer