import CommonLayout from '../components/sections/CommonLayout'
import { useT } from '../i18n'
import style from '../styles/pages/privacy-policy.module.scss'

const TermsAndConditions = () => {
    const t = useT()

    return <CommonLayout>
        <div>
            <h1 className={style.title}>{t("Terms and conditions")}</h1>

            <h1 className={style.subtitle}>
                {t("DISCLAIMER")}
            </h1>

            <div>
                <div>
                    <p>

                        {t("This document is intended for")}
                        [<strong>
                            {t("vshops and its subdomains")}
                        </strong>],
                        {t("now known as the website and is referred to as the service that is served by")}
                        [<strong>
                            Cloudronics Solutions Oy, Y-Tunnus:2760646-7
                        </strong>],
                        {t("a Finland corporation and the owner, operator of vshops.fi,vshops.eu now known as the company and referred to as we, us, our in the context")}.
                    </p>

                    <p>
                        {t("This page details the Terms of service and/or Privacy policy that govern the usage of shops, its subdomains and applications that are available (or will be made available) for iOS and Android ecosystem")}.
                    </p>

                    <p>
                        {t(`By using this service, you acknowledge and agree to the Terms ofservice  and Privacy policy , the links to which can be found on this page and which are incorporated herein by reference. These Terms of service constitute a legally binding agreement between you (whether personally or on behalf or an organization) and us. If you are using this service on behalf of an organization or a business or an entity, then you are agreeing to these Terms of service and Privacy policy on behalf of that organization and that you warrant that you have the authority to bind that organization or business or entity to these terms. In all such cases, you and yours refers to that specific organization or business or entity.`)}
                    </p>

                    <p>
                        {t(" Should you disagree with any of our Terms of service and/or Privacy policy, then you are expressly prohibited from using our service and you must discontinue its use immediately. Any references to Law or Local law means the law of the land (the highest administrative unit or judicial authority) where our business is registered and operates from a physical location within that land - in our case: Finland.")}
                    </p>

                    <p>
                        {t(" Changes to the Terms of service and Privacy policy: We can update the Terms of service and Privacy policy from time to time. All changes thereafter will be made available on this website, which is why we encourage you to frequently check this page for any changes. Please note, changes hereto will not prejudice your rights without your consent. We will inform you of any material changes by sending an email or other such applicable way.")}
                    </p>

                    <p>
                        {t("Should you have any questions regarding the service, the Terms of service or the Privacy policy governing the use and storage of data, please feel free to contact us at")}
                        <code>info @ cloudronics.com.</code>
                    </p>

                </div>
            </div>
        </div>
        <div>
            <h2>
                <h1 className={style.subtitle}>
                    {t("TERMS OF SERVICE FOR USERS")}
                </h1>
            </h2>
            <div>
                <div>
                    <p>
                        {t("This document outlines the Terms of Service and acts as a legally binding agreement between the Company (us, the service provider) and you (the user of such a service) - who uses Vshops to order meal from a nearby restaurant, grocery store or similar institution. All such users will be referred to as Consumer or user in the context.")}
                    </p>
                    <p>
                        {t(" Copyright: All the information and content included in or made available through Vshops, such as text, graphics, logos etc are a property of the Company (us, Cloudronics Solutions Oy) or the Businesses (restaurants) that use Vshops and are protected by standard copyright laws.")}
                    </p>
                    <p>
                        {t(" Changes to our service: We may change, update, improve or suspend any of our services or any part of them at any time and without any prior notice. We may amend any of our agreement terms at our sole discretion by posting the revised terms on our website. Your continued use of our services after all such amendments constitutes your acceptance of the terms.")}
                    </p>
                    <p>
                        {t("Application permissions: When you use the mobile or web apps created by us, you may grant certain permissions to us for your device. Such permissions allow our applications to get access to your location, unique ID of your device and to send you notifications. Our applications provide you with the information about such permissions. Such permissions also allow us to provide you with a seamless experience while using our services and they can be updated from time to time. At all such times, you will have full control to allow or deny such permissions - however, denying them might restrict application functionality and may cause them to misbehave or not function as intended. In all such cases, you are solely responsible for the for the consequences that arise due to lack of permissions to the web or mobile application.")}
                    </p>

                    <p>
                        {t("Connectivity: Using Vshops requires a working internet connection (WiFi or Data) on your device and such internet connection is subject to fees, restrictions, terms and limitations imposed by your Internet service provider. It is your responsibility to subscribe to a suitable data or internet")}.
                    </p>
                    <p>
                        {t("Access to location: Using Vshops requires access to your current location (GPS or internet based) so that we can show you a list of nearby restaurants or places that use our services for managing their online orders. Without access to your location, this feature will not work. The service also relies on the TimeZone that is set on your device to fetch the data from our server and might misbehave if the TimeZone is not set in accordance to your current location.")}

                    </p>
                    <p>
                        {t("Information you receive: While using our services, you will receive information related to the Restaurant (or place) including but not limited to their operating hours, services available at their premises, their online food (or item) catalog - including (but not limited to) the prices, information that they need from you before you can order an item etc. All such information is provided as- is and it is the responsibility of the restaurant to make sure that the information is correct and not misleading in any form. We as a Service provider have no control over the content of all such information and cannot be held responsible in any way - should this information be incorrect.")}
                    </p>
                    <p>
                        {t(" Information you provide to others: When you decide to order an item (meal or other) using our service, all the personal and non-personal information related to your request is collected and submitted to our system according to our Privacy policy . In certain special situations and on-demand of the Restaurant from where you are ordering the food, our service collects your answers to questions that are specific to your order. In addition to this, you have an option to provide the Restaurant with any instructions or allergies that you (or for who you are ordering the food) may have. You agree that all such information you provide to us is not misleading in any form and is correct to the best of your knowledge. You also confirm and understand that a false information will affect the quality of service that you are going to receive and may cause a legal action against you, if required (and on a case-by-case basis).")}
                    </p>
                    <p>
                        {t(" List of ingredients and allergies: When you are making an order using our service, we display the list of ingredients in that specific item - which is based on the information provided by the Restaurant (or business) that offers the item. We cannot guarantee that such list will always be accurate or up-to-date and in all such cases, we recommend you to contact the Restaurant directly. We also recommend you to provide any information related to your allergies before creating an order in the form that is provided at the time of the checkout process.")}
                    </p>
                    <h2 className={style.headings}>{t("BY USING OUR SERVICE")}</h2>
                    <p>
                        {t(" You agree that we are just a tool that helps you explore nearby restaurants using your mobile device or computer and order a meal (or food) of your choice to a Restaurant that is using our service.")}
                    </p>
                    <p>
                        {t("You agree to pay for all the purchases that arise as a result of your actions on our service that create an order for a restaurant. Unless explicitly agreed otherwise, payments for all such orders shall be made at the time of ordering by using a relevant payment method like a credit or debit card or via other means of payment available with our service at that time.")}
                    </p>
                    <p>
                        {t("You agree to provide your name, address (when required, for example, delivery), phone number, email address, payment details and other information that is required to make a purchase. You also agree to be of legal age (18, eighteen) and be the lawful owner of any debit or credit card that is used to make the purchase. Your card (or the instrument of purchase) will be charged in full at the time of making the purchase.")}
                    </p>
                    <p>
                        {t("You agree that we (as a service provider) do not guarantee in any form that you will or will not receive the customer service at (or from) the restaurant (or business) where you have placed an order via our service. The act of providing (or denying) customer service is governed completely by the Restaurant (or business), their staff, the customer service policies they have, availability of their kitchen (or resources) and other factors that may effect this decision. We as a service provider have no control (and we do not want any such control) on any such action. Our job is to make the process easy by providing tools and technologies that make such a process easy. We do not and will not influence the process in any way whatsoever.")}
                    </p>
                    <p>
                        {t("You agree that your order is subject to availability and requires a confirmation from the Restaurant (or business) that they are going to process the order. This confirmation depends on multiple factors, including but not limited to availability of raw materials, availability of the kitchen, current number of orders that are being processed by the restaurant (or business). This availability criteria applies on all items in the order. By default, we will create an order based on the general availability and in all such cases when the created order has items (or quantities) less than the actual quantity specified in the original order, you will have an option to cancel the order without making any payment towards such an order or proceed to checkout with the adjusted quantities and price. All such adjusted orders will also be treated as normal orders and will require a confirmation from the Restaurant (or business) before being processed.")}
                    </p>
                    <p>
                        {t("You agree that all the meals that you purchase, unless explicitly mentioned otherwise, are intended for immediate consumption. We as a Service provider and the Restaurant (or business) as the vendor cannot be held responsible for the quality of the meal if it is consumed later.")}
                    </p>
                    <p>
                        {t(" You agree to receive notifications over Email (and / or) PUSH that will help you understand when your order is ready for pick-up or dine-in. It becomes your sole responsibility to visit the restaurant for pick-up or dine-in within a reasonable amount of time after the order has been marked as ready for pick-up or dine-in. We as a Service provider and the Restaurant (or business) as the vendor cannot be held responsible for the quality of the meal if it is consumed later.")}
                    </p>
                    <p>
                        {t("You agree not to misuse Vshops and shall not permit or assist others in any manner whatsoever, which includes but is not limited to (1) using foul language when answering any questions in context of your order; (2) making an order without any intention of turning up at the restaurant (or business) to avail services in context to that order; (3) using available technologies in any form that cause service outage or failure in any form for other users. All such actions can result in you getting banned from using our service for a certain or an indefinite period of time.")}
                    </p>
                    <p>
                        {t(" You agree not to access Vshops and shall not permit or assist others in any manner (1) that violates any applicable law or local law or international law or regulations OR advocates, promotes or assists any unlawful act OR constitutes a threat in any form; (2) that is false, deceptive, misleading or fraudulent, including but not limited to (a) any attempt to impersonate any person or entity, including any other user, person or organization; (b) to misrepresent your identity or affiliation with any person or organization; (3) to monitor or copy materials form the website for any unauthorized purpose or access the website via any automatic device, process or means of access such as a robot or spider (except as it may be the result of standard search engine protocol); (4) to disassemble or decompile or reverse engineer Vshops and the services provided by it and to access them for the purpose of developing a product or service or feature that mimics or competes with Vshops in any form.")}
                    </p>
                    <h2 className={style.headings}>{t("PAYMENTS, CANCELLATION AND REFUND")} </h2>
                    <p>
                        {t("Unless explicitly agreed otherwise, payments for all the orders made through the platform Vshops shall be made at the time of placing the order by using the credit or debit card or the relevant instrument of purchase. The instrument of purchase will be charged in full at the time of making the purchase.")}
                    </p>
                    <p>
                        {t(" Once the order has been placed, it awaits confirmation from the Restaurant (or business) that they are going to process the order or not. During this time (and before the order has been confirmed), you may change your mind any time and cancel the order for any reason or no reason at all. You will receive a refund of the amount you paid minus any service charge(s) or bank charge(s) that may incur as a part of the cancellation process, within 14 days of cancellation. In situations when its not possible to issue a refund, you will be provided with a coupon code which must be used on our service within 12 months of issue.")}
                    </p>
                    <p>
                        {t("In situations when your order is cancelled by the Restaurant, for whatsoever reason, you will receive a confirmation of cancellation via email that you provided during the purchase process, together with the reason of such cancellation. We as a service provider have no control on such cancellations and we do not want any control over such cancellations. In all such situations, you will receive a refund of the amount you paid minus any service charge(s) or bank charge(s) that may incur as a part of the cancellation process, within 14 days of cancellation. In situations when it is not possible to issue a refund, you will be provided with a coupon code which must be used on our service within 12 months of issue.")}
                    </p>
                    <p>
                        {t("Once the order has been made and confirmed by the Restaurant (or business) that they are going to process the order, it is binding to both the parties - you (who has placed the order) and the Restaurant (or business) that is going to provide you the order. In all such situations, the order exceeds it's cancellation period and hence, it cannot be cancelled by any party involved.")}
                    </p>
                    <h2 className={style.headings}>{t("LIABILITIES AND LIMITATIONS TO LIABILITY")}</h2>
                    <p>
                        {t("You are liable to compensate Vshops for any damages direct and/or in-direct that are suffered by Vshops as a result of your breach of the terms specified in this document.")}
                    </p>
                    <p>
                        {t(" We cannot be held liable or responsible for any misuse of the service in any form whatsoever. In addition to this, we cannot be held responsible for any emotional, psychological, physical or financial mishaps of any kind related to using our product or services.")}
                    </p>
                    <p>
                        {t("  We cannot be held liable or responsible for any situations or scenarios that occur outside of our machinery or infrastructure that can cause a delay or failure in providing the intended service via our platform. Such scenarios may include (but are not limited to): (1) failure of third party services like payment gateway or push notification service or email notifications; (2) Restaurant (or business) accepting your order even when they are overbooked or do not have resources in context to your order; (3) you being unable to pick-up or dine-in within a reasonable amount of time for whatsoever reasons.")}
                    </p>
                    <p>
                        {t("We cannot be held liable or responsible for situations or scenarios that arise as a result of your actions which include (but are not limited to): (1) providing misleading or no information about your allergies in context of the order; (2) providing false or misleading contact information like Email address, Phone number. In addition to this, if you have not provided any information related to your allergies (or any specific instructions on how you want the order to be prepared), the option to which is available before making a purchase on our platform, neither us nor the restaurant (or business) can be held liable or responsible for any situations that arise as a result of lack of this information.")}
                    </p>
                    <p>
                        {t(" We cannot be held liable or responsible for situations or scenarios that arise as a result of your actions which include (but are not limited to): (1) poor or no network connection; (2) power saving mode on your device that restricts network or internet access; (3) low battery that restricts network or internet usage; (4) low battery that causes your device to turn off; (4) flight mode turned on your device which restricts or disables internet or network usage.")}
                    </p>
                    <p>
                        {t(" The service Vshops is provided on an  as is, as available and with all faults basis. To the fullest extent permissible by law, neither we nor any of our employees, managers, officers, partners or suppliers make any warranties of any kind, whether express or implied in relation to the services provided by us.")}
                    </p>
                    <h2 className={style.headings}>{t("APPLICABLE LAW AND ARBITRATION")} </h2> <p>
                        {t("The terms mentioned in this document shall be construed and interpreted in accordance with the Laws of Finland and any disputes relating to or arising in connection with this terms (and agreement) shall be finally settled in arbitration. The sole arbitrator is to be appointed by the Arbitration Committee of the Finland Chamber of Commerce and the rules of the said Committee are to be followed in arbitration. The place of arbitration shall be Helsinki - Finland and the language of arbitration shall be English unless all the parties to the the dispute agree otherwise and in written. The award shall be final and binding to both the parties hereto and enforceable in any court of competent jurisdiction.")}
                    </p>

                </div>
            </div>
        </div>
        <div>
            <h2>
                <h1 className={style.subtitle}>
                    {t("TERMS OF SERVICE FOR BUSINESSES")}
                </h1>
            </h2>
            <div>
                <div>
                    <p>
                        {t(" This document outlines the terms of service and acts as a legally binding agreement between the Company (us, the service provider) and You (a restaurant or business, the user of such a service) - who uses Vshops to maintain their online food ordering portal and receive orders from people based on the online catalog. You will be referred to as you, Client, Restaurant, Business, Restaurant owner, Business owner, User in the context. People who make orders to your business using our services will be referred to as Consumers. Vshops will be referred to as the Service, Platform.")}
                    </p>

                    <p>
                        {t(" All of the terms mentioned in this document are valid for all the accounts managed directly by us (Cloudronics Solutions Oy, the company), however, they may be slightly different (for example, the terms of payment, grace period, support resolution time) based on if your account (or account subscription) is managed by a partner, reseller or an appointed sub-contractor. In all such cases when your account is managed by a partner, reseller or an appointed sub-contractor, you should be reading the Terms of service that is available on their website. While this document can act as a backup or a guideline in cases your account or account subscription is managed by a partner, reseller or an appointed sub-contractor, the exact terms may vary depending on the applicable local law, market practices and policies of that specific service provider.")}
                    </p>

                    <p>
                        {t("The terms outlined in this document and the legal binding between you (the user) and us (the service provider) are effective immediately from the moment you've registered your account on our service and remain valid until further notice. It is however possible and on a case-by-case basis that the Terms of agreement between us and you is governed or superseded by a separate Terms and conditions document or a Service level agreement or a Frame agreement which outlines The terms of service applicable between both the parties. Unless explicitly mentioned in that separate document, the Terms of Service will be governed by the terms outlined in this document.")}
                    </p>

                    <p>
                        {t(" By registering an account on our Service, you authorize us to make any inquiries we consider necessary to validate the details that you have provided us for yourself and your Business. This may include asking you for further information and/or documentation and verifying your information against the government register or third-party database or other sources. This process is for verification purposes and to make sure that you (as a person) represent the business for which you are creating an account on our service and that you are authorized to do so.")}
                    </p>

                    <h2 className={style.headings}>{t("BY USING OUR SERVICE AND DURING THE TERM OF THIS AGREEMENT")}</h2>
                    <p>
                        {t(" You agree that you are solely responsible for accuracy of all the account specific data that you provide to us for the purpose of creating an account for your Restaurant (or business) on our Service. This information includes (but may not be limited to) name of restaurant, name of business, vat number, email address, kitchen phone number, full street address of your restaurant, operating hours, Finnish bank account number in IBAN format (for handling the payouts).")}
                    </p>
                    <p>
                        {t("You agree that you are solely responsible for making all arrangements necessary for your Restaurant (or business) and its staff to access Vshops. This includes and is not limited to procuring an internet connection and device(s) that are used to access our service; ensuring that your staff is aware of this service and is trained to use it.")}
                    </p>

                    <p>
                        {t("You agree that our service is just a tool that helps your customers to place an order to your restaurant (or business) from the online catalog that you have published on our service. In this context, we will manage your customer payments on your behalf and we will also provide additional services which may be a part of the subscription plan that you are subscribed to.")}
                    </p>
                    <p>
                        {t(" You agree that you are solely responsible for accuracy of all the content that you publish on our service. This includes (but is not limited to) information like name of the item (that you are selling), it's brief description, price and other optional information like spice level, food category (e.g. vegan, vegetarian) and also the main ingredients that are a part of that item.")}
                    </p>

                    <p>
                        {t("You agree that you have the rights to publish the content that you create on our service and that all such content shall not be libelous or otherwise unlawful, threatening, abusive or obscene material or contain software viruses or any form of spam.")}
                    </p>
                    <p>
                        {t("You agree that we (as a service provider) are not responsible for and do not endorse any content that is posted on our service and do not have any obligation to prescreen, monitor, edit or remove any content. If your content violates our terms, you may bear legal responsibility for that content. We also reserve the right to review the content that is posted on our service from time to time and to remove any content that is libelous, unlawful, threatening, abusive or obscene.")}
                    </p>

                    <p>
                        {t("You agree not to access Vshops and shall not permit or assist others in any manner (1) that violates any applicable law or local law or international law or regulations OR advocates, promotes or assists any unlawful act OR constitutes a threat in any form; (2) that is false, deceptive, misleading or fraudulent, including but not limited to (a) any attempt to impersonate any person or entity, including any other user, person or organization; (b) to misrepresent your identity or affiliation with any person or organization; (3) to monitor or copy materials form the website for any unauthorized purpose or access the website via any automatic device, process or means of access such as a robot or spider (except as it may be the result of standard search engine protocol); (4) to disassemble or decompile or reverse engineer Vshops and the services provided by it and to access them for the purpose of developing a product or service or feature that mimics or competes with Vshops in any form.")}
                    </p>
                    <p>
                        {t("You agree that we will handle, on your behalf, the payment for all the orders that are made by Consumers to your Restaurant (or business) and to charge the Consumers, the full price of the order during the checkout process. In order to handle this process on your behalf, we will charge a commission on all the sales that happen through our service. This commission will be charged as a percent of the full order price (and on every single order). The amount of this commission is defined in the subscription plan you are subscribed to and may be revised from time to time. Should there be any changes in the commission amount, we are obliged to inform you in written about such changes at least 60 days prior to the change being implemented on our service.")}
                    </p>
                    <p>
                        {t(" You agree that you are solely responsible to ensure that your business is conducted in compliance with the applicable local law and regulations from time to time and that you hold all the necessary permits, licence and registration including any permits that are required for your business to sell your services on a portal like ours.")}
                    </p>

                    <p>
                        {t("You agree to confirm the receipt of an order within five (5) minutes of receiving such order in your Order management terminal (or dashboard) by pressing the Accept h1. Under situations when you do not have the capacity to fulfil the order, you must reject it immediately and also provide the reason of rejection of such order. Once an order has been rejected, it will automatically trigger a refund process for the customer who placed the order.")}
                    </p>
                    <p>
                        {t(" You agree that the customer who has placed an order has the right to cancel the order anytime and for whatsoever reason before the order has been accepted or rejected by you or your staff. Once the order has been accepted by you (or your staff), it becomes binding to both the parties (you and the consumer) and hence, it cannot be cancelled anymore.")}
                    </p>
                    <p>
                        {t(" You agree to follow the order workflow that is defined on our platform and has been demonstrated to you and your staff by our sales personnel. This means that once you receive an order:")}
                    </p>

                    <p>

                        <ul>
                            <li>{t("You must Accept or Reject the order within five (5) minutes of receiving such order")}</li>
                            <li>{t("Once accepted, you must update the order status from:")}</li>
                            <li>{t("Accepted to In progress when you've started working on the order")}</li>
                            <li>{t("In progress to Ready when the order is ready for pick-up or dine-in")}</li>
                            <li>{t("Ready to Delivered when the order has been received by the customer")}</li>
                        </ul>

                    </p>

                    <p>
                        {t("These status updates are crucial for every order as they help the customer understand when it is the right time for them to visit your restaurant for order pick-up or dine-in. Once the order has been marked as Delivered, it sends a Feedback request to the customer, which can be answered by selecting one, two or three stars for the service they received and the quality of the meal. This feedback directly affects the ratings of your restaurant on our portal.")}
                    </p>
                    <p>
                        {t("You agree to prepare the order in good faith to the consumer and take all possible measures to ensure the quality of the meal (or order). This includes (but is not limited to) taking measures like using good quality ingredients, maintain strict hygiene standards, follow the applicable safety guidelines, pack the meal (or order) in accordance with the applicable regulations, adhere to the instructions and/or allergy information that is provided by the customer. In addition to this, you also agree that we reserve the right to charge you with a penalty fees if the prepared meal (or order) does not follow 1) the applicable quality regulations; 2) the instructions provided by the customer; 3) the allergy information provided by the customer. You also agree that we, as a service provider cannot be held responsible for any consequences that arise out of such negligence.")}
                    </p>

                    <h2 className={style.headings}>{t("SUBSCRIPTION, INVOICES, APPLICABLE FEES AND PAYOUTS")} </h2>
                    <p>
                        {t("Unless explicitly agreed otherwise, all our subscription plans:")} <strong>
                            {t(" Explore, Experiment,Premium with a trial period of 30 days")}
                        </strong>,
                        {t("during which you can opt to quit the service at any time without any payment obligation towards any applicable monthly rental defined in the subscription plan; provided you are using the service as-is and without any special customization requests (which might be subject to relevant charges).")}
                    </p>
                    <p>
                        {t(" In order to use our ordering service,We charge a commission on all the sales that happen through our platform, including the sales that happens during the trial period and after that.")}
                    </p>
                    <p>
                        {t("Once the trial period is over, you account is automatically upgraded to the plan you have subscribed to and from this point, you are responsible to make payments towards the invoices that are generated against the usage of Vshops by your restaurant (or business) and within the due date of 30 days from the date of generation of such an invoice.")}
                    </p>
                    <p>
                        {t("Once the invoice is generated, our system will trigger an automatic email to the email address that was provided during the time of registration. This email will contain the summary of the invoice including information like how to view or download the invoice, due date of payment, net payable amount, payment instructions.")}
                    </p>
                    <p>
                        {t("All invoices are generated during the first week of every month and they contain details like due date of payment, payment instructions, net payable amount. The Net payable amount is calculated using the following formula: Net payable amount = Applicable fees total + 24% VAT. The Applicable fees total is defined as the sum total of all the charges or fees that you are obliged to pay to us. Such charges or fees include (but may not be limited to): (1) Setup fees to cover the costs related to setting up your account on our platform, device(s) that you order from us (like: thermal printer, order management terminal, equipment to secure the terminal, self service kiosk); (2) Monthly subscription fees that is based on the plan you are subscribed to; (3) Hardware rental to cover the costs related to the hardware you've ordered from us; (4) Commission on the sales that happen through our portal; (5) Other charges or fees to cover the costs for services that you have ordered through us.")}
                    </p>

                    <p>
                        {t(" All invoices that are generated are due to be paid within 30 days of generation of such invoice. Payment reminders towards an unpaid invoice will be sent to your email address 7 (seven), 3 (three), 1 (one) day(s) before and on the the due date of payment of that specific invoice. Invoice overdue reminders will be sent 1 (one), 3 (three) and 7 (seven) days after the due date of payment of that specific invoice. Late or delayed payments made towards a generated invoice are subject to a penalty interest of 7% per annum. We reserve the right to cancel your account or deduct the amount to be paid for such invoice from your future payouts.")}
                    </p>
                    {t(" We use Paytrail as our payment partner. In order to receive payments immediately,Payment API key should be purchased from Paytrail and provided to us.")}
                    <br />
                    <h2 className={style.headings}>{t("LIABILITIES AND LIMITATIONS TO LIABILITY")}</h2>
                    <p>
                        {t("You are liable to compensate Vshops  for any damages direct and/or in-direct that are suffered by Vshops  as a result of your breach of the terms specified in this document.")}
                    </p>
                    <p>
                        {t("We cannot be held liable or responsible for any misuse of the service in any form whatsoever; consequences that arise due to misuse of the product in any kind by anyone which occurs by intention or in negligence.In addition to this, we cannot be held responsible for any emotional, psychological, physical or financial mishaps of any kind related to using our product or services.")}
                    </p>
                    <p>
                        {t("We cannot be held liable or responsible for situations or scenarios that arise as a result of your actions or arise as a result of misconfigured infrastructure are your business premises, which include (but are not limited to): (1) poor or no network connection; (2) power saving mode on your device that restricts network or internet access; (3) low battery that restricts network or internet usage; (4) low battery that causes your device to turn off; (4) flight mode turned on your device which restricts or disables internet or network usage.")}
                    </p>
                    <p>
                        {t(" We cannot be held liable or responsible for situations or scenarios that arise as a result of your actions which include (but are not limited to): (1) not adhering to the applicable quality regulations; (2) ignorance of any instructions or allergy related information provided by the customer during checkout; (3) providing false or misleading information related to the content of the order, including the list of ingredients and applicable allergy information.")}
                    </p>
                    <p>
                        {t("As a service provider, our responsibilities include:")}
                    </p>

                    <p>

                        <ul>
                            <li>{t("Within the best of our capacity, 99.9% uptime of our services")}</li>

                            <li>{t("Provide you with a product that is free from defects in material and workmanship")}</li>

                            <li>{t("Provide you with a product maintenance and support which is included as a part of your subscription")}</li>

                            <li>{t("Provide you with regular updates, bug fixes and new features that are a part of our product roadmap")}</li>

                            <li>{t("Fix or repair the bugs or defects reported in the product by you or your staff within a reasonable timeframe")}</li>
                        </ul>

                    </p>
                    <p>
                        {t("As a user of this service, your responsibilities include:")}
                    </p>

                    <p>

                        <ul>
                            <li>{t("Provide us and your customers with information that is accurate and not misleading in any form")}</li>

                            <li>{t("Make regular and timely payments towards the invoices generated as a part of your subscription")}</li>

                            <li>{t("To an extent it is possible, make sure that this service is used for its intended purposes only and is not misused in any form by you, your staff and your customers")}</li>

                            <li>{t("Make sure that all the customer data handled through this service is handled with utmost respect towards the privacy and rights of the customer")}</li>

                            <li>{t("Follow the terms outlined and defined in this document in good faith and without any unlawfulintention towards the service provider and the consumer")}</li>
                        </ul>
                    </p>

                    <p>
                        {t(" The service Vshops  is provided on an as is, as available and with all faults basis.To the fullest extent permissible by law, neither we nor any of our employees, managers, officers, partners or suppliers make any warranties of any kind, whether express or implied in relation to the services provided by us.")}
                    </p>
                    <h2 className={style.headings}>{t("TRANSFER OF RIGHTS")}</h2>
                    <p>
                        {t(" We (as a service provider) are entitled to transfer all or some of our rights and obligations under this agreement to a partner, reseller or an appointed sub-contractor, provided that they are in capacity to fulfil those rights and obligations in satisfactory manner.")}
                    </p>
                    <p>
                        {t("You (the restaurant) are not entitled to transfer or assign your rights and obligations under this agreement to another party without a written consent from us. Should that transfer or assignment happen without our written consent, we reserve the right to terminate this agreement with immediate effect.In situations when your business is transferred to a third-party, you must notify us of this change no later than two (2) weeks before such transfer takes place, so that we can provision a new contract (without any mandatory obligation to do so) between us and the third-party.")}
                    </p>
                    <h2 className={style.headings}>{t("INDEMNITY")} </h2>
                    <p>
                        {t(" You agree to defend, indemnify and hold harmless, the Company (Cloudronics Solutions Oy) against any claims, liabilities, damages, losses and expenses, that may be awarded or agreed to be paid to any third-party in respect of any claim or action that is a result of your activities which include (but are not limited to): a) your breach or alleged breach of any terms; b) your violation of any third-party rights, including (but not limited to) any intellectual property rights, publicity, confidentiality, property or privacy rights; c) your violation of any laws, rules, regulations, codes, statutes, ordinances or orders of any government, regulatory, administrative or legislative authorities; d) any misrepresentations made by you.")}
                    </p>
                    <h2 className={style.headings}>{t("CONFIDENTIALITY, IPR AND MARKETING RIGHTS")} </h2>
                    <p>
                        {t(" Both parties (you and us) shall maintain strictly confidential and not without prior written consent, disclose to any third party or use any documentation or any information designated or understood as confidential, whether of a commercial or a technical nature, including but not limited to future or proposed products, material, projects and services, including, but not limited to, marketing and business plans, concepts, drawings, specifications, notebook entries, technical notes and graphs, computer printouts, technical memoranda and correspondence, unpublished promotional material, product development agreements and related agreements.Confidentiality obligations set forth herein, do not prevent either party from disclosing confidential information either (i) to a third party pursuant to a prior written authorization from the other; or (ii) to satisfy a statutory requirement or a requirement of, or demand by, a competent court of law, or a governmental, or regulatory, body or listing authority.In the event that any confidential information is to be disclosed pursuant to sub-clause (ii) the disclosing party will (unless prevented by order of such competent court of law, or a governmental, or regulatory, body or listing authority) use all reasonable endeavors to notify the other of such requirement or demand in advance of disclosure and will in any event notify it of such requirement or demand as soon as is possible, and such notification shall include details and description of both the confidential information required or demanded and, to the extent possible, the reason why such confidential information was required or demanded.")}
                    </p>

                    <p>
                        {t("All the Intellectual property rights that are owned or controlled by a us at the commencement of this contract shall remain under our control and ownership throughout the term of this contract and thereafter.All rights, title and interest in and to the intellectual properties created or invented during the cooperation shall vest in and be the exclusive property of the Company (Cloudronics Solutions Oy), unless explicitly agreed in written between both the parties (for example, for development of a new feature that is done exclusively for you or your organization.In all such cases, the development of this new features may attract additional charges which must be agreed beforehand by both the parties)")}
                    </p>

                    <p>
                        {t(" Unless explicitly mentioned in separate Terms and Conditions document, both the parties (we and you), authorize each other to apply each other's trademarks in their materials for the purpose of cooperation.We both agree that we shall not use the other party's trademarks for any other purpose without the other party's prior written consent. Both the parties shall have the right to use the other party's name as a reference in their marketing activities.")}
                    </p>

                    <p>
                        {t(" In reference to any items that are a part of provision of services under this contract, the company warrants that it has full, clear and unencumbered title to all such items, at the date of delivery of such items to you, the client and that the company will have full and unrestricted rights to sell and transfer all such items to the client.")}
                    </p>
                    <h2 className={style.headings}>{t("CUSTOMER SERVICE AND SUPPORT")}</h2>
                    <p>
                        {t("In order reach our customer service or to report bugs or defects found in our product by you or your staff, an email must be written to our support team at    product.support[at]cloudronics or support.[at]Cloudronics Solutions.com  describing the situation and adding any screenshots of the scenario if possible.Our email support is monitored during standard working hours on weekdays (in GMT+2, Eastern Europe Time - Finland - Mon to Fri: 08: 30 - 16: 00).Emails that are received outside of monitored support hours, during public or state holidays will be collected and responded to during the next working day.")}
                    </p>
                    <p>
                        {t(" All the support email received will be dealt with as soon as possible, on a first-come-first-served basis followed by the severity of the support request.Our team will act on the support request and propose a timeline of the fix within the support resolution time which is defined in the subscription plan that you have subscribed to.")}

                    </p>
                    <p>
                        {t("In an event when our team is handling a support issue raised by you (as a user of our business services), it is necessary for our team (in-house or sub-contractor) or a support agent to access your account and content in order to diagnose the problem.In all situations when you contact our support team, it is implied that you allow us access to your account and content if it is required to do so.In situations when you would like to receive support without granting permission to your account and content, you are required to specify this in your communication with our team and we will respect your decision to an extent it is possible to fix the issue.")}
                    </p>
                    <p>
                        {t("As a Service provider, we will always work in the best of our capacity to make the service available as much as possible, there will be situations when the service may be interrupted (including but not limited to) for scheduled or unscheduled maintenance, upgrades - for emergency repairs, system or server failures, failure of telecommunications links and/or equipment.In such context, we encourage you to maintain your own backup of your content and not rely or treat us as a backup service.We will not be liable to you for any loss of content that arises due to system or server failure which cannot be repaired.")}
                    </p>

                    <p>
                        {t("Should there be any planned maintenance activities or infrastructure upgrades, that will cause a downtime of more than 5 (five) minutes, we agree to communicate to you about such events at least 2 weeks in advance.In situations when such activities affect your operating hours, we can agree to a new schedule of such activities.")}
                    </p>

                    <p>
                        {t(" Should there be any planned maintenance activities or infrastructure upgrades from any of our service providers that will cause a service outage or a downtime during operating hours of our customers (like you), we agree to communicate to you about such events as soon as we know about it. In all such situations, we will do our best to make arrangements within our capacity to ensure the continuity of our services.")}
                    </p>

                    <p>
                        {t("Should there be any un-planned maintenance or service outages that are within the control of our systems and software, we agree to communicate about them as soon as we have known about them and agree to fix them with utmost priority.")}
                    </p>
                    <p>
                        {t(" In case of any service outages that are outside of our control, for example, a service break or outage or maintenance in services that are provided to us from our service providers like (but not limited to) DigitalOcean, FireBase, Amazon Web Services, we agree to inform you about it as soon as we know about it.In all such situations, we will do our best to make arrangements within our capacity to ensure the continuity of our services.")}

                    </p>
                    <h2 className={style.headings}>{t("TERM AND TERMINATION")}</h2>
                    <p>
                        {t(" This agreement comes into effect immediately from the moment your account is registered on our service and remains valid until further notice.This agreement is subject to one (1) month's notice of termination by either party and such notice of termination must be given in writing.")}
                    </p>

                    <p>
                        {t("We reserve the right to terminate this agreement with immediate effect if (a) You (restaurant or business) are in breach of your obligation under this agreement; (b) You (restaurant or business) is declared bankrupt or enters into liquidation or is otherwise found insolvent; (c) You (restaurant or business) no longer hold the necessary permits for operating your business; (d) You (restaurant or business) have transferred your business to a third-party.")}
                    </p>
                    <p>
                        {t("The termination of this agreement does not release either Party from its obligation to pay the debts (pending invoices or payouts) incurred during the term of this agreement.In addition to this, there will be no refund towards any charges or fees paid by you during the term of this agreement.")}
                    </p>

                    <p>
                        {t("Upon termination of this agreement, you must return all the equipment you have ordered from us including the Order management terminal (or dashboard), Thermal printers, Equipment to secure the terminal and printers, Self service kiosks etc.If any of the ordered equipment is not returned within five (5) days of termination of the agreement, we reserve the right to charge you with a late fees of 250 EURO per equipment, per week.Such late fees does not release you from your obligation of returning the equipment that you ordered during the term of this agreement.")}
                    </p>
                    <p>
                        {t("Upon receiving a termination request, your account enters into a notice period that ends after 30 days or on the last day of your current billing period - for which an invoice has already been generated. You do not have any obligation to use our services during the notice period.Your last payout will be processed after your notice period is over and your account will be deleted anytime within fifteen (15) days after the payout has been processed.Deletion of your account does not release you from obligation of making payments towards any unpaid invoices.Once your account is deleted, any content related to your account is erased, including any personally identifiable information of your customers, staff and business.This action cannot be reversed.")}
                    </p>
                    <h2 className={style.headings}>{t("APPLICABLE LAW AND")} </h2>
                    <p>
                        {t("The terms mentioned in this document shall be construed and interpreted in accordance with the Laws of Finland and any disputes relating to or arising in connection with this terms (and agreement) shall be finally settled in arbitration.The sole arbitrator is to be appointed by the Arbitration Committee of the Finland Chamber of Commerce and the rules of the said Committee are to be followed in arbitration.The place of arbitration shall be Helsinki - Finland and the language of arbitration shall be English unless all the parties to the the dispute agree otherwise and in written.The award shall be final and binding to both the parties hereto and enforceable in any court of competent jurisdiction.")}
                    </p>

                </div>
            </div>
        </div>
    
    </CommonLayout >
}

export default TermsAndConditions