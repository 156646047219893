import { useSelector } from 'react-redux'
import AdminLayout from '../../components/sections/AdminLayout'
import AdminPanelHeader from '../../components/sections/AdminPanelHeader'
import { useT } from '../../i18n'
import { StoreState } from '../../redux/reducers'
import { AuthAdmin } from '../../redux/reducers/authAdmin.reducer'
import AdminHomeCard from '../../components/cards/AdminHomeCard'

const Home = () => {
    const t = useT()
    const authAdmin = useSelector<StoreState, AuthAdmin | null>(state => state.authAdmin)
    return <AdminLayout>
        <AdminPanelHeader title={t("WELCOME") + ` ${authAdmin?.name}!`} />
        {<AdminHomeCard />}
    </AdminLayout>
}

export default Home