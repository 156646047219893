import { Favourite } from '../reducers/favourite.reducer'

export enum FavouriteActionTypes {
    ADD_FAVOURITE = 'ADD_FAVOURITE',
    REMOVE_FAVOURITE = 'REMOVE_FAVOURITE'
}

export interface AddFavouriteAction {
    type: FavouriteActionTypes.ADD_FAVOURITE
    data: Favourite
}

export interface RemoveFavouriteAction {
    type: FavouriteActionTypes.REMOVE_FAVOURITE
    data: Favourite
}

export type FavouriteActions = AddFavouriteAction | RemoveFavouriteAction

export const addFavourite = (input: Favourite): AddFavouriteAction => {
    return {
        type: FavouriteActionTypes.ADD_FAVOURITE,
        data: input
    }
}

export const removeFavourite = (input: Favourite): RemoveFavouriteAction => {
    return {
        type: FavouriteActionTypes.REMOVE_FAVOURITE,
        data: input
    }
}