const Cart = () => {
    return <svg xmlns='http://www.w3.org/2000/svg' width='23.112' height='19' viewBox='0 0 23.112 19'>
        <g id='Group_128' data-name='Group 128' transform='translate(0.5 0.5)'>
            <g id='Group_119' data-name='Group 119' transform='translate(6 14)'>
                <path id='Path_9' data-name='Path 9' d='M16,32a2,2,0,1,1-2-2A2,2,0,0,1,16,32Z' transform='translate(-12 -30)' fill='none' stroke='#404040' strokeLinecap='round' strokeLinejoin='round' strokeWidth='1' />
                <path id='Path_10' data-name='Path 10' d='M32.5,32a2,2,0,1,1-2-2A2,2,0,0,1,32.5,32Z' transform='translate(-19.5 -30)' fill='none' stroke='#404040' strokeLinecap='round' strokeLinejoin='round' strokeWidth='1' />
            </g>
            <path id='Path_11' data-name='Path 11' d='M1.5,1.5H3.587l2.68,9.731a1.959,1.959,0,0,0,2,1.17H19.9a1.959,1.959,0,0,0,2-1.17l1.6-6.023' transform='translate(-1.5 -1.5)' fill='none' stroke='#404040' strokeLinecap='round' strokeLinejoin='round' strokeWidth='1' />
        </g>
    </svg>
}

export default Cart