import React from 'react'
import style from '../../styles/components/error-text.module.scss'

interface ErrorTextProps {
    errorText?: string
    className?: string
    top?: string
    start?: string
    absolute?: boolean
}

const ErrorText = (props: ErrorTextProps) => {
    const absolute = props.absolute ?? true

    const inlineStyle: React.CSSProperties | undefined = absolute ? {
        position: 'absolute',
        top: props.top ?? '100%',
        left: props.start ?? '0%'
    } : undefined

    return <React.Fragment>
        {props.errorText && props.errorText !== '' && <div className={`${style.errorText} ${props.className || ''}`} style={inlineStyle}>{props.errorText}</div>}
    </React.Fragment>
}

export default ErrorText