import { Action } from '../actions'
import { DivisionActionTypes } from '../actions/division.actions'

export interface DivisionAddress {
    addressLine1: string
    addressLine2: string
    city: string
    pincode: number
    state: string
}

export interface Division {
    userName: string
    name: string
    email: string
    phoneNumber: string
    address: DivisionAddress
    id: string
    createdAt?: string
    updatedAt?: string
    password?: string
}

const initialState: Division[] = []

const divisionReducer = (state: Division[] = initialState, action: Action): Division[] => {
    switch (action.type) {

        case DivisionActionTypes.ADD_DIVISION: {
            return [
                ...state,
                action.data
            ]
        }

        case DivisionActionTypes.UPDATE_DIVISION: {
            const divisions = [...state]
            const index = divisions.findIndex(c => c.id === action.data.id)

            if (index > -1) {
                divisions[index] = {
                    ...action.data
                }
            }

            return divisions
        }

        case DivisionActionTypes.FETCH_DIVISIONS: {
            return action.data
        }

        case DivisionActionTypes.DELETE_DIVISION: {
            return state.filter(c => c.id !== action.data)
        }

        default: {
            return state
        }
    }
}

export default divisionReducer