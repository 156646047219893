import React from 'react'
import style from '../../styles/components/modal.module.scss'

interface ModalProps {
    children?: React.ReactNode
    lazy?: boolean
    animate?: boolean
    highPriority?: boolean
}

const Modal = (props: ModalProps) => {
    const [lazyClassName, setLazyClassName] = React.useState<string>(props.lazy ? 'd-none' : '')

    React.useEffect(() => {
        const bodyElement = document.querySelector('body')

        if (!bodyElement) {
            return
        }

        bodyElement.classList.add('overflow-hidden')

        return () => {
            bodyElement.classList.remove('overflow-hidden')
        }
    }, [])

    React.useEffect(() => {
        if (props.lazy) {
            setTimeout(() => {
                setLazyClassName('')
            }, 100)
        }
    }, [props.lazy])

    return <div className={`${style.container} ${lazyClassName} ${props.highPriority ? style.highPriority : ''}`}>
        <div className={`${style.mainContent} ${props.animate ? style.animate : ''}`}>{props.children}</div>
    </div>
}

export default Modal