/* eslint-disable no-throw-literal */
import { CartItemName } from '../reducers/cart.reducer'
import { EventCartItemPrice } from '../reducers/eventCart.reducer'
export enum EventCartActionTypes {
    ADD_EVENT_CART_ITEM = 'ADD_EVET_CART_ITEM',
    REMOVE_EVENT_CART_ITEM = 'REMOVE_EVENT_CART_ITEM'
}

interface AddToEventCart {
    eventId: string
    names: CartItemName[]
    eventType: string
    foodPreferences: string[]
    volunteers: string[]
    cultureVouchers: string[]
    prices: EventCartItemPrice[]
    category: string
    eventRule: string
    withoutRegistration: string
}

export interface AddEventCartItemAction {
    type: EventCartActionTypes.ADD_EVENT_CART_ITEM
    data: {
        shopId: string
        item: AddToEventCart
    }
}

export interface RemoveEventCartItemAction {
    type: EventCartActionTypes.REMOVE_EVENT_CART_ITEM
}

export type EventCartActions = AddEventCartItemAction | RemoveEventCartItemAction

export const eventAddToCart = (item: AddToEventCart, shopId: string): AddEventCartItemAction => {

    return {
        type: EventCartActionTypes.ADD_EVENT_CART_ITEM,
        data: {
            shopId,
            item
        }
    }
}

export const eventRemoveCartItem = (): RemoveEventCartItemAction => {
    return {
        type: EventCartActionTypes.REMOVE_EVENT_CART_ITEM
    }
}

