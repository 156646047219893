import React, { MouseEventHandler, useEffect, useMemo, useState } from 'react'
import { CSVLink } from 'react-csv'
import * as Feather from 'react-feather'
import { useDispatch } from 'react-redux'
import { Response, api } from '../../api'
import { ToastType, useToast } from '../../components/ToastProvider'
import ViewUser from '../../components/forms/ViewUser'
import Confirmation from '../../components/modals/Confirmation'
import ModalWithHeader from '../../components/modals/ModalWithHeader'
import AdminPanelHeader from '../../components/sections/AdminPanelHeader'
import SuperAdminLayout from '../../components/sections/SuperAdminLayout'
import { AppContext, AppContextType } from '../../context/AppProvider'
import { useT } from '../../i18n'
import { deleteUser, referralCountMail } from '../../redux/actions/user.actions'
import { User } from '../../redux/reducers/user.reducer'
import { AppDispatch } from '../../redux/store'
import { toNumber } from '../../utils'

interface TableBody {
    sno: number
    userId?: string
    firstName: string
    lastName: string
    email: string
    countryCode: string
    phoneNumber: string
    addressLine1: string
    addressLine2: string
    referralCount?: number
    referralEmailCount?: number
    city: string
    state: string
    pincode: string
    landmark: string
    onDelete: MouseEventHandler<HTMLButtonElement>
    onView: MouseEventHandler<HTMLButtonElement>
    sendMail: (value: string) => void
}

const Users = () => {
    const t = useT()
    const toast = useToast()
    const dispatch = useDispatch<AppDispatch>()

    const appContext = React.useContext<AppContextType | null>(AppContext)
    const [userList, setUserList] = useState<User[]>([])
    const [showModal, setShowModal] = useState<Boolean>(false)
    const [editData, setEditData] = useState<User>()

    const fetchUser = () => {
        api.get<Response<User[]>>('user/').then(response => {
            if (response.status === 200 && response.data.data) {
                const result = response.data.data

                setUserList(result)
            } else {
                throw { response }
            }
        }).catch(() => {
            toast(t('Something went wrong!'))
        }).finally(() => {
            appContext?.hideOverlay?.()
        })
    }
    useEffect(fetchUser, [])

    const data = useMemo<string[][]>(() => {
        let arr: string[][] = []
        userList.filter((user) => user.emailConcent === "Yes").forEach((u, i) => {
            arr.push([u.firstName, u.lastName, u.email, u.phoneNumber, u.address.addressLine1, u.address.addressLine2, u.address.city, u.address.state, u.address.pincode, u.address.landmark])
        })
        return arr
    }, [userList])

    const csvData = [
        ["First Name", "Last Name", "E-mail", "Phone Number", "Address Line 1", "Address Line 2", "City", "State", "Pincode", "Landmark"],
        ...data
    ];

    const modalCloseHandler = () => {
        setShowModal(false)
    }

    const handleDelete = (value: User) => {
        // setEditData(value)
        if (value.id !== undefined) {
            dispatch(deleteUser(value.id)).then(text => {
                toast(text, ToastType.SUCCESS)
                fetchUser()
            }).catch(text => {
                toast(text, ToastType.ERROR)
            })
        }
    }

    const sendMailForReferralHandler = (userId: string) => {
        if (userId !== undefined) {
            dispatch(referralCountMail(userId)).then(text => {
                toast(text, ToastType.SUCCESS)
                fetchUser()
            }).catch(text => {
                toast(text, ToastType.ERROR)
            })
        }
    }

    const handleView = (value: User) => {
        setShowModal(true)
        setEditData(value)
    }

    return <>
        {showModal && <ModalWithHeader
            onClose={modalCloseHandler}
            headerText={t('View Users')}
            small={false}>
            <ViewUser
                data={editData}
            />
        </ModalWithHeader>}
        <SuperAdminLayout>
            <div className='row mb-4'>
                <AdminPanelHeader title={t('Users')} />
                <div className="card m-2">
                    <div className="card-body">
                        <div className='text-end mb-2'>
                            <CSVLink data={csvData} filename={"UserDetail"}> <button type="button" className={`m-1 btn btn-s btn-primary`}><Feather.Download /> {t("Download CSV")}</button></CSVLink>
                        </div>
                        <div className='table-wrapper'>
                            <table className='table'>
                                <thead className="table-primary">
                                    <tr>
                                        <th className='text-truncate text-center align-middle'>S.No</th>
                                        <th className='text-truncate text-center align-middle'>{t("First Name")}</th>
                                        <th className='text-truncate text-center align-middle'>{t("Last Name")}</th>
                                        <th className='text-truncate text-center align-middle'>{t("Email")}</th>
                                        <th className='text-truncate text-center align-middle'>{t("Phone Number")}</th>
                                        <th className='text-truncate text-center align-middle'>{t("Referral Counts")}</th>
                                        {/* <th className='text-truncate text-center align-middle'>Address Line 1</th>
                                        <th className='text-truncate text-center align-middle'>Address Line 2</th>
                                        <th className='text-truncate text-center align-middle'>City</th>
                                        <th className='text-truncate text-center align-middle '>State</th>
                                        <th className='text-truncate text-center align-middle'>PinCode</th>
                                        <th className='text-truncate text-center align-middle'>Landmark</th> */}
                                        <th className='text-truncate align-middle'>{t("Action")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {userList.filter((user) => user.emailConcent === "Yes").map((u, i) => {
                                        return <UserBody
                                            sno={i + 1}
                                            userId={u.id}
                                            firstName={u.firstName}
                                            lastName={u.lastName}
                                            email={u.email}
                                            countryCode={u.countryCode}
                                            phoneNumber={u.phoneNumber}
                                            addressLine1={u.address.addressLine1}
                                            addressLine2={u.address.addressLine2}
                                            city={u.address.city}
                                            state={u.address.state}
                                            pincode={u.address.pincode}
                                            landmark={u.address.landmark}
                                            referralCount={toNumber(u.referral?.count) || 0}
                                            referralEmailCount={toNumber(u.referral?.emailCount) || 0}
                                            key={i}
                                            onDelete={() => handleDelete(u)}
                                            onView={() => handleView(u)}
                                            sendMail={(value) => sendMailForReferralHandler(value)}
                                        />
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </SuperAdminLayout></>
}

const UserBody = ({ sno, userId, firstName, lastName, email, countryCode, phoneNumber, referralCount, referralEmailCount, addressLine1, addressLine2, city, state, pincode, landmark, onDelete, onView, sendMail }: TableBody) => {
    const t = useT()
    const [openConfirmation, setOpenConfirmation] = React.useState(false);

    const sendMailHandler = () => {
        if (userId)
            sendMail(userId)

        setOpenConfirmation(false);
    }

    return <React.Fragment>
        {openConfirmation && <Confirmation
            onClose={() => setOpenConfirmation(false)}
            confirm={sendMailHandler}
            itemName={t(`Are you sure want to confirm this action?`)}
        />
        }
        <tr>
            <td className='align-middle text-center text-truncate'>{sno}</td>
            <td className='align-middle text-center text-truncate text-capitalize'>{firstName}</td>
            <td className='align-middle text-center text-truncate text-capitalize'>{lastName}</td>
            <td className='text-center'>{email}</td>
            <td className='align-middle text-center text-truncate'>{countryCode ? countryCode : '+358'} {phoneNumber}</td>
            <td className='text-center'><div className='hstack gap-2 justify-content-center align-items-center'>{referralCount ? referralCount : 0} {referralEmailCount ? <div style={{ border: '1px solid silver', borderRadius: '10px', padding: '0px 5px' }}>{t("Email Sent")}</div> : ''}</div></td>
            {/* <td className='align-middle text-truncate text-capitalize'>{addressLine1}</td>
        <td className='align-middle text-truncate text-capitalize'>{addressLine2}</td>
        <td className='align-middle text-truncate text-capitalize'>{city}</td>
        <td className='align-middle text-truncate text-capitalize'>{state}</td>
        <td className='align-middle text-truncate'>{pincode}</td>
        <td className='align-middle text-truncate text-capitalize'>{landmark}</td> */}
            <td>
                <div className='hstack gap-1'>
                    <button className='btn btn-sm btn-success' onClick={onView}>{t("View")}</button>
                    <button className='btn btn-sm btn-danger' onClick={onDelete}>{t("Delete")}</button>
                </div>
            </td>
        </tr>
    </React.Fragment>
}

export default Users

//<ModalWithHeader onClose={modalCloseHandler} headerText={`Password Reset - ${selectedAdmin.name}`} small={true}>
//<PasswordResetForm endPoint='admin/set/password' afterSave={modalCloseHandler} admin={selectedAdmin} />
//</ModalWithHeader>