import { t } from 'i18next';
import React from 'react';
import { useDispatch } from 'react-redux';
import { AdminContext } from '../../context/AdminProvider';
import { deleteVoucher } from '../../redux/actions';
import { Voucher } from '../../redux/reducers/voucher.reducer';
import { AppDispatch } from '../../redux/store';
import style from '../../styles/components/voucher-card.module.scss';
import { date, toCurrencyFormat } from '../../utils';
import { ToastType, useToast } from '../ToastProvider';
import Button from '../utils/Button';

export interface VoucherCardProps {
    voucher?: Voucher
}

const VoucherCard = (props: VoucherCardProps) => {

    const toast = useToast()
    const dispatch = useDispatch<AppDispatch>()

    const adminContext = React.useContext(AdminContext)

    const handleDelete = (value: string) => {

        if (value !== undefined) {
            dispatch(deleteVoucher(value)).then(text => {
                toast(text, ToastType.SUCCESS)
            }).catch(text => {
                toast(text, ToastType.ERROR)
            })
        }
    }

    return <>
        <div className={style.container}>
            <div className={style.codeAndValue}>
                {props.voucher?.mode === 'percentage' && <div className={style.value}>{props.voucher?.value}% {t("Offer")}</div>}
                {props.voucher?.mode === 'amount' && <div className={style.value}>{t("Value")} {toCurrencyFormat(props.voucher?.value)}</div>}
                <div className={style.code}>{props.voucher?.code}</div>
            </div>
            <div className={style.validity}>{t("Valid until")} <span>{date('MON d, Y', props.voucher?.expiryDate)}</span></div>
            <div className={style.orderValue}>{t("For orders with a minimum value of")} {toCurrencyFormat(props.voucher?.minOrderValue)}</div>
            <div className={style.title}>{props.voucher?.title}</div>
            <div className={style.description}>{props.voucher?.description}</div>
            <div className='d-flex justify-content-between hstack gap-5'>
                <Button className={style.button} onClick={() => adminContext?.onEditVoucher?.(props.voucher?.id!)}>{t("Edit")}</Button>
                <Button className={style.button} onClick={() => handleDelete(props.voucher?.id!)}>{t("Delete")}</Button>
            </div>
        </div>
    </>
}

export default VoucherCard