import { useState } from 'react'
import { useT } from '../../i18n'
import style from '../../styles/components/home-section.module.scss'
import ChevronRightDark from '../svgs/ChevronRightDark'

interface Item {
    id: string
}

interface HomeSectionProps<I extends Item> {
    titleLeft?: boolean
    title?: string
    items?: I[]
    renderItemGetter?: (item: I) => React.ReactNode
}

const HomeSection = <D extends Item>(props: HomeSectionProps<D>) => {
    const t = useT()
    const [view, setView] = useState<string>(t('View All'))
    let size = 4;
    const items = props.items?.slice(0, size)
    const ViewAllHandler = () => {
        setView(prev => prev === t('View All') ? t('View Less') : t('View All'))
    }

    return <div className='mb-5'>
        <div className='position-relative mb-3'>
            {items && items?.length > 0 && <div className={`${style.headerText} ${props.titleLeft ? style.start : ''}`}>{props.title}</div>}
            <div className='position-absolute top-0 end-0'>
                {items && items?.length > 0 && <div className={style.viewAll}>
                    <div className={style.label} onClick={ViewAllHandler}>{view}</div>
                    <ChevronRightDark />
                </div>}
            </div>
        </div>
        <div className='row'>
            {view === t('View All') && items?.map(item => {
                const children = props.renderItemGetter?.(item)
                return <SectionItem title={props.title} key={item.id}>{children}</SectionItem>
            })}
            {view === t('View Less') && props.items?.map(item => {
                const children = props.renderItemGetter?.(item)
                return <SectionItem title={props.title} key={item.id}>{children}</SectionItem>
            })}
        </div>
    </div>
}

interface SectionItemProps {
    title?: string
    children?: React.ReactNode
}

const SectionItem = (props: SectionItemProps) => {
    return <div className='col-sm-6 col-md-4 col-xl-3 mb-3'>
        <div className={style.sectionWrapper}>{props.children}</div>
    </div>
}

export default HomeSection