import { Action, ReservationActionTypes } from '../actions'
import { Category } from './category.reducer'

export interface ReservationReports {
    count: number
    date: string
    price: number
}


export interface ReservationReport {
    _id: {
        category: Category[]
    }
    data: ReservationReports[]
}

const initialState: ReservationReport[] = []


const ReservationReportReducer = (state: ReservationReport[] = initialState, action: Action): ReservationReport[] => {
    switch (action.type) {
        case ReservationActionTypes.FETCH_RESERVATION_REPORTS: {
            return action.data
        }
        default: {
            return state
        }
    }
}

export default ReservationReportReducer;