import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppContext } from '../../context/AppProvider'
import { useT } from '../../i18n'
import { fetchShops } from '../../redux/actions'
import { updateShopAppointmentPayment, updateShopAppointmentStatus } from '../../redux/actions/appointmentBookings.actions'
import { StoreState } from '../../redux/reducers'
import { AppointmentBooking as AppointmentBookingObj } from '../../redux/reducers/appointmentBookings.reducer'
import { Buyer, PaymentMode, PaymentStatus } from '../../redux/reducers/order.reducer'
import { Shop } from '../../redux/reducers/shop.reducer'
import { AppDispatch } from '../../redux/store'
import style from '../../styles/components/order.module.scss'
import { date, toCurrencyFormat } from '../../utils'
import Confirmation from '../modals/Confirmation'

type BookingRole = 'USER' | 'ADMIN'

interface AdminBookingProps {
    booking: AppointmentBookingObj
    role: BookingRole
    updateUserStatusHandler?: (id: string) => void
    shop?: Shop[]
}

const AppointmentBooking = (props: AdminBookingProps) => {
    const t = useT()
    const dispatch = useDispatch<AppDispatch>()
    const appContext = React.useContext(AppContext)
    const [openConfirmModal, setOpenConfirmModal] = React.useState<{ payment: boolean, booking: boolean }>({ payment: false, booking: false })
    const shops = useSelector<StoreState, Shop[] | null>(state => state.shops)
    const appointmentBookingShop = shops?.find(s => s.id?.toString() === props.booking.shopId.id?.toString())
    const [bookingStatus, setBookingStatus] = React.useState('');

    useEffect(() => {
        dispatch(fetchShops())
    }, [])

    const paymentVerifyClickHandler = (status: string) => {
        const shopId = props.booking.shopId;
        appContext?.showOverlay?.();
        dispatch(updateShopAppointmentPayment(status, shopId.id, props.booking.id)).finally(() => {
            appContext?.hideOverlay?.();
        });
    };

    const bookingStatusClickHandler = () => {
        appContext?.showOverlay?.()
        if (bookingStatus)
            dispatch(updateShopAppointmentStatus(bookingStatus, props.booking.id)).finally(() => {
                appContext?.hideOverlay?.()
            })
    }

    return <Fragment>
        {openConfirmModal.payment && <Confirmation
            onClose={() => setOpenConfirmModal({ ...openConfirmModal, payment: false })}
            confirm={() => paymentVerifyClickHandler('paid')}
            itemName={t(`This action cannot be reverted are you sure you want to proceed?`)}
        />}
        {openConfirmModal.booking && props.role === 'ADMIN' && <Confirmation
            onClose={() => setOpenConfirmModal({ ...openConfirmModal, booking: false })}
            confirm={() => bookingStatusClickHandler()}
            itemName={t(`This action cannot be reverted are you sure you want to proceed?`)}
        />}
        {openConfirmModal.booking && props.role === 'USER' && <Confirmation
            onClose={() => setOpenConfirmModal({ ...openConfirmModal, booking: false })}
            confirm={() => props.updateUserStatusHandler?.(props.booking.id)}
            itemName={t(`This action cannot be reverted are you sure you want to proceed?`)}
        />}
        <div className='card my-3 shadow-sm'>
            <div className='card-header bg-white'>
                <div className='row'>
                    <div className='col-sm-4 d-flex align-items-center'>
                        <div className={style.orderNumber}>#{props.booking.reservationNumber}</div>
                    </div>
                    <div className='col-sm-4 d-flex align-items-center my-1'>
                        <div className={style.orderStatus}>{props.booking.reservationStatus}</div>
                    </div>
                    <div className='col-sm-4 d-flex align-items-center'>
                        <div className={style.orderDate}>{date('MON d, Y h:i Z', props.booking.reservationDate)}</div>
                    </div>
                </div>
            </div>
            <div className='card-body'>
                <div className='row'>
                    <div className='col-sm-5'>

                        <div className='fw-bold text-uppercase'>{appointmentBookingShop?.name}</div>
                        <div className={`${style.sectionHeader} mb-2`}>{t("Order Items")}</div>

                        <BookingDetail
                            booking={props.booking} />
                    </div>
                    <div className='col-sm-7'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <PriceDetails
                                    billAmount={props.booking.billAmount}
                                    totalAmount={props.booking.totalAmount}
                                    totalTax={props.booking.totalTax}
                                />
                                <OtherDetails
                                    paymentMode={props.booking.paymentMode}
                                    paymentStatus={props.booking.paymentStatus}
                                />
                            </div>
                            <div className='col-md-6'>
                                <CustomerDetails
                                    buyer={props.booking.user}
                                    role={props.role}
                                />
                            </div>
                        </div>
                        {props.role === 'ADMIN' && props.booking.paymentStatus === "pending" ?
                            <>
                                <div className={style.sectionHeader}>{t("Update Status")}</div>
                                <div className='hstack flex-wrap gap-2'>
                                    <div className={style.bookingStatus} onClick={() => setOpenConfirmModal({ ...openConfirmModal, payment: true })}>
                                        <span>{t("Payment Verify")}</span>
                                    </div>
                                </div>
                            </>
                            :
                            props.role === 'ADMIN' && props.booking.reservationStatus === 'booked' ?
                                <>
                                    <div className={style.sectionHeader}>{t("Update Status")}</div>
                                    <div className='hstack flex-wrap gap-2'>
                                        <div className={style.bookingStatus} onClick={() => { setOpenConfirmModal({ ...openConfirmModal, booking: true }); setBookingStatus('attended') }}>
                                            <span>{t("Attend")}</span>
                                        </div>
                                    </div>
                                </>
                                :
                                props.role === 'USER' && props.booking.reservationStatus === 'booked' &&
                                <>
                                    <div className={style.sectionHeader}>{t("Update Status")}</div>
                                    <div className='hstack flex-wrap gap-2'>
                                        <div className={style.bookingStatus} onClick={() => { setOpenConfirmModal({ ...openConfirmModal, booking: true }); setBookingStatus('cancelled') }}>
                                            <span>{t("Cancel")}</span>
                                        </div>
                                    </div>
                                </>
                        }
                    </div>
                </div>
            </div>
        </div>
    </Fragment>

}

interface BookingDetailProps {
    booking: AppointmentBookingObj
}

const BookingDetail = (props: BookingDetailProps) => {
    const t = useT()

    return <React.Fragment>
        <div className={style.itemTitle}>{t("Appointment Date")} : {date('d MON Y', props.booking.appointmentDate)}</div>
        <div className={style.itemTitle}>{t("Appointment Time")}  :   {props.booking.slotTime} </div>
    </React.Fragment>
}

interface CustomerDetailsProps {
    buyer: Buyer
    role: BookingRole
}

const CustomerDetails = (props: CustomerDetailsProps) => {
    const t = useT()

    return <React.Fragment>
        <div className={style.sectionHeader}>{props.role === 'ADMIN' ? t('Customer') : t('Contact')} {t("Detail")}</div>
        <div className={style.customerName}>{props.buyer.firstName} {props.buyer.lastName}</div>
        <div className={style.customerDetails}>{props.buyer.address.addressLine1}, {props.buyer.address.addressLine2}, {props.buyer.address.city}, {props.buyer.address.state} - {props.buyer.address.pincode}</div>
        {props.buyer.address.landmark && <div className={style.customerDetails}>{props.buyer.address.landmark}</div>}
        <div className={style.email}>{props.buyer.email}</div>
        <div className={style.customerDetails}>{props.buyer.phoneNumber}</div>
    </React.Fragment>
}

interface PriceDetailsProps {
    totalAmount: number
    totalTax: number | null
    billAmount: number
}

const PriceDetails = (props: PriceDetailsProps) => {
    const t = useT()

    return <React.Fragment>
        <div className={style.sectionHeader}>{t("Price details")}</div>
        <table>
            <tbody>
                <tr>
                    <td className={style.priceLabel}>{t("Total Amount")}:</td>
                    <td className={style.priceValue}>{toCurrencyFormat(props.totalAmount)}</td>
                </tr>
                {props.totalTax && <tr>
                    <td className={style.priceLabel}>{t("Tax")}:</td>
                    <td className={style.priceValue}>{toCurrencyFormat(props.totalTax)}</td>
                </tr>}
            </tbody>
        </table>
        <table className='border-top pt-2 mt-2 d-inline-block'>
            <tbody>
                <tr>
                    <td className={style.billAmountLabel}>{t("Bill Amount")}:</td>
                    <td className={style.billAmount}>{toCurrencyFormat(props.billAmount)}</td>
                </tr>
            </tbody>
        </table>
    </React.Fragment>
}

interface OtherDetailsProps {
    paymentStatus: PaymentStatus
    paymentMode: PaymentMode
}

const OtherDetails = (props: OtherDetailsProps) => {
    const t = useT()

    return <table className='pt-2 mt-2'>
        <tbody>
            <tr>
                <td className={style.priceLabel}>{t("Payment")}:</td>
                <td className={style.priceValue}>{props.paymentStatus}</td>
            </tr>
            <tr>
                <td className={style.priceLabel}>{t("Mode")}:</td>
                <td className={style.priceValue}>{props.paymentMode}</td>
            </tr>
        </tbody>
    </table>
}

export default AppointmentBooking