import React from 'react'
import { useSelector } from 'react-redux'
import AdminPanelHeader from '../../components/sections/AdminPanelHeader'
import DivisionLayout from '../../components/sections/DivisionLayout'
import { DivisionContext } from '../../context/DivisionProvider'
import { useT } from '../../i18n'
import { StoreState } from '../../redux/reducers'
import { AuthDivision } from '../../redux/reducers/authDivision.reducer'
import { Shop as ShopType } from '../../redux/reducers/shop.reducer'
import { Shop } from '../super/Shops'

const DivisionShop = () => {
    const t = useT()
    const divisionContext = React.useContext(DivisionContext)
    const shops = useSelector<StoreState, ShopType[]>(state => state.shops)
    const authDivision = useSelector<StoreState, AuthDivision | null>(state => state.authDivision)


    return <DivisionLayout>
        <div className='row mb-4'>
            <AdminPanelHeader
                btnText={t('Add Shop')}
                title={t('Shops')}
                onBtnClick={divisionContext?.onAddShop}
            />
            <Shop
                shops={shops}
                from='division'
                divisionId={authDivision?.id}
            />
        </div>
    </DivisionLayout>
}

export default DivisionShop