import { nanoid } from 'nanoid'
import React from 'react'
import style from '../styles/components/toast.module.scss'
import X from './svgs/X'

export enum ToastType {
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR'
}

interface ToastProps {
    children: React.ReactNode
}

interface Toast {
    text: string
    type: ToastType
}

interface Message extends Toast {
    key: string
}

interface ToastContextProps {
    toast: (text: string, toastType?: ToastType) => void
}

const ToastContext = React.createContext<ToastContextProps | null>(null)

const ToastProvider = (props: ToastProps) => {
    const [messages, setMessages] = React.useState<Array<Message>>([])

    const setToast = (text: string, type: ToastType = ToastType.SUCCESS) => {
        const key = nanoid()

        setMessages(prev => {
            return [{
                key: key,
                text: text,
                type: type
            }, ...prev].slice(0, 4)
        })

        setTimeout(() => {
            removeToast(key)
        }, 10000)
    }

    const removeToast = (key: string) => {
        setMessages(prev => {
            return prev.filter(p => p.key !== key)
        })
    }

    return <ToastContext.Provider value={{ toast: setToast }}>
        {messages.length > 0 && <div className={style.container}>
            {messages.map((msg) => {
                return <ToastItem
                    key={msg.key}
                    text={msg.text}
                    type={msg.type}
                    onClose={() => removeToast(msg.key)}
                />
            })}
        </div>}
        {props.children}
    </ToastContext.Provider>
}

interface ToastItemProps extends Toast {
    onClose: () => void
}

const ToastItem = (props: ToastItemProps) => {
    const closeToastClickHandler = () => {
        setTimeout(() => {
            props.onClose()
        }, 200)
    }

    return <div className={`${style.item} ${props.type === ToastType.SUCCESS ? style.success : style.error}`}>
        <div className={style.labelWrapper}>
            <div className={style.label}>{props.text}</div>
        </div>
        <div className={style.closeWrapper} onClick={closeToastClickHandler}>
            <div className={style.close}>
                <X />
            </div>
        </div>
    </div>
}

export const useToast = () => {
    const toastContext = React.useContext<ToastContextProps | null>(ToastContext)!
    return toastContext.toast
}

export default ToastProvider