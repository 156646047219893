/* eslint-disable no-throw-literal */
import { Dispatch } from 'redux'
import { api, apiErrorHandler, getApiRequestConfig, Response } from '../../api'
import { GetState } from '../reducers'
import { Spice } from '../reducers/spice.reducer'
import { AddLanguagesAction, LanguageActionTypes } from './language.actions'

export enum SpiceActionTypes {
    ADD_SPICE = 'ADD_SPICE',
    UPDATE_SPICE = 'UPDATE_SPICE',
    FETCH_SHOP_SPICES = 'FETCH_SHOP_SPICES'
}

export interface AddSpiceAction {
    type: SpiceActionTypes.ADD_SPICE
    data: Spice
}

export interface UpdateSpiceAction {
    type: SpiceActionTypes.UPDATE_SPICE
    data: Spice
}

export interface FetchShopSpicesAction {
    type: SpiceActionTypes.FETCH_SHOP_SPICES
    data: Spice[]
}

export type SpiceActions = AddSpiceAction | FetchShopSpicesAction | UpdateSpiceAction

interface SpiceRequestName {
    language: string
    name: string
}

interface AddSpice {
    names: SpiceRequestName[]
}

export const addSpice = (input: AddSpice) => async (dispatch: Dispatch, getState: GetState) => {
    const authAdmin = getState().authAdmin
    const config = getApiRequestConfig(authAdmin?.['auth-token'])

    return api.post<Response<Spice>>('spice', input, config).then(response => {
        if (response.status === 200) {
            dispatch<AddSpiceAction>({
                type: SpiceActionTypes.ADD_SPICE,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || 'Added')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to add'))
    })
}

interface UpdateSpice {
    names: SpiceRequestName[]
}

export const updateSpice = (input: UpdateSpice, spiceId: string) => async (dispatch: Dispatch, getState: GetState) => {
    const authAdmin = getState().authAdmin
    const config = getApiRequestConfig(authAdmin?.['auth-token'])

    return api.put<Response<Spice>>(`spice/${spiceId}`, input, config).then(response => {
        if (response.status === 200) {
            dispatch<UpdateSpiceAction>({
                type: SpiceActionTypes.UPDATE_SPICE,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || 'Updated')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to updated'))
    })
}

export const fetchShopSpices = () => async (dispatch: Dispatch, getState: GetState) => {
    const authAdmin = getState().authAdmin
    const config = getApiRequestConfig(authAdmin?.['auth-token'])

    return api.get<Response<Spice[]>>('spices', config).then(response => {
        if (response.status === 200) {
            dispatch<FetchShopSpicesAction>({
                type: SpiceActionTypes.FETCH_SHOP_SPICES,
                data: response.data.data
            })

            if (getState().authAdmin) {
                const languages: string[] = []

                response.data.data.forEach(d => {
                    d.names && d.names.forEach(p => {
                        languages.push(p.language)
                    })
                })

                dispatch<AddLanguagesAction>({
                    type: LanguageActionTypes.ADD_LANGUAGES,
                    data: languages
                })
            }

            return Promise.resolve<string>(response.data.message || '')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to fetch'))
    })
}