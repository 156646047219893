const Linkedin = () => {
    return <svg id='Layer_2' data-name='Layer 2' xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'>
        <g id='Layer_1' data-name='Layer 1'>
            <rect id='Rectangle_20' data-name='Rectangle 20' width='40' height='40' rx='6.48' fill='#0077b0' />
            <path id='Path_20' data-name='Path 20' d='M8.074,13.4h4.063V25.912H8.074ZM10.146,7.19A2.3,2.3,0,0,1,12.5,9.434a2.3,2.3,0,0,1-2.347,2.252A2.305,2.305,0,0,1,7.79,9.451a2.2,2.2,0,0,1,.685-1.6,2.415,2.415,0,0,1,1.671-.663' transform='translate(3.037 3.449)' fill='#fff' />
            <path id='Path_21' data-name='Path 21' d='M12.924,12.086h3.914v1.788a4.279,4.279,0,0,1,3.86-2.113c4.063,0,4.889,2.708,4.889,6.243v7.191H21.523V18.857c0-1.517,0-3.48-2.113-3.48s-2.438,1.652-2.438,3.358v6.487H12.91Z' transform='translate(5.171 4.726)' fill='#fff' />
        </g>
    </svg>
}

export default Linkedin