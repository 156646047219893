import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { StoreState } from '../../redux/reducers'
import { AuthUser } from '../../redux/reducers/authUser.reducer'
import UserAuthenticateForm from '../forms/UserAuthenticateForm'
import Authenticate, { AuthenticateType } from '../modals/Authenticate'
import { EventCart } from '../../redux/reducers/eventCart.reducer'

interface ShouldAuthenticateProps {
    children?: React.ReactNode | React.ReactNode[]
}

const ShouldAuthenticate = (props: ShouldAuthenticateProps) => {
    const authUser = useSelector<StoreState, AuthUser | null>(state => state.authUser)
    const eventCart = useSelector<StoreState, EventCart>((state) => state.eventCart);
    const navigate = useNavigate()

    const [authenticateType, setAuthenticateType] = React.useState<AuthenticateType>(AuthenticateType.SIGN_IN)

    return (authUser||eventCart.item?.withoutRegistration&&eventCart.item?.withoutRegistration==='Yes')
        ? <React.Fragment>
            {props.children}
        </React.Fragment>
        : <div className='vh-100'>
            <Authenticate onClose={() => navigate(-1)} authenticateType={authenticateType}>
                <UserAuthenticateForm authenticateType={authenticateType} onAuthenticateTypeChange={setAuthenticateType} />
            </Authenticate>
        </div>
}

export default ShouldAuthenticate