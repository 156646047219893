interface InfoProps {
    stroke?: string
    strokeWidth?: number
}


const Camera = (props: InfoProps) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke={props.stroke || '#404040'} strokeWidth={props.strokeWidth || '1'} strokeLinecap="round" strokeLinejoin="round" className="feather feather-camera">
        <path d="M23 19a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h4l2-3h6l2 3h4a2 2 0 0 1 2 2z"></path><circle cx="12" cy="13" r="4"></circle>
    </svg>
}

export default Camera