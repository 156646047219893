import style from '../../styles/components/switch.module.scss'

interface SwitchProps {
    onClick?: () => void
    // onChange?: () => void
    checked?: boolean
    label?: string
    name?: string
}

const Switch = (props: SwitchProps) => {
    return <div className={style.container}>
        <input className={style.input} type='checkbox' role='switch' onChange={props.onClick} checked={props.checked} name={props.name} />
        {props.label && <label className={style.label}>{props.label}</label>}
    </div>
}

export default Switch