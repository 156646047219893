import axios, { AxiosRequestConfig } from 'axios'
import { API_URL,DELIVERY_API_URL } from '../config'

export interface Response<D = undefined> {
    error?: any
    message?: string
    data: D
}

export const api = axios.create({ baseURL: API_URL + '/api/' })
export const deliveryApi = axios.create({ baseURL: DELIVERY_API_URL + '/api/' })

export const apiErrorHandler = (error: any, errorText?: string) => {
    const defaultText = 'Unable to process request'
    if (error?.code === 'ERR_NETWORK') {
        return String(errorText || error?.message || defaultText)
    } else if (error?.response) {
        return String(error?.response.data?.message || errorText || defaultText)
    } else {
        return String(errorText || error?.message || defaultText)
    }
}

export const getApiRequestConfig = (token?: string): AxiosRequestConfig => {
    return {
        headers: {
            'auth-token': token || ''
        }
    }
}