import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Tooltip } from 'react-tooltip'
import { getApiRequestConfig } from '../../api'
import { ToastType, useToast } from '../../components/ToastProvider'
import AdminContactDetailsForm from '../../components/forms/AdminContactDetailsForm'
import ChangePasswordForm from '../../components/forms/ChangePasswordForm'
import ProductPreviewForm from '../../components/forms/ProductPreviewForm'
import ModalWithHeader from '../../components/modals/ModalWithHeader'
import AdminLayout from '../../components/sections/AdminLayout'
import AdminPanelHeader from '../../components/sections/AdminPanelHeader'
import BankSettings from '../../components/sections/BankSettings'
import DeliveryAPI from '../../components/sections/DeliveryAPI'
import DeliveryChargeAndDistance from '../../components/sections/DeliveryChargeAndDistance'
import Paytrail from '../../components/sections/Paytrail'
import ProcessTime from '../../components/sections/ProcessTime'
import SectionWithTitle from '../../components/sections/SectionWithTitle'
import ShopTheme from '../../components/sections/ShopTheme'
import Timing from '../../components/sections/Timing'
import Button from '../../components/utils/Button'
import FormInput from '../../components/utils/FormInput'
import { AppContext, AppContextType } from '../../context/AppProvider'
import { useT } from '../../i18n'
import { disableDelivery, enableDelivery, updateShopPreviewTheme, updateVatId } from '../../redux/actions'
import { StoreState } from '../../redux/reducers'
import { AuthAdmin } from '../../redux/reducers/authAdmin.reducer'
import { AppDispatch } from '../../redux/store'
import style from '../../styles/components/form.module.scss'
// import { input } from '../../styles/components/input.module.scss'
import PickupPoint from '../../components/sections/PickupPoint'
import { toString } from '../../utils'
import ProductView from '../ProductView'

interface Price {
    worsePrice: string
    bestPrice: string
}

interface PreviewTheme {
    animation: string
    fontStyle: string
    backgroundColor: string
    color: string
    priceColor: Price
}

const Settings = () => {
    const appContext = React.useContext<AppContextType | null>(AppContext)

    const dispatch = useDispatch<AppDispatch>()
    const toast = useToast()
    const t = useT()

    const authAdmin = useSelector<StoreState, AuthAdmin | null>(state => state.authAdmin)

    const apiRequestConfig = getApiRequestConfig(authAdmin?.['auth-token'])
    const [copyText, setCopyText] = React.useState(false);
    const [perviewProduct, setPreviewProduct] = React.useState(false)

    const [previewTheme, setPreviewTheme] = React.useState<PreviewTheme>({
        animation: 'rightLeftAnimations',
        fontStyle: 'EB Garamond',
        backgroundColor: 'tan',
        color: '#000',
        priceColor: { worsePrice: 'darkred', bestPrice: '#000' }
    })

    const [vatId, setVatId] = React.useState<string>('')
    const [vatIdError, setVatIdError] = React.useState<string>('')

    const [vatIdSaving, setVatIdSaving] = React.useState<boolean>(false)
    const [deliveryEnabled, setDeliveryEnabled] = React.useState<boolean | null>(null)

    const vatIdChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setVatId(e.target.value)
        setVatIdError('')
    }

    const vatIdSaveClickHandler = () => {
        let error = false

        if (toString(vatId) === '') {
            setVatIdError(t('Vat ID required'))
            error = true
        }

        if (!error) {
            setVatIdSaving(true)
            dispatch(updateVatId(toString(vatId) || null)).then(() => {
                toast(t('Vat ID updated'))
            }).catch(text => {
                toast(text, ToastType.ERROR)
            }).finally(() => {
                setVatIdSaving(false)
            })
        }
    }

    const copyClipBoard = () => {
        setCopyText(true);
        let hostName = window.location.host;
        navigator.clipboard.writeText(`${hostName}/product-view/${authAdmin?.shop.id}`)
        setTimeout(() => {
            setCopyText(false);
        }, 1000)
    }

    const toggleDeliveryEnableSwith = () => {
        const enabled = !deliveryEnabled
        setDeliveryEnabled(enabled)

        if (enabled) {
            appContext?.showOverlay?.()
            dispatch(enableDelivery()).then(() => {
                toast(t('Delivery enabled'))
            }).catch(text => {
                toast(text, ToastType.ERROR)
            }).finally(() => {
                appContext?.hideOverlay?.()
            })
        } else {
            appContext?.showOverlay?.()
            dispatch(disableDelivery()).then(() => {
                toast(t('Delivery disabled'))
            }).catch(text => {
                toast(text, ToastType.ERROR)
            }).finally(() => {
                appContext?.hideOverlay?.()
            })
        }
    }

    const previewHandler = (type: string, value: string) => {
        if (type === "bestPrice" || type === "worsePrice") {
            setPreviewTheme((prev: PreviewTheme) => ({ ...prev, priceColor: { ...prev.priceColor, [type]: value } }))
            return
        }

        setPreviewTheme((prev: PreviewTheme) => ({ ...prev, [type]: value }))
    }

    const onSavePreviewThemeHandler = () => {
        let flag = true;
        dispatch(updateShopPreviewTheme({
            animation: toString(previewTheme.animation) || null,
            fontStyle: toString(previewTheme.fontStyle) || null,
            backgroundColor: toString(previewTheme.backgroundColor) || null,
            color: toString(previewTheme.color) || null,
            priceColor: {
                worsePrice: toString(previewTheme.priceColor.worsePrice) || null,
                bestPrice: toString(previewTheme.priceColor.bestPrice) || null,
            },
        })).then(() => {
            setPreviewProduct(false);
            toast(t('Preview theme updated'))
        }).catch(text => {
            toast(text, ToastType.ERROR)
        }).finally(() => {
            return false
        })
        return flag;
    }
    React.useEffect(() => {
        if (authAdmin) {
            setDeliveryEnabled(authAdmin.shop.delivery.enabled)
            setVatId(authAdmin.shop.vatId || '')
            setPreviewTheme((prev: PreviewTheme) => ({
                ...prev,
                animation: authAdmin.shop?.previewTheme?.animation ? authAdmin.shop?.previewTheme?.animation : 'rightLeftAnimations',
                fontStyle: authAdmin.shop?.previewTheme?.fontStyle ? authAdmin.shop?.previewTheme?.fontStyle : 'EB Garamond',
                backgroundColor: authAdmin.shop?.previewTheme?.backgroundColor ? authAdmin.shop?.previewTheme?.backgroundColor : '#dfdea9',
                color: authAdmin.shop?.previewTheme?.color ? authAdmin.shop?.previewTheme?.color : '#000000',
                priceColor: {
                    worsePrice: authAdmin.shop?.previewTheme?.priceColor.worsePrice ? authAdmin.shop?.previewTheme?.priceColor.worsePrice : '#ff0040',
                    bestPrice: authAdmin.shop?.previewTheme?.priceColor.bestPrice ? authAdmin.shop?.previewTheme?.priceColor.bestPrice : '#000000'
                }
            }))
        }
    }, [authAdmin])

    return <AdminLayout>
        {
            perviewProduct && <ModalWithHeader onClose={() => setPreviewProduct(false)} headerText='Preview Product' large>
                <div className='row'>
                    <div className='col-8'>
                        <ProductView shopId={authAdmin?.shop.id} previewTheme={previewTheme} />
                    </div>
                    <div className='col-4'>
                        <ProductPreviewForm previewTheme={previewTheme} previewHandler={previewHandler} onSave={onSavePreviewThemeHandler} />
                    </div>
                </div>
            </ModalWithHeader>
        }
        <AdminPanelHeader title={t('Settings')} />
        <br />
        <SectionWithTitle title={t('Contact details')} withRow>
            <AdminContactDetailsForm />
        </SectionWithTitle>

        <SectionWithTitle title={t('Reset Password')} withRow>
            <ChangePasswordForm
                endPoint='admin/reset-password'
                apiConfig={apiRequestConfig}
            />
        </SectionWithTitle>

        {/* <SectionWithTitle title='Bank details' withRow>
            <AdminBankDetailsForm />
        </SectionWithTitle> */}

        <div className='row'>
            <div className={(authAdmin?.shop?.plan === 'Starter' || authAdmin?.shop?.plan === 'Economy' || authAdmin?.shop?.plan === 'Standard' || authAdmin?.category === "Booking") ? "col-md-8 col-lg-12" : "col-md-8 col-lg-6"}>
                <SectionWithTitle title={t('Shop Timing')} withRow>
                    <Timing
                        timing={authAdmin?.shop.shopTiming}
                        startTimingText={t('Opening Time')}
                        endTimingText={t('Closing Time')}
                        type='shop'
                    />
                </SectionWithTitle>
            </div>
            {(authAdmin?.shop?.plan === 'Premium' || authAdmin?.shop?.plan?.toString() === '' || authAdmin?.shop?.plan === undefined) && authAdmin?.category !== "Booking" && <div className='col-md-8 col-lg-6'>
                <SectionWithTitle title={t('Delivery Timing')} withRow>
                    <Timing
                        timing={authAdmin?.shop.delivery.timing}
                        startTimingText={t('Start Time')}
                        endTimingText={t('End Time')}
                        type='delivery'
                    />
                </SectionWithTitle>
            </div>}
        </div>
        {authAdmin?.category !== "Event" && authAdmin?.category !== "Booking" && (authAdmin?.shop?.plan === 'Premium' || authAdmin?.shop?.plan?.toString() === '' || authAdmin?.shop?.plan === undefined) &&
            <SectionWithTitle
                title={t('Delivery charge and distance')}
                withRow
                toggleSwitch={toggleDeliveryEnableSwith}
                switchState={deliveryEnabled ?? false}
                switchLabel={authAdmin?.shop.delivery.enabled ? t('Enabled') : t('Disabled')}
            >
                <DeliveryChargeAndDistance />
            </SectionWithTitle>
        }
        {(authAdmin?.category === "Food" || authAdmin?.category === "Product") && (
            <SectionWithTitle title="Pick-Up Point">
                <PickupPoint />
            </SectionWithTitle>
        )}
        <SectionWithTitle title={t('Other Payment Details')} withRow>
            <BankSettings />
        </SectionWithTitle>

        <div className='row mb-4'>
            {(authAdmin?.shop?.plan === 'Premium' || authAdmin?.shop?.plan?.toString() === '' || authAdmin?.shop?.plan === undefined) && authAdmin?.category !== "Booking" && <div className='col-md-6'>
                <SectionWithTitle title={t('Delivery API')} withRow>
                    <DeliveryAPI />
                </SectionWithTitle>
            </div>}
            {(authAdmin?.shop?.plan === 'Standard' || authAdmin?.shop?.plan === 'Premium' || authAdmin?.shop?.plan?.toString() === '' || authAdmin?.shop?.plan === undefined) && <div className={(authAdmin?.shop?.plan === 'Standard') ? "col-md-8 col-lg-12" : "col-md-8 col-lg-6"}>
                <SectionWithTitle title={t('Paytrail')} withRow>
                    <Paytrail />
                </SectionWithTitle>
            </div>}
        </div>
        {authAdmin?.category !== "Event" && authAdmin?.category !== "Booking" &&
            <SectionWithTitle title={t('Process time')} withRow>
                <ProcessTime />
            </SectionWithTitle>
        }
        <SectionWithTitle title={t('Theme')}>
            <ShopTheme />
        </SectionWithTitle>
        {(authAdmin?.shop?.plan === 'Standard' || authAdmin?.shop?.plan === 'Premium' || authAdmin?.shop?.plan?.toString() === '' || authAdmin?.shop?.plan === undefined) && <SectionWithTitle title="Product View">
            <span>
                <Button className={style.copyButton} onClick={copyClipBoard} data-tooltip-id="copyClip" data-tooltip-content={!copyText ? "Copy" : 'Copied!'}>{t("Copy Link")}</Button>
                <Tooltip id="copyClip" style={{ borderRadius: "15px", width: "100px", textAlign: 'center' }} />
                <Button className={style.productPreview} onClick={() => setPreviewProduct(true)}>{t("Preview Products")}</Button>
            </span>
        </SectionWithTitle>}

        <SectionWithTitle title={t('Vat ID')}>
            <FormInput
                placeholder={t('Vat ID')}
                containerClass='mb-4'
                value={vatId}
                errorText={vatIdError}
                onChange={vatIdChangeHandler}
            />
            <div className='col-12 mb-4'>
                <Button className={style.saveButton} onClick={vatIdSaveClickHandler} loading={vatIdSaving}>{t("Save")}</Button>
            </div>
        </SectionWithTitle>
    </AdminLayout>
}

export default Settings