/* eslint-disable no-throw-literal */
import { Dispatch } from 'redux'
import { AddSizesAction, SizeActionTypes } from '.'
import { Response, api, apiErrorHandler, getApiRequestConfig } from '../../api'
import { GetState } from '../reducers'
import { Appointment, AppointmentType, Slot, TimeType } from '../reducers/appointment.reducer'
import { AddLanguagesAction, LanguageActionTypes } from './language.actions'

export enum AppointmentActionTypes {
    ADD_APPOINTMENT = 'ADD_APPOINTMENT',
    UPDATE_APPOINTMENT = 'UPDATE_APPOINTMENT',
    FETCH_APPOINTMENTS = 'FETCH_APPOINTMENTS'
}

export interface FetchAppointmentsAction {
    type: AppointmentActionTypes.FETCH_APPOINTMENTS
    data: Appointment[]
}

export interface AddAppointmentAction {
    type: AppointmentActionTypes.ADD_APPOINTMENT
    data: Appointment
}

export interface UpdateAppointmentAction {
    type: AppointmentActionTypes.UPDATE_APPOINTMENT
    data: Appointment
}

export type AppointmentActions = FetchAppointmentsAction | AddAppointmentAction | UpdateAppointmentAction

export const fetchAppointments = () => async (dispatch: Dispatch, getState: GetState) => {
    const token = getState().authAdmin?.['auth-token']
    const config = getApiRequestConfig(token)

    return api.get<Response<Appointment[]>>('appointments', config).then(response => {
        if (response.status === 200) {
            dispatch<FetchAppointmentsAction>({
                type: AppointmentActionTypes.FETCH_APPOINTMENTS,
                data: response.data.data
            })

            if (getState().authAdmin) {
                const sizes: string[] = []
                const languages: string[] = []

                response.data.data.forEach(d => {
                    d.prices && d.prices.forEach(p => {
                        sizes.push(p.size)
                    })

                    d.names && d.names.forEach(p => {
                        languages.push(p.language)
                    })
                })

                dispatch<AddSizesAction>({
                    type: SizeActionTypes.ADD_SIZES,
                    data: sizes
                })

                dispatch<AddLanguagesAction>({
                    type: LanguageActionTypes.ADD_LANGUAGES,
                    data: languages
                })
            }

            return Promise.resolve<string>(response.data.message || '')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to fetch'))
    })
}

export const fetchUserAppointments = () => async (dispatch: Dispatch, getState: GetState) => {
    const token = getState().authUser?.['auth-token']
    const config = getApiRequestConfig(token)

    return api.get<Response<Appointment[]>>('user-appointments', config).then(response => {
        if (response.status === 200) {
            dispatch<FetchAppointmentsAction>({
                type: AppointmentActionTypes.FETCH_APPOINTMENTS,
                data: response.data.data
            })

            if (getState().authUser) {
                const sizes: string[] = []
                const languages: string[] = []

                response.data.data.forEach(d => {
                    d.prices && d.prices.forEach(p => {
                        sizes.push(p.size)
                    })

                    d.names && d.names.forEach(p => {
                        languages.push(p.language)
                    })
                })

                dispatch<AddSizesAction>({
                    type: SizeActionTypes.ADD_SIZES,
                    data: sizes
                })

                dispatch<AddLanguagesAction>({
                    type: LanguageActionTypes.ADD_LANGUAGES,
                    data: languages
                })
            }

            return Promise.resolve<string>(response.data.message || '')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to fetch'))
    })
}

export interface AppointmentName {
    name: string
    language: string
}

export interface AppointmentDescription {
    description: string
    language: string
}

export interface AppointmentPrice {
    price: number
    offerPrice: number | null
    size: string
}

export interface AddAppointment {
    names: AppointmentName[]
    descriptions: AppointmentDescription[]
    categories: string[]
    image: string
    type: AppointmentType
    tax: number | null
    stock: number | null
    additionalImages: string[]
    offerLabel: string | null
    price: number | null
    offerPrice: number | null
    prices: AppointmentPrice[]
    fromTime?: string
    toTime?: string
    capacity: number | null
    date: string
    slots:Slot[]
}

export const addAppointment = (input: AddAppointment) => async (dispatch: Dispatch, getState: GetState) => {
    const token = getState().authAdmin?.['auth-token']
    const config = getApiRequestConfig(token)

    return api.post<Response<Appointment>>('appointment', input, config).then(response => {
        if (response.status === 200) {
            dispatch<AddAppointmentAction>({
                type: AppointmentActionTypes.ADD_APPOINTMENT,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || 'appointment added')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable add Appointment'))
    })
}

type UpdateAppointment = AddAppointment

export const updateAppointment = (input: UpdateAppointment, appointmentId: string) => async (dispatch: Dispatch, getState: GetState) => {
    const token = getState().authAdmin?.['auth-token']
    const config = getApiRequestConfig(token)

    return api.put<Response<Appointment>>(`appointment/${appointmentId}`, input, config).then(response => {
        if (response.status === 200) {
            dispatch<UpdateAppointmentAction>({
                type: AppointmentActionTypes.UPDATE_APPOINTMENT,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || 'appointment updated')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to update'))
    })
}


export const updateAppointmentStatus = (status: string, appointmentId: string | undefined) => async (dispatch: Dispatch, getState: GetState) => {
    const token = getState().authAdmin?.['auth-token']
    const config = getApiRequestConfig(token)

    return api.put<Response<Appointment>>(`appointment/update-status/${appointmentId}`, { status: status }, config).then(response => {
        if (response.status === 200) {
            dispatch<UpdateAppointmentAction>({
                type: AppointmentActionTypes.UPDATE_APPOINTMENT,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || 'appointment updated')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to update'))
    })
}

export const updateAppointmentBanner = (formData: any, appointmentId: string | undefined | null) => async (dispatch: Dispatch, getState: GetState) => {
    const token = getState().authAdmin?.['auth-token']
    const config = getApiRequestConfig(token)
    return api.put<Response<Appointment>>(`appointment/upload-image/${appointmentId}`, formData, config).then(response => {
        if (response.status === 200) {
            dispatch<UpdateAppointmentAction>({
                type: AppointmentActionTypes.UPDATE_APPOINTMENT,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || 'appointment updated')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to update'))
    })
}