import { Action, ReservationActionTypes } from '../actions'


export type PaymentModeReservation = 'card' | 'upi' | 'paytrail' | 'bank-transfer' | 'free'
export type PaymentStatus = 'pending' | 'paid' | 'failed'
export type ReservationStatus = 'booked' | 'attended' | 'cancelled' | 'failed'


export interface ReservationDetails {
    id?: string
    name: string
    type: { price: number, size: string }
    category?: string
}

export interface TimeType {
    time: string
    timePeriod: string
}


export interface User {
    firstName: string
    lastName: string
    email: string
    phoneNumber: string
    address: {
        addressLine1: string
        addressLine2: string
        city: string
        pincode: number
        state: string
        landmark: string | null
    }
}

export interface Reservation {
    id?: string
    appointmentId?: string
    shopId?: string
    user?: User
    reservationStatus?: ReservationStatus
    paymentMode?: any
    cultureVoucher?: string
    reservationDetails?: ReservationDetails[]
    appointmentDate?: string
    toTime?: string
    fromTime?: string
    reservationDate?: string
    totalHours?:string
    priceSize?:string
    price?:number
    totalAmount?: number | undefined
    billAmount?: number
    slotTime?:string
}

const initialState: Reservation[] = []

const ReservationReducer = (state: Reservation[] = initialState, action: Action): Reservation[] => {
    switch (action.type) {
        case ReservationActionTypes.ADD_RESERVATION: {
            return [
                ...state,
                action.data
            ]
        }

        case ReservationActionTypes.FETCH_RESERVATIONS: {
            return action.data
        }

        case ReservationActionTypes.REPLACE_RESERVATION: {
            const newState = [...state]
            const index = newState.findIndex(s => s.id === action.data.id)

            if (index > -1) {
                newState[index] = { ...action.data }
            }

            return newState
        }

        default: {
            return state
        }
    }
}

export default ReservationReducer