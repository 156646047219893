import React from 'react'
import { api } from '../../api'
import { useT } from '../../i18n'
import style from '../../styles/components/instant-input-form.module.scss'
import Camera from '../svgs/Camera'
import Edit from '../svgs/Edit'

export interface InstantInputFormProps {
    onAdd?: (value: string) => void
    label?: string
    type?: string
}

const InstantInputForm = (props: InstantInputFormProps) => {
    const [onCamera, setOnCamera] = React.useState<boolean>(false)
    const [value, setValue] = React.useState<any>('')
    const [show, setShow] = React.useState<boolean>(false)
    const t = useT()

    const onChangeMoreImageHandler: React.ChangeEventHandler<HTMLInputElement | HTMLFormElement> = async (e) => {
        let response: any
        if (onCamera) {
            const formData: any = new FormData()
            formData.append("file", e.target.files[0]);
            response = await api.post<Response>(`upload-img/${props.type}`, formData);
        }
        onCamera ? setValue(response.data.data) : setValue(e.target.value)
    }
    const addClickHandler = () => {
        if (show) {
            if (value !== '') {
                props.onAdd?.(value)
                setValue('')
                setShow(false)
            }
        } else {
            setShow(true)
        }
    }

    const submitHandler: React.FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault()
        if (value !== '') {
            props.onAdd?.(value)
            setValue('')
            setShow(false)
        }
    }
    const cancelClickHandler = () => {
        setShow(false)
        setValue('')
    }

    return <form className='d-inline-block' onSubmit={submitHandler}>
        {show && !props.type && <input type='text' className={`px-2 me-2 ${style.newLanguageInput}`} value={value} onChange={e => setValue(e.target.value)} autoFocus />}
        {show && props.type &&
            <React.Fragment>
                <input type={onCamera ? "file" : "text"} className={`px-2 me-2 ${style.newLanguageInput}`} value={onCamera ? value.filename : value} onChange={onChangeMoreImageHandler} />
                <div className={`px-1 py-1 ${style.changeFieldButton}`} onClick={() => setOnCamera(onCamera === true ? false : true)}>{!onCamera ? <Camera strokeWidth={2} /> : <Edit />}</div>
            </React.Fragment>
        }
        <div className={`ms-2 px-3 py-1 ${style.addNewLanguageButton}`} onClick={addClickHandler}>{show ? 'Add' : `Add ${props.label || ''}`}</div>
        {show && <div className={`ms-2 px-3 py-1 ${style.cancelLanguageButton}`} onClick={cancelClickHandler}>{t("Cancel")}</div>}
    </form>
}

export default InstantInputForm