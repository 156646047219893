import React from 'react'
import { useDispatch } from 'react-redux'
import { AdminContext, AdminContextType } from '../../context/AdminProvider'
import { useT } from '../../i18n'
import { updateFoodPreivewStatus, updateFoodStatus } from '../../redux/actions'
import { AppDispatch } from '../../redux/store'
import Card from './Card'
import FoodTable from './FoodTable'

export interface FoodCardProps {
    name?: string
    img?: string
    offerLabel?: string | null
    price?: number | null
    status?: string | null
    previewStatus?: string | null
    offerPrice?: number | null
    id?: string
    currency?: string
    key?: number
    index?: number
}

const FoodCard = (props: FoodCardProps) => {
    const adminContext = React.useContext<AdminContextType | null>(AdminContext)
    const [foodIsActive, setFoodIsActive] = React.useState(props?.status);
    const [productPreview, setProductPreview] = React.useState(props.previewStatus || "inactive");
    const dispatch = useDispatch<AppDispatch>();
    const t = useT()

    const toggleHandler = () => {
        if (props.status) {
            const foodId = props.id;
            if (props.status === "active") {
                dispatch(updateFoodStatus("inactive", foodId)).finally(() => {
                    setFoodIsActive("inactive");
                })
            } else {
                dispatch(updateFoodStatus("active", foodId)).finally(() => {
                    setFoodIsActive("active");
                })
            }
        }
    }

    const productPreviewHandler = () => {
        if (productPreview) {
            const foodId = props.id;
            if (productPreview === "active") {
                dispatch(updateFoodPreivewStatus("inactive", foodId)).finally(() => {
                    setProductPreview("inactive");
                })
            } else {
                dispatch(updateFoodPreivewStatus("active", foodId)).finally(() => {
                    setProductPreview("active");
                })

            }
        }
    }

    return <>
        {adminContext?.isCardStyle ?
            <Card
                img={props.img}
                name={props.name}
                offerLabel={props.offerLabel || ''}
                onClick={() => props.id && adminContext?.onEditFood?.(props.id)}
                buttonText={t('Edit')}
                status={foodIsActive}
                toggleHandler={toggleHandler}
                price={props.price !== Infinity ? props.price : 0}
                offerPrice={props.offerPrice !== Infinity ? props.offerPrice : 0}
                uploadBanner={() => props.id && adminContext?.onCamera?.(props.id)}
                updatePrice={() => props.id && adminContext?.onPriceUpdate?.(props.id)}
                previewHandler={productPreviewHandler}
                preview={productPreview === "active" ? true : false}
                from={false}
                currency={props.currency}
            // index={props.index}
            /> : <FoodTable
                img={props.img}
                name={props.name}
                offerLabel={props.offerLabel || ''}
                onClick={() => props.id && adminContext?.onEditFood?.(props.id)}
                buttonText={t('Edit')}
                status={foodIsActive}
                toggleHandler={toggleHandler}
                price={props.price !== Infinity ? props.price : 0}
                offerPrice={props.offerPrice !== Infinity ? props.offerPrice : 0}
                uploadBanner={() => props.id && adminContext?.onCamera?.(props.id)}
                updatePrice={() => props.id && adminContext?.onPriceUpdate?.(props.id)}
                previewHandler={productPreviewHandler}
                preview={productPreview === "active" ? true : false}
                from={false}
                currency={props.currency}
                index={props.index}
            />}
    </>
}

export default FoodCard