import { Action, OtherBookingActionTypes } from '../actions'
import { Category } from './category.reducer'

export interface OtherBookingReports {
    count: number
    date: string
    price: number
}


export interface OtherBookingReport {
    _id: {
        category: Category[]
    }
    data: OtherBookingReports[]
}

const initialState: OtherBookingReport[] = []


const otherBookingReportReducer = (state: OtherBookingReport[] = initialState, action: Action): OtherBookingReport[] => {
    switch (action.type) {
        case OtherBookingActionTypes.FETCH_OTHER_BOOKING_REPORTS: {
            return action.data
        }
        default: {
            return state
        }
    }
}

export default otherBookingReportReducer;