import React from 'react'
import { animate } from '.'
import assets from '../../assets'
import style from '../../styles/components/confetti.module.scss'

interface ConfettiProps {
    onClose?: () => void
}

const Confetti = (props: ConfettiProps) => {
    const container = React.useRef<HTMLDivElement>(null)

    React.useEffect(() => {
        if (container.current) {
            animate({
                container: container.current,
                data: assets.json.confetti
            }, props.onClose)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <div className={style.container}>
        <div className='w-100 h-100' ref={container}></div>
    </div>
}


export default Confetti