import { nanoid } from "@reduxjs/toolkit";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useT } from "../../i18n";
import { updateShopOtherPayments } from "../../redux/actions/authAdmin.actions";
import { StoreState } from "../../redux/reducers";
import { AuthAdmin } from "../../redux/reducers/authAdmin.reducer";
import { AppDispatch } from "../../redux/store";
import formStyle from '../../styles/components/form.module.scss';
import { toString } from '../../utils';
import { ToastType, useToast } from "../ToastProvider";
import Button from "../utils/Button";
import FormInput from "../utils/FormInput";

interface OtherPayment {
    id: string
    title: string
    detail: string
    transactionNeeded: boolean
}

const BankSettings = () => {
    const dispatch = useDispatch<AppDispatch>()
    const toast = useToast()
    const t = useT()

    const authAdmin = useSelector<StoreState, AuthAdmin | null>(state => state.authAdmin)

    const [otherPayments, setOtherPayments] = React.useState<OtherPayment[]>([])
    const [otherPaymentObj, setOtherPaymentObj] = React.useState<OtherPayment>({ id: nanoid(), title: '', detail: '', transactionNeeded: false })
    const [titleError, setTitleError] = React.useState<string>('')
    const [disable, setDisable] = React.useState<boolean>(true)
    const [detailError, setDetailError] = React.useState<string>('')
    const [loading, setLoading] = React.useState<boolean>(false)

    const inputChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const { name, value } = e.target;
        let error = false
        if (toString(value) !== '' && name === "title") {
            if (otherPayments.some((otherPayment: any) => otherPayment.title === toString(value))) {
                setTitleError(t('Title already exists'))
                error = true
            } else {
                setTitleError('')
            }
        }
        else if (toString(value) !== '' && name === "detail") {
            if (otherPayments.some((otherPayment: any) => otherPayment.detail === toString(value))) {
                setDetailError(t('Detail already exists'))
                error = true
            } else {
                setDetailError('')
            }
        }
        // else if (name === "transactionNeeded") {
        //     if (otherPayments.some((otherPayment: any) => otherPayment.detail === toString(value))) {
        //         setDetailError(t('Detail already exists'))
        //         error = true
        //     } else {
        //         setDetailError('')
        //     }
        // }

        if (!error) {
            if (name === "transactionNeeded") {
                console.log('in');

                setOtherPaymentObj({ ...otherPaymentObj, transactionNeeded: !otherPaymentObj.transactionNeeded });
            }
            else {
                setOtherPaymentObj({ ...otherPaymentObj, [name]: value });

            }
        }
    }

    // const editHandler = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
    //     const { name, value } = e.target;
    //     let error = false
    //     if (toString(value) !== '' && name === "title") {
    //         if (otherPayments.some((otherPayment: any) => otherPayment.title === toString(value))) {
    //             setTitleError(t('Title already exists'))
    //             error = true
    //             setDisable(false)
    //         } else {
    //             setTitleError('')
    //             setDisable(false)
    //         }
    //     }
    //     else if (toString(value) !== '' && name === "detail") {
    //         if (otherPayments.some((otherPayment: any) => otherPayment.detail === toString(value))) {
    //             setDetailError(t('Detail already exists'))
    //             error = true
    //             setDisable(false)
    //         } else {
    //             setDetailError('')
    //             setDisable(false)
    //         }
    //     }

    //     if (!error) {
    //         if (name === "transactionNeeded") {
    //             console.log('in');

    //             setOtherPaymentObj({ ...otherPaymentObj, transactionNeeded: !otherPaymentObj.transactionNeeded });
    //         }
    //         else {
    //             setOtherPayments([...otherPayments, otherPaymentObj])
    //             setOtherPaymentObj({ id: nanoid(), title: '', detail: '', transactionNeeded: false })
    //             setDisable(false)
    //         }
    //     }

    // }
    const editHandler = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
        const { name, value } = e.target;
        let error = false;

        // Validation checks for title and detail
        if (toString(value) !== '' && name === "title") {
            if (otherPayments.some((otherPayment) => otherPayment.title === toString(value) && otherPayment.id !== id)) {
                setTitleError(t('Title already exists'));
                error = true;
            } else {
                setTitleError('');
            }
        } else if (toString(value) !== '' && name === "detail") {
            if (otherPayments.some((otherPayment) => otherPayment.detail === toString(value) && otherPayment.id !== id)) {
                setDetailError(t('Detail already exists'));
                error = true;
            } else {
                setDetailError('');
            }
        }

        if (!error) {
            // Update the specific payment with new values
            setOtherPayments((prevPayments) =>
                prevPayments.map((payment) =>
                    payment.id === id
                        ? { ...payment, [name]: value }
                        : payment
                )
            );
            setDisable(false);
        }
    };

    const addClickHandler = () => {
        let error = false;
        if (toString(otherPaymentObj.title) === '') {
            setTitleError(t('Title required'))
            error = true
        }

        if (toString(otherPaymentObj.detail) === '') {
            setDetailError(t('Detail required'))
            error = true
        }
        if (!error) {
            setOtherPayments([...otherPayments, otherPaymentObj])
            setOtherPaymentObj({ id: nanoid(), title: '', detail: '', transactionNeeded: false })
            setDisable(false)
        }

    }

    const deleteHandler = (id: string) => {
        setOtherPayments([...otherPayments.filter((otherPayment) => otherPayment.id !== id)]);
        setDisable(false)
    }

    const saveClickHandler: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault()
        let error = false

        if (!error) {
            setLoading(true)
            dispatch(updateShopOtherPayments(otherPayments.map((otherPayment) => { return { title: otherPayment.title, detail: otherPayment.detail, transactionNeeded: otherPayment.transactionNeeded } }))).then(() => {
                toast(t('Bank Details updated'))
            }).catch(text => {
                toast(text, ToastType.ERROR)
            }).finally(() => {
                setLoading(false)
                setDisable(true);
                setOtherPaymentObj({ id: nanoid(), title: '', detail: '', transactionNeeded: false })
            })
        }
    }

    // React.useEffect(() => {
    //     if (authAdmin?.shop?.otherPayments && authAdmin?.shop?.otherPayments.length !== 0) {
    //         setOtherPayments(authAdmin?.shop?.otherPayments?.map((otherPayment: any) => {
    //             return {
    //                 id: nanoid(),
    //                 title: otherPayment.title.toString(),
    //                 detail: otherPayment.detail.toString()
    //             }
    //         }) || [])
    //     }
    // }, [authAdmin])

    React.useEffect(() => {
        if (authAdmin?.shop) {
            console.log(authAdmin?.shop);
            console.log(authAdmin?.shop.otherPayments)
            const otherPaymentsArray = (authAdmin.shop.otherPayments as any).otherPayments?.length > 0
                ? (authAdmin.shop.otherPayments as any).otherPayments.map((otherPayment: any) => ({
                    id: nanoid(),
                    title: otherPayment.title.toString(),
                    detail: otherPayment.detail.toString(),
                    transactionNeeded: otherPayment.transactionNeeded
                }))
                : [];
            console.log(otherPaymentsArray);

            setOtherPayments(otherPaymentsArray);
        }
    }, [authAdmin]);

    return (
        <React.Fragment>
            {
                (authAdmin?.category === "Food" || authAdmin?.category === "Product" || authAdmin?.category === "Booking") && otherPayments.map((otherPayment) => (
                    <React.Fragment>
                        <div className='col-md-6 col-lg-4'>
                            <FormInput
                                type='text'
                                placeholder={t('Title')}
                                name="title"
                                value={otherPayment.title}
                                onChange={(e) => editHandler(e, otherPayment.id)}
                                containerClass='mb-4'
                                disabled
                            />
                        </div>
                        <div className='col-md-6 col-lg-4'>
                            <FormInput
                                type='text'
                                name="detail"
                                placeholder={t('Detail')}
                                value={otherPayment.detail}
                                onChange={(e) => editHandler(e, otherPayment.id)}
                                containerClass='mb-4'
                                disabled
                            />
                        </div>

                        <div className='col-md-6 col-lg-2'>
                            <Button onClick={() => deleteHandler(otherPayment.id)} className={formStyle.saveButton}>{t("Delete")}</Button>
                        </div>
                    </React.Fragment>
                ))
            }
            {(authAdmin?.category === "Food" || authAdmin?.category === "Product" || authAdmin?.category === "Booking") && <>
                <div className='col-md-6 col-lg-4'>
                    <FormInput
                        type='text'
                        placeholder={t('Title')}
                        name="title"
                        errorText={titleError}
                        value={otherPaymentObj.title}
                        onChange={inputChangeHandler}
                        containerClass='mb-4'
                    />
                </div>
                <div className='col-md-6 col-lg-4'>
                    <FormInput
                        type='text'
                        placeholder={t('Detail')}
                        name="detail"
                        errorText={detailError}
                        value={otherPaymentObj.detail}
                        onChange={inputChangeHandler}
                        containerClass='mb-4'
                    />
                </div>
                <div className='col-md-6 col-lg-2'>
                    <Button onClick={addClickHandler} className={formStyle.saveButton} >{t("Add")}</Button>
                </div>
            </>}

            {/* for event only */}
            {
                authAdmin?.category === "Event" && otherPayments.map((otherPayment) => (
                    <React.Fragment>
                        <div className='col-md-6 col-lg-3'>
                            <FormInput
                                type='text'
                                placeholder={t('Title')}
                                name="title"
                                value={otherPayment.title}
                                onChange={(e) => editHandler(e, otherPayment.id)}
                                containerClass='mb-4'
                                disabled
                            />
                        </div>
                        <div className='col-md-6 col-lg-3'>
                            <FormInput
                                type='text'
                                name="detail"
                                placeholder={t('Detail')}
                                value={otherPayment.detail}
                                onChange={(e) => editHandler(e, otherPayment.id)}
                                containerClass='mb-4'
                                disabled
                            />
                        </div>
                        {authAdmin?.category === "Event" && <div className='col-md-6 col-lg-4 mt-3'>
                            {/* <Switch
                                name="transactionNeeded"
                                onChange={(e: any) => editHandler(e, otherPayment.id)}
                                checked={otherPayment.transactionNeeded}
                                label={"Transaction Proof needed"}
                            /> */}
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" name="transactionNeeded"

                                    checked={otherPayment.transactionNeeded} />
                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Transaction Proof needed</label>
                            </div>
                        </div>}
                        <div className='col-md-6 col-lg-2'>
                            <Button onClick={() => deleteHandler(otherPayment.id)} className={formStyle.saveButton}>{t("Delete")}</Button>
                        </div>
                    </React.Fragment>
                ))
            }
            {authAdmin?.category === "Event" && <>
                <div className='col-md-6 col-lg-3'>
                    <FormInput
                        type='text'
                        placeholder={t('Title')}
                        name="title"
                        errorText={titleError}
                        value={otherPaymentObj.title}
                        onChange={inputChangeHandler}
                        containerClass='mb-4'
                    />
                </div>
                <div className='col-md-6 col-lg-3'>
                    <FormInput
                        type='text'
                        placeholder={t('Detail')}
                        name="detail"
                        errorText={detailError}
                        value={otherPaymentObj.detail}
                        onChange={inputChangeHandler}
                        containerClass='mb-4'
                    />
                </div>
                {authAdmin?.category === "Event" && <div className='col-md-6 col-lg-4 mt-3'>
                    {/* <Switch
                        onClick={inputChangeHandler}
                        checked={otherPaymentObj.transactionNeeded}
                        label={"Transaction Proof needed"}
                        name="transactionNeeded"
                    /> */}
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" name="transactionNeeded"
                            onChange={inputChangeHandler}
                            checked={otherPaymentObj.transactionNeeded} />
                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Transaction Proof needed</label>
                    </div>
                </div>}
                <div className='col-md-6 col-lg-2'>
                    <Button onClick={addClickHandler} className={formStyle.saveButton} >{t("Add")}</Button>
                </div>
            </>}
            <div className='mb-4'>
                <Button onClick={saveClickHandler} loading={loading} className={formStyle.saveButton} disabled={disable}>{t("Save")}</Button>
            </div>
        </React.Fragment>
    )
}

export default BankSettings;