import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useT } from '../../i18n'
import { updateEventBanner, updateFoodBanner } from '../../redux/actions'
import { StoreState } from '../../redux/reducers'
import { AuthAdmin } from '../../redux/reducers/authAdmin.reducer'
import { AppDispatch } from '../../redux/store'
import { ToastType, useToast } from '../ToastProvider'
import ModalWithHeader from '../modals/ModalWithHeader'
import FormInput from '../utils/FormInput'


interface FoodFormProps {
    onClose?: () => void
    id?: string | null
}

const UploadForm = (props: FoodFormProps) => {
    const dispatch = useDispatch<AppDispatch>()
    const authAdmin = useSelector<StoreState, AuthAdmin | null>(state => state.authAdmin)
    const toast = useToast()
    const t = useT()

    const [loading, setLoading] = React.useState<boolean>(false)
    const [imageUrl, setImageUrl] = React.useState<any>({})


    const submitHandler = () => {
        const formData: any = new FormData()
        formData.append("file", imageUrl);
        const dispatchAction = authAdmin?.category === "Event" ? updateEventBanner(formData, props.id) : updateFoodBanner(formData, props.id)
        setLoading(true)
        dispatch(dispatchAction).then(() => {
            toast(t('Banner Image updated'))
            props.onClose?.()
        }).catch(text => {
            toast(text, ToastType.ERROR)
        }).finally(() => {
            setLoading(false)
        })

    }
    return <ModalWithHeader
        buttonText={t('Save')}
        headerText={t('Banner Image')}
        loading={loading}
        onClose={props.onClose}
        onSave={submitHandler}
        small
    >
        <div className='row mb-4'>
            <div className='col'>
                <FormInput
                    type='file'
                    label={t('Choose Image')}
                    placeholder={t('Image')}
                    containerClass='mb-4'
                    onChange={(e: any) => setImageUrl(e.target.files[0])}
                />

            </div>
        </div>

    </ModalWithHeader>
}
export default UploadForm