import React from 'react'
import assets from '../../assets'
import { useT } from '../../i18n'
import style from '../../styles/components/authenticate.module.scss'
import Close from '../utils/Close'
import Modal from './Modal'

export enum AuthenticateType {
    SIGN_IN = 'SIGN_IN',
    REGISTER = 'REGISTER',
    FORGOT_PASSWORD = 'FORGOT_PASSWORD'
}

interface AuthenticateProps {
    onClose?: () => void
    authenticateType?: AuthenticateType
    children?: React.ReactNode | React.ReactNode[]
}

const Authenticate = (props: AuthenticateProps) => {
    const t = useT()
    return <Modal animate>
        <div className={props.authenticateType === AuthenticateType.REGISTER ? style.registerContainer : style.signInContainer}>
            <div className='d-flex justify-content-between px-4 pt-4'>
                <div className='hstack gap-3'>
                    <div className={style.logoContainer}>
                        <img src={assets.images.logo} alt='V-Shops logo' />
                    </div>
                    <div className={`d-none d-sm-block ${style.logoCaption}`}>Virtual shopping,<br />Real value!</div>
                </div>
                <Close onClose={props.onClose} />
            </div>
            <div className='my-2 px-4'>
                {props.authenticateType === AuthenticateType.SIGN_IN && <div className={style.headerText}>{t("Sign In")}</div>}
                {props.authenticateType === AuthenticateType.REGISTER && <div className={style.headerText}>{t("Create Account")}</div>}
                {props.authenticateType === AuthenticateType.FORGOT_PASSWORD && <div className={style.headerText}>{t("Reset Password")}</div>}
            </div>
            {props.children}
        </div>
    </Modal>
}

export default Authenticate