import { Action, ToppingActionTypes } from '../actions'

export interface ToppingName {
    language: string
    name: string
    createdAt: string
    updatedAt: string
    id: string
}

export interface ToppingType {
    language: string
    type: string
    createdAt: string
    updatedAt: string
    id: string
}

export interface Topping {
    names: ToppingName[]
    types: ToppingType[]
    shopId: string
    createdAt: string
    updatedAt: string
    id: string
}

const initialState: Topping[] = []

const toppingReducer = (state: Topping[] = initialState, action: Action): Topping[] => {
    switch (action.type) {
        case ToppingActionTypes.ADD_TOPPING: {
            return [
                ...state,
                action.data
            ]
        }

        case ToppingActionTypes.UPDATE_TOPPING: {
            const topings = [...state]
            const index = topings.findIndex(c => c.id === action.data.id)

            if (index > -1) {
                topings[index] = {
                    ...action.data
                }
            }

            return topings
        }

        case ToppingActionTypes.FETCH_SHOP_TOPPINGS: {
            return action.data
        }

        default: {
            return state
        }
    }
}

export default toppingReducer