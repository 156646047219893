import React from 'react'
import { AdminContext, AdminContextType } from '../../context/AdminProvider'
import style from '../../styles/components/category.module.scss'
import Edit from '../svgs/Edit'

export interface CategoryProps {
    name?: string
    offerLabel?: string | null
    id?: string
}

const Category = (props: CategoryProps) => {
    const adminContext = React.useContext<AdminContextType | null>(AdminContext)

    return <div className={style.container}>
        <div className={style.name}>{props.name}</div>
        <div className={style.edit} onClick={() => props.id && adminContext?.onEditCategory?.(props.id)}>
            <Edit />
        </div>
    </div>
}

export default Category