interface GlobeProps {
    stroke?: string
    strokeWidth?: number
}

const Globe = (props: GlobeProps) => {
    return <svg xmlns='http://www.w3.org/2000/svg' width='19' height='19' viewBox='0 0 19 19'>
        <g id='Icon_feather-globe' data-name='Icon feather-globe' transform='translate(0.5 0.5)'>
            <path id='Path_3' data-name='Path 3' d='M21,12a9,9,0,1,1-9-9A9,9,0,0,1,21,12Z' transform='translate(-3 -3)' fill='none' stroke={props.stroke || '#404040'} strokeLinecap='round' strokeLinejoin='round' strokeWidth={props.strokeWidth || '1'} />
            <path id='Path_4' data-name='Path 4' d='M3,18H21' transform='translate(-3 -9)' fill='none' stroke={props.stroke || '#404040'} strokeLinecap='round' strokeLinejoin='round' strokeWidth={props.strokeWidth || '1'} />
            <path id='Path_5' data-name='Path 5' d='M16,3a13.063,13.063,0,0,1,4,9,13.063,13.063,0,0,1-4,9,13.063,13.063,0,0,1-4-9,13.063,13.063,0,0,1,4-9Z' transform='translate(-7 -3)' fill='none' stroke={props.stroke || '#404040'} strokeLinecap='round' strokeLinejoin='round' strokeWidth={props.strokeWidth || '1'} />
        </g>
    </svg>
}

export default Globe