import { useEffect, useState } from "react"
import { Button } from "react-bootstrap"
import * as Feather from 'react-feather'
import { useDispatch, useSelector } from "react-redux"
import { api, getApiRequestConfig } from "../../api"
import { useT } from "../../i18n"
import { addRating, fetchRatings, fetchUsers } from "../../redux/actions"
import { addFeedback, fetchFeedbacks } from "../../redux/actions/feedback.actions"
import { StoreState } from "../../redux/reducers"
import { AuthAdmin } from "../../redux/reducers/authAdmin.reducer"
import { AuthUser } from "../../redux/reducers/authUser.reducer"
import { Feedback } from "../../redux/reducers/feedback.reducer"
import { Rating } from "../../redux/reducers/rating.reducer"
import { User } from "../../redux/reducers/user.reducer"
import { AppDispatch } from "../../redux/store"
import { ToastType, useToast } from "../ToastProvider"
import Send from "../svgs/Send"
import FormInput from "../utils/FormInput"
import Switch from "../utils/Switch"


interface ReviewProps {
    shopId: string | undefined
    showbutton?: boolean
    showToggle?: boolean
    from?: string
    showRating?: boolean
}
const ReadReview = (props: ReviewProps) => {
    const toast = useToast()
    const t = useT()

    const dispatch = useDispatch<AppDispatch>()
    const authAdmin = useSelector<StoreState, AuthAdmin | null>(state => state.authAdmin)
    const authUser = useSelector<StoreState, AuthUser | null>(state => state.authUser)
    const adminConfig = getApiRequestConfig(authAdmin?.['auth-token'])
    const shopFeedbacks = useSelector<StoreState, Feedback[]>(state => state.feedback)
    const shopRatings = useSelector<StoreState, Rating[]>(state => state.rating)
    const userList = useSelector<StoreState, User[]>(state => state.users)
    const [showReview, setShowReview] = useState<boolean>(false)

    const [ratingTrue, setRatingTrue] = useState<boolean>(false)

    const [feedback, setFeedback] = useState<string>('')
    const [feedbackError, setFeedbackError] = useState<string>('')

    const [rating1, setRating1] = useState<string>('1')
    const [rating2, setRating2] = useState<string>('2')
    const [rating3, setRating3] = useState<string>('3')
    const [rating4, setRating4] = useState<string>('4')
    const [rating5, setRating5] = useState<string>('5')

    const [rating, setRating] = useState<number[]>([])

    const userId = authUser?.id
    const isUserFeedbackExists = shopFeedbacks.filter(s => s.userId === userId)
    const isUserRatingExists = shopRatings.filter(s => s.userId === userId)

    const FeedbackChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setFeedback(e.target.value)
        setFeedbackError('')
    }
    const handleRating = (value: number) => {
        let arr = []
        for (let i = 1; i <= value; i++) {
            arr.push(i)
        }
        setRating(arr)
        setRatingTrue(!ratingTrue)

        if (isUserRatingExists.length === 0) {
            let data = { rating: value, shopId: props.shopId }
            if (value !== undefined) {
                dispatch(addRating(data)).then(text => {
                    toast(text, ToastType.SUCCESS)
                    if (props.shopId) dispatch(fetchRatings(props.shopId))

                    setShowReview(false)
                    setRating([])
                }).catch(text => {
                    toast(text, ToastType.ERROR)
                })
            }
        } else if (isUserRatingExists.length > 0) {
            toast(t('Sorry!,Rating is allowed once per user'), ToastType.ERROR)
        }
    }


    const toggleHandler = (status: string, feedbackId: string) => {
        if (props.shopId) {
            if (status === 'active') {
                api.put('shop/feedback/disable', { shopId: props.shopId, feedbackId: feedbackId }, adminConfig).then(res => {
                    if (props.shopId) dispatch(fetchFeedbacks(props.shopId))
                }).catch((error) => {
                })
            } else if (status === 'inactive') {
                api.put('shop/feedback/enable', { shopId: props.shopId, feedbackId: feedbackId }, adminConfig).then(res => {
                    if (props.shopId) dispatch(fetchFeedbacks(props.shopId))
                }).catch((error) => {
                })
            }
        }

    }

    const submitHandler = () => {
        let data = { feedback: feedback, shopId: props.shopId }

        let error = false

        if (feedback.length > 1000) {
            setFeedbackError(t('Feedback must be within 1000 characters'))
            error = true
        } else if (feedback === '') {
            setFeedbackError(t('Feedback required'))
            error = true
        }
        if (isUserFeedbackExists.length === 0) {

            if (error === false && feedback !== undefined) {
                dispatch(addFeedback(data)).then(text => {
                    toast(text, ToastType.SUCCESS)
                    if (props.shopId) dispatch(fetchFeedbacks(props.shopId))
                    setShowReview(false)
                    setFeedback('')
                }).catch(text => {
                    toast(text, ToastType.ERROR)
                })
            }
        } else if (isUserFeedbackExists.length > 0) {
            toast(t('Sorry!, One review is allowed per user'), ToastType.ERROR)
        }

    }
    useEffect(() => {
        if (props.shopId) {
            dispatch(fetchFeedbacks(props.shopId))
            dispatch(fetchRatings(props.shopId))
        }
        dispatch(fetchUsers())

    }, [])

    return <>
        <div className="row">
            <div className="card border-0 mb-2">
                <div className="card-body border-0 p-0">
                    {!props.showRating && <div className="hstack gap-3">
                        <h5 className="mt-2 text-info fw-bold">{t("Rating")}</h5>
                        <div>
                            <Feather.Star className="text-warning" id='1' fill={rating.includes(parseInt(rating1)) ? "#FCC628" : '#fff'} values={rating1} onClick={() => handleRating(1)} />
                            <Feather.Star className="text-warning" id='2' fill={rating.includes(parseInt(rating2)) ? "#FCC628" : '#fff'} values={rating2} onClick={() => handleRating(2)} />
                            <Feather.Star className="text-warning" id='3' fill={rating.includes(parseInt(rating3)) ? "#FCC628" : '#fff'} values={rating3} onClick={() => handleRating(3)} />
                            <Feather.Star className="text-warning" id='4' fill={rating.includes(parseInt(rating4)) ? "#FCC628" : '#fff'} values={rating4} onClick={() => handleRating(4)} />
                            <Feather.Star className="text-warning" id='5' fill={rating.includes(parseInt(rating5)) ? "#FCC628" : '#fff'} values={rating5} onClick={() => handleRating(5)} />
                        </div>
                    </div>}
                    {props.showbutton && <div className="mb-4">
                        {!showReview && <Button className='d-flex btn-info text-white float-end p-1' onClick={() => setShowReview(!showReview)}><Feather.Edit className="me-2" /> {t("Write a review")}</Button>}
                    </div>}
                    {showReview && <div className="hstack gap-1 mb-4">
                        <div className='col-md-6 col-lg-9'>
                            <FormInput
                                type='text'
                                label=''
                                placeholder={t('Write a review')}
                                value={feedback}
                                onChange={FeedbackChangeHandler}
                                errorText={feedbackError}
                            />
                        </div>
                        <Button className="btn btn-info text-white mb-1" onClick={submitHandler}><Send /></Button>
                    </div>}
                    {shopFeedbacks.map(r => {
                        let obj = userList.find(u => u.id === r.userId)
                        let userName = obj?.firstName
                        let feedback = r.feedback
                        let status = r.status

                        return <>
                            {props.from === 'User' && <div>
                                {status === 'active' && <div className="m-2">
                                    <h6 className="fw-bold">{userName}</h6>
                                    <p className="ms-2 text-captalize">{feedback}</p>
                                    {props.showToggle &&
                                        <div className="vstack gap-1">
                                            <div className="d-flex justify-content-end">
                                                <Switch
                                                    onClick={() => toggleHandler(r.status, r.id!)}
                                                    checked={status === "active"}
                                                />
                                            </div>
                                            <div><hr /></div>
                                        </div>}
                                </div>}
                            </div>}

                            {props.from === 'Admin' && <div>
                                <div className="m-2">
                                    <h6 className={status === "inactive" ? 'text-secondary fw-bold' : 'fw-bold'}>{userName}</h6>
                                    <p className={status === "inactive" ? 'text-secondary ms-2 text-captalize' : "ms-2 text-captalize"}>{feedback}</p>
                                    {props.showToggle &&
                                        <div className="vstack gap-1">
                                            <div className="d-flex justify-content-end">
                                                <Switch
                                                    onClick={() => toggleHandler(r.status, r.id!)}
                                                    checked={status === "active"}
                                                />
                                            </div>
                                            <div><hr /></div>
                                        </div>}
                                </div>
                            </div>}
                        </>
                    })
                    }
                </div>
            </div>
        </div>
    </>
}
export default ReadReview