import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CURRENCY } from '../../config'
import { useT } from '../../i18n'
import { fetchShops, updateShopContactDetails } from '../../redux/actions'
import { StoreState } from '../../redux/reducers'
import { AuthAdmin } from '../../redux/reducers/authAdmin.reducer'
import { Shop } from '../../redux/reducers/shop.reducer'
import { AppDispatch } from '../../redux/store'
import formStyle from '../../styles/components/form.module.scss'
import { testNumber, toString } from '../../utils'
import { ToastType, useToast } from '../ToastProvider'
import Button from '../utils/Button'
import FormInput from '../utils/FormInput'
import Select from '../utils/Select'
// import { Editor } from 'react-draft-wysiwyg'
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// import {
//     EditorState,
//     convertToRaw,
//     ContentState,
// } from 'draft-js';
// import draftToHtml from "draftjs-to-html";
// import htmlToDraft from "html-to-draftjs";

const AdminContactDetailsForm = () => {
    const dispatch = useDispatch<AppDispatch>()
    const toast = useToast()
    const t = useT()

    const authAdmin = useSelector<StoreState, AuthAdmin | null>(state => state.authAdmin)
    const shop = useSelector<StoreState, Shop>(state => state.shops.find(f => f.id === authAdmin?.shop.id)!)

    const [businessName, setBusinessName] = React.useState<string>('')
    const [businessNameError, setBusinessNameError] = React.useState<string>('')
    const [shopName, setShopName] = React.useState<string>('')
    const [shopNameError, setShopNameError] = React.useState<string>('')
    const [addressLine1, setAddressLine1] = React.useState<string>('')
    const [addressLine1Error, setAddressLine1Error] = React.useState<string>('')
    const [addressLine2, setAddressLine2] = React.useState<string>('')
    const [addressLine2Error, setAddressLine2Error] = React.useState<string>('')
    const [pincode, setPincode] = React.useState<string>('')
    const [pincodeError, setPincodeError] = React.useState<string>('')
    const [landmark, setLandmark] = React.useState<string>('')
    const [landmarkError, setLandmarkError] = React.useState<string>('')
    const [countryCode, setCountrycode] = React.useState<string>('+358')
    const [phoneNumber, setPhoneNumber] = React.useState<string>('')
    const [currency, setCurrency] = React.useState<string>('')
    const [currencyError, setCurrencyError] = React.useState<string>('')
    const [phoneNumberError, setPhoneNumberError] = React.useState<string>('')
    const [state, setState] = React.useState<string>('')
    const [stateError, setStateError] = React.useState<string>('')
    const [city, setCity] = React.useState<string>('')
    const [cityError, setCityError] = React.useState<string>('')
    // const [description, setDescription] = React.useState<EditorState>(EditorState.createEmpty())
    const [description, setDescription] = React.useState<string>('')
    const [descriptionError, setDescriptionError] = React.useState<string>('')
    const [plan, setPlan] = React.useState<string>('')
    const [planError, setPlanError] = React.useState<string>('')

    const [showShopInformation, setShowShopInformation] = React.useState<boolean>(false)
    const [loading, setLoading] = React.useState<boolean>(false)

    const businessNameChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setBusinessName(e.target.value)
        setBusinessNameError('')
    }

    const shopNameChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setShopName(e.target.value)
        setShopNameError('')
    }

    const addressLine1ChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setAddressLine1(e.target.value)
        setAddressLine1Error('')
    }

    const addressLine2ChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setAddressLine2(e.target.value)
        setAddressLine2Error('')
    }

    const pincodeChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        if ((!testNumber(e.target.value) && e.target.value))
            return
        else if (e.target.value.length >= 7)
            return

        setPincode(e.target.value)
        setPincodeError('')
    }

    const cityChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const value = e.target.value;
        const regex = /^[A-Za-z]*$/;

        if (regex.test(value)) {
            setCity(value);
            setCityError('');
        } else {
            setCityError('Please enter only alphabets');
        }
    }

    const landmarkChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setLandmark(e.target.value)
        setLandmarkError('')
    }

    const planChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setPlan(e.target.value)
        setPlanError('')
    }

    const countryCodeChangeHandler = (value: string) => {
        setCountrycode(value)
    }

    const phoneNumberChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        if ((!testNumber(e.target.value) && e.target.value))
            return
        else if (e.target.value.length >= 12)
            return

        setPhoneNumber(e.target.value)
        setPhoneNumberError('')
    }

    const currencyChangeHandler: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
        setCurrency(e.target.value)
        setCurrencyError('')
    }
    // const descriptionChangeHandler = (editorState: EditorState) => {
    //     setDescription(editorState);
    //     setDescriptionError('')
    // }

    const descriptionChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setDescription(e.target.value)
        setDescriptionError('')
    }

    const stateChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const value = e.target.value;
        const regex = /^[A-Za-z]*$/;

        if (regex.test(value)) {
            setState(value);
            setStateError('');
        } else {
            setStateError('Please enter only alphabets');
        }
    }

    const saveClickHandler: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault()

        let error = false

        // const descriptionState = draftToHtml(convertToRaw(description.getCurrentContent()));
        // let desc = descriptionState;

        if (toString(shopName) === '') {
            setShopNameError(t('Shop name required'))
            error = true
        }

        if (toString(businessName) === '') {
            setBusinessNameError(t('Business Name required'))
            error = true
        }

        if (toString(phoneNumber) === '') {
            setPhoneNumberError(t('Phone Number required'))
            error = true
        } else if ((phoneNumber).length > 15) {
            setPhoneNumberError(t('Phone Number should be within 15 digits'))
            error = true
        }

        if (toString(pincode) === '') {
            setPincodeError(t('Pincode required'))
            error = true
        } else if ((pincode).length > 10) {
            setPincodeError(t('Pincode should be within 10 digits'))
            error = true
        }

        if (toString(city) === '') {
            setCityError(t('City required'))
            error = true
        }

        if (toString(addressLine1) === '') {
            setAddressLine1Error(t('Address line 1 required'))
            error = true
        }

        if (toString(currency) === '') {
            setCurrencyError(t('Currency required'))
            error = true
        }

        if (toString(addressLine2) === '') {
            setAddressLine2Error(t('Address line 2 required'))
            error = true
        }

        if (toString(state) === '') {
            setStateError(t('State required'))
            error = true
        }

        if (toString(description) === '') {
            setDescriptionError(t('Description required'))
            error = true
        }

        // if (toString(plan) === '') {
        //     setPlanError(t('Plan required'))
        //     error = true
        // }

        if (!error) {

            setLoading(true)
            dispatch(updateShopContactDetails({
                city: toString(city),
                businessName: toString(businessName) || null,
                shopName: toString(shopName),
                addressLine1: toString(addressLine1),
                addressLine2: toString(addressLine2),
                landmark: toString(landmark) || null,
                pincode: toString(pincode),
                state: toString(state),
                countryCode: toString(countryCode),
                phoneNumber: toString(phoneNumber) || null,
                currency: toString(currency),
                description: toString(description),
                // plan: toString(plan) as AdminPlan
            })).then(() => {
                toast(t('Details updated'))
            }).catch(text => {
                toast(text, ToastType.ERROR)
            }).finally(() => {
                setLoading(false)
            })
        }
    }

    React.useEffect(() => {
        setBusinessName(shop?.businessName || '')
        setShopName(shop?.name || '')
        setAddressLine1(shop?.address.addressLine1 || '')
        setAddressLine2(shop?.address.addressLine2 || '')
        setPincode(shop?.address.pincode.toString() || '')
        setLandmark(shop?.address.landmark || '')
        setState(shop?.address.state || '')
        setCity(shop?.address.city || '')
        setCountrycode(shop?.countryCode || '+358')
        setPhoneNumber(shop?.phoneNumber || '')
        setCurrency(shop?.currency || '')
        // setPlan(shop?.plan || '')
        setDescription(shop?.description || '')
        // let descriptionEngBlock = htmlToDraft(authAdmin?.shop.description||'');
        // const descriptionState = ContentState.createFromBlockArray(
        //     descriptionEngBlock.contentBlocks
        // );
        // const editorState = EditorState.createWithContent(descriptionState);
        // setDescription(editorState);
    }, [shop])

    React.useEffect(() => {
        dispatch(fetchShops())
    }, [])

    return <React.Fragment>
        {/* {showShopInformation && <PlanInformation planType={plan} onClose={() => setShowShopInformation(false)} showbutton={true} />} */}
        <div className='col-md-6 col-lg-4'>
            <FormInput
                type='text'
                label={t('Shop Name')}
                placeholder={t('Shop Name')}
                errorText={shopNameError}
                value={shopName}
                onChange={shopNameChangeHandler}
                containerClass='mb-4'
            />
        </div>
        <div className='col-md-6 col-lg-4'>
            <FormInput
                type='text'
                label={t('Business name')}
                placeholder={t('Business name')}
                errorText={businessNameError}
                value={businessName}
                onChange={businessNameChangeHandler}
                containerClass='mb-4'
            />
        </div>
        <div className='col-md-6 col-lg-4'>
            <FormInput
                type='text'
                label={t('Address Line 1')}
                placeholder={t('Address Line 1')}
                errorText={addressLine1Error}
                value={addressLine1}
                onChange={addressLine1ChangeHandler}
                containerClass='mb-4'
            />
        </div>
        <div className='col-md-6 col-lg-4'>
            <FormInput
                type='text'
                label={t('Address Line 2')}
                placeholder={t('Address Line 2')}
                errorText={addressLine2Error}
                value={addressLine2}
                onChange={addressLine2ChangeHandler}
                containerClass='mb-4'
            />
        </div>
        <div className='col-md-6 col-lg-4'>
            <FormInput
                type='text'
                label={t('City')}
                placeholder={t('City')}
                errorText={cityError}
                value={city}
                onChange={cityChangeHandler}
                containerClass='mb-4'
            />
        </div>
        <div className='col-md-6 col-lg-4'>
            <FormInput
                type='text'
                label={t('State')}
                placeholder={t('State')}
                errorText={stateError}
                value={state}
                onChange={stateChangeHandler}
                containerClass='mb-4'
            />
        </div>
        <div className='col-md-6 col-lg-4'>
            <FormInput
                type='text'
                label={t('Pincode')}
                placeholder={t('Pincode')}
                errorText={pincodeError}
                value={pincode}
                onChange={pincodeChangeHandler}
                containerClass='mb-4'
            />
        </div>
        <div className='col-md-6 col-lg-4'>
            <FormInput
                type='text'
                label={t('Landmark')}
                placeholder={t('Landmark')}
                errorText={landmarkError}
                value={landmark}
                onChange={landmarkChangeHandler}
                containerClass='mb-4'
            />
        </div>
        <div className='col-md-6 col-lg-4'>
            <FormInput
                type='text'
                label={t('Phone Number')}
                placeholder={t('Phone Number')}
                errorText={phoneNumberError}
                value={phoneNumber}
                leftRenderer={countryCode}
                countryCode={countryCodeChangeHandler}
                onChange={phoneNumberChangeHandler}
                containerClass='mb-4'
            />
        </div>
        <div className='col-md-6 col-lg-4'>
            <Select
                options={CURRENCY}
                label={t('Currency')}
                placeholder={t('Select currency')}
                errorText={currencyError}
                value={currency}
                onChange={currencyChangeHandler}
                containerClass='mb-4'
            />
        </div>
        {/* <div className='col-md-6 col-lg-4 hstack gap-2'>
            <div>
                <FormInput
                    type='text'
                    label={('Plan')}
                    placeholder={('Plan')}
                    errorText={planError}
                    value={plan}
                    onChange={planChangeHandler}
                    containerClass='mb-4'
                    disabled
                />
            </div>
            <div>
                <Button className={`${style.button}`} onClick={() => setShowShopInformation(true)} size='sm'>Plan Upgrade</Button>
            </div>
        </div> */}
        {/* <div className='col-md-6 col-lg-4'>
            <div className='hstack gap-2'>
                <div className={style1.label}>Plan</div>
                <div className={style2.infoIcon} onClick={() => setShowShopInformation(true)}><Info /></div>
            </div>
            <Select
                options={PLAN_OPTIONS}
                // label={('Plan')}
                placeholder={('Select plan')}
                // required={true}
                errorText={planError}
                value={plan}
                onChange={planChangeHandler}
                containerClass='mb-4'
            />
        </div> */}
        {/* <div><label>Description</label>
            <div className='border rounded-5 p-3 m-1'> <Editor
                editorState={description}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={descriptionChangeHandler}
            />
                <span>{descriptionError}</span>
            </div>
            </div> */}
        <div>
            <FormInput
                type='text'
                label={t('Description')}
                placeholder={t('Description')}
                errorText={descriptionError}
                value={description}
                onChange={descriptionChangeHandler}
                containerClass='mb-4'
            />
        </div>

        <div className='mb-4'>
            <Button onClick={saveClickHandler} loading={loading} className={formStyle.saveButton}>{t("Save")}</Button>
        </div>
    </React.Fragment>
}

export default AdminContactDetailsForm