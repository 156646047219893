/* eslint-disable no-throw-literal */
import { Dispatch } from 'redux'
import { api, apiErrorHandler, Response } from '../../api'
import { AuthSuperAdmin } from '../reducers/authSuperAdmin.reducer'

export enum AuthSuperAdminActionTypes {
    LOGIN_SUPER_ADMIN = 'LOGIN_SUPER_ADMIN',
    LOGOUT_SUPER_ADMIN = 'LOGOUT_SUPER_ADMIN'
}

export interface LoginSuperAdminAction {
    type: AuthSuperAdminActionTypes.LOGIN_SUPER_ADMIN
    data: AuthSuperAdmin
}

export interface LogoutSuperAdminAction {
    type: AuthSuperAdminActionTypes.LOGOUT_SUPER_ADMIN
}

export type AuthSuperAdminActions = LoginSuperAdminAction | LogoutSuperAdminAction

export interface LoginAuthSuperAdmin {
    username: string
    password: string
}

export const loginSuperAdmin = (input: LoginAuthSuperAdmin) => async (dispatch: Dispatch) => {
    return api.post<Response<AuthSuperAdmin>>('super/login', input).then(response => {
        if (response.status === 200) {
            dispatch<LoginSuperAdminAction>({
                type: AuthSuperAdminActionTypes.LOGIN_SUPER_ADMIN,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || 'Logged in')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to login'))
    })
}

export const logoutSuperAdmin = (): LogoutSuperAdminAction => {
    return {
        type: AuthSuperAdminActionTypes.LOGOUT_SUPER_ADMIN
    }
}