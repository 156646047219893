import React from 'react'
import formStyle from '../../styles/components/form.module.scss'
import style from '../../styles/components/selectable-tile-section.module.scss'
import InstantInputForm, { InstantInputFormProps } from '../forms/InstantInputForm'
import ErrorText from '../utils/ErrorText'
import SelectableTile, { SelectableTileProps } from '../utils/SelectableTile'

interface SelectableTileSectionProps<L> {
    list?: L[]
    getSelectableTileProps?: (item: L, index: number) => SelectableTileProps & { key?: React.Key | null }
    onAdd?: () => void
    addButtonText?: string
    errorText?: string
    title?: string
    instantInput?: InstantInputFormProps
}

const SelectableTileSection = <T extends any>(props: SelectableTileSectionProps<T>) => {
    return <div className='position-relative'>
        <div className={formStyle.sectionHeader}>{props.title}</div>
        <div className='hstack gap-2 flex-wrap mb-2'>
            {props.list?.map((item, index) => {
                const selectableTileProps = props.getSelectableTileProps?.(item, index)

                return <SelectableTile
                    {...selectableTileProps}
                />
            })}
            {props.instantInput
                ? <InstantInputForm
                    onAdd={props.instantInput.onAdd}
                    label={props.instantInput.label}
                />
                : <div className={style.button} onClick={props?.onAdd}>{props.addButtonText}</div>}
        </div>
        <ErrorText errorText={props.errorText} />
    </div>
}

export default SelectableTileSection