import CookieConsent from "react-cookie-consent";
import { useT } from "../../i18n";
import style from '../../styles/components/cookie.module.scss';
import { Xcircle } from "../svgs/Xcircle";

const Cookie = () => {
    const t = useT()
    return (
        <CookieConsent
            location="bottom"
            buttonText={t("Yes")}
            cookieName="v-shops"
            buttonStyle={{ color: "#fff", fontSize: "16px", borderRadius: "15px", backgroundColor: "#2ac8fe" }}
            declineButtonStyle={{ borderRadius: "15px", backgroundColor: "transparent" }}
            enableDeclineButton
            containerClasses={style.card}
            declineButtonText={<Xcircle />}
            flipButtons
        >
            <div className={style.lable}>{t("We use cookies to ensure that we give you the best experience on our website. If you continue to use this site we will assume that you are happy with it.")}</div>
        </CookieConsent>
    )
}

export default Cookie;