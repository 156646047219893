import Spinner from '../utils/Spinner'
import Modal from './Modal'

const OverlayLoader = () => {
    return <Modal highPriority>
        <div className='rounded p-5 bg-white'>
            <Spinner
                color='#383838'
                size={30}
            />
        </div>
    </Modal>
}

export default OverlayLoader