import { Action } from '../actions'
import { CountReportsTypes } from '../actions/countReport.actions'


export interface CountReport {
    shopCount: string
    userCount: string
    foodOrderCount: string
    productOrderCount: string
    reservationCount: string,
    bookingCount: string
}

const initialState: CountReport = {
    shopCount: '',
    userCount: '',
    foodOrderCount: '',
    productOrderCount: '',
    reservationCount: '',
    bookingCount: ''
}

const countReportReducer = (state: CountReport = initialState, action: Action): CountReport => {
    switch (action.type) {

        case CountReportsTypes.FETCH_COUNT_REPORT: {
            return action.data
        }

        default: {
            return state
        }
    }
}

export default countReportReducer