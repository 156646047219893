import style from '../../styles/components/small-image.module.scss'
import Close from './Close'

interface SmallImageProps {
    image?: string
    onClose?: () => void
    onClick?: () => void
}

const SmallImage = (props: SmallImageProps) => {
    return <div className={style.container} onClick={props.onClick}>
        {props.onClose && <div className={style.close}>
            <Close small onClose={props.onClose} />
        </div>}
        <img src={props.image} alt='Food' />
    </div>
}

export default SmallImage