import React from 'react'
import { Mail, Phone, User } from 'react-feather'
import { useDispatch } from 'react-redux'
import assets from '../assets'
import { ToastType, useToast } from '../components/ToastProvider'
import CommonLayout from '../components/sections/CommonLayout'
import Button from '../components/utils/Button'
import FormInput from '../components/utils/FormInput'
import FormTextArea from '../components/utils/FormTextArea'
import { useT } from '../i18n'
import { addContactUs } from '../redux/actions'
import { AppDispatch } from '../redux/store'
import style from '../styles/pages/contact-us.module.scss'
import { testNumber, toString } from '../utils'
const ContactUs = () => {
    const dispatch = useDispatch<AppDispatch>()
    const toast = useToast()
    const t = useT()
    const [name, setName] = React.useState<string>('')
    const [nameError, setNameError] = React.useState<string>('')
    const [phoneNumber, setPhoneNumber] = React.useState<string>('')
    const [phoneNumberError, setPhoneNumberError] = React.useState<string>('')
    const [email, setEmail] = React.useState<string>('')
    const [emailError, setEmailError] = React.useState<string>('')
    const [message, setMessage] = React.useState<string>('')
    const [messageError, setMessageError] = React.useState<string>('')

    const [loading, setLoading] = React.useState<boolean>(false)

    const resetStates = () => {
        setName('')
        setNameError('')
        setPhoneNumber('')
        setPhoneNumberError('')
        setEmail('')
        setEmailError('')
        setMessage('')
        setMessageError('')
    }

    const NameChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setName(e.target.value)
        setNameError('')
    }

    const PhonenumberChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setPhoneNumber(e.target.value)
        setPhoneNumberError('')
    }

    const EmailChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setEmail(e.target.value)
        setEmailError('')
    }

    const MessageChangeHandler: React.ChangeEventHandler<HTMLTextAreaElement> = (e) => {
        setMessage(e.target.value)
        setMessageError('')
    }

    const submitHandler: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault()
        let error = false
        if (name === '') {
            setNameError(t('Name required'))
            error = true
        }

        if (phoneNumber === '') {
            setPhoneNumberError(t('Phone Number required'))
            error = true
        } else if ((phoneNumber).length > 15) {
            setPhoneNumberError(t('Phone Number should be within 15 digits'))
            error = true
        } else if (!testNumber(phoneNumber)) {
            setPhoneNumberError(t('Phone Number must be a number'))
            error = true
        }

        if (email === '') {
            setEmailError(t('Email required'))
            error = true
        }

        if (message === '') {
            setMessageError(t('Message required'))
            error = true
        }

        if (phoneNumber === '') {
            setPhoneNumberError(t('Phone Number required'))
            error = true
        }

        if (!error)
            dispatch(addContactUs({
                name: toString(name),
                phoneNumber: toString(phoneNumber),
                email: toString(email),
                message: toString(message)
            })).then(() => {
                resetStates()
                toast(t('Thank you for Contact Us'))
            }).catch(text => {
                toast(text, ToastType.ERROR)
            }).finally(() => {
                setLoading(false)
            })
    }
    return <CommonLayout>
        <div className='mx-5'>
            <h4 className={style.title}>{t("Contact us")}</h4><br />
            <form className='container d-flex'>
                <div className='card-body'>
                    <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <div className={style.image}>
                                <img src={assets.images.contactUs} />
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <FormInput
                                type='text'
                                label={t("Name")}
                                placeholder={t("Name")}
                                errorText={nameError}
                                required={true}
                                value={name}
                                onChange={NameChangeHandler}
                                containerClass='mb-4'
                                rightRenderer={<User />}
                            />
                            <FormInput
                                type='text'
                                label={t("Phone Number")}
                                placeholder={t("Phone Number")}
                                errorText={phoneNumberError}
                                required={true}
                                value={phoneNumber}
                                onChange={PhonenumberChangeHandler}
                                containerClass='mb-4'
                                rightRenderer={<Phone />}
                            />
                            <FormInput
                                type='email'
                                label={t("Email")}
                                placeholder={t("Email")}
                                errorText={emailError}
                                required={true}
                                value={email}
                                onChange={EmailChangeHandler}
                                containerClass='mb-4'
                                rightRenderer={<Mail />}
                            />
                            <FormTextArea
                                label={t("Message")}
                                placeholder={t("Type...")}
                                errorText={messageError}
                                required={true}
                                value={message}
                                onChange={MessageChangeHandler}
                                containerClass='mb-4'
                            />

                            <Button type='button' className={style.submit} onClick={submitHandler} loading={loading}>{t("Submit")}</Button>

                        </div>
                    </div>

                </div>
            </form>
        </div >

    </CommonLayout >
}

export default ContactUs