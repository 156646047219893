/* eslint-disable no-throw-literal */
import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Response, api, apiErrorHandler, getApiRequestConfig } from '../api'
import { ToastType, useToast } from '../components/ToastProvider'
import Close from '../components/utils/Close'
import { ORDER_TYPES } from '../config'
import { useT } from '../i18n'
import { StoreState } from '../redux/reducers'
import { AppointmentCart } from '../redux/reducers/appointmentCart.reducer'
import { AuthUser } from '../redux/reducers/authUser.reducer'
import { BookingStatus } from '../redux/reducers/booking.reducer'
import { Cart } from '../redux/reducers/cart.reducer'
import { EventCart } from '../redux/reducers/eventCart.reducer'
import { OrderType, PaymentMode } from '../redux/reducers/order.reducer'
import { Reservation, ReservationStatus } from '../redux/reducers/reservation.reducer'
import { Pickup, Shop } from '../redux/reducers/shop.reducer'
import style from '../styles/pages/checkout.module.scss'
import { getAppointmentCheckoutPrice, getEventCheckoutPrice, testNumber, toNumber, toString } from '../utils'

export interface CheckoutContextType {
    paymentModeChangeHandler?: (type: any) => void
    orderTypeChangeHandler?: (type: OrderType) => void
    bookingStatusChangeHandler?: (status: BookingStatus) => void
    appointmentStatusChangeHandler?: (status: ReservationStatus) => void
    inputHanlder?: (e: { target: { name: string; value: string } }, index?: number) => void
    typeHanlder?: (e: { target: { name: string; value: string } }, price: PersonTypes) => void
    appointmentTypeHanlder?: (e: { target: { name: string; value: string } }, price: ReservationDetails) => void
    foodChoiceHanlder?: (e: { target: { name: string; value: string } }, index?: number) => void
    timePeriodHandler?: (value: string, type: string) => void
    volunteerCheckHandler?: (value: string) => void
    removeOtherFood?: (index: number) => void
    cultureVoucherChangeHandler?: (value: string) => void
    cultureVocuherSelectHandler?: (value: string) => void
    eventRuleChangeHandler?: (value: string) => void
    priceSizeClickHandler?: (price: any) => void
    addBookDetailHandler?: React.MouseEventHandler<HTMLDivElement>
    removeHandler?: (index: number) => void
    fromTimeChangeHandler?: (time: string) => void
    toTimeChangeHandler?: (time: string) => void
    slotTimeChangeHandler?: (e: { target: { name: string; value: string } }, index?: number) => void
    appointmentDateChangeHandler?: React.ChangeEventHandler<HTMLInputElement>
    totalHourChangeHandler?: React.ChangeEventHandler<HTMLInputElement>
    firstNameChangeHandler?: React.ChangeEventHandler<HTMLInputElement>
    lastNameChangeHandler?: React.ChangeEventHandler<HTMLInputElement>
    emailChangeHandler?: React.ChangeEventHandler<HTMLInputElement>
    phoneNumberChangeHandler?: React.ChangeEventHandler<HTMLInputElement>
    addressLine1ChangeHandler?: React.ChangeEventHandler<HTMLInputElement>
    addressLine2ChangeHandler?: React.ChangeEventHandler<HTMLInputElement>
    cityChangeHandler?: React.ChangeEventHandler<HTMLInputElement>
    pincodeChangeHandler?: React.ChangeEventHandler<HTMLInputElement>
    stateChangeHandler?: React.ChangeEventHandler<HTMLInputElement>
    landmarkChangeHandler?: React.ChangeEventHandler<HTMLInputElement>
    additionalInfoChangeHandler?: React.ChangeEventHandler<HTMLTextAreaElement>
    continueClickHandler?: React.MouseEventHandler<HTMLButtonElement>
    continueBookingClickHandler?: React.MouseEventHandler<HTMLButtonElement>
    continueReservationClickHandler?: React.MouseEventHandler<HTMLButtonElement>
    setVoucherCode?: React.Dispatch<React.SetStateAction<string>>
    setTotal?: React.Dispatch<React.SetStateAction<number>>
    setBookingObj?: React.Dispatch<React.SetStateAction<BookingDetails>>
    setFoodPrefers?: React.Dispatch<React.SetStateAction<{ text: string, value: string }[]>>
    setAddMore?: React.Dispatch<React.SetStateAction<boolean>>
    setPersonTypes?: React.Dispatch<React.SetStateAction<any>>
    setPickup?: React.Dispatch<React.SetStateAction<Pickup>>
    setTransactionImage?: React.Dispatch<React.SetStateAction<File | null>>
    pickup?: Pickup
    voucherCode?: string
    paymentMode?: any
    transactionImage?: File
    paymentModeError?: string
    orderType?: OrderType
    orderTypeError?: string
    firstName?: string
    firstNameError?: string
    lastName?: string
    lastNameError?: string
    email?: string
    emailError?: string
    phoneNumber?: string
    phoneNumberError?: string
    addressLine1?: string
    addressLine1Error?: string
    addressLine2?: string
    addressLine2Error?: string
    city?: string
    cityError?: string
    pincode?: string
    pincodeError?: string
    state?: string
    stateError?: string
    landmark?: string
    landmarkError?: string
    slotTimeError?: string
    additionalInfo?: string
    additionalInfoError?: string
    loading?: boolean
    nameError?: string
    name?: string
    total?: number
    bookingDetails?: BookingDetails[]
    volunteerCheckedLists?: string[] | null
    volunteerError?: string
    bookingObj?: BookingDetails
    bookingStatus?: string
    foodPrefers?: { text: string, value: string }[]
    addMore?: boolean
    cultureVoucher?: string
    cultureVoucherSelected?: string
    personTypes?: PersonTypes[]
    eventRule?: string
    eventRuleError?: string,
    cultureVoucherSelectedError?: string
    foodPreferenceError?: string
    participantError?: string
    appointmentId?: string
    shopId?: string
    reservationStatus?: ReservationStatus
    reservationDetails?: ReservationDetails[]
    appointmentDate?: string
    toTime?: { time: string, timePeriod: string }
    fromTime?: { time: string, timePeriod: string }
    reservationDate?: string
    totalAmount?: number
    billAmount?: number
    fromTimeError?: string
    toTimeError?: string
    appointmentDateError?: string
    totalHour?: number | string
    totalHourError?: string
    priceSize?: string
    slotTime?: string
    priceSizeError?: string
}

export const CheckoutContext = React.createContext<CheckoutContextType | null>(null)

interface CheckoutProviderProps {
    children?: React.ReactNode
}

interface OrderItem {
    id: string
    size: string
    alternateToppings: string[]
    extraToppings: string[]
    spices: string[]
    comment: string | null
    quantity: number
    tax: number
    stock: number | string | null
    category: string
}

interface Buyer {
    firstName: string
    lastName: string
    email: string
    phoneNumber: string
    address: {
        addressLine1: string
        addressLine2: string
        city: string
        pincode: string
        state: string
        landmark: string | null
    }
}

interface PlaceOrder {
    orderType: OrderType
    shopId: string
    buyer: Buyer
    paymentMode: PaymentMode
    additionalInfo: string | null
    orderItems: OrderItem[]
    voucherCode: string | null
    pickup: Pickup
}

interface Booking {
    eventId: string
    shopId: string
    user: Buyer
    bookingStatus: string
    volunteers: string[] | null
    paymentMode: PaymentMode
    email: string
    personTypes: PersonTypes[],
    cultureVoucher: string,
    additionalInfo: string | null
    bookingDetails: BookingDetails[]
    eventRule: string
    eventRuleText: string
    transactionImage: string
}

interface PlaceOrderResponse {
    orderNumber?: string
    token?: string
    url: string
    href: string
}
interface BookingResponse {
    bookingNumber?: string
    token?: string
    url: string
    href: string
    bookingId?: string
}
interface ReservationResponse {
    reservationNumber?: string
    token?: string
    url: string
    href: string
}
interface BookingDetails {
    id?: string
    category?: string
    eventId?: string
    shopId?: string | null
    name: string
    type: { price: number, size: string | undefined }
    foodPreference: string | undefined
    otherFood?: boolean
}
interface ReservationDetails {
    id?: string
    category?: string
    appointmentId?: string
    shopId?: string | null
    name: string
    type: { price: number, size: string }
}
export interface PersonTypes {
    price: number
    offerPrice: number | null
    size: string
    count: number
}

const CheckoutProvider = (props: CheckoutProviderProps) => {
    const t = useT()
    const toast = useToast()
    const navigate = useNavigate()

    const authUser = useSelector<StoreState, AuthUser | null>(state => state.authUser)
    const cart = useSelector<StoreState, Cart>(state => state.cart)
    const eventCart = useSelector<StoreState, EventCart>(state => state.eventCart)
    const appointmentCart = useSelector<StoreState, AppointmentCart>(state => state.appointmentCart)
    const shop = useSelector<StoreState, Shop | null>(state => state.shops.find(s => s.id === state.eventCart.shopId) || null)
    const axiosReqConfig = getApiRequestConfig(authUser?.['auth-token'])

    const [urlSearchParams] = useSearchParams()

    const [total, setTotal] = React.useState<number>(0)
    const [nameError, setNameError] = React.useState<string>('');
    const [foodPreferenceError, setFoodPreferenceError] = React.useState<string>('');
    const [participantError, setParticipantError] = React.useState<string>('')
    const [bookingDetails, setBookingDetails] = React.useState<BookingDetails[]>([])
    const [reservationDetails, setReservationDetails] = React.useState<ReservationDetails[]>([])
    const [bookingObj, setBookingObj] = React.useState<BookingDetails>({ id: "", category: "", shopId: "", name: "", type: { price: 0, size: "" }, foodPreference: '' })
    const [reservationObj, setReservationObj] = React.useState<ReservationDetails>({ id: "", category: "", shopId: "", name: "", type: { price: 0, size: "" } })
    const [pickup, setPickup] = React.useState<Pickup>({})
    const [personTypes, setPersonTypes] = React.useState<any>([])
    const [foodPrefers, setFoodPrefers] = React.useState<{ text: string, value: string }[]>([{ text: 'Non-veg', value: 'Non-veg' }, { text: 'Vegan', value: 'Vegan' }, { text: 'Others', value: 'Others' }])
    const [orderType, setOrderType] = React.useState<OrderType>('pick_up')
    const [bookingStatus, setBookingStatus] = React.useState<BookingStatus>('booked')
    const [reservationStatus, setReservationStatus] = React.useState<ReservationStatus>('booked')

    const [volunteerCheckedLists, setVolunteerCheckedLists] = React.useState<string[] | null>([])
    const [volunteerError, setVolunteerError] = React.useState<string>('')
    const [orderTypeError, setOrderTypeError] = React.useState<string>('')
    const [paymentMode, setPaymentMode] = React.useState<any>('')
    const [paymentModeError, setPaymentModeError] = React.useState<string>('')
    const [slotTime, setSlotTime] = React.useState<string>('');
    const [slotTimeError, setSlotTimeError] = React.useState<string>('');
    const [fromTime, setfromTime] = React.useState<{ time: string, timePeriod: string }>({ time: '', timePeriod: 'AM' })
    const [fromTimeError, setfromTimeError] = React.useState<string>('')
    const [toTime, settoTime] = React.useState<{ time: string, timePeriod: string }>({ time: '', timePeriod: 'AM' })
    const [priceSize, setPriceSize] = React.useState<string>('')
    const [priceSizeError, setPriceSizeError] = React.useState<string>('')
    const [toTimeError, settoTimeError] = React.useState<string>('')
    const [appointmentDate, setAppointmentDate] = React.useState<string>('')
    const [appointmentDateError, setAppointmentDateError] = React.useState<string>('')
    const [totalHour, setTotalHour] = React.useState<number | string>(0)
    const [totalHourError, setTotalHourError] = React.useState<string>('')
    const [firstName, setFirstName] = React.useState<string>('')
    const [firstNameError, setFirstNameError] = React.useState<string>('')
    const [lastName, setLastName] = React.useState<string>('')
    const [lastNameError, setLastNameError] = React.useState<string>('')
    const [email, setEmail] = React.useState<string>('')
    const [emailError, setEmailError] = React.useState<string>('')
    const [phoneNumber, setPhoneNumber] = React.useState<string>('')
    const [phoneNumberError, setPhoneNumberError] = React.useState<string>('')
    const [addressLine1, setAddressLine1] = React.useState<string>('')
    const [addressLine1Error, setAddressLine1Error] = React.useState<string>('')
    const [addressLine2, setAddressLine2] = React.useState<string>('')
    const [addressLine2Error, setAddressLine2Error] = React.useState<string>('')
    const [city, setCity] = React.useState<string>('')
    const [cityError, setCityError] = React.useState<string>('')
    const [pincode, setPincode] = React.useState<string>('')
    const [pincodeError, setPincodeError] = React.useState<string>('')
    const [state, setState] = React.useState<string>('')
    const [stateError, setStateError] = React.useState<string>('')
    const [landmark, setLandmark] = React.useState<string>('')
    const [landmarkError, setLandmarkError] = React.useState<string>('')
    const [additionalInfo, setAdditionalInfo] = React.useState<string>('')
    const [additionalInfoError, setAdditionalInfoError] = React.useState<string>('')
    const [addMore, setAddMore] = React.useState<boolean>(true);
    const [voucherCode, setVoucherCode] = React.useState<string>('')
    const [cultureVoucher, setCultureVoucher] = React.useState<string>('no')
    const [cultureVoucherSelected, setCultureVoucherSelected] = React.useState<string>('')
    const [cultureVoucherSelectedError, setCultureVoucherSelectedError] = React.useState<string>('')
    const [eventRule, setEventRule] = React.useState<string>('')
    const [eventRuleError, setEventRuleError] = React.useState<string>('')
    const [transactionImage, setTransactionImage] = React.useState<File | null>(null)
    const [showAppointmentModal, setShowAppointmentModal] = React.useState<boolean>(false)
    // const [vouchers, setVouchers] = React.useState<Voucher[]>([])

    const [showAlert, setShowAlert] = React.useState<boolean>(false)
    const [loading, setLoading] = React.useState<boolean>(false)

    const inputHanlder = (e: { target: { name: string; value: string } }, index?: number) => {
        const { name, value } = e.target;
        setNameError('');
        if (index !== undefined) {
            const bookingArray: any = [...bookingDetails]
            const updateIndex = bookingArray.findIndex((ba: any, i: number) => i === index);
            bookingArray[updateIndex][name] = value;
            setBookingDetails(bookingArray)

        }
        else
            setBookingObj({ ...bookingObj, [name]: value })

    }
    const appointmentTypeHanlder = (e: { target: { name: string; value: string } }, price: any) => {
        const { name, value } = e.target;
        if (parseInt(value) > 10) {
            setParticipantError(t(`Minimum 1 hour is alotted for booking`))
            return false
        }
        setParticipantError('')
        const personTypeArray: any = [...personTypes]
        const updateIndex = personTypeArray.findIndex((pt: any, i: number) => pt.size === name);
        if (updateIndex > -1 && typeof parseInt(value) === "number") {
            personTypeArray[updateIndex].count = value ? parseInt(value) : '';
            setPersonTypes(personTypeArray)

        }

        if (reservationDetails.length > 0 && !value) {
            const reservationArray: any = [...reservationDetails]
            const removedReservation = reservationArray.filter((b: any, i: number) => b.type.size !== name);
            setReservationDetails(removedReservation)
        }
        else {
            const reservationArrays = [];
            for (let i = 0; i < parseInt(value); i++) {
                reservationArrays.push({ id: appointmentCart.item?.appointmentId, category: appointmentCart.item?.category, shopId: appointmentCart.shopId, name: "", type: { price: getAppointmentCheckoutPrice(price), size: price.size } })
            }
            setReservationDetails([...reservationDetails, ...reservationArrays])
        }
    }


    const typeHanlder = (e: { target: { name: string; value: string } }, price: any) => {
        const { name, value } = e.target;
        if (parseInt(value) > 10) {
            setParticipantError(`${name} 10 participant can apply`)
            return false
        }
        setParticipantError('')
        const personTypeArray: any = [...personTypes]
        const updateIndex = personTypeArray.findIndex((pt: any, i: number) => pt.size === name);
        if (updateIndex > -1 && typeof parseInt(value) === "number") {
            personTypeArray[updateIndex].count = value ? parseInt(value) : '';
            setPersonTypes(personTypeArray)

        }

        if (bookingDetails.length > 0 && !value) {
            const bookingArray: any = [...bookingDetails]
            const removedBooking = bookingArray.filter((b: any, i: number) => b.type.size !== name);
            setBookingDetails(removedBooking)
        }
        else {
            const bookingArrays = [];
            for (let i = 0; i < parseInt(value); i++) {
                bookingArrays.push({ id: eventCart.item?.eventId, category: eventCart.item?.category, shopId: eventCart.shopId, name: "", type: { price: getEventCheckoutPrice(price), size: price.size }, foodPreference: '' })
            }
            setBookingDetails([...bookingDetails, ...bookingArrays])
        }
    }

    const priceSizeClickHandler = (price: any) => {
        setPriceSize(price.size)
        setPriceSizeError('')
    }

    const volunteerCheckHandler = (value: string) => {
        if (value && value !== '') {
            setVolunteerError('')
            setVolunteerCheckedLists((prev: any) => {
                return prev.some((p: string) => p === value)
                    ? prev.length > 0
                        ? prev.filter((p: string) => p !== value)
                        : prev
                    : [
                        ...prev,
                        value
                    ]
            })
        }
    }

    const foodChoiceHanlder = (e: { target: { name: string; value: string } }, index?: number) => {

        setFoodPreferenceError('')
        const { name, value } = e.target;
        if (index !== undefined && value !== "Others") {
            const bookingArray: any = [...bookingDetails]
            const updateIndex = bookingArray.findIndex((ba: any, i: number) => i === index);
            bookingArray[updateIndex][name] = value;
            setBookingDetails(bookingArray)

        }
        else if (index !== undefined && value === "Others") {
            const bookingArray: any = [...bookingDetails]
            const updateIndex = bookingArray.findIndex((ba: any, i: number) => i === index);
            bookingArray[updateIndex][name] = '';
            bookingArray[updateIndex]['otherFood'] = true;
            setBookingDetails(bookingArray)
        }
        else
            setBookingObj({ ...bookingObj, [name]: value })
    }

    const removeOtherFood = (index: number) => {
        if (index !== undefined) {
            const bookingArray: any = [...bookingDetails]
            const updateIndex = bookingArray.findIndex((ba: any, i: number) => i === index);
            bookingArray[updateIndex]['otherFood'] = false;
            bookingArray[updateIndex]['foodPreference'] = '';
            setBookingDetails(bookingArray)

        }
    }

    const addBookDetailHandler = () => {
        if (!bookingObj.name) {
            setNameError(t('Name required'));
            return
        }
        setBookingDetails([...bookingDetails, bookingObj])
        setAddMore(false);
    }

    const addReservationDetailHandler = () => {
        if (!reservationObj.name) {
            setNameError(t('Name required'));
            return
        }
        setReservationDetails([...reservationDetails, reservationObj])
        setAddMore(false);
    }

    const removeHandler = (index: number) => {
        const bookingArray = [...bookingDetails]
        const updatedBooking = bookingArray.filter((ba, i) => i !== index);
        setBookingDetails(updatedBooking);
    }

    const removeAppointmentHandler = (index: number) => {
        const reservationArray = [...reservationDetails]
        const updatedReservation = reservationArray.filter((ba, i) => i !== index);
        setReservationDetails(updatedReservation);
    }

    const orderTypeChangeHandler = (type: OrderType) => {
        setOrderType(type)
        setOrderTypeError('')
    }

    const bookingStatusChangeHandler = (status: BookingStatus) => {
        setBookingStatus(status)
    }

    const appointmentStatusChangeHandler = (status: ReservationStatus) => {
        setReservationStatus(status)
    }

    const paymentModeChangeHandler = (type: any) => {
        setPaymentMode(type)
        setPaymentModeError('')
        setOrderTypeError('')
    }

    const cultureVoucherChangeHandler = (type: any) => {
        setCultureVoucher(type)
        setCultureVoucherSelected('')
        setCultureVoucherSelectedError('')
        setNameError('')
        setFoodPreferenceError('')
        if (type === "yes") {
            const prices = [{
                price: eventCart.item?.prices[0].price,
                offerPrice: eventCart.item?.prices[0].offerPrice,
                size: eventCart.item?.prices[0].size,
                count: 1,
                status: false
            }];
            setPersonTypes(prices)
            setBookingDetails([{ id: eventCart.item?.eventId, category: eventCart.item?.category, shopId: eventCart.shopId, name: "", type: { price: getEventCheckoutPrice(eventCart.item?.prices[0]), size: eventCart.item?.prices[0].size }, foodPreference: '' }])
        }
        else {
            const prices = eventCart.item?.prices.map((p: any) => {
                return {
                    price: p.price,
                    offerPrice: p.offerPrice,
                    size: p.size,
                    count: ''
                }
            });
            setPersonTypes(prices)
            setBookingDetails([])
        }
    }

    const cultureVocuherSelectHandler = (type: string) => {
        setCultureVoucherSelected(type)
        setCultureVoucherSelectedError('')
        setPaymentMode(shop?.otherPayments[0].title)
    }


    const eventRuleChangeHandler = (value: string) => {
        setEventRule(value)
        if (value)
            setEventRuleError('')
    }

    const fromTimeChangeHandler = (value: string) => {
        setfromTime({ ...fromTime, time: value })
        setfromTimeError('')
    }

    const toTimeChangeHandler = (value: string) => {
        settoTime({ ...toTime, time: value })
        settoTimeError('')
    }

    const timePeriodHandler = (value: string, type: string) => {
        if (type === "from") setfromTime({ ...fromTime, timePeriod: value })
        else settoTime({ ...toTime, timePeriod: value })
    }

    const totalHourChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setTotalHour(e.target.value)
        settoTimeError('')
    }



    const slotTimeChangeHandler = (e: { target: { name: string; value: string } }, index?: number) => {
        setSlotTime(e.target.value);
        setSlotTimeError('')
    }

    const appointmentDateChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setAppointmentDate(e.target.value)
        setAppointmentDateError('')
    }

    const firstNameChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setFirstName(e.target.value)
        setFirstNameError('')
    }

    const lastNameChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setLastName(e.target.value)
        setLastNameError('')
    }

    const emailChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setEmail(e.target.value)
        setEmailError('')
    }

    const phoneNumberChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setPhoneNumber(e.target.value)
        setPhoneNumberError('')
    }

    const addressLine1ChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setAddressLine1(e.target.value)
        setAddressLine1Error('')
    }

    const addressLine2ChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setAddressLine2(e.target.value)
        setAddressLine2Error('')
    }

    const cityChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setCity(e.target.value)
        setCityError('')
    }

    const pincodeChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        if ((!testNumber(e.target.value) && e.target.value))
            return
        else if (e.target.value.length >= 7)
            return

        setPincode(e.target.value)
        setPincodeError('')
    }

    const stateChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setState(e.target.value)
        setStateError('')
    }

    const landmarkChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setLandmark(e.target.value)
        setLandmarkError('')
    }

    const additionalInfoChangeHandler: React.ChangeEventHandler<HTMLTextAreaElement> = (e) => {
        setAdditionalInfo(e.target.value)
        setAdditionalInfoError('')
    }

    const continueClickHandler: React.MouseEventHandler<HTMLButtonElement> = () => {
        let error = false

        if (!ORDER_TYPES.includes(orderType)) {
            setOrderTypeError(t('Invalid order type'))
            error = true
        }

        if (toString(firstName) === '') {
            setFirstNameError(t('First Name required'))
            error = true
        }

        if (toString(lastName) === '') {
            setLastNameError(t('Last Name required'))
            error = true
        }

        if (toString(email) === '') {
            setEmailError(t('Email required'))
            error = true
        }

        if (toString(phoneNumber) === '') {
            setPhoneNumberError(t('Phone Number required'))
            error = true
        }

        if (toString(addressLine1) === '' && orderType === "home") {
            setAddressLine1Error(t('Address line 1 required'))
            error = true
        }

        if (toString(addressLine2) === '' && orderType === "home") {
            setAddressLine2Error(t('Address line 2 required'))
            error = true
        }

        if (toString(city) === '' && orderType === "home") {
            setCityError(t('City required'))
            error = true
        }

        if (toString(pincode) === '' && orderType === "home") {
            setPincodeError(t('Pincode required'))
            error = true
        }

        if (toString(state) === '' && orderType === "home") {
            setStateError(t('State required'))
            error = true
        }

        if (toString(paymentMode) === '') {
            setOrderTypeError(t('Payment mode required'))
            error = true
        }

        // if (!PAYMENT_MODES.includes(paymentMode)) {
        //     setOrderTypeError('Invalid payment mode')
        //     error = true
        // }

        if (!error) {
            const orderItems: OrderItem[] = cart.items.map(item => ({
                alternateToppings: item.alternateToppings,
                comment: toString(item.comment) || null,
                discount: null,
                extraToppings: item.extraToppings.map(et => et.id),
                id: toString(item.foodId),
                quantity: toNumber(item.quantity),
                size: toString(item.size),
                spices: item.spices,
                stock: item.stock,
                tax: toNumber(item.tax),
                category: item.category
            }))

            const data: PlaceOrder = {
                additionalInfo: toString(additionalInfo) || null,
                buyer: {
                    address: {
                        addressLine1: toString(addressLine1),
                        addressLine2: toString(addressLine2),
                        city: toString(city),
                        landmark: toString(landmark) || null,
                        pincode: toString(pincode),
                        state: toString(state)
                    },
                    email: toString(email).toLowerCase(),
                    firstName: toString(firstName),
                    lastName: toString(lastName),
                    phoneNumber: toString(phoneNumber)
                },
                orderItems: orderItems,
                orderType: toString(orderType) as OrderType,
                paymentMode: toString(paymentMode) as any,
                shopId: toString(cart.shopId),
                voucherCode: voucherCode || null,
                pickup: pickup
            }

            setLoading(true)
            api.post<Response<PlaceOrderResponse>>('order', data, axiosReqConfig).then(response => {
                if (response.status === 200) {
                    if (paymentMode === 'paytrail') {
                        window.location.href = response.data.data.href
                    }
                    else {
                        navigate(response.data.data.url)
                    }
                } else {
                    throw { response }
                }
            }).catch(error => {
                toast(apiErrorHandler(error, t('Unable to place order')), ToastType.ERROR)
                setLoading(false)
            })
        }
    }

    const continueBookingClickHandler: React.MouseEventHandler<HTMLButtonElement> = () => {
        let error = false

        if (cultureVoucher === "yes" && cultureVoucherSelected === "") {
            setCultureVoucherSelectedError(t("At least one option required"))
            error = true
        }

        if (bookingDetails.length === 0) {
            setParticipantError(t('At least one participant required'))
            error = true
        }

        else if (bookingDetails.length !== 0) {
            for (let booking of bookingDetails) {
                if (booking.name === "") {
                    setNameError(t('Full Name required'))
                    error = true
                }
                else if (booking.foodPreference === "" && eventCart.item?.foodPreferences.length !== 0) {
                    setFoodPreferenceError(t('Food Preference Required'))
                    error = true
                }
            }
        }

        if (volunteerCheckedLists?.length === 0 && eventCart.item?.volunteers.length !== 0 && eventCart.item?.eventType !== "free") {
            setVolunteerError(t('At least one volunteer required'))
            error = true
        }

        if (!eventRule && eventCart.item?.eventRule && eventCart.item?.eventRule.length > 20 && eventCart.item?.eventType !== "free") {
            setEventRuleError(t('Please agree to the terms to proceed'))
            error = true
        }

        if (toString(paymentMode) === '' && eventCart.item?.eventType !== "free") {
            setPaymentModeError(t('Payment mode required'))
            error = true
        }

        if (toString(email) === '' && !authUser && eventCart.item?.withoutRegistration && eventCart.item.withoutRegistration === "Yes") {
            setEmailError(t('Email required'))
            error = true
        }
        // if (paymentMode !== "paytrail") {
        //     error = true

        // }
        // if (!PAYMENT_MODES.includes(paymentMode)) {
        //     setOrderTypeError('Invalid payment mode')
        //     error = true
        // }

        if (!error) {
            const bookingItems: BookingDetails[] = bookingDetails.map(item => ({
                name: item.name,
                type: item.type,
                id: toString(item.id),
                foodPreference: item.foodPreference,
                category: item.category
            }))

            const data: Booking = {
                additionalInfo: toString(additionalInfo) || null,
                user: {
                    address: {
                        addressLine1: toString(addressLine1),
                        addressLine2: toString(addressLine2),
                        city: toString(city),
                        landmark: toString(landmark) || null,
                        pincode: toString(pincode),
                        state: toString(state)
                    },
                    email: toString(email).toLowerCase(),
                    firstName: toString(firstName),
                    lastName: toString(lastName),
                    phoneNumber: toString(phoneNumber)
                },
                email: toString(email).toLowerCase(),
                bookingDetails: bookingItems,
                bookingStatus: bookingStatus,
                volunteers: volunteerCheckedLists,
                personTypes: personTypes,
                cultureVoucher: cultureVoucher === "yes" ? cultureVoucherSelected : cultureVoucher,
                eventRule: eventRule,
                paymentMode: toString(paymentMode) as any,
                eventId: toString(eventCart.item?.eventId),
                eventRuleText: toString(eventCart.item?.eventRule && eventCart.item?.eventRule.length > 10 ? eventCart.item?.eventRule : ''),
                shopId: toString(eventCart.shopId),
                transactionImage: ""
            }

            setLoading(true)
            const apiUrl = (!authUser && eventCart.item?.withoutRegistration && eventCart.item?.withoutRegistration === "Yes") ? 'other-booking' : 'booking';
            api.post<Response<BookingResponse>>(apiUrl, data, axiosReqConfig).then(response => {
                if (response.status === 200) {
                    if (paymentMode === 'paytrail') {
                        window.location.href = response.data.data.href
                    } else {
                        const handleImgUpdate = async () => {
                            if (transactionImage) {
                                const formData: any = new FormData()
                                formData.append("file", transactionImage);
                                // const bookingNumber = response.data.data.url.split('/').pop()
                                let Imgresponse: any = await api.post<Response>(`upload-img/transactionImg/${eventCart.shopId}`, formData);
                                console.log(Imgresponse);

                                if (Imgresponse.status === 200 && Imgresponse.data.data) {
                                    await api.put<Response>(`user/booking/update-transactionImg/${response.data.data.bookingId}`, { transactionImage: Imgresponse.data.data }, axiosReqConfig)
                                        .then(response => {
                                            // console.log(response);
                                            if (response.status === 200) {
                                                toast('Transaction image updated')
                                            }

                                        }).catch(error => {
                                            toast(error.response.data.message)
                                        })
                                }
                            }
                        }
                        handleImgUpdate()
                        navigate(response.data.data.url)
                    }
                } else {
                    throw { response }
                }
            }).catch(error => {
                toast(apiErrorHandler(error, t('Unable to place order')), ToastType.ERROR)
                setLoading(false)
            })
        }
    }

    const continueReservationClickHandler: React.MouseEventHandler<HTMLButtonElement> = () => {
        let error = false
        if (toString(paymentMode) === '') {
            setPaymentModeError(t('Payment mode required'))
            error = true
        }

        if (toString(appointmentDate) === '') {
            setAppointmentDateError(t('Date is required'))
            error = true
        }

        if (toString(slotTime) === '') {
            setSlotTimeError(t('Time is required'))
            error = true
        }

        if (appointmentCart.item?.type === "detail") {
            if (toString(priceSize) === '') {
                setPriceSizeError(t('Appointment type is required'))
                error = true
            }
        }



        if (!error) {
            const reservationItems: ReservationDetails[] = reservationDetails.map(item => ({
                name: item.name,
                id: item.id ? toString(item.id) : "",
                category: item.category,
                type: item.type
            }))

            const data: Reservation = {
                user: {
                    address: {
                        addressLine1: toString(addressLine1),
                        addressLine2: toString(addressLine2),
                        city: toString(city),
                        landmark: toString(landmark) || null,
                        pincode: toNumber(pincode),
                        state: toString(state)
                    },
                    email: toString(email).toLowerCase(),
                    firstName: toString(firstName),
                    lastName: toString(lastName),
                    phoneNumber: toString(phoneNumber)
                },
                reservationDetails: reservationItems,
                reservationStatus: reservationStatus,
                paymentMode: toString(paymentMode) as any,
                appointmentId: toString(appointmentCart.item?.appointmentId),
                shopId: toString(appointmentCart.shopId),
                // fromTime: fromTime,
                // toTime: toTime,
                slotTime: slotTime,
                appointmentDate: toString(appointmentDate),
                totalHours: toString(totalHour),
                priceSize: toString(priceSize),
                price: appointmentCart.item?.type === "detail" ? getAppointmentCheckoutPrice(appointmentCart.item?.prices.filter((p) => p.size === priceSize)[0]) : appointmentCart.item?.offerPrice
            }

            setLoading(true)
            api.post<Response<ReservationResponse>>('reservation', data, axiosReqConfig).then(response => {
                if (response.status === 200) {
                    if (paymentMode === 'paytrail') {
                        window.location.href = response.data.data.href
                    } else {
                        navigate(response.data.data.url)
                    }
                } else {
                    throw { response }
                }
            }).catch(error => {
                toast(apiErrorHandler(error, t('Unable to reservation')), ToastType.ERROR)
                setLoading(false)
            })
        }
    }

    const contextValues: CheckoutContextType = {
        additionalInfo,
        additionalInfoChangeHandler,
        additionalInfoError,
        addressLine1,
        addressLine1ChangeHandler,
        addressLine1Error,
        addressLine2,
        addressLine2ChangeHandler,
        addressLine2Error,
        city,
        cityChangeHandler,
        cityError,
        orderType,
        orderTypeChangeHandler,
        orderTypeError,
        email,
        emailChangeHandler,
        emailError,
        firstName,
        firstNameChangeHandler,
        firstNameError,
        landmark,
        landmarkChangeHandler,
        landmarkError,
        lastName,
        lastNameChangeHandler,
        lastNameError,
        phoneNumber,
        phoneNumberChangeHandler,
        phoneNumberError,
        pincode,
        pincodeChangeHandler,
        pincodeError,
        state,
        stateChangeHandler,
        stateError,
        paymentMode,
        paymentModeChangeHandler,
        paymentModeError,
        continueClickHandler,
        loading,
        voucherCode,
        setVoucherCode,
        inputHanlder,
        addBookDetailHandler,
        foodChoiceHanlder,
        volunteerCheckHandler,
        volunteerError,
        typeHanlder,
        appointmentTypeHanlder,
        removeHandler,
        setTotal,
        setBookingObj,
        bookingObj,
        bookingDetails,
        volunteerCheckedLists,
        nameError,
        total,
        continueBookingClickHandler,
        continueReservationClickHandler,
        bookingStatus,
        bookingStatusChangeHandler,
        appointmentStatusChangeHandler,
        foodPrefers,
        setFoodPrefers,
        removeOtherFood,
        addMore,
        setAddMore,
        cultureVoucherChangeHandler,
        cultureVoucher,
        personTypes,
        setPersonTypes,
        cultureVoucherSelected,
        cultureVocuherSelectHandler,
        eventRuleChangeHandler,
        eventRule,
        eventRuleError,
        cultureVoucherSelectedError,
        foodPreferenceError,
        participantError,
        fromTime,
        toTime,
        appointmentDate,
        totalHour,
        fromTimeChangeHandler,
        toTimeChangeHandler,
        appointmentDateChangeHandler,
        fromTimeError,
        toTimeError,
        appointmentDateError,
        totalHourError,
        priceSizeClickHandler,
        priceSize,
        totalHourChangeHandler,
        timePeriodHandler,
        slotTimeChangeHandler,
        slotTime,
        slotTimeError,
        priceSizeError,
        pickup,
        setPickup,
        setTransactionImage
    }

    React.useEffect(() => {
        if (authUser) {
            setFirstName(authUser.firstName)
            setLastName(authUser.lastName)
            setEmail(authUser.email)
            setPhoneNumber(authUser.phoneNumber)
            setAddressLine1(authUser.address.addressLine1)
            setAddressLine2(authUser.address.addressLine2)
            setCity(authUser.address.city)
            setPincode(authUser.address.pincode.toString())
            setLandmark(authUser.address.landmark || '')
            setState(authUser.address.state)
        }
    }, [authUser])

    React.useEffect(() => {
        const prices = eventCart.item?.prices.map((p: any) => {
            return {
                price: p.price,
                offerPrice: p.offerPrice,
                size: p.size,
                count: ''
            }
        });
        setPersonTypes(prices)
    }, [])
    React.useEffect(() => {
        const prices = appointmentCart.item?.prices.map((p: any) => {
            return {
                price: p.price,
                offerPrice: p.offerPrice,
                size: p.size,
                count: ''
            }
        });
        setPersonTypes(prices)
    }, [])

    // React.useEffect(() => {
    //     if (authUser && cart.shopId) {
    //         api.get<Response<Voucher[]>>(`vouchers/${cart.shopId}`).then(response => {
    //             if (response.status === 200) {
    //                 setVouchers(response.data.data)
    //             } else {
    //                 throw { response }
    //             }
    //         }).catch(() => { })
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [])

    React.useEffect(() => {
        if (authUser && appointmentCart.shopId) {
            api.get<Response>(`user/appointment/check-appointment/${appointmentCart.shopId}/${appointmentDate}/${slotTime}`).then((response: any) => {
                if (response.status === 200) {
                    const existingAppointmentCount = response.data.data.totalReservation;
                    const capacityCount = appointmentCart.item?.slots.filter((slot) => slot.fromTime.time + ' ' + slot.fromTime.timePeriod + '-' + slot.toTime.time + ' ' + slot.toTime.timePeriod === slotTime)[0].capacity;
                    if (existingAppointmentCount === toNumber(capacityCount)) {
                        setSlotTime('');
                        setShowAppointmentModal(true);
                    }


                } else {
                    throw { response }
                }
            }).catch(() => { })
        }
    }, [appointmentDate, slotTime])

    React.useEffect(() => {
        if (showAlert) {
            setTimeout(() => {
                setShowAlert(false)
            }, 10000)
        }
    }, [showAlert])

    React.useEffect(() => {
        if (urlSearchParams.get('payment_status') === 'failed') {
            setShowAlert(true)
        }
    }, [urlSearchParams])

    return <CheckoutContext.Provider value={contextValues}>
        {showAppointmentModal && <div className={style.topAlertBanner}>
            <div className={`container ${style.alert}`}>
                <div className={style.alertText}>{t("This Schedule Sold Out, Choose some other Date/Time")}</div>
                <Close white small onClose={() => setShowAppointmentModal(false)} />
            </div>
        </div>
        }
        {showAlert && <div className={style.topAlertBanner}>
            <div className={`container ${style.alert}`}>
                <div className={style.alertText}>{t("Payment failed")}</div>
                <Close white small onClose={() => setShowAlert(false)} />
            </div>
        </div>}
        {props.children}
    </CheckoutContext.Provider>
}

export default CheckoutProvider