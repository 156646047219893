const Veg = () => {
    return <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'>
        <g id='Group_194' data-name='Group 194' transform='translate(-790 -170)'>
            <g id='Rectangle_68' data-name='Rectangle 68' transform='translate(790 170)' fill='#fff' stroke='#007C00' strokeWidth='1'>
                <rect width='20' height='20' stroke='none' />
                <rect x='0.5' y='0.5' width='19' height='19' fill='none' />
            </g>
            <g id='Ellipse_6' data-name='Ellipse 6' transform='translate(794 174)' fill='#007C00' stroke='#007C00' strokeWidth='1'>
                <circle cx='6' cy='6' r='6' stroke='none' />
                <circle cx='6' cy='6' r='5.5' fill='none' />
            </g>
        </g>
    </svg>
}

export default Veg