import { useSelector } from 'react-redux'
import ReadReview from '../../components/modals/ReadReview'
import AdminLayout from '../../components/sections/AdminLayout'
import AdminPanelHeader from '../../components/sections/AdminPanelHeader'
import { useT } from '../../i18n'
import { StoreState } from '../../redux/reducers'
import { AuthAdmin } from '../../redux/reducers/authAdmin.reducer'

const Feedbacks = () => {
    const authAdmin = useSelector<StoreState, AuthAdmin | null>(state => state.authAdmin)
    const shopId = authAdmin?.shop.id
    const t = useT()

    return <AdminLayout>

        <AdminPanelHeader title={t('Feedbacks')} />
        <ReadReview
            shopId={shopId}
            showbutton={false}
            showToggle={true}
            from='Admin'
            showRating={true}
        />
    </AdminLayout>
}

export default Feedbacks