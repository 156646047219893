import { RatingActionTypes } from '../actions/rating.actions'

export interface Rating {
    id?: string
    userId: string
    rating: number
}

const initialState: Rating[] = []

const RatingReducer = (state: Rating[] = initialState, action: any): Rating[] => {
    switch (action.type) {
        case RatingActionTypes.ADD_RATING: {
            return [
                ...state,
                action.data
            ]
        }

        case RatingActionTypes.UPDATE_RATING: {
            const Ratings = [...state]
            const index = Ratings.findIndex(c => c.userId === action.data.userId)

            if (index > -1) {
                Ratings[index] = {
                    ...action.data
                }
            }

            return Ratings
        }

        case RatingActionTypes.FETCH_RATINGS: {
            return action.data
        }

        case RatingActionTypes.DELETE_RATING: {
            return state.filter(c => c.userId !== action.data)
        }

        default: {
            return state
        }
    }
}

export default RatingReducer