import { nanoid } from '@reduxjs/toolkit'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useT } from '../../i18n'
import { fetchShops } from '../../redux/actions'
import { StoreState } from '../../redux/reducers'
import { AuthAdmin } from '../../redux/reducers/authAdmin.reducer'
import { Shop } from '../../redux/reducers/shop.reducer'
import { AppDispatch } from '../../redux/store'
import { testNumber, toString } from '../../utils'
import ModalWithHeader from '../modals/ModalWithHeader'
import { useToast } from '../ToastProvider'
import FormInput from '../utils/FormInput'

export interface Pickup {
    id: string
    addressLine1?: string
    addressLine2?: string
    state?: string
    city?: string
    pincode?: string
    phoneNumber?: string
    landmark?: string
}

interface PickupFormProps {
    id?: string
    onClose?: () => void
    onSave?: (pickup: Pickup) => void
}

const PickupPointForm = (props: PickupFormProps) => {
    const dispatch = useDispatch<AppDispatch>()
    const toast = useToast()
    const t = useT()

    const authAdmin = useSelector<StoreState, AuthAdmin | null>(state => state.authAdmin)
    const shop = useSelector<StoreState, Shop>(state => state.shops.find(f => f.id === authAdmin?.shop.id)!)

    const [addressLine1, setAddressLine1] = React.useState<string>('')
    const [addressLine1Error, setAddressLine1Error] = React.useState<string>('')
    const [addressLine2, setAddressLine2] = React.useState<string>('')
    const [addressLine2Error, setAddressLine2Error] = React.useState<string>('')
    const [pincode, setPincode] = React.useState<string>('')
    const [pincodeError, setPincodeError] = React.useState<string>('')
    const [landmark, setLandmark] = React.useState<string>('')
    const [landmarkError, setLandmarkError] = React.useState<string>('')
    const [countryCode, setCountrycode] = React.useState<string>('+358')
    const [phoneNumber, setPhoneNumber] = React.useState<string>('')
    const [phoneNumberError, setPhoneNumberError] = React.useState<string>('')
    const [state, setState] = React.useState<string>('')
    const [stateError, setStateError] = React.useState<string>('')
    const [city, setCity] = React.useState<string>('')
    const [cityError, setCityError] = React.useState<string>('')

    const [showShopInformation, setShowShopInformation] = React.useState<boolean>(false)
    const [loading, setLoading] = React.useState<boolean>(false)

    const addressLine1ChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setAddressLine1(e.target.value)
        setAddressLine1Error('')
    }

    const addressLine2ChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setAddressLine2(e.target.value)
        setAddressLine2Error('')
    }

    const pincodeChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        if ((!testNumber(e.target.value) && e.target.value))
            return
        else if (e.target.value.length >= 7)
            return

        setPincode(e.target.value)
        setPincodeError('')
    }

    const cityChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setCity(e.target.value)
        setCityError('')
    }

    const landmarkChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setLandmark(e.target.value)
        setLandmarkError('')
    }

    const countryCodeChangeHandler = (value: string) => {
        setCountrycode(value)
    }

    const phoneNumberChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        if ((!testNumber(e.target.value) && e.target.value))
            return
        else if (e.target.value.length >= 12)
            return

        setPhoneNumber(e.target.value)
        setPhoneNumberError('')
    }

    const stateChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setState(e.target.value)
        setStateError('')
    }

    const saveClickHandler: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault()

        let error = false

        // if (toString(phoneNumber) === '') {
        //     setPhoneNumberError(t('Phone Number required'))
        //     error = true
        // } else if ((phoneNumber).length > 15) {
        //     setPhoneNumberError(t('Phone Number should be within 15 digits'))
        //     error = true
        // }

        if (toString(pincode) === '') {
            setPincodeError(t('Pincode required'))
            error = true
        } else if ((pincode).length > 10) {
            setPincodeError(t('Pincode should be within 10 digits'))
            error = true
        }

        if (toString(city) === '') {
            setCityError(t('City required'))
            error = true
        }

        if (toString(addressLine1) === '') {
            setAddressLine1Error(t('Address line 1 required'))
            error = true
        }

        if (toString(addressLine2) === '') {
            setAddressLine2Error(t('Address line 2 required'))
            error = true
        }

        if (toString(state) === '') {
            setStateError(t('State required'))
            error = true
        }


        if (!error) {
            props.onSave?.({
                id: nanoid(),
                addressLine1: toString(addressLine1),
                addressLine2: toString(addressLine2),
                city: toString(city),
                state: toString(state),
                landmark: toString(landmark),
                phoneNumber: toString(phoneNumber),
                pincode: toString(pincode)

            })
            props.onClose?.()
        }
    }


    React.useEffect(() => {
        dispatch(fetchShops())
    }, [])


    return <ModalWithHeader
        buttonText={t('Save')}
        headerText={('Add Pick-Up Address')}
        onClose={props.onClose}
        onSave={saveClickHandler}
        small
    >
        <div className='col-md-12 col-lg-12'>
            <FormInput
                type='text'
                label={t('Address Line 1')}
                placeholder={t('Address Line 1')}
                errorText={addressLine1Error}
                value={addressLine1}
                onChange={addressLine1ChangeHandler}
                containerClass='mb-4'
            />
        </div>
        <div className='col-md-12 col-lg-12'>
            <FormInput
                type='text'
                label={t('Address Line 2')}
                placeholder={t('Address Line 2')}
                errorText={addressLine2Error}
                value={addressLine2}
                onChange={addressLine2ChangeHandler}
                containerClass='mb-4'
            />
        </div>
        <div className='col-md-12 col-lg-12'>
            <FormInput
                type='text'
                label={t('City')}
                placeholder={t('City')}
                errorText={cityError}
                value={city}
                onChange={cityChangeHandler}
                containerClass='mb-4'
            />
        </div>
        <div className='col-md-12 col-lg-12'>
            <FormInput
                type='text'
                label={t('State')}
                placeholder={t('State')}
                errorText={stateError}
                value={state}
                onChange={stateChangeHandler}
                containerClass='mb-4'
            />
        </div>
        <div className='col-md-12 col-lg-12'>
            <FormInput
                type='text'
                label={t('Pincode')}
                placeholder={t('Pincode')}
                errorText={pincodeError}
                value={pincode}
                onChange={pincodeChangeHandler}
                containerClass='mb-4'
            />
        </div>
        <div className='col-md-12 col-lg-12'>
            <FormInput
                type='text'
                label={t('Landmark')}
                placeholder={t('Landmark')}
                errorText={landmarkError}
                value={landmark}
                onChange={landmarkChangeHandler}
                containerClass='mb-4'
            />
        </div>
        <div className='col-md-12 col-lg-12'>
            <FormInput
                type='text'
                label={t('Phone Number')}
                placeholder={t('Phone Number')}
                errorText={phoneNumberError}
                value={phoneNumber}
                leftRenderer={countryCode}
                countryCode={countryCodeChangeHandler}
                onChange={phoneNumberChangeHandler}
                containerClass='mb-4'
            />
        </div>
    </ModalWithHeader>
}

export default PickupPointForm