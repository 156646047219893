import React from 'react'
import { useDispatch } from 'react-redux'
import { useT } from '../../i18n'
import { loginSuperAdmin } from '../../redux/actions'
import { AppDispatch } from '../../redux/store'
import style from '../../styles/components/authenticate.module.scss'
import formStyle from '../../styles/components/form.module.scss'
import { toString } from '../../utils'
import { ToastType, useToast } from '../ToastProvider'
import Eye from '../svgs/Eye'
import EyeOff from '../svgs/EyeOff'
import Button from '../utils/Button'
import FormInput from '../utils/FormInput'

const SuperAdminAuthenticateForm = () => {
    const dispatch = useDispatch<AppDispatch>()
    const toast = useToast()
    const t = useT()

    const [username, setUsername] = React.useState<string>('')
    const [usernameError, setUsernameError] = React.useState<string>('')
    const [password, setPassword] = React.useState<string>('')
    const [passwordError, setPasswordError] = React.useState<string>('')

    const [showPassword, setShowPassword] = React.useState<boolean>(false)
    const [loading, setLoading] = React.useState<boolean>(false)

    const usernameChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setUsername(e.target.value)
        setUsernameError('')
    }

    const passwordChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setPassword(e.target.value)
        setPasswordError('')
    }

    const submitHandler: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault()

        let error = false

        if (toString(username) === '') {
            setUsernameError(t('Username required'))
            error = true
        }

        if (password === '') {
            setPasswordError(t('Password required'))
            error = true
        }

        if (!error) {
            setLoading(true)
            dispatch(loginSuperAdmin({
                username: toString(username).toLowerCase(),
                password: password
            })).then(text => {
                toast(text)
            }).catch(text => {
                toast(text, ToastType.ERROR)
            }).finally(() => {
                setLoading(false)
            })
        }
    }

    return <form>
        <div className={`${style.mainContent} px-4`}>
            <div className='row'>
                <div className='col-12'>
                    <FormInput
                        type='username'
                        label={t('Username')}
                        placeholder={t('Username')}
                        errorText={usernameError}
                        value={username}
                        onChange={usernameChangeHandler}
                        containerClass='mb-4'
                    />
                </div>
                <div className='col-12'>
                    <FormInput
                        type={showPassword ? 'text' : 'password'}
                        label={t('Password')}
                        placeholder={t('Password')}
                        errorText={passwordError}
                        value={password}
                        onChange={passwordChangeHandler}
                        rightRenderer={showPassword ? <EyeOff /> : <Eye />}
                        onRightRendererClick={() => setShowPassword(!showPassword)}
                        containerClass='mb-4'
                    />
                </div>
            </div>
        </div>

        <div className='p-4'>
            <Button type='submit' className={formStyle.saveButton} onClick={submitHandler} loading={loading}>{t("Sign In")}</Button>
        </div>
        <div className='p-4'>
            <p className='text-danger p-1 text-center'>{t("Restricted access")}</p>
        </div>
    </form>
}

export default SuperAdminAuthenticateForm