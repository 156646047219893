import { useSelector } from 'react-redux';
import AdminLayout from '../../components/sections/AdminLayout';
import AdminPanelHeader from '../../components/sections/AdminPanelHeader';
import BookingReport from '../../components/sections/BookingReport';
import Report from '../../components/sections/Report';
import { useT } from '../../i18n';
import { StoreState } from '../../redux/reducers';
import { AuthAdmin } from '../../redux/reducers/authAdmin.reducer';
const Reports = () => {
    const authAdminCategory = useSelector<StoreState, AuthAdmin | null>(state => state.authAdmin)?.category
    const t = useT()
    return <AdminLayout>
        <AdminPanelHeader title={t('Reports')} />
        {authAdminCategory === "Event" ? <BookingReport /> : <Report />}
    </AdminLayout>
}

export default Reports