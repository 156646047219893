import React,{ChangeEventHandler, LegacyRef, useRef} from "react";
import { OptionProps } from "./Select";
import style from '../../styles/components/time-input.module.scss'
import Clock from "../svgs/Clock";

interface Props{
    options:OptionProps[]
    value:string | undefined
    onChange:any
    label:string
    timePeriod:string
    timePeriodHandler:any

}

const TimeInput = (props:Props) =>{
    const [showTime, setShowTime] = React.useState(false);
    const periodHanlder = () =>{
        props.timePeriodHandler(props.timePeriod==='AM'?'PM':'AM')
    }

    return(<div className={style.timeContainer}>
            {props.label&&<label className={style.label}>{props.label}</label>}
            <div className={style.timeCard}>
            <div className={`hstack justify-content-between ${style.timeInput}`}><div className="hstack gap-3"><div onClick={()=>setShowTime(showTime?false:true)}>{props.value?props.value:'--:--'}</div><div className={style.periods} onClick={periodHanlder}>{props.timePeriod}</div></div><div className={style.clock} onClick={()=>setShowTime(showTime?false:true)}><Clock/></div></div>
            <div className={`${style.timeItems} ${showTime?style.active:''}`}>
                {props.options.map((time:any)=>(
                    <div className={style.timeItem} onClick={()=>{props.onChange(time.value);setShowTime(false)}}>{time.text}</div>
                ))}
            </div>
            </div>
    </div>)
}

export default TimeInput;