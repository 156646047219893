import React from 'react'
import { Camera, Edit } from 'react-feather'
import { Link } from 'react-router-dom'
import assets from '../../assets'
import { useT } from '../../i18n'
import style from '../../styles/components/card.module.scss'
import { toCurrencyFormat } from '../../utils'
import Calender from '../svgs/Calender'
import Cast from '../svgs/Cast'
import IosHeart from '../svgs/IosHeart'
import LocationOn from '../svgs/LocationOn'
import PercentageWhite from '../svgs/PercentageWhite'
import Star from '../svgs/Star'
import Button from '../utils/Button'
import Switch from '../utils/Switch'

export interface CardProps {
    name?: string
    img?: string
    offerLabel?: string
    price?: number | null | undefined
    eventDate?: string
    appointmentDate?: string
    offerPrice?: number | null | undefined
    buttonText?: string
    onClick?: React.MouseEventHandler<HTMLButtonElement>
    rating?: number
    subText?: string
    locationPrefix?: string
    locationSuffix?: string
    favourites?: boolean
    isFavourite?: boolean
    includes?: string
    to?: string
    onFavouritesClick?: () => void
    startsFrom?: boolean
    status?: string | undefined | null
    toggleHandler?: () => void
    uploadBanner?: () => void
    updatePrice?: () => void
    itemId?: string
    from?: boolean
    preview?: boolean
    currency?: string
    previewHandler?: () => void
    cloneEvent?: () => void
}

const Card = (props: CardProps) => {
    return props.to
        ? <Link to={props.to} className={style.container}>
            <Children {...props} />
        </Link>
        : <div className={style.container}>
            <Children {...props} />
        </div>
}

const Children = (props: CardProps) => {
    const t = useT()

    // const dispatch = useDispatch<AppDispatch>()
    // const shopRatings = useSelector<StoreState, Rating[]>(state => state.rating)

    // const TotalAvgShopRating = useMemo<number>(() => {
    //     let sum = shopRatings.reduce((prev, current) => {
    //         return prev + current.rating
    //     }, 0)
    //     const value = sum / shopRatings.length
    //     return shopRatings.length > 0 ? value : 0
    // }, [shopRatings, props.itemId])

    // useEffect(() => {
    //     if (props.itemId) {
    //         dispatch(fetchRatings(props.itemId))
    //     }
    // }, [props.itemId])

    return <React.Fragment>
        <div className={style.imageContainer}>
            <div className={style.imageWrapper}>
                <img src={props.img ? props.img : assets.images.thumbnail} alt={props.name} className={style.image} loading='lazy' />
            </div>
            {props.offerLabel && <div className={style.absoluteContainer}>
                <div className={style.offerLabelContent}>
                    <div className={style.absoluteIconWrapper}>
                        <PercentageWhite />
                    </div>
                    <div className={style.absoluteContainerLabel}>{props.offerLabel}</div>
                </div>
            </div>}
            {typeof props.rating === 'number' && <div className={style.absoluteContainer}>
                <div className={style.ratingContent}>
                    <div className={style.absoluteIconWrapper}>
                        <Star />
                    </div>
                    <div className={style.absoluteContainerLabel}>{props.rating}</div>
                </div>
            </div>}
            {props.uploadBanner && <div className={style.iosHeartIconWrapper} onClick={props.uploadBanner}>
                <Camera />
            </div>}
            {props.updatePrice && <div className={style.editIconWrapper} onClick={props.updatePrice}>
                <Edit />
            </div>}
            {props.previewHandler && <div className={`${style.castIconWrapper} ${props.preview ? style.showCast : ''}`} onClick={props.previewHandler}>
                <Cast stroke={props.preview ? '#4ab96a' : '#404040'} strokeWidth='2' />
            </div>}
            {/* {props.from === true && <div className={style.facebookIconWrapper}>
                <FacebookShareButton
                    url={`${window.location.href}?search=${props.name}`}
                    className="facebookSharing"
                    hashtag={`#${props.name}`}
                >
                    <FacebookIcon size={34} />
                </FacebookShareButton>
            </div>} */}
            {props.favourites && <div className={style.iosHeartIconWrapper} onClick={props.onFavouritesClick}>
                <IosHeart
                    fill={props.isFavourite ? '#ec3b50' : 'none'}
                    stroke={props.isFavourite ? '#ec3b50' : '#404040'}
                />
            </div>}
        </div>
        <div className={style.bottomSection}>
            <div className='d-flex justify-content-between hstack gap-2 mt-4'>
                {props.name && <div className={style.name}>{props.name}</div>}
                {props.status && <div >
                    <Switch
                        onClick={props?.toggleHandler}
                        checked={props?.status === "active" ? true : false}
                        label={props.status === "active" ? 'Active' : 'Inactive'}
                    />
                </div>}
            </div>
            {typeof props.subText === 'string' && (props.subText !== ''
                ? <div className={style.subText}>{props.subText}</div>
                : <React.Fragment>&nbsp;</React.Fragment>)}
            {typeof props.includes === 'string' && (props.includes !== ''
                ? <div className={style.includes}>{t("Includes")}: {props.includes}</div>
                : <React.Fragment>&nbsp;</React.Fragment>)}
            {props.eventDate ? <p><Calender /> {props.eventDate}</p> : ''}
            {typeof props.price === 'number' && <div className='vstack gap-1'>
                {props.startsFrom && <div className={style.startsFrom}>{t("Starts from")}</div>}
                {props.offerPrice?.toString() !== "" && props.offerPrice! > 0
                    ? <h4> {toCurrencyFormat(props.offerPrice, props?.currency)}</h4>
                    : props.price?.toString() !== "" && props.price! > 0 ? <h4> {toCurrencyFormat(props.price, props?.currency)}</h4> : ""}
                {/* <br /> */}
                {props.offerPrice?.toString() !== "" && props.offerPrice! > 0 && <div className={style.price}><del>{toCurrencyFormat(props.price, props?.currency)}</del></div>}
                {/* <div className={style.offerPrice}>/ {toCurrencyFormat(props.offerPrice)}</div> */}
            </div>}
            {(props.locationPrefix || props.locationSuffix) && <div className={style.locationWrapper}>
                <div className={style.locationIconWrapper}>
                    <LocationOn />
                </div>
                <div className={style.locationPrefix}>{props.locationPrefix}, </div>
                <div className={style.locationSuffix}>{props.locationSuffix}</div>
            </div>}
            {props.buttonText && <Button className={style.editButton} onClick={props.onClick}>{props.buttonText}</Button>}
            {props.cloneEvent && <Button className={style.cloneButton} onClick={props.cloneEvent}>{t("Clone Event")}</Button>}
        </div>
    </React.Fragment>
}

export default Card