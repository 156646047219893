/* eslint-disable no-throw-literal */
import { Dispatch } from 'redux'
import { api, apiErrorHandler, getApiRequestConfig, Response } from '../../api'
import { GetState } from '../reducers'
import { Voucher, VoucherMode } from '../reducers/voucher.reducer'

export enum VoucherActionTypes {
    ADD_VOUCHER = 'ADD_VOUCHER',
    UPDATE_VOUCHER = 'UPDATE_VOUCHER',
    FETCH_SHOP_VOUCHERS = 'FETCH_SHOP_VOUCHERS',
    DELETE_VOUCHER = 'DELETE_VOUCHER'
}

export interface AddVoucherAction {
    type: VoucherActionTypes.ADD_VOUCHER
    data: Voucher
}

export interface UpdateVoucherAction {
    type: VoucherActionTypes.UPDATE_VOUCHER
    data: Voucher
}

export interface FetchShopVouchersAction {
    type: VoucherActionTypes.FETCH_SHOP_VOUCHERS
    data: Voucher[]
}

export interface DeleteVoucher {
    id: string
}

export interface DeleteVoucherAction {
    type: VoucherActionTypes.DELETE_VOUCHER
    data: string
}

export type VoucherActions = AddVoucherAction | FetchShopVouchersAction | UpdateVoucherAction|DeleteVoucherAction

interface AddVoucher {
    title: string
    description: string | null
    code: string
    value: number
    minOrderValue: number
    maxCount: number
    mode: VoucherMode
    expiryDate: string
}

export const addVoucher = (input: AddVoucher) => async (dispatch: Dispatch, getState: GetState) => {
    const authAdmin = getState().authAdmin
    const config = getApiRequestConfig(authAdmin?.['auth-token'])

    return api.post<Response<Voucher>>('voucher', input, config).then(response => {
        if (response.status === 200) {
            dispatch<AddVoucherAction>({
                type: VoucherActionTypes.ADD_VOUCHER,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || 'Added')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to add'))
    })
}

 export interface UpdateVoucher {
    title: string
    description: string | null
    code: string
    value: number
    minOrderValue: number
    maxCount: number
    mode: VoucherMode
    expiryDate: string
}

export const updateVoucher = (input: UpdateVoucher, voucherId: string) => async (dispatch: Dispatch, getState: GetState) => {
    const authAdmin = getState().authAdmin
    const config = getApiRequestConfig(authAdmin?.['auth-token'])

    return api.put<Response<Voucher>>(`voucher/${voucherId}`, input, config).then(response => {
        if (response.status === 200) {
            dispatch<UpdateVoucherAction>({
                type: VoucherActionTypes.UPDATE_VOUCHER,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || 'Updated')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to updated'))
    })
}

export const fetchShopVouchers = () => async (dispatch: Dispatch, getState: GetState) => {
    const authAdmin = getState().authAdmin
    const config = getApiRequestConfig(authAdmin?.['auth-token'])

    return api.get<Response<Voucher[]>>('vouchers', config).then(response => {
        if (response.status === 200) {
            dispatch<FetchShopVouchersAction>({
                type: VoucherActionTypes.FETCH_SHOP_VOUCHERS,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || '')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to fetch'))
    })
}

export const deleteVoucher = (voucherId: string) => async (dispatch: Dispatch, getState: GetState) => {
    const authAdmin = getState().authAdmin
    const config = getApiRequestConfig(authAdmin?.['auth-token'])

    return api.delete(`voucher/${voucherId}`,config).then(response => {
        if (response.status === 200) {
            dispatch<DeleteVoucherAction>({
                type: VoucherActionTypes.DELETE_VOUCHER,
                data: voucherId
            })
            return Promise.resolve<string>(response.data.message || 'Delete voucher')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to delete'))
    })
}