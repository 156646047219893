import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useT } from '../../i18n'
import { generateReferralLink, updateUserContactDetails } from '../../redux/actions'
import { StoreState } from '../../redux/reducers'
import { AuthUser } from '../../redux/reducers/authUser.reducer'
import { AppDispatch } from '../../redux/store'
import formStyle from '../../styles/components/form.module.scss'
import { testNumber, toNumber, toString } from '../../utils'
import { ToastType, useToast } from '../ToastProvider'
import Button from '../utils/Button'
import FormInput from '../utils/FormInput'

const MyProfileForm = () => {

    const dispatch = useDispatch<AppDispatch>()
    const toast = useToast()
    const t = useT()

    const authUser = useSelector<StoreState, AuthUser | null>(state => state.authUser)
    const referralCount = toNumber(authUser?.referralCode?.count);
    const [firstName, setFirstName] = React.useState<string>('')
    const [firstNameError, setFirstNameError] = React.useState<string>('')
    const [lastName, setLastName] = React.useState<string>('')
    const [referralCode, setReferralCode] = React.useState<string>('')
    const [lastNameError, setLastNameError] = React.useState<string>('')
    const [Email, setEmail] = React.useState<string>('')
    const [EmailError, setEmailError] = React.useState<string>('')
    const [countryCode, setCountryCode] = React.useState<string>('+358')
    const [phoneNumber, setPhoneNumber] = React.useState<string>('')
    const [phoneNumberError, setPhoneNumberError] = React.useState<string>('')
    const [addressLine1, setAddressLine1] = React.useState<string>('')
    const [addressLine1Error, setAddressLine1Error] = React.useState<string>('')
    const [addressLine2, setAddressLine2] = React.useState<string>('')
    const [addressLine2Error, setAddressLine2Error] = React.useState<string>('')
    const [city, setCity] = React.useState<string>('')
    const [cityError, setCityError] = React.useState<string>('')
    const [state, setState] = React.useState<string>('')
    const [stateError, setStateError] = React.useState<string>('')
    const [pincode, setPincode] = React.useState<string>('')
    const [pincodeError, setPincodeError] = React.useState<string>('')
    const [landmark, setLandmark] = React.useState<string>('')
    const [landmarkError, setLandmarkError] = React.useState<string>('')
    const [copyText, setCopyText] = React.useState<boolean>(false)
    const [loading, setLoading] = React.useState<boolean>(false)


    const FirstNameChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setFirstName(e.target.value)
        setFirstNameError('')
    }

    const LastNameChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setLastName(e.target.value)
        setLastNameError('')
    }

    const EmailChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setEmail(e.target.value)
        setEmailError('')
    }

    const phoneNumberChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setPhoneNumber(e.target.value)
        setPhoneNumberError('')
    }

    const addressLine1ChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setAddressLine1(e.target.value)
        setAddressLine1Error('')
    }
    const addressLine2ChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setAddressLine2(e.target.value)
        setAddressLine2Error('')
    }

    const pincodeChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        if ((!testNumber(e.target.value) && e.target.value))
            return
        else if (e.target.value.length >= 7)
            return

        setPincode(e.target.value)
        setPincodeError('')
    }

    const cityChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const value = e.target.value;
        const regex = /^[A-Za-z]*$/;

        if (regex.test(value)) {
            setCity(value);
            setCityError('');
        } else {
            setCityError('Please enter only alphabets');
        }
    }

    const landmarkChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setLandmark(e.target.value)
        setLandmarkError('')
    }

    const stateChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const value = e.target.value;
        const regex = /^[A-Za-z]*$/;

        if (regex.test(value)) {
            setState(value);
            setStateError('');
        } else {
            setStateError('Please enter only alphabets');
        }
    }

    const copyClipBoard = () => {
        setCopyText(true);
        let hostName = window.location.host;
        navigator.clipboard.writeText(`${hostName}?ref=${referralCode}`)
        setTimeout(() => {
            setCopyText(false);
        }, 1000)
    }

    const countryCodeChangeHandler = (value: string) => {
        setCountryCode(value);
    }

    const generateCodeHandler = () => {
        dispatch(generateReferralLink())

    }

    const saveClickHandler: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault()

        let error = false

        if (toString(firstName) === '') {
            setFirstNameError(t('First Name required'))
            error = true
        }

        if (toString(lastName) === '') {
            setLastNameError(t('Last Name required'))
            error = true
        }

        if (toString(Email) === '') {
            setEmailError(t('Email required'))
            error = true
        }

        if (toString(phoneNumber) === '') {
            setPhoneNumberError(t('Phone Number required'))
            error = true
        } else if ((phoneNumber).length > 15) {
            setPhoneNumberError(t('Phone Number should be within 15 digits'))
            error = true
        }

        // if (toString(addressLine1) === '') {
        //     setAddressLine1Error(t('Address line 1 required'))
        //     error = true
        // }

        // if (toString(pincode) === '') {
        //     setPincodeError(t('Pincode required'))
        //     error = true
        // } else 
        if ((pincode).length > 10) {
            setPincodeError(t('Pincode should be within 10 digits'))
            error = true
        }

        if (toString(city) === '') {
            setCityError(t('City required'))
            error = true
        }

        // if (toString(addressLine2) === '') {
        //     setAddressLine2Error(t('Address line 2 required'))
        //     error = true
        // }

        // if (toString(state) === '') {
        //     setStateError(t('State required'))
        //     error = true
        // }

        if (!error) {
            setLoading(true)
            dispatch(updateUserContactDetails({
                city: toString(city),
                firstName: toString(firstName),
                lastName: toString(firstName),
                Email: toString(Email).toLowerCase(),
                addressLine1: toString(addressLine1),
                addressLine2: toString(addressLine2),
                landmark: toString(landmark) || null,
                pincode: toString(pincode),
                state: toString(state),
                referral: { code: authUser?.referralCode?.code || '', count: authUser?.referralCode?.count || '' },
                countryCode: toString(countryCode),
                phoneNumber: toString(phoneNumber) || null
            })).then(() => {
                toast(t('Details updated'))
            }).catch(text => {
                toast(text, ToastType.ERROR)
            }).finally(() => {
                setLoading(false)
            })
        }

    }

    React.useEffect(() => {
        setFirstName(authUser?.firstName || '')
        setLastName(authUser?.lastName || '')
        setEmail(authUser?.email || '')
        setAddressLine1(authUser?.address.addressLine1 || '')
        setAddressLine2(authUser?.address.addressLine2 || '')
        setPincode(authUser?.address.pincode.toString() || '')
        setLandmark(authUser?.address.landmark || '')
        setState(authUser?.address.state || '')
        setCity(authUser?.address.city || '')
        setPhoneNumber(authUser?.phoneNumber || '')
        setCountryCode(authUser?.countryCode || '+358')
        setReferralCode(authUser?.referralCode?.code || '')
    }, [authUser])

    return <React.Fragment>
        {<div className='col-md-6 col-lg-4'>
            <FormInput
                type='text'
                label={`Your Personal Referral Link ${authUser?.referralCode?.count ? '(' + authUser?.referralCode.count + ')' : ''}`}
                value={referralCode ? `${window.location.host}?ref=${referralCode}` : ''}
                containerClass='mb-4'
                rightRenderer={referralCode ? copyText ? 'Copied' : 'Copy' : 'Generate'}
                onRightRendererClick={referralCode ? copyClipBoard : generateCodeHandler}
                disabled
            />
        </div>}
        <div className='col-md-6 col-lg-4'>
            <FormInput
                type='text'
                label={t('First Name')}
                placeholder={t('First Name')}
                errorText={firstNameError}
                value={firstName}
                onChange={FirstNameChangeHandler}
                containerClass='mb-4'
            // disabled
            />
        </div>
        <div className='col-md-6 col-lg-4'>
            <FormInput
                type='text'
                label={t('Last Name')}
                placeholder={t('Last Name')}
                errorText={lastNameError}
                value={lastName}
                onChange={LastNameChangeHandler}
                containerClass='mb-4'
            // disabled
            />
        </div>
        <div className='col-md-6 col-lg-4'>
            <FormInput
                type='text'
                label={t('Email')}
                placeholder={t('Email')}
                errorText={EmailError}
                value={Email}
                onChange={EmailChangeHandler}
                containerClass='mb-4'
                disabled
            />
        </div>
        <div className='col-md-6 col-lg-4'>
            <FormInput
                type='number'
                label={t('Phone Number')}
                placeholder={t('Phone Number')}
                errorText={phoneNumberError}
                value={phoneNumber}
                leftRenderer={countryCode}
                countryCode={countryCodeChangeHandler}
                onChange={phoneNumberChangeHandler}
                containerClass='mb-4'
            />
        </div>
        <div className='col-md-6 col-lg-4'>
            <FormInput
                type='text'
                label={t('Address Line 1')}
                placeholder={t('Address Line 1')}
                errorText={addressLine1Error}
                value={addressLine1}
                onChange={addressLine1ChangeHandler}
                containerClass='mb-4'
            />
        </div>
        <div className='col-md-6 col-lg-4'>
            <FormInput
                type='text'
                label={t('Address Line 2')}
                placeholder={t('Address Line 2')}
                errorText={addressLine2Error}
                value={addressLine2}
                onChange={addressLine2ChangeHandler}
                containerClass='mb-4'
            />
        </div><div className='col-md-6 col-lg-4'>
            <FormInput
                type='text'
                label={t('City')}
                placeholder={t('City')}
                errorText={cityError}
                value={city}
                onChange={cityChangeHandler}
                containerClass='mb-4'
            />
        </div>
        <div className='col-md-6 col-lg-4'>
            <FormInput
                type='text'
                label={t('State')}
                placeholder={t('State')}
                errorText={stateError}
                value={state}
                onChange={stateChangeHandler}
                containerClass='mb-4'
            />
        </div>
        <div className='col-md-6 col-lg-4'>
            <FormInput
                type='text'
                label={t('Pincode')}
                placeholder={t('Pincode')}
                errorText={pincodeError}
                value={pincode}
                onChange={pincodeChangeHandler}
                containerClass='mb-4'
            />
        </div>
        <div className='col-md-6 col-lg-4'>
            <FormInput
                type='text'
                label={t('Landmark')}
                placeholder={t('Landmark')}
                errorText={landmarkError}
                value={landmark}
                onChange={landmarkChangeHandler}
                containerClass='mb-4'
            />
        </div>

        <div className='mb-4'>
            <Button onClick={saveClickHandler} loading={loading} className={formStyle.saveButton}>{t("Update Contact Details")}</Button>
        </div>
    </React.Fragment>
}

export default MyProfileForm;