import { useDispatch } from 'react-redux'
import { decreaseCartItemQuantity, increaseCartItemQuantity, removeCartItem } from '../../redux/actions'
import { AppDispatch } from '../../redux/store'
import style from '../../styles/components/cart-counter-button.module.scss'
import Trash from '../svgs/Trash'
import Minus from '../utils/Minus'
import Plus from '../utils/Plus'

interface CartCounterButtonProps {
    cartId?: string
    quantity?: number
    stock?: number
}

const CartCounterButton = (props: CartCounterButtonProps) => {
    const dispatch = useDispatch<AppDispatch>()

    const increseClickHandler = () => {
        props.cartId && dispatch(increaseCartItemQuantity(props.cartId))

    }

    const decreseClickHandler = () => {
        props.cartId && props.quantity && props.quantity > 1 && dispatch(decreaseCartItemQuantity(props.cartId))
    }

    const removeClickHandler = () => {
        props.cartId && dispatch(removeCartItem(props.cartId))
    }

    return <div className={`${style.container}`}>
        <div className={style.trashIcon} onClick={removeClickHandler}>
            <Trash />
        </div>
        <div className={style.counter}>
            <Minus onClick={decreseClickHandler} />
            <div className={style.quantity}>{props.quantity?.toString().padStart(2, '0')}</div>
            <button style={{
                border: 'none',
                background: 'none',
                color: 'inherit',
                padding: 0,
                font: 'inherit',
                cursor: 'pointer'
            }}
                disabled={props.quantity && props.stock ? props.quantity >= props.stock : false}> <Plus onClick={increseClickHandler} /></button>

        </div>
    </div>
}

export default CartCounterButton