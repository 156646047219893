/* eslint-disable no-throw-literal */
import { Dispatch } from 'redux'
import { AddSizesAction, SizeActionTypes } from '.'
import { api, apiErrorHandler, getApiRequestConfig, Response } from '../../api'
import { GetState } from '../reducers'
import { Food, FoodType } from '../reducers/food.reducer'
import { AddLanguagesAction, LanguageActionTypes } from './language.actions'

export enum FoodActionTypes {
    ADD_FOOD = 'ADD_FOOD',
    UPDATE_FOOD = 'UPDATE_FOOD',
    FETCH_FOODS = 'FETCH_FOODS'
}

export interface FetchFoodsAction {
    type: FoodActionTypes.FETCH_FOODS
    data: Food[]
}

export interface AddFoodAction {
    type: FoodActionTypes.ADD_FOOD
    data: Food
}

export interface UpdateFoodAction {
    type: FoodActionTypes.UPDATE_FOOD
    data: Food
}

export interface ImportFoodAction {
    type: FoodActionTypes.FETCH_FOODS
    data: Food[]
}

export type FoodActions = FetchFoodsAction | AddFoodAction | UpdateFoodAction | ImportFoodAction

export const fetchFoods = () => async (dispatch: Dispatch, getState: GetState) => {
    const token = getState().authAdmin?.['auth-token']
    const config = getApiRequestConfig(token)

    return api.get<Response<Food[]>>('foods', config).then(response => {
        if (response.status === 200) {
            dispatch<FetchFoodsAction>({
                type: FoodActionTypes.FETCH_FOODS,
                data: response.data.data
            })

            if (getState().authAdmin) {
                const sizes: string[] = []
                const languages: string[] = []

                response.data.data.forEach(d => {
                    d.prices && d.prices.forEach(p => {
                        sizes.push(p.size)
                    })

                    d.names && d.names.forEach(p => {
                        languages.push(p.language)
                    })
                })

                dispatch<AddSizesAction>({
                    type: SizeActionTypes.ADD_SIZES,
                    data: sizes
                })

                dispatch<AddLanguagesAction>({
                    type: LanguageActionTypes.ADD_LANGUAGES,
                    data: languages
                })
            }

            return Promise.resolve<string>(response.data.message || '')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to fetch'))
    })
}

export interface FoodName {
    name: string
    language: string
}

export interface FoodDescription {
    description: string
    language: string
}

export interface FoodPrice {
    price: number
    offerPrice: number | null
    size: string
}

export interface AddFood {
    names: FoodName[]
    descriptions: FoodDescription[]
    categories: string[]
    image: string
    type: FoodType
    tax: number | null
    stock: number | null
    expiryDate:string | null
    expectedDate:string | null
    productCode:string | null
    manufacture:string | null
    additionalImages: string[]
    offerLabel: string | null
    price: number | null
    offerPrice: number | null
    toppings: string[]
    alternateToppings: string[]
    alternateToppingsCount: number | null
    extraToppings: string[]
    spices: string[]
    prices: FoodPrice[]
}

export const addFood = (input: AddFood) => async (dispatch: Dispatch, getState: GetState) => {
    const token = getState().authAdmin?.['auth-token']
    const config = getApiRequestConfig(token)

    return api.post<Response<Food>>('food', input, config).then(response => {
        if (response.status === 200) {
            dispatch<AddFoodAction>({
                type: FoodActionTypes.ADD_FOOD,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || 'Food added')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable add food'))
    })
}

type UpdateFood = AddFood

export const updateFood = (input: UpdateFood, foodId: string) => async (dispatch: Dispatch, getState: GetState) => {
    const token = getState().authAdmin?.['auth-token']
    const config = getApiRequestConfig(token)

    return api.put<Response<Food>>(`food/${foodId}`, input, config).then(response => {
        if (response.status === 200) {
            dispatch<UpdateFoodAction>({
                type: FoodActionTypes.UPDATE_FOOD,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || 'Food updated')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to update'))
    })
}


export const updateFoodStatus = (status: string, foodId: string|undefined) => async (dispatch: Dispatch, getState: GetState) => {
    const token = getState().authAdmin?.['auth-token']
    const config = getApiRequestConfig(token)

    return api.put<Response<Food>>(`food/update-status/${foodId}`, {status:status}, config).then(response => {
        if (response.status === 200) {
            dispatch<UpdateFoodAction>({
                type: FoodActionTypes.UPDATE_FOOD,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || 'Food updated')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to update'))
    })
}



export const updateFoodPreivewStatus = (status: string, foodId: string|undefined) => async (dispatch: Dispatch, getState: GetState) => {
    const token = getState().authAdmin?.['auth-token']
    const config = getApiRequestConfig(token)

    return api.put<Response<Food>>(`food/update-previewStatus/${foodId}`, {status}, config).then(response => {
        if (response.status === 200) {
            dispatch<UpdateFoodAction>({
                type: FoodActionTypes.UPDATE_FOOD,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || 'Food updated')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to update'))
    })
}

export const updateFoodBanner = (formData: any, foodId: string|undefined|null) => async (dispatch: Dispatch, getState: GetState) => {
    const token = getState().authAdmin?.['auth-token']
    const config = getApiRequestConfig(token)
    return api.put<Response<Food>>(`food/upload-image/${foodId}`, formData, config).then(response => {
        if (response.status === 200) {
            dispatch<UpdateFoodAction>({
                type: FoodActionTypes.UPDATE_FOOD,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || 'Food updated')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to update'))
    })
}

export const importProducts = (formData: any) => async (dispatch: Dispatch, getState: GetState) => {
    const token = getState().authAdmin?.['auth-token']
    const config = getApiRequestConfig(token)
    return api.post<Response<Food[]>>(`food/import-data`, formData, config).then(response => {
        if (response.status === 200) {
            dispatch<ImportFoodAction>({
                type: FoodActionTypes.FETCH_FOODS,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || 'Food updated')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to update'))
    })
}