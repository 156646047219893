interface AccountCircleProps {
    stroke?: string
    strokeWidth?: number
}

const AccountCircle = (props: AccountCircleProps) => {
    return <svg xmlns='http://www.w3.org/2000/svg' width='19' height='19' viewBox='0 0 19 19'>
        <path id='Icon_material-account-circle' data-name='Icon material-account-circle' d='M12,3a9,9,0,1,0,9,9A9,9,0,0,0,12,3Zm0,2.7A2.7,2.7,0,1,1,9.3,8.4,2.7,2.7,0,0,1,12,5.7Zm.011,12.27a6.48,6.48,0,0,1-5.4-2.9C6.638,13.281,10.2,12.81,12,12.81s5.384.471,5.411,2.262a6.48,6.48,0,0,1-5.4,2.9Z' transform='translate(-2.5 -2.5)' fill='none' stroke={props.stroke || '#404040'} strokeLinejoin='round' strokeWidth={props.strokeWidth || 1} />
    </svg>
}

export default AccountCircle