import { TranslationLanguage } from '../../i18n'
import { Action, TranslationLanguageActionTypes } from '../actions'

const initialState: TranslationLanguage = 'english'

const translationLanguageReducer = (state: TranslationLanguage = initialState, action: Action): TranslationLanguage => {
    switch (action.type) {
        case TranslationLanguageActionTypes.SET_TRANSLATION_LANGUAGE: {
            return action.data
        }

        default: {
            return state
        }
    }
}

export default translationLanguageReducer