import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import AppProvider from '../context/AppProvider'
import { persistor, store } from '../redux/store'
import Router from './Router'
import ToastProvider from './ToastProvider'

const App = () => {
    return <Provider store={store}>
        <PersistGate persistor={persistor}>
            <ToastProvider>
                <AppProvider>
                    <Router />
                </AppProvider>
            </ToastProvider>
        </PersistGate>
    </Provider>
}

export default App