import { Action, SizeActionTypes } from '../actions'

const initialState: string[] = []

const sizeReducer = (state: string[] = initialState, action: Action): string[] => {
    const newState = [...state]

    switch (action.type) {
        case SizeActionTypes.ADD_SIZE: {
            return newState.includes(action.data)
                ? newState
                : [
                    ...newState,
                    action.data
                ]
        }

        case SizeActionTypes.ADD_SIZES: {
            const sizes: string[] = [...newState]

            action.data.forEach(s => {
                if (!sizes.includes(s)) {
                    sizes.push(s)
                }
            })

            return sizes
        }

        default: {
            return state
        }
    }
}

export default sizeReducer