import React from 'react'
import style from '../../styles/components/color-picker.module.scss'
import inputStyle from '../../styles/components/input.module.scss'
import { invertColor } from '../../utils'
import EyeDropper from '../svgs/EyeDropper'
import ErrorText from './ErrorText'

interface ColorPickerProps {
    onChange?: React.ChangeEventHandler<HTMLInputElement>
    value?: string
    errorText?: string
    label?: string
    containerClass?: string
    placeholder?: string
}

const ColorPicker = (props: ColorPickerProps) => {
    const isError = props.errorText && props.errorText !== ''

    const [eyeDropFill, setEyeDropFill] = React.useState<string>('#000000')

    React.useEffect(() => {
        try {
            const color = invertColor(props.value || '#ffffff')
            setEyeDropFill(color)
        } catch (error: any) {
            setEyeDropFill('#000000')
        }
    }, [props.value])

    return <div className={`${inputStyle.container} ${props.containerClass || ''}`}>
        {props.label && <label htmlFor={props.label} className={inputStyle.label}>{props.label}</label>}
        <div className={style.mainContent}>
            <div className='hstack w-100'>
                <input
                    className={`${style.input} ${isError ? style.error : ''}`}
                    onChange={props.onChange}
                    value={props.value}
                    placeholder={props.placeholder}
                    min={7}
                    max={7}
                />
                <label className={`${style.picker} ${isError ? style.error : ''}`} htmlFor={props.label} style={{ backgroundColor: props.value }}>
                    <EyeDropper fill={eyeDropFill} />
                </label>
                <input type='color' className={style.colorInput} value={props.value} onChange={props.onChange} id={props.label} />
            </div>

        </div>
        <ErrorText errorText={props.errorText} />
    </div>
}

export default ColorPicker