import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppContext } from '../../context/AppProvider'
import { StoreState } from '../../redux/reducers'
import { Shop } from '../../redux/reducers/shop.reducer'
import { AppDispatch } from '../../redux/store'
import Switch from '../utils/Switch'
import { removeFromPrivateShop, setToPrivateShop } from '../../redux/actions'

export interface ShopPrivateProps {
    onClose?: () => void
    id: string | null
}

const ShopPrivate = (props: ShopPrivateProps) => {
    const dispatch = useDispatch<AppDispatch>()

    const appProvider = React.useContext(AppContext)
    const shop = useSelector<StoreState, Shop | null>(state => state.shops.find(shop => shop.id === props.id) || null)

    const privateClickHandler = () => {
        if (shop) {
            appProvider?.showOverlay?.()
            if (shop.private) {
                dispatch(removeFromPrivateShop(shop.id)).finally(() => {
                    appProvider?.hideOverlay?.()
                })
            } else {
                dispatch(setToPrivateShop(shop.id)).finally(() => {
                    appProvider?.hideOverlay?.()
                })
            }
        }
    }
    return (<div>
        <Switch checked={shop?.private} onClick={privateClickHandler} />
    </div>)
}

export default ShopPrivate