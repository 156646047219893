interface LogOutProps {
    stroke?: string
    strokeWidth?: number
}

const Layers = (props: LogOutProps) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={props.stroke?props.stroke:"#404040"} stroke-width={props.strokeWidth?props.strokeWidth:"2"} stroke-linecap="round" stroke-linejoin="round" className="feather feather-layers"><polygon points="12 2 2 7 12 12 22 7 12 2"></polygon><polyline points="2 17 12 22 22 17"></polyline><polyline points="2 12 12 17 22 12"></polyline>
    </svg>
}

export default Layers