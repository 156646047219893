import { nanoid } from '@reduxjs/toolkit'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DEFAULT_LANGUAGES } from '../../config'
import { AdminContext, AdminContextType } from '../../context/AdminProvider'
import { useT } from '../../i18n'
import { addExtraTopping, addLanguage, addSize, updateExtraTopping } from '../../redux/actions'
import { StoreState } from '../../redux/reducers'
import { ExtraTopping } from '../../redux/reducers/extraTopping.reducer'
import { AppDispatch } from '../../redux/store'
import { toNumber, toString } from '../../utils'
import { ToastType, useToast } from '../ToastProvider'
import ModalWithHeader from '../modals/ModalWithHeader'
import SelectableTileSection from '../sections/SelectableTileSection'
import ResponsiveFormInput from '../utils/ResponsiveFormInput'
import FoodPriceForm from './FoodPriceForm'

interface ExtraToppingFormProps {
    onClose?: () => void
    id?: string | null
}

interface Name {
    language: string
    name: string
    errorText: string
    id: string
}

interface Type {
    language: string
    type: string
    errorText: string
    id: string
}

interface Type {
    language: string
    type: string
    errorText: string
    id: string
}

interface Price {
    price: string
    priceError: string
    size: string
    id: string
}

const ExtraToppingForm = (props: ExtraToppingFormProps) => {
    const dispatch = useDispatch<AppDispatch>()
    const toast = useToast()
    const t = useT()

    const adminContext = React.useContext<AdminContextType | null>(AdminContext)
    const languages = useSelector<StoreState, string[]>(state => state.languages)
    const [sizes, setSizes] = React.useState<string[] | undefined>(adminContext?.selectedSizes)
    const extraTopping = useSelector<StoreState, ExtraTopping | null>(state => props.id ? state.extraToppings.find(t => t.id === props.id) || null : null)

    const [selectedLanguages, setSelectedLanguages] = React.useState<string[]>(DEFAULT_LANGUAGES)

    const [names, setNames] = React.useState<Name[]>([])
    const [types, setTypes] = React.useState<Type[]>([])
    const [prices, setPrices] = React.useState<Price[]>([])
    const [pricesError, setPricesError] = React.useState<string>('')

    const [loading, setLoading] = React.useState<boolean>(false)

    const languageClickHandler = (lng?: string) => {
        if (lng && lng !== '') {
            setSelectedLanguages(prev => {
                return prev.some(p => p === lng)
                    ? prev.length > 1
                        ? prev.filter(p => p !== lng)
                        : prev
                    : [
                        ...prev,
                        lng
                    ]
            })

            setNames(prev => {
                return prev.some(p => p.language === lng)
                    ? prev.length > 1
                        ? prev.filter(p => p.language !== lng)
                        : prev
                    : [
                        ...prev,
                        {
                            language: lng,
                            name: '',
                            errorText: '',
                            id: nanoid()
                        }
                    ]
            })
        }
    }

    const addLanguageClickHandler = (language: string) => {
        dispatch(addLanguage(language))
    }

    const nameChangeHandler = (name: Name, value: string) => {
        setNames(prev => {
            const names = [...prev]
            const index = names.findIndex(p => p.id === name.id)

            if (index > -1) {
                names[index] = {
                    ...names[index],
                    name: value,
                    errorText: ''
                }
            }

            return names
        })
    }

    const typeChangeHandler = (type: Type, value: string) => {
        setTypes(prev => {
            const types = [...prev]
            const index = types.findIndex(p => p.id === type.id)

            if (index > -1) {
                types[index] = {
                    ...types[index],
                    type: value,
                    errorText: ''
                }
            }

            return types
        })
    }

    const priceChangeHandler = (value: string, id?: string) => {
        if (id) {
            setPrices(prev => {
                const prices = [...prev]
                const index = prices.findIndex(p => p.id === id)

                if (index > -1) {
                    prices[index] = {
                        ...prices[index],
                        price: value,
                        priceError: ''
                    }
                }

                return prices
            })
        }
    }

    const addSizeClickHandler = (size: string) => {
        if (size !== '') {
            dispatch(addSize(size))
            setPricesError('')
        }
    }

    const submitHandler: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault()

        let error = false

        names.forEach((name, i) => {
            if (toString(name.name) === '') {
                error = true
                setNames(prev => {
                    const names = [...prev]
                    names[i].errorText = name.language.charAt(0).toUpperCase() + name.language.substring(1) + t('Name required')
                    return names
                })
            }
        })

        types.forEach((type, i) => {
            if (toString(type.type) === '') {
                error = true
                setTypes(prev => {
                    const types = [...prev]
                    types[i].errorText = type.language.charAt(0).toUpperCase() + type.language.substring(1) + t('Type required')
                    return types
                })
            }
        })

        if (prices.length <= 0) {
            error = true
            setPricesError(t('Prices required'))
        } else {
            prices.forEach((price, i) => {
                if (toString(price.size) === '') {
                    error = true
                    setPrices(prev => {
                        const prices = [...prev]
                        prices[i].priceError = t('Size required')
                        return prices
                    })
                }

                if (toString(price.price) === '') {
                    error = true
                    setPrices(prev => {
                        const prices = [...prev]
                        prices[i].priceError = t('Price required')
                        return prices
                    })
                } else if (toNumber(price.price) <= 0) {
                    error = true
                    setPrices(prev => {
                        const prices = [...prev]
                        prices[i].priceError = t('Invalid price given')
                        return prices
                    })
                }
            })
        }

        if (!error) {
            if (props.id && extraTopping) {
                setLoading(true)
                dispatch(updateExtraTopping({
                    names: names.map(n => ({ language: toString(n.language), name: toString(n.name) })),
                    types: types.map(t => ({ language: toString(t.language), type: toString(t.type) })),
                    prices: prices.map(p => ({ price: toNumber(p.price), size: toString(p.size) }))
                }, extraTopping.id)).then(() => {
                    toast(t('Extra topping updated'))
                    props.onClose?.()
                }).catch(text => {
                    toast(text, ToastType.ERROR)
                }).finally(() => {
                    setLoading(false)
                })
            } else {
                setLoading(true)
                dispatch(addExtraTopping({
                    names: names.map(n => ({ language: toString(n.language), name: toString(n.name) })),
                    types: types.map(t => ({ language: toString(t.language), type: toString(t.type) })),
                    prices: prices.map(p => ({ price: toNumber(p.price), size: toString(p.size) }))
                })).then(() => {
                    toast(t('Extra topping added'))
                    props.onClose?.()
                }).catch(text => {
                    toast(text, ToastType.ERROR)
                }).finally(() => {
                    setLoading(false)
                })
            }
        }
    }

    React.useEffect(() => {
        setNames(selectedLanguages.map(lng => {
            return {
                errorText: '',
                id: nanoid(),
                language: lng,
                name: ''
            }
        }))

        setTypes(selectedLanguages.map(lng => {
            return {
                errorText: '',
                id: nanoid(),
                language: lng,
                type: ''
            }
        }))
    }, [])

    React.useEffect(() => {
        if (extraTopping && props.id) {
            setSelectedLanguages(() => {
                const lngs: string[] = []
                extraTopping.names.forEach(n => {
                    if (lngs.indexOf(n.language) === -1) {
                        lngs.push(n.language)
                    }
                })
                return lngs
            })
            setNames(() => {
                const names: Name[] = []

                extraTopping.names.forEach(n => {
                    if (!names.some(p => p.language === n.language)) {
                        names.push({
                            errorText: '',
                            id: n.id,
                            language: n.language,
                            name: n.name
                        })
                    }
                })

                return names
            })
            setTypes(() => {
                const types: Type[] = []

                extraTopping.types.forEach(t => {
                    if (!types.some(p => p.language === t.language)) {
                        types.push({
                            errorText: '',
                            id: t.id,
                            language: t.language,
                            type: t.type
                        })
                    }
                })

                return types
            })
            setPrices(() => {
                const prices: Price[] = []

                extraTopping.prices.forEach(t => {
                    if (!prices.some(p => p.size === t.size)) {
                        prices.push({
                            id: t.id,
                            price: toString(t.price),
                            priceError: '',
                            size: t.size
                        })
                    }
                })

                return prices
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [extraTopping])

    React.useEffect(() => {
        setPrices((prev): any => {
            const newState = [...prev]
            return sizes?.map<Price>(size => {
                const index = newState.findIndex(p => p.size === size)
                return index > -1
                    ? newState[index]
                    : {
                        offerPrice: '',
                        offerPriceError: '',
                        price: '',
                        priceError: '',
                        size: size,
                        id: nanoid()
                    }
            })
        })
    }, [sizes])

    return <ModalWithHeader
        buttonText={t('Save')}
        headerText={props.id ? t('Edit Extra Topping') : t('Add Extra Topping')}
        loading={loading}
        onClose={props.onClose}
        onSave={submitHandler}
        small
    >
        <div className='row mb-4'>
            <div className='col'>
                <SelectableTileSection
                    list={languages}
                    getSelectableTileProps={language => ({
                        key: language,
                        active: selectedLanguages.some(slng => slng === language),
                        onClick: languageClickHandler,
                        value: language,
                        label: language
                    })}
                    title={t('Language')}
                    instantInput={{
                        onAdd: addLanguageClickHandler,
                        label: t('Language')
                    }}
                />
            </div>
        </div>

        <div className='row'>
            <div className='col'>
                <div className='row'>
                    {names.map(n => {
                        return <ResponsiveFormInput
                            responsiveClassName='col-12'
                            containerClass='mb-4'
                            type='text'
                            key={n.id}
                            label={n.language.charAt(0).toUpperCase() + n.language.slice(1) + t('Name')}
                            value={n.name}
                            onChange={e => nameChangeHandler(n, e.target.value)}
                            errorText={n.errorText}
                        />
                    })}
                </div>
            </div>
        </div>

        <div className='row'>
            <div className='col'>
                <div className='row'>
                    {types.map(t => {
                        return <ResponsiveFormInput
                            responsiveClassName='col-12'
                            containerClass='mb-4'
                            type='text'
                            key={t.id}
                            label={t.language.charAt(0).toUpperCase() + t.language.slice(1) + ' type'}
                            value={t.type}
                            onChange={e => typeChangeHandler(t, e.target.value)}
                            errorText={t.errorText}
                        />
                    })}
                </div>
            </div>
        </div>

        <div className='row'>
            <div className='col'>
                {/* <div className='position-relative mb-2'>
                    <div className={formStyle.sectionHeader}>Prices</div>
                    <InstantInputForm
                        label='size'
                        onAdd={addSizeClickHandler}
                    />
                    <ErrorText errorText={pricesError} />
                </div> */}
                <div className='row'>
                    {sizes?.map(size => {
                        const price = prices.find(p => p.size === size)

                        return <FoodPriceForm
                            responsiveClassName='col-sm-6 col-md-4'
                            label={size}
                            price={price?.price || ''}
                            priceError={price?.priceError}
                            onPriceChange={e => priceChangeHandler(e.target.value, price?.id)}
                            key={size}
                        />
                    })}
                </div>
            </div>
        </div>
    </ModalWithHeader>
}

export default ExtraToppingForm