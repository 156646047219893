import { useT } from '../../i18n'
import style from '../../styles/components/added-to-cart.module.scss'
import Button from '../utils/Button'
import Modal from './Modal'

interface AddedToCartProps {
    onClose?: () => void
    item?: string
}

const EventAgreePoint = (props: AddedToCartProps) => {
    const t = useT()
    return <Modal animate>
        <div className={style.eventAgreeContainer}>
            <div className='mb-3'>
                <p className={`my-1 ${style.comments}`} dangerouslySetInnerHTML={{ __html: props.item || '' }} ></p>
            </div>
            <div className={'d-flex align-items-center justify-content-between mb-3'}>
                <Button onClick={props.onClose}>{t("Close")}</Button>
            </div>
        </div>
    </Modal>
}

export default EventAgreePoint