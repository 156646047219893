interface ForkKnife {
    stroke?: string
    strokeWidth?: number
}


const ForkKnife = (props:ForkKnife) => {
    return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="24" width="24"><g id="fork-knife--fork-spoon-knife-food-dine-cook-utensils-eat-restaurant-dining-kitchenware"><path id="Vector" stroke="#000" stroke-linecap="round" stroke-linejoin="round" d="M7.892857142857143 12.535714285714286V0.4642857142857143c3.7142857142857144 1.8571428571428572 4.178571428571429 6.964285714285714 4.178571428571429 8.821428571428571H7.892857142857143" stroke-width="1"></path><path id="Vector_2" stroke="#000" stroke-linecap="round" stroke-linejoin="round" d="M3.25 0.4642857142857143v12.071428571428571" stroke-width="1"></path><path id="Vector_3" stroke="#000" stroke-linecap="round" stroke-linejoin="round" d="M5.571428571428571 0.4642857142857143V2.7857142857142856c0 0.30485 -0.06004142857142857 0.6067192857142858 -0.17670714285714287 0.8883735714285714 -0.11666571428571429 0.28164500000000003 -0.28766214285714287 0.5375592857142858 -0.5032207142857144 0.7531271428571429 -0.21556785714285714 0.21555857142857146 -0.4714821428571429 0.386555 -0.7531271428571429 0.5032207142857144C3.8567192857142856 5.047101428571429 3.55485 5.107142857142858 3.25 5.107142857142858v0c-0.61568 0 -1.2061492857142857 -0.24457642857142858 -1.6415007142857143 -0.6799278571428572C1.1731478571428573 3.991863571428572 0.9285714285714286 3.401394285714286 0.9285714285714286 2.7857142857142856V0.4642857142857143" stroke-width="1"></path></g></svg>
}

export default ForkKnife