import style from '../../styles/components/small-image.module.scss'
import Close from './Close'

interface PickupBoxProps {
    id: string
    addressLine1?: string
    addressLine2?: string
    state?: string
    city?: string
    pincode?: string
    phoneNumber?: string
    landmark?: string
    onClose?: () => void
    onClick?: () => void
}

const PickupBox = (props: PickupBoxProps) => {
    return <div className={style.container} onClick={props.onClick}>
        {props.onClose && <div className={style.close}>
            <Close small onClose={props.onClose} />
        </div>}
        <div>{props.addressLine1}</div>
        <div>{props.addressLine2}</div>
        <div>{props.city}</div>
        <div>{props.state}</div>
        <div>{props.landmark}</div>
        <div>{props.phoneNumber}</div>
    </div>
}

export default PickupBox