const GooglePlayStore = () => {
    return <svg id='google_play_store' data-name='google play store' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' width='28' height='32' viewBox='0 0 28 32'>
        <defs>
            <linearGradient id='linear-gradient' y1='0.5' x2='1' y2='0.5' gradientUnits='objectBoundingBox'>
                <stop offset='0' stopColor='#63be6b' />
                <stop offset='0.506' stopColor='#5bbc6a' />
                <stop offset='1' stopColor='#4ab96a' />
            </linearGradient>
            <linearGradient id='linear-gradient-2' y1='0.5' x2='0.999' y2='0.5' gradientUnits='objectBoundingBox'>
                <stop offset='0' stopColor='#3ec6f2' />
                <stop offset='1' stopColor='#45afe3' />
            </linearGradient>
            <linearGradient id='linear-gradient-3' y1='0.5' x2='1' y2='0.5' gradientUnits='objectBoundingBox'>
                <stop offset='0' stopColor='#faa51a' />
                <stop offset='0.387' stopColor='#fab716' />
                <stop offset='0.741' stopColor='#fac412' />
                <stop offset='1' stopColor='#fac80f' />
            </linearGradient>
            <linearGradient id='linear-gradient-4' y1='0.5' x2='1' y2='0.5' gradientUnits='objectBoundingBox'>
                <stop offset='0' stopColor='#ec3b50' />
                <stop offset='1' stopColor='#e7515b' />
            </linearGradient>
        </defs>
        <path id='Path_138' data-name='Path 138' d='M18.014,9.794,1.033.088A.684.684,0,0,0,.344.1.681.681,0,0,0,0,.687s.007.895.014,2.368l12.37,12.369Z' transform='translate(0 0.001)' fill='url(#linear-gradient)' />
        <path id='Path_139' data-name='Path 139' d='M.2,44.4c.021,5.285.083,18.114.11,24.5L12.577,56.7Z' transform='translate(-0.186 -41.344)' fill='url(#linear-gradient-2)' />
        <path id='Path_140' data-name='Path 140' d='M195.157,147.855l-9.719-5.555-5.638,5.632,6.36,6.36,9-5.245a.688.688,0,0,0,.344-.592A.7.7,0,0,0,195.157,147.855Z' transform='translate(-167.509 -132.57)' fill='url(#linear-gradient-3)' />
        <path id='Path_141' data-name='Path 141' d='M1.7,236.459c.014,2.292.021,3.758.021,3.758a.671.671,0,0,0,.344.592.684.684,0,0,0,.688,0L20.326,230.56l-6.36-6.36Z' transform='translate(-1.583 -208.902)' fill='url(#linear-gradient-4)' />
    </svg>
}

export default GooglePlayStore