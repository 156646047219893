import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useNavigate } from 'react-router-dom'
import { ToastType, useToast } from '../components/ToastProvider'
import AdminAuthenticateForm from '../components/forms/AdminAuthenticateForm'
import AppointmentForm from '../components/forms/AppointmentForm'
import CategoryForm from '../components/forms/CategoryForm'
import EventForm from '../components/forms/EventForm'
import ExtraToppingForm from '../components/forms/ExtraToppingForm'
import FoodForm from '../components/forms/FoodForm'
import PriceUploadForm from '../components/forms/PriceUploadForm'
import SpiceForm from '../components/forms/SpiceForm'
import ToppingForm from '../components/forms/ToppingForm'
import UploadForm from '../components/forms/UploadForm'
import VoucherForm from '../components/forms/VoucherForm'
import Authenticate, { AuthenticateType } from '../components/modals/Authenticate'
import { useT } from '../i18n'
import { addOrder, fetchAppointments, fetchEvents, fetchFoods, fetchShopBookings, fetchShopCategories, fetchShopExtraToppings, fetchShopOrders, fetchShopOtherBookings, fetchShopSpices, fetchShopToppings, fetchShopVouchers, updateOrder } from '../redux/actions'
import { fetchShopAppointmentBookings } from '../redux/actions/appointmentBookings.actions'
import { StoreState } from '../redux/reducers'
import { AuthAdmin } from '../redux/reducers/authAdmin.reducer'
import { Food } from '../redux/reducers/food.reducer'
import { AppDispatch } from '../redux/store'
import Socket from '../socket'

export interface AdminContextType {
    onEditFood?: (foodId: string) => void
    onEditAppointment?: (appointmentId: string) => void
    onAddFood?: () => void
    onAddAppointment?: () => void
    onEditCategory?: (categoryId: string) => void
    onAddCategory?: () => void
    onEditTopping?: (toppingId: string) => void
    onAddTopping?: () => void
    onEditExtraTopping?: (extratoppingId: string) => void
    onAddExtraTopping?: () => void
    onEditSpice?: (spiceId: string) => void
    onAddSpice?: () => void
    onEditVoucher?: (voucherId: string) => void
    onAddVoucher?: () => void
    onEditEvent?: (eventId: string) => void
    onAddEvent?: () => void
    onCamera?: (moduleId: string) => void
    onPriceUpdate?: (foodId: string) => void
    setSelectedSizes?: React.Dispatch<React.SetStateAction<string[]>>
    cardStyleHandler?: () => void
    tableStyleHandler?: () => void
    fetchingOrders?: boolean
    fetchingBookings?: boolean
    fetchingOtherBookings?: boolean
    fetchingAppointmentBookings?: boolean
    fetchingOrderReports?: boolean
    selectedSizes?: string[]
    isCardStyle?: boolean
}

export const AdminContext = React.createContext<AdminContextType | null>(null)

const AdminProvider = () => {
    const t = useT()
    const toast = useToast()
    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()
    const authAdmin = useSelector<StoreState, AuthAdmin | null>(state => state.authAdmin)
    const foods = useSelector<StoreState, Food[] | null>(state => state.foods)

    const isLoggedIn = authAdmin && authAdmin['auth-token'] !== ''

    const [showFoodForm, setShowFoodForm] = React.useState<boolean>(false)
    const [showAppointmentForm, setShowAppointmentForm] = React.useState<boolean>(false)

    const [editFoodId, setEditFoodId] = React.useState<string | null>(null)
    const [editAppointmentId, setEditAppointmentId] = React.useState<string | null>(null)

    const [selectedSizes, setSelectedSizes] = React.useState<string[]>([]);
    const [showEventForm, setShowEventForm] = React.useState<boolean>(false)
    const [editEventId, setEditEventId] = React.useState<string | null>(null)
    const [isCardStyle, setIsCardStyle] = React.useState<boolean>(true)

    const [fetchingOtherBookings, setFetchingOtherBooking] = React.useState<boolean>(false)
    const [fetchingBookings, setFetchingBooking] = React.useState<boolean>(false)
    const [fetchingOrders, setFetchingOrders] = React.useState<boolean>(false)
    const [fetchingAppointmentBookings, setFetchingAppointmentBookings] = React.useState<boolean>(false)
    const [fetchingOrderReports, setFetchingOrderReports] = React.useState<boolean>(false)

    const [showCategoryForm, setShowCategoryForm] = React.useState<boolean>(false)
    const [editCategoryId, setEditCategoryId] = React.useState<string | null>(null)

    const [showToppingForm, setShowToppingForm] = React.useState<boolean>(false)
    const [editToppingId, setEditToppingId] = React.useState<string | null>(null)

    const [showExtraToppingForm, setShowExtraToppingForm] = React.useState<boolean>(false)
    const [editExtraToppingId, setEditExtraToppingId] = React.useState<string | null>(null)

    const [showSpiceForm, setShowSpiceForm] = React.useState<boolean>(false)
    const [editSpiceId, setEditSpiceId] = React.useState<string | null>(null)

    const [showVoucherForm, setShowVoucherForm] = React.useState<boolean>(false)
    const [editVoucherId, setEditVoucherId] = React.useState<string | null>(null)

    const [openCameraModal, setOpenCameraModal] = React.useState(false)
    const [openPriceModal, setOpenPriceModal] = React.useState(false)

    const [authenticateType, setAuthenticateType] = React.useState<AuthenticateType>(AuthenticateType.SIGN_IN)
    const [moduleId, setModuleId] = React.useState<string | null>(null)

    const onAddFood = () => {
        const productArray = foods?.filter(f => f.shopId === authAdmin?.shop.id)

        if (authAdmin?.shop?.plan === 'Starter') {

            if (productArray && productArray?.length < 10) {
                setShowFoodForm(true)
            }
            else {
                toast(t("Starter plan can add only 10 product in a shop"), ToastType.ERROR)
            }
        }
        else {
            setShowFoodForm(true)
        }
    }
    const onAddEvent = () => setShowEventForm(true)
    const onAddAppointment = () => setShowAppointmentForm(true)


    const onEditFood = (foodId: string) => {
        setEditFoodId(foodId)
        setShowFoodForm(true)
    }

    const onEditAppointment = (appointmentId: string) => {
        setEditAppointmentId(appointmentId)
        setShowAppointmentForm(true)
    }

    const onCamera = (moduleId: string) => {
        setModuleId(moduleId)
        setOpenCameraModal(true)
    }

    const closeCamera = () => {
        setOpenCameraModal(false)
        setModuleId(null)
    }

    const onPriceUpdate = (foodId: string) => {
        setEditFoodId(foodId)
        setOpenPriceModal(true)
    }

    const closePriceUpdate = () => {
        setOpenPriceModal(false)
        setEditFoodId(null)
    }

    const closeFoodFormClickHandler = () => {
        setShowFoodForm(false)
        setEditFoodId(null)
    }
    const closeAppointmentFormClickHandler = () => {
        setShowAppointmentForm(false)
        setEditAppointmentId(null)
    }
    const onEditEvent = (eventId: string) => {
        setEditEventId(eventId)
        setShowEventForm(true)
    }

    const closeEventFormClickHandler = () => {
        setShowEventForm(false)
        setEditEventId(null)
    }

    const onAddCategory = () => setShowCategoryForm(true)

    const onEditCategory = (foodId: string) => {
        setEditCategoryId(foodId)
        setShowCategoryForm(true)
    }

    const closeCategoryFormClickHandler = () => {
        setShowCategoryForm(false)
        setEditCategoryId(null)
    }

    const onAddTopping = () => setShowToppingForm(true)

    const onEditTopping = (toppingId: string) => {
        setShowToppingForm(true)
        setEditToppingId(toppingId)
    }

    const closeToppingFormClickHandler = () => {
        setShowToppingForm(false)
        setEditToppingId(null)
    }

    const onAddExtraTopping = () => setShowExtraToppingForm(true)

    const onEditExtraTopping = (extraToppingId: string) => {
        setShowExtraToppingForm(true)
        setEditExtraToppingId(extraToppingId)
    }

    const closeExtraToppingFormClickHandler = () => {
        setShowExtraToppingForm(false)
        setEditExtraToppingId(null)
    }

    const onAddSpice = () => setShowSpiceForm(true)

    const onEditSpice = (spiceId: string) => {
        setShowSpiceForm(true)
        setEditSpiceId(spiceId)
    }

    const closeSpiceFormClickHandler = () => {
        setShowSpiceForm(false)
        setEditSpiceId(null)
    }

    const onAddVoucher = () => setShowVoucherForm(true)

    const onEditVoucher = (voucherId: string) => {
        setShowVoucherForm(true)
        setEditVoucherId(voucherId.toString())
    }

    const closeVoucherFormClickHandler = () => {
        setShowVoucherForm(false)
        setEditVoucherId(null)
    }

    const cardStyleHandler = () => {
        setIsCardStyle(true)
    }

    const tableStyleHandler = () => {
        setIsCardStyle(false)
    }

    React.useEffect(() => {
        if (isLoggedIn) {
            dispatch(fetchFoods())
            dispatch(fetchAppointments())
            dispatch(fetchEvents())
            dispatch(fetchShopCategories())
            dispatch(fetchShopToppings())
            dispatch(fetchShopExtraToppings())
            dispatch(fetchShopSpices())
            dispatch(fetchShopVouchers())

            setFetchingOrders(true)
            dispatch(fetchShopOrders()).finally(() => {
                setFetchingOrders(false)
            })
            setFetchingBooking(true)
            dispatch(fetchShopBookings()).finally(() => {
                setFetchingBooking(false)
            })
            setFetchingOtherBooking(true)
            dispatch(fetchShopOtherBookings()).finally(() => {
                setFetchingOtherBooking(false)
            })
            // setFetchingOrderReports(true)
            // dispatch(fetchShopOrderReports(initDate(6),initDate())).finally(() => {
            //     setFetchingOrderReports(false)
            // })





            setFetchingAppointmentBookings(true)
            dispatch(fetchShopAppointmentBookings()).finally(() => {
                setFetchingAppointmentBookings(false)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn])

    React.useEffect(() => {
        if (isLoggedIn) {
            Socket.init().connect()
        }

        return () => Socket.init().close()
    }, [isLoggedIn])
    console.log(isLoggedIn);

    React.useEffect(() => {
        console.log("Sound 1")
        if (isLoggedIn) {
            console.log("Sound 2")
            Socket.init().onNewOrder(order => {
                console.log("Sound 3")

                dispatch(addOrder(order))
            })
        }

        return () => Socket.init().offNewOrder()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn])

    React.useEffect(() => {
        if (isLoggedIn) Socket.init().onOrderReady(order => {
            dispatch(updateOrder(order))
        })

        return () => Socket.init().offOrderReady()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn])

    return authAdmin
        ? <AdminContext.Provider value={{ fetchingOrders, fetchingBookings, fetchingAppointmentBookings, fetchingOtherBookings, fetchingOrderReports, onAddCategory, onAddExtraTopping, onAddFood, onAddAppointment, onAddEvent, onAddSpice, onAddTopping, onAddVoucher, onEditCategory, onEditExtraTopping, onEditFood, onEditAppointment, onEditEvent, onEditSpice, onEditTopping, onEditVoucher, onCamera, setSelectedSizes, selectedSizes, cardStyleHandler, tableStyleHandler, isCardStyle, onPriceUpdate }}>
            {showFoodForm && <FoodForm
                onClose={closeFoodFormClickHandler}
                id={editFoodId}
            />}
            {showAppointmentForm && <AppointmentForm
                onClose={closeAppointmentFormClickHandler}
                id={editAppointmentId}
            />}
            {openCameraModal && <UploadForm
                onClose={closeCamera}
                id={moduleId}
            />}
            {openPriceModal && <PriceUploadForm
                onClose={closePriceUpdate}
                id={editFoodId}
            />}
            {showEventForm && <EventForm
                onClose={closeEventFormClickHandler}
                id={editEventId}
            />}
            {showCategoryForm && <CategoryForm
                onClose={closeCategoryFormClickHandler}
                id={editCategoryId}
            />}
            {showToppingForm && <ToppingForm
                onClose={closeToppingFormClickHandler}
                id={editToppingId}
            />}
            {showExtraToppingForm && <ExtraToppingForm
                onClose={closeExtraToppingFormClickHandler}
                id={editExtraToppingId}
            />}
            {showSpiceForm && <SpiceForm
                onClose={closeSpiceFormClickHandler}
                id={editSpiceId}
            />}
            {showVoucherForm && <VoucherForm
                onClose={closeVoucherFormClickHandler}
                id={editVoucherId}
            />}
            <Outlet />
        </AdminContext.Provider>
        : <Authenticate authenticateType={authenticateType} onClose={() => navigate("/")} >
            <AdminAuthenticateForm authenticateType={authenticateType} onAuthenticateTypeChange={setAuthenticateType} />
        </Authenticate>
}

export default AdminProvider