import React from 'react'
import style from '../../styles/components/radio.module.scss'
import RadioOff from '../svgs/RadioOff'
import RadioOn from '../svgs/RadioOn'

interface RadioProps {
    checked?: boolean
    children?: React.ReactNode
    onClick?: () => void
    className?: string
    labelClassName?: string
}

const Radio = (props: RadioProps) => {
    return <div className={props.className}>
        <div className={style.radioWrapper} onClick={props.onClick}>
            <div className={style.check}>
                {props.checked ? <RadioOn /> : <RadioOff />}
            </div>
            <div className={`${style.label} ${props.labelClassName || ''}`}>{props.children}</div>
        </div>
    </div>
}

export default Radio