import React, { FC, Fragment, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CheckoutContext, CheckoutContextType } from "../../context/CheckoutProvider";
import { useT } from "../../i18n";
import { StoreState } from "../../redux/reducers";
import { AppointmentCart } from "../../redux/reducers/appointmentCart.reducer";
import { Reservation } from "../../redux/reducers/reservation.reducer";
import { AppDispatch } from "../../redux/store";
import style from '../../styles/pages/checkout.module.scss';
import { formatDate, getAppointmentCheckoutPrice, toCurrencyFormat } from "../../utils";
import { useToast } from "../ToastProvider";
import ErrorText from "../utils/ErrorText";
import FormInput from "../utils/FormInput";
import Radio from "../utils/Radio";
import Select from "../utils/Select";
import SectionWithTitle from "./SectionWithTitle";



interface ReservationProps {
    onClose?: () => void
    id?: string | null
}

const CheckoutAppointmentBookingDetails: FC = (props: ReservationProps) => {
    const dispatch = useDispatch<AppDispatch>()
    const toast = useToast()
    const t = useT()
    const appointmentCart = useSelector<StoreState, AppointmentCart>((state) => state.appointmentCart);
    const checkoutContext = React.useContext<CheckoutContextType | null>(CheckoutContext)
    const Reservation = useSelector<StoreState, Reservation | null>(state => props.id ? state.reservation.find(f => f.id === props.id) || null : null)
    const [slotTime, setSlotTime] = React.useState('');
    const [slots, setSlots] = React.useState([{ fromTime: { time: '09:00', timePeriod: 'AM' }, toTime: { time: '10:00', timePeriod: 'AM' } }, { fromTime: { time: '09:00', timePeriod: 'AM' }, toTime: { time: '10:00', timePeriod: 'AM' } }]);
    const [capacityCount, setCapacityCount] = React.useState({ slot: 0, filledSlot: 0 });
    const [slotCount, setSlotCount] = React.useState(0);

    React.useEffect(() => {
        if (checkoutContext?.personTypes) {
            checkoutContext?.setTotal?.(checkoutContext?.personTypes?.map((x: any) => getAppointmentCheckoutPrice(x) * x.count).reduce((a, b) => a + b, 0))
        }
    }, [checkoutContext?.personTypes])

    const slotSelectHandler = (value: string) => {
        setSlotTime(value);
    }

    const capacityItems = useMemo(() => {
        const items = [];
        const capacities = [];
        let index = 0;
        let perPage = 4;
        if (capacityCount) {
            for (let i = 0; i < Math.ceil(capacityCount.slot / 4); i++) {
                items.push({ index: index, pageCount: perPage });
                index = index + 4;
                perPage = perPage + 4;
            }
            for (let i = 0; i < Math.ceil(capacityCount.slot); i++) {
                capacities.push(i + 1);
            }

        }
        return { items, capacities };
    }, [appointmentCart])

    useEffect(() => {
        if (appointmentCart.item?.type === "detail") {
            const price = appointmentCart.item?.prices.filter((p) => p.size === checkoutContext?.priceSize)[0];
            checkoutContext?.setTotal?.(getAppointmentCheckoutPrice(price))
        }
        else {
            checkoutContext?.setTotal?.(appointmentCart.item?.offerPrice ? appointmentCart.item?.offerPrice : 0)
        }
    }, [checkoutContext])


    return (
        <>

            <SectionWithTitle title={t('Participant Informations')} className='mb-4' withRow>
                {appointmentCart.item?.cultureVouchers && appointmentCart.item?.cultureVouchers.length !== 0 &&
                    <div className="mb-3">
                        <h6>{t("Are you paying with Culture Voucher?")}</h6>

                        <Radio checked={checkoutContext?.cultureVoucher === 'yes'} onClick={() => checkoutContext?.cultureVoucherChangeHandler?.("yes")}>{t("Yes")}</Radio>
                        <div className="d-flex gap-2 pb-2 p-4 pt-2">
                            {checkoutContext?.cultureVoucher === "yes" &&
                                <Fragment>
                                    {appointmentCart.item?.cultureVouchers.map((cv) => (
                                        <Radio checked={checkoutContext?.cultureVoucherSelected === cv} onClick={() => checkoutContext?.cultureVocuherSelectHandler?.(cv)}>{cv}</Radio>
                                    ))}
                                </Fragment>
                            }
                            {checkoutContext?.cultureVoucherSelectedError && <span className={style.requiredLabel}>( {checkoutContext?.cultureVoucherSelectedError} )</span>}
                        </div>
                        <Radio checked={checkoutContext?.cultureVoucher === 'no'} onClick={() => checkoutContext?.cultureVoucherChangeHandler?.("no")}>{t("No")}</Radio>
                    </div>
                }
                <Fragment>
                    <div className='row'>
                        <div className="col-lg-6 col-md-12 col-sm-12 order-2 order-lg-1">
                            <div className='row'>
                                <div className='col-6 mb-3'>
                                    <FormInput
                                        type='date'
                                        label={t("Schedule Date")}
                                        min={formatDate(new Date())}
                                        placeholder={t("Date")}
                                        value={checkoutContext?.appointmentDate}
                                        onChange={checkoutContext?.appointmentDateChangeHandler}
                                        errorText={checkoutContext?.appointmentDateError}
                                    // containerClass='mb-4'
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className='col-4 mb-3'>
                                    <Select
                                        containerClass='mb-3'
                                        label={t("Schedule Time")}
                                        value={checkoutContext?.slotTime}
                                        placeholder={t('Select Time')}
                                        errorText={checkoutContext?.slotTimeError}
                                        options={appointmentCart.item?.slots.map((slot) => { return { text: slot.fromTime.time + ' ' + slot.fromTime.timePeriod + '-' + slot.toTime.time + ' ' + slot.toTime.timePeriod, value: slot.fromTime.time + ' ' + slot.fromTime.timePeriod + '-' + slot.toTime.time + ' ' + slot.toTime.timePeriod } })}
                                        onChange={checkoutContext?.slotTimeChangeHandler}
                                    />
                                </div>
                                {/* <div className='col-4 mb-3'>
                                    <TimeInput
                                        options={times}
                                        value={checkoutContext?.toTime?.time||''}
                                        onChange={checkoutContext?.toTimeChangeHandler}
                                        timePeriod={checkoutContext?.toTime?.timePeriod||''}
                                        timePeriodHandler={(value: string) => checkoutContext?.timePeriodHandler?.(value, 'from')}
                                        label={'To Time'}
                                    />
                                </div> */}
                            </div>
                            <div className={'col-6 mb-3'}>
                                {appointmentCart.item?.type === "detail" && <div className='position-relative'>
                                    <div >{t("Choose Your Appointment")}</div>
                                    <div className='gap-3'>
                                        {appointmentCart.item?.prices.map((p) => (
                                            <Radio checked={checkoutContext?.priceSize === p.size} onClick={() => checkoutContext?.priceSizeClickHandler?.(p)}>{p.size} ({toCurrencyFormat(getAppointmentCheckoutPrice(p))})</Radio>
                                        ))}
                                    </div>
                                    {checkoutContext?.priceSizeError && <ErrorText errorText={checkoutContext?.priceSizeError} />}
                                </div>}
                                {appointmentCart.item?.type === "simple" && <div>
                                    <div >{("Your Appointments")}</div>
                                    <div>{appointmentCart.item.offerPrice}</div>
                                </div>}
                            </div>
                        </div>
                        {/* <div className="col-lg-6 col-md-12 col-sm-12 order-1 order-lg-2">
                            {(appointmentCart.item?.capacity!==null&&appointmentCart.item?.capacity!==0)&&
                                <div className={style.capacityContainer}>
                                    <div className="row">
                                        {capacityItems.items.map((ci)=>(
                                            <div className="col-6">
                                                <div className={`hstack gap-1 ${style.capacityList}`}>
                                                    {capacityItems.capacities.slice(ci.index,ci.pageCount).map((capacity)=>{
                                                            if(capacity<=capacityCount.filledSlot)
                                                            return <div className={style.capacitySelectedBox}>{capacity}</div>
                                                            else if(capacity===capacityCount.filledSlot+1)
                                                            return <div className={style.capacitySelectBox} onClick={selectCapacityHandler}>{capacity}</div>
                                                            else
                                                            return <div className={style.capacityBox}>{capacity}</div>

                                                    })} 
                                                </div>
                                        </div>
                                        ))}
                                    </div>
                                </div>
                            }
                        </div> */}
                    </div>
                </Fragment>

            </SectionWithTitle>
        </>
    )
}

export default CheckoutAppointmentBookingDetails;