import React from 'react'
import { useSelector } from 'react-redux'
import { A11y, Autoplay, Navigation, Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/bundle'
import { Swiper, SwiperSlide } from 'swiper/react'
import { UserContext, UserContextType } from '../../context/UserProvider'
import { StoreState } from '../../redux/reducers'
import { Shop } from '../../redux/reducers/shop.reducer'
import { Voucher } from '../../redux/reducers/voucher.reducer'
import SlideCard from '../cards/SlideCard'
import SlideCardPlaceholder from '../placeholders/SlideCardPlaceholder'

const breakpoints = () => {
    const breakpoints: any = {}

    Array.from(new Array(1500)).forEach((_, i) => {
        breakpoints[i] = {
            slidesPerView: i / 280
        }
    })

    return breakpoints
}

const Slider = () => {
    const userProvider = React.useContext<UserContextType | null>(UserContext)
    const shops = useSelector<StoreState, Shop[]>(state => state.shops)

    return <div className='mb-4'>
        <Swiper
            modules={[A11y, Autoplay, Navigation, Pagination]}
            spaceBetween={15}
            loop={true}
            slidesPerView='auto'
            autoplay={{
                delay: 3000,
                pauseOnMouseEnter: true
            }}
            grabCursor={true}
            navigation={true}
            pagination={{ clickable: true }}
            breakpoints={breakpoints()}
        >
            {userProvider?.fetchingShop
                ? Array.from(new Array(6)).map((_, i) => {
                    return <SwiperSlide key={i}>
                        <SlideCardPlaceholder />
                    </SwiperSlide>
                })
                : shops.filter(shop => shop.featured).map(shop => {
                    return <SwiperSlide key={shop.id}>
                        <SlideCard
                            shopId={shop.id}
                            shopName={shop.name}
                            bannerImage={shop.theme.bannerImage || ''}
                            description={shop.description}
                         />
                    </SwiperSlide>
                })}
        </Swiper>
    </div>
}

export default Slider