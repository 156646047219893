import React from 'react'
import style from '../../styles/components/offer-label.module.scss'
import PercentageBlue from '../svgs/PercentageBlue'

interface OfferLabelProps {
    children?: React.ReactNode
}

const OfferLabel = (props: OfferLabelProps) => {
    return <div className={style.container}>
        <div className={style.content}>
            <div className='hstack gap-2'>
                <PercentageBlue />
                <div className={style.label}>{props.children}</div>
            </div>
        </div>
    </div>
}

export default OfferLabel