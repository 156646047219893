import React from 'react'
import style from '../../styles/components/modal-with-header.module.scss'
import Button from '../utils/Button'
import Close from '../utils/Close'
import Modal from './Modal'

interface ModalWithHeaderProps {
    onClose?: () => void
    onSave?: React.MouseEventHandler<HTMLButtonElement>
    headerText?: string
    children?: React.ReactNode | React.ReactNode[]
    loading?: boolean
    buttonText?: string
    small?: boolean
    large?:boolean
}

const ModalWithHeader = (props: ModalWithHeaderProps) => {
    return <Modal lazy animate>
        <div className={`${style.container} ${typeof props.small === 'boolean' ? (props.small ? style.small : '') : ''} ${typeof props.large === 'boolean' ? (props.large ? style.large : '') : ''}`}>
            <div className='d-flex align-items-center justify-content-between pb-3 px-4 pt-4'>
                <div className={style.headerText}>{props.headerText}</div>
                {<Close onClose={props.onClose} />}
            </div>
            <div className={`px-4 ${style.mainContent}  ${typeof props.large === 'boolean' ? (props.large ? style.large : '') : ''}`}>{props.children}</div>
            {(props.onSave || props.buttonText) && <div className='p-4'>
                <Button type='submit' className={style.saveButton} onClick={props.onSave} loading={props.loading}>{props.buttonText}</Button>
            </div>}
        </div>
    </Modal>
}


export default ModalWithHeader