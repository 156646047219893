/* eslint-disable no-throw-literal */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Response, api, apiErrorHandler, getApiRequestConfig } from '../../api'
import { CheckoutContext, CheckoutContextType } from '../../context/CheckoutProvider'
import { useT } from '../../i18n'
import { fetchShopVouchers } from '../../redux/actions'
import { StoreState } from '../../redux/reducers'
import { AuthUser } from '../../redux/reducers/authUser.reducer'
import { CartItem as CartItemType } from '../../redux/reducers/cart.reducer'
import { Shop } from '../../redux/reducers/shop.reducer'
import { Voucher } from '../../redux/reducers/voucher.reducer'
import { AppDispatch } from '../../redux/store'
import style from '../../styles/components/checkout-shopping-cart.module.scss'
import { toCurrencyFormat, toNumber, toString } from '../../utils'
import CartItem from '../sections/CartItem'
import PercentageBlue from '../svgs/PercentageBlue'
import Button from '../utils/Button'
import FormInput from '../utils/FormInput'
import OfferLabel from '../utils/OfferLabel'

const calculateDiscount = (voucher?: Voucher | null, amount?: number | null) => {
    if (voucher && amount) {
        if (voucher.mode === 'percentage') {
            return toNumber(((voucher.value / 100) * amount).toFixed(2))
        } else if (voucher.mode === 'amount') {
            return voucher.value
        }
    }

    return 0
}

const CheckoutShoppingCart = () => {
    const dispatch = useDispatch<AppDispatch>()
    const checkoutContext = React.useContext<CheckoutContextType | null>(CheckoutContext)
    const t = useT()
    const cartItems = useSelector<StoreState, CartItemType[]>(state => state.cart.items)
    const cartShop = useSelector<StoreState, Shop | null>(state => state.shops.find(s => s.id === state.cart.shopId) || null)

    const [isShopPrivate, setShopPrivate] = useState<boolean>(false)

    const applyVoucherList = useSelector<StoreState, Voucher[] | null>(state => {
        return state.vouchers.filter(s => s.shopId === state.cart.shopId) || null
    })

    const [disableVoucherInput, setDisableVoucherInput] = React.useState<boolean>(false)

    const [showVouchers, setShowVouchers] = React.useState<boolean>(false)
    const authUser = useSelector<StoreState, AuthUser | null>(state => state.authUser)

    const [showApplyVoucherInput, setShowApplyVoucherInput] = React.useState<boolean>(false)
    const [showApplyVoucherButton, setShowApplyVoucherButton] = React.useState<boolean>(true)
    const [laoding, setLaoding] = React.useState<boolean>(false)
    const [voucher, setVoucher] = React.useState<Voucher | null>(null)
    const [voucherCodeError, setVoucherCodeError] = React.useState<string>('')

    const netTotal = React.useMemo(() => {
        return cartItems.length > 0
            ? cartItems.reduce((p, c: any) => {
                return p + (c.amount * c.quantity) / (1 + c.tax / 100)
            }, 0) : 0
    }, [cartItems])

    const totalTax = React.useMemo(() => {
        return cartItems.length > 0
            ? cartItems.reduce((p, c: any) => {
                const amount = c.amount * c.quantity
                const tax = (c.tax && ((amount / (1 + c.tax / 100)) - amount) * -1) || 0
                return p + tax
            }, 0) : 0
    }, [cartItems])

    const isDeliveryExist = cartShop?.delivery.enabled && checkoutContext?.orderType === 'home'
    const deliveryCharge = isDeliveryExist ? (netTotal >= 50 ? 0 : 5) : 0
    let inTotal = netTotal + totalTax
    const discount = calculateDiscount(voucher, inTotal)
    inTotal -= discount
    inTotal += deliveryCharge

    const voucherCodeChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        checkoutContext?.setVoucherCode?.(e.target.value.trim().toUpperCase())
        setVoucherCodeError('')
    }

    const applyVoucherClickHandler = () => {
        setShowVouchers(true)
        setShowApplyVoucherInput(true)
        setShowApplyVoucherButton(false)
        setVoucher(null)
    }

    const voucherClickHandler = (voucher: Voucher) => {
        if (toNumber(netTotal) < toNumber(voucher.minOrderValue)) {
            setVoucherCodeError(`Net total must be greater than or equal to ${voucher.minOrderValue} Euros`)
            setShowApplyVoucherInput(true)
            setShowApplyVoucherButton(false)
            setDisableVoucherInput(true)
        } else {
            checkoutContext?.setVoucherCode?.(voucher.code.trim().toUpperCase())
            setVoucherCodeError('')
            setDisableVoucherInput(false)
            setShowApplyVoucherInput(true)
            setShowApplyVoucherButton(false)
        }

    }

    const cancelClickHandler = () => {
        setShowApplyVoucherInput(false)
        setShowApplyVoucherButton(true)
        setShowVouchers(false)
        setVoucher(null)
        checkoutContext?.setVoucherCode?.('')
    }

    const removeClickHandler = () => {
        setShowApplyVoucherInput(false)
        setShowApplyVoucherButton(true)
        setVoucher(null)
        checkoutContext?.setVoucherCode?.('')
    }

    const applyClickHandler = () => {
        let error = false

        if (toString(checkoutContext?.voucherCode) === '') {
            setVoucherCodeError(t('Please enter voucher code'))
            error = true
        }

        if (!error) {
            const data = {
                code: toString(checkoutContext?.voucherCode).toUpperCase(),
                shopId: cartShop?.id || ''
            }

            const config = getApiRequestConfig(authUser?.['auth-token'])

            setLaoding(true)
            api.post<Response<Voucher>>('voucher/verify', data, config).then(response => {
                if (response.status === 200) {
                    setVoucher(response.data.data)
                    setShowApplyVoucherInput(false)
                    setShowApplyVoucherButton(false)
                    setShowVouchers(false)
                } else {
                    throw { response }
                }
            }).catch(error => {
                setVoucherCodeError(apiErrorHandler(error, t('Unable to apply voucher')))
            }).finally(() => {
                setLaoding(false)
            })
        }
    }
    useEffect(() => {
        dispatch(fetchShopVouchers())
    }, [])

    useEffect(() => {
        checkoutContext?.setVoucherCode?.('')
        setVoucher(null)
        setShowApplyVoucherButton(true)
    }, [netTotal])

    useEffect(() => {
        setShopPrivate(cartShop?.private ? cartShop?.private : false)
    }, [cartShop])

    return <div className={style.container}>
        <div className={style.title}>{t("Shopping cart")}</div>
        {cartItems.map(item => {
            return <CartItem
                name={item.names[0].name}
                quantity={item.quantity}
                id={item.id}
                key={item.id}
                price={item.amount * item.quantity}
                image={item.image}
                staus={item.status}
                foodId={item.foodId}
                shopId={cartShop?.id}
                stock={item.stock ? item.stock : 0}
            />
        })}
        <div className={style.applyVoucher}>
            {showApplyVoucherButton && <Button className={style.applyVoucherButton} onClick={applyVoucherClickHandler}>
                <div className='hstack gap-2'>
                    <PercentageBlue />
                    <div className={style.label}>{t("Apply Voucher")}</div>
                </div>
            </Button>}
            {!isShopPrivate && showVouchers && <div className='border rounded p-2 hstack gap-3 flex-wrap'>
                {applyVoucherList !== null && applyVoucherList.map(voucher => {
                    return <React.Fragment key={voucher.id}>
                        {voucher.title && <span onClick={() => voucherClickHandler(voucher)}><OfferLabel>{voucher.title}</OfferLabel></span>}
                    </React.Fragment>
                })}
            </div>}
            {showApplyVoucherInput && <React.Fragment>
                <FormInput
                    type='text'
                    value={checkoutContext?.voucherCode}
                    onChange={voucherCodeChangeHandler}
                    errorText={voucherCodeError}
                    placeholder={t('Enter voucher code')}
                    containerClass='mb-3'
                    autoFocus
                    disabled={disableVoucherInput}
                />
                <div className='d-flex align-items-center justify-content-center gap-2'>
                    <Button className={style.applyButton} onClick={applyClickHandler} loading={laoding}>{t("Apply")}</Button>
                    <Button className={style.cancelButton} onClick={cancelClickHandler}>{t("Cancel")}</Button>
                </div>
            </React.Fragment>}
            {voucher && <div className={style.codeApplied}>
                <div className={style.left}>{t("Voucher of")} {voucher.mode === 'percentage' ? voucher.value + '%' : voucher.mode === 'amount' ? toCurrencyFormat(voucher.value) : ''} {t("offer applied on the bill!")}</div>
                <div className={style.right} onClick={removeClickHandler}>{t("Remove")}</div>
            </div>}
        </div>
        <div className={`${style.priceSection} mb-1`}>
            <div className={style.strongText}>{t("Net total")}</div>
            <div className={style.strongText}>{toCurrencyFormat(netTotal, cartShop?.currency)}</div>
        </div>
        <div className={`${style.priceSection} mb-${isDeliveryExist ? 1 : 4}`}>
            <div className={style.lightText}>{t("Taxes etc.")}</div>
            <div className={style.lightText}>{toCurrencyFormat(totalTax, cartShop?.currency)}</div>
        </div>
        {isDeliveryExist && <div className={`${style.priceSection} mb-4`}>
            <div className={style.lightText}>{t("Delivery Charge")}</div>
            <div className={style.lightText}>{toCurrencyFormat(deliveryCharge, cartShop?.currency)}</div>
        </div>}
        {voucher && <div className={`${style.priceSection} mb-4`}>
            <div className={style.strongText}>{t("Discount")}</div>
            <div className={style.strongText}>-{toCurrencyFormat(discount, cartShop?.currency)}</div>
        </div>}

        <div className={style.horizontalLine} />

        <div className={`${style.priceSection} mt-4`}>
            <div className={style.inTotal}>{t("In total")} (inc.VAT)</div>
            <div className={style.inTotal}>{toCurrencyFormat(inTotal, cartShop?.currency)}</div>
        </div>
    </div>
}

export default CheckoutShoppingCart