interface ChevronRightProps {
    stroke?: string
}

const ChevronRight = (props: ChevronRightProps) => {
    return <svg xmlns='http://www.w3.org/2000/svg' width='7.409' height='14.817' viewBox='0 0 7.409 14.817'>
        <path id='Path_13' data-name='Path 13' d='M0,5,6.076,0,12,5' transform='translate(6.409 1.408) rotate(90)' fill='none' stroke={props.stroke || '#dadada'} strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' />
    </svg>
}

export default ChevronRight