import { Action } from '../actions'
import { EventCartActionTypes } from '../actions/eventCart.actions'


export interface CartItemName {
    name: string
    language: string
}

export interface EventCartItemPrice {
    id: string
    size: string
    price: number
    offerPrice: number
}

export interface EventCartItem {
    eventId: string
    names: CartItemName[]
    eventType: string
    foodPreferences: string[]
    volunteers: string[]
    cultureVouchers: string[]
    prices: EventCartItemPrice[]
    category: string
    eventRule: string
    withoutRegistration: string
}

export interface EventCart {
    shopId: string | null
    item: EventCartItem | null
}

const initialState: EventCart = {
    item: null,
    shopId: null
}

const eventCartReducer = (state: EventCart = initialState, action: Action): EventCart => {
    switch (action.type) {
        case EventCartActionTypes.ADD_EVENT_CART_ITEM: {
            return {
                ...state,
                item: action.data.item,
                shopId: action.data.shopId
            }
        }

        case EventCartActionTypes.REMOVE_EVENT_CART_ITEM: {
            return {
                item: null,
                shopId: null
            }
        }

        default: {
            return state
        }
    }
}

export default eventCartReducer