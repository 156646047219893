import style from '../../styles/components/selectable-tile.module.scss'
import Edit from '../svgs/Edit'

export interface SelectableTileProps<V = any> {
    onClick?: (value?: V) => void
    value?: V
    label?: string
    active?: boolean
    onEdit?: () => void
    error?: boolean
}

const SelectableTile = (props: SelectableTileProps) => {
    const clickHandler = () => {
        props.onClick?.(props.value)
    }

    return <div className={`${style.container} ${props.error ? style.error : ''}`}>
        <div className={`${style.clickable} ${props.active ? style.active : ''}`} onClick={clickHandler}>{props.label}</div>
        {props.onEdit && <div className={style.editButton} onClick={props.onEdit}>
            <Edit />
        </div>}
    </div>
}

export default SelectableTile