import { Action, OrderActionTypes } from '../actions'
import { OtherPayments, Pickup } from './shop.reducer'

export type OrderType = 'home' | 'pick_up'
export type PaymentMode = 'card' | 'upi' | 'cash' | 'paytrail' | 'bank-transfer' | 'free'
export type PaymentStatus = 'pending' | 'paid' | 'failed' | 'orderGrouping'
export type OrderStatus = 'ordered' | 'processing' | 'ready' | 'delivered'

export interface OrderItemExtraTopping {
    name: string
    price: number
}

export interface OrderItem {
    id: string
    title: string
    manufacturer: string | null
    manufacturerCode: string | null
    productGroup: string | null
    productCode: string | null
    productDescription: string
    priceVat: string | null
    day: string | null
    date: Date | null
    stock: string | null
    webAddress: string | null
    eanKoodi: string | null
    weight: string | null
    size: string | null
    price: number
    defaultToppings: string[]
    alternateToppings: string[]
    extraToppings: OrderItemExtraTopping[]
    extraToppingsPrice: number | null
    spices: string[]
    comment: string | null
    quantity: number
    tax: number | null
    discount: number | null
    createdAt: string
    updatedAt: string
}

export interface Buyer {
    firstName: string
    lastName: string
    email: string
    phoneNumber: string
    address: {
        addressLine1: string
        addressLine2: string
        city: string
        pincode: number
        state: string
        landmark: string | null
    }
}

export interface Order {
    id: string
    orderNumber: string
    orderDate: string
    deliveryDate: string
    orderType: OrderType
    shopId: { id: string, otherPayments: OtherPayments[] }
    buyer: Buyer
    userId: string
    totalAmount: number
    totalDiscount: number | null
    deliveryCharge: number | null
    deliveredAt: string | null
    totalTax: number | null
    billAmount: number
    paymentMode: PaymentMode
    paymentStatus: PaymentStatus
    paidAt: string | null
    orderStatus: OrderStatus
    processTime: number | null
    processStartedAt: string | null
    additionalInfo: string | null
    orderItems: OrderItem[]
    pickup: Pickup
    createdAt: string
    updatedAt: string
}

const initialState: Order[] = []

const orderReducer = (state: Order[] = initialState, action: Action): Order[] => {
    switch (action.type) {
        case OrderActionTypes.ADD_ORDER: {
            return [
                ...state,
                action.data
            ]
        }

        case OrderActionTypes.FETCH_ORDERS: {
            return action.data
        }

        case OrderActionTypes.REPLACE_ORDER: {
            const newState = [...state]
            const index = newState.findIndex(s => s.id === action.data.id)

            if (index > -1) {
                newState[index] = action.data
            }

            return newState
        }

        default: {
            return state
        }
    }
}

export default orderReducer