const audios = {
    school: require('./audios/school.mp3')
}

const images = {
    logo: require('./images/logo.png'),
    registerToSellAtVshop: require('./images/register-to-sell-at-vshop.png'),
    paytrailWeb: require('./images/paytrail-web.png'),
    paytrailMobile: require('./images/paytrail-mobile.png'),
    onBoardImage1:require('./images/onboard-image-1.png'),
    onBoardImage2:require('./images/onboard-image-2.png'),
    shoppingBag:require('./images/shopping-bag.png'),
    priceTag:require('./images/price-tag.png'),
    contactUs:require('./images/contact-us.jpg'),
    thumbnail:require('./images/thumnail.jpg')
}

const json = {
    confetti: require('./json/confetti.json'),
    done: require('./json/done.json'),
    empty: require('./json/empty.json'),
    loading: require('./json/loading.json'),
    notFound: require('./json/not-found.json'),
    shoppingLoader: require('./json/shopping-loader.json')
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    audios,
    images,
    json
}