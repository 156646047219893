import { Action, UserActionsType } from "../actions"
import { ReferralCode } from "./authUser.reducer"

export interface User {
    id?: string
    firstName: string
    emailConcent?:string
    lastName: string
    email: string
    countryCode:string
    phoneNumber: string
    referral?:ReferralCode
    address: {
        addressLine1: string
        addressLine2: string
        city: string
        landmark: string
        pincode: string
        state: string
    }
}

const initialState: User[] = []

const userReducer = (state: User[] = initialState, action: Action): User[] => {
    const newState = [...state]
    switch (action.type) {

        case UserActionsType.FETCH_USER: {
            return action.data
        }

        case UserActionsType.DELETE_USER: {
            return state.filter(s => s.id !== action.data)
        }
        default: {
            return state
        }
    }

}

export default userReducer