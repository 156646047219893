import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CATEGORY_OPTIONS, CURRENCY, PLAN_OPTIONS } from '../../config'
import { useT } from '../../i18n'
import { AdminCategory, fetchShops, registerAdmin } from '../../redux/actions'
import { StoreState } from '../../redux/reducers'
import { Division } from '../../redux/reducers/division.reducer'
import { AppDispatch } from '../../redux/store'
import style1 from '../../styles/components/added-to-cart.module.scss'
import style2 from '../../styles/components/shop-details.module.scss'
import { testNumber, toString } from '../../utils'
import { ToastType, useToast } from '../ToastProvider'
import ModalWithHeader from '../modals/ModalWithHeader'
import PlanInformation from '../modals/Plan'
import Eye from '../svgs/Eye'
import EyeOff from '../svgs/EyeOff'
import Info from '../svgs/Info'
import FormInput from '../utils/FormInput'
import FormInputs from '../utils/FormInputs'
import Option from '../utils/Option'
import Select from '../utils/Select'

interface ShopFormProps {
    onClose?: () => void
    id?: string | null
    divisionId?: string | null
}

const ShopForm = (props: ShopFormProps) => {
    const dispatch = useDispatch<AppDispatch>()
    const toast = useToast()
    const t = useT()


    const DivisionList = useSelector<StoreState, Array<Division>>(state => (state.division))

    const [name, setName] = React.useState<string>('')
    const [nameError, setNameError] = React.useState<string>('')
    const [divisionId, setDivisionId] = React.useState<string>(props.divisionId ? props.divisionId : '')
    const [divisionIdError, setDivisionIdError] = React.useState<string>('')
    const [username, setUsername] = React.useState<string>('')
    const [usernameError, setUsernameError] = React.useState<string>('')
    const [email, setEmail] = React.useState<string>('')
    const [emailError, setEmailError] = React.useState<string>('')
    const [password, setPassword] = React.useState<string>('')
    const [passwordError, setPasswordError] = React.useState<string>('')
    const [shopName, setShopName] = React.useState<string>('')
    const [shopNameError, setShopNameError] = React.useState<string>('')
    const [category, setCategory] = React.useState<string>('')
    const [categoryError, setCategoryError] = React.useState<string>('')
    const [addressLine1, setAddressLine1] = React.useState<string>('')
    const [addressLine1Error, setAddressLine1Error] = React.useState<string>('')
    const [addressLine2, setAddressLine2] = React.useState<string>('')
    const [addressLine2Error, setAddressLine2Error] = React.useState<string>('')
    const [pincode, setPincode] = React.useState<string>('')
    const [pincodeError, setPincodeError] = React.useState<string>('')
    const [city, setCity] = React.useState<string>('')
    const [cityError, setCityError] = React.useState<string>('')
    const [description, setDescription] = React.useState<string>('')
    const [descriptionError, setDescriptionError] = React.useState<string>('')
    const [currency, setCurrency] = React.useState<string>('')
    const [currencyError, setCurrencyError] = React.useState<string>('')
    const [file, setFile] = React.useState<any>('')
    const [state, setState] = React.useState<string>()
    const [stateError, setStateError] = React.useState<string>('')
    const [plan, setPlan] = React.useState<string>('')
    const [planError, setPlanError] = React.useState<string>('')

    const [showShopInformation, setShowShopInformation] = React.useState<boolean>(false)

    const [showPassword, setShowPassword] = React.useState<boolean>(false)
    const [loading, setLoading] = React.useState<boolean>(false)

    const nameChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setName(e.target.value)
        setNameError('')
    }

    const divisionIdChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setDivisionId(e.target.value)
        setDivisionIdError('')
    }

    const usernameChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setUsername(e.target.value)
        setUsernameError('')
    }

    const emailChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setEmail(e.target.value)
        setEmailError('')
    }

    const passwordChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setPassword(e.target.value)
        setPasswordError('')
    }

    const shopNameChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setShopName(e.target.value)
        setShopNameError('')
    }

    const categoryChangeHandler: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
        setCategory(e.target.value)
        setCategoryError('')
    }

    const currencyChangeHandler: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
        setCurrency(e.target.value)
        setCurrencyError('')
    }

    const addressLine1ChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setAddressLine1(e.target.value)
        setAddressLine1Error('')
    }

    const addressLine2ChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setAddressLine2(e.target.value)
        setAddressLine2Error('')
    }

    const pincodeChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        if ((!testNumber(e.target.value) && e.target.value))
            return
        else if (e.target.value.length >= 7)
            return

        setPincode(e.target.value)
        setPincodeError('')
    }

    const cityChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const value = e.target.value;
        const regex = /^[A-Za-z]*$/;

        if (regex.test(value)) {
            setCity(value);
            setCityError('');
        } else {
            setCityError('Please enter only alphabets');
        }
    }

    const descriptionChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setDescription(e.target.value)
        setDescriptionError('')
    }

    const stateChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const value = e.target.value;
        const regex = /^[A-Za-z]*$/;

        if (regex.test(value)) {
            setState(value);
            setStateError('');
        } else {
            setStateError('Please enter only alphabets');
        }
    }

    const planChangeHandler: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
        setPlan(e.target.value)
        setPlanError('')
    }

    const fileUploadHandler: React.ChangeEventHandler<HTMLInputElement | HTMLFormElement> = (e) => {
        setFile(e.target.files[0]);
    }
    const submitHandler: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault()

        let error = false

        if (toString(name) === '') {
            setNameError(t('Name required'))
            error = true
        }

        if (toString(divisionId) === '') {
            setDivisionIdError(t('Division required'))
            error = true
        }

        if (toString(email) === '') {
            setEmailError(t('Email required'))
            error = true
        }

        if (toString(shopName) === '') {
            setShopNameError(t('Shop name required'))
            error = true
        }

        if (toString(category) === '') {
            setCategoryError(t('Category required'))
            error = true
        }

        if (toString(pincode) === '') {
            setPincodeError(t('Pincode required'))
            error = true
        }

        if (toString(city) === '') {
            setCityError(t('City required'))
            error = true
        }

        if (toString(state) === '') {
            setStateError(t('State required'))
            error = true
        }

        if (toString(description) === '') {
            setDescriptionError(t('Description required'))
            error = true
        }

        if (toString(currency) === '') {
            setCurrencyError(t('Currency required'))
            error = true
        }

        if (toString(addressLine1) === '') {
            setAddressLine1Error(t('Address line 1 required'))
            error = true
        }

        if (toString(addressLine2) === '') {
            setAddressLine2Error(t('Address line 2 required'))
            error = true
        }

        if (toString(username) === '') {
            setUsernameError(t('Username required'))
            error = true
        }

        if (toString(plan) === '') {
            setPlanError(t('Plan required'))
            error = true
        }

        if (password === '') {
            setPasswordError(t('Password required'))
            error = true
        } else if (password.length < 8) {
            setPasswordError(t('Password is too short - should be 8 chars minimum'))
            error = true
        }
        // else if (password.length > 15) {
        //     setPasswordError('Password is too long - should be 15 chars maximum')
        //     error = true
        // } else if (!testPassword(password)) {
        //     setPasswordError('Password can only contain alphanumeric characters, underscore and first character must be a letter')
        //     error = true
        // }

        if (!error) {
            setLoading(true)
            const formData: any = new FormData()
            formData.append("uploadFile", file);
            formData.append("category", toString(category) as AdminCategory);
            formData.append("divisionId", toString(divisionId));
            formData.append("city", toString(city))
            formData.append("state", toString(state));
            formData.append("email", toString(email).toLowerCase());
            formData.append("name", toString(name));
            formData.append("password", password);
            formData.append("shopName", toString(shopName));
            formData.append("username", toString(username).toLowerCase());
            formData.append("addressLine1", toString(addressLine1));
            formData.append("addressLine2", toString(addressLine2));
            formData.append("landmark", '');
            formData.append("pincode", toString(pincode));
            formData.append("description", toString(description));
            formData.append("currency", toString(currency));
            formData.append("plan", toString(plan));

            dispatch(registerAdmin(formData, true)).then(() => {
                props?.onClose?.();
                toast(t('Shop added'));

                // dispatch(registerAdmin({
                //     category: toString(category) as AdminCategory,
                //     divisionId: toString(divisionId),
                //     city: toString(city),
                //     state: toString(state),
                //     email: toString(email).toLowerCase(),
                //     name: toString(name),
                //     password: password,
                //     shopName: toString(shopName),
                //     username: toString(username).toLowerCase(),
                //     addressLine1: toString(addressLine1),
                //     addressLine2: toString(addressLine2),
                //     landmark: '',
                //     pincode: toNumber(pincode),
                //     description: toString(description),
                // }, true)).then(() => {
                // toast('Shop added')
                dispatch(fetchShops())

            }).catch(text => {
                toast(text, ToastType.ERROR)
            }).finally(() => {
                setLoading(false)
            })
        }
    }


    return <>

        <ModalWithHeader
            buttonText={t('Save')}
            headerText={t('Add Shop')}
            loading={loading}
            onClose={props.onClose}
            onSave={submitHandler}
        >
            <div className='row justify-content-center'>
                <div className='col-md-6'>
                    {!props.divisionId && <FormInputs
                        type='select'
                        label={t('Division Name')}
                        placeholder={t('Division Name')}
                        errorText={divisionIdError}
                        value={divisionId}
                        onChange={divisionIdChangeHandler}
                        containerClass='mb-4'
                    >
                        <option value="">{t("Select")}</option>
                        {DivisionList.map((a, i) => {
                            return <Option value={a.id} key={i}>{a.name}</Option>
                        })}
                    </FormInputs>}

                    <FormInput
                        type='text'
                        label={t('Name')}
                        placeholder={t('Name')}
                        errorText={nameError}
                        value={name}
                        onChange={nameChangeHandler}
                        containerClass='mb-4'
                    />

                    <FormInput
                        type='email'
                        label={t('Email')}
                        placeholder='Email'
                        errorText={emailError}
                        value={email}
                        onChange={emailChangeHandler}
                        containerClass='mb-4'
                    />

                    <FormInput
                        type='text'
                        label={t('Username')}
                        placeholder={t('Username')}
                        errorText={usernameError}
                        value={username}
                        onChange={usernameChangeHandler}
                        containerClass='mb-4'
                    />

                    <FormInput
                        type={showPassword ? 'text' : 'password'}
                        label={t('Password')}
                        placeholder={t('Password')}
                        errorText={passwordError}
                        value={password}
                        onChange={passwordChangeHandler}
                        containerClass='mb-4'
                        rightRenderer={showPassword ? <EyeOff /> : <Eye />}
                        onRightRendererClick={() => setShowPassword(!showPassword)}
                    />

                    <FormInput
                        type='text'
                        label={t('Shop Name')}
                        placeholder={t('Shop Name')}
                        errorText={shopNameError}
                        value={shopName}
                        onChange={shopNameChangeHandler}
                        containerClass='mb-4'
                    />

                    <Select
                        options={CATEGORY_OPTIONS}
                        label={t('Category')}
                        placeholder={t('Select category')}
                        errorText={categoryError}
                        value={category}
                        onChange={categoryChangeHandler}
                        containerClass='mb-4'
                    />
                    {showShopInformation && <PlanInformation planType={plan} onClose={() => setShowShopInformation(false)} showbutton={false} shopId='' />}

                    <div className='hstack gap-2'>
                        <div className={style1.label}>{t("Plan")}</div>
                        <div className={style2.infoIcon} onClick={() => setShowShopInformation(true)}><Info /></div>
                    </div>
                    <Select
                        options={PLAN_OPTIONS}
                        // label={('Plan')}
                        placeholder={t('Select plan')}
                        // required={true}
                        errorText={planError}
                        value={plan}
                        onChange={planChangeHandler}
                        containerClass='mb-4'
                    />
                </div>

                <div className='col-md-6'>

                    <FormInput
                        type='text'
                        label={t('Address Line 1')}
                        placeholder={t('Address Line 1')}
                        errorText={addressLine1Error}
                        value={addressLine1}
                        onChange={addressLine1ChangeHandler}
                        containerClass='mb-4'
                    />

                    <FormInput
                        type='text'
                        label={t('Address Line 2')}
                        placeholder={t('Address Line 2')}
                        errorText={addressLine2Error}
                        value={addressLine2}
                        onChange={addressLine2ChangeHandler}
                        containerClass='mb-4'
                    />

                    <FormInput
                        type='text'
                        label={t('City')}
                        placeholder={t('City')}
                        errorText={cityError}
                        value={city}
                        onChange={cityChangeHandler}
                        containerClass='mb-4'
                    />

                    <FormInput
                        type='text'
                        label={t('State')}
                        placeholder={t('State')}
                        errorText={stateError}
                        value={state}
                        onChange={stateChangeHandler}
                        containerClass='mb-4'
                    />

                    <FormInput
                        type='text'
                        label={t('Pincode')}
                        placeholder={t('Pincode')}
                        errorText={pincodeError}
                        value={pincode}
                        onChange={pincodeChangeHandler}
                        containerClass='mb-4'
                    />

                    <div>
                        <FormInput
                            type='text'
                            label={t('Description')}
                            placeholder={t('Description')}
                            errorText={descriptionError}
                            value={description}
                            onChange={descriptionChangeHandler}
                            containerClass='mb-4'
                        />
                    </div>

                    <Select
                        options={CURRENCY}
                        label={t('Currency')}
                        placeholder={t('Select currency')}
                        errorText={currencyError}
                        value={currency}
                        onChange={currencyChangeHandler}
                        containerClass='mb-4'
                    />
                </div>

                <div>
                    <FormInput
                        type='file'
                        label={t('Import Product (Optional) .zip')}
                        // type='text'
                        // label='Description'
                        // placeholder='Description'
                        // errorText={descriptionError}
                        value={file ? file.filename : ''}
                        onChange={fileUploadHandler}
                        containerClass='mb-4'
                    />
                </div>
            </div>
        </ModalWithHeader></>
}

export default ShopForm