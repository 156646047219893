import { Action, CategoryActionTypes } from '../actions'

export interface CategoryName {
    language: string
    name: string
    createdAt: string
    updatedAt: string
    id: string
}

export interface Category {
    names: CategoryName[]
    offerLabel: string | null
    seqNo: number
    shopId: string
    createdAt: string
    updatedAt: string
    id: string
}

const initialState: Category[] = []

const categoryReducer = (state: Category[] = initialState, action: Action): Category[] => {
    switch (action.type) {
        case CategoryActionTypes.ADD_CATEGORY: {
            return [
                ...state,
                action.data
            ]
        }

        case CategoryActionTypes.UPDATE_CATEGORY: {
            const categories = [...state]
            const index = categories.findIndex(c => c.id === action.data.id)

            if (index > -1) {
                categories[index] = {
                    ...action.data
                }
            }

            return categories
        }

        case CategoryActionTypes.REORDER_CATEGORY: {
            return action.data
        }

        case CategoryActionTypes.FETCH_SHOP_CATEGORIES: {
            return action.data
        }

        default: {
            return state
        }
    }
}

export default categoryReducer