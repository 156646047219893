/* eslint-disable no-throw-literal */
import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { api, Response } from '../../api'
import { AppContext, AppContextType } from '../../context/AppProvider'
import { UserContext, UserContextType } from '../../context/UserProvider'
import { useT } from '../../i18n'
import { StoreState } from '../../redux/reducers'
import { CartStatus } from '../../redux/reducers/cart.reducer'
import { Food } from '../../redux/reducers/food.reducer'
import { Shop } from '../../redux/reducers/shop.reducer'
import style from '../../styles/components/cart-item.module.scss'
import { toCurrencyFormat } from '../../utils'
import ChevronRightDark from '../svgs/ChevronRightDark'
import { useToast } from '../ToastProvider'
import CartCounterButton from './CartCounterButton'

interface CartItemProps {
    name?: string
    id?: string
    foodId?: string
    quantity?: number
    size?: string | null
    price?: number
    image?: string
    staus?: CartStatus
    shopId?: string
    stock?: number
}

const CartItem = (props: CartItemProps) => {
    const { shopId } = useParams()
    const appContext = React.useContext<AppContextType | null>(AppContext)
    const userContext = React.useContext<UserContextType | null>(UserContext)
    const shop = useSelector<StoreState, Shop | null>(state => state.shops.find(s => s.id === shopId || props.shopId) || null)

    const toast = useToast()
    const t = useT()

    const editOrderClickHandler = () => {
        if (props.foodId) {
            appContext?.showOverlay?.()
            api.get<Response<Food>>(`food/${props.foodId}`).then(response => {
                if (response.status === 200) {
                    userContext?.openCartModal?.(response.data.data, props.id)
                } else {
                    throw { response }
                }
            }).catch(() => {
                toast(t('Something went wrong!'))
            }).finally(() => {
                appContext?.hideOverlay?.()
            })
        }
    }

    return <div className='row mb-3'>
        <div className='col-sm-6 mb-2'>
            <div className='d-flex gap-3'>
                <div className={style.imageWrapper}>
                    <img src={props.image} alt='Product' />
                </div>
                <div className={style.mainContent}>
                    <div className={style.name}>{props.name} {props.size ? `(${props.size})` : ''} </div>
                    <div className='hstack'>
                        <div className={style.editOrder} onClick={editOrderClickHandler}>
                            <span className={style.label}>{t("Edit order")}</span>
                            <ChevronRightDark />
                        </div>
                    </div>
                    <div className={`${style.status} ${props.staus === 'available' ? style.available : style.notAvailable}`}>{props.staus}</div>
                    <div className={style.price}>{toCurrencyFormat(props.price, shop?.currency)}</div>
                </div>
            </div>
        </div>
        <div className='col-sm-6'>
            <CartCounterButton
                cartId={props.id}
                quantity={props.quantity}
                stock={props.stock}
            />
        </div>
    </div>
}

export default CartItem