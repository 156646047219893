import { t } from 'i18next'
import React from 'react'
import * as Feather from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { api, getApiRequestConfig } from '../../api'
import assets from '../../assets'
import { syncCartWithDB } from '../../redux/actions'
import { StoreState } from '../../redux/reducers'
import { AuthUser } from '../../redux/reducers/authUser.reducer'
import { Cart } from '../../redux/reducers/cart.reducer'
import { Shop } from '../../redux/reducers/shop.reducer'
import { AppDispatch } from '../../redux/store'
import style from '../../styles/components/shopping-cart.module.scss'
import tooltip from '../../styles/components/tooltip.module.scss'
import { toCurrencyFormat } from '../../utils'
import { ToastType, useToast } from '../ToastProvider'
import Lottie from '../lottie/Lottie'
import CartItem from '../sections/CartItem'
import Button from '../utils/Button'
import Close from '../utils/Close'
import Tooltip from '../utils/Tooltip'

interface ShoppingCartProps {
    onClose?: () => void
}

const ShoppingCart = (props: ShoppingCartProps) => {
    const toast = useToast()

    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()
    const { shopId } = useParams()
    const authUser = useSelector<StoreState, AuthUser | null>(state => state.authUser)
    const cart = useSelector<StoreState, Cart>(state => state.cart)
    const cartItems = cart.items
    const shop = useSelector<StoreState, Shop | null>(state => state.shops.find(s => s.id === shopId) || null)
    console.log(cartItems);

    const [isSlideIn, setIsSlideIn] = React.useState<boolean>(false)
    const [loading, setLoading] = React.useState<boolean>(true)
    const [mounted, setMounted] = React.useState<boolean>(false)
    const [canCheckout, setCanCheckout] = React.useState<boolean>(false)

    const netTotal = React.useMemo(() => {
        return cartItems.length > 0
            ? cartItems.reduce((p, c: any) => {
                return p + (c.amount * c.quantity) / (1 + c.tax / 100)
            }, 0) : 0
    }, [cartItems])

    const totalTax = React.useMemo(() => {
        return cartItems.length > 0
            ? cartItems.reduce((p, c: any) => {
                const amount = c.amount * c.quantity
                const tax = (c.tax && ((amount / (1 + c.tax / 100)) - amount) * -1) || 0
                return (p + tax)
            }, 0) : 0
    }, [cartItems])

    const closeClickHandler = () => {
        setIsSlideIn(false)
        setTimeout(() => {
            props.onClose?.()
        }, 300)
    }

    const shareClickHandler = () => {
        const config = getApiRequestConfig(authUser?.['auth-token'])

        api.post('cart', cart, config).then(response => {
            if (response.status === 200 && response.data.data) {
                const result = response.data.data
                let hostName = window.location.host;
                navigator.clipboard.writeText(`${hostName}/cart/${result.shopId}/${result.id}`)
                toast("Link copied", ToastType.SUCCESS)

            } else {
                throw { response }
            }
        }).catch(error => {
            toast(error.response.data.error, ToastType.ERROR)
        })
    }

    const checkoutClickHandler = () => {
        let error: string[] = []
        cartItems.forEach((c) => {
            if (shop?.category === "Product") {
                if (c.quantity <= (c.stock ? parseInt(c.stock.toString()) : 0)) {

                } else {
                    error.push(c.names[0].name + ` - Selected qty : ${c.quantity} Available qty : ${c.stock} Please reduce the qty to proceed.`)
                }
            }
        })
        if (error.length === 0) {
            props.onClose?.()
            navigate('/checkout')
        }
        else {
            error.map(e => {
                return toast(e, ToastType.ERROR)
            })
        }
    }

    React.useEffect(() => {
        setIsSlideIn(true)
        const bodyElement = document.querySelector('body')

        if (!bodyElement) {
            return
        }

        bodyElement.classList.add('overflow-hidden')

        return () => {
            bodyElement.classList.remove('overflow-hidden')
        }
    }, [])

    React.useEffect(() => {
        if (cartItems.length > 0) {
            const foodIds = cartItems.map(i => i.foodId).join('|')
            setTimeout(() => {
                setLoading(true)
                dispatch(syncCartWithDB(foodIds)).finally(() => {
                    setLoading(false)
                })
            }, 300)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        setTimeout(() => {
            setMounted(true)
        }, 300)
    }, [])

    React.useEffect(() => {
        setCanCheckout(!cartItems.some(i => i.status === 'unavailable'))
    }, [cartItems])

    return <div className={`${style.shoppingCart} ${isSlideIn ? style.fadeIn : style.fadeOut}`}>
        <div className='w-100 h-100 d-flex justify-content-end align-items-center'>
            <div className={`${style.shoppingCartBody} ${isSlideIn ? style.slideIn : style.slideOut}`}>
                <div className='d-flex align-items-center justify-content-between mb-4 px-4 pt-4'>
                    <div className='hstack gap-3'>
                        <div className={style.title}>{t("Shopping cart")}</div>
                        <Feather.Share2 onClick={shareClickHandler} />
                    </div>
                    <Close onClose={closeClickHandler} />
                </div>
                {mounted && <React.Fragment>
                    {cartItems.length > 0
                        ? <React.Fragment>
                            <div className={`${style.cartItemsContainer} px-4`}>
                                {cartItems.map(item => {
                                    return <CartItem
                                        name={item.names[0].name}
                                        quantity={item.quantity}
                                        id={item.id}
                                        key={item.id}
                                        price={item.amount * item.quantity}
                                        image={item.image}
                                        size={item.size}
                                        staus={item.status}
                                        foodId={item.foodId}
                                        stock={item.stock ? item.stock : 0}
                                    />
                                })}
                            </div>
                            <div className='px-4 pb-4 mt-3'>
                                <div className='d-flex align-items-center justify-content-between mb-1'>
                                    <div className={style.netTotal}>{t("Net total")}</div>
                                    <div className={style.netTotal}>{toCurrencyFormat(netTotal, shop?.currency)}</div>
                                </div>
                                <div className='d-flex align-items-center justify-content-between mb-1'>
                                    <div className={style.tax}>{t("Taxes etc")}.</div>
                                    <div className={style.tax}>{toCurrencyFormat(totalTax, shop?.currency)}</div>
                                </div>
                                <div className={style.horizontalLine} />
                                <div className='d-flex align-items-center justify-content-between my-4'>
                                    <div className={style.inTotal}>{t("In total") + ' (INC.VAT)'}</div>
                                    <div className={style.inTotal}>{toCurrencyFormat(netTotal + totalTax, shop?.currency)}</div>
                                </div>
                                <div className={tooltip.parent}>
                                    {!canCheckout && <Tooltip>{t("Some items are currently unavailable")}</Tooltip>}
                                    <Button onClick={checkoutClickHandler} className={style.checkoutButton} loading={loading} disabled={loading || !canCheckout}>{t("Checkout")}</Button>
                                </div>
                            </div>
                        </React.Fragment>
                        : <div className={style.cartEmpty}>
                            <div className={style.animation}>
                                <Lottie data={assets.json.empty} loop />
                            </div>
                            <div className={style.label}>{t("Your cart is empty")}</div>
                        </div>}
                </React.Fragment>}
            </div>
        </div>
    </div>
}

export default ShoppingCart