type Props={
    stroke?:string
}

const CheckboxOff = (props:Props) => {
    return <svg xmlns='http://www.w3.org/2000/svg' width='19' height='19' viewBox='0 0 19 19'>
        <g id='Component_102_5' data-name='Component 102 – 5' transform='translate(0.5 0.5)'>
            <rect id='checkbox_light' width='18' height='18' rx='4' fill='none' />
            <g id='Rectangle_67' data-name='Rectangle 67' fill='none' stroke={props.stroke?props.stroke:'#404040'} strokeWidth='2'>
                <rect width='18' height='18' rx='2' stroke='none' />
                <rect x='1' y='1' width='16' height='16' rx='1' fill='none' />
            </g>
        </g>
    </svg>
}

export default CheckboxOff