import { useT } from '../../i18n'
import style from '../../styles/components/added-to-cart.module.scss'
import { AlertTriangle } from '../svgs/AlertTriangle'
import Button from '../utils/Button'
import Modal from './Modal'

interface AddedToCartProps {
    onClose?: () => void
    confirm?: () => void
    itemName?: any
}

const Confirmation = (props: AddedToCartProps) => {
    const t = useT()
    return <Modal animate>
        <div className={style.container}>
            <div className={style.container}>
                <div className='px-4 pt-4'>
                    <div className='d-flex align-items-center justify-content-between mb-3'>
                        <div className={style.label}><AlertTriangle Stroke='#dc3545' /> {props.itemName}</div>
                        {/* <Close onClose={props.onClose} /> */}
                    </div>
                    <div className={'d-flex align-items-center justify-content-between mb-3'}>
                        <Button onClick={props.onClose}>{t("Close")}</Button>
                        <Button onClick={props.confirm}>{t("Confirm")}</Button>
                    </div>
                </div>
            </div>
        </div>
    </Modal>
}

export default Confirmation