import React from 'react'
import { animate } from '.'
import style from '../../styles/components/lottie.module.scss'

interface LottieProps {
    onComplete?: () => void
    loop?: boolean
    data: string
}

const Lottie = (props: LottieProps) => {
    const container = React.useRef<HTMLDivElement>(null)

    React.useEffect(() => {
        if (container.current) {
            animate({
                container: container.current,
                data: props.data,
                loop: props.loop ?? false
            }, props.onComplete)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <div className={style.container} ref={container} />
}


export default Lottie