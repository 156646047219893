import style from '../../styles/components/checkbox.module.scss'
import CheckboxOff from '../svgs/CheckboxOff'
import CheckboxOn from '../svgs/CheckboxOn'

interface CheckboxProps {
    checked?: boolean
    children?: React.ReactNode
    onClick?: () => void
    className?: string
    selectColor?:string
    required?:boolean
}

const Checkbox = (props: CheckboxProps) => {
    return <div className={props.className}>
        <div className={style.checkboxWrapper} onClick={props.onClick}>
            <div className={style.check}>
                {props.checked ? <CheckboxOn stroke={props.selectColor?props.selectColor:''} /> : <CheckboxOff stroke={props.required?'#ff5050':''} />}
            </div>
            <div className={style.label}>{props.children}</div>
        </div>
    </div>
}

export default Checkbox