import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useSelector } from 'react-redux'
import { api, getApiRequestConfig, Response } from '../../api'
import UserAppointmentBooking from '../../components/sections/AppointmentBooking'
import ShouldAuthenticate from '../../components/sections/ShouldAuthenticate'
import UserLayout from '../../components/sections/UserLayout'
import Spinner from '../../components/utils/Spinner'
import { AppContext } from '../../context/AppProvider'
import { UserContext } from '../../context/UserProvider'
import { useT } from '../../i18n'
import { StoreState } from '../../redux/reducers'
import { AppointmentBooking } from '../../redux/reducers/appointmentBookings.reducer'
import { AuthUser } from '../../redux/reducers/authUser.reducer'
import style from '../../styles/pages/admin-orders.module.scss'
import styleUser from '../../styles/pages/user-orders.module.scss'

type BookingMenu = 'BOOKED' | 'ATTENDED' | 'CANCELLED'

const getAppointmentMenu = (booking: AppointmentBooking): BookingMenu | null => {
    if (booking.reservationStatus === 'booked') {
        if (booking.shopId.otherPayments.findIndex(x => x.title === booking.paymentMode) !== -1) {
            return 'BOOKED'
        }

        if (booking.paymentMode === 'paytrail' && booking.paymentStatus === 'paid') {
            return 'BOOKED'
        }
    }

    if (booking.reservationStatus === 'attended') {
        return 'ATTENDED'
    }

    if (booking.reservationStatus === 'cancelled') {
        return 'CANCELLED'
    }

    return null
}

const AppointmentBookings = () => {
    const authUser = useSelector<StoreState, AuthUser | null>(state => state.authUser)
    const userContext = React.useContext(UserContext)
    const appContext = React.useContext(AppContext)
    const t = useT()
    const [appointmentMenu, setAppointmentMenu] = React.useState<BookingMenu>('BOOKED')

    const filteredAppointments = userContext?.appointments.filter(appointment => getAppointmentMenu(appointment) === appointmentMenu) || []

    const getUserAppointments = () => {
        const config = getApiRequestConfig(authUser?.['auth-token'])
        api.get<Response<AppointmentBooking[]>>('user/appointments', config).then(response => {
            if (response.status === 200) {
                userContext?.setAppointments?.(response.data.data)
            } else {
                // eslint-disable-next-line no-throw-literal
                throw { response }
            }
        }).catch(() => { })
    }
    React.useEffect(() => {
        if (authUser) {
            getUserAppointments()
        }
    }, [authUser])

    const [pages, setPages] = React.useState(Array.from({ length: 0 }))
    const [hasMore, setHasMore] = React.useState(true)
    const fetchMoreData = () => {
        if (pages.length <= filteredAppointments.length) {
            setTimeout(() => {
                setPages(pages.concat(Array.from({ length: 20 })))
            }, 1000)
            return
        }
        setHasMore(false)
    }
    React.useEffect(() => {
        fetchMoreData()
    }, [])

    const updateStatusClickHandler = (id: string) => {
        appContext?.showOverlay?.()
        const config = getApiRequestConfig(authUser?.['auth-token'])
        api.put<Response<AppointmentBooking[]>>(`user/appointment/update-status/${id}`, { appointmentStatus: 'cancelled' }, config).then(response => {
            if (response.status === 200) {
                getUserAppointments()
                userContext?.setShowConfirm?.(false)
                appContext?.hideOverlay?.()
            } else {
                // eslint-disable-next-line no-throw-literal
                throw { response }
            }
        }).catch(() => { })
    }

    return <ShouldAuthenticate>
        <UserLayout>
            <div className='mb-4 mt-2'>
                <div className={styleUser.title}>{"My Appointments"}</div>
                <div className='row'>
                    <div className='col-sm-12 col-lg-2 col-xl-2 mb-2'>
                        <BookingMenuLink active={appointmentMenu === 'BOOKED'} onClick={() => setAppointmentMenu('BOOKED')}>{t("BOOKED")} ({userContext?.appointments.filter(appointment => getAppointmentMenu(appointment) === "BOOKED").length})</BookingMenuLink>
                    </div>
                    <div className='col-sm-12 col-lg-2 col-xl-2 mb-2'>
                        <BookingMenuLink active={appointmentMenu === 'ATTENDED'} onClick={() => setAppointmentMenu('ATTENDED')}>{t("ATTENDED")} ({userContext?.appointments.filter(appointment => getAppointmentMenu(appointment) === "ATTENDED").length})</BookingMenuLink>
                    </div>
                    <div className='col-sm-12 col-lg-2 col-xl-2 mb-2'>
                        <BookingMenuLink active={appointmentMenu === 'CANCELLED'} onClick={() => setAppointmentMenu('CANCELLED')}>{t("CANCELLED")} ({userContext?.appointments.filter(appointment => getAppointmentMenu(appointment) === "CANCELLED").length})</BookingMenuLink>
                    </div>
                </div>
                {filteredAppointments.length == 0
                    ? <Spinner size={25} color='#ec3b50' className='mt-3' />
                    :
                    filteredAppointments.length !== 0 && <InfiniteScroll dataLength={pages.length} scrollableTarget="scrollableDiv" next={fetchMoreData} hasMore={hasMore} loader={<p className={style.infiniteScrollText}>{t("Fetching orders...")}</p>} endMessage={<p className={style.infiniteScrollText}>{t("All orders fetched")}</p>}>
                        {filteredAppointments.reverse().slice(0, pages.length).map(booking => {
                            return <UserAppointmentBooking
                                key={booking.id}
                                booking={booking}
                                updateUserStatusHandler={updateStatusClickHandler}
                                role='USER'
                            />
                        })}
                    </InfiniteScroll>
                }
            </div>
        </UserLayout>
    </ShouldAuthenticate>
}

interface MenuLinkProps {
    children?: React.ReactNode
    active?: boolean
    onClick?: () => void
}

const BookingMenuLink = (props: MenuLinkProps) => {
    return <div className={`${style.orderMenuLink} ${props.active ? style.active : ''}`} children={props.children} onClick={props.onClick} />
}

export default AppointmentBookings