/* eslint-disable no-throw-literal */
import { Dispatch } from 'redux'
import { api, apiErrorHandler, getApiRequestConfig, Response } from '../../api'
import { GetState } from '../reducers'
import { Order } from '../reducers/order.reducer'
import {OrderReport} from '../reducers/orderReport.reducer'

export enum OrderActionTypes {
    ADD_ORDER = 'ADD_ORDER',
    FETCH_ORDERS = 'FETCH_ORDERS',
    REPLACE_ORDER = 'REPLACE_ORDER',
    FETCH_ORDER_REPORTS = 'FETCH_ORDER_REPORTS'
}

export interface AddOrderAction {
    type: OrderActionTypes.ADD_ORDER
    data: Order
}

export interface FetchOrdersAction {
    type: OrderActionTypes.FETCH_ORDERS
    data: Order[]
}

export interface FetchOrderReportAction {
    type: OrderActionTypes.FETCH_ORDER_REPORTS
    data: OrderReport[]
}

export interface ReplaceOrderAction {
    type: OrderActionTypes.REPLACE_ORDER
    data: Order
}

export type OrderActions = AddOrderAction | FetchOrdersAction | ReplaceOrderAction | FetchOrderReportAction

export const addOrder = (input: Order): AddOrderAction => {
    return {
        type: OrderActionTypes.ADD_ORDER,
        data: input
    }
}

export const updateOrder = (input: Order): ReplaceOrderAction => {
    return {
        type: OrderActionTypes.REPLACE_ORDER,
        data: input
    }
}


export const fetchShopOrders = () => async (dispatch: Dispatch, getState: GetState) => {
    const token = getState().authAdmin?.['auth-token']
    const config = getApiRequestConfig(token)

    return api.get<Response<Order[]>>('shop/orders', config).then(response => {
        if (response.status === 200) {
            dispatch<FetchOrdersAction>({
                type: OrderActionTypes.FETCH_ORDERS,
                data: response.data.data
            })

            return Promise.resolve<string>(response.data.message || '')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to fetch'))
    })
}

export const fetchShopOrderReports = (fromDate:string,toDate:string) => async (dispatch: Dispatch, getState: GetState) => {
    const token = getState().authAdmin?.['auth-token']
    const config = getApiRequestConfig(token)

    return api.get<Response<OrderReport[]>>(`shop/order-reports/${fromDate}/${toDate}`, config).then(response => {
        if (response.status === 200) {
            dispatch<FetchOrderReportAction>({
                type: OrderActionTypes.FETCH_ORDER_REPORTS,
                data: response.data.data
            })

            return Promise.resolve<string>(response.data.message || '')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to fetch'))
    })
}

export const setOrderProcessTime = (processTime: number, orderId: string) => async (dispatch: Dispatch, getState: GetState) => {
    const token = getState().authAdmin?.['auth-token']
    const config = getApiRequestConfig(token)

    return api.put<Response<Order>>(`order/set-process-time/${orderId}`, { processTime }, config).then(response => {
        if (response.status === 200) {
            dispatch<ReplaceOrderAction>({
                type: OrderActionTypes.REPLACE_ORDER,
                data: response.data.data
            })

            return Promise.resolve<string>(response.data.message || 'Process time setted')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to set process time'))
    })
}

export const updateOrderStatus = (paymentStatus: string, orderId: string) => async (dispatch: Dispatch, getState: GetState) => {
    const token = getState().authAdmin?.['auth-token']
    const config = getApiRequestConfig(token)

    return api.put<Response<Order>>(`order/verify/payment-status/${orderId}`, { paymentStatus }, config).then(response => {
        if (response.status === 200) {
            dispatch<ReplaceOrderAction>({
                type: OrderActionTypes.REPLACE_ORDER,
                data: response.data.data
            })

            return Promise.resolve<string>(response.data.message || 'Process time setted')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to set process time'))
    })
}