import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { CheckoutContext, CheckoutContextType } from "../../context/CheckoutProvider";
import { useT } from "../../i18n";
import { StoreState } from "../../redux/reducers";
import { EventCart } from "../../redux/reducers/eventCart.reducer";
import style from '../../styles/pages/checkout.module.scss';
import EventAgreePoint from "../modals/EventAgreePoint";
import Checkbox from "../utils/Checkbox";


const CheckoutEventRules = () => {
    const t = useT()
    const eventCart = useSelector<StoreState, EventCart>((state) => state.eventCart);
    const checkoutContext = React.useContext<CheckoutContextType | null>(CheckoutContext)
    const [openDescribeModal, setOpenDescribeModal] = React.useState(false);
    return (
        <Fragment>
            {
                openDescribeModal && <EventAgreePoint
                    onClose={() => setOpenDescribeModal(false)}
                    item={eventCart.item?.eventRule}
                />
            }
            <div className='mb-4'>
                <div className='hstack mb-2'>
                    <Checkbox
                        required={checkoutContext?.eventRuleError ? true : false}
                        onClick={() => checkoutContext?.eventRuleChangeHandler?.(checkoutContext?.eventRule ? '' : 'Agree')}
                        checked={checkoutContext?.eventRule === "Agree"}
                    ><h6>{t("I have read, understood")} <Link target="_blank" to='/terms-and-conditions' className={style.link}>{t("Terms and conditions")}</Link> {t("and")} <Link to='/privacy-policy' target="_blank" className={style.link}>{t("Privacy policy")}</Link> {t("and agree to the")} <Link to='' onClick={() => setOpenDescribeModal(true)} className={style.link}>{t("rules")}</Link> {t("described specific to this event.")}</h6></Checkbox>
                </div>
                {checkoutContext?.eventRuleError && <span className={style.requiredLabel}>( {checkoutContext?.eventRuleError} )</span>}
            </div>
        </Fragment>
    )
}



export default CheckoutEventRules;