import React from 'react'
import { CSVLink } from 'react-csv'
import * as Feather from 'react-feather'
import { AdminContext, AdminContextType } from '../../context/AdminProvider'
import style from '../../styles/components/admin-panel-header.module.scss'
import Button from '../utils/Button'

interface AdminPanelHeaderProps {
    title?: string
    btnText?: string
    exportBtnText?: string
    importBtnTxt?: string
    csvProducts?: any
    onImportBtnClick?: React.MouseEventHandler<HTMLButtonElement>
    onBtnClick?: React.MouseEventHandler<HTMLButtonElement>
    isShowTableIcon?: boolean
}

const AdminPanelHeader = (props: AdminPanelHeaderProps) => {
    const adminContext = React.useContext<AdminContextType | null>(AdminContext)

    return <div className='d-flex align-items-center justify-content-between mb-3'>
        <div className={style.title}>{props.title}</div>
        <div className='hstack gap-2'>
            {props.isShowTableIcon &&
                <div className='hstack gap-2'>
                    <span title='Card View' onClick={adminContext?.cardStyleHandler} className={style.icon}><Feather.Grid /></span>
                    <span title='Table View' onClick={adminContext?.tableStyleHandler} className={style.icon}><Feather.List /></span>
                </div>}
            {window.innerWidth > 550
                ? <div className='hstack gap-2'>
                    <div>
                        {props.exportBtnText && <CSVLink data={props.csvProducts} filename={"Product Lists"}><Button className={`${style.button} px-4`} size='sm'>{props.exportBtnText}</Button></CSVLink>}
                    </div>
                    <div>
                        {props.importBtnTxt && <Button className={`${style.button} px-4`} onClick={props.onImportBtnClick} size='sm'>{props.importBtnTxt}</Button>}
                    </div>
                    <div>
                        {props.btnText && <Button className={`${style.button} px-4`} onClick={props.onBtnClick} size='sm'>{props.btnText}</Button>}
                    </div>
                </div>
                : <div className='row ms-4'>
                    <div className='col-2 me-2'>
                        {props.exportBtnText && <CSVLink data={props.csvProducts} filename={"Product Lists"}><span><Feather.Upload /></span></CSVLink>}
                    </div>
                    <div className='col-2 me-2'>
                        {props.importBtnTxt && <span onClick={props.onImportBtnClick} ><span><Feather.Download /></span></span>}
                    </div>
                    <div className='col-2'>
                        {props.btnText && <span onClick={props.onBtnClick}><span><Feather.ShoppingBag /></span></span>}
                    </div>
                </div>}
        </div>
    </div>
}

export default AdminPanelHeader