import style from '../../styles/components/text-area.module.scss'

export type TextAreaProps = {
    errorText?: string
} & React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>

const TextArea = ({ className, errorText, ...props }: TextAreaProps) => {
    return <textarea className={`${style.textArea} ${errorText && errorText !== '' ? style.error : ''} ${className || ''}`} {...props} />
}

export default TextArea