import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useNavigate } from 'react-router-dom'
import BlogForm from '../components/forms/BlogForm'
import DivisionAuthenticateForm from '../components/forms/DivisionAuthenticateForm'
import DivisionForm from '../components/forms/DivisionForm'
import ShopForm from '../components/forms/ShopForm'
import Authenticate from '../components/modals/Authenticate'
import ShopMoreDetails from '../components/modals/ShopMoreDetails'
import { fetchBlogs, fetchShops } from '../redux/actions'
import { fetchDivisions } from '../redux/actions/division.actions'
import { StoreState } from '../redux/reducers'
import { AuthDivision } from '../redux/reducers/authDivision.reducer'
import { AppDispatch } from '../redux/store'

export interface DivisionContextType {
    onMoreClick?: (shopId: string) => void
    onAddShop?: () => void
    onAddBlog?: () => void
    onAddDivision?: () => void
    onEditShop?: (shopId: string) => void
    onEditBlog?: (blogId: string) => void
    onEditDivision?: (divisionId: string) => void

}

export const DivisionContext = React.createContext<DivisionContextType | null>(null)

const DivisionProvider = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch<AppDispatch>()
    const authDivision = useSelector<StoreState, AuthDivision | null>(state => state.authDivision)

    const isLoggedIn = authDivision && authDivision['auth-token'] !== ''

    const [showShopForm, setShowShopForm] = React.useState<boolean>(false)
    const [showBlogForm, setShowBlogForm] = React.useState<boolean>(false)
    const [showDivisionForm, setShowDivisionForm] = React.useState<boolean>(false)


    const [showShopMoreDetails, setShowShopMoreDetails] = React.useState<boolean>(false)
    const [editShopId, setEditShopId] = React.useState<string | null>(null)
    const [editBlogId, setEditBlogId] = React.useState<string | null>(null)
    const [editDivisionId, setEditDivisionId] = React.useState<string | null>(null)


    const onAddShop = () => setShowShopForm(true)
    const onAddBlog = () => setShowBlogForm(true)
    const onAddDivision = () => setShowDivisionForm(true)

    const onEditShop = (shopId: string) => {
        setEditShopId(shopId)
        setShowShopForm(true)
    }
    const onEditBlog = (blogId: string) => {
        setEditBlogId(blogId)
        setShowBlogForm(true)
    }
    const onEditDivision = (divisionId: string) => {
        setEditDivisionId(divisionId)
        setShowDivisionForm(true)
    }

    const closeShopFormClickHandler = () => {
        setShowShopForm(false)
        setEditShopId(null)
    }
    const closeBlogFormClickHandler = () => {
        setShowBlogForm(false)
        setEditBlogId(null)
    }
    const closeDivisionFormClickHandler = () => {
        setShowDivisionForm(false)
        setEditDivisionId(null)
    }

    const closeShopMoreDetailsClickHandler = () => {
        setShowShopMoreDetails(false)
        setEditShopId(null)
    }

    const onMoreClick = (shopId: string) => {
        setEditShopId(shopId)
        setShowShopMoreDetails(true)
    }

    React.useEffect(() => {
        if (isLoggedIn) {
            dispatch(fetchShops())
            dispatch(fetchBlogs())
            dispatch(fetchDivisions())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn])

    return authDivision
        ? <DivisionContext.Provider value={{ onAddShop, onEditShop, onMoreClick, onAddBlog, onEditBlog, onAddDivision, onEditDivision }}>
            {showShopForm && <ShopForm
                onClose={closeShopFormClickHandler}
                divisionId={authDivision.id}
                id={editShopId}
            />}
            {showBlogForm && <BlogForm
                onClose={closeBlogFormClickHandler}
                id={editBlogId}
            />}
            {showDivisionForm && <DivisionForm
                onClose={closeDivisionFormClickHandler}
                id={editDivisionId}
            />}
            {showShopMoreDetails && <ShopMoreDetails
                onClose={closeShopMoreDetailsClickHandler}
                id={editShopId}
            />}
            <Outlet />
        </DivisionContext.Provider>
        : <Authenticate onClose={() => navigate("/")} >
            <DivisionAuthenticateForm />
        </Authenticate>
}

export default DivisionProvider