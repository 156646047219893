
import { Html5Qrcode, Html5QrcodeScanType, Html5QrcodeSupportedFormats } from 'html5-qrcode'
import React from 'react'
import { useT } from '../../i18n'
import style from '../../styles/components/added-to-cart.module.scss'
import Button from '../utils/Button'
import Modal from './Modal'
interface Props {
    openQrScan: boolean
    setScanResult: any
    closeQrCode: any
}

const QRCodeScanner: React.FC<Props> = (props) => {
    const t = useT()
    const formatsToSupport = [
        Html5QrcodeSupportedFormats.QR_CODE,
        Html5QrcodeSupportedFormats.UPC_A,
        Html5QrcodeSupportedFormats.UPC_E,
        Html5QrcodeSupportedFormats.UPC_EAN_EXTENSION,
    ];
    const config = {
        fps: 10,
        qrbox: { width: 250, height: 250 },
        rememberLastUsedCamera: true,
        formatsToSupport: formatsToSupport,
        supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_FILE, Html5QrcodeScanType.SCAN_TYPE_CAMERA,]
    };
    let scanner: any = null
    const success = (result: string) => {
        scanner.stop();
        props.setScanResult(JSON.parse(result));
        props.closeQrCode(false)
        scanner.clear();
    }

    React.useEffect(() => {
        if (props.openQrScan) {
            scanner = new Html5Qrcode('reader');
            scanner.start({ facingMode: "environment" }, config, success)
        }
    }, [props.openQrScan])

    return <Modal animate>
        <div className={style.container}>
            <div id='reader' className={style.reader}></div>
            <div className={'mb-3 mt-3 align-items-center'}>
                <Button onClick={() => { props.closeQrCode(false); scanner.stop(); }}>{t("Stop Scan")}</Button>
            </div>
        </div>
    </Modal>
}

export default QRCodeScanner