import { Action, FavouriteActionTypes } from '../actions'

export type FavouriteType = 'shop' | 'item'

export interface Favourite {
    shopId: string
    itemId?: string
    type: FavouriteType
}

const initialState: Favourite[] = []

const favouriteReducer = (state = initialState, action: Action) => {
    const newState = [...state]

    switch (action.type) {
        case FavouriteActionTypes.ADD_FAVOURITE: {
            if (action.data.type === 'item') {
                if (newState.some(fav => fav.itemId === action.data.itemId && fav.type === 'item')) {
                    return newState
                }

                return [...newState, { ...action.data }]
            } else if (action.data.type === 'shop') {
                if (newState.some(fav => fav.shopId === action.data.shopId && fav.type === 'shop')) {
                    return newState
                }

                return [...newState, { ...action.data }]
            }

            return newState
        }

        case FavouriteActionTypes.REMOVE_FAVOURITE: {
            const index = newState.findIndex(s => s.itemId === action.data.itemId && s.shopId === action.data.shopId && s.type === action.data.type)

            if (index > -1) {
                return newState.filter((_, i) => index !== i)
            }

            return newState
        }

        default: {
            return state
        }
    }
}

export default favouriteReducer