import { TranslationLanguage } from '../../i18n'

export enum TranslationLanguageActionTypes {
    SET_TRANSLATION_LANGUAGE = 'SET_TRANSLATION_LANGUAGE'
}

export interface SetTranslationLanguageAction {
    type: TranslationLanguageActionTypes.SET_TRANSLATION_LANGUAGE
    data: TranslationLanguage
}

export type TranslationLanguageActions = SetTranslationLanguageAction

export const setTranslationLanguage = (language: TranslationLanguage): SetTranslationLanguageAction => {
    return {
        type: TranslationLanguageActionTypes.SET_TRANSLATION_LANGUAGE,
        data: language
    }
}