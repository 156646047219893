import React from 'react'
import { useSelector } from 'react-redux'
import { useT } from '../../i18n'
import { StoreState } from '../../redux/reducers'
import { AuthAdmin } from '../../redux/reducers/authAdmin.reducer'
import style from '../../styles/components/admin-layout.module.scss'
import Activity from '../svgs/Activity'
import Coffee from '../svgs/Coffee'
import Home from '../svgs/Home'
import MessageCircle from '../svgs/MessageCircle'
import Package from '../svgs/Package'
import Percent from '../svgs/Percent'
import PieChart from '../svgs/PieChart'
import Settings from '../svgs/Settings'
import AdminHeader from './AdminHeader'
import Footer from './Footer'
import SideNavbar from './SideNavbar'

interface AdminLayoutProps {
    children?: React.ReactNode
}

const AdminLayout = (props: AdminLayoutProps) => {
    const t = useT()
    const authAdmin = useSelector<StoreState, AuthAdmin | null>(state => state.authAdmin)
    const [sideNavbarActive, setSideNavbarActive] = React.useState<boolean>(false)
    const [width, setWidth] = React.useState<number>(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    React.useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    const isMobile = width <= 760

    const foodItemList =
        [
            {
                label: t('Home'),
                to: '/admin',
                icon: <Home stroke='#404040' strokeWidth={1} />
            },
            {
                label: t('Orders'),
                to: '/admin/orders',
                icon: <PieChart stroke='#404040' strokeWidth={1} />
            },
            {
                label: t('Categories'),
                to: '/admin/categories',
                icon: <Package stroke='#404040' strokeWidth={1} />
            },
            {
                label: t('Products'),
                to: '/admin/products',
                icon: <Coffee stroke='#404040' strokeWidth={1} />
            },
            {
                label: t('Vouchers'),
                to: '/admin/vouchers',
                icon: <Percent stroke='#404040' strokeWidth={1} />
            },
            {
                label: t('Feedbacks'),
                to: '/admin/feedbacks',
                icon: <MessageCircle stroke='#404040' strokeWidth={1} />
            },
            {
                label: t('Reports'),
                to: '/admin/reports',
                icon: <Activity stroke='#404040' strokeWidth={1} />
            },
            {
                label: t('Settings'),
                to: '/admin/settings',
                icon: <Settings stroke='#404040' strokeWidth={1} />
            }
        ]
    const bookingItemList =
        [
            {
                label: t('Home'),
                to: '/admin',
                icon: <Home stroke='#404040' strokeWidth={1} />
            },
            {
                label: t('Appointment Bookings'),
                to: '/admin/appointment-bookings',
                icon: <PieChart stroke='#404040' strokeWidth={1} />
            },
            {
                label: t('Categories'),
                to: '/admin/categories',
                icon: <Package stroke='#404040' strokeWidth={1} />
            },
            {
                label: t('Appointments'),
                to: '/admin/appointments',
                icon: <Coffee stroke='#404040' strokeWidth={1} />
            },
            {
                label: t('Vouchers'),
                to: '/admin/vouchers',
                icon: <Percent stroke='#404040' strokeWidth={1} />
            },
            {
                label: t('Feedbacks'),
                to: '/admin/feedbacks',
                icon: <MessageCircle stroke='#404040' strokeWidth={1} />
            },
            {
                label: t('Reports'),
                to: '/admin/reports',
                icon: <Activity stroke='#404040' strokeWidth={1} />
            },
            {
                label: t('Settings'),
                to: '/admin/settings',
                icon: <Settings stroke='#404040' strokeWidth={1} />
            }
        ]
    const eventItemList =
        [
            {
                label: t('Home'),
                to: '/admin',
                icon: <Home stroke='#404040' strokeWidth={1} />
            },
            {
                label: t('Reservations'),
                to: '/admin/reservations',
                icon: <PieChart stroke='#404040' strokeWidth={1} />
            },
            {
                label: t('Volunteering'),
                to: '/admin/volunteering',
                icon: <PieChart stroke='#404040' strokeWidth={1} />
            },
            {
                label: t('Events'),
                to: '/admin/events',
                icon: <Coffee stroke='#404040' strokeWidth={1} />
            },
            {
                label: t('Vouchers'),
                to: '/admin/vouchers',
                icon: <Percent stroke='#404040' strokeWidth={1} />
            },
            {
                label: t('Feedbacks'),
                to: '/admin/feedbacks',
                icon: <MessageCircle stroke='#404040' strokeWidth={1} />
            },
            {
                label: t('Reports'),
                to: '/admin/reports',
                icon: <Activity stroke='#404040' strokeWidth={1} />
            },
            {
                label: t('Settings'),
                to: '/admin/settings',
                icon: <Settings stroke='#404040' strokeWidth={1} />
            }
        ]

    return <React.Fragment>
        <AdminHeader
            onMenuClick={() => setSideNavbarActive(true)}
        />
        <div className={style.container} id='scrollableDiv'>
            <div className='d-flex'>
                <SideNavbar
                    isActive={sideNavbarActive}
                    onClose={() => setSideNavbarActive(false)}
                    fixed={isMobile ? true : false}
                    showHeader={isMobile ? true : false}
                    navbarItemList={(authAdmin?.category === "Event" ? eventItemList : (authAdmin?.category === "Booking" ? bookingItemList : foodItemList))}
                    light
                />
                <div className={style.mainContent}>
                    <div className='container pt-3'>
                        {props.children}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    </React.Fragment>
}

export default AdminLayout