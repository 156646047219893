import React from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { api, apiErrorHandler, Response } from '../../api'
import { UserContext } from '../../context/UserProvider'
import { useT } from '../../i18n'
import { loginUser, registerUser } from '../../redux/actions'
import { AppDispatch } from '../../redux/store'
import style from '../../styles/components/authenticate.module.scss'
import formStyle from '../../styles/components/form.module.scss'
import { testNumber, toString } from '../../utils'
import { AuthenticateType } from '../modals/Authenticate'
import Eye from '../svgs/Eye'
import EyeOff from '../svgs/EyeOff'
import { ToastType, useToast } from '../ToastProvider'
import Button from '../utils/Button'
import Checkbox from '../utils/Checkbox'
import FormInput from '../utils/FormInput'

interface UserAuthenticateFormProps {
    onAuthenticateTypeChange?: (value: AuthenticateType) => void
    authenticateType?: AuthenticateType
}

const UserAuthenticateForm = (props: UserAuthenticateFormProps) => {
    const userContext = React.useContext(UserContext)

    const dispatch = useDispatch<AppDispatch>()
    const toast = useToast()
    const t = useT()

    const [firstName, setFirstName] = React.useState<string>('')
    const [firstNameError, setFirstNameError] = React.useState<string>('')
    const [lastName, setLastName] = React.useState<string>('')
    const [lastNameError, setLastNameError] = React.useState<string>('')
    const [email, setEmail] = React.useState<string>('')
    const [emailError, setEmailError] = React.useState<string>('')
    const [password, setPassword] = React.useState<string>('')
    const [passwordError, setPasswordError] = React.useState<string>('')
    const [countryCode, setCountryCode] = React.useState<string>('+358')
    const [phoneNumber, setPhoneNumber] = React.useState<string>('')
    const [phoneNumberError, setPhoneNumberError] = React.useState<string>('')
    const [addressLine1, setAddressLine1] = React.useState<string>('')
    const [addressLine1Error, setAddressLine1Error] = React.useState<string>('')
    const [addressLine2, setAddressLine2] = React.useState<string>('')
    const [addressLine2Error, setAddressLine2Error] = React.useState<string>('')
    const [pincode, setPincode] = React.useState<string>('')
    const [pincodeError, setPincodeError] = React.useState<string>('')
    const [landmark, setLandmark] = React.useState<string>('')
    const [landmarkError, setLandmarkError] = React.useState<string>('')
    const [state, setState] = React.useState<string>('')
    const [stateError, setStateError] = React.useState<string>('')
    const [city, setCity] = React.useState<string>('')
    const [cityError, setCityError] = React.useState<string>('')
    const [privacyPolicy, setPrivacyPolicy] = React.useState<boolean>(false)
    const [privacyPolicyError, setPrivacyPolicyError] = React.useState<string>('')
    const [emailConcent, setEmailConcent] = React.useState<boolean>(false)
    const [showPassword, setShowPassword] = React.useState<boolean>(false)
    const [loading, setLoading] = React.useState<boolean>(false)

    const firstNameChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setFirstName(e.target.value)
        setFirstNameError('')
    }

    const lastNameChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setLastName(e.target.value)
        setLastNameError('')
    }

    const emailChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setEmail(e.target.value)
        setEmailError('')
    }

    const passwordChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setPassword(e.target.value)
        setPasswordError('')
    }

    const phoneNumberChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setPhoneNumber(e.target.value)
        setPhoneNumberError('')
    }

    const addressLine1ChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setAddressLine1(e.target.value)
        setAddressLine1Error('')
    }

    const addressLine2ChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setAddressLine2(e.target.value)
        setAddressLine2Error('')
    }

    const pincodeChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        if ((!testNumber(e.target.value) && e.target.value))
            return
        else if (e.target.value.length >= 7)
            return

        setPincode(e.target.value)
        setPincodeError('')
    }

    const cityChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setCity(e.target.value)
        setCityError('')
    }

    const landmarkChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setLandmark(e.target.value)
        setLandmarkError('')
    }

    const stateChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setState(e.target.value)
        setStateError('')
    }

    const privacyPolicyChangeHandler = (check: boolean) => {
        setPrivacyPolicy(check ? false : true)
        if (check === true)
            setPrivacyPolicyError('')
    }

    const emailConcentChangeHandler = (check: boolean) => {
        setEmailConcent(check ? false : true)
    }

    const clearState = () => {
        setFirstName('')
        setFirstNameError('')
        setLastName('')
        setLastNameError('')
        setEmail('')
        setEmailError('')
        setPassword('')
        setPasswordError('')
        setPhoneNumber('')
        setPhoneNumberError('')
        setAddressLine1('')
        setAddressLine1Error('')
        setAddressLine2('')
        setAddressLine2Error('')
        setPincode('')
        setPincodeError('')
        setLandmark('')
        setLandmarkError('')
        setState('')
        setStateError('')
        setCity('')
        setCityError('')
        setPrivacyPolicy(false)
        setPrivacyPolicyError('')
        setEmailConcent(false)
        setShowPassword(false)
        setLoading(false)
    }

    const submitHandler: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault()

        let error = false

        if (props.authenticateType === AuthenticateType.REGISTER) {
            if (toString(firstName) === '') {
                setFirstNameError(t('First Name required'))
                error = true
            }

            if (toString(lastName) === '') {
                setLastNameError(t('Last Name required'))
                error = true
            }

            if (toString(phoneNumber) === '') {
                setPhoneNumberError(t('Phone Number required'))
                error = true
            } else if ((phoneNumber).length > 15) {
                setPhoneNumberError(t('Phone Number should be within 15 digits'))
                error = true
            } else if (!testNumber(phoneNumber)) {
                setPhoneNumberError(t('Phone Number must be a number'))
                error = true
            }

            // if (toString(pincode) === '') {
            //     setPincodeError(t('Pincode required'))
            //     error = true
            // } else if ((pincode).length > 10) {
            //     setPincodeError(t('Pincode should be within 10 digits'))
            //     error = true
            // } else if (!testNumber(pincode)) {
            //     setPincodeError(t('Pincode must be a number'))
            //     error = true
            // }

            if (toString(city) === '') {
                setCityError(t('City required'))
                error = true
            }

            if (privacyPolicy === false) {
                setPrivacyPolicyError(t('Privacy Error'))
                error = true
            }

            // if (toString(addressLine1) === '') {
            //     setAddressLine1Error('Address line 1 required')
            //     error = true
            // }

            // if (toString(addressLine2) === '') {
            //     setAddressLine2Error('Address line 2 required')
            //     error = true
            // }

            // if (toString(state) === '') {
            //     setStateError('State required')
            //     error = true
            // }

            if (password === '') {
                setPasswordError(t('Password required'))
                error = true
            }
            // else if (password.length < 8) {
            //     setPasswordError('Password is too short - should be 8 chars minimum')
            //     error = true
            // } else if (password.length > 15) {
            //     setPasswordError('Password is too long - should be 15 chars maximum')
            //     error = true
            // } else if (!testPassword(password)) {
            //     setPasswordError('Password can only contain alphanumeric characters, underscore and first character must be a letter')
            //     error = true
            // }
        }

        if (props.authenticateType !== AuthenticateType.FORGOT_PASSWORD) {
            if (password === '') {
                setPasswordError(t('Password required'))
                error = true
            }
        }

        if (toString(email) === '') {
            setEmailError(t('Email required'))
            error = true
        }

        if (!error) {
            if (props.authenticateType === AuthenticateType.REGISTER) {
                setLoading(true)
                dispatch(registerUser({
                    email: toString(email).toLowerCase(),
                    firstName: toString(firstName),
                    lastName: toString(lastName),
                    password: password,
                    countryCode: toString(countryCode),
                    phoneNumber: toString(phoneNumber),
                    addressLine1: toString(addressLine1),
                    addressLine2: toString(addressLine2),
                    landmark: toString(landmark) || null,
                    pincode: toString(pincode),
                    state: toString(state),
                    privacyPolicy: toString("Agreed"),
                    emailConcent: emailConcent ? toString("Yes") : toString('No'),
                    city: toString(city)
                })).then(text => {
                    toast(text)
                    props.onAuthenticateTypeChange?.(AuthenticateType.SIGN_IN)
                }).catch(text => {
                    toast(text, ToastType.ERROR)
                }).finally(() => {
                    setLoading(false)
                })
            } else if (props.authenticateType === AuthenticateType.SIGN_IN) {
                setLoading(true)
                dispatch(loginUser({
                    email: toString(email).toLowerCase(),
                    password: password
                })).then(text => {
                    toast(text)
                    userContext?.setShowSignIn?.(false)
                }).catch(text => {
                    toast(text, ToastType.ERROR)
                }).finally(() => {
                    setLoading(false)
                })
            } else if (props.authenticateType === AuthenticateType.FORGOT_PASSWORD) {
                setLoading(true)
                api.put<Response>('user/forgot-password', { email: toString(email).toLowerCase() }).then(response => {
                    toast(response.data.message || t('Password has been changed. New password has been sent to your registered email. Please check'))
                    props.onAuthenticateTypeChange?.(AuthenticateType.SIGN_IN)
                }).catch(error => {
                    toast(apiErrorHandler(error, t('Unable to reset password')), ToastType.ERROR)
                }).finally(() => {
                    setLoading(false)
                })
            }
        }
    }
    const countryCodeHandler = (value: string) => {
        setCountryCode(value)
    }

    React.useEffect(() => {
        clearState()
    }, [props.authenticateType])

    return <form>
        <div className={`${style.mainContent} px-4`}>
            <div className='row'>
                {props.authenticateType === AuthenticateType.REGISTER && <React.Fragment>
                    <div className='col-md-6 col-lg-4'>
                        <FormInput
                            type='text'
                            label={t('First Name')}
                            placeholder={t('First Name')}
                            errorText={firstNameError}
                            value={firstName}
                            required={true}
                            onChange={firstNameChangeHandler}
                            containerClass='mb-4'
                        />
                    </div>
                    <div className='col-md-6 col-lg-4'>
                        <FormInput
                            type='text'
                            label={t('Last Name')}
                            placeholder={t('Last Name')}
                            errorText={lastNameError}
                            value={lastName}
                            required={true}
                            onChange={lastNameChangeHandler}
                            containerClass='mb-4'
                        />
                    </div>
                </React.Fragment>}
                <div className={props.authenticateType === AuthenticateType.REGISTER ? 'col-md-6 col-lg-4' : 'col-12'}>
                    <FormInput
                        type='email'
                        label={t('Email')}
                        placeholder={t('Email')}
                        errorText={emailError}
                        value={email}
                        required={true}
                        onChange={emailChangeHandler}
                        containerClass={props.authenticateType !== AuthenticateType.FORGOT_PASSWORD ? 'mb-4' : ''}
                    />
                </div>
                {(props.authenticateType === AuthenticateType.REGISTER || props.authenticateType === AuthenticateType.SIGN_IN) && <div className={props.authenticateType === AuthenticateType.REGISTER ? 'col-md-6 col-lg-4' : 'col-12'}>
                    <FormInput
                        type={showPassword ? 'text' : 'password'}
                        label={t('Password')}
                        placeholder={t('Password')}
                        required={true}
                        errorText={passwordError}
                        value={password}
                        onChange={passwordChangeHandler}
                        containerClass={props.authenticateType === AuthenticateType.REGISTER ? 'mb-4' : ''}
                        rightRenderer={showPassword ? <EyeOff /> : <Eye />}
                        onRightRendererClick={() => setShowPassword(!showPassword)}
                        labelRightRenderer={props.authenticateType === AuthenticateType.SIGN_IN && <div className={style.forgotPassword} onClick={() => props.onAuthenticateTypeChange?.(AuthenticateType.FORGOT_PASSWORD)}>{t("Forgot password")}?</div>}
                    />
                </div>}
                {props.authenticateType === AuthenticateType.REGISTER && <React.Fragment>
                    <div className='col-md-6 col-lg-4'>
                        <FormInput
                            type='text'
                            label={t('Phone Number')}
                            required={true}
                            placeholder={t('Phone Number')}
                            errorText={phoneNumberError}
                            value={phoneNumber}
                            leftRenderer={countryCode}
                            countryCode={countryCodeHandler}
                            onChange={phoneNumberChangeHandler}
                            containerClass='mb-4'
                        />
                    </div>
                    <div className='col-md-6 col-lg-4'>
                        <FormInput
                            type='text'
                            label={t('Address Line 1')}
                            required={false}
                            placeholder={t('Address Line 1')}
                            errorText={addressLine1Error}
                            value={addressLine1}
                            onChange={addressLine1ChangeHandler}
                            containerClass='mb-4'
                        />
                    </div>
                    <div className='col-md-6 col-lg-4'>
                        <FormInput
                            type='text'
                            label={t('Address Line 2')}
                            placeholder={t('Address Line 2')}
                            required={false}
                            errorText={addressLine2Error}
                            value={addressLine2}
                            onChange={addressLine2ChangeHandler}
                            containerClass='mb-4'
                        />
                    </div>
                    <div className='col-md-6 col-lg-4'>
                        <FormInput
                            type='text'
                            label={t('City')}
                            placeholder={t('City')}
                            required={true}
                            errorText={cityError}
                            value={city}
                            onChange={cityChangeHandler}
                            containerClass='mb-4'
                        />
                    </div>
                    <div className='col-md-6 col-lg-4'>
                        <FormInput
                            type='text'
                            label={t('State')}
                            placeholder={t('State')}
                            required={false}
                            errorText={stateError}
                            value={state}
                            onChange={stateChangeHandler}
                            containerClass='mb-4'
                        />
                    </div>
                    <div className='col-md-6 col-lg-4'>
                        <FormInput
                            type='text'
                            label={t('Pincode')}
                            placeholder={t('Pincode')}
                            required={false}
                            errorText={pincodeError}
                            value={pincode}
                            onChange={pincodeChangeHandler}
                            containerClass='mb-4'
                        />
                    </div>
                    <div className='col-md-6 col-lg-4'>
                        <FormInput
                            type='text'
                            label={t('Landmark')}
                            placeholder={t('Landmark')}
                            errorText={landmarkError}
                            value={landmark}
                            onChange={landmarkChangeHandler}
                            containerClass='mb-4'
                        />
                    </div>
                </React.Fragment>}
            </div>
            {props.authenticateType === AuthenticateType.REGISTER &&
                <React.Fragment>
                    <Checkbox
                        className='mb-1'
                        onClick={() => privacyPolicyChangeHandler(privacyPolicy)}
                        checked={privacyPolicy}
                        required={privacyPolicyError ? true : false}
                    >{t("I have read, understand and agree to vshops")}<Link to='/terms-and-conditions' target='_blank' className={style.link}>{t("Terms and conditions")}</Link> and <Link to='/privacy-policy' target='_blank' className={style.link}>{t("Privacy policy")}</Link> <span className={style.required}>*</span>
                    </Checkbox>
                    <Checkbox
                        className='mb-1'
                        onClick={() => emailConcentChangeHandler(emailConcent)}
                        checked={emailConcent}
                    >{t("Opt in for email updates on exclusive offers and news from vshops.fi. You can unsubscribe anytime.")}
                    </Checkbox>
                </React.Fragment>
            }
        </div>

        <div className='p-4'>
            {props.authenticateType === AuthenticateType.REGISTER && <React.Fragment>
                <Button type='submit' className={formStyle.saveButton} onClick={submitHandler} loading={loading}>{t("Register")}</Button>
                <div className='hstack justify-content-center'>
                    <div className={`${style.switchLink} ${style.userAuthText}`} onClick={() => props.onAuthenticateTypeChange?.(AuthenticateType.SIGN_IN)}>{t("Existing user? Sign in")}</div>
                </div>
            </React.Fragment>}
            {props.authenticateType === AuthenticateType.SIGN_IN && <React.Fragment>
                <Button type='submit' className={formStyle.saveButton} onClick={submitHandler} loading={loading}>{t("Sign In")}</Button>
                <div className='hstack justify-content-center'>
                    <div className={`${style.switchLink} ${style.userAuthText}`} onClick={() => props.onAuthenticateTypeChange?.(AuthenticateType.REGISTER)}>{t("New to Vshop? Create an account")}</div>
                </div>
            </React.Fragment>}
            {props.authenticateType === AuthenticateType.FORGOT_PASSWORD && <React.Fragment>
                <Button type='submit' className={formStyle.saveButton} onClick={submitHandler} loading={loading}>{t("Continue")}</Button>
                <div className='hstack justify-content-center'>
                    <div className={`${style.switchLink} ${style.userAuthText}`} onClick={() => props.onAuthenticateTypeChange?.(AuthenticateType.REGISTER)}>{t("New to Vshop? Create an account")}</div>
                </div>
                <div className='hstack justify-content-center'>
                    <div className={`${style.switchLink} ${style.userAuthText}`} onClick={() => props.onAuthenticateTypeChange?.(AuthenticateType.SIGN_IN)}>{t("Existing user? Sign in")}</div>
                </div>
            </React.Fragment>}
        </div>
    </form>
}

export default UserAuthenticateForm