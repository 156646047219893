import React, { useEffect, useState } from 'react';
import { Carousel, Col, Row } from 'react-bootstrap';
import * as Feather from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { FacebookIcon, FacebookShareButton } from 'react-share';
import { api } from '../api';
import { useToast } from '../components/ToastProvider';
import Card from '../components/cards/Card';
import UserLayout from '../components/sections/UserLayout';
import Button from '../components/utils/Button';
import ShopProvider from '../context/ShopProvider';
import { UserContext, UserContextType } from '../context/UserProvider';
import { useT } from '../i18n';
import { addFavourite, appointmentAddToCart, removeFavourite } from '../redux/actions';
import { StoreState } from '../redux/reducers';
import { Appointment } from '../redux/reducers/appointment.reducer';
import { Cart, CartItem } from '../redux/reducers/cart.reducer';
import { Favourite } from '../redux/reducers/favourite.reducer';
import { Shop } from '../redux/reducers/shop.reducer';
import { AppDispatch } from '../redux/store';
import style1 from '../styles/components/add-to-cart.module.scss';
import style from '../styles/pages/productview2.module.scss';
import { dayNames, getAppointmentOfferPrice, getAppointmentPrice, monthNames, toCurrencyFormat } from '../utils';
// import './productview2.css';

interface AddToCartProps {
    onClose?: () => void
    cartId?: string | null
    appointment?: any
}


const ProductViewAppointment = (props: AddToCartProps) => {
    const t = useT()
    const { shopId, appointmentId } = useParams()
    const navigate = useNavigate();
    const userProvider = React.useContext<UserContextType | null>(UserContext)
    const shop = useSelector<StoreState, Shop | null>(state => state.shops.find(s => s.id === shopId) || null)
    const dispatch = useDispatch<AppDispatch>()
    const toast = useToast()
    const cartItem = useSelector<StoreState, CartItem | null>(state => state.cart.items.find(c => c.id === props.cartId) || null)
    const cart = useSelector<StoreState, Cart>(state => state.cart)
    const favourites = useSelector<StoreState, Favourite[]>(state => state.favourites.filter(fav => fav.type === 'item' && fav.shopId === props.appointment?.shopId))
    const isFavourite = favourites.some(fav => fav.itemId && fav.itemId === appointment?.id)
    const [appointment, setAppointment] = React.useState<Appointment>()
    const [appointmentDate, setAppointmentDate] = useState<string>("")

    const [view, setView] = React.useState<string>(t('View All'))
    const [actualImage, setActualImage] = React.useState<string>(
        props.appointment && props.appointment.image ? props.appointment.image : ''
    );
    const [width, setWidth] = React.useState(window.innerWidth);

    // function gmapHandler() {
    //     window.open("https://www.google.com/maps/place/" + appointment?.location, '_blank')
    // }

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    React.useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    useEffect(() => {
        const fromDate = appointment?.date;
        const from = new Date(fromDate ? fromDate : '');
        const date = dayNames[from.getDay()] + ', ' + from.getDate() + 'th ' + monthNames[from.getMonth()] + ', ' + from.getFullYear();
        setAppointmentDate(date)
    }, [appointment])

    const ViewAllHandler = () => {
        setView(prev => prev === t('View All') ? t('View Less') : t('View All'))
    }

    const goToPreviousPage = (shopId: string | undefined) => {
        if (shopId) {
            const url = `${window.location.origin}/shop/${shopId}`;
            window.location.href = url;
        }
    };

    const goToProduct = (shopId: string | undefined, appointmentId: string | undefined) => {
        if (shopId && appointmentId) {
            const url = `${window.location.origin}/appointment/${shopId}/${appointmentId}`;
            window.location.href = url;
        }
    }

    const appointmentLists = React.useMemo(() => {
        let appointmentItems: Appointment[] = [];

        if (shopId) {
            api.get<{ data: Appointment[] }>(`appointments/${shopId}`).then((res) => {
                if (res.status === 200 && res.data.data) {
                    const result = res.data.data
                    appointmentItems.push(...res.data.data)
                    let appointment = result?.find(f => f?.id === appointmentId!)
                    setAppointment(appointment)
                }
            });
        }
        return appointmentItems
    }, [shopId])

    const items = appointmentLists.filter(p => p.id !== appointmentId).slice(0, 4)

    const sameCategoryItems = appointmentLists.filter(c => c.categories[0].id === appointment?.categories[0].id && c.id !== appointmentId)

    const categoryLength = sameCategoryItems.length < 4

    if (categoryLength) {
        const toAddLength = 4 - sameCategoryItems.length
        const otherCategoryProducts = items.filter(c => c.categories[0].id !== appointment?.categories[0].id)

        let extraProduct = otherCategoryProducts.slice(0, toAddLength)
        extraProduct.forEach(e => {
            sameCategoryItems.push(e)
        })
    }

    const favouritesClickHandler = () => {
        if (appointment) {
            if (isFavourite) {
                dispatch(removeFavourite({
                    shopId: appointment?.shopId,
                    type: 'item',
                    itemId: appointment?.id
                }))
            } else {
                dispatch(addFavourite({
                    shopId: appointment?.shopId,
                    type: 'item',
                    itemId: appointment?.id
                }))
            }
        }
    }

    const appointmentAddToCartClickHandler = () => {

        dispatch(appointmentAddToCart({
            appointmentId: appointment?.id || "",
            names: appointment?.names.map((n: { language: string, name: string }) => ({ language: (n.language), name: (n.name) })) || [],
            prices: appointment?.prices || [],
            category: appointment?.categories[0].id || "",
            slots: appointment?.slots || [],
            capacity: appointment?.capacity || 0,
            price: appointment?.price || 0,
            offerPrice: appointment?.offerPrice || 0,
            type: appointment?.type || "simple"
        }, appointment?.shopId || ''))
        navigate("/checkout-appointment");
        props.onClose?.()
    }

    React.useEffect(() => {
        if (appointment && appointment.image) {
            setActualImage(appointment.image)
        }
    }, [appointment])

    return (
        <UserLayout>
            {shopId && <ShopProvider shopId={shopId}>
                <div className="container p-3">
                    <div className='d-flex justify-content-between'>
                        <div className='hstack gap-2 mt-2'>
                            <div className={style.productName}>{appointment?.names[0].name}</div>
                            <div>
                                <FacebookShareButton
                                    url={`${window.location.origin}/appointment/${shopId}/${appointmentId}`}
                                    className="facebookSharing"
                                    hashtag={`#${appointment?.names[0].name}`}
                                >
                                    <FacebookIcon size={34} />
                                </FacebookShareButton>
                            </div>
                        </div>
                        {width > 500 && <Button className={style.previousButton} size="sm" onClick={() => goToPreviousPage(shopId)}>{t("Go to shop")}</Button>}
                        {width <= 500 && <div className="mt-2" onClick={() => goToPreviousPage(shopId)}><Feather.ArrowLeftCircle /></div>}
                    </div>
                    <hr />

                    <div className='row mb-3'>

                        <div className='col-lg-6 col-sm-12 col-xs-12 col-md-12'>
                            <Row>
                                {width > 500 && appointment?.additionalImages.length !== 0 &&
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        {appointment?.additionalImages.length !== 0 ?

                                            <Row>
                                                {<Col lg={3} md={3} sm={3} xs={3}>
                                                    <div className='overflow-auto' style={{ height: "400px" }}>
                                                        <p className='mt-3'>
                                                            <img
                                                                src={appointment?.image}
                                                                style={{ cursor: "pointer" }}
                                                                onMouseOver={() => setActualImage(appointment!.image)}
                                                                alt=""
                                                                width="70px" height="70px" className="img-thumbnail"
                                                            /></p>
                                                        {appointment?.additionalImages.map((additionalimage, index) => (
                                                            <p key={index} className='mt-3'>
                                                                <img
                                                                    src={additionalimage !== undefined ? additionalimage : ""}
                                                                    onMouseOver={() => setActualImage(additionalimage)}
                                                                    style={{ cursor: "pointer" }}
                                                                    alt="" width="70px" height="70px" className="img-thumbnail" />
                                                            </p>

                                                        ))}
                                                    </div>
                                                </Col>}
                                                <Col lg={9} md={9} sm={9} xs={9}>

                                                    <img src={actualImage ? actualImage : ''} alt="icon" className={style.bigImage2} />

                                                </Col>
                                            </Row>
                                            :
                                            <img src={appointment?.image ? appointment?.image : ''} alt="icon" className={style.bigImage2} />
                                        }

                                    </Col>
                                }
                                {width > 500 && appointment?.additionalImages.length === 0 &&
                                    <img src={appointment?.image ? appointment?.image : ''} alt="icon" className={style.additionalImage} />
                                }
                                {width <= 500 &&
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        {appointment?.additionalImages.length !== 0 ?
                                            <Carousel fade={true} slide={false}>

                                                <Carousel.Item key={0} interval={3000} >

                                                    <img
                                                        className="d-block w-100"
                                                        src={(appointment?.image && appointment?.image !== null) ? appointment?.image : ''}
                                                        alt={`${0} slide`}
                                                    />

                                                    <Carousel.Caption />

                                                </Carousel.Item>

                                                {appointment?.additionalImages.map((additionalImage, index) => (
                                                    <Carousel.Item key={index + 1} interval={3000} >

                                                        <img
                                                            className="d-block w-100"
                                                            src={(additionalImage && additionalImage !== null) ? additionalImage : ''}
                                                            alt={`${index + 1} slide`}
                                                        />

                                                        <Carousel.Caption />

                                                    </Carousel.Item>
                                                ))}

                                            </Carousel>
                                            :
                                            <img src={appointment?.image ? appointment?.image : ''} alt="icon" className={width <= 420 ? style.bigImage : style.bigImage2} />
                                        }

                                    </Col>
                                }
                            </Row>
                        </div>

                        <div className='col-lg-3 col-sm-12 col-xs-12 col-md-12'>
                            <p className={` mt-2 ${style.description}`} dangerouslySetInnerHTML={{ __html: appointment?.descriptions[0].description || '' }}>
                            </p>
                        </div>

                        <div className={`col-lg-3 col-sm-12 col-xs-12 col-md-12`}>
                            <div className={`mb-2 ${style1.editOrderContent} `}>
                                {appointment && appointment.prices && appointment.prices.length > 0 && (
                                    <div >
                                        <div className={`text-capitalize ${style.productName} mt-2 mb-2`}>{t("Appointment Fees")}:</div>
                                        {appointment.type === "detail" && appointment.prices.map((p: any) => {
                                            const priceIndex = appointment.prices.findIndex((fp: any) => fp.size === p.size);
                                            const price = getAppointmentPrice(appointment, priceIndex);
                                            const offerPrice = getAppointmentOfferPrice(appointment, priceIndex);
                                            return (
                                                <div className='hstack gap-2 justify-content-between' key={p.size}>
                                                    <span className='text-capitalize'>{p.size}</span>
                                                    <div className='hstack gap-2'>
                                                        {offerPrice > 0 ? (
                                                            <React.Fragment>
                                                                <span> {'('}
                                                                    {toCurrencyFormat(offerPrice, shop?.currency)}
                                                                </span>
                                                                <span>

                                                                    <del className={style.offerPrice}>{toCurrencyFormat(price, shop?.currency)}</del>{')'}
                                                                </span>

                                                            </React.Fragment>
                                                        ) : (
                                                            <span>{'('}{toCurrencyFormat(price, shop?.currency)}{')'}</span>
                                                        )}
                                                    </div>
                                                </div>
                                            );
                                        })}

                                    </div>
                                )}
                                {appointment && appointment.type === "simple" &&
                                    <div>
                                        <div className={`text-capitalize ${style.productName} mt-2 mb-2`}>{t("Appointment Fees")}:</div>
                                        {("Amount")} : {appointment.offerPrice + " €"}
                                    </div>}
                            </div>

                            <Button className={`${style.cartButton} mt-2`} size='sm' onClick={appointmentAddToCartClickHandler}>{t("Book Now")}</Button>
                        </div>

                    </div>

                </div>

                <div >
                    <div className='row'>
                        <div className='hstack justify-content-between'>
                            <div className={style.productName}>{t("Recommended Products")}</div>
                            {/* {items && items?.length > 0 && <div className={style2.viewAll}>
                                <div className={style2.label} onClick={ViewAllHandler}>{view}
                                </div>
                                <ChevronRightDark />
                            </div>} */}
                        </div>
                    </div>
                    <div className='row'>
                        {sameCategoryItems.length > 0 ? sameCategoryItems.filter(p => p.id !== appointmentId).map((oneproductdata, index) => (
                            <div key={index} className='col-sm-6 col-lg-4 col-xxl-3 mb-2'>
                                <Card
                                    img={oneproductdata.image}
                                    name={oneproductdata.names[0].name}
                                    buttonText={t('View Appointment')}
                                    onClick={() => goToProduct(shopId, oneproductdata.id)}
                                // favourites
                                // isFavourite={isFavourite}
                                // onFavouritesClick={favouritesClickHandler}

                                />
                            </div>
                        )) : items.map((oneproductdata, index) => (
                            <div key={index} className='col-sm-6 col-lg-4 col-xxl-3 mb-2'>
                                <Card
                                    img={oneproductdata.image}
                                    name={oneproductdata.names[0].name}
                                    buttonText={t('View Appointment')}
                                    onClick={() => goToProduct(shopId, oneproductdata.id)}
                                // favourites
                                // isFavourite={isFavourite}
                                // onFavouritesClick={favouritesClickHandler}

                                />
                            </div>
                        ))}
                    </div>
                </div>
                {/* {view === t('View All') && items.map((oneproductdata, index) => (
                        <div className='col-sm-6 col-md-4 col-lg-3 mb-1 hstack gap-1'>
                        <div className="row border rounded m-1 vstack gap-2">
                        <div className="col sm-12">
                        <div className='w-100 p-2  mb-2'>
                        <div>
                        <img alt="img" src={oneproductdata?.image} className={style.blogImageSuper} />
                        </div>
                        <div className={`text-capitalize ${style.productName}`}>
                        {oneproductdata?.names[0].name}
                        </div>
                        
                                            <Button className={style.addToCartButton} onClick={() => goToProduct(shopId, oneproductdata?.id)} >{t("View Event")}</Button>
                                            </div>
                                            </div>
                                            </div>
                                            </div>
                                        ))}
                                        {view === t('View Less') && appointmentLists.map((oneproductdata, index) => (
                                            <div className='col-sm-6 col-md-6 col-lg-3 hstack gap-1'>
                                            <div className="row border rounded m-2">
                                            <div className="col sm-12">
                                            <div className='w-100 p-1'>
                                            <div>
                                            <img alt="img" src={oneproductdata?.image} className={style.blogImageSuper} />
                                            </div>
                                            <div className={`text-capitalize ${style.productName}`}>
                                            {oneproductdata?.names[0].name}
                                            </div>
                                            
                                            <Button className={style.addToCartButton} onClick={() => goToProduct(shopId, oneproductdata?.id)} >{t("View Event")}</Button>
                                            </div>
                                            </div>
                                            </div>
                                            </div>
                                        ))} */}

            </ShopProvider>}

        </UserLayout >
    );
}

export default ProductViewAppointment;