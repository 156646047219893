import { nanoid } from '@reduxjs/toolkit'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DEFAULT_LANGUAGES } from '../../config'
import { useT } from '../../i18n'
import { addLanguage, addSpice, updateSpice } from '../../redux/actions'
import { StoreState } from '../../redux/reducers'
import { Spice } from '../../redux/reducers/spice.reducer'
import { AppDispatch } from '../../redux/store'
import { toString } from '../../utils'
import { ToastType, useToast } from '../ToastProvider'
import ModalWithHeader from '../modals/ModalWithHeader'
import SelectableTileSection from '../sections/SelectableTileSection'
import ResponsiveFormInput from '../utils/ResponsiveFormInput'

interface SpiceFormProps {
    onClose?: () => void
    id?: string | null
}

interface Name {
    language: string
    name: string
    errorText: string
    id: string
}

const SpiceForm = (props: SpiceFormProps) => {
    const dispatch = useDispatch<AppDispatch>()
    const toast = useToast()
    const t = useT()

    const languages = useSelector<StoreState, string[]>(state => state.languages)
    const spice = useSelector<StoreState, Spice | null>(state => props.id ? state.spices.find(s => s.id === props.id) || null : null)

    const [selectedLanguages, setSelectedLanguages] = React.useState<string[]>(DEFAULT_LANGUAGES)
    const [names, setNames] = React.useState<Name[]>([])

    const [loading, setLoading] = React.useState<boolean>(false)

    const languageClickHandler = (lng?: string) => {
        if (lng && lng !== '') {
            setSelectedLanguages(prev => {
                return prev.some(p => p === lng)
                    ? prev.length > 1
                        ? prev.filter(p => p !== lng)
                        : prev
                    : [
                        ...prev,
                        lng
                    ]
            })

            setNames(prev => {
                return prev.some(p => p.language === lng)
                    ? prev.length > 1
                        ? prev.filter(p => p.language !== lng)
                        : prev
                    : [
                        ...prev,
                        {
                            language: lng,
                            name: '',
                            errorText: '',
                            id: nanoid()
                        }
                    ]
            })
        }
    }

    const addLanguageClickHandler = (language: string) => {
        dispatch(addLanguage(language))
    }

    const nameChangeHandler = (name: Name, value: string) => {
        setNames(prev => {
            const names = [...prev]
            const index = names.findIndex(p => p.id === name.id)

            if (index > -1) {
                names[index] = {
                    ...names[index],
                    name: value,
                    errorText: ''
                }
            }

            return names
        })
    }

    const submitHandler: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault()

        let error = false

        names.forEach((name, i) => {
            if (toString(name.name) === '') {
                error = true
                setNames(prev => {
                    const names = [...prev]
                    names[i].errorText = name.language.charAt(0).toUpperCase() + name.language.substring(1) + t('Name required')
                    return names
                })
            }
        })

        if (!error) {
            if (props.id && spice) {
                setLoading(true)
                dispatch(updateSpice({
                    names: names.map(n => ({ language: toString(n.language), name: toString(n.name) })),
                }, spice.id)).then(() => {
                    toast(t('Spice updated'))
                    props.onClose?.()
                }).catch(text => {
                    toast(text, ToastType.ERROR)
                }).finally(() => {
                    setLoading(false)
                })
            } else {
                setLoading(true)
                dispatch(addSpice({
                    names: names.map(n => ({ language: toString(n.language), name: toString(n.name) })),
                })).then(() => {
                    toast(t('Spice added'))
                    props.onClose?.()
                }).catch(text => {
                    toast(text, ToastType.ERROR)
                }).finally(() => {
                    setLoading(false)
                })
            }
        }
    }

    React.useEffect(() => {
        setNames(DEFAULT_LANGUAGES.map(lng => {
            return {
                errorText: '',
                id: nanoid(),
                language: lng,
                name: ''
            }
        }))
    }, [])

    React.useEffect(() => {
        if (spice && props.id) {
            setSelectedLanguages(() => {
                const lngs: string[] = []
                spice.names.forEach(n => {
                    if (lngs.indexOf(n.language) === -1) {
                        lngs.push(n.language)
                    }
                })
                return lngs
            })
            setNames(() => {
                const names: Name[] = []

                spice.names.forEach(n => {
                    if (!names.some(p => p.language === n.language)) {
                        names.push({
                            errorText: '',
                            id: n.id,
                            language: n.language,
                            name: n.name
                        })
                    }
                })

                return names
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [spice])

    return <ModalWithHeader
        buttonText={t('Save')}
        headerText={props.id ? t('Edit Spice') : t('Add Spice')}
        loading={loading}
        onClose={props.onClose}
        onSave={submitHandler}
        small
    >
        <div className='row mb-4'>
            <div className='col'>
                <SelectableTileSection
                    list={languages}
                    getSelectableTileProps={language => ({
                        key: language,
                        active: selectedLanguages.some(slng => slng === language),
                        onClick: languageClickHandler,
                        value: language,
                        label: language
                    })}
                    title={t('Language')}
                    instantInput={{
                        onAdd: addLanguageClickHandler,
                        label: t('Language')
                    }}
                />
            </div>
        </div>

        <div className='row'>
            <div className='col'>
                <div className='row'>
                    {names.map((n, i) => {
                        return <ResponsiveFormInput
                            responsiveClassName='col-12'
                            containerClass={names.length - 1 !== i ? 'mb-4' : ''}
                            type='text'
                            key={n.id}
                            label={n.language.charAt(0).toUpperCase() + n.language.slice(1) + t('Name')}
                            value={n.name}
                            onChange={e => nameChangeHandler(n, e.target.value)}
                            errorText={n.errorText}
                        />
                    })}
                </div>
            </div>
        </div>
    </ModalWithHeader>
}

export default SpiceForm