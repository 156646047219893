import * as Feather from 'react-feather'
import { useSelector } from 'react-redux'
import { useT } from '../../i18n'
import { CountReport } from '../../redux/actions/countReport.actions'
import { StoreState } from '../../redux/reducers'
import { Blog } from '../../redux/reducers/blog.reducer'
import style from '../../styles/pages/super-admin.module.scss'
import { numberFormat } from '../../utils'
import { useToast } from '../ToastProvider'
import ForkKnife from '../svgs/ForkKnife'
import ShoppingBagHand from '../svgs/ShoppingBagHand'
import StoreTwo from '../svgs/StoreTwo'
import TicketOne from '../svgs/TicketOne'
import UserMultiple from '../svgs/UserMultiple'

// import draftToHtml from 'draftjs-to-html'
// import { convertToRaw } from 'draft-js'

export interface BlogCardProps {
    blog?: Blog
    onMore?: () => void
}

// export const stripHTML=(myString:string)=> {
//     return myString.replace( /(<([^>]+)>)/ig, '');
//  }

const SuperAdminHomeCard = (props: BlogCardProps) => {

    const toast = useToast()
    const t = useT()
    const countReport = useSelector<StoreState, CountReport>(state => state.countReports)

    return <>
        <div className="row">
            <div className='col-md-5 col-lg-4 col-xl-4'>
                <div className='mb-3'>
                    <div className={style.homeContainer}>
                        <div className={style.countLabel}>
                            <h6>{t("Number of Shops")}</h6>
                            <StoreTwo stroke='#404040' strokeWidth={1} />
                        </div>
                        <div className={style.count}>{numberFormat(countReport.shopCount)}</div>
                    </div>
                </div>
            </div>
            <div className='col-md-5 col-lg-4 col-xl-4'>
                <div className='mb-3'>
                    <div className={style.homeContainer}>
                        <div className={style.countLabel}>
                            <h6>{t("Number of Users")}</h6>
                            <UserMultiple stroke='#404040' strokeWidth={1} />
                        </div>
                        <div className={style.count}>{numberFormat(countReport.userCount)}</div>
                    </div>
                </div>
            </div>
            <div className='col-md-5 col-lg-4 col-xl-4'>
                <div className='mb-3'>
                    <div className={style.homeContainer}>
                        <div className={style.countLabel}>
                            <h6>{t("Number of Food Orders")}</h6>
                            <ForkKnife stroke='#404040' strokeWidth={1} />
                        </div>
                        <div className={style.count}>{numberFormat(countReport.foodOrderCount)}</div>
                    </div>
                </div>
            </div>
            <div className='col-md-5 col-lg-4 col-xl-4'>
                <div className='mb-3'>
                    <div className={style.homeContainer}>
                        <div className={style.countLabel}>
                            <h6>{t("Number of Products Orders")}</h6>
                            <ShoppingBagHand stroke='#404040' strokeWidth={1} />
                        </div>
                        <div className={style.count}>{numberFormat(countReport.productOrderCount)}</div>
                    </div>
                </div>
            </div>
            <div className='col-md-5 col-lg-4 col-xl-4'>
                <div className='mb-3'>
                    <div className={style.homeContainer}>
                        <div className={style.countLabel}>
                            <h6>{t("Number of Reservations")}</h6>
                            <TicketOne stroke='#404040' strokeWidth={1} />
                        </div>
                        <div className={style.count}>{numberFormat(countReport.reservationCount)}</div>
                    </div>
                </div>
            </div>
            <div className='col-md-5 col-lg-4 col-xl-4'>
                <div className='mb-3'>
                    <div className={style.homeContainer}>
                        <div className={style.countLabel}>
                            <h6>{t("Number of Bookings")}</h6>
                            <Feather.BookOpen />
                        </div>
                        <div className={style.count}>{numberFormat(countReport.bookingCount)}</div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default SuperAdminHomeCard
