/* eslint-disable no-throw-literal */
import { Dispatch } from 'redux'
import { AddSizesAction, SizeActionTypes } from '.'
import { Response, api, apiErrorHandler, getApiRequestConfig } from '../../api'
import { GetState } from '../reducers'
import { Event, EventType } from '../reducers/event.reducer'
import { AddLanguagesAction, LanguageActionTypes } from './language.actions'

export enum EventActionTypes {
    ADD_EVENT = 'ADD_EVENT',
    UPDATE_EVENT = 'UPDATE_EVENT',
    FETCH_EVENTS = 'FETCH_EVENTS'
}

export interface FetchEventsAction {
    type: EventActionTypes.FETCH_EVENTS
    data: Event[]
}

export interface AddEventAction {
    type: EventActionTypes.ADD_EVENT
    data: Event
}

export interface UpdateEventAction {
    type: EventActionTypes.UPDATE_EVENT
    data: Event
}

export type EventActions = FetchEventsAction | AddEventAction | UpdateEventAction

export const fetchEvents = () => async (dispatch: Dispatch, getState: GetState) => {
    const token = getState().authAdmin?.['auth-token']
    const config = getApiRequestConfig(token)

    return api.get<Response<Event[]>>('events', config).then(response => {
        if (response.status === 200) {
            dispatch<FetchEventsAction>({
                type: EventActionTypes.FETCH_EVENTS,
                data: response.data.data
            })

            if (getState().authAdmin) {
                const sizes: string[] = []
                const languages: string[] = []

                response.data.data.forEach(d => {
                    d.prices && d.prices.forEach(p => {
                        sizes.push(p.size)
                    })

                    d.names && d.names.forEach(p => {
                        languages.push(p.language)
                    })
                })

                dispatch<AddSizesAction>({
                    type: SizeActionTypes.ADD_SIZES,
                    data: sizes
                })

                dispatch<AddLanguagesAction>({
                    type: LanguageActionTypes.ADD_LANGUAGES,
                    data: languages
                })
            }

            return Promise.resolve<string>(response.data.message || '')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to fetch'))
    })
}
export const fetchUserEvents = () => async (dispatch: Dispatch, getState: GetState) => {
    const token = getState().authUser?.['auth-token']
    const config = getApiRequestConfig(token)

    return api.get<Response<Event[]>>('user-events', config).then(response => {
        if (response.status === 200) {
            dispatch<FetchEventsAction>({
                type: EventActionTypes.FETCH_EVENTS,
                data: response.data.data
            })

            if (getState().authUser) {
                const sizes: string[] = []
                const languages: string[] = []

                response.data.data.forEach(d => {
                    d.prices && d.prices.forEach(p => {
                        sizes.push(p.size)
                    })

                    d.names && d.names.forEach(p => {
                        languages.push(p.language)
                    })
                })

                dispatch<AddSizesAction>({
                    type: SizeActionTypes.ADD_SIZES,
                    data: sizes
                })

                dispatch<AddLanguagesAction>({
                    type: LanguageActionTypes.ADD_LANGUAGES,
                    data: languages
                })
            }

            return Promise.resolve<string>(response.data.message || '')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to fetch'))
    })
}

export interface EventName {
    name: string
    language: string
}

export interface EventDescription {
    description: string
    language: string
}

export interface EventPrice {
    price: number
    offerPrice: number | null
    size: string
}

export interface AddEvent {
    names: EventName[]
    descriptions: EventDescription[]
    categories: string[]
    image: string
    location: string
    type: EventType
    tax: number | null
    additionalImages: string[]
    offerLabel: string | null,
    fromDate: string,
    fromTime: string,
    toDate: string,
    toTime: string,
    price: number | null
    foodPreferences: string[] | null
    volunteers:string[]|null
    cultureVouchers:string[]|null
    offerPrice: number | null
    prices: EventPrice[]
    eventRule:string|null
    eventStatus:string|null
    withoutRegistration?:string|null
    status?:string|null
}

export const addEvent = (input: AddEvent) => async (dispatch: Dispatch, getState: GetState) => {
    const token = getState().authAdmin?.['auth-token']
    const config = getApiRequestConfig(token)

    return api.post<Response<Event>>('event', input, config).then(response => {
        if (response.status === 200) {
            dispatch<AddEventAction>({
                type: EventActionTypes.ADD_EVENT,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || 'Event added')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable add food'))
    })
}

type UpdateEvent = AddEvent

export const updateEvent = (input: UpdateEvent, eventId: string) => async (dispatch: Dispatch, getState: GetState) => {
    const token = getState().authAdmin?.['auth-token']
    const config = getApiRequestConfig(token)

    return api.put<Response<Event>>(`event/${eventId}`, input, config).then(response => {
        if (response.status === 200) {
            dispatch<UpdateEventAction>({
                type: EventActionTypes.UPDATE_EVENT,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || 'Event updated')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to update'))
    })
}

export const updateEventStatus = (status: string, eventId: string | undefined) => async (dispatch: Dispatch, getState: GetState) => {
    const token = getState().authAdmin?.['auth-token']
    const config = getApiRequestConfig(token)

    return api.put<Response<Event>>(`event/update-status/${eventId}`, { status: status }, config).then(response => {
        if (response.status === 200) {
            dispatch<UpdateEventAction>({
                type: EventActionTypes.UPDATE_EVENT,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || 'Event updated')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to update'))
    })
}

export const updateEventPreivewStatus = (status: string, eventId: string | undefined) => async (dispatch: Dispatch, getState: GetState) => {
    const token = getState().authAdmin?.['auth-token']
    const config = getApiRequestConfig(token)

    return api.put<Response<Event>>(`event/update-previewStatus/${eventId}`, { status: status }, config).then(response => {
        if (response.status === 200) {
            dispatch<UpdateEventAction>({
                type: EventActionTypes.UPDATE_EVENT,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || 'Event updated')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to update'))
    })
}


export const updateEventBanner = (formData: any, eventId: string|undefined|null) => async (dispatch: Dispatch, getState: GetState) => {
    const token = getState().authAdmin?.['auth-token']
    const config = getApiRequestConfig(token)
    return api.put<Response<Event>>(`event/upload-image/${eventId}`, formData, config).then(response => {
        if (response.status === 200) {
            dispatch<UpdateEventAction>({
                type: EventActionTypes.UPDATE_EVENT,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || 'Event updated')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to update'))
    })
}