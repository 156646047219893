import { Action, OrderActionTypes } from '../actions'
import { Category } from './category.reducer'

export interface OrderReports {
    count: number
    date: string
    price: number
}


export interface OrderReport {
   _id:{
    category:Category[]
   }
   data:OrderReports[]
}

const initialState:OrderReport[] = []

const orderReportReducer = (state: OrderReport[] = initialState, action: Action):OrderReport[] => {
    switch (action.type) {
        case OrderActionTypes.FETCH_ORDER_REPORTS: {
            return action.data
        }
        default: {
            return state
        }
    }
}

export default orderReportReducer