import React from 'react'
import { useT } from '../../i18n'
import style from '../../styles/components/search.module.scss'
import SearchSvg from '../svgs/Search'

interface SearchProps {
    value?: string
    onChange?: React.ChangeEventHandler<HTMLInputElement>
    onFocus?: React.FocusEventHandler<HTMLInputElement>
    onBlur?: React.FocusEventHandler<HTMLInputElement>
    placeholder?: string
    innerRef?: React.RefObject<HTMLInputElement>
}

const Search = (props: SearchProps) => {
    const t = useT()

    return <React.Fragment>
        <div className='hstack gap-0'>
            <div className={style.iconWrapper}>
                <SearchSvg />
            </div>
            <input className={style.input} value={props.value} onChange={props.onChange} onFocus={props.onFocus} onBlur={props.onBlur} placeholder={props.placeholder || t('Search')} ref={props.innerRef} />
            <div className={style.button}>{t("Search")}</div>
        </div>
    </React.Fragment>
}

export default Search