/* eslint-disable no-throw-literal */
import { Dispatch } from 'redux'
import { api, apiErrorHandler, getApiRequestConfig, Response } from '../../api'
import { GetState } from '../reducers'
import { Reservation } from '../reducers/reservation.reducer'
import { ReservationReport } from '../reducers/reservationReport.reducer'

export enum ReservationActionTypes {
    ADD_RESERVATION = 'ADD_RESERVATION',
    FETCH_RESERVATIONS = 'FETCH_RESERVATIONS',
    REPLACE_RESERVATION = 'REPLACE_RESERVATION',
    FETCH_RESERVATION_REPORTS = 'FETCH_RESERVATION_REPORTS'
}

export interface AddReservationAction {
    type: ReservationActionTypes.ADD_RESERVATION
    data: Reservation
}

export interface FetchReservationsAction {
    type: ReservationActionTypes.FETCH_RESERVATIONS
    data: Reservation[]
}

export interface FetchReservationReportAction {
    type: ReservationActionTypes.FETCH_RESERVATION_REPORTS
    data: ReservationReport[]
}

export interface ReplaceReservationAction {
    type: ReservationActionTypes.REPLACE_RESERVATION
    data: Reservation
}

export type ReservationActions = AddReservationAction | FetchReservationsAction | ReplaceReservationAction | FetchReservationReportAction

export const addReservation = (input: Reservation): AddReservationAction => {
    return {
        type: ReservationActionTypes.ADD_RESERVATION,
        data: input
    }
}

export const updateReservation = (input: Reservation): ReplaceReservationAction => {
    return {
        type: ReservationActionTypes.REPLACE_RESERVATION,
        data: input
    }
}

export const fetchShopReservationsById = (reservationId: string) => async (dispatch: Dispatch, getState: GetState) => {
    const token = getState().authAdmin?.['auth-token']
    const config = getApiRequestConfig(token)

    return api.get<Response<Reservation[]>>(`shop/reservation/${reservationId}`, config).then(response => {
        if (response.status === 200) {
            dispatch<FetchReservationsAction>({
                type: ReservationActionTypes.FETCH_RESERVATIONS,
                data: response.data.data
            })

            return Promise.resolve<string>(response.data.message || '')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to fetch'))
    })
}

export const fetchShopReservations = () => async (dispatch: Dispatch, getState: GetState) => {
    const token = getState().authAdmin?.['auth-token']
    const config = getApiRequestConfig(token)

    return api.get<Response<Reservation[]>>('shop/reservations', config).then(response => {
        if (response.status === 200) {
            dispatch<FetchReservationsAction>({
                type: ReservationActionTypes.FETCH_RESERVATIONS,
                data: response.data.data
            })

            return Promise.resolve<string>(response.data.message || '')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to fetch'))
    })
}

export const fetchShopReservationReports = (eventId: string, fromDate: string, toDate: string) => async (dispatch: Dispatch, getState: GetState) => {
    const token = getState().authAdmin?.['auth-token']
    const config = getApiRequestConfig(token)

    return api.get<Response<ReservationReport[]>>(`shop/reservation-reports/${eventId}/${fromDate}/${toDate}`, config).then(response => {
        if (response.status === 200) {
            dispatch<FetchReservationReportAction>({
                type: ReservationActionTypes.FETCH_RESERVATION_REPORTS,
                data: response.data.data
            })

            return Promise.resolve<string>(response.data.message || '')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to fetch'))
    })
}
export const updateShopReservationParticipantStatus = (data: any, reservationId: string) => async (dispatch: Dispatch, getState: GetState) => {
    const token = getState().authAdmin?.['auth-token']
    const config = getApiRequestConfig(token)

    return api.put<Response<Reservation>>(`shop/reservation/participant-status/${reservationId}`, { ...data }, config).then(response => {
        if (response.status === 200) {
            dispatch<ReplaceReservationAction>({
                type: ReservationActionTypes.REPLACE_RESERVATION,
                data: response.data.data
            })

            return Promise.resolve<string>(response.data.message || 'Reservation is updated')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to update'))
    })
}

export const updateShopReservationStatus = (status: string, reservationId: string) => async (dispatch: Dispatch, getState: GetState) => {
    const token = getState().authAdmin?.['auth-token']
    const config = getApiRequestConfig(token)

    return api.put<Response<Reservation>>(`shop/reservation/update-status/${reservationId}`, { ReservationStatus: status }, config).then(response => {
        if (response.status === 200) {
            dispatch<ReplaceReservationAction>({
                type: ReservationActionTypes.REPLACE_RESERVATION,
                data: response.data.data
            })

            return Promise.resolve<string>(response.data.message || 'Reservation is updated')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to update'))
    })
}

export const updateShopReservationPayment = (status: string, reservationId: string) => async (dispatch: Dispatch, getState: GetState) => {
    const token = getState().authAdmin?.['auth-token']
    const config = getApiRequestConfig(token)

    return api.put<Response<Reservation>>(`shop/reservation/update-payment/${reservationId}`, { paymentStatus: status }, config).then(response => {
        if (response.status === 200) {
            dispatch<ReplaceReservationAction>({
                type: ReservationActionTypes.REPLACE_RESERVATION,
                data: response.data.data
            })

            return Promise.resolve<string>(response.data.message || 'Reservation is updated')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to update'))
    })
}