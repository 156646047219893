import { Dispatch } from 'redux'
import { api, apiErrorHandler, getApiRequestConfig, Response } from '../../api'
import { GetState } from '../reducers'
import { Feedback } from '../reducers/feedback.reducer'

export enum FeedbackActionTypes {
    ADD_FEEDBACK = 'ADD_FEEDBACK',
    UPDATE_FEEDBACK = 'UPDATE_FEEDBACK',
    FETCH_FEEDBACKS = 'FETCH_FEEDBACKS',
    DELETE_FEEDBACK = 'DELETE_FEEDBACK'
}

export interface AddFeedbackAction {
    type: FeedbackActionTypes.ADD_FEEDBACK
    data: Feedback
}

export interface UpdateFeedbackAction {
    type: FeedbackActionTypes.UPDATE_FEEDBACK
    data: Feedback
}

export interface FetchShopFeedbacksAction {
    type: FeedbackActionTypes.FETCH_FEEDBACKS
    data: Feedback[]
}

export interface DeleteFeedback {
    id: string
}

export interface DeleteFeedbackAction {
    type: FeedbackActionTypes.DELETE_FEEDBACK
    data: string
}

export type FeedbackActions = AddFeedbackAction | FetchShopFeedbacksAction | UpdateFeedbackAction | DeleteFeedbackAction

interface AddFeedback {
    feedback: string
    userId?: string
    status?: string
}

export const addFeedback = (input: AddFeedback) => async (dispatch: Dispatch, getState: GetState) => {
    const authUser = getState().authUser
    const config = getApiRequestConfig(authUser?.['auth-token'])

    return api.post<Response<Feedback>>('shop/feedback', input, config).then(response => {
        if (response.status === 200) {
            dispatch<AddFeedbackAction>({
                type: FeedbackActionTypes.ADD_FEEDBACK,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || 'Added')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to add'))
    })
}

export interface UpdateFeedback {
    feedback: string
    userId?: string
    status?: string
}

export const updateFeedback = (input: UpdateFeedback, FeedbackId: string) => async (dispatch: Dispatch, getState: GetState) => {
    const authAdmin = getState().authAdmin
    const config = getApiRequestConfig(authAdmin?.['auth-token'])

    return api.put<Response<Feedback>>(`feedback/${FeedbackId}`, input, config).then(response => {
        if (response.status === 200) {
            dispatch<UpdateFeedbackAction>({
                type: FeedbackActionTypes.UPDATE_FEEDBACK,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || 'Updated')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to updated'))
    })
}

export const fetchFeedbacks = (shopId: string) => async (dispatch: Dispatch, getState: GetState) => {
    const authUser = getState().authUser
    const config = getApiRequestConfig(authUser?.['auth-token'])

    return api.get<Response<Feedback[]>>(`shop/feedback/${shopId}`, config).then(response => {
        if (response.status === 200) {
            dispatch<FetchShopFeedbacksAction>({
                type: FeedbackActionTypes.FETCH_FEEDBACKS,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || '')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to fetch'))
    })
}

export const deleteFeedback = (FeedbackId: string) => async (dispatch: Dispatch, getState: GetState) => {
    const authSuperAdmin = getState().authSuperAdmin
    const config = getApiRequestConfig(authSuperAdmin?.['auth-token'])

    return api.delete(`feedback/${FeedbackId}`, config).then(response => {
        if (response.status === 200) {
            dispatch<DeleteFeedbackAction>({
                type: FeedbackActionTypes.DELETE_FEEDBACK,
                data: FeedbackId
            })
            return Promise.resolve<string>(response.data.message || 'Delete Feedback')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to delete'))
    })
}