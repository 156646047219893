import React, { useMemo } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useSelector } from 'react-redux'
import AdminLayout from '../../components/sections/AdminLayout'
import AdminPanelHeader from '../../components/sections/AdminPanelHeader'
import AdminOrder from '../../components/sections/Order'
import Spinner from '../../components/utils/Spinner'
import { AdminContext, AdminContextType } from '../../context/AdminProvider'
import { useT } from '../../i18n'
import { StoreState } from '../../redux/reducers'
import { Order, OrderStatus, PaymentStatus } from '../../redux/reducers/order.reducer'
// import style1 from '../../styles/components/order.module.scss'
import { AuthAdmin } from '../../redux/reducers/authAdmin.reducer'
import style1 from '../../styles/components/card.module.scss'
import style from '../../styles/pages/admin-orders.module.scss'
import { date, date as dateFunc } from '../../utils'

type OrderMenu = 'NEW' | 'PROCESS' | 'READY' | 'PAYMENT_PENDING' | 'FAILED' | 'ORDER GROUPING'

const getOrderMenu = (order: Order): OrderMenu | null => {
    if (order.paymentStatus === 'pending') {
        return 'PAYMENT_PENDING'
    }

    if (order.paymentStatus === 'failed') {
        return 'FAILED'
    }

    if (order.orderStatus === 'ordered') {
        if (order.shopId.otherPayments.findIndex(x => x.title === order.paymentMode) !== -1) {
            return 'NEW'
        }

        if (order.paymentMode === 'paytrail' && order.paymentStatus === 'paid') {
            return 'NEW'
        }

        if (order.paymentMode === 'free') {
            return 'NEW'
        }
    }


    if (order.orderStatus === 'processing') {
        return 'PROCESS'
    }

    if (order.orderStatus === 'ready') {
        return 'READY'
    }

    return null
}

const Orders = () => {
    const adminContext = React.useContext<AdminContextType | null>(AdminContext)
    const orders = useSelector<StoreState, Order[]>(state => state.orders)
    const t = useT()
    const [orderMenu, setOrderMenu] = React.useState<OrderMenu>('PAYMENT_PENDING')
    const authAdmin = useSelector<StoreState, AuthAdmin | null>(state => state.authAdmin)
    const shopCategory = authAdmin?.category
    const filteredOrders = orders.filter(order => getOrderMenu(order) === orderMenu)
    const [pages, setPages] = React.useState(Array.from({ length: 0 }))
    const [hasMore, setHasMore] = React.useState(true)

    interface GroupedData {
        shopId: string
        productTitle: string
        count: number
        orderDate: string
        paymentPendingCount: number
        paymentStatus: PaymentStatus
        orderStatus: OrderStatus
        paymentPaidCount: number
    }

    const orderGroupedData = useMemo<GroupedData[]>(() => {
        const groupedData: GroupedData[] = []

        const OrderItems = orders.map(o => o.orderItems).flat(1)
        const OrderDates = orders.map(o => dateFunc('Y-Mn-d', o.orderDate)).filter((val, id, array) => {
            return array.indexOf(val) === id;
        });
        const productNames = OrderItems.map(oi => oi.title).filter((val, id, array) => {
            return array.indexOf(val) === id;
        });

        OrderDates.forEach(date => {
            productNames.forEach(pname => {
                orders.filter(f => dateFunc('Y-Mn-d', f.orderDate) === date).forEach(od => {

                    let data = od.orderItems.filter(p => p.title === pname)
                    if (data.length > 0) {
                        let count = data.reduce((previous, current) => {
                            return previous + (current.quantity)
                        }, 0)

                        let paymentPendingCount = 0;
                        if (od.paymentStatus === 'pending') {
                            paymentPendingCount += count;
                        }

                        let paymentPaidCount = 0;
                        if (od.paymentStatus === 'paid') {
                            paymentPaidCount += count;
                        }

                        let index = groupedData.findIndex(g => g.orderDate === date && g.productTitle === pname && g.shopId === od.shopId.id)

                        if (index > -1) {
                            groupedData[index].count += count;
                            groupedData[index].paymentPendingCount += paymentPendingCount;
                            groupedData[index].paymentPaidCount += paymentPaidCount;
                        } else {
                            groupedData.push({
                                orderDate: dateFunc('Y-Mn-d', od.orderDate),
                                productTitle: pname,
                                shopId: od.shopId.id,
                                count: count,
                                paymentPendingCount: paymentPendingCount,
                                paymentPaidCount: paymentPaidCount,
                                paymentStatus: od.paymentStatus,
                                orderStatus: od.orderStatus,
                            })
                        }

                    }
                })
            })
        })

        return groupedData

    }, [orders])

    const fetchMoreData = () => {
        if (pages.length <= filteredOrders.length) {
            setTimeout(() => {
                setPages(pages.concat(Array.from({ length: 20 })))
            }, 1000)
            return
        }
        setHasMore(false)
    }
    React.useEffect(() => {
        fetchMoreData()
    }, [])


    return <AdminLayout>
        <div className='mb-4 mt-2'>
            <AdminPanelHeader title='Orders' />
            <div className='row'>
                <div className='col-sm-12 col-lg-2 col-xl-2 mb-2'>
                    <OrderMenuLink active={orderMenu === 'PAYMENT_PENDING'} onClick={() => setOrderMenu('PAYMENT_PENDING')}>{t("PAYMENT PENDING")} ({orders.filter(order => getOrderMenu(order) === "PAYMENT_PENDING").length})</OrderMenuLink>
                </div>
                <div className='col-sm-12 col-lg-2 col-xl-2 mb-2'>
                    <OrderMenuLink active={orderMenu === 'NEW'} onClick={() => setOrderMenu('NEW')}>{t("NEW")} ({orders.filter(order => getOrderMenu(order) === "NEW").length})</OrderMenuLink>
                </div>
                <div className='col-sm-12 col-lg-2 col-xl-2 mb-2'>
                    <OrderMenuLink active={orderMenu === 'PROCESS'} onClick={() => setOrderMenu('PROCESS')}>{t("PROCESS")} ({orders.filter(order => getOrderMenu(order) === "PROCESS").length})</OrderMenuLink>
                </div>
                <div className='col-sm-12 col-lg-2 col-xl-2 mb-2'>
                    <OrderMenuLink active={orderMenu === 'READY'} onClick={() => setOrderMenu('READY')}>{t("READY")} ({orders.filter(order => getOrderMenu(order) === "READY").length})</OrderMenuLink>
                </div>
                <div className='col-sm-12 col-lg-2 col-xl-2 mb-2'>
                    <OrderMenuLink active={orderMenu === 'FAILED'} onClick={() => setOrderMenu('FAILED')}>{t("FAILED")} ({orders.filter(order => getOrderMenu(order) === "FAILED").length})</OrderMenuLink>
                </div>
                {shopCategory === "Product" && <div className='col-sm-12 col-lg-2 col-xl-2 mb-2'>
                    <OrderMenuLink active={orderMenu === 'ORDER GROUPING'} onClick={() => setOrderMenu('ORDER GROUPING')}>{t("ORDER GROUPING")}</OrderMenuLink>
                </div>}
            </div>
            {adminContext?.fetchingOrders
                ? <Spinner size={25} color='#ec3b50' className='mt-3' />
                :
                orderMenu === 'ORDER GROUPING' ?
                    <div className='table-wrapper'>
                        <table className='table'>
                            <thead className="table-primary">
                                <tr>
                                    <th className={`align-middle ${style1.tableStyle}`}>{t("DATE")}</th>
                                    <th className={`align-middle ${style1.tableStyle}`}>{t("ORDER ITEMS")}</th>
                                    <th className={`align-middle ${style1.tableStyle}`}>{("PAYMENT PENDING")}</th>
                                    <th className={`align-middle ${style1.tableStyle}`}>{("NEW ORDERS")}</th>
                                    <th className={`align-middle text-center ${style1.tableStyle}`}>{t("NUMBER OF ITEMS")}</th>
                                </tr>
                            </thead>
                            {orderGroupedData
                                .filter(f => f.orderStatus !== 'processing' && f.orderStatus !== 'ready')
                                .reverse()
                                .slice(0, pages.length)
                                .map((o, index) => (
                                    <tbody key={index}>
                                        <tr>
                                            <td className={`align-middle text-start ${style1.tableStyle} text-capitalize`}>
                                                {date('MON d, Y', o.orderDate)}
                                            </td>
                                            <td className={`align-middle text-start ${style1.tableStyle}`}>
                                                {o.productTitle}
                                            </td>
                                            <td className={`align-middle text-start ${style1.tableStyle}`}>
                                                {o.paymentPendingCount}
                                            </td>
                                            <td className={`align-middle text-start ${style1.tableStyle}`}>
                                                {o.paymentPaidCount}
                                            </td>
                                            <td className={`align-middle text-start ${style1.tableStyle}`}>
                                                {o.count}
                                            </td>
                                        </tr>
                                    </tbody>
                                ))
                            }
                        </table>
                    </div>

                    : filteredOrders.length !== 0 && <InfiniteScroll dataLength={pages.length} scrollableTarget="scrollableDiv" next={fetchMoreData} hasMore={hasMore} loader={<p className={style.infiniteScrollText}>{t("Fetching orders...")}</p>} endMessage={<p className={style.infiniteScrollText}>{t("All orders fetched")}</p>}>
                        {filteredOrders.reverse().slice(0, pages.length).map(order => {
                            return <AdminOrder
                                key={order.id}
                                order={order}
                                role='ADMIN'
                            />
                        })}
                    </InfiniteScroll>
            }

        </div>
    </AdminLayout>
}

interface MenuLinkProps {
    children?: React.ReactNode
    active?: boolean
    onClick?: () => void
}

const OrderMenuLink = (props: MenuLinkProps) => {
    return <div className={`${style.orderMenuLink} ${props.active ? style.active : ''}`} children={props.children} onClick={props.onClick} />
}

export default Orders