import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deliveryApi, getApiRequestConfig } from '../../api'
import { ONE_MINUTE, ONE_SECOND } from '../../config'
import { AppContext } from '../../context/AppProvider'
import { useT } from '../../i18n'
import { fetchShops, setOrderProcessTime, updateOrder, updateOrderStatus } from '../../redux/actions'
import { StoreState } from '../../redux/reducers'
import { AuthAdmin } from '../../redux/reducers/authAdmin.reducer'
import { Buyer, OrderItemExtraTopping, Order as OrderObj, OrderType, PaymentMode, PaymentStatus } from '../../redux/reducers/order.reducer'
import { Pickup, Shop } from '../../redux/reducers/shop.reducer'
import { AppDispatch } from '../../redux/store'
import style from '../../styles/components/order.module.scss'
import { date, toCurrencyFormat } from '../../utils'
import { ToastType, useToast } from '../ToastProvider'

type OrderRole = 'USER' | 'ADMIN'

interface AdminOrderProps {
    order: OrderObj
    role: OrderRole
    // shop?: Shop[]
}

const Order = (props: AdminOrderProps) => {
    const t = useT()
    const dispatch = useDispatch<AppDispatch>()
    const appContext = React.useContext(AppContext)
    const shops = useSelector<StoreState, Shop[] | null>(state => state.shops)
    const orderShop = shops?.find(s => s?.id?.toString() === props?.order?.shopId?.id?.toString())
    useEffect(() => {
        dispatch(fetchShops())
    }, [])

    const paymentVerifyClickHandler = (status: string) => {
        appContext?.showOverlay?.()
        dispatch(updateOrderStatus(status, props?.order?.id)).finally(() => {
            appContext?.hideOverlay?.()
        })
    }

    return <div className='card my-3 shadow-sm'>
        <div className='card-header bg-white'>
            <div className='row'>
                <div className='col-sm-4 d-flex align-items-center'>
                    <div className={style.orderNumber}>#{props.order.orderNumber}</div>
                </div>
                <div className='col-sm-4 d-flex align-items-center my-1'>
                    <div className={style.orderStatus}>{props.order.orderStatus}</div>
                </div>
                <div className='col-sm-4 d-flex align-items-center'>
                    <div className={style.orderDate}>{date('MON d, Y h:i Z', props.order.orderDate)}</div>
                </div>
            </div>
        </div>
        <div className='card-body'>
            <div className='row'>
                <div className='col-sm-5'>

                    <div className='fw-bold text-uppercase'>{orderShop?.name}</div>
                    <div className={`${style.sectionHeader} mb-2`}>{t("Order Items")}</div>

                    {props.order.orderItems.map((item, index) => {
                        return <OrderItem
                            key={item.id}
                            price={item.price}
                            quantity={item.quantity}
                            title={item.title}
                            comment={item.comment}
                            alternateToppings={item.alternateToppings.join(', ')}
                            defaultToppings={item.defaultToppings.join(', ')}
                            size={item.size}
                            extraToppings={item.extraToppings}
                            spices={item.spices.join(', ')}
                            isLast={index === props.order.orderItems.length - 1}
                            currency={orderShop?.currency}
                        />
                    })}

                    {props.order.additionalInfo && <div>
                        <div className='fw-bold text-uppercase mt-2'>{t("Additional order information")} </div>
                        <li>{props.order.additionalInfo}</li>
                    </div>}

                </div>
                <div className='col-sm-7'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <PriceDetails
                                billAmount={props.order.billAmount}
                                deliveryCharge={props.order.deliveryCharge}
                                orderType={props.order.orderType}
                                totalAmount={props.order.totalAmount}
                                totalDiscount={props.order.totalDiscount}
                                totalTax={props.order.totalTax}
                                currency={orderShop?.currency}
                            />
                            <OtherDetails
                                orderType={props.order.orderType}
                                paymentMode={props.order.paymentMode}
                                paymentStatus={props.order.paymentStatus}
                            />
                        </div>
                        <div className='col-md-6'>
                            <CustomerDetails
                                buyer={props.order.buyer}
                                role={props.role}
                            />
                            <PickupPoints
                                pickup={props.order.pickup}
                            />
                        </div>
                    </div>
                    {props.role === 'ADMIN' && props.order.paymentStatus === "pending" &&
                        <>
                            <div className={style.sectionHeader}>{t("Update Status")}</div>
                            <div className='hstack flex-wrap gap-2'>
                                <div className={style.bookingStatus} onClick={() => paymentVerifyClickHandler('paid')}>
                                    <span>{t("Payment Verify")}</span>
                                </div>
                            </div>
                        </>
                    }
                    {props.role === 'ADMIN' && props.order.paymentStatus !== "pending" && <ProcessTime order={props.order} />}
                </div>
            </div>
        </div>
    </div>
}

interface OrderItemProps {
    title: string
    quantity: number
    price: number
    comment: string | null
    alternateToppings: string
    defaultToppings: string
    extraToppings: OrderItemExtraTopping[]
    size: string | null
    spices: string
    isLast: boolean
    currency?: string
}

const OrderItem = (props: OrderItemProps) => {
    const t = useT()

    return <React.Fragment>
        <div className={style.itemTitle}>{props.title} {props.size && <span className='text-capitalize'>({props.size})</span>}</div>
        <div className={style.price}>{props.quantity} x {toCurrencyFormat(props.price, props.currency)} = {toCurrencyFormat(props.quantity * props.price, props.currency)}</div>

        {(!!props.extraToppings.length || props.defaultToppings || props.alternateToppings || props.spices || props.comment) && <table className='table table-bordered m-0'>
            <thead>
                <tr>
                    {!!props.extraToppings.length && <th className={style.itemExtraDetailsHeader}>{t("Extra toppings")}</th>}
                    {props.defaultToppings && <th className={style.itemExtraDetailsHeader}>{t("Toppings")}</th>}
                    {props.alternateToppings && <th className={style.itemExtraDetailsHeader}>{t("Alternates")}</th>}
                    {props.spices && <th className={style.itemExtraDetailsHeader}>{t("Spices")}</th>}
                </tr>
            </thead>
            <tbody>
                <tr>
                    {!!props.extraToppings.length && <td className={style.itemExtraDetails}>
                        {props.extraToppings.map((et, index) => {
                            return <div className={style.itemExtraDetails} key={index}>{index + 1}. {et.name} - {toCurrencyFormat(et.price)}</div>
                        })}
                    </td>}
                    {props.defaultToppings && <td className={style.itemExtraDetails}>{props.defaultToppings}</td>}
                    {props.alternateToppings && <td className={style.itemExtraDetails}>{props.alternateToppings}</td>}
                    {props.spices && <td className={style.itemExtraDetails}>{props.spices}</td>}
                </tr>
                {props.comment && <tr>
                    <th className={style.itemExtraDetailsHeader}>{t("Comments")}</th>
                    <td className={style.itemExtraDetails} colSpan={3}>
                        <ul>
                            {props.comment.split(',').map((comment, index) => (
                                <li key={index}>{comment.trim()}</li>
                            ))}
                        </ul>
                    </td>
                </tr>}
            </tbody>
        </table>}
        {!props.isLast && <hr />}
    </React.Fragment>
}

interface ProcessTimeProps {
    order: OrderObj
}

const ProcessTime = (props: ProcessTimeProps) => {
    const t = useT()
    const toast = useToast()
    const appContext = React.useContext(AppContext)
    const authAdmin = useSelector<StoreState, AuthAdmin | null>(state => state.authAdmin)
    const orderShop = authAdmin?.shop
    const dispatch = useDispatch<AppDispatch>()

    const [processTimer, setProcessTimer] = React.useState<string>('')

    const processTimeClickHandler = (time: number) => {
        appContext?.showOverlay?.()
        dispatch(setOrderProcessTime(time, props.order.id)).finally(() => {
            appContext?.hideOverlay?.()
        })
    }

    React.useEffect(() => {
        let interval: NodeJS.Timer

        if (props.order.processTime && props.order.processStartedAt && props.order.orderStatus === 'processing') {
            interval = setInterval(() => {
                const willEndAt = new Date(props.order.processStartedAt!).getTime() + (props.order.processTime! * ONE_MINUTE)
                const ms = willEndAt - Date.now()

                if (ms >= 0) {
                    const m = Math.floor(ms / ONE_MINUTE).toString().padStart(2, '0')
                    const s = Math.trunc((ms % ONE_MINUTE) / ONE_SECOND).toString().padStart(2, '0')
                    setProcessTimer(m + ':' + s)
                } else {
                    dispatch(updateOrder({
                        ...props.order,
                        orderStatus: 'ready'
                    }))

                    if (orderShop?.delivery.api.authToken) {
                        const config = getApiRequestConfig(orderShop?.delivery.api.authToken);
                        const data = {
                            orderNumber: props.order.orderNumber,
                            name: props.order.buyer.firstName + ' ' + props.order.buyer.lastName,
                            address: props.order.buyer.address.addressLine1 + ' ' + props.order.buyer.address.addressLine2,
                            postalOffice: props.order.buyer.address.city,
                            postalCode: props.order.buyer.address.pincode,
                            phoneNumber: props.order.buyer.phoneNumber,
                            distance: 0,
                            itemCount: props.order.orderItems.length,
                            items: props.order.orderItems,
                            weight: 0,
                            billAmount: props.order.billAmount,
                            paymentType: props.order.paymentMode,
                            paymentStatus: props.order.paymentStatus,
                            deliveryCharge: props.order.deliveryCharge || 0
                        }
                        deliveryApi.post('shop/deliveryRequest', data, config).then((res) => { toast("Order is ready", ToastType.SUCCESS) }).catch((ex) => {
                            console.log(ex);
                        })
                    }
                    clearInterval(interval)
                }
            }, ONE_SECOND)
        }

        return () => clearInterval(interval)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.order.orderStatus, props.order.processStartedAt, props.order.processTime])

    return <React.Fragment>
        {(props.order.orderStatus === 'ordered' || props.order.orderStatus === 'processing') && <React.Fragment>
            <hr />
            <div className={style.sectionHeader}>{t("Process time")}</div>

            {props.order.orderStatus === 'ordered' && <div className='hstack flex-wrap gap-2'>
                {authAdmin?.shop.processTimes.map((time, index) => {
                    return <div className={style.processTime} key={index} onClick={() => processTimeClickHandler(time)}>
                        <span>{time}</span>
                    </div>
                })}
            </div>}

            {props.order.processTime && props.order.processStartedAt && props.order.orderStatus === 'processing' && <div>{t("Order will be ready in")}: {processTimer}</div>}
        </React.Fragment>}
    </React.Fragment>
}

interface CustomerDetailsProps {
    buyer: Buyer
    role: OrderRole
}

const CustomerDetails = (props: CustomerDetailsProps) => {
    const t = useT()

    return <React.Fragment>
        <div className={style.sectionHeader}>{props.role === 'ADMIN' ? t('Customer') : t('Contact')} {t("Detail")}</div>
        <div className={style.customerName}>{props.buyer.firstName} {props.buyer.lastName}</div>
        <div className={style.customerDetails}>{props.buyer.address.addressLine1}, {props.buyer.address.addressLine2}, {props.buyer.address.city}, {props.buyer.address.state} - {props.buyer.address.pincode}</div>
        {props.buyer.address.landmark && <div className={style.customerDetails}>{props.buyer.address.landmark}</div>}
        <div className={style.email}>{props.buyer.email}</div>
        <div className={style.customerDetails}>{props.buyer.phoneNumber}</div>
    </React.Fragment>
}
interface PickupPointProps {
    pickup: Pickup
    // role: OrderRole
}
const PickupPoints = (props: PickupPointProps) => {
    const t = useT()

    return <React.Fragment>
        <div className={style.sectionHeader}>PICKUP POINT</div>
        <div className={style.customerDetails}>{props.pickup.addressLine1}, {props.pickup.addressLine2}, {props.pickup.city}, {props.pickup.state} - {props.pickup.pincode}</div>
        {props.pickup.landmark && <div className={style.customerDetails}>{props.pickup.landmark}</div>}
        <div className={style.customerDetails}>{props.pickup.phoneNumber}</div>
    </React.Fragment>
}

interface PriceDetailsProps {
    totalAmount: number
    totalDiscount: number | null
    totalTax: number | null
    orderType: OrderType
    deliveryCharge: number | null
    billAmount: number
    currency?: string
}

const PriceDetails = (props: PriceDetailsProps) => {
    const t = useT()

    return <React.Fragment>
        <div className={style.sectionHeader}>{t("Price details")}</div>
        <table>
            <tbody>
                <tr>
                    <td className={style.priceLabel}>{t("Total Amount")}:</td>
                    <td className={style.priceValue}>{toCurrencyFormat(props.totalAmount, props.currency)}</td>
                </tr>
                {props.totalDiscount && <tr>
                    <td className={style.priceLabel}>{t("Discount")}:</td>
                    <td className={style.priceValue}>{toCurrencyFormat(props.totalDiscount, props.currency)}</td>
                </tr>}
                {props.totalTax && <tr>
                    <td className={style.priceLabel}>{t("Tax")}:</td>
                    <td className={style.priceValue}>{toCurrencyFormat(props.totalTax, props.currency)}</td>
                </tr>}
                {props.orderType === 'home' && props.deliveryCharge && <tr>
                    <td className={style.priceLabel}>{t("Delivery Charge")}:</td>
                    <td className={style.priceValue}>{toCurrencyFormat(props.deliveryCharge, props.currency)}</td>
                </tr>}
            </tbody>
        </table>
        <table className='border-top pt-2 mt-2 d-inline-block'>
            <tbody>
                <tr>
                    <td className={style.billAmountLabel}>{t("Bill Amount")}:</td>
                    <td className={style.billAmount}>{toCurrencyFormat(props.billAmount, props.currency)}</td>
                </tr>
            </tbody>
        </table>
    </React.Fragment>
}

interface OtherDetailsProps {
    orderType: OrderType
    paymentStatus: PaymentStatus
    paymentMode: PaymentMode
}

const OtherDetails = (props: OtherDetailsProps) => {
    const t = useT()

    return <table className='pt-2 mt-2'>
        <tbody>
            <tr>
                <td className={style.priceLabel}>{t("Order type")}:</td>
                <td className={style.priceValue}>{props.orderType === 'home' ? 'Delivery' : 'Pick up'}</td>
            </tr>
            <tr>
                <td className={style.priceLabel}>{t("Payment")}:</td>
                <td className={style.priceValue}>{props.paymentStatus}</td>
            </tr>
            <tr>
                <td className={style.priceLabel}>{t("Mode")}:</td>
                <td className={style.priceValue}>{props.paymentMode}</td>
            </tr>
        </tbody>
    </table>
}

export default Order