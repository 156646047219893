import { Action, SpiceActionTypes } from '../actions'

export interface SpiceName {
    language: string
    name: string
    createdAt: string
    updatedAt: string
    id: string
}

export interface Spice {
    names: SpiceName[]
    shopId: string
    createdAt: string
    updatedAt: string
    id: string
}

const initialState: Spice[] = []

const spiceReducer = (state: Spice[] = initialState, action: Action): Spice[] => {
    switch (action.type) {
        case SpiceActionTypes.ADD_SPICE: {
            return [
                ...state,
                action.data
            ]
        }

        case SpiceActionTypes.UPDATE_SPICE: {
            const spices = [...state]
            const index = spices.findIndex(c => c.id === action.data.id)

            if (index > -1) {
                spices[index] = {
                    ...action.data
                }
            }

            return spices
        }

        case SpiceActionTypes.FETCH_SHOP_SPICES: {
            return action.data
        }

        default: {
            return state
        }
    }
}

export default spiceReducer