import style from '../../styles/components/add-more.module.scss'
import Plus from './Plus'

interface AddMoreProps {
    errorText?: string
    title?: string
    onClick?: () => void
}

const AddMore = (props: AddMoreProps) => {
    return <div className={`${style.container} rounded shadow-sm ${props.errorText && props.errorText !== '' ? style.error : ''}`} title={props.title} onClick={props.onClick}>
        <div className={style.content}>
            <Plus />
        </div>
    </div>
}

export default AddMore