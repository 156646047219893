import React from 'react'
import { useDispatch } from 'react-redux'
import { Response, api, apiErrorHandler } from '../../api'
import { CATEGORY_OPTIONS, CURRENCY } from '../../config'
import { useT } from '../../i18n'
import { AdminCategory, fetchDivisions, loginAdmin, registerShop } from '../../redux/actions'
// import { AdminCategory, loginAdmin, registerShop } from '../../redux/actions'
// import { fetchDivisions } from '../../redux/actions/division.actions'
// import { StoreState } from '../../redux/reducers'
// import { Division } from '../../redux/reducers/division.reducer'
import { AdminPlan } from '../../redux/reducers/authAdmin.reducer'
import { AppDispatch } from '../../redux/store'
import style from '../../styles/components/authenticate.module.scss'
import formStyle from '../../styles/components/form.module.scss'
import { testNumber, toString } from '../../utils'
import { ToastType, useToast } from '../ToastProvider'
import { AuthenticateType } from '../modals/Authenticate'
import Eye from '../svgs/Eye'
import EyeOff from '../svgs/EyeOff'
import Button from '../utils/Button'
import FormInput from '../utils/FormInput'
import FormTextArea from '../utils/FormTextArea'
import Select from '../utils/Select'

interface AdminAuthenticateFormProps {
    onAuthenticateTypeChange?: (value: AuthenticateType) => void
    authenticateType?: AuthenticateType
}

const AdminAuthenticateForm = (props: AdminAuthenticateFormProps) => {
    const dispatch = useDispatch<AppDispatch>()
    const toast = useToast()
    const t = useT()

    const [name, setName] = React.useState<string>('')
    const [nameError, setNameError] = React.useState<string>('')
    const [username, setUsername] = React.useState<string>('')
    const [usernameError, setUsernameError] = React.useState<string>('')
    const [email, setEmail] = React.useState<string>('')
    const [emailError, setEmailError] = React.useState<string>('')
    const [password, setPassword] = React.useState<string>('')
    const [passwordError, setPasswordError] = React.useState<string>('')
    const [shopName, setShopName] = React.useState<string>('')
    const [shopNameError, setShopNameError] = React.useState<string>('')
    const [businessName, setBusinessName] = React.useState<string>('')
    const [businessNameError, setBusinessNameError] = React.useState<string>('')
    const [websiteUrl, setWebsiteUrl] = React.useState<string>('')
    const [websiteUrlError, setWebsiteUrlError] = React.useState<string>('')
    const [category, setCategory] = React.useState<string>('')
    const [categoryError, setCategoryError] = React.useState<string>('')
    const [addressLine1, setAddressLine1] = React.useState<string>('')
    const [addressLine1Error, setAddressLine1Error] = React.useState<string>('')
    const [addressLine2, setAddressLine2] = React.useState<string>('')
    const [addressLine2Error, setAddressLine2Error] = React.useState<string>('')
    const [pincode, setPincode] = React.useState<string>('')
    const [pincodeError, setPincodeError] = React.useState<string>('')
    const [landmark, setLandmark] = React.useState<string>('')
    const [landmarkError, setLandmarkError] = React.useState<string>('')
    const [currency, setCurrency] = React.useState<string>('')
    const [currencyError, setCurrencyError] = React.useState<string>('')
    const [state, setState] = React.useState<string>('')
    const [stateError, setStateError] = React.useState<string>('')
    const [city, setCity] = React.useState<string>('')
    const [cityError, setCityError] = React.useState<string>('')
    const [description, setDescription] = React.useState<string>('')
    const [descriptionError, setDescriptionError] = React.useState<string>('')
    const [plan, setPlan] = React.useState<string>('')
    const [planError, setPlanError] = React.useState<string>('')

    const [showShopInformation, setShowShopInformation] = React.useState<boolean>(false)

    const [showPassword, setShowPassword] = React.useState<boolean>(false)
    const [loading, setLoading] = React.useState<boolean>(false)

    const nameChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setName(e.target.value)
        setNameError('')
    }


    const usernameChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setUsername(e.target.value)
        setUsernameError('')
    }

    const emailChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setEmail(e.target.value)
        setEmailError('')
    }

    const passwordChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setPassword(e.target.value)
        setPasswordError('')
    }

    const shopNameChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setShopName(e.target.value)
        setShopNameError('')
    }

    const businessNameChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setBusinessName(e.target.value)
        setBusinessNameError('')
    }

    const websiteUrlChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setWebsiteUrl(e.target.value)
        setWebsiteUrlError('')
    }

    const categoryChangeHandler: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
        setCategory(e.target.value)
        setCategoryError('')
    }

    const addressLine1ChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setAddressLine1(e.target.value)
        setAddressLine1Error('')
    }

    const addressLine2ChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setAddressLine2(e.target.value)
        setAddressLine2Error('')
    }

    const pincodeChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        if ((!testNumber(e.target.value) && e.target.value))
            return
        else if (e.target.value.length >= 7)
            return

        setPincode(e.target.value)
        setPincodeError('')
    }

    const cityChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const value = e.target.value;
        const regex = /^[A-Za-z]*$/;

        if (regex.test(value)) {
            setCity(value);
            setCityError('');
        } else {
            setCityError('Please enter only alphabets');
        }
    }

    const landmarkChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setLandmark(e.target.value)
        setLandmarkError('')
    }

    const currencyChangeHandler: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
        setCurrency(e.target.value)
        setCurrencyError('')
    }

    const descriptionChangeHandler: React.ChangeEventHandler<HTMLTextAreaElement> = (e) => {
        setDescription(e.target.value)
        setDescriptionError('')
    }

    const stateChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const value = e.target.value;
        const regex = /^[A-Za-z]*$/;

        if (regex.test(value)) {
            setState(value);
            setStateError('');
        } else {
            setStateError('Please enter only alphabets');
        }
    }

    const planChangeHandler: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
        setPlan(e.target.value)
        setPlanError('')
    }

    const clearState = () => {
        setName('')
        setNameError('')
        setUsername('')
        setUsernameError('')
        setEmail('')
        setEmailError('')
        setPassword('')
        setPasswordError('')
        setShopName('')
        setShopNameError('')
        setBusinessName('')
        setBusinessNameError('')
        setWebsiteUrl('')
        setWebsiteUrlError('')
        setCategory('')
        setCategoryError('')
        setAddressLine1('')
        setAddressLine1Error('')
        setAddressLine2('')
        setAddressLine2Error('')
        setPincode('')
        setPincodeError('')
        setLandmark('')
        setLandmarkError('')
        setPlan('')
        setPlanError('')
        setCurrency('')
        setCurrencyError('')
        setState('')
        setStateError('')
        setCity('')
        setCityError('')
        setDescription('')
        setDescriptionError('')
        setShowPassword(false)
        setLoading(false)
    }

    const submitHandler: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault()

        let error = false

        if (props.authenticateType === AuthenticateType.REGISTER) {
            if (toString(name) === '') {
                setNameError(t('Name required'))
                error = true
            }

            if (toString(email) === '') {
                setEmailError(t('Email required'))
                error = true
            }

            if (toString(shopName) === '') {
                setShopNameError(t('Shop name required'))
                error = true
            }

            if (toString(category) === '') {
                setCategoryError(t('Category required'))
                error = true
            }

            if (toString(pincode) === '') {
                setPincodeError(t('Pincode required'))
                error = true
            } else if (!testNumber(pincode)) {
                setPincodeError(t('Pincode must be a number'))
                error = true
            }

            if (toString(city) === '') {
                setCityError(t('City required'))
                error = true
            }

            if (toString(description) === '') {
                setDescriptionError(t('Message required'))
                error = true
            }

            if (toString(currency) === '') {
                setCurrencyError(t('Currency required'))
                error = true
            }

            if (toString(addressLine1) === '') {
                setAddressLine1Error(t('Address line 1 required'))
                error = true
            }

            if (toString(addressLine2) === '') {
                setAddressLine2Error(t('Address line 2 required'))
                error = true
            }

            if (toString(state) === '') {
                setStateError(t('State required'))
                error = true
            }

            if (toString(plan) === '') {
                setPlanError(t('Plan required'))
                error = true
            }
            // if (password === '') {
            //     setPasswordError(t('Password required'))
            //     error = true
            // }
            // else if (password.length < 8) {
            //     setPasswordError('Password is too short - should be 8 chars minimum')
            //     error = true
            // } else if (password.length > 15) {
            //     setPasswordError('Password is too long - should be 15 chars maximum')
            //     error = true
            // } else if (!testPassword(password)) {
            //     setPasswordError('Password can only contain alphanumeric characters, underscore and first character must be a letter')
            //     error = true
            // }
        }

        if (props.authenticateType !== AuthenticateType.FORGOT_PASSWORD && props.authenticateType !== AuthenticateType.REGISTER) {
            if (password === '') {
                setPasswordError(t('Password required'))
                error = true
            }
        }
        if (props.authenticateType !== AuthenticateType.FORGOT_PASSWORD && props.authenticateType !== AuthenticateType.REGISTER) {
            if (toString(username) === '') {
                setUsernameError(t('Username required'))
                error = true
            }
        }

        if (!error) {
            if (props.authenticateType === AuthenticateType.REGISTER) {
                setLoading(true)
                dispatch(registerShop({
                    category: toString(category) as AdminCategory,
                    city: toString(city),
                    email: toString(email).toLowerCase(),
                    name: toString(name),
                    password: password,
                    shopName: toString(shopName),
                    businessName: toString(businessName),
                    websiteUrl: toString(websiteUrl),
                    username: toString(username).toLowerCase(),
                    addressLine1: toString(addressLine1),
                    addressLine2: toString(addressLine2),
                    landmark: toString(landmark) || null,
                    pincode: toString(pincode),
                    state: toString(state),
                    currency: toString(currency),
                    description: toString(description),
                    plan: toString(plan) as AdminPlan

                })).then(() => {
                    toast(t('Registered, Vshop Team Will contact you soon'))
                    props.onAuthenticateTypeChange?.(AuthenticateType.SIGN_IN)
                }).catch(text => {
                    toast(text, ToastType.ERROR)
                }).finally(() => {
                    setLoading(false)
                })
            } else if (props.authenticateType === AuthenticateType.SIGN_IN) {
                setLoading(true)
                dispatch(loginAdmin({
                    username: toString(username).toLowerCase(),
                    password: password
                })).then(text => {
                    toast(text)
                }).catch(text => {
                    toast(text, ToastType.ERROR)
                }).finally(() => {
                    setLoading(false)
                })
            } else if (props.authenticateType === AuthenticateType.FORGOT_PASSWORD) {
                setLoading(true)
                api.put<Response>('admin/forgot-password', { username: toString(username).toLowerCase() }).then(response => {
                    toast(response.data.message || t('Password has been changed. New password has been sent to your registered email. Please check'))
                    props.onAuthenticateTypeChange?.(AuthenticateType.SIGN_IN)
                }).catch(error => {
                    toast(apiErrorHandler(error, t('Unable to reset password')), ToastType.ERROR)
                }).finally(() => {
                    setLoading(false)
                })
            }
        }
    }

    React.useEffect(() => {
        clearState()
    }, [props.authenticateType])

    React.useEffect(() => {
        dispatch(fetchDivisions())
    }, [])

    return <form>
        {/* {showShopInformation && <PlanInformation planType={plan} onClose={() => setShowShopInformation(false)} shopId={}/>} */}
        <div className={`${style.mainContent} px-4`}>
            <div className='row'>
                {props.authenticateType === AuthenticateType.REGISTER && <div className='col-md-6 col-lg-4'>
                    <FormInput
                        type='text'
                        label={t('Name')}
                        required={true}
                        placeholder={t('Name')}
                        errorText={nameError}
                        value={name}
                        onChange={nameChangeHandler}
                        containerClass='mb-4'
                    />
                </div>
                }
                {props.authenticateType === AuthenticateType.REGISTER && <div className={props.authenticateType === AuthenticateType.REGISTER ? 'col-md-6 col-lg-4' : 'col-12'}>
                    <FormInput
                        type='email'
                        label={t('Email')}
                        placeholder={t('Email')}
                        required={true}
                        errorText={emailError}
                        value={email}
                        onChange={emailChangeHandler}
                        containerClass={props.authenticateType === AuthenticateType.REGISTER ? 'mb-4' : ''}
                    />
                </div>}
                {(props.authenticateType !== AuthenticateType.REGISTER) && <React.Fragment>
                    <div className={'col-12'}>
                        <FormInput
                            type='text'
                            label={t('Username')}
                            required={true}
                            placeholder={t('Username')}
                            errorText={usernameError}
                            value={username}
                            onChange={usernameChangeHandler}
                            containerClass={props.authenticateType !== AuthenticateType.FORGOT_PASSWORD ? 'mb-4' : ''}
                        />
                    </div>
                </React.Fragment>
                }
                {(props.authenticateType === AuthenticateType.SIGN_IN) && <React.Fragment>
                    <div className={'col-12'}>
                        <FormInput
                            type={showPassword ? 'text' : 'password'}
                            label={t('Password')}
                            placeholder={t('Password')}
                            required={true}
                            errorText={passwordError}
                            value={password}
                            onChange={passwordChangeHandler}
                            rightRenderer={showPassword ? <EyeOff /> : <Eye />}
                            onRightRendererClick={() => setShowPassword(!showPassword)}
                            labelRightRenderer={props.authenticateType === AuthenticateType.SIGN_IN && <div className={style.forgotPassword} onClick={() => props.onAuthenticateTypeChange?.(AuthenticateType.FORGOT_PASSWORD)}>{t("Forgot password")}?</div>}
                        />
                    </div>
                </React.Fragment>}
                {props.authenticateType === AuthenticateType.REGISTER && <React.Fragment>
                    <div className='col-md-6 col-lg-4'>
                        <FormInput
                            type='text'
                            label={t('Shop Name')}
                            required={true}
                            placeholder={t('Shop Name')}
                            errorText={shopNameError}
                            value={shopName}
                            onChange={shopNameChangeHandler}
                            containerClass='mb-4'
                        />
                    </div>
                    <div className='col-md-6 col-lg-4'>
                        <FormInput
                            type='text'
                            label={t('Business Name')}
                            placeholder={t('Business Name')}
                            errorText={businessNameError}
                            value={businessName}
                            onChange={businessNameChangeHandler}
                            containerClass='mb-4'
                        />
                    </div>
                    <div className='col-md-6 col-lg-4'>
                        <FormInput
                            type='text'
                            label={'Website URL'}
                            placeholder={'Website URL'}
                            errorText={websiteUrlError}
                            value={websiteUrl}
                            onChange={websiteUrlChangeHandler}
                            containerClass='mb-4'
                        />
                    </div>
                    <div className='col-md-6 col-lg-4'>
                        <Select
                            options={CATEGORY_OPTIONS}
                            label={t('Category')}
                            placeholder={t('Select category')}
                            required={true}
                            errorText={categoryError}
                            value={category}
                            onChange={categoryChangeHandler}
                            containerClass='mb-4'
                        />
                    </div>
                    <div className='col-md-6 col-lg-4'>
                        <FormInput
                            type='text'
                            label={t('Address Line 1')}
                            required={true}
                            placeholder={t('Address Line 1')}
                            errorText={addressLine1Error}
                            value={addressLine1}
                            onChange={addressLine1ChangeHandler}
                            containerClass='mb-4'
                        />
                    </div>
                    <div className='col-md-6 col-lg-4'>
                        <FormInput
                            type='text'
                            label={t('Address Line 2')}
                            placeholder={t('Address Line 2')}
                            required={true}
                            errorText={addressLine2Error}
                            value={addressLine2}
                            onChange={addressLine2ChangeHandler}
                            containerClass='mb-4'
                        />
                    </div>
                    <div className='col-md-6 col-lg-4'>
                        <FormInput
                            type='text'
                            label={t('City')}
                            required={true}
                            placeholder={t('City')}
                            errorText={cityError}
                            value={city}
                            onChange={cityChangeHandler}
                            containerClass='mb-4'
                        />
                    </div>
                    <div className='col-md-6 col-lg-4'>
                        <FormInput
                            type='text'
                            label={t('State')}
                            required={true}
                            placeholder={t('State')}
                            errorText={stateError}
                            value={state}
                            onChange={stateChangeHandler}
                            containerClass='mb-4'
                        />
                    </div>
                    <div className='col-md-6 col-lg-4'>
                        <FormInput
                            type='text'
                            label={t('Pincode')}
                            placeholder={t('Pincode')}
                            required={true}
                            errorText={pincodeError}
                            value={pincode}
                            onChange={pincodeChangeHandler}
                            containerClass='mb-4'
                        />
                    </div>
                    <div className='col-md-6 col-lg-4'>
                        <FormInput
                            type='text'
                            label={t('Landmark')}
                            placeholder={t('Landmark')}
                            errorText={landmarkError}
                            value={landmark}
                            onChange={landmarkChangeHandler}
                            containerClass='mb-4'
                        />
                    </div>
                    <div className='col-md-6 col-lg-4'>
                        <Select
                            options={CURRENCY}
                            label={t('Currency')}
                            placeholder={t('Select currency')}
                            errorText={currencyError}
                            value={currency}
                            onChange={currencyChangeHandler}
                            containerClass='mb-4'
                        />
                    </div>
                    {/* <div className='col-md-6 col-lg-4'>
                        <div className='hstack gap-2'>
                            <div className={style1.label}>Plan</div>
                            <div className={style2.infoIcon} onClick={() => setShowShopInformation(true)}><Info /></div>
                        </div>
                        <Select
                            options={PLAN_OPTIONS}
                            // label={('Plan')}
                            placeholder={('Select plan')}
                            // required={true}
                            errorText={planError}
                            value={plan}
                            onChange={planChangeHandler}
                            containerClass='mb-4'
                        />
                    </div> */}
                    <div>
                        <FormTextArea
                            label={t('Message')}
                            placeholder={t('Message')}
                            required={true}
                            errorText={descriptionError}
                            value={description}
                            onChange={descriptionChangeHandler}
                            containerClass='mb-4'
                        />
                    </div>
                </React.Fragment>}
            </div>
        </div>
        <div className='p-4'>
            {props.authenticateType === AuthenticateType.REGISTER && <React.Fragment>
                <Button type='submit' className={formStyle.saveButton} onClick={submitHandler} loading={loading}>{t("Register")}</Button>
                <div className='hstack justify-content-center'>
                    <div className={style.switchLink} onClick={() => props.onAuthenticateTypeChange?.(AuthenticateType.SIGN_IN)}>{t("Existing user? Sign in")}</div>
                </div>
            </React.Fragment>}
            {props.authenticateType === AuthenticateType.SIGN_IN && <React.Fragment>
                <Button type='submit' className={formStyle.saveButton} onClick={submitHandler} loading={loading}>{t("Sign In")}</Button>
                <div className='hstack justify-content-center'>
                    <div className={style.switchLink} onClick={() => props.onAuthenticateTypeChange?.(AuthenticateType.REGISTER)}>{t("New to Vshop? Create an account")}</div>
                </div>
            </React.Fragment>}
            {props.authenticateType === AuthenticateType.FORGOT_PASSWORD && <React.Fragment>
                <Button type='submit' className={formStyle.saveButton} onClick={submitHandler} loading={loading}>{t("Continue")}</Button>
                <div className='hstack justify-content-center'>
                    <div className={style.switchLink} onClick={() => props.onAuthenticateTypeChange?.(AuthenticateType.REGISTER)}>{t("New to Vshop? Create an account")}</div>
                </div>
                <div className='hstack justify-content-center'>
                    <div className={style.switchLink} onClick={() => props.onAuthenticateTypeChange?.(AuthenticateType.SIGN_IN)}>{t("Existing user? Sign in")}</div>
                </div>

            </React.Fragment>}
        </div>
        <div className='p-1'>
            <p className='text-danger text-center'>{t("Shop Admin")}</p>
        </div>
    </form>
}
export default AdminAuthenticateForm