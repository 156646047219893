/* eslint-disable no-throw-literal */
import { Dispatch } from 'redux'
import { api, apiErrorHandler, getApiRequestConfig, Response } from '../../api'
import { GetState } from '../reducers'
import { AdminPlan, AuthAdmin } from '../reducers/authAdmin.reducer'
import { BankDetails, DeliveryAPI, DeliveryCharge, DeliveryDistance, LateNightDelivery, OrderValueForFreeDelivery, OtherPayments, Paytrail, Pickup, PreviewTheme, Shop, ShopAddress, ShopCategory, Theme, Timing } from '../reducers/shop.reducer'
import { AddShopAction, ShopActionTypes } from './shop.actions'

export enum AuthAdminActionTypes {
    LOGIN_ADMIN = 'LOGIN_ADMIN',
    LOGOUT_ADMIN = 'LOGOUT_ADMIN',
    UPDATE_SHOP_CONTACT_DETAILS = 'UPDATE_SHOP_CONTACT_DETAILS',
    UPDATE_SHOP_BANK_DETAILS = 'UPDATE_SHOP_BANK_DETAILS',
    UPDATE_SHOP_OTHER_PAYMENTS = 'UPDATE_SHOP_OTHER_PAYMENTS',
    UPDATE_SHOP_TIMING = 'UPDATE_SHOP_TIMING',
    UPDATE_DELIVERY_TIMING = 'UPDATE_DELIVERY_TIMING',
    ENABLE_DELIVERY = 'ENABLE_DELIVERY',
    DISABLE_DELIVERY = 'DISABLE_DELIVERY',
    UPDATE_DELIVERY_CHARGE_AND_DISTANCE = 'UPDATE_DELIVERY_CHARGE_AND_DISTANCE',
    UPDATE_DELIVERY_API = 'UPDATE_DELIVERY_API',
    UPDATE_PAYTRAIL = 'UPDATE_PAYTRAIL',
    UPDATE_PROCESS_TIMES = 'UPDATE_PROCESS_TIMES',
    UPDATE_SHOP_THEME = 'UPDATE_SHOP_THEME',
    UPDATE_PICKUP = 'UPDATE_PICKUP',
    UPDATE_SHOP_PREVIEW_THEME = 'UPDATE_SHOP_PREVIEW_THEME',
    UPDATE_SHOP_VAT_ID = 'UPDATE_SHOP_VAT_ID'
}

export type AdminCategory = ShopCategory

export type AdminRole = 'admin' | 'chef'

export interface LoginAdminAction {
    type: AuthAdminActionTypes.LOGIN_ADMIN
    data: AuthAdmin
}

export interface LogoutAdminAction {
    type: AuthAdminActionTypes.LOGOUT_ADMIN
}

export interface UpdateShopContactDetailsAction {
    type: AuthAdminActionTypes.UPDATE_SHOP_CONTACT_DETAILS
    data: UpdateShopContactDetailsResponse
}

export interface UpdateShopBankDetailsAction {
    type: AuthAdminActionTypes.UPDATE_SHOP_BANK_DETAILS
    data: BankDetails
}

export interface UpdateShopOtherPaymentsAction {
    type: AuthAdminActionTypes.UPDATE_SHOP_OTHER_PAYMENTS
    data: OtherPayments[]
}

export interface UpdateShopTimingAction {
    type: AuthAdminActionTypes.UPDATE_SHOP_TIMING
    data: Timing
}

export interface UpdateDeliveryTimingAction {
    type: AuthAdminActionTypes.UPDATE_DELIVERY_TIMING
    data: Timing
}

export interface EnableDeliveryAction {
    type: AuthAdminActionTypes.ENABLE_DELIVERY
}

export interface DisableDeliveryAction {
    type: AuthAdminActionTypes.DISABLE_DELIVERY
}

export interface UpdateDeliveryChargeAndDistanceAction {
    type: AuthAdminActionTypes.UPDATE_DELIVERY_CHARGE_AND_DISTANCE
    data: UpdateDeliveryChargeAndDistanceResponse
}

export interface UpdateDeliveryAPIAction {
    type: AuthAdminActionTypes.UPDATE_DELIVERY_API
    data: DeliveryAPI
}

export interface UpdatePaytrailAction {
    type: AuthAdminActionTypes.UPDATE_PAYTRAIL
    data: Paytrail
}

export interface UpdateProcessTimesAction {
    type: AuthAdminActionTypes.UPDATE_PROCESS_TIMES
    data: number[]
}

export interface UpdateShopThemeAction {
    type: AuthAdminActionTypes.UPDATE_SHOP_THEME
    data: Theme
}

export interface UpdatePickupAction {
    type: AuthAdminActionTypes.UPDATE_PICKUP
    data: Pickup
}

export interface UpdateShopPreviewThemeAction {
    type: AuthAdminActionTypes.UPDATE_SHOP_PREVIEW_THEME
    data: PreviewTheme
}

export interface UpdateShopVatIdAction {
    type: AuthAdminActionTypes.UPDATE_SHOP_VAT_ID
    data: string | null
}

export type AuthAdminActions = LoginAdminAction | LogoutAdminAction | UpdateShopContactDetailsAction | UpdateShopBankDetailsAction | UpdateShopOtherPaymentsAction | UpdateShopTimingAction | UpdateDeliveryTimingAction | EnableDeliveryAction | DisableDeliveryAction | UpdateDeliveryChargeAndDistanceAction | UpdateDeliveryAPIAction | UpdatePaytrailAction | UpdateProcessTimesAction | UpdateShopThemeAction | UpdateShopPreviewThemeAction | UpdateShopVatIdAction | UpdatePickupAction

interface RegisterAdmin {
    name: string
    username: string
    divisionId?: string
    category: AdminCategory
    email: string
    password: string
    shopName: string
    businessName: string
    websiteUrl: string
    addressLine1: string
    addressLine2: string
    city: string
    pincode: string
    state: string
    landmark: string | null
    currency: string | null
    description: string
    plan?: AdminPlan
}

interface RegisterAdminResponse {
    name: string
    email: string
    username: string
    category: AdminCategory
    role: AdminRole
    shop: Shop
    createdAt: string
    updatedAt: string
    id: string
}

export const registerAdmin = (input: RegisterAdmin, updatedState?: boolean) => async (dispatch: Dispatch) => {
    return api.post<Response<RegisterAdminResponse>>('admin', input).then(response => {
        if (response.status === 200) {
            if (updatedState) {
                dispatch<AddShopAction>({
                    type: ShopActionTypes.ADD_SHOP,
                    data: response.data.data.shop
                })
            }
            return Promise.resolve<string>(response.data.message || 'Registered')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Failed to register'))
    })
}


export const registerShop = (input: RegisterAdmin, updatedState?: boolean) => async (dispatch: Dispatch) => {
    return api.post<Response<any>>('shop', input).then(response => {
        if (response.status === 200) {
            return Promise.resolve<string>(response.data.message || 'Registered successfully, Vshop Team contact you soon!')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Failed to register'))
    })
}

interface UpdateShopContactDetails {
    shopName: string
    businessName: string | null
    addressLine1: string
    addressLine2: string
    city: string
    pincode: string
    state: string
    landmark: string | null
    countryCode: string | null
    phoneNumber: string | null
    currency: string
    description: string
    plan?: AdminPlan
}

interface UpdateShopContactDetailsResponse {
    shopName: string
    businessName: string | null
    countryCode: string | null
    currency: string
    phoneNumber: string | null
    description: string
    address: ShopAddress
}

export const updateShopContactDetails = (input: UpdateShopContactDetails) => async (dispatch: Dispatch, getState: GetState) => {
    const authAdmin = getState().authAdmin
    const config = getApiRequestConfig(authAdmin?.['auth-token'])

    return api.put<Response<UpdateShopContactDetailsResponse>>('shop/contact-details', input, config).then(response => {
        if (response.status === 200) {
            dispatch<UpdateShopContactDetailsAction>({
                type: AuthAdminActionTypes.UPDATE_SHOP_CONTACT_DETAILS,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || 'Updated')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to update'))
    })
}

export const updateShopBankDetails = (input: BankDetails) => async (dispatch: Dispatch, getState: GetState) => {
    const authAdmin = getState().authAdmin
    const config = getApiRequestConfig(authAdmin?.['auth-token'])

    return api.put<Response<BankDetails>>('shop/bank-details', input, config).then(response => {
        if (response.status === 200) {
            dispatch<UpdateShopBankDetailsAction>({
                type: AuthAdminActionTypes.UPDATE_SHOP_BANK_DETAILS,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || 'Updated')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to update'))
    })
}

export const updateShopOtherPayments = (input: OtherPayments[]) => async (dispatch: Dispatch, getState: GetState) => {
    const authAdmin = getState().authAdmin
    const config = getApiRequestConfig(authAdmin?.['auth-token'])

    return api.put<Response<OtherPayments[]>>('shop/other-payments', { otherPayments: input }, config).then(response => {
        if (response.status === 200) {
            dispatch<UpdateShopOtherPaymentsAction>({
                type: AuthAdminActionTypes.UPDATE_SHOP_OTHER_PAYMENTS,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || 'Updated')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to update'))
    })
}

export const updateShopTiming = (input: Timing) => async (dispatch: Dispatch, getState: GetState) => {
    const authAdmin = getState().authAdmin
    const config = getApiRequestConfig(authAdmin?.['auth-token'])

    return api.put<Response<Timing>>('shop/timing', input, config).then(response => {
        if (response.status === 200) {
            dispatch<UpdateShopTimingAction>({
                type: AuthAdminActionTypes.UPDATE_SHOP_TIMING,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || 'Updated')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to update'))
    })
}

export const updateDeliveryTiming = (input: Timing) => async (dispatch: Dispatch, getState: GetState) => {
    const authAdmin = getState().authAdmin
    const config = getApiRequestConfig(authAdmin?.['auth-token'])

    return api.put<Response<Timing>>('shop/delivery-timing', input, config).then(response => {
        if (response.status === 200) {
            dispatch<UpdateDeliveryTimingAction>({
                type: AuthAdminActionTypes.UPDATE_DELIVERY_TIMING,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || 'Updated')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to update'))
    })
}

export const enableDelivery = () => async (dispatch: Dispatch, getState: GetState) => {
    const authAdmin = getState().authAdmin
    const config = getApiRequestConfig(authAdmin?.['auth-token'])

    return api.put('shop/delivery/enable', {}, config).then(response => {
        if (response.status === 200) {
            dispatch<EnableDeliveryAction>({
                type: AuthAdminActionTypes.ENABLE_DELIVERY
            })
            return Promise.resolve<string>(response.data.message || 'Delivery enabled')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to update'))
    })
}

export const disableDelivery = () => async (dispatch: Dispatch, getState: GetState) => {
    const authAdmin = getState().authAdmin
    const config = getApiRequestConfig(authAdmin?.['auth-token'])

    return api.put('shop/delivery/disable', {}, config).then(response => {
        if (response.status === 200) {
            dispatch<DisableDeliveryAction>({
                type: AuthAdminActionTypes.DISABLE_DELIVERY
            })
            return Promise.resolve<string>(response.data.message || 'Delivery disabled')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to update'))
    })
}

interface UpdateDeliveryChargeAndDistance {
    deliveryChargeForEveryKmAboveGivenDistance: number
    deliveryChargeUpToGivenDistance: number
    lateNightDeliveryChargeForEveryKm: number
    lateNightDeliveryFrom: string
    lateNightDeliveryTo: string
    deliveryDistanceFrom: number
    orderValueForFreeDeliveryUpToGivenDistance: number
    deliveryDistanceTo: number
    orderValueForFreeDeliveryAboveGivenDistance: number
}

interface UpdateDeliveryChargeAndDistanceResponse {
    charge: DeliveryCharge
    distance: DeliveryDistance
    lateNightDelivery: LateNightDelivery
    orderValueForFreeDelivery: OrderValueForFreeDelivery
}

export const updateDeliveryChargeAndDistance = (input: UpdateDeliveryChargeAndDistance) => async (dispatch: Dispatch, getState: GetState) => {
    const authAdmin = getState().authAdmin
    const config = getApiRequestConfig(authAdmin?.['auth-token'])

    return api.put<Response<UpdateDeliveryChargeAndDistanceResponse>>('shop/delivery-charge-and-distance', input, config).then(response => {
        if (response.status === 200) {
            dispatch<UpdateDeliveryChargeAndDistanceAction>({
                type: AuthAdminActionTypes.UPDATE_DELIVERY_CHARGE_AND_DISTANCE,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || 'Delivery charge and distance updated')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to update'))
    })
}

export const updateDeliveryAPI = (input: DeliveryAPI) => async (dispatch: Dispatch, getState: GetState) => {
    const authAdmin = getState().authAdmin
    const config = getApiRequestConfig(authAdmin?.['auth-token'])

    return api.put<Response<DeliveryAPI>>('shop/delivery-api', input, config).then(response => {
        if (response.status === 200) {
            dispatch<UpdateDeliveryAPIAction>({
                type: AuthAdminActionTypes.UPDATE_DELIVERY_API,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || 'Delivery api updated')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to update'))
    })
}

export const updatePaytrail = (input: Paytrail) => async (dispatch: Dispatch, getState: GetState) => {
    const authAdmin = getState().authAdmin
    const config = getApiRequestConfig(authAdmin?.['auth-token'])

    return api.put<Response<Paytrail>>('shop/paytrail', input, config).then(response => {
        if (response.status === 200) {
            dispatch<UpdatePaytrailAction>({
                type: AuthAdminActionTypes.UPDATE_PAYTRAIL,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || 'Paytrail updated')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to update'))
    })
}

export const updateProcessTimes = (input: number[]) => async (dispatch: Dispatch, getState: GetState) => {
    const authAdmin = getState().authAdmin
    const config = getApiRequestConfig(authAdmin?.['auth-token'])

    return api.put<Response<number[]>>('shop/process-time', input, config).then(response => {
        if (response.status === 200) {
            dispatch<UpdateProcessTimesAction>({
                type: AuthAdminActionTypes.UPDATE_PROCESS_TIMES,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || 'Process time updated')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to update'))
    })
}

interface UpdateShopTheme {
    bannerImage: string | null
    primaryColor: string | null
    secondaryColor: string | null
    slides: {
        image: string
        productUrl: string
        description?: string
        title?: string
    }[]
}

export const updateShopTheme = (input: UpdateShopTheme) => async (dispatch: Dispatch, getState: GetState) => {
    const authAdmin = getState().authAdmin
    const config = getApiRequestConfig(authAdmin?.['auth-token'])

    return api.put<Response<Theme>>('shop/theme', input, config).then(response => {
        if (response.status === 200) {
            dispatch<UpdateShopThemeAction>({
                type: AuthAdminActionTypes.UPDATE_SHOP_THEME,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || 'Shop theme updated')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to update'))
    })
}

interface UpdatePickups {
    addressLine1?: string
    addressLine2?: string
    state?: string
    city?: string
    pincode?: string
    phoneNumber?: string
    landmark?: string
}

export const UpdatePickup = (input: UpdatePickups[]) => async (dispatch: Dispatch, getState: GetState) => {
    const authAdmin = getState().authAdmin
    const config = getApiRequestConfig(authAdmin?.['auth-token'])

    return api.put<Response<Pickup>>('shop/pickup', { data: input }, config).then(response => {
        if (response.status === 200) {
            dispatch<UpdatePickupAction>({
                type: AuthAdminActionTypes.UPDATE_PICKUP,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || 'Pickup point updated')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to update'))
    })
}

interface Price {
    worsePrice: string | null
    bestPrice: string | null
}

interface UpdateShopPreviewTheme {
    animation: string | null
    fontStyle: string | null
    backgroundColor: string | null
    color: string | null
    priceColor: Price
}

export const updateShopPreviewTheme = (input: UpdateShopPreviewTheme) => async (dispatch: Dispatch, getState: GetState) => {
    const authAdmin = getState().authAdmin
    const config = getApiRequestConfig(authAdmin?.['auth-token'])

    return api.put<Response<UpdateShopPreviewTheme>>('shop/preview-theme', input, config).then(response => {
        if (response.status === 200) {
            dispatch<UpdateShopPreviewThemeAction>({
                type: AuthAdminActionTypes.UPDATE_SHOP_PREVIEW_THEME,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || 'Shop preview theme updated')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to update'))
    })
}


export const updateVatId = (input: string | null) => async (dispatch: Dispatch, getState: GetState) => {
    const authAdmin = getState().authAdmin
    const config = getApiRequestConfig(authAdmin?.['auth-token'])

    return api.put<Response<string | null>>('shop/vat-id', { vatId: input }, config).then(response => {
        if (response.status === 200) {
            dispatch<UpdateShopVatIdAction>({
                type: AuthAdminActionTypes.UPDATE_SHOP_VAT_ID,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || 'Shop vat id updated')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to update'))
    })
}

export interface LoginAdmin {
    username: string
    password: string
}

export const loginAdmin = (input: LoginAdmin) => async (dispatch: Dispatch) => {
    return api.post<Response<AuthAdmin>>('admin/login', input).then(response => {
        if (response.status === 200) {
            dispatch<LoginAdminAction>({
                type: AuthAdminActionTypes.LOGIN_ADMIN,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || 'Logged in')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to login'))
    })
}

export interface LoginForSuperAdmin {
    username: string
}

export const loginForSuperAdmin = (input: LoginForSuperAdmin) => async (dispatch: Dispatch, getState: GetState) => {
    const authSuperAdmin = getState().authSuperAdmin
    const config = getApiRequestConfig(authSuperAdmin?.['auth-token'])

    return api.post<Response<AuthAdmin>>('superAdmin/login', input, config).then(response => {
        if (response.status === 200) {
            dispatch<LoginAdminAction>({
                type: AuthAdminActionTypes.LOGIN_ADMIN,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || 'Super Admin Logged in')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to super admin login'))
    })
}

export const logoutAdmin = (): LogoutAdminAction => {
    return {
        type: AuthAdminActionTypes.LOGOUT_ADMIN
    }
}