import inputStyle from '../../styles/components/input.module.scss'
import ErrorText from './ErrorText'
import TextArea, { TextAreaProps } from './TextArea'

interface FormTextAreaProps extends TextAreaProps {
    label?: string
    containerClass?: string
    required?: boolean
}

const FormTextArea = ({ label,required, containerClass, ...props }: FormTextAreaProps) => {
    return <div className={`${inputStyle.container} ${containerClass || ''}`}>
        <div className={inputStyle.label}>{label} {required && <span className={inputStyle.required}>*</span>}</div>
        <TextArea {...props} />
        <ErrorText errorText={props.errorText} top={'95%'} />
    </div>
}

export default FormTextArea