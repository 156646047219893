import { Action, FoodActionTypes } from '../actions'
import { Category } from './category.reducer'
import { ExtraTopping } from './extraTopping.reducer'
import { Spice } from './spice.reducer'
import { Topping } from './topping.reducer'

export type FoodType = 'detail' | 'simple' | 'custom'
export type FoodStatus = 'active' | 'inactive'
export type PreviewStatus = 'active' | 'inactive'

export interface FoodName {
    language: string
    name: string
    createdAt: string
    updatedAt: string
    id: string
}

export interface FoodDescription {
    language: string
    description: string
    createdAt: string
    updatedAt: string
    id: string
}

export interface FoodPrice {
    size: string
    price: number
    offerPrice: number | null
    createdAt: string
    updatedAt: string
    id: string
}

export interface Food {
    names: FoodName[]
    descriptions: FoodDescription[]
    categories: Category[]
    type: FoodType
    image: string
    additionalImages: string[]
    toppings: Topping[]
    offerPrice: number | null
    tax: number | null
    stock: number | null
    expiryDate: string | null
    expectedDate: string | null
    productCode: string | null
    manufacture: string | null
    offerLabel: string | null
    status: FoodStatus
    previewStatus: PreviewStatus
    shopId: string
    createdAt: string
    updatedAt: string
    id: string
    alternateToppings: Topping[]
    alternateToppingsCount: number | null
    extraToppings: ExtraTopping[]
    spices: Spice[]
    prices: FoodPrice[]
    price: number | null
}

const initialState: Food[] = []

const foodReducer = (state: Food[] = initialState, action: Action): Food[] => {
    switch (action.type) {
        case FoodActionTypes.ADD_FOOD: {
            return [
                ...state,
                action.data
            ]
        }

        case FoodActionTypes.UPDATE_FOOD: {
            const foods = [...state]
            const index = foods.findIndex(f => f.id === action.data.id)

            if (index > -1) {
                foods[index] = {
                    ...action.data
                }
            }

            return foods
        }

        case FoodActionTypes.FETCH_FOODS: {
            return action.data
        }

        default: {
            return state
        }
    }
}

export default foodReducer