import { Dispatch } from "redux"
import { api, apiErrorHandler, getApiRequestConfig, Response } from "../../api"
import { GetState } from "../reducers"
import { User } from "../reducers/user.reducer"


export enum UserActionsType {
    DELETE_USER = 'DELETE_USER',
    FETCH_USER = 'FETCH_USER'

}

export interface DeleteUser {
    id: string
}

export interface DeleteUserAction {
    type: UserActionsType.DELETE_USER
    data: string
}

export interface FetchUserAction {
    type: UserActionsType.FETCH_USER
    data: User[]
}

export type UserActions = DeleteUserAction | FetchUserAction

export const fetchUsers = () => async (dispatch: Dispatch, getState: GetState) => {
    // const authAdmin = getState().authAdmin
    // const config = getApiRequestConfig(authAdmin?.['auth-token'])

    return api.get<Response<User[]>>('user/').then(response => {
        if (response.status === 200) {
            dispatch<FetchUserAction>({
                type: UserActionsType.FETCH_USER,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || '')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to fetch'))
    })
}
// const fetchUser = () => {
//     api.get<Response<User[]>>('user/').then(response => {
//         if (response.status === 200 && response.data.data) {
//             const result = response.data.data

//             setUserList(result)
//         } else {
//             throw { response }
//         }
//     }).catch(() => {
//         toast('Something went wrong!')
//     }).finally(() => {
//         appContext?.hideOverlay?.()
//     })
// }

export const deleteUser = (userId: string) => async (dispatch: Dispatch, getState: GetState) => {
    const authSuperAdmin = getState().authSuperAdmin
    const config = getApiRequestConfig(authSuperAdmin?.['auth-token'])

    return api.delete(`user/${userId}`, config).then(response => {
        if (response.status === 200) {
            dispatch<DeleteUserAction>({
                type: UserActionsType.DELETE_USER,
                data: userId
            })
            return Promise.resolve<string>(response.data.message || 'Delete User')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to delete'))
    })
}

export const referralCountMail = (userId: string) => async (dispatch: Dispatch, getState: GetState) => {
    const authSuperAdmin = getState().authSuperAdmin
    const config = getApiRequestConfig(authSuperAdmin?.['auth-token'])

    return api.post(`user/referral-mail`,{userId},config).then(response => {
        if (response.status === 200) {
            return Promise.resolve<string>(response.data.message || 'Send Mail Successfully')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to delete'))
    })
}