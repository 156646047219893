import { Link } from 'react-router-dom'
import assets from '../../assets'
import { useT } from '../../i18n'
import style from '../../styles/components/header.module.scss'

interface HeaderProps {
    homeUrl?: string
}

const Header = (props: HeaderProps) => {
    const t = useT()
    return <header className={`container-fluid py-2 ${style.container}`}>
        <Link to={props.homeUrl || '/'} className='d-inline-block text-decoration-none'>
            <div className='hstack gap-3'>
                <div className={style.logo}>
                    <img src={assets.images.logo} alt='V-Shops logo' />
                </div>
                <div className={style.logoCaption}>{t("Virtual shopping")},<br />{t("Real value")}!</div>
            </div>
        </Link>
    </header>
}

export default Header