
export enum LanguageActionTypes {
    ADD_LANGUAGE = 'ADD_LANGUAGE',
    ADD_LANGUAGES = 'ADD_LANGUAGES'
}

export interface AddLanguageAction {
    type: LanguageActionTypes.ADD_LANGUAGE
    data: string
}

export interface AddLanguagesAction {
    type: LanguageActionTypes.ADD_LANGUAGES
    data: string[]
}

export type LanguageActions = AddLanguageAction | AddLanguagesAction

export const addLanguage = (language: string): AddLanguageAction => {
    return {
        type: LanguageActionTypes.ADD_LANGUAGE,
        data: language
    }
}

export const addLanguages = (languages: string[]): AddLanguagesAction => {
    return {
        type: LanguageActionTypes.ADD_LANGUAGES,
        data: languages
    }
}