import React, { useState } from 'react';
import style from '../../styles/components/FileUpload.module.scss';

interface FileUploadProps {
    onFileSelect: (file: File | null) => void;
}

const FileUpload: React.FC<FileUploadProps> = ({ onFileSelect }) => {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        setSelectedFile(file);
        onFileSelect(file);
    };

    return (
        <div className={style.uploadContainer}>
            <label htmlFor="file-upload" className={style.uploadLabel}>
                Upload Receipt
            </label>
            <input
                type="file"
                id="file-upload"
                accept="image/*"
                onChange={handleFileChange}
                className={style.uploadInput}
            />
            {selectedFile && (
                <p className={style.selectedFile}>Selected file: {selectedFile.name}</p>
            )}
            <small className={style.fileFormat}>Supported Formats: doc, docx, rtf, pdf, upto 2 MB</small>
        </div>
    );
};

export default FileUpload;

