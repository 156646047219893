import { nanoid } from '@reduxjs/toolkit'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Response, api, getApiRequestConfig } from '../../api'
import { AdminContext, AdminContextType } from '../../context/AdminProvider'
import { useT } from '../../i18n'
import { addSize, fetchFoods } from '../../redux/actions'
import { StoreState } from '../../redux/reducers'
import { AuthAdmin } from '../../redux/reducers/authAdmin.reducer'
import { Food, FoodType } from '../../redux/reducers/food.reducer'
import { AppDispatch } from '../../redux/store'
import formStyle from '../../styles/components/form.module.scss'
import { toNumber } from '../../utils'
import { ToastType, useToast } from '../ToastProvider'
import ModalWithHeader from '../modals/ModalWithHeader'
import SelectableTileSection from '../sections/SelectableTileSection'
import ErrorText from '../utils/ErrorText'
import Radio from '../utils/Radio'
import FoodPriceForm from './FoodPriceForm'

interface Price {
    price: string
    priceError: string
    offerPrice: string
    offerPriceError: string
    size: string
    id: string
}

interface FoodFormProps {
    onClose?: () => void
    id?: string | null
}

const PriceUploadForm = (props: FoodFormProps) => {
    const authAdmin = useSelector<StoreState, AuthAdmin | null>(state => state.authAdmin)
    const adminContext = React.useContext<AdminContextType | null>(AdminContext)

    const food = useSelector<StoreState, Food | null>(state => props.id ? state.foods.find(f => f.id === props.id) || null : null)

    const token = authAdmin?.['auth-token']
    const config = getApiRequestConfig(token)
    const dispatch = useDispatch<AppDispatch>()

    const toast = useToast()
    const t = useT()

    const sizes = useSelector<StoreState, string[]>(state => state.sizes)
    const [prices, setPrices] = React.useState<Price[]>([])

    const [type, setType] = React.useState<FoodType>(authAdmin?.category !== 'Product' ? 'detail' : 'simple')
    const [typeError, setTypeError] = React.useState<string>('')
    const [price, setPrice] = React.useState<string>('')
    const [priceError, setPriceError] = React.useState<string>('')
    const [offerPrice, setOfferPrice] = React.useState<string>('')
    const [offerPriceError, setOfferPriceError] = React.useState<string>('')
    const size = useSelector<StoreState, string[]>(state => state.sizes)
    const [selectedSizesError, setSelectedSizesError] = React.useState<string>('')

    const [loading, setLoading] = React.useState<boolean>(false)

    const priceChangeHandler = (value: string, id?: string) => {
        if (id) {
            setPrices(prev => {
                const prices = [...prev]
                const index = prices.findIndex(p => p.id === id)

                if (index > -1) {
                    prices[index] = {
                        ...prices[index],
                        price: value,
                        priceError: ''
                    }
                }

                return prices
            })
        } else {
            setPrice(value)
            setPriceError('')
        }
    }

    const offerPriceChangeHandler = (value: string, id?: string) => {
        if (id) {
            setPrices(prev => {
                const prices = [...prev]
                const index = prices.findIndex(p => p.id === id)

                if (index > -1) {
                    prices[index] = {
                        ...prices[index],
                        offerPrice: value,
                        offerPriceError: ''
                    }
                }

                return prices
            })
        } else {
            setOfferPrice(value)
            setOfferPriceError('')
        }
    }
    const typeClickHandler = (value: FoodType) => {
        setType(value)
        setTypeError('')
    }
    const addNewSizeClickHandler = (size: string) => {
        dispatch(addSize(size))
    }
    const selectSizeHandler = (size?: string) => {
        if (size && size !== '') {
            adminContext?.setSelectedSizes?.(prev => {
                return prev.some(p => p === size)
                    ? prev.filter(p => p !== size)
                    : [
                        ...prev,
                        size
                    ]
            })
            setSelectedSizesError('')
        }
    }
    React.useEffect(() => {
        setPrices((prev): any => {
            const newState = [...prev]
            return adminContext?.selectedSizes?.map<Price>(size => {
                const index = newState.findIndex(p => p.size === size)
                return index > -1
                    ? newState[index]
                    : {
                        offerPrice: '',
                        offerPriceError: '',
                        price: '',
                        priceError: '',
                        size: size,
                        id: nanoid()
                    }
            })
        })
    }, [adminContext?.selectedSizes])

    React.useEffect(() => {
        setType(food?.type ? food?.type : 'simple')
        setPrices(() => {
            const prices: Price[] = []

            food?.prices.forEach(n => {
                if (!prices.some(p => p.size === n.size)) {
                    prices.push({
                        id: n.id,
                        size: n.size,
                        offerPrice: n.offerPrice?.toString() || '',
                        offerPriceError: '',
                        price: n.price.toString(),
                        priceError: ''
                    })
                }
            })

            return prices
        })
        setPrice(food?.price?.toString() || '')
        setOfferPrice(food?.offerPrice?.toString() || '')
        adminContext?.setSelectedSizes?.(() => {
            const sizes: string[] = []
            food?.prices.forEach(n => {
                if (sizes.indexOf(n.size) === -1) {
                    sizes.push(n.size)
                }
            })
            return sizes
        })
    }, [props.id])

    const EditIconHandler = () => {

        let error = false

        if ((type).toString() === '') {
            error = true
            setTypeError(t('Type required'))
        } else if ((['detail', 'simple'] as FoodType[]).indexOf(type) === -1) {
            error = true
            setTypeError(t('Invalid type given'))
        }

        if (type === 'detail') {
            if (prices.length <= 0) {
                error = true
                setSelectedSizesError(t('Prices required'))
            } else {
                prices.forEach((price, i) => {
                    if ((price.size) === '') {
                        error = true
                        setPrices(prev => {
                            const prices = [...prev]
                            prices[i].priceError = (t('Size required'))
                            return prices
                        })
                    }

                    if ((price.price) === '') {
                        error = true
                        setPrices(prev => {
                            const prices = [...prev]
                            prices[i].priceError = (t('Price required'))
                            return prices
                        })
                    } else if (toNumber(price.price) < 0) {
                        error = true
                        setPrices(prev => {
                            const prices = [...prev]
                            prices[i].priceError = t('Invalid price given')
                            return prices
                        })
                    }

                    if ((price.offerPrice) !== '' && toNumber(price.offerPrice) < 0) {
                        error = true
                        setPrices(prev => {
                            const prices = [...prev]
                            prices[i].offerPriceError = t('Invalid offer price given')
                            return prices
                        })
                    }
                })
            }

        }

        if (type === 'simple') {
            if ((price) === '') {
                error = true
                setPriceError(t('Price required'))
            } else if (toNumber(price) < 0) {
                error = true
                setPriceError(t('Invalid price given'))
            }

            if ((offerPrice) !== '' && toNumber(offerPrice) < 0) {
                error = true
                setOfferPriceError(t('Invalid offer price given'))
            }
        }
        if (!error) {

            if (authAdmin !== null && authAdmin !== undefined && authAdmin.id && authAdmin.shop.id) {

                const input = {
                    prices: prices,
                    offerPrice: offerPrice,
                    price: price,
                    type: type
                }

                api.put<Response<Food>>(`food/price/${food?.id}`, input, config).then(response => {
                    if (response.status === 200) {
                        toast(response.data?.message!, ToastType.SUCCESS)
                        dispatch(fetchFoods())
                        props.onClose!()
                    }
                }).catch((error) => {
                    toast(error, ToastType.ERROR)

                })
            }
        }

    }


    return <ModalWithHeader
        buttonText={t('Save')}
        headerText={t('Price Edit')}
        loading={loading}
        onClose={props.onClose}
        onSave={EditIconHandler}
        small
    >

        {authAdmin?.category !== "Product" &&
            <div className='row mb-4'>
                <div className='col'>
                    <div className='position-relative'>
                        <div className={formStyle.sectionHeader}>{t("Type")}</div>
                        <div className='hstack gap-3'>
                            <Radio checked={type === 'detail'} onClick={() => typeClickHandler('detail')}>{t("Detail")}</Radio>
                            <Radio checked={type === 'simple'} onClick={() => typeClickHandler('simple')}>{t("Simple")}</Radio>
                        </div>
                        <ErrorText errorText={typeError} />
                    </div>
                </div>
            </div>
        }
        <div className='row mb-4'>
            <div className='col'>
                {type === 'detail' && <SelectableTileSection
                    list={sizes}
                    addButtonText={t('Add Size')}
                    errorText={selectedSizesError}
                    getSelectableTileProps={size => ({
                        key: size,
                        value: size,
                        label: size,
                        active: adminContext?.selectedSizes?.some(s => s === size),
                        onClick: selectSizeHandler,
                        error: selectedSizesError !== ''
                    })}
                    instantInput={{
                        onAdd: addNewSizeClickHandler,
                        label: t('Size')
                    }}
                    title={t('Prices')}
                />}
                <div className='row'>
                    {type === 'detail' && adminContext?.selectedSizes?.map(size => {
                        const price = prices.find(p => p.size === size)

                        return <FoodPriceForm
                            responsiveClassName='col-sm-6 col-md-4 my-2'
                            label={price?.size}
                            price={price?.price || ''}
                            priceError={price?.priceError}
                            offerPrice={price?.offerPrice || ''}
                            offerPriceError={price?.offerPriceError}
                            onOfferPriceChange={e => offerPriceChangeHandler(e.target.value, price?.id)}
                            onPriceChange={e => priceChangeHandler(e.target.value, price?.id)}
                            key={size}
                            showOfferPrice
                        />
                    })}

                    {type === 'simple' && <FoodPriceForm
                        responsiveClassName='col-4'
                        price={price}
                        priceError={priceError}
                        offerPrice={offerPrice}
                        offerPriceError={offerPriceError}
                        onOfferPriceChange={e => offerPriceChangeHandler(e.target.value)}
                        onPriceChange={e => priceChangeHandler(e.target.value)}
                        showOfferPrice
                    />}
                </div>
            </div>
        </div>


    </ModalWithHeader>
}
export default PriceUploadForm