import { useT } from '../../i18n'
import formStyle from '../../styles/components/form.module.scss'
import style from '../../styles/components/slot-card.module.scss'
import Edit from '../svgs/Edit'
import Trash from '../svgs/Trash'


interface Props {
    title: string
    buttonText: string
    list: any
    onEdit: any
    onDelete: any
}

const SlotCard = (props: Props) => {
    const t = useT()
    return <div className='position-relative'>
        <div className={formStyle.sectionHeader}>{props.title}</div>
        <div className='hstack gap-2 flex-wrap mb-2'>
            {props.list.map((l: any, index: number) => (
                <div className={style.container}>
                    <div className={style.card}>
                        <div className={`hstack gap-4 ${style.cardList}`}>{`${l.fromTime.time} ${l.fromTime.timePeriod} - ${l.toTime.time} ${l.toTime.timePeriod} `}</div>
                        <div className={`hstack gap-2 ${style.cardList}`}>{t("Capacity")} : {l.capacity} <div className={style.editButton} onClick={() => props.onEdit(index)} ><Edit /></div><div className={style.editButton} onClick={() => props.onDelete(index)} ><Trash /></div></div>
                    </div>
                </div>
            ))}
        </div>
        {/* <ErrorText errorText={props.errorText} /> */}
    </div>
}

export default SlotCard