import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import assets from '../../assets'
import { useT } from '../../i18n'
import style from '../../styles/components/side-navbar.module.scss'
import ChevronRight from '../svgs/ChevronRight'
import Close from '../utils/Close'

export interface NavbarItemChild {
    label?: string
    to?: string
    onClick?: () => void
}

interface NavbarItem {
    label?: string
    to?: string
    onClick?: () => void
    icon?: JSX.Element
    innerItems?: NavbarItemChild[]
    order?: number
    show?: boolean
    buttonStyle?: boolean
}

interface SideNavbarProps {
    isActive?: boolean
    onClose?: () => void
    navbarItemList?: NavbarItem[]
    title?: string
    showHeader?: boolean
    fixed?: boolean
    light?: boolean
    version?: string
}

const SideNavbar = (props: SideNavbarProps) => {
    const t = useT()
    return <div className={`${style.sideNavbar} ${props.fixed ? style.fixed : ''} ${props.isActive ? style.active : style.inactive} ${props.light ? style.light : ''}`}>
        {props.showHeader && <React.Fragment>
            <div className={style.header}>
                <div className={style.logoWrapper}>
                    <img src={assets.images.logo} alt='V-Shop logo' />
                </div>
                <Close white={props.light ? false : true} onClose={props.onClose} />
            </div>
            <div className='px-2'>
                <div className={style.logoCaption}>{t("Virtual shopping")},<br />{t("Real value")}!</div>
                {props.title && <div className={style.shopName}>{props.title}</div>}
            </div>
        </React.Fragment>}
        {props.navbarItemList?.filter(item => item.show === undefined || item.show === true).sort((a, b) => (a.order || 0) - (b.order || 0)).map((item, index) => {
            return <SideNavbarItem
                to={item.to}
                icon={item.icon}
                innerItems={item.innerItems}
                onClick={item.onClick}
                buttonStyle={item.buttonStyle}
                key={index}
            >{item.label}</SideNavbarItem>
        })}
        {props.version && <span className={style.version}>{t("Version")} {props.version}</span>}
    </div>
}

interface SideNavbarItemProps {
    to?: string
    onClick?: () => void
    children?: React.ReactNode
    icon?: JSX.Element
    innerItems?: NavbarItemChild[]
    buttonStyle?: boolean
}

const SideNavbarItem = (props: SideNavbarItemProps) => {
    const { pathname } = useLocation()
    const innerItemRef = React.useRef<HTMLDivElement>(null)

    const [collapsed, setCollapsed] = React.useState<boolean>(false)

    const innerItemsExist = !!props.innerItems?.length
    const maxHeight = collapsed ? innerItemRef.current?.scrollHeight || 0 : 0

    const itemClickHandler = () => {
        if (innerItemsExist) {
            setCollapsed(!collapsed)
        } else {
            setCollapsed(false)
        }

        props.onClick?.()
    }

    return <div className={`${style.itemWrapper} ${(props.to === pathname || collapsed) ? style.active : style.inactive}`}>
        <Item
            icon={props.icon}
            onClick={itemClickHandler}
            to={props.to}
            showChevron={innerItemsExist}
            collapsed={collapsed}
            buttonStyle={props.buttonStyle}
        >{props.children}</Item>
        {innerItemsExist && <div className={style.innerItem} style={{ maxHeight }} ref={innerItemRef}>
            {props.innerItems?.map((item, index) => {
                return <Item onClick={item.onClick} to={item.to} key={index}>{item.label}</Item>
            })}
        </div>}
    </div>
}

interface ItemProps {
    to?: string
    onClick?: () => void
    children?: React.ReactNode
    icon?: JSX.Element
    buttonStyle?: boolean
    collapsed?: boolean
    showChevron?: boolean
}

const Item = (props: ItemProps) => {
    return props.to
        ? <Link to={props.to} className={`${style.item} ${props.buttonStyle ? style.buttonStyle : ''}`}>
            <div className={style.left}>
                {props.icon}
                <span className={style.label}>{props.children}</span>
            </div>
            {props.showChevron && <div className={`${style.right} ${props.collapsed ? style.collapsed : ''}`}>
                <ChevronRight stroke='#ffffff' />
            </div>}
        </Link>
        : <div className={`${style.item} ${props.buttonStyle ? style.buttonStyle : ''}`} onClick={props.onClick}>
            <div className={style.left}>
                {props.icon}
                <span className={style.label}>{props.children}</span>
            </div>
            {props.showChevron && <div className={`${style.right} ${props.collapsed ? style.collapsed : ''}`}>
                <ChevronRight stroke='#ffffff' />
            </div>}
        </div>
}

export default SideNavbar