import React from 'react'
import { Link } from 'react-router-dom'
import { useT } from '../../i18n'
import style from '../../styles/components/card.module.scss'
import style1 from '../../styles/components/shop-card.module.scss'
import Cast from '../svgs/Cast'
import Button from '../utils/Button'
import Switch from '../utils/Switch'

export interface TableProps {
    name?: string
    img?: string
    offerLabel?: string
    price?: number | null | undefined
    eventDate?: string
    offerPrice?: number | null | undefined
    buttonText?: string
    onClick?: React.MouseEventHandler<HTMLButtonElement>
    rating?: number
    subText?: string
    locationPrefix?: string
    locationSuffix?: string
    favourites?: boolean
    isFavourite?: boolean
    includes?: string
    to?: string
    onFavouritesClick?: () => void
    startsFrom?: boolean
    status?: string | undefined | null
    toggleHandler?: () => void
    uploadBanner?: () => void
    updatePrice?: () => void
    itemId?: string
    from?: boolean
    preview?: boolean
    currency?: string
    previewHandler?: () => void
    cloneEvent?: () => void
    key?: number
    index?: number
}

const EventTable = (props: TableProps) => {
    return props.to
        ? <Link to={props.to} className='table-wrapper'>
            <Children {...props} />
        </Link>
        : <div className='table-wrapper'>
            <Children {...props} />
        </div>
}


const Children = (props: TableProps) => {
    const t = useT()

    return <React.Fragment>
        <div className='table-wrapper'>
            <table className='table'>
                {props.index === 0 && < thead className="table-primary">
                    <tr>
                        <th className={`align-middle ${style.tableStyle}`}>{t("Name")}</th>
                        <th className={`align-middle ${style.tableStyle}`}>{t("Product Image")}</th>
                        {/* <th className={`align-middle ${style.tableStyle}`}>Price</th>
                        <th className={`align-middle ${style.tableStyle}`}>Offer Price</th> */}
                        <th className={`align-middle pe-0 ${style.tableStyle}`}>{t("Clone Event")}</th>
                        <th className={`align-middle text-center ${style.tableStyle}`}>{t("Status")}</th>
                        <th className={`align-middle ${style.tableStyle}`}>{t("Action")}</th>
                    </tr>
                </thead>}
                <tbody>
                    < tr >
                        <td className={`align-middle text-start ${style.tableStyle} text-capitalize`}>
                            <div className='hstack gap-2'>
                                <div>{props.name}</div>
                                {props.previewHandler && <div className={`${style.castIconWrapper} ${props.preview ? style.showCast : ''}`} onClick={props.previewHandler}>
                                    <Cast stroke={props.preview ? '#4ab96a' : '#404040'} strokeWidth='2' />
                                </div>}
                            </div>
                        </td>

                        <td className={`align-middle text-start ${style.tableStyle}`}>
                            <div onClick={props.uploadBanner}>
                                <img src={props.img} alt='productimage' className={style1.bannerImage} />
                            </div>
                        </td>
                        {/* 
                        <td className={`align-middle text-start ${style.tableStyle}`}>
                            <div className='hstack gap-2'>
                                <div className=
                                    {style.editPrice} onClick={props.updatePrice}>
                                    {props.offerPrice?.toString() !== "" && props.offerPrice! > 0 && <div className={style.price}>{toCurrencyFormat(props.price, props?.currency)}
                                    </div>}
                                </div>
                                <div>
                                    {props.updatePrice && <div onClick={props.updatePrice}>
                                        <Edit />
                                    </div>}
                                </div>
                            </div>
                        </td>

                        <td className={`align-middle text-start ${style.tableStyle}`}>
                            {props.updatePrice && <div className=
                                {style.editPrice} onClick={props.updatePrice}>
                                <div>{props.offerPrice?.toString() !== "" && props.offerPrice! > 0
                                    ? <div> {toCurrencyFormat(props.offerPrice, props?.currency)}</div>
                                    : props.price?.toString() !== "" && props.price! > 0 ? <div> {toCurrencyFormat(props.price, props?.currency)}</div> : ""}</div>

                            </div>}
                        </td> */}
                        <td className={`align-middle  pe-0 ${style.tableStyle}`}>
                            {props.cloneEvent && <Button className={style.buttonStyle} onClick={props.cloneEvent}>{t("Clone")}</Button>}
                        </td>

                        <td className={`align-middle text-center ${style.tableStyle}`}>{props.status &&
                            <Switch
                                onClick={props?.toggleHandler}
                                checked={props?.status === "active" ? true : false}
                            // label={props.status === "active" ? 'Active' : 'Inactive'}
                            />
                        }</td>
                        <td className={`align-middle ${style.tableStyle} `}>
                            <Button className={style.buttonStyle} onClick={props.onClick}>{t("Edit")}</Button></td>
                    </tr>
                </tbody>
            </table>
        </div >
    </React.Fragment >
}

export default EventTable