/* eslint-disable no-throw-literal */
import { Dispatch } from 'redux'
import { api, apiErrorHandler, getApiRequestConfig, Response } from '../../api'
import { GetState } from '../reducers'
import { Shop } from '../reducers/shop.reducer'

export enum ShopActionTypes {
    ADD_SHOP = 'ADD_SHOP',
    FETCH_SHOPS = 'FETCH_SHOPS',
    SET_TO_FEATURED_SHOP = 'SET_TO_FEATURED_SHOP',
    REMOVE_FROM_FEATURED_SHOP = 'REMOVE_FROM_FEATURED_SHOP',
    SET_TO_PRIVATE_SHOP = 'SET_TO_PRIVATE_SHOP',
    REMOVE_FROM_PRIVATE_SHOP = 'REMOVE_FROM_PRIVATE_SHOP',
    ENABLE_SHOP = 'ENABLE_SHOP',
    DISABLE_SHOP = 'DISABLE_SHOP'
}

export interface FetchShopsAction {
    type: ShopActionTypes.FETCH_SHOPS
    data: Shop[]
}

export interface AddShopAction {
    type: ShopActionTypes.ADD_SHOP
    data: Shop
}

export interface SetToFeaturedShopAction {
    type: ShopActionTypes.SET_TO_FEATURED_SHOP
    data: string
}

export interface RemoveFromFeaturedShopAction {
    type: ShopActionTypes.REMOVE_FROM_FEATURED_SHOP
    data: string
}

export interface SetToPrivateShopAction {
    type: ShopActionTypes.SET_TO_PRIVATE_SHOP
    data: string
}

export interface RemoveFromPrivateShopAction {
    type: ShopActionTypes.REMOVE_FROM_PRIVATE_SHOP
    data: string
}

export interface EnableShopAction {
    type: ShopActionTypes.ENABLE_SHOP
    data: string
}

export interface DisableShopAction {
    type: ShopActionTypes.DISABLE_SHOP
    data: string
}


export type ShopActions = FetchShopsAction | AddShopAction | SetToFeaturedShopAction | RemoveFromFeaturedShopAction | EnableShopAction | DisableShopAction | SetToPrivateShopAction | RemoveFromPrivateShopAction

export const fetchShops = () => async (dispatch: Dispatch) => {
    return api.get<Response<Shop[]>>('shops').then(response => {
        if (response.status === 200) {
            dispatch<FetchShopsAction>({
                type: ShopActionTypes.FETCH_SHOPS,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || '')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to fetch'))
    })
}

export const setToFeaturedShop = (shopId: string) => async (dispatch: Dispatch, getState: GetState) => {
    const authSuperAdmin = getState().authSuperAdmin
    const config = getApiRequestConfig(authSuperAdmin?.['auth-token'])

    return api.put('shop/set/featured', { shopId }, config).then(response => {
        if (response.status === 200) {
            dispatch<SetToFeaturedShopAction>({
                type: ShopActionTypes.SET_TO_FEATURED_SHOP,
                data: shopId
            })
            return Promise.resolve<string>(response.data.message || 'Added to featured shop')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to set'))
    })
}

export const removeFromFeaturedShop = (shopId: string) => async (dispatch: Dispatch, getState: GetState) => {
    const authSuperAdmin = getState().authSuperAdmin
    const config = getApiRequestConfig(authSuperAdmin?.['auth-token'])

    return api.put('shop/remove/featured', { shopId }, config).then(response => {
        if (response.status === 200) {
            dispatch<RemoveFromFeaturedShopAction>({
                type: ShopActionTypes.REMOVE_FROM_FEATURED_SHOP,
                data: shopId
            })
            return Promise.resolve<string>(response.data.message || 'Removed from featured shop')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to remove'))
    })
}

export const setToPrivateShop = (shopId: string) => async (dispatch: Dispatch, getState: GetState) => {
    const authSuperAdmin = getState().authSuperAdmin
    const config = getApiRequestConfig(authSuperAdmin?.['auth-token'])

    return api.put('shop/set/private', { shopId }, config).then(response => {
        if (response.status === 200) {
            dispatch<SetToPrivateShopAction>({
                type: ShopActionTypes.SET_TO_PRIVATE_SHOP,
                data: shopId
            })
            return Promise.resolve<string>(response.data.message || 'Added to Private shop')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to set'))
    })
}

export const removeFromPrivateShop = (shopId: string) => async (dispatch: Dispatch, getState: GetState) => {
    const authSuperAdmin = getState().authSuperAdmin
    const config = getApiRequestConfig(authSuperAdmin?.['auth-token'])

    return api.put('shop/remove/private', { shopId }, config).then(response => {
        if (response.status === 200) {
            dispatch<RemoveFromPrivateShopAction>({
                type: ShopActionTypes.REMOVE_FROM_PRIVATE_SHOP,
                data: shopId
            })
            return Promise.resolve<string>(response.data.message || 'Removed from Private shop')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to remove'))
    })
}

export const enableShop = (shopId: string) => async (dispatch: Dispatch, getState: GetState) => {
    const authSuperAdmin = getState().authSuperAdmin
    const config = getApiRequestConfig(authSuperAdmin?.['auth-token'])

    return api.put('shop/enable', { shopId }, config).then(response => {
        if (response.status === 200) {
            dispatch<EnableShopAction>({
                type: ShopActionTypes.ENABLE_SHOP,
                data: shopId
            })
            return Promise.resolve<string>(response.data.message || 'Shop enabled')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to enable shop'))
    })
}
export const disableShop = (shopId: string) => async (dispatch: Dispatch, getState: GetState) => {
    const authSuperAdmin = getState().authSuperAdmin
    const config = getApiRequestConfig(authSuperAdmin?.['auth-token'])

    return api.put('shop/disable', { shopId }, config).then(response => {
        if (response.status === 200) {
            dispatch<DisableShopAction>({
                type: ShopActionTypes.DISABLE_SHOP,
                data: shopId
            })
            return Promise.resolve<string>(response.data.message || 'Shop disabled')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to disable shop'))
    })
}
