import * as SocketIOClient from 'socket.io-client'
import assets from '../assets'
import { API_URL } from '../config'
import { Order } from '../redux/reducers/order.reducer'
import { store } from '../redux/store'

type ConnectCallback = (socket: Socket) => void
type OnCallback = (order: Order) => void

export default class Socket {
    private static instance: Socket | null = null
    private socket: SocketIOClient.Socket | null = null

    private isConnectionRequestSend: boolean = false

    private constructor() { }

    public static init() {
        if (Socket.instance === null) {
            Socket.instance = new Socket()
        }

        return Socket.instance
    }

    public connect(callback?: ConnectCallback) {
        if (!this.socket?.connected && !this.isConnectionRequestSend) {
            this.socket = SocketIOClient.io(API_URL, {
                query: {
                    userId: store.getState().authAdmin?.id,
                    role: store.getState().authAdmin?.role,
                    shopId: store.getState().authAdmin?.shop.id
                }
            })

            this.isConnectionRequestSend = true

            this.socket.on('connect', () => {
                if (this.socket?.connected) {
                    if (Socket.instance) {
                        callback?.(Socket.instance)
                    }
                }
            })
        }
    }

    public getSocket() {
        return this.socket
    }

    // private playSound() {
    //     try {
    //         console.log("Sound 6")

    //         new Audio(assets.audios.school).play()

    //     } catch (error: any) { }
    // }

    // public onNewOrder(callback?: OnCallback) {
    //     console.log("Sound 4")

    //     this.socket?.on('newOrder', (order: Order) => {

    //         callback?.(order)
    //         console.log("Sound 5")

    //     })
    //     this.playSound()

    // }
    private async playSound() {
        try {
            console.log("Sound 6");
            const audio = new Audio(assets.audios.school);
            await audio.play();
        } catch (error) {
            console.error("Error playing sound:", error);
        }
    }

    public onNewOrder(callback?: OnCallback) {
        console.log("Sound 4");

        this.socket?.on('newOrder', async (order: Order) => {
            callback?.(order);
            console.log("Sound 5");
            await this.playSound();
        });
    }


    public offNewOrder() {
        console.log("off")

        this.socket?.off('newOrder')
    }

    public onOrderReady(callback?: OnCallback) {
        this.socket?.on('orderReady', (order: Order) => {
            callback?.(order)
        })
    }

    public offOrderReady() {
        this.socket?.off('orderReady')
    }

    public close() {
        if (this.socket) {
            if (this.socket.connected) {
                this.socket.off('connect')
                this.socket.disconnect()
            }
            this.socket = null
        }

        if (Socket.instance) {
            Socket.instance = null
        }
    }
}