/* eslint-disable no-throw-literal */
import { Dispatch } from 'redux'
import { api, apiErrorHandler, getApiRequestConfig, Response } from '../../api'
import { GetState } from '../reducers'
import { Booking } from '../reducers/booking.reducer'
import {BookingReport} from '../reducers/bookingReport.reducer'

export enum BookingActionTypes {
    ADD_BOOKING = 'ADD_BOOKING',
    FETCH_BOOKINGS = 'FETCH_BOOKINGS',
    REPLACE_BOOKING = 'REPLACE_BOOKING',
    FETCH_BOOKING_REPORTS = 'FETCH_BOOKING_REPORTS'
}

export interface AddBookingAction {
    type: BookingActionTypes.ADD_BOOKING
    data: Booking
}

export interface FetchBookingsAction {
    type: BookingActionTypes.FETCH_BOOKINGS
    data: Booking[]
}

export interface FetchBookingReportAction {
    type: BookingActionTypes.FETCH_BOOKING_REPORTS
    data: BookingReport[]
}

export interface ReplaceBookingAction {
    type: BookingActionTypes.REPLACE_BOOKING
    data: Booking
}

export type BookingActions = AddBookingAction | FetchBookingsAction | ReplaceBookingAction | FetchBookingReportAction

export const addBooking = (input: Booking): AddBookingAction => {
    return {
        type: BookingActionTypes.ADD_BOOKING,
        data: input
    }
}

export const updateBooking = (input: Booking): ReplaceBookingAction => {
    return {
        type: BookingActionTypes.REPLACE_BOOKING,
        data: input
    }
}

export const fetchShopBookingsById = ( bookingId: string) => async (dispatch: Dispatch, getState: GetState) => {
    const token = getState().authAdmin?.['auth-token']
    const config = getApiRequestConfig(token)

    return api.get<Response<Booking[]>>(`shop/booking/${bookingId}`, config).then(response => {
        if (response.status === 200) {
            dispatch<FetchBookingsAction>({
                type: BookingActionTypes.FETCH_BOOKINGS,
                data: response.data.data
            })

            return Promise.resolve<string>(response.data.message || '')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to fetch'))
    })
}

export const fetchShopBookings = () => async (dispatch: Dispatch, getState: GetState) => {
    const token = getState().authAdmin?.['auth-token']
    const config = getApiRequestConfig(token)

    return api.get<Response<Booking[]>>('shop/bookings', config).then(response => {
        if (response.status === 200) {
            dispatch<FetchBookingsAction>({
                type: BookingActionTypes.FETCH_BOOKINGS,
                data: response.data.data
            })

            return Promise.resolve<string>(response.data.message || '')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to fetch'))
    })
}

export const fetchShopBookingReports = (eventId:string,fromDate:string,toDate:string) => async (dispatch: Dispatch, getState: GetState) => {
    const token = getState().authAdmin?.['auth-token']
    const config = getApiRequestConfig(token)

    return api.get<Response<BookingReport[]>>(`shop/booking-reports/${eventId}/${fromDate}/${toDate}`, config).then(response => {
        if (response.status === 200) {
            dispatch<FetchBookingReportAction>({
                type: BookingActionTypes.FETCH_BOOKING_REPORTS,
                data: response.data.data
            })

            return Promise.resolve<string>(response.data.message || '')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to fetch'))
    })
}
export const updateShopBookingParticipantStatus = (data: any, bookingId: string) => async (dispatch: Dispatch, getState: GetState) => {
    const token = getState().authAdmin?.['auth-token']
    const config = getApiRequestConfig(token)

    return api.put<Response<Booking>>(`shop/booking/participant-status/${bookingId}`, { ...data }, config).then(response => {
        if (response.status === 200) {
            dispatch<ReplaceBookingAction>({
                type: BookingActionTypes.REPLACE_BOOKING,
                data: response.data.data
            })

            return Promise.resolve<string>(response.data.message || 'Booking is updated')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to update'))
    })
}

export const updateShopBookingStatus = (status: string, bookingId: string) => async (dispatch: Dispatch, getState: GetState) => {
    const token = getState().authAdmin?.['auth-token']
    const config = getApiRequestConfig(token)

    return api.put<Response<Booking>>(`shop/booking/update-status/${bookingId}`, { bookingStatus:status }, config).then(response => {
        if (response.status === 200) {
            dispatch<ReplaceBookingAction>({
                type: BookingActionTypes.REPLACE_BOOKING,
                data: response.data.data
            })

            return Promise.resolve<string>(response.data.message || 'Booking is updated')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to update'))
    })
}

export const updateShopBookingPayment = (status: string,shopId:string|undefined, bookingId: string) => async (dispatch: Dispatch, getState: GetState) => {
    const token = getState().authAdmin?.['auth-token']
    const config = getApiRequestConfig(token)

    return api.put<Response<Booking>>(`shop/booking/update-payment/${bookingId}`, { paymentStatus:status,shopId }, config).then(response => {
        if (response.status === 200) {
            dispatch<ReplaceBookingAction>({
                type: BookingActionTypes.REPLACE_BOOKING,
                data: response.data.data
            })

            return Promise.resolve<string>(response.data.message || 'Booking is updated')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to update'))
    })
}

export const resendEamilBookingPayment = (shopId:string|undefined, bookingId: string) => async (dispatch: Dispatch, getState: GetState) => {
    const token = getState().authAdmin?.['auth-token']
    const config = getApiRequestConfig(token)

    return api.put<Response<Booking>>(`shop/booking/resend-email/${bookingId}`, { shopId }, config).then(response => {
        if (response.status === 200) {
            dispatch<ReplaceBookingAction>({
                type: BookingActionTypes.REPLACE_BOOKING,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || 'Booking is updated')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to update'))
    })
}