/* eslint-disable no-throw-literal */
import { Dispatch } from 'redux'
import { api, apiErrorHandler, getApiRequestConfig, Response } from '../../api'
import { GetState } from '../reducers'
import { OtherBooking } from '../reducers/otherBooking.reducer'
import { OtherBookingReport } from '../reducers/otherBookingReport.reducer'

export enum OtherBookingActionTypes {
    FETCH_OTHER_BOOKINGS = 'FETCH_OTHER_BOOKINGS',
    REPLACE_OTHER_BOOKING = 'REPLACE_OTHER_BOOKING',
    FETCH_OTHER_BOOKING_REPORTS = 'FETCH_OTHER_BOOKING_REPORTS'
}

export interface FetchOtherBookingsAction {
    type: OtherBookingActionTypes.FETCH_OTHER_BOOKINGS
    data: OtherBooking[]
}

export interface ReplaceOtherBookingAction {
    type: OtherBookingActionTypes.REPLACE_OTHER_BOOKING
    data: OtherBooking
}

export interface FetchOtherBookingReportAction {
    type: OtherBookingActionTypes.FETCH_OTHER_BOOKING_REPORTS
    data: OtherBookingReport[]
}

export type OtherBookingActions = FetchOtherBookingsAction | ReplaceOtherBookingAction | FetchOtherBookingReportAction


export const updateOtherBooking = (input: OtherBooking): ReplaceOtherBookingAction => {
    return {
        type: OtherBookingActionTypes.REPLACE_OTHER_BOOKING,
        data: input
    }
}

export const fetchShopOtherBookingsById = ( bookingId: string) => async (dispatch: Dispatch, getState: GetState) => {
    const token = getState().authAdmin?.['auth-token']
    const config = getApiRequestConfig(token)

    return api.get<Response<OtherBooking[]>>(`shop/other-booking/${bookingId}`, config).then(response => {
        if (response.status === 200) {
            dispatch<FetchOtherBookingsAction>({
                type: OtherBookingActionTypes.FETCH_OTHER_BOOKINGS,
                data: response.data.data
            })

            return Promise.resolve<string>(response.data.message || '')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to fetch'))
    })
}

export const fetchShopOtherBookings = () => async (dispatch: Dispatch, getState: GetState) => {
    const token = getState().authAdmin?.['auth-token']
    const config = getApiRequestConfig(token)
    return api.get<Response<OtherBooking[]>>('shop/other-bookings', config).then(response => {
        if (response.status === 200) {
            dispatch<FetchOtherBookingsAction>({
                type: OtherBookingActionTypes.FETCH_OTHER_BOOKINGS,
                data: response.data.data
            })

            return Promise.resolve<string>(response.data.message || '')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to fetch'))
    })
}

export const fetchShopOtherBookingReports = (eventId:string,fromDate:string,toDate:string) => async (dispatch: Dispatch, getState: GetState) => {
    const token = getState().authAdmin?.['auth-token']
    const config = getApiRequestConfig(token)

    return api.get<Response<OtherBookingReport[]>>(`shop/other-booking-reports/${eventId}/${fromDate}/${toDate}`, config).then(response => {
        if (response.status === 200) {
            dispatch<FetchOtherBookingReportAction>({
                type: OtherBookingActionTypes.FETCH_OTHER_BOOKING_REPORTS,
                data: response.data.data
            })

            return Promise.resolve<string>(response.data.message || '')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to fetch'))
    })
}
export const updateShopOtherBookingParticipantStatus = (data: any, bookingId: string) => async (dispatch: Dispatch, getState: GetState) => {
    const token = getState().authAdmin?.['auth-token']
    const config = getApiRequestConfig(token)

    return api.put<Response<OtherBooking>>(`shop/other-booking/participant-status/${bookingId}`, { ...data }, config).then(response => {
        if (response.status === 200) {
            dispatch<ReplaceOtherBookingAction>({
                type: OtherBookingActionTypes.REPLACE_OTHER_BOOKING,
                data: response.data.data
            })

            return Promise.resolve<string>(response.data.message || 'Booking is updated')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to update'))
    })
}

export const updateShopOtherBookingStatus = (status: string, bookingId: string) => async (dispatch: Dispatch, getState: GetState) => {
    const token = getState().authAdmin?.['auth-token']
    const config = getApiRequestConfig(token)

    return api.put<Response<OtherBooking>>(`shop/other-booking/update-status/${bookingId}`, { bookingStatus:status }, config).then(response => {
        if (response.status === 200) {
            dispatch<ReplaceOtherBookingAction>({
                type: OtherBookingActionTypes.REPLACE_OTHER_BOOKING,
                data: response.data.data
            })

            return Promise.resolve<string>(response.data.message || 'Booking is updated')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to update'))
    })
}

export const updateShopOtherBookingPayment = (status: string,shopId:string|undefined, bookingId: string) => async (dispatch: Dispatch, getState: GetState) => {
    const token = getState().authAdmin?.['auth-token']
    const config = getApiRequestConfig(token)

    return api.put<Response<OtherBooking>>(`shop/other-booking/update-payment/${bookingId}`, { paymentStatus:status,shopId }, config).then(response => {
        if (response.status === 200) {
            dispatch<ReplaceOtherBookingAction>({
                type: OtherBookingActionTypes.REPLACE_OTHER_BOOKING,
                data: response.data.data
            })

            return Promise.resolve<string>(response.data.message || 'Booking is updated')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to update'))
    })
}

export const resendEamilOtherBookingPayment = (shopId:string|undefined, bookingId: string) => async (dispatch: Dispatch, getState: GetState) => {
    const token = getState().authAdmin?.['auth-token']
    const config = getApiRequestConfig(token)

    return api.put<Response<OtherBooking>>(`shop/other-booking/resend-email/${bookingId}`, { shopId }, config).then(response => {
        if (response.status === 200) {
            dispatch<ReplaceOtherBookingAction>({
                type: OtherBookingActionTypes.REPLACE_OTHER_BOOKING,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || 'Booking is updated')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to update'))
    })
}
