import assets from '../assets'
import Lottie from '../components/lottie/Lottie'
import CommonLayout from '../components/sections/CommonLayout'
import ButtonLink from '../components/utils/ButtonLink'
import { useT } from '../i18n'
import style from '../styles/pages/not-found.module.scss'

const NotFound = () => {
    const t = useT()
    return <CommonLayout>
        <div className={style.container}>
            <div className={style.animationContainer}>
                <Lottie
                    data={assets.json.notFound}
                    loop
                />
            </div>
            <div className={style.buttonWrapper}>
                <ButtonLink to='/' className={style.button}>{t("Go to Home")}</ButtonLink>
            </div>
        </div>
    </CommonLayout>
}

export default NotFound