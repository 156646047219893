import CommonLayout from '../components/sections/CommonLayout'
import { useT } from '../i18n'
import style from '../styles/pages/about-us.module.scss'

const AboutUs = () => {
    const t = useT()
    return <CommonLayout>
        <div className='container mx-5'>
            <h4 className={style.title}>{t("About us")}</h4>

            <h6 className={style.description}>{t("We are a chain of virtual shopping 100+ Individual Entrepreneurs and companies to deliver you the best belongings in town. Our goal is to connect customers to Individual owners and shops virtually and in a more efficient and fairway!")}
            </h6>
        </div>
    </CommonLayout>
}

export default AboutUs