import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useSelector } from 'react-redux'
import AdminLayout from '../../components/sections/AdminLayout'
import AdminPanelHeader from '../../components/sections/AdminPanelHeader'
import AdminAppointmentBooking from '../../components/sections/AppointmentBooking'
import Spinner from '../../components/utils/Spinner'
import { AdminContext, AdminContextType } from '../../context/AdminProvider'
import { useT } from '../../i18n'
import { StoreState } from '../../redux/reducers'
import { AppointmentBooking } from '../../redux/reducers/appointmentBookings.reducer'
import style from '../../styles/pages/admin-orders.module.scss'

type BookingMenu = 'BOOKED' | 'ATTENDED' | 'CANCELLED' | 'PAYMENT_PENDING' | 'FAILED'

const getBookingMenu = (booking: AppointmentBooking): BookingMenu | null => {
    if (booking.paymentStatus === 'pending') {
        return 'PAYMENT_PENDING'
    }

    if (booking.paymentStatus === 'failed') {
        return 'FAILED'
    }

    if (booking.reservationStatus === 'booked') {
        if (booking.shopId.otherPayments.findIndex(x => x.title === booking.paymentMode) !== -1) {
            return 'BOOKED'
        }

        if (booking.paymentMode === 'paytrail' && booking.paymentStatus === 'paid') {
            return 'BOOKED'
        }
    }

    if (booking.reservationStatus === 'attended') {
        return 'ATTENDED'
    }

    if (booking.reservationStatus === 'cancelled') {
        return 'CANCELLED'
    }

    return null
}

const AppointmentBookings = () => {
    const adminContext = React.useContext<AdminContextType | null>(AdminContext)
    const appointmentBookings = useSelector<StoreState, AppointmentBooking[]>(state => state.appointmentBooking)

    const t = useT()
    const [bookingMenu, setBookingMenu] = React.useState<BookingMenu>('PAYMENT_PENDING')

    const filteredBookings = appointmentBookings.filter(booking => getBookingMenu(booking) === bookingMenu)
    const [pages, setPages] = React.useState(Array.from({ length: 0 }))
    const [hasMore, setHasMore] = React.useState(true)
    const fetchMoreData = () => {
        if (pages.length <= filteredBookings.length) {
            setTimeout(() => {
                setPages(pages.concat(Array.from({ length: 20 })))
            }, 1000)
            return
        }
        setHasMore(false)
    }
    React.useEffect(() => {
        fetchMoreData()
    }, [])

    return <AdminLayout>
        <div className='mb-4 mt-2'>
            <AdminPanelHeader title='Orders' />
            <div className='row'>
                <div className='col-sm-12 col-lg-2 col-xl-2 mb-2'>
                    <BookingMenuLink active={bookingMenu === 'PAYMENT_PENDING'} onClick={() => setBookingMenu('PAYMENT_PENDING')}>{t("PAYMENT PENDING")} ({appointmentBookings.filter(order => getBookingMenu(order) === "PAYMENT_PENDING").length})</BookingMenuLink>
                </div>
                <div className='col-sm-12 col-lg-2 col-xl-2 mb-2'>
                    <BookingMenuLink active={bookingMenu === 'BOOKED'} onClick={() => setBookingMenu('BOOKED')}>{t("BOOKED")} ({appointmentBookings.filter(order => getBookingMenu(order) === "BOOKED").length})</BookingMenuLink>
                </div>
                <div className='col-sm-12 col-lg-2 col-xl-2 mb-2'>
                    <BookingMenuLink active={bookingMenu === 'ATTENDED'} onClick={() => setBookingMenu('ATTENDED')}>{t("ATTENDED")} ({appointmentBookings.filter(order => getBookingMenu(order) === "ATTENDED").length})</BookingMenuLink>
                </div>
                <div className='col-sm-12 col-lg-2 col-xl-2 mb-2'>
                    <BookingMenuLink active={bookingMenu === 'CANCELLED'} onClick={() => setBookingMenu('CANCELLED')}>{t("CANCELLED")} ({appointmentBookings.filter(order => getBookingMenu(order) === "CANCELLED").length})</BookingMenuLink>
                </div>
                <div className='col-sm-12 col-lg-2 col-xl-2 mb-2'>
                    <BookingMenuLink active={bookingMenu === 'FAILED'} onClick={() => setBookingMenu('FAILED')}>{t("FAILED")} ({appointmentBookings.filter(order => getBookingMenu(order) === "FAILED").length})</BookingMenuLink>
                </div>
            </div>
            {adminContext?.fetchingOrders
                ? <Spinner size={25} color='#ec3b50' className='mt-3' />
                :
                filteredBookings.length !== 0 && <InfiniteScroll dataLength={pages.length} scrollableTarget="scrollableDiv" next={fetchMoreData} hasMore={hasMore} loader={<p className={style.infiniteScrollText}>{t("Fetching orders...")}</p>} endMessage={<p className={style.infiniteScrollText}>{t("All orders fetched")}</p>}>
                    {filteredBookings.reverse().slice(0, pages.length).map(booking => {
                        return <AdminAppointmentBooking
                            key={booking.id}
                            booking={booking}
                            role='ADMIN'
                        />
                    })}
                </InfiniteScroll>
            }

        </div>
    </AdminLayout>
}

interface MenuLinkProps {
    children?: React.ReactNode
    active?: boolean
    onClick?: () => void
}

const BookingMenuLink = (props: MenuLinkProps) => {
    return <div className={`${style.orderMenuLink} ${props.active ? style.active : ''}`} children={props.children} onClick={props.onClick} />
}

export default AppointmentBookings