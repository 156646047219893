import React from 'react'
import { useDispatch } from 'react-redux'
import Option from '../../components/utils/Option'
import { useT } from '../../i18n'
import { AppDispatch } from '../../redux/store'
import formStyle from '../../styles/components/form.module.scss'
import { toString } from '../../utils'
import { useToast } from '../ToastProvider'
import Button from '../utils/Button'
import ColorPicker from '../utils/ColorPicker'
import FormInput from '../utils/FormInput'
import FormInputs from '../utils/FormInputs'


const ANIMATION_DROPS = [
    {
        text: 'Slideshow Right to Left',
        value: 'rightLeftAnimations'
    },
    {
        text: 'Slideshow Bottom to Top',
        value: 'bottomTopAnimations'
    },
    {
        text: 'Fade in and out',
        value: 'fadeInOut'
    }
]

interface Price {
    worsePrice: string
    bestPrice: string
}

interface PreviewTheme {
    animation: string
    fontStyle: string
    backgroundColor: string
    color: string
    priceColor: Price
}

interface PreviewForm {
    previewTheme?: PreviewTheme
    previewHandler?: (type: string, value: string) => void
    onSave?: () => boolean
}
const ProductPreviewForm = (props: PreviewForm) => {

    const dispatch = useDispatch<AppDispatch>()
    const toast = useToast()
    const t = useT()


    const [animationError, setAnimationError] = React.useState<string>('')
    const [fontError, setFontError] = React.useState<string>('')
    const [backgroundColorError, setBackgroundColorError] = React.useState<string>('')
    const [colorError, setColorError] = React.useState<string>('')
    const [primaryColorError, setPrimartColorError] = React.useState<string>('')
    const [secondaryColorError, setSecondaryColorError] = React.useState<string>('')

    const [loading, setLoading] = React.useState<boolean>(false)


    const saveClickHandler: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault()

        let error = false

        if (toString(props.previewTheme?.animation) === '') {
            setAnimationError(t('Animation required'))
            error = true
        }

        if (toString(props.previewTheme?.fontStyle) === '') {
            setFontError(t('Font required'))
            error = true
        }

        if (toString(props.previewTheme?.backgroundColor) === '') {
            setBackgroundColorError(t('Background color required'))
            error = true
        }

        if (toString(props.previewTheme?.color) === '') {
            setColorError(t('Color required'))
            error = true
        }

        if (toString(props.previewTheme?.priceColor.bestPrice) === '') {
            setPrimartColorError(t('Primary color required'))
            error = true
        }

        if (toString(props.previewTheme?.priceColor.worsePrice) === '') {
            setSecondaryColorError(t('Secondary color required'))
            error = true
        }
        if (!error) {
            const response = props.onSave?.();
            setLoading(response ? response : true);
        }
    }

    const errorRemoveHandler = (type: string) => {
        if (type === "animation")
            setAnimationError('');
        if (type === "fontStyle")
            setFontError('');
        if (type === "backgroundColor")
            setBackgroundColorError('')
        if (type === "color")
            setColorError('')
        if (type === "bestPrice")
            setPrimartColorError('')
        if (type === "worsePrice")
            setSecondaryColorError('')

    }
    return <React.Fragment>
        <div className='col-md-6 col-lg-12'>
            <FormInputs
                type='select'
                label={t('Animations')}
                placeholder={t('Animations')}
                errorText={animationError}
                value={props.previewTheme?.animation}
                onChange={(e) => { props.previewHandler?.('animation', e.target.value); errorRemoveHandler('animation') }}
                containerClass='mb-4'
            >
                <option value="">Select</option>
                {ANIMATION_DROPS.map((a, i) => {
                    return <Option value={a.value.toString()} key={i}>{a.text}</Option>
                })}
            </FormInputs>
        </div>
        <div className='col-md-6 col-lg-12'>
            <FormInput
                type='text'
                label={t('Font')}
                placeholder={t('Font')}
                errorText={fontError}
                value={props.previewTheme?.fontStyle}
                onChange={(e) => { props.previewHandler?.('fontStyle', e.target.value); errorRemoveHandler('fontStyle') }}
                containerClass='mb-4'
            />
        </div>
        <div className='row'>
            <div className='col-md-6 col-lg-6'>
                <ColorPicker
                    label={t('Background Color')}
                    placeholder={'#ffffff'}
                    containerClass='mb-4'
                    value={props.previewTheme?.backgroundColor}
                    onChange={(e) => { props.previewHandler?.('backgroundColor', e.target.value); errorRemoveHandler('backgroundColor') }}
                    errorText={backgroundColorError}
                />
            </div>
            <div className='col-md-6 col-lg-6'>
                <ColorPicker
                    label={t('Color')}
                    placeholder={'#ffffff'}
                    containerClass='mb-4'
                    value={props.previewTheme?.color}
                    onChange={(e) => { props.previewHandler?.('color', e.target.value); errorRemoveHandler("color") }}
                    errorText={colorError}
                />
            </div>
        </div>
        <div className='row'>
            <div className='col-md-6 col-lg-6'>
                <ColorPicker
                    label={t('Price (Primary)')}
                    placeholder={'#ffffff'}
                    containerClass='mb-4'
                    value={props.previewTheme?.priceColor.bestPrice}
                    onChange={(e) => { props.previewHandler?.('bestPrice', e.target.value); errorRemoveHandler('bestPrice') }}
                    errorText={primaryColorError}
                />
            </div>
            <div className='col-md-6 col-lg-6'>
                <ColorPicker
                    label={t('Price (Secondary)')}
                    placeholder={'#fffffff'}
                    containerClass='mb-4'
                    value={props.previewTheme?.priceColor.worsePrice}
                    onChange={(e) => { props.previewHandler?.('worsePrice', e.target.value); errorRemoveHandler('worsePrice') }}
                    errorText={secondaryColorError}
                />
            </div>
        </div>

        <div className='mb-4'>
            <Button onClick={saveClickHandler} loading={loading} className={formStyle.saveButton}>{t("Update Preview theme")}</Button>
        </div>
    </React.Fragment>
}

export default ProductPreviewForm;