import { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';
import { useT } from '../../i18n';

// simple donut chart

interface Props {
    data:{name:string,count:number,color:string}[]
}

const EventDonutChart = (props:Props) => {
    const t = useT()
    // default options
    const apexDonutOpts: ApexOptions = {
        chart: {
            height: 320,
            type: 'pie',
        },
        labels: props.data.map((d)=>d.name),
        colors: props.data.map((d)=>d.color),
        legend: {
            show: true,
            position: 'bottom',
            horizontalAlign: 'center',
            floating: false,
            fontSize: '14px',
            offsetX: 0,
            offsetY: -10,
        },
        responsive: [
            {
                breakpoint: 600,
                options: {
                    chart: {
                        height: 240,
                    },
                    legend: {
                        show: false,
                    },
                },
            },
        ],
    };

    // chart data
    const apexDonutData = props.data.map((d)=>d.count);

    return (
        <>
            <h4 className="header-title mb-3">{}</h4>
            <Chart
                options={apexDonutOpts}
                series={apexDonutData}
                type="donut"
                height={270}
                width={350}
                className="apex-charts"
            />
        </>
    );
};

export default EventDonutChart;
