import { nanoid } from '@reduxjs/toolkit'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DEFAULT_LANGUAGES } from '../../config'
import { useT } from '../../i18n'
import { addCategory, addLanguage, updateCategory } from '../../redux/actions'
import { StoreState } from '../../redux/reducers'
import { Category } from '../../redux/reducers/category.reducer'
import { AppDispatch } from '../../redux/store'
import { toString } from '../../utils'
import { ToastType, useToast } from '../ToastProvider'
import ModalWithHeader from '../modals/ModalWithHeader'
import SelectableTileSection from '../sections/SelectableTileSection'
import FormInput from '../utils/FormInput'
import ResponsiveFormInput from '../utils/ResponsiveFormInput'

interface CategoryFormProps {
    onClose?: () => void
    id?: string | null
}

interface Name {
    language: string
    name: string
    errorText: string
    id: string
}

const CategoryForm = (props: CategoryFormProps) => {
    const dispatch = useDispatch<AppDispatch>()
    const toast = useToast()
    const t = useT()

    const languages = useSelector<StoreState, string[]>(state => state.languages)
    const category = useSelector<StoreState, Category | null>(state => props.id ? state.categories.find(p => p.id === props.id) || null : null)

    const [selectedLanguages, setSelectedLanguages] = React.useState<string[]>(DEFAULT_LANGUAGES)
    const [names, setNames] = React.useState<Name[]>([])
    const [offerLabel, setOfferLabel] = React.useState<string>('')
    const [offerLabelError, setOfferLabelError] = React.useState<string>('')

    const [loading, setLoading] = React.useState<boolean>(false)

    const languageClickHandler = (lng?: string) => {
        if (lng && lng !== '') {
            setSelectedLanguages(prev => {
                return prev.some(p => p === lng)
                    ? prev.length > 1
                        ? prev.filter(p => p !== lng)
                        : prev
                    : [
                        ...prev,
                        lng
                    ]
            })

            setNames(prev => {
                return prev.some(p => p.language === lng)
                    ? prev.length > 1
                        ? prev.filter(p => p.language !== lng)
                        : prev
                    : [
                        ...prev,
                        {
                            language: lng,
                            name: '',
                            errorText: '',
                            id: nanoid()
                        }
                    ]
            })
        }
    }

    const nameChangeHandler = (id: string | null, value: string) => {
        setNames(prev => {
            const names = [...prev]
            const index = names.findIndex(p => p.id === id)
            if (index > -1) {
                names[index] = {
                    ...names[index],
                    name: value,
                    errorText: ''
                }
            }

            return names
        })
    }

    const addLanguageClickHandler = (language: string) => {
        dispatch(addLanguage(language))
    }

    const offerLabelChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setOfferLabel(e.target.value)
        setOfferLabelError('')
    }

    const submitHandler: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault()

        let error = false

        names.forEach((name, i) => {
            if (toString(name.name) === '') {
                error = true
                setNames(prev => {
                    const names = [...prev]
                    names[i].errorText = name.language.charAt(0).toUpperCase() + name.language.substring(1) + t('Name required')
                    return names
                })
            }
        })

        if (!error) {
            if (category && props.id) {
                setLoading(true)
                dispatch(updateCategory({
                    names: names.map(n => ({ language: toString(n.language), name: toString(n.name) })),
                    offerLabel: toString(offerLabel) || null
                }, category.id)).then(() => {
                    toast(t('Category updated'))
                    props.onClose?.()
                }).catch(text => {
                    toast(text, ToastType.ERROR)
                }).finally(() => {
                    setLoading(false)
                })
            } else {
                setLoading(true)
                dispatch(addCategory({
                    names: names.map(n => ({ language: toString(n.language), name: toString(n.name) })),
                    offerLabel: toString(offerLabel) || null
                })).then(() => {
                    toast(t('Category added'))
                    props.onClose?.()
                }).catch(text => {
                    toast(text, ToastType.ERROR)
                }).finally(() => {
                    setLoading(false)
                })
            }
        }
    }

    React.useEffect(() => {
        setNames(prev => {
            return selectedLanguages.map(lng => {
                return prev.find(p => p.language === lng) || {
                    errorText: '',
                    id: nanoid(),
                    language: lng,
                    name: ''
                }
            })
        })
    }, [selectedLanguages])

    React.useEffect(() => {
        if (category && props.id) {
            setSelectedLanguages(() => {
                const lngs: string[] = []
                category.names.forEach(n => {
                    if (lngs.indexOf(n.language) === -1) {
                        lngs.push(n.language)
                    }
                })
                return lngs
            })
            setNames(() => {
                const names: Name[] = []

                category.names.forEach(n => {
                    if (!names.some(p => p.language === n.language)) {
                        names.push({
                            errorText: '',
                            id: n.id,
                            language: n.language,
                            name: n.name
                        })
                    }
                })

                return names
            })
            setOfferLabel(category.offerLabel || '')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [category])

    return <ModalWithHeader
        buttonText={t('Save')}
        headerText={props.id ? t('Edit Category') : t('Add Category')}
        loading={loading}
        onClose={props.onClose}
        onSave={submitHandler}
        small
    >
        <div className='row mb-4'>
            <div className='col'>
                <SelectableTileSection
                    list={languages}
                    getSelectableTileProps={language => ({
                        key: language,
                        active: selectedLanguages.some(slng => slng === language),
                        onClick: languageClickHandler,
                        value: language,
                        label: language
                    })}
                    title={t('Language')}
                    instantInput={{
                        onAdd: addLanguageClickHandler,
                        label: t('Language')
                    }}
                />
            </div>
        </div>

        <div className='row'>
            <div className='col'>
                <div className='row'>
                    {selectedLanguages.map(lng => {
                        const name = names.find(n => n.language === lng)

                        return <ResponsiveFormInput
                            responsiveClassName='col-12'
                            containerClass='mb-4'
                            type='text'
                            key={lng}
                            label={lng.charAt(0).toUpperCase() + lng.slice(1) + t('Name')}
                            value={name?.name || ''}
                            onChange={e => nameChangeHandler(name?.id || null, e.target.value)}
                            errorText={name?.errorText}
                        />
                    })}
                </div>
            </div>
        </div>

        <div className='row'>
            <div className='col'>
                <FormInput
                    type='text'
                    label={t('Offer label')}
                    value={offerLabel}
                    onChange={offerLabelChangeHandler}
                    errorText={offerLabelError}
                    placeholder={t('Offer label')}
                />
            </div>
        </div>
    </ModalWithHeader>
}

export default CategoryForm