import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import HomeSectionCard from '../../components/cards/HomeSectionCard'
import Confetti from '../../components/lottie/Confetti'
import HomeSection from '../../components/sections/HomeSection'
import UserLayout from '../../components/sections/UserLayout'
import { useT } from '../../i18n'
import { appointmentRemoveCartItem } from '../../redux/actions'
import { StoreState } from '../../redux/reducers'
import { Rating } from '../../redux/reducers/rating.reducer'
import { Shop } from '../../redux/reducers/shop.reducer'
import { AppDispatch } from '../../redux/store'
import style from '../../styles/pages/order-placed.module.scss'

type ReservationParams = {
    reservationNumber?: string
}

const EventBooked = () => {
    const { reservationNumber } = useParams<ReservationParams>()
    const t = useT()
    const dispatch = useDispatch<AppDispatch>()
    const shops = useSelector<StoreState, Shop[]>(state => state.shops).filter(s => s.active === true)

    const [showAnimation, setShowAnimation] = React.useState<boolean>(true)

    const getRating = (rating: Rating[]) => {
        let sum = rating.reduce((prev, current) => {
            return prev + current.rating
        }, 0)
        const value = sum / rating.length
        return rating.length > 0 ? value : 0
    }

    React.useEffect(() => {
        dispatch(appointmentRemoveCartItem())
    }, [dispatch])

    return <React.Fragment>
        {showAnimation && <Confetti onClose={() => setShowAnimation(false)} />}
        <UserLayout>
            <div className={style.thankyou}>{t("Thank you for your reservation with us!")}</div>
            <div className={style.orderPlaced}>{t("Your reservation successfully registered")}.</div>

            <div className={style.orderNumber}>{t("Reservation number")}: {reservationNumber}</div>
            {shops.length > 0 && <HomeSection
                titleLeft
                items={shops}
                title={t('Shops')}
                renderItemGetter={item => {
                    return <HomeSectionCard
                        img={item.theme.bannerImage || ''}
                        locationPrefix={item.address.city}
                        locationSuffix={item.address.landmark || ''}
                        name={item.name}
                        rating={getRating(item.ratings || [])}
                        subText={item.businessName || ''}
                        to={`/shop/${item.id}`}
                        itemId={item.id}

                    />
                }}
            />}
        </UserLayout>
    </React.Fragment>
}

export default EventBooked;