import React from 'react'
import { useT } from '../../i18n'
import formStyle from '../../styles/components/form.module.scss'
import FormInput from '../utils/FormInput'

interface FoodPriceFormProps {
    responsiveClassName?: string
    label?: string
    price?: string
    offerPrice?: string
    showOfferPrice?: boolean
    priceError?: string
    offerPriceError?: string
    onPriceChange?: React.ChangeEventHandler<HTMLInputElement>
    onOfferPriceChange?: React.ChangeEventHandler<HTMLInputElement>
}

const FoodPriceForm = (props: FoodPriceFormProps) => {
    const t = useT()
    return <div className={props.responsiveClassName}>
        <div className={formStyle.sectionHeader}>{props.label ? (props.label.charAt(0).toUpperCase() + props.label.substring(1) + ' price') : t('Prices')}</div>
        <FormInput
            type='number'
            placeholder={t('Prices')}
            onChange={props.onPriceChange}
            errorText={props.priceError}
            value={props.price}
            containerClass='mb-4'
        />
        {props.showOfferPrice && <FormInput
            type='number'
            placeholder={t('Offer price')}
            onChange={props.onOfferPriceChange}
            errorText={props.offerPriceError}
            value={props.offerPrice}
        />}
    </div>
}

export default FoodPriceForm