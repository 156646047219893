interface IosHeartProps {
    fill?: string
    stroke?: string
}

const IosHeart = (props: IosHeartProps) => {
    return <svg xmlns='http://www.w3.org/2000/svg' width='17' height='17' viewBox='0 0 15 15'>
        <path id='Icon_ionic-ios-heart' data-name='Icon ionic-ios-heart' d='M13.606,3.938h-.034a3.731,3.731,0,0,0-3.2,2.081,3.731,3.731,0,0,0-3.2-2.081H7.144c-2.083.024-3.769,2.037-3.769,4.522a8.908,8.908,0,0,0,1.672,4.626,11.794,11.794,0,0,0,5.328,4.853,11.812,11.812,0,0,0,5.455-4.853,8.635,8.635,0,0,0,1.545-4.626C17.375,5.974,15.689,3.962,13.606,3.938Z' transform='translate(-2.875 -3.438)' fill={props.fill || 'none'} stroke={props.stroke || '#404040'} strokeLinecap='round' strokeLinejoin='round' strokeWidth='1' />
    </svg>
}

export default IosHeart