import {
    ContentState,
    EditorState,
    convertToRaw,
} from 'draft-js'
import draftToHtml from "draftjs-to-html"
import htmlToDraft from "html-to-draftjs"
import React, { useEffect } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { useDispatch, useSelector } from 'react-redux'
import { useT } from '../../i18n'
import { addBlog } from '../../redux/actions'
import { fetchBlogs, updateBlog } from '../../redux/actions/blog.actions'
import { StoreState } from '../../redux/reducers'
import { Blog } from '../../redux/reducers/blog.reducer'
import { AppDispatch } from '../../redux/store'
import style from '../../styles/components/voucher-form.module.scss'
import { toString } from '../../utils'
import { ToastType, useToast } from '../ToastProvider'
import ModalWithHeader from '../modals/ModalWithHeader'
import FormInput from '../utils/FormInput'
import FormTextArea from '../utils/FormTextArea'



interface BlogFormProps {
    onClose?: () => void
    id?: string | null
}

const BlogForm = (props: BlogFormProps) => {
    const dispatch = useDispatch<AppDispatch>()
    const toast = useToast()
    const t = useT()

    const Blog = useSelector<StoreState, Blog | null>(state => props.id ? state.blog.find(s => s.id === props.id) || null : null)

    const [title, setTitle] = React.useState<string>('')
    const [titleError, setTitleError] = React.useState<string>('')
    const [description, setDescription] = React.useState<string>('')
    const [descriptionError, setDescriptionError] = React.useState<string>('')
    const [imageUrl, setImageUrl] = React.useState<string>('')
    const [imageUrlError, setImageUrlError] = React.useState<string>('')
    const [content, setContent] = React.useState<EditorState>(EditorState.createEmpty())
    const [contentError, setContentError] = React.useState('')
    const [author, setAuthor] = React.useState<string>('')
    const [authorError, setAuthorError] = React.useState<string>('')

    const [loading, setLoading] = React.useState<boolean>(false)

    const resetStates = () => {
        setTitle('')
        setTitleError('')
        setDescription('')
        setDescriptionError('')
        setImageUrl('')
        setImageUrlError('')
        setContent(EditorState.createEmpty())
        setContentError('')
        setAuthor('')
        setAuthorError('')
    }

    const TitleChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setTitle(e.target.value)
        setTitleError('')
    }

    const DescriptionChangeHandler: React.ChangeEventHandler<HTMLTextAreaElement> = (e) => {
        setDescription(e.target.value)
        setDescriptionError('')
    }

    const ImageUrlChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setImageUrl(e.target.value)
        setImageUrlError('')
    }

    const ContentChangeHandler = (editorState: EditorState) => {
        setContent(editorState);
        setContentError('')
    }

    const AuthorChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setAuthor(e.target.value)
        setAuthorError('')
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleClear = () => {
        resetStates()
    }

    const submitHandler: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault()

        let error = false

        const contentState = draftToHtml(convertToRaw(content.getCurrentContent()));
        let cont = contentState;

        if (title === '') {
            setTitleError(t('Title required'))
            error = true
        }

        if (description === '') {
            setDescriptionError(t('Description required'))
            error = true
        }

        if (imageUrl === '') {
            setImageUrlError(t('ImageUrl required'))
            error = true
        }

        if (cont.toString() === '') {
            setContentError(t('Content required'))
            error = true
        }

        if (author === '') {
            setAuthorError(t('Author required'))
            error = true
        }

        if (!error) {
            if (props.id !== "" && Blog) {

                setLoading(true)
                dispatch(updateBlog({
                    title: toString(title),
                    description: toString(description),
                    imageUrl: toString(imageUrl),
                    content: toString(cont),
                    author: toString(author),
                }, Blog.id)).then(() => {
                    toast(t('Blog updated'))
                    dispatch(fetchBlogs())
                    props.onClose?.()
                }).catch(text => {
                    toast(text, ToastType.ERROR)
                }).finally(() => {
                    setLoading(false)
                })
            } else {
                setLoading(true)

                dispatch(addBlog({
                    title: toString(title),
                    description: toString(description),
                    imageUrl: toString(imageUrl),
                    content: toString(cont),
                    author: toString(author),
                })).then(() => {
                    toast(t('Blog added'))
                    props.onClose?.()
                }).catch(text => {
                    toast(text, ToastType.ERROR)
                }).finally(() => {
                    setLoading(false)
                })
            }
        }
    }

    useEffect(() => {
        if (Blog && props.id !== '') {
            setTitle(Blog.title)
            setDescription(Blog.description!)
            setImageUrl(Blog.imageUrl)
            // setContent(Blog.content)
            setAuthor(Blog.author)
            let contentEngBlock = htmlToDraft(Blog.content);
            const contentState = ContentState.createFromBlockArray(
                contentEngBlock.contentBlocks
            );
            const editorState = EditorState.createWithContent(contentState);
            setContent(editorState);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Blog])

    return <ModalWithHeader
        buttonText={t('Save')}
        headerText={props.id ? t('Edit Blog') : t('Add Blog')}
        loading={loading}
        onClose={props.onClose}
        onSave={submitHandler}
    >
        <div className='row'>
            <div className='col-12'>
                <FormInput
                    type='text'
                    label={t('Title')}
                    placeholder={t('Title')}
                    errorText={titleError}
                    value={title}
                    onChange={TitleChangeHandler}
                    containerClass='mb-4'
                />
            </div>
            <div className='col-12'>
                <FormTextArea
                    label={t('Description')}
                    placeholder={t('Description')}
                    errorText={descriptionError}
                    value={description}
                    onChange={DescriptionChangeHandler}
                    containerClass='mb-4'
                />
            </div>
            <div className='col-12'>
                <FormInput
                    type='text'
                    label={t('Image URL')}
                    placeholder={t('Image URL')}
                    errorText={imageUrlError}
                    value={imageUrl}
                    onChange={ImageUrlChangeHandler}
                    containerClass='mb-4'
                    rightRendererClassName={style.generate}
                />
            </div>
            <div className='col-12'>
                <label>{t("Content")}</label>
                <div className='border rounded-5 p-3 m-1'> <Editor
                    editorState={content}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={ContentChangeHandler}
                />
                    <span>{contentError}</span>
                </div>
                {/* <FormTextArea
                    label='Content'
                    placeholder='Content'
                    errorText={contentError}
                    value={content.toString()}
                    // onChange={ContentChangeHandler}
                    containerClass='mb-4'

                /> */}
            </div>
            <div className='col-12'>
                <FormInput
                    type='text'
                    label={t('Author')}
                    placeholder={t('Author')}
                    errorText={authorError}
                    value={author}
                    onChange={AuthorChangeHandler}
                    containerClass='mb-4'
                />
            </div>

        </div>
    </ModalWithHeader>
}

export default BlogForm