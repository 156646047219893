/* eslint-disable no-throw-literal */
import { Dispatch } from 'redux'
import { api, apiErrorHandler, getApiRequestConfig, Response } from '../../api'
import { GetState } from '../reducers'
import { AuthDivision } from '../reducers/authDivision.reducer'
import { Division } from '../reducers/division.reducer'

export enum DivisionActionTypes {
    LOGIN_DIVISION = 'LOGIN_DIVISION',
    LOGOUT_DIVISION = 'LOGOUT_DIVISION',
    ADD_DIVISION = 'ADD_DIVISION',
    UPDATE_DIVISION = 'UPDATE_DIVISION',
    FETCH_DIVISIONS = 'FETCH_DIVISIONS',
    DELETE_DIVISION = 'DELETE_DIVISION'
}

export interface LoginDivisionAction {
    type: DivisionActionTypes.LOGIN_DIVISION
    data: AuthDivision
}

export interface LogoutDivisionAction {
    type: DivisionActionTypes.LOGOUT_DIVISION
}

export interface AddDivisionAction {
    type: DivisionActionTypes.ADD_DIVISION
    data: Division
}

export interface UpdateDivisionAction {
    type: DivisionActionTypes.UPDATE_DIVISION
    data: Division
}

export interface FetchShopDivisionsAction {
    type: DivisionActionTypes.FETCH_DIVISIONS
    data: Division[]
}

export interface DeleteDivision {
    id: string
}

export interface DeleteDivisionAction {
    type: DivisionActionTypes.DELETE_DIVISION
    data: string
}


export type DivisionActions = LoginDivisionAction | LogoutDivisionAction | AddDivisionAction | FetchShopDivisionsAction | UpdateDivisionAction | DeleteDivisionAction

export interface RegisterDivision {
    name: string
    userName: string
    email: string
    password: string
    phoneNumber: string
    addressLine1: string
    addressLine2: string
    city: string
    pincode: number
    state: string
}

// export const registerDivision = (input: RegisterDivision) => async () => {
//     return api.post<Response<Division>>('division', input).then(response => {
//         if (response.status === 200) {
//             return Promise.resolve<string>(response.data.message || 'Registered')
//         } else {
//             throw { response }
//         }
//     }).catch((error) => {
//         return Promise.reject<string>(apiErrorHandler(error, 'Failed to register'))
//     })
// }

export interface LoginAuthDivision {
    email: string
    password: string
}

export const loginDivision = (input: LoginAuthDivision) => async (dispatch: Dispatch) => {
    return api.post<Response<AuthDivision>>('division/login', input).then(response => {
        if (response.status === 200) {

            dispatch<LoginDivisionAction>({
                type: DivisionActionTypes.LOGIN_DIVISION,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || 'Logged in')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to login'))
    })
}

export const logoutDivision = (): LogoutDivisionAction => {
    return {
        type: DivisionActionTypes.LOGOUT_DIVISION
    }
}

interface AddDivision {
    name: string
    userName: string
    email: string
    phoneNumber: string
    password: string
    addressLine1: string
    addressLine2: string
    city: string
    pincode: string
    state: string
}

export const addDivision = (input: AddDivision) => async (dispatch: Dispatch, getState: GetState) => {
    const authSuperAdmin = getState().authSuperAdmin
    const config = getApiRequestConfig(authSuperAdmin?.['auth-token'])

    return api.post<Response<Division>>('division', input, config).then(response => {
        if (response.status === 200) {
            dispatch<AddDivisionAction>({
                type: DivisionActionTypes.ADD_DIVISION,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || 'Added')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>((error.response.data.message + ", " + error.response.data.error) || "Unable to Add")
    })
}

export interface UpdateDivision {
    name: string
    userName: string
    email: string
    phoneNumber: string
    addressLine1: string
    addressLine2: string
    city: string
    pincode: string
    state: string
}

export const updateDivision = (input: UpdateDivision, divisionId: string) => async (dispatch: Dispatch, getState: GetState) => {
    const authSuperAdmin = getState().authSuperAdmin
    const config = getApiRequestConfig(authSuperAdmin?.['auth-token'])

    return api.put<Response<Division>>(`division/${divisionId}`, input, config).then(response => {
        if (response.status === 200) {
            dispatch<UpdateDivisionAction>({
                type: DivisionActionTypes.UPDATE_DIVISION,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || 'Updated')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to updated'))
    })
}

export const fetchDivisions = () => async (dispatch: Dispatch, getState: GetState) => {
    const authSuperAdmin = getState().authSuperAdmin
    const config = getApiRequestConfig(authSuperAdmin?.['auth-token'])

    return api.get<Response<Division[]>>('division', config).then(response => {
        if (response.status === 200) {
            dispatch<FetchShopDivisionsAction>({
                type: DivisionActionTypes.FETCH_DIVISIONS,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || '')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to fetch'))
    })
}

export const deleteDivision = (divisionId: string) => async (dispatch: Dispatch, getState: GetState) => {
    const authSuperAdmin = getState().authSuperAdmin
    const config = getApiRequestConfig(authSuperAdmin?.['auth-token'])

    return api.delete(`division/${divisionId}`, config).then(response => {
        if (response.status === 200) {
            dispatch<DeleteDivisionAction>({
                type: DivisionActionTypes.DELETE_DIVISION,
                data: divisionId
            })
            return Promise.resolve<string>(response.data.message || 'Delete Division')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to delete'))
    })
}
