const ListPlaceholder = () => {
    return <div className='placeholder-glow'>
        <div className='vstack gap-2'>
            <div className='placeholder placeholder-sm' style={{ width: '100%' }} />
            <div className='placeholder placeholder-sm' style={{ width: '50%' }} />
            <div className='placeholder placeholder-sm' style={{ width: '80%' }} />
            <div className='placeholder placeholder-sm' style={{ width: '60%' }} />
            <div className='placeholder placeholder-sm' style={{ width: '70%' }} />
            <div className='placeholder placeholder-sm' style={{ width: '40%' }} />
        </div>
    </div>
}

export default ListPlaceholder