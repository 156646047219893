import { Dispatch } from 'redux'
import { api, apiErrorHandler, getApiRequestConfig, Response } from '../../api'
import { GetState } from '../reducers'
import { ContactUs } from '../reducers/contactUs.reducer'

export enum ContactUsActionTypes {
    ADD_CONTACTUS = 'ADD_CONTACTUS',
    UPDATE_CONTACTUS = 'UPDATE_CONTACTUS',
    FETCH_CONTACTUS = 'FETCH_CONTACTUS',
    DELETE_CONTACTUS = 'DELETE_CONTACTUS'
}

export interface AddContactUsAction {
    type: ContactUsActionTypes.ADD_CONTACTUS
    data: ContactUs
}

export interface UpdateContactUsAction {
    type: ContactUsActionTypes.UPDATE_CONTACTUS
    data: ContactUs
}

export interface FetchContactUsAction {
    type: ContactUsActionTypes.FETCH_CONTACTUS
    data: ContactUs[]
}

export interface DeleteContactUs {
    id: string
}

export interface DeleteContactUsAction {
    type: ContactUsActionTypes.DELETE_CONTACTUS
    data: string
}

export type ContactUsActions = AddContactUsAction | FetchContactUsAction | UpdateContactUsAction|DeleteContactUsAction

interface AddContactUs {
    name: string
    phoneNumber: string
    email: string
    message: string
}

export const addContactUs = (input: AddContactUs) => async (dispatch: Dispatch, getState: GetState) => {

    return api.post<Response<ContactUs>>('contactUs', input).then(response => {
        if (response.status === 200) {
            dispatch<AddContactUsAction>({
                type: ContactUsActionTypes.ADD_CONTACTUS,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || 'Added')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to add'))
    })
}

export const fetchContactus = () => async (dispatch: Dispatch, getState: GetState) => {
    const authSuperAdmin = getState().authSuperAdmin
    const config = getApiRequestConfig(authSuperAdmin?.['auth-token'])

    return api.get<Response<ContactUs[]>>('contactUs', config).then(response => {
        if (response.status === 200) {
            dispatch<FetchContactUsAction>({
                type: ContactUsActionTypes.FETCH_CONTACTUS,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || '')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to fetch'))
    })
}

