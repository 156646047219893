import Card from '../../components/cards/Card'
import SuperAdminHomeCard from '../../components/cards/SuperAdminHomeCard'
import AdminPanelHeader from '../../components/sections/AdminPanelHeader'
import SuperAdminLayout from '../../components/sections/SuperAdminLayout'
import { useT } from '../../i18n'

const Home = () => {
    const t = useT()
    return <SuperAdminLayout>
        <AdminPanelHeader title={t('WELCOME ADMIN!')} />
        <SuperAdminHomeCard/>
    </SuperAdminLayout>
}

export default Home