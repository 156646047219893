import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useT } from '../../i18n'
import { StoreState } from '../../redux/reducers'
import { Shop } from '../../redux/reducers/shop.reducer'
import style from '../../styles/components/checkout-delivery-charge.module.scss'
import { toCurrencyFormat } from '../../utils'
import SectionWithTitle from './SectionWithTitle'

const CheckoutDeliveryCharge = () => {
    const t = useT()
    const cartShop = useSelector<StoreState, Shop | null>(state => state.shops.find(s => s.id === state.cart.shopId) || null)

    return <React.Fragment>
        <SectionWithTitle
            title={t("Delivery Charge")}
            className='mb-4'
            titleClassName='text-end'>
            <div className={style.description}>
                {/* {t("Delivery Charge")}: */}
                {t("From")} {cartShop?.delivery.distance.from} to {cartShop?.delivery.distance.to} kms, {t("The fixed delivery charge is")} {toCurrencyFormat(cartShop?.delivery.charge.upToGivenDistance, cartShop?.currency)}.
                <br />
                {t("Free delivery for orders of")}{toCurrencyFormat(cartShop?.delivery.orderValueForFreeDelivery.upToGivenDistance, cartShop?.currency)}.
                <br />
                <br />
                {t("If above")} {cartShop?.delivery.distance.to} kms, {t("For every kilometer the delivery charge is")} {toCurrencyFormat(cartShop?.delivery.charge.forEveryKmAboveGivenDistance, cartShop?.currency)}/km.
                <br />
                {t("Free delivery for orders of")} {toCurrencyFormat(cartShop?.delivery.orderValueForFreeDelivery.aboveGivenDistance, cartShop?.currency)}.
            </div>
        </SectionWithTitle>

        <div className='mb-4 text-end'>
            <Link to='#' className={style.link}>{t("Terms and conditions")}</Link>
        </div>
        <div className='mb-4 text-end'>
            <Link to='#' className={style.link}>{t("Privacy policy")}</Link>
        </div>
    </React.Fragment>
}

export default CheckoutDeliveryCharge