import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useT } from '../../i18n'
import { addDivision, updateDivision } from '../../redux/actions/division.actions'
import { StoreState } from '../../redux/reducers'
import { Division } from '../../redux/reducers/division.reducer'
import { AppDispatch } from '../../redux/store'
import { testNumber, toString } from '../../utils'
import { ToastType, useToast } from '../ToastProvider'
import ModalWithHeader from '../modals/ModalWithHeader'
import Eye from '../svgs/Eye'
import EyeOff from '../svgs/EyeOff'
import FormInput from '../utils/FormInput'

interface DivisionFormProps {
    onClose: () => void
    id?: string | null
}

const DivisionForm = (props: DivisionFormProps) => {
    const dispatch = useDispatch<AppDispatch>()
    const toast = useToast()
    const t = useT()

    const Division = useSelector<StoreState, Division | null>(state => props.id ? state.division.find(s => s.id === props.id) || null : null)

    const [name, setName] = React.useState<string>('')
    const [nameError, setNameError] = React.useState<string>('')
    const [username, setUsername] = React.useState<string>('')
    const [usernameError, setUsernameError] = React.useState<string>('')
    const [email, setEmail] = React.useState<string>('')
    const [emailError, setEmailError] = React.useState<string>('')
    const [password, setPassword] = React.useState<string>('')
    const [passwordError, setPasswordError] = React.useState<string>('')
    const [phoneNumber, setPhoneNumber] = React.useState<string>('')
    const [phoneNumberError, setPhoneNumberError] = React.useState<string>('')
    const [addressLine1, setAddressLine1] = React.useState<string>('')
    const [addressLine1Error, setAddressLine1Error] = React.useState<string>('')
    const [addressLine2, setAddressLine2] = React.useState<string>('')
    const [addressLine2Error, setAddressLine2Error] = React.useState<string>('')
    const [pincode, setPincode] = React.useState<string>('')
    const [pincodeError, setPincodeError] = React.useState<string>('')
    const [city, setCity] = React.useState<string>('')
    const [cityError, setCityError] = React.useState<string>('')
    const [state, setState] = React.useState<string>('')
    const [stateError, setStateError] = React.useState<string>('')

    const [showPassword, setShowPassword] = React.useState<boolean>(false)

    const [loading, setLoading] = React.useState<boolean>(false)

    const nameChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setName(e.target.value)
        setNameError('')
    }

    const usernameChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setUsername(e.target.value)
        setUsernameError('')
    }

    const emailChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setEmail(e.target.value)
        setEmailError('')
    }

    const passwordChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setPassword(e.target.value)
        setPasswordError('')
    }

    const phoneNumberChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setPhoneNumber(e.target.value)
        setPhoneNumberError('')
    }

    const addressLine1ChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setAddressLine1(e.target.value)
        setAddressLine1Error('')
    }

    const addressLine2ChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setAddressLine2(e.target.value)
        setAddressLine2Error('')
    }

    const pincodeChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        if ((!testNumber(e.target.value) && e.target.value))
            return
        else if (e.target.value.length >= 7)
            return

        setPincode(e.target.value)
        setPincodeError('')
    }

    const cityChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const value = e.target.value;
        const regex = /^[A-Za-z]*$/;

        if (regex.test(value)) {
            setCity(value);
            setCityError('');
        } else {
            setCityError('Please enter only alphabets');
        }
    }

    const stateChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const value = e.target.value;
        const regex = /^[A-Za-z]*$/;

        if (regex.test(value)) {
            setState(value);
            setStateError('');
        } else {
            setStateError('Please enter only alphabets');
        }
    }

    const submitHandler: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault()

        let error = false

        if (toString(name) === '') {
            setNameError(t('Name required'))
            error = true
        }

        if (toString(email) === '') {
            setEmailError(t('Email required'))
            error = true
        }

        if (toString(pincode) === '') {
            setPincodeError(t('Pincode required'))
            error = true
        }
        else if (!testNumber(pincode)) {
            setPincodeError(t('Pincode must be number'))
            error = true
        }

        if (toString(phoneNumber) === '') {
            setPhoneNumberError(t('Phone Number required'))
            error = true
        }
        else if (!testNumber(phoneNumber)) {
            setPhoneNumberError(t('Phone Number must be number'))
            error = true
        }

        if (toString(city) === '') {
            setCityError(t('City required'))
            error = true
        }

        if (toString(state) === '') {
            setStateError(t('State required'))
            error = true
        }

        if (toString(addressLine1) === '') {
            setAddressLine1Error(t('Address Line 1 required'))
            error = true
        }

        if (toString(addressLine2) === '') {
            setAddressLine2Error(t('Address Line 2 required'))
            error = true
        }

        if (toString(username) === '') {
            setUsernameError(t('Username required'))
            error = true
        }
        if (!props.id) {
            if (password === '') {
                setPasswordError(t('Password required'))
                error = true
            } else if (password.length < 8) {
                setPasswordError(t('Password is too short - should be 8 chars minimum'))
                error = true
            }
        }

        // else if (password.length > 15) {
        //     setPasswordError('Password is too long - should be 15 chars maximum')
        //     error = true
        // } else if (!testPassword(password)) {
        //     setPasswordError('Password can only contain alphanumeric characters, underscore and first character must be a letter')
        //     error = true
        // }

        if (!error) {
            if (props.id !== "" && Division) {
                setLoading(true)
                dispatch(updateDivision({
                    city: toString(city),
                    state: toString(state),
                    email: toString(email).toLowerCase(),
                    name: toString(name),
                    phoneNumber: toString(phoneNumber),
                    userName: toString(username).toLowerCase(),
                    addressLine1: toString(addressLine1),
                    addressLine2: toString(addressLine2),
                    pincode: toString(pincode),
                }, Division.id)).then(() => {
                    toast(t('Division updated'))
                    props?.onClose()
                }).catch(text => {
                    toast(text, ToastType.ERROR)
                }).finally(() => {
                    setLoading(false)
                })
            }
            else {
                setLoading(true)
                dispatch(addDivision({
                    city: toString(city),
                    state: toString(state),
                    email: toString(email).toLowerCase(),
                    name: toString(name),
                    phoneNumber: toString(phoneNumber),
                    password: password,
                    userName: toString(username).toLowerCase(),
                    addressLine1: toString(addressLine1),
                    addressLine2: toString(addressLine2),
                    pincode: toString(pincode),
                })).then(() => {
                    toast(t('Division added'))
                    props?.onClose()
                }).catch(text => {
                    toast(text, ToastType.ERROR)
                }).finally(() => {
                    setLoading(false)
                })
            }
        }
    }

    useEffect(() => {
        if (Division && props.id !== '') {
            setName(Division.name)
            setUsername(Division.userName)
            setEmail(Division.email)
            setPhoneNumber(Division.phoneNumber)
            setAddressLine1(Division.address.addressLine1)
            setAddressLine2(Division.address.addressLine2)
            setCity(Division.address.city)
            setState(Division.address.state)
            setPincode(Division.address.pincode.toString())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Division])

    return <ModalWithHeader
        buttonText='Save'
        headerText={props.id ? t('Edit Division') : t('Add Division')}
        loading={loading}
        onClose={props?.onClose}
        onSave={submitHandler}
    >
        <div className='row'>
            <div className='col-md-6 col-lg-6'>
                <FormInput
                    type='text'
                    label={t('Name')}
                    placeholder={t('Name')}
                    errorText={nameError}
                    value={name}
                    onChange={nameChangeHandler}
                    containerClass='mb-4'
                />
            </div>
            <div className='col-md-6 col-lg-6'>
                <FormInput
                    type='email'
                    label={t('Email')}
                    placeholder={t('Email')}
                    errorText={emailError}
                    value={email}
                    onChange={emailChangeHandler}
                    containerClass='mb-4'
                />
            </div>
            <div className='col-md-6 col-lg-6'>
                <FormInput
                    type='text'
                    label={t('Username')}
                    placeholder={t('Username')}
                    errorText={usernameError}
                    value={username}
                    onChange={usernameChangeHandler}
                    containerClass='mb-4'
                />
            </div>
            {!props.id && <div className='col-md-6 col-lg-6'>
                <FormInput
                    type={showPassword ? 'text' : 'password'}
                    label={t('Password')}
                    placeholder={t('Password')}
                    errorText={passwordError}
                    value={password}
                    onChange={passwordChangeHandler}
                    containerClass='mb-4'
                    rightRenderer={showPassword ? <EyeOff /> : <Eye />}
                    onRightRendererClick={() => setShowPassword(!showPassword)}
                />
            </div>}

            <div className='col-md-6 col-lg-6'>
                <FormInput
                    type='text'
                    label={t('Phone Number')}
                    placeholder={t('Phone Number')}
                    errorText={phoneNumberError}
                    value={phoneNumber}
                    onChange={phoneNumberChangeHandler}
                    containerClass='mb-4'
                />
            </div>

            <div className='col-md-6 col-lg-6'>
                <FormInput
                    type='text'
                    label={t('Address Line 1')}
                    placeholder={t('Address Line 1')}
                    errorText={addressLine1Error}
                    value={addressLine1}
                    onChange={addressLine1ChangeHandler}
                    containerClass='mb-4'
                />
            </div>
            <div className='col-md-6 col-lg-6'>
                <FormInput
                    type='text'
                    label={t('Address Line 2')}
                    placeholder={t('Address Line 2')}
                    errorText={addressLine2Error}
                    value={addressLine2}
                    onChange={addressLine2ChangeHandler}
                    containerClass='mb-4'
                />
            </div>
            <div className='col-md-6 col-lg-6'>
                <FormInput
                    type='text'
                    label={t('City')}
                    placeholder={t('City')}
                    errorText={cityError}
                    value={city}
                    onChange={cityChangeHandler}
                    containerClass='mb-4'
                />
            </div>
            <div className='col-md-6 col-lg-6'>
                <FormInput
                    type='text'
                    label={t('State')}
                    placeholder={t('State')}
                    errorText={stateError}
                    value={state}
                    onChange={stateChangeHandler}
                    containerClass='mb-4'
                />
            </div>
            <div className='col-md-6 col-lg-6'>
                <FormInput
                    type='text'
                    label={t('Pincode')}
                    placeholder={t('Pincode')}
                    errorText={pincodeError}
                    value={pincode}
                    onChange={pincodeChangeHandler}
                    containerClass='mb-4'
                />
            </div>
            <div>
            </div>
        </div>
    </ModalWithHeader>
}

export default DivisionForm