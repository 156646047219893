import { Action, AuthSuperAdminActionTypes } from '../actions'

export interface AuthSuperAdmin {
    'auth-token': string
    name: string
    email: string
    username: string
    id: string
}

const initialState: AuthSuperAdmin | null = null

const authSuperAdminReducer = (state: AuthSuperAdmin | null = initialState, action: Action): AuthSuperAdmin | null => {
    switch (action.type) {
        case AuthSuperAdminActionTypes.LOGIN_SUPER_ADMIN: {
            return action.data
        }

        case AuthSuperAdminActionTypes.LOGOUT_SUPER_ADMIN: {
            return null
        }

        default: {
            return state
        }
    }
}

export default authSuperAdminReducer