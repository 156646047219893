import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useT } from '../../i18n'
import { updateDeliveryTiming, updateShopTiming } from '../../redux/actions'
import { StoreState } from '../../redux/reducers'
import { AuthAdmin } from '../../redux/reducers/authAdmin.reducer'
import { OpeningAndClosingTime, Timing as TimingStruct } from '../../redux/reducers/shop.reducer'
import { AppDispatch } from '../../redux/store'
import formStyle from '../../styles/components/form.module.scss'
import style from '../../styles/components/timing.module.scss'
import { timingDeepCopy } from '../../utils'
import { ToastType, useToast } from '../ToastProvider'
import Button from '../utils/Button'

export type TimingType = 'shop' | 'delivery'

interface TimingProps {
    timing?: TimingStruct | null
    startTimingText?: string
    endTimingText?: string
    type?: TimingType
}

const Timing = (props: TimingProps) => {
    const dispatch = useDispatch<AppDispatch>()
    const toast = useToast()
    const t = useT()

    const authAdmin = useSelector<StoreState, AuthAdmin | null>(state => state.authAdmin)

    const [timing, setTiming] = React.useState<TimingStruct | null>(null)
    const [loading, setLoading] = React.useState<boolean>(false)

    const disabled = props.type === 'delivery' && !authAdmin?.shop.delivery.enabled

    const timingChangeHandler = (day: keyof TimingStruct, openingOrClosing: keyof OpeningAndClosingTime, value: string) => {
        setTiming(prev => {
            if (prev) {
                const newState = timingDeepCopy(prev)
                newState[day][openingOrClosing] = value
                return newState
            }

            return null
        })
    }

    const saveClickHandler = () => {
        if (timing && !disabled) {
            if (props.type === 'shop') {
                setLoading(true)
                dispatch(updateShopTiming(timing)).then(() => {
                    toast(t('Timing updated'))
                }).catch(text => {
                    toast(text, ToastType.ERROR)
                }).finally(() => {
                    setLoading(false)
                })
            } else if (props.type === 'delivery') {
                setLoading(true)
                dispatch(updateDeliveryTiming(timing)).then(() => {
                    toast(t('Timing updated'))
                }).catch(text => {
                    toast(text, ToastType.ERROR)
                }).finally(() => {
                    setLoading(false)
                })
            }
        }
    }

    React.useEffect(() => {
        if (props.timing) {
            setTiming(timingDeepCopy(props.timing))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <div className='col-12'>
        <table className='table table-bordered'>
            <thead>
                <tr>
                    <th>{t("Day")}</th>
                    <th>{props.startTimingText}</th>
                    <th>{props.endTimingText}</th>
                </tr>
            </thead>
            <tbody>
                {timing && Object.keys(timing).map(k => {
                    const key = k as keyof TimingStruct
                    return <TimeCell
                        opening={timing?.[key].opening}
                        closing={timing?.[key].closing}
                        day={key}
                        key={key}
                        onOpeningChange={e => timingChangeHandler(key, 'opening', e.target.value)}
                        onClosingChange={e => timingChangeHandler(key, 'closing', e.target.value)}
                        type={props.type}
                    />
                })}
            </tbody>
        </table>
        <div className='mb-4'>
            <Button className={formStyle.saveButton} onClick={saveClickHandler} loading={loading} disabled={disabled}>{t("Save")}</Button>
        </div>
    </div>
}

interface TimeCellProps {
    day?: string
    opening?: string
    closing?: string
    onOpeningChange?: React.ChangeEventHandler<HTMLInputElement>
    onClosingChange?: React.ChangeEventHandler<HTMLInputElement>
    type?: TimingType
}

const TimeCell = (props: TimeCellProps) => {
    const authAdmin = useSelector<StoreState, AuthAdmin | null>(state => state.authAdmin)
    const disabled = props.type === 'delivery' && !authAdmin?.shop.delivery.enabled

    return <tr>
        <th className='text-capitalize'>{props.day}</th>
        <td className='p-0 m-0'>
            <input type='time' className={style.timeInput} value={props.opening} onChange={props.onOpeningChange} disabled={disabled} />
        </td>
        <td className='p-0 m-0'>
            <input type='time' className={style.timeInput} value={props.closing} onChange={props.onClosingChange} disabled={disabled} />
        </td>
    </tr>
}

export default Timing