import React from 'react'
import buttonStyle from '../../styles/components/button.module.scss'
import Spinner from './Spinner'

interface ButtonProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    loading?: boolean
    disabled?: boolean
    size?: 'sm' | 'lg'
}

const Button = ({ className, loading, disabled, size, ...props }: ButtonProps) => {
    const buttonSize = size === 'lg' ? buttonStyle.large : size === 'sm' ? buttonStyle.small : ''

    return loading || disabled
        ? <button className={`${buttonStyle.button} ${buttonSize} ${className || ''}`} disabled={disabled}>
            {loading ? <Spinner /> : props.children}
        </button>
        : <button className={`${buttonStyle.button} ${buttonSize} ${className || ''}`} {...props} />
}

export default Button