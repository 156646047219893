import React, { useEffect, useState } from 'react'
import { Carousel, Col, Row } from "react-bootstrap"
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { UserContext, UserContextType } from '../../context/UserProvider'
import { useT } from '../../i18n'
import { addToCart, updateCartItem } from '../../redux/actions'
import { StoreState } from '../../redux/reducers'
import { Cart, CartItem, CartItemExtraTopping } from '../../redux/reducers/cart.reducer'
import { ExtraTopping } from '../../redux/reducers/extraTopping.reducer'
import { Food, FoodPrice } from '../../redux/reducers/food.reducer'
import { Shop } from '../../redux/reducers/shop.reducer'
import { AppDispatch } from '../../redux/store'
import style from '../../styles/components/add-to-cart.module.scss'
import formStyle from '../../styles/components/form.module.scss'
import { getFoodPrice, getOfferPrice, getPrice, toCurrencyFormat, toNumber, toString } from '../../utils'
import { useToast } from '../ToastProvider'
import Veg from '../svgs/Veg'
import Button from '../utils/Button'
import Checkbox from '../utils/Checkbox'
import Close from '../utils/Close'
import Radio from '../utils/Radio'
import TextArea from '../utils/TextArea'
import Modal from './Modal'


interface AddToCartProps {
    onClose?: () => void
    cartId: string | null
    food: Food
}

const AddToCart = (props: AddToCartProps) => {
    const t = useT()
    const { shopId } = useParams()
    const userProvider = React.useContext<UserContextType | null>(UserContext)
    const shop = useSelector<StoreState, Shop | null>(state => state.shops.find(s => s.id === shopId) || null)

    const dispatch = useDispatch<AppDispatch>()
    const toast = useToast()

    const cartItem = useSelector<StoreState, CartItem | null>(state => state.cart.items.find(c => c.id === props.cartId) || null)

    const cart = useSelector<StoreState, Cart>(state => state.cart)


    const [foodPrices, setPrices] = React.useState<FoodPrice[] | null>(null)
    const [selectedPrice, setSelectedPrice] = React.useState<FoodPrice | null>(null)
    const [foodPrice, setFoodPrice] = React.useState<number | null>(null)
    const [comment, setComment] = React.useState<string>('')
    const [selectedExtraToppings, setSelectedExtraToppings] = React.useState<ExtraTopping[]>([])
    const [selectedSpices, setSelectedSpices] = React.useState<string[]>([])
    const [selectedAlternateToppings, setSelectedAlternateToppings] = React.useState<string[]>([])

    const [actualImage, setActualImage] = useState<string>(props.food.image);
    const [showFullDescription, setShowFullDescription] = useState<boolean>(false);

    const descriptionLength = (props.food.descriptions[0].description ? props.food.descriptions[0].description.length : 0)

    const [width, setWidth] = useState(window.innerWidth);
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const sizeClickHandler = (foodPrice: FoodPrice) => {
        setSelectedPrice(foodPrice)
        setSelectedExtraToppings([])
    }

    const toppingClickHandler = (toppingId: string) => {
        setSelectedAlternateToppings(prev => {
            return selectedAlternateToppings.some(id => id === toppingId)
                ? props.food.alternateToppingsCount
                    ? prev.filter(p => p !== toppingId).slice(0, props.food.alternateToppingsCount)
                    : prev
                : props.food.alternateToppingsCount
                    ? [
                        toppingId,
                        ...prev
                    ].slice(0, props.food.alternateToppingsCount)
                    : [
                        toppingId,
                        ...prev
                    ]
        })
    }

    const extraToppingClickHandler = (extraTopping: ExtraTopping) => {
        setSelectedExtraToppings(prev => {
            return selectedExtraToppings.some(e => e.id === extraTopping.id)
                ? prev.filter(p => p.id !== extraTopping.id)
                : [
                    ...prev,
                    extraTopping
                ]
        })
    }

    const spiceClickHandler = (spiceId: string) => {
        setSelectedSpices(prev => {
            return selectedSpices.some(id => id === spiceId)
                ? prev.filter(p => p !== spiceId)
                : [
                    ...prev,
                    spiceId
                ]
        })
    }

    const addToCartClickHandler = async () => {
        let obj = cart.items.find(c => c.foodId === props.food.id)
        // if (obj) {
        if (props.food.type === 'detail' && props.food.status === 'active' && selectedPrice) {
            const index = props.food.prices.findIndex(p => p.size === selectedPrice.size) ?? -1
            const price = getFoodPrice(props.food, index)

            const extraPrice = selectedExtraToppings.reduce((p, c) => {
                return index > -1 && c.prices[index] ? c.prices[index].price + p : p
            }, 0)

            const extraToppings = selectedExtraToppings.map<CartItemExtraTopping>(extraTopping => ({
                id: extraTopping.id,
                price: extraTopping.prices[index]?.price || 0
            }))

            if (props.cartId && cartItem) {
                dispatch(updateCartItem({
                    comment: toString(comment),
                    extraToppings: extraToppings,
                    foodId: props.food.id,
                    image: props.food.image,
                    names: props.food.names.map(n => ({ language: toString(n.language), name: toString(n.name) })),
                    amount: toNumber(price + extraPrice),
                    size: selectedPrice.size,
                    spices: selectedSpices,
                    tax: props.food.tax,
                    stock: props.food.stock,
                    alternateToppings: selectedAlternateToppings,
                    status: props.food.status === 'active' ? 'available' : 'unavailable',
                    category: props.food.categories[0].id
                }, props.cartId))

                toast(t('Cart item updated'))
                props.onClose?.()
            } else {
                dispatch(addToCart({
                    comment: toString(comment),
                    extraToppings: extraToppings,
                    foodId: props.food.id,
                    image: props.food.image,
                    names: props.food.names.map(n => ({ language: toString(n.language), name: toString(n.name) })),
                    amount: toNumber(price + extraPrice),
                    size: selectedPrice.size,
                    spices: selectedSpices,
                    tax: props.food.tax,
                    stock: props.food.stock,
                    alternateToppings: selectedAlternateToppings,
                    status: props.food.status === 'active' ? 'available' : 'unavailable',
                    category: props.food.categories[0].id
                }, props.food.shopId))

                userProvider?.showAddedToCartModal?.(props.food.names[0].name)
                props.onClose?.()
            }
        } else if (props.food.type === 'simple' && props.food.status === 'active') {
            const price = getPrice(props.food)

            if (props.cartId && cartItem) {
                dispatch(updateCartItem({
                    comment: toString(comment),
                    extraToppings: [],
                    foodId: props.food.id,
                    image: props.food.image,
                    names: props.food.names.map(n => ({ language: toString(n.language), name: toString(n.name) })),
                    amount: toNumber(price),
                    size: null,
                    spices: [],
                    stock: props.food.stock,
                    tax: props.food.tax,
                    alternateToppings: [],
                    status: props.food.status === 'active' ? 'available' : 'unavailable',
                    category: props.food.categories[0].id
                }, props.cartId))

                toast(t('Cart item updated'))
                props.onClose?.()
            } else {
                dispatch(addToCart({
                    comment: toString(comment),
                    extraToppings: [],
                    foodId: props.food.id,
                    image: props.food.image,
                    names: props.food.names.map(n => ({ language: toString(n.language), name: toString(n.name) })),
                    amount: toNumber(price),
                    size: null,
                    spices: [],
                    stock: props.food.stock,
                    tax: props.food.tax,
                    alternateToppings: [],
                    status: props.food.status === 'active' ? 'available' : 'unavailable',
                    category: props.food.categories[0].id
                }, props.food.shopId))

                userProvider?.showAddedToCartModal?.(props.food.names[0].name)
                props.onClose?.()
            }
        } else if (props.food.type === 'custom' && props.food.status === 'active') {
            const price = getPrice(props.food)

            if (props.cartId && cartItem) {
                dispatch(updateCartItem({
                    comment: toString(comment),
                    extraToppings: [],
                    foodId: props.food.id,
                    image: props.food.image,
                    names: props.food.names.map(n => ({ language: toString(n.language), name: toString(n.name) })),
                    amount: toNumber(0),
                    size: null,
                    spices: [],
                    stock: props.food.stock,
                    tax: props.food.tax,
                    alternateToppings: [],
                    status: props.food.status === 'active' ? 'available' : 'unavailable',
                    category: props.food.categories[0].id
                }, props.cartId))

                toast(t('Cart item updated'))
                props.onClose?.()
            } else {
                dispatch(addToCart({
                    comment: toString(comment),
                    extraToppings: [],
                    foodId: props.food.id,
                    image: props.food.image,
                    names: props.food.names.map(n => ({ language: toString(n.language), name: toString(n.name) })),
                    amount: toNumber(0),
                    size: null,
                    spices: [],
                    stock: props.food.stock,
                    tax: props.food.tax,
                    alternateToppings: [],
                    status: props.food.status === 'active' ? 'available' : 'unavailable',
                    category: props.food.categories[0].id
                }, props.food.shopId))

                userProvider?.showAddedToCartModal?.(props.food.names[0].name)
                props.onClose?.()
            }
        }
        // }
        //  else { toast(t("Item already exists in the cart"), ToastType.ERROR) }
    }

    React.useEffect(() => {
        if (props.cartId && cartItem) {
            const extraToppings = props.food.extraToppings.filter(et => cartItem.extraToppings.some(cet => cet.id === et.id))
            const spiceIds = props.food.spices.filter(s => cartItem.spices.some(sid => sid === s.id)).map(s => s.id)
            const alternateToppingsIds = props.food.alternateToppings.filter(t => cartItem.alternateToppings.some(atid => atid === t.id)).map(t => t.id)
            const priceIndex = props.food.prices.findIndex(p => p.size === cartItem.size) ?? -1
            const selectedPrice = cartItem.size && props.food.type === 'detail' && priceIndex > -1 ? props.food.prices?.[priceIndex] : null
            setSelectedPrice(selectedPrice || null)
            setComment(cartItem.comment)
            setSelectedExtraToppings(extraToppings)
            setSelectedSpices(spiceIds)

            setSelectedAlternateToppings(alternateToppingsIds)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cartItem])

    React.useEffect(() => {
        if (props.food.type === 'detail' && !cartItem) {
            const prices = [...props.food.prices]
            prices.sort((a, b) => a.price - b.price)

            setPrices(prices)
            setSelectedPrice(prices[0])
        }
        else {
            const prices = [...props.food.prices]
            prices.sort((a, b) => a.price - b.price)
            const priceIndex = props.food.prices.findIndex(p => p.size === cartItem?.size) ?? -1
            const selectedPrice = cartItem?.size && props.food.type === 'detail' && priceIndex > -1 ? props.food.prices?.[priceIndex] : null
            setPrices(prices)
            setSelectedPrice(selectedPrice)
        }
    }, [props.food.prices, props.food.type, cartItem])

    React.useEffect(() => {
        if (props.food.type === 'detail') {
            const price = (selectedPrice?.offerPrice ? selectedPrice?.offerPrice : selectedPrice?.price) || 0
            const extraToppingsPrice = selectedExtraToppings.reduce((p, c) => p + (c.prices?.find(p => p.size === selectedPrice?.size)?.price || 0), 0)
            setFoodPrice(price + extraToppingsPrice)
        } else {
            setFoodPrice(getFoodPrice(props.food))
        }
    }, [props.food, selectedExtraToppings, selectedPrice])

    return <><Modal animate>

        <div className={style.container}>
            <div className={style.container}>

                <div className='px-4 pt-4'>
                    <div className='d-flex align-items-center justify-content-between mb-2'>

                        <div className={style.headerText}>{props.cartId && cartItem ? t('Edit order') : t('Add to cart')}</div>
                        <Close onClose={props.onClose} />

                    </div>

                    <div className='row mb-2'>
                        <div className='col-lg-6 col-sm-12 col-xs-12 col-md-6'>
                            <Row>
                                {width > 500 &&
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        {props.food.additionalImages.length !== 0 ?

                                            <Row>
                                                <Col lg={3} md={3} sm={3} xs={3}>
                                                    <div className='overflow-auto' style={{ height: "200px" }}>
                                                        <p>
                                                            <img
                                                                src={props.food.image}
                                                                style={{ cursor: "pointer" }}
                                                                onMouseOver={() => setActualImage(props.food.image)}
                                                                alt=""
                                                                width="70px" height="70px" className="img-thumbnail"
                                                            /></p>
                                                        {props.food.additionalImages.map((additionalimage, index) => (
                                                            <p key={index}>
                                                                <img
                                                                    src={additionalimage !== undefined ? additionalimage : ""}
                                                                    onMouseOver={() => setActualImage(additionalimage)}
                                                                    style={{ cursor: "pointer" }}
                                                                    alt="" width="70px" height="70px" className="img-thumbnail" />
                                                            </p>

                                                        ))}
                                                    </div>
                                                </Col>
                                                <Col lg={9} md={9} sm={9} xs={9}>


                                                    <img src={actualImage ? actualImage : ''} alt="icon" className={style.bigImage} />

                                                </Col>
                                            </Row>
                                            :
                                            <img src={props.food.image ? props.food.image : ''} alt="icon" className={style.editBigImage} />
                                        }

                                    </Col>
                                }
                                {width <= 500 &&
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        {props.food.additionalImages.length !== 0 ?
                                            <Carousel fade={true} slide={false}>

                                                <Carousel.Item key={0} interval={3000} >

                                                    <img
                                                        className="d-block w-100"
                                                        src={(props.food.image && props.food.image !== null) ? props.food.image : ''}
                                                        alt={`${0} slide`}
                                                    />

                                                    <Carousel.Caption />

                                                </Carousel.Item>

                                                {props.food.additionalImages.map((additionalImage, index) => (
                                                    <Carousel.Item key={index + 1} interval={3000} >

                                                        <img
                                                            className="d-block w-100"
                                                            src={(additionalImage && additionalImage !== null) ? additionalImage : ''}
                                                            alt={`${index + 1} slide`}
                                                        />

                                                        <Carousel.Caption />

                                                    </Carousel.Item>
                                                ))}

                                            </Carousel>
                                            :
                                            <img src={props.food.image ? props.food.image : ''} alt="icon" className={style.bigImage} />
                                        }

                                    </Col>
                                }
                            </Row>
                            {/* </div> */}
                        </div>
                        <div className='col-lg-6 col-sm-12 col-xs-12 col-md-6'>
                            <div className='hstack gap-2 mt-2'>
                                <div className={`text-capitalize ${style.productName}`}>{props.food.names[0].name}</div>
                                {shop?.category === "Food" && <Veg />}
                            </div>
                            <div className={style.price}>{toCurrencyFormat(foodPrice, shop?.currency)}</div>
                            {shop?.category === "Product" && <p className={style.stock} >{props.food.stock && props.food.stock <= 10 ? `Only ${props.food.stock} Left!! Hurry ` : !props.food.stock ? 'Stock not available' : ''} </p>}
                        </div>

                        <p className={`mt-3 ${style.editDescription} ${!showFullDescription ? style.less : ''}`} dangerouslySetInnerHTML={{ __html: props.food.descriptions[0].description || '' }}>
                        </p>
                        {descriptionLength > 150 && <span className='text-primary float-end cursor-pointer' style={{
                            fontSize: '14px',
                            fontWeight: '400',
                        }} role='button' onClick={() => setShowFullDescription(!showFullDescription)}>{showFullDescription ? t('Less') : t('More')}</span>}
                    </div>
                </div>

                {props.food.type === 'detail' && <React.Fragment>
                    <div className={`${style.editOrderHeaderText} px-4`}>{t("Edit order")}</div>
                    <div className={`${style.editOrderContent} px-4`}>
                        {props.food.prices.length > 0 && <div className='mb-4'>
                            <div className={formStyle.sectionHeader}>{t("Size")}:</div>
                            {foodPrices?.map((p) => {
                                const priceIndex = props.food.prices.findIndex(fp => fp.size === p.size)
                                const price = getPrice(props.food, priceIndex)
                                const offerPrice = getOfferPrice(props.food, priceIndex)
                                return <div className='hstack gap-2 justify-content-between'>
                                    <Radio
                                        className='mb-1'
                                        key={p.id}
                                        onClick={() => sizeClickHandler(p)}
                                        checked={p.size === selectedPrice?.size}
                                    >
                                        <span className='text-capitalize'>{p.size}</span>
                                    </Radio>
                                    <div className='hstack gap-2'>
                                        {offerPrice > 0
                                            ? <React.Fragment>
                                                <span>
                                                    {'('}
                                                    <span className={style.offerPrice}>{toCurrencyFormat(price, shop?.currency)}</span>
                                                </span>
                                                <span>
                                                    {toCurrencyFormat(offerPrice, shop?.currency)}{')'}
                                                </span>
                                            </React.Fragment>
                                            : <span>{'('}{toCurrencyFormat(price, shop?.currency)}{')'}</span>}
                                    </div>
                                </div>
                            })}
                        </div>}
                        {props.food.alternateToppings.length > 0 && props.food.alternateToppingsCount && props.food.alternateToppingsCount > 0 && <div className='mb-4'>
                            <div className={formStyle.sectionHeader}>{t("Toppings")}: {`(Select up to ${props.food.alternateToppingsCount})`}</div>
                            {props.food.alternateToppings.map(alternate => {
                                return <Checkbox
                                    className='mb-1'
                                    key={alternate.id}
                                    onClick={() => toppingClickHandler(alternate.id)}
                                    checked={selectedAlternateToppings.some(id => id === alternate.id)}
                                >{alternate.names[0].name}</Checkbox>
                            })}
                        </div>}
                        {props.food.extraToppings.length > 0 && props.food.extraToppings.some(e => e.prices.some(p => p.size === selectedPrice?.size)) && <div className='mb-4'>
                            <div className={formStyle.sectionHeader}>{t("Extra toppings")}:</div>
                            {props.food.extraToppings.map(extra => {
                                const price = extra.prices.find(e => e.size === selectedPrice?.size)

                                return <Checkbox
                                    className='mb-1'
                                    key={extra.id}
                                    onClick={() => extraToppingClickHandler(extra)}
                                    checked={selectedExtraToppings.some(e => e.id === extra.id)}
                                >{`${extra.names[0].name} (${toCurrencyFormat(price?.price, shop?.currency)})`}</Checkbox>
                            })}
                        </div>}
                        {props.food.spices.length > 0 && <div className='mb-4'>
                            <div className={formStyle.sectionHeader}>{t("Spices")}:</div>
                            {props.food.spices.map(spice => {
                                return <Checkbox
                                    className='mb-1'
                                    key={spice.id}
                                    onClick={() => spiceClickHandler(spice.id)}
                                    checked={selectedSpices.some(id => id === spice.id)}
                                >{spice.names[0].name}</Checkbox>
                            })}
                        </div>}
                        <div className={formStyle.sectionHeader}>{t("Comments")}:</div>
                        <TextArea
                            onChange={e => setComment(e.target.value)}
                            value={comment}
                        />
                    </div>
                </React.Fragment>}
            </div>

            <div className='px-4 pb-4 pt-0 mt-0'>
                {props.food.type === 'simple' && <React.Fragment>
                    <div className={formStyle.sectionHeader}>{t("Comments")}:</div>
                    <TextArea
                        onChange={e => setComment(e.target.value)}
                        value={comment}
                    />
                </React.Fragment>}
                {props.food.type === 'custom' && <React.Fragment>
                    <div className={formStyle.sectionHeader}>{t("Comments")}:</div>
                    <TextArea
                        onChange={e => setComment(e.target.value)}
                        value={comment}
                    />
                </React.Fragment>}
                <div className='mb-3' />
                {/* <Button className={formStyle.saveButton} onClick={addToCartClickHandler} disabled={shop?.category === "Product" && !props.food.stock ? true : false}>{props.cartId && cartItem ? t('Update') : t('Add to cart')}</Button> */}
                <Button className={formStyle.saveButton} onClick={addToCartClickHandler}>{props.cartId && cartItem ? t('Update') : t('Add to cart')}</Button>
            </div>
        </div>
    </Modal ></>
}

export default AddToCart