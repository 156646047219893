interface CastProps {
    stroke?: string
    strokeWidth?:string

}

const Cast = (props: CastProps) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={props.stroke?props.stroke:"#404040"} stroke-width={props.strokeWidth?props.strokeWidth:"1"} stroke-linecap="round" stroke-linejoin="round" className="feather feather-cast"><path d="M2 16.1A5 5 0 0 1 5.9 20M2 12.05A9 9 0 0 1 9.95 20M2 8V6a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2h-6"></path><line x1="2" y1="20" x2="2.01" y2="20"></line>
    </svg>
}

export default Cast