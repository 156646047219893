interface CoffeeProps {
    stroke?: string
    strokeWidth?: number
}

const Coffee = (props: CoffeeProps) => {
    return <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke={props.stroke || '#ffffff'} strokeWidth={props.strokeWidth || 2} strokeLinecap='round' strokeLinejoin='round' className='feather feather-coffee'>
        <path d='M18 8h1a4 4 0 0 1 0 8h-1'></path>
        <path d='M2 8h16v9a4 4 0 0 1-4 4H6a4 4 0 0 1-4-4V8z'></path>
        <line x1='6' y1='1' x2='6' y2='4'></line>
        <line x1='10' y1='1' x2='10' y2='4'></line>
        <line x1='14' y1='1' x2='14' y2='4'></line>
    </svg>
}

export default Coffee