import React from 'react'
import { useT } from '../../i18n'
import style from '../../styles/components/admin-layout.module.scss'
import AccountCircle from '../svgs/AccountCircle'
import Archive from '../svgs/Archive'
import Home from '../svgs/Home'
import Layers from '../svgs/Layers'
import MessageCircle from '../svgs/MessageCircle'
import Package from '../svgs/Package'
import Footer from './Footer'
import SideNavbar from './SideNavbar'
import SuperAdminHeader from './SuperAdminHeader'
interface SuperAdminLayoutProps {
    children?: React.ReactNode
}

const SuperAdminLayout = (props: SuperAdminLayoutProps) => {
    const t = useT()
    const [sideNavbarActive, setSideNavbarActive] = React.useState<boolean>(false)
    const [width, setWidth] = React.useState<number>(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    React.useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 760

    const navebarList =
        [
            {
                label: t('Home'),
                to: '/super',
                icon: <Home stroke='#404040' strokeWidth={1} />
            },
            {
                label: t('Divisions'),
                to: '/super/divisions',
                icon: <Layers stroke='#404040' strokeWidth={1} />
            },
            {
                label: t('shops'),
                to: '/super/shops',
                icon: <Archive stroke='#404040' strokeWidth={1} />
            },
            {
                label: t('Users'),
                to: '/super/users',
                icon: <AccountCircle stroke='#404040' strokeWidth={1} />
            },
            {
                label: t('Blogs'),
                to: '/super/blogs',
                icon: <Package stroke='#404040' strokeWidth={1} />
            },
            {
                label: t('Messages'),
                to: '/super/messages',
                icon: <MessageCircle stroke='#404040' strokeWidth={1} />
            }
        ]
    return <React.Fragment>
        <SuperAdminHeader
            onMenuClick={() => setSideNavbarActive(true)} />
        {/* <div className={style.container}>
            <div className='d-flex'>
                <SideNavbar
                    isActive={false}
                    navbarItemList={[
                        {
                            label: t('Home'),
                            to: '/super',
                            icon: <Home stroke='#404040' strokeWidth={1} />
                        },
                        {
                            label: t('Divisions'),
                            to: '/super/divisions',
                            icon: <Layers stroke='#404040' strokeWidth={1} />
                        },
                        {
                            label: t('shops'),
                            to: '/super/shops',
                            icon: <Archive stroke='#404040' strokeWidth={1} />
                        },
                        {
                            label: t('Users'),
                            to: '/super/users',
                            icon: <AccountCircle stroke='#404040' strokeWidth={1} />
                        },
                        {
                            label: t('Blogs'),
                            to: '/super/blogs',
                            icon: <Package stroke='#404040' strokeWidth={1} />
                        },
                        {
                            label: t('Messages'),
                            to: '/super/messages',
                            icon: <MessageCircle stroke='#404040' strokeWidth={1} />
                        }
                    ]}
                    light
                    version="2.0.10.2304"
                />
                <div className={style.mainContent}>
                    <div className='container pt-3'>
                        {props.children}
                    </div>
                </div>
            </div>
            <Footer />
        </div> */}
        <div className={style.container} id='scrollableDiv'>
            <div className='d-flex'>
                <SideNavbar
                    isActive={sideNavbarActive}
                    onClose={() => setSideNavbarActive(false)}
                    fixed={isMobile ? true : false}
                    showHeader={isMobile ? true : false}
                    navbarItemList={navebarList}
                    light
                    version="2.0.10.2304"
                />
                <div className={style.mainContent}>
                    <div className='container pt-3'>
                        {props.children}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    </React.Fragment>
}

export default SuperAdminLayout