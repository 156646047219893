import style from '../../styles/components/country-code.module.scss'
import { countryCode } from '../../utils'

interface ErrorTextProps {
    setCountryCode?:(value:string)=>void
    active?: boolean
}

const CountryCode = (props: ErrorTextProps) => {
    
    return <div className={`${style.container} ${props.active?style.active:''}`}>
                {countryCode.map((country)=>(
                    <div className={style.code} onClick={()=>props.setCountryCode?.(country.value)}>{country.title}</div>
                ))}
        </div>
}

export default CountryCode;