import React from 'react'
import spinnerStyle from '../../styles/components/spinner.module.scss'

interface SpinnerProps {
    color?: string
    size?: number
    className?: string
}

const Spinner = (props: SpinnerProps) => {
    const size: React.CSSProperties = {
        borderColor: props.color || '#ffffff',
        width: `${props.size ?? 20}px`,
        height: `${props.size ?? 20}px`,
    }

    return <div className={`${spinnerStyle.container} ${props.className || ''}`}>
        <div className={spinnerStyle.spinLine} style={size} />
    </div>
}

export default Spinner