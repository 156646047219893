import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useT } from '../../i18n'
import { updateDeliveryAPI } from '../../redux/actions'
import { StoreState } from '../../redux/reducers'
import { AuthAdmin } from '../../redux/reducers/authAdmin.reducer'
import { AppDispatch } from '../../redux/store'
import style from '../../styles/components/form.module.scss'
import { toString } from '../../utils'
import { ToastType, useToast } from '../ToastProvider'
import Button from '../utils/Button'
import FormInput from '../utils/FormInput'

const DeliveryAPI = () => {
    const dispatch = useDispatch<AppDispatch>()
    const toast = useToast()
    const t = useT()

    const authAdmin = useSelector<StoreState, AuthAdmin | null>(state => state.authAdmin)

    const [merchantId, setMerchantId] = React.useState<string>('')
    const [merchantIdError, setMerchantIdError] = React.useState<string>('')
    const [authToken, setAuthToken] = React.useState<string>('')
    const [authTokenError, setAuthTokenError] = React.useState<string>('')

    const [loading, setLoading] = React.useState<boolean>(false)

    const merchantIdChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setMerchantId(e.target.value)
        setMerchantIdError('')
    }

    const authTokenChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setAuthToken(e.target.value)
        setAuthTokenError('')
    }

    const saveClickHandler = () => {
        let error = false

        if (toString(merchantId) === '') {
            setMerchantIdError(t('Merchant ID required'))
            error = true
        }

        if (toString(authToken) === '') {
            setAuthTokenError(t('Auth token required'))
            error = true
        }

        if (!error) {
            setLoading(true)
            dispatch(updateDeliveryAPI({
                merchantId: toString(merchantId),
                authToken: toString(authToken)
            })).then(() => {
                toast(t('Delivery api updated'))
            }).catch(text => {
                toast(text, ToastType.ERROR)
            }).finally(() => {
                setLoading(false)
            })
        }
    }

    React.useEffect(() => {
        setMerchantId(authAdmin?.shop.delivery.api.merchantId || '')
        setAuthToken(authAdmin?.shop.delivery.api.authToken || '')
    }, [authAdmin])

    return <React.Fragment>
        <div className='mb-4 col-12'>
            <FormInput
                type='text'
                label={t('Merchant ID')}
                placeholder={t('Merchant ID')}
                containerClass='mb-4'
                uniqueId={1}
                value={merchantId}
                onChange={merchantIdChangeHandler}
                errorText={merchantIdError}
            />
            <FormInput
                type='text'
                label={t('Auth token')}
                placeholder={t('Auth token')}
                containerClass='mb-4'
                value={authToken}
                onChange={authTokenChangeHandler}
                errorText={authTokenError}
            />
            <Button className={style.saveButton} onClick={saveClickHandler} loading={loading}>{t("Save")}</Button>
        </div>
    </React.Fragment>
}

export default DeliveryAPI