import { Link } from 'react-router-dom'
import assets from '../../assets'
import { useT } from '../../i18n'
import style from '../../styles/components/header.module.scss'
import Menu from '../svgs/Menu'
import AppMenu from './AppMenu'

interface SuperAdminHeaderProps {
    onMenuClick?: () => void
}

const SuperAdminHeader = (props: SuperAdminHeaderProps) => {
    const t = useT()
    return <header className={`container py-2 ${style.container}`}>
        <div className='d-flex h-100 align-items-center justify-content-between'>
            <div className='d-flex align-items-center gap-1'>
                <div className={style.menuIcon} onClick={props.onMenuClick}>
                    <Menu />
                </div>
                <Link to='/super' className='d-block text-decoration-none'>
                    <div className='hstack gap-3'>
                        <div className={style.logo}>
                            <img src={assets.images.logo} alt='V-Shops logo' />
                        </div>
                        <div className={style.logoCaption}>{t("Virtual shopping")},<br />{t("Real value")}!</div>
                    </div>
                </Link>
            </div>
            <AppMenu role='super' />
        </div>
    </header>
}

export default SuperAdminHeader