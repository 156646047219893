import { Action } from '../actions'
import { AppointmentCartActionTypes } from '../actions/appointmentCart.actions'
import { AppointmentType, Slot } from './appointment.reducer'

export interface CartItemName {
    name: string
    language: string
}

export interface AppointmentCartItemPrice {
    id: string
    size: string
    price: number
    offerPrice: number
}

export interface AppointmentCartItem {
    appointmentId: string
    names: CartItemName[]
    cultureVouchers?: string[]
    prices: AppointmentCartItemPrice[]
    fromTime?: string
    toTime?: string
    capacity: number
    category: string
    slots: Slot[]
    type: AppointmentType
    price: number
    offerPrice: number
}

export interface AppointmentCart {
    shopId: string | null
    item: AppointmentCartItem | null
}

const initialState: AppointmentCart = {
    item: null,
    shopId: null
}

const appointmentCartReducer = (state: AppointmentCart = initialState, action: Action): AppointmentCart => {
    switch (action.type) {
        case AppointmentCartActionTypes.ADD_APPOINTMENT_CART_ITEM: {
            return {
                ...state,
                item: action.data.item,
                shopId: action.data.shopId
            }
        }

        case AppointmentCartActionTypes.REMOVE_APPOINTMENT_CART_ITEM: {
            return {
                item: null,
                shopId: null
            }
        }

        default: {
            return state
        }
    }
}

export default appointmentCartReducer