import { Action } from '../actions'
import { DivisionActionTypes } from '../actions/division.actions'

export interface DivisionAddress {
    addressLine1: string
    addressLine2: string
    city: string
    pincode: number
    state: string
}

export interface AuthDivision {
    'auth-token'?: string
    name: string
    userName: string
    email: string
    phoneNumber: string
    address: DivisionAddress
    id: string
}

const initialState: AuthDivision | null = null

const authDivisionReducer = (state: AuthDivision | null = initialState, action: Action): AuthDivision | null => {
    switch (action.type) {
        case DivisionActionTypes.LOGIN_DIVISION: {
            return action.data
        }

        case DivisionActionTypes.LOGOUT_DIVISION: {
            return null
        }

        default: {
            return state
        }
    }
}

export default authDivisionReducer