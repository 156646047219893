import ProductCardPlaceholder from './ProductCardPlaceholder'

interface ProductSectionPlaceholderProps {
    size: number
}

const ProductSectionPlaceholder = (props: ProductSectionPlaceholderProps) => {
    return <div className='w-100 mb-3'>
        <div className='placeholder-glow mb-2'>
            <div className='placeholder placeholder-sm' style={{ width: '30%' }} />
        </div>
        <div className='row'>
            {[...new Array(props.size)].map((_, index) => {
                return <ProductPlaceholder
                    key={index}
                />
            })}
        </div>
    </div>
}

const ProductPlaceholder = () => {
    return <div className='col-sm-6 col-lg-4 col-xxl-3 mb-3'>
        <div className='d-flex align-items-center justify-content-center'>
            <ProductCardPlaceholder />
        </div>
    </div>
}

export default ProductSectionPlaceholder