import { useSelector } from "react-redux";
import { getApiRequestConfig } from "../../api";
import ChangePasswordForm from "../../components/forms/ChangePasswordForm";
import MyProfileForm from "../../components/forms/MyProfileForm";
import AdminPanelHeader from "../../components/sections/AdminPanelHeader";
import SectionWithTitle from "../../components/sections/SectionWithTitle";
import ShouldAuthenticate from "../../components/sections/ShouldAuthenticate";
import UserLayout from "../../components/sections/UserLayout";
import { useT } from "../../i18n";
import { StoreState } from '../../redux/reducers';
import { AuthUser } from "../../redux/reducers/authUser.reducer";


const MyProfile = () => {
    const t = useT()
    const authUser = useSelector<StoreState, AuthUser | null>(state => state.authUser)
    const apiRequestConfig = getApiRequestConfig(authUser?.['auth-token'])

    return (
        <div>
            <ShouldAuthenticate>
                <UserLayout>
                    <AdminPanelHeader title={t('My Profile')} />
                    <br />
                    <SectionWithTitle title={t('Contact details')} withRow>
                        <MyProfileForm />
                    </SectionWithTitle>
                    <SectionWithTitle title={t('Change Password')} withRow>
                        <ChangePasswordForm
                            endPoint='user/reset-password'
                            apiConfig={apiRequestConfig}
                        />
                    </SectionWithTitle>
                </UserLayout>
            </ShouldAuthenticate>
        </div>
    )
}

export default MyProfile;