import { useSelector } from 'react-redux'
import AdminPanelHeader from '../../components/sections/AdminPanelHeader'
import SuperAdminLayout from '../../components/sections/SuperAdminLayout'
import { useT } from '../../i18n'
import { StoreState } from '../../redux/reducers'
import { ContactUs } from '../../redux/reducers/contactUs.reducer'

interface TableBody {
    sno: number
    name: string
    phonenumber: string
    email: string
    message: string
}

const Reviews = () => {
    const t = useT()
    const contactUsLists = useSelector<StoreState, Array<ContactUs>>((state => state.contactUs))



    return <>
        <SuperAdminLayout>
            <div className='row mb-4'>
                <AdminPanelHeader title={t('Messages')} />
                <div className="card m-2">
                    <div className="card-body">
                        <div className='table-wrapper'>
                            <table className='table'>
                                <thead className="table-primary">
                                    <tr>
                                        <th className='text-truncate text-center align-middle'>S.No</th>
                                        <th className='text-truncate text-center align-middle'>{t("Name")}</th>
                                        <th className='text-truncate text-center align-middle'>{t("Phone Number")}</th>
                                        <th className='text-truncate text-center align-middle'>{t("Email")}</th>
                                        <th className='text-truncate text-center align-middle'>{t("Message")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {contactUsLists.map((cl, i) => {
                                        return <ContactUsBody
                                            sno={i + 1}
                                            name={cl.name}
                                            phonenumber={cl.phoneNumber}
                                            email={cl.email}
                                            message={cl.message}
                                            key={i}
                                        />
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </SuperAdminLayout></>
}

const ContactUsBody = ({ sno, name, phonenumber, email, message }: TableBody) => {
    return <tr>
        <td className='align-middle text-center text-truncate'>{sno}</td>
        <td className='align-middle text-center text-truncate text-capitalize'>{name}</td>
        <td className='align-middle text-center'>{phonenumber}</td>
        <td className='align-middle text-center'>{email}</td>
        <td className='align-middle text-center'>{message}</td>
    </tr>
}

export default Reviews