import { Action, AppointmentActionTypes } from '../actions'
import { Category } from './category.reducer'

export type AppointmentType = 'detail' | 'simple'
export type AppointmentStatus = 'active' | 'inactive'

export interface AppointmentName {
    language: string
    name: string
    createdAt: string
    updatedAt: string
    id: string
}

export interface AppointmentDescription {
    language: string
    description: string
    createdAt: string
    updatedAt: string
    id: string
}

export interface TimeType {
    time: string
    timePeriod: string
}

export interface AppointmentPrice {
    size: string
    price: number
    offerPrice: number
    createdAt?: string
    updatedAt?: string
    id: string
}

interface SlotTime {
    time: string
    timePeriod: string
}

export interface Slot {
    fromTime: SlotTime
    toTime: SlotTime
    capacity: string
}

export interface Appointment {
    names: AppointmentName[]
    descriptions: AppointmentDescription[]
    categories: Category[]
    type: AppointmentType
    image: string
    additionalImages: string[]
    offerPrice: number
    tax: number | null
    stock: number | null
    offerLabel: string | null
    status: AppointmentStatus
    shopId: string
    createdAt: string
    updatedAt: string
    id: string
    prices: AppointmentPrice[]
    price: number
    fromTime: number
    toTime: number
    capacity: number
    date: string
    slots: Slot[]
}

const initialState: Appointment[] = []

const AppointmentReducer = (state: Appointment[] = initialState, action: Action): Appointment[] => {
    switch (action.type) {
        case AppointmentActionTypes.ADD_APPOINTMENT: {
            return [
                ...state,
                action.data
            ]
        }

        case AppointmentActionTypes.UPDATE_APPOINTMENT: {
            const Appointments = [...state]
            const index = Appointments.findIndex(f => f.id === action.data.id)

            if (index > -1) {
                Appointments[index] = {
                    ...action.data
                }
            }

            return Appointments
        }

        case AppointmentActionTypes.FETCH_APPOINTMENTS: {
            return action.data
        }

        default: {
            return state
        }
    }
}

export default AppointmentReducer