import React from 'react'
import FormInput from '../utils/FormInput'

interface ResponsiveFormInputProps {
    value?: string
    onChange?: React.ChangeEventHandler<HTMLInputElement>
    label?: string
    responsiveClassName?: string
    containerClass?: string
    type?: React.HTMLInputTypeAttribute
    errorText?: string
}

const ResponsiveFormInput = (props: ResponsiveFormInputProps) => {
    return <div className={props.responsiveClassName}>
        <FormInput
            type={props.type}
            label={props.label}
            placeholder={props.label}
            value={props.value}
            onChange={props.onChange}
            containerClass={props.containerClass}
            errorText={props.errorText}
        />
    </div>
}

export default ResponsiveFormInput