import { Action, ExtraToppingActionTypes } from '../actions'

export interface ExtraToppingName {
    language: string
    name: string
    createdAt: string
    updatedAt: string
    id: string
}

export interface ExtraToppingType {
    language: string
    type: string
    createdAt: string
    updatedAt: string
    id: string
}

export interface ExtraToppingPrice {
    size: string
    price: number
    createdAt: string
    updatedAt: string
    id: string
}

export interface ExtraTopping {
    names: ExtraToppingName[]
    types: ExtraToppingType[]
    prices: ExtraToppingPrice[]
    shopId: string
    createdAt: string
    updatedAt: string
    id: string
}

const initialState: ExtraTopping[] = []

const extraToppingReducer = (state: ExtraTopping[] = initialState, action: Action): ExtraTopping[] => {
    switch (action.type) {
        case ExtraToppingActionTypes.ADD_EXTRA_TOPPING: {
            return [
                ...state,
                action.data
            ]
        }

        case ExtraToppingActionTypes.UPDATE_EXTRA_TOPPING: {
            const extraToppings = [...state]
            const index = extraToppings.findIndex(c => c.id === action.data.id)

            if (index > -1) {
                extraToppings[index] = {
                    ...action.data
                }
            }

            return extraToppings
        }

        case ExtraToppingActionTypes.FETCH_SHOP_EXTRA_TOPPINGS: {
            return action.data
        }

        default: {
            return state
        }
    }
}

export default extraToppingReducer