import React, { useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useDispatch, useSelector } from 'react-redux'
import { useToast } from '../../components/ToastProvider'
import AdminLayout from '../../components/sections/AdminLayout'
import AdminPanelHeader from '../../components/sections/AdminPanelHeader'
import Select from '../../components/utils/Select'
import Spinner from '../../components/utils/Spinner'
import { AdminContext, AdminContextType } from '../../context/AdminProvider'
import { AppContext } from '../../context/AppProvider'
import { useT } from '../../i18n'
import { StoreState } from '../../redux/reducers'
import { Booking } from '../../redux/reducers/booking.reducer'
import { Event as EventType } from '../../redux/reducers/event.reducer'
import { AppDispatch } from '../../redux/store'
import style from '../../styles/pages/admin-orders.module.scss'

interface TableBody {
    sno: number
    name: string
    phonenumber: string
    email: string
    volunteering: string[]
}



const Reservations = () => {
    const adminContext = React.useContext<AdminContextType | null>(AdminContext)
    const appContext = React.useContext(AppContext);
    const t = useT()
    const toast = useToast()
    const [pages, setPages] = React.useState(Array.from({ length: 0 }))
    const [hasMore, setHasMore] = React.useState(true)

    const dispatch = useDispatch<AppDispatch>();

    const events = useSelector<StoreState, EventType[]>(state => state.events)
    const bookings = useSelector<StoreState, Booking[]>(state => state.bookings)
    const [selectedEvent, setSelectedEvent] = useState("")

    const filteredBookings = bookings.filter(booking => booking?.eventId?.id === selectedEvent)



    const fetchMoreData = () => {
        if (pages.length <= filteredBookings.length) {
            setTimeout(() => {
                setPages(pages.concat(Array.from({ length: 20 })))
            }, 1000)
            return
        }
        setHasMore(false)
    }

    React.useEffect(() => {
        fetchMoreData()
    }, [])

    return <AdminLayout>
        <div className='mb-4 mt-2'>
            <AdminPanelHeader title={t('Volunteering Reservations')} />
            <div className='d-flex align-items-center justify-content-between mb-3'>
                <Select
                    containerClass='mb-3'
                    value={selectedEvent}
                    placeholder={t('Select Event')}
                    options={events.map((event) => { return { text: event.names[0].name, value: event.id } })}
                    onChange={(e) => setSelectedEvent(e.target.value)}
                />
            </div>

            {adminContext?.fetchingOrders
                ? <Spinner size={25} color='#ec3b50' className='mt-3' />
                :
                filteredBookings.length !== 0 && <InfiniteScroll dataLength={pages.length} scrollableTarget="scrollableDiv" next={fetchMoreData} hasMore={hasMore} loader={<p className={style.infiniteScrollText}>{filteredBookings[0].volunteers.length > 0 ? t("Fetching volunteers...") : t("No volunteers!")}</p>} endMessage={<p className={style.infiniteScrollText}>{t("All volunteers fetched")}</p>}>

                    <div className="card m-2">
                        <div className="card-body">
                            <div className='table-wrapper'>
                                <table className='table'>
                                    <thead className="table-primary">
                                        <tr>
                                            <th className='text-truncate text-center align-middle'>S.No</th>
                                            <th className='text-truncate text-center align-middle'>{t("Name")}</th>
                                            <th className='text-truncate text-center align-middle'>{t("Phone Number")}</th>
                                            <th className='text-truncate text-center align-middle'>{t("Email")}</th>
                                            <th className='text-truncate text-center align-middle'>{t("Volunteering")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredBookings.reverse().filter((booking) => booking.volunteers.length !== 0).map((booking, i) => {
                                            return <VolunteerReservationBody
                                                sno={i + 1}
                                                name={booking.user.firstName + " " + booking.user.lastName}
                                                phonenumber={booking.user.phoneNumber}
                                                email={booking.user.email}
                                                volunteering={booking.volunteers}
                                                key={i}
                                            />

                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </InfiniteScroll>
            }
        </div>
    </AdminLayout>
}

const VolunteerReservationBody = ({ sno, name, phonenumber, email, volunteering }: TableBody) => {
    return <tr>
        <td className='align-middle text-center text-truncate'>{sno}</td>
        <td className='align-middle text-center text-truncate text-capitalize'>{name}</td>
        <td className='align-middle text-center'>{phonenumber}</td>
        <td className='align-middle text-center'>{email}</td>
        <td className='align-middle text-center'>{volunteering.join(", ")}</td>
    </tr>
}

export default Reservations