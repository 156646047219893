import React from 'react'
import { useDispatch } from 'react-redux'
import { AdminContext, AdminContextType } from '../../context/AdminProvider'
import { useT } from '../../i18n'
import { updateEventPreivewStatus, updateEventStatus } from '../../redux/actions'
import { AppDispatch } from '../../redux/store'
import Card from './Card'
import EventTable from './EventTable'

export interface EventCardProps {
    name?: string
    img?: string
    status?: string | null
    previewStatus?: string | null
    offerLabel?: string | null
    price?: number | null
    offerPrice?: number | null
    cloneEvent?: () => void
    id?: string | undefined
    key?: number
    index?: number
}

const EventCard = (props: EventCardProps) => {
    const adminContext = React.useContext<AdminContextType | null>(AdminContext)
    const [eventIsActive, setEventIsActive] = React.useState(props?.status);
    const [productPreview, setProductPreview] = React.useState(props.previewStatus || "inactive");
    const dispatch = useDispatch<AppDispatch>()
    const t = useT()
    const toggleHandler = () => {
        if (props.status) {
            const eventId = props.id;
            if (props.status === "active") {
                dispatch(updateEventStatus("inactive", eventId)).finally(() => {
                    setEventIsActive("inactive");
                })
            } else {
                dispatch(updateEventStatus("active", eventId)).finally(() => {
                    setEventIsActive("active");
                })
            }
        }
    }

    const productPreviewHandler = () => {
        if (productPreview) {
            const eventId = props.id;
            if (productPreview === "active") {
                dispatch(updateEventPreivewStatus("inactive", eventId)).finally(() => {
                    setProductPreview("inactive");
                })
            } else {
                dispatch(updateEventPreivewStatus("active", eventId)).finally(() => {
                    setProductPreview("active");
                })

            }
        }
    }

    return <>
        {adminContext?.isCardStyle ? <Card
            img={props.img}
            name={props.name}
            offerLabel={props.offerLabel || ''}
            onClick={() => props.id && adminContext?.onEditEvent?.(props.id)}
            buttonText={t('Edit')}
            status={eventIsActive}
            toggleHandler={toggleHandler}
            price={props.price !== Infinity ? props.price : 0}
            offerPrice={props.offerPrice !== Infinity ? props.offerPrice : 0}
            uploadBanner={() => props.id && adminContext?.onCamera?.(props.id)}
            previewHandler={productPreviewHandler}
            cloneEvent={props.cloneEvent}
            preview={productPreview === "active" ? true : false}
            from={false}
        // index={props.index}
        /> : <EventTable
            img={props.img}
            name={props.name}
            offerLabel={props.offerLabel || ''}
            onClick={() => props.id && adminContext?.onEditEvent?.(props.id)}
            buttonText={t('Edit')}
            status={eventIsActive}
            toggleHandler={toggleHandler}
            price={props.price !== Infinity ? props.price : 0}
            offerPrice={props.offerPrice !== Infinity ? props.offerPrice : 0}
            uploadBanner={() => props.id && adminContext?.onCamera?.(props.id)}
            previewHandler={productPreviewHandler}
            cloneEvent={props.cloneEvent}
            preview={productPreview === "active" ? true : false}
            from={false}
            index={props.index}
        />}
    </>
}

export default EventCard