import { Action, AdminCategory, AuthAdminActionTypes } from '../actions'
import { BankDetails, Delivery, OtherPayments, Paytrail, Pickup, PreviewTheme, ShopAddress, Theme, Timing } from './shop.reducer'

export type AdminPlan = 'Starter' | 'Economy' | 'Standard' | 'Premium'

export interface AuthAdminShop {
    shopNumber: number
    name: string
    divisionId?: string
    businessName: string | null
    countryCode: string | null
    phoneNumber: string | null
    currency: string
    plan?: AdminPlan
    description: string
    category: AdminCategory
    address: ShopAddress
    active: boolean
    delivery: Delivery
    tax: number
    vatId: string | null
    id: string
    bankDetails: BankDetails
    otherPayments: OtherPayments[]
    shopTiming: Timing
    paytrail: Paytrail
    processTimes: number[]
    theme: Theme
    previewTheme?: PreviewTheme
    pickup?: Pickup[]
}

export interface AuthAdmin {
    'auth-token': string
    name: string
    email: string
    username: string
    category: AdminCategory
    role: string
    shop: AuthAdminShop
    createdAt: string
    updatedAt: string
    id: string
}

const initialState: AuthAdmin | null = null

const authAdminReducer = (state: AuthAdmin | null = initialState, action: Action): AuthAdmin | null => {
    switch (action.type) {
        case AuthAdminActionTypes.LOGIN_ADMIN: {
            return action.data
        }

        case AuthAdminActionTypes.LOGOUT_ADMIN: {
            return null
        }

        case AuthAdminActionTypes.UPDATE_SHOP_CONTACT_DETAILS: {
            if (state) {
                return {
                    ...state,
                    shop: {
                        ...state.shop,
                        name: action.data.shopName,
                        businessName: action.data.businessName,
                        countryCode: action.data.countryCode,
                        phoneNumber: action.data.phoneNumber,
                        currency: action.data.currency,
                        description: action.data.description,
                        address: {
                            ...action.data.address
                        }
                    }
                }
            }
            return null
        }

        case AuthAdminActionTypes.UPDATE_SHOP_BANK_DETAILS: {
            if (state) {
                return {
                    ...state,
                    shop: {
                        ...state.shop,
                        bankDetails: {
                            ...action.data
                        }
                    }
                }
            }
            return null
        }

        case AuthAdminActionTypes.UPDATE_SHOP_OTHER_PAYMENTS: {
            if (state) {
                return {
                    ...state,
                    shop: {
                        ...state.shop,
                        otherPayments: action.data

                    }
                }
            }
            return null
        }

        case AuthAdminActionTypes.UPDATE_SHOP_TIMING: {
            if (state) {
                return {
                    ...state,
                    shop: {
                        ...state.shop,
                        shopTiming: {
                            ...action.data
                        }
                    }
                }
            }
            return null
        }

        case AuthAdminActionTypes.UPDATE_DELIVERY_TIMING: {
            if (state) {
                return {
                    ...state,
                    shop: {
                        ...state.shop,
                        delivery: {
                            ...state.shop.delivery,
                            timing: {
                                ...action.data
                            }
                        }
                    }
                }
            }
            return null
        }

        case AuthAdminActionTypes.ENABLE_DELIVERY: {
            if (state) {
                return {
                    ...state,
                    shop: {
                        ...state.shop,
                        delivery: {
                            ...state.shop.delivery,
                            enabled: true
                        }
                    }
                }
            }
            return null
        }

        case AuthAdminActionTypes.DISABLE_DELIVERY: {
            if (state) {
                return {
                    ...state,
                    shop: {
                        ...state.shop,
                        delivery: {
                            ...state.shop.delivery,
                            enabled: false
                        }
                    }
                }
            }
            return null
        }

        case AuthAdminActionTypes.UPDATE_DELIVERY_CHARGE_AND_DISTANCE: {
            if (state) {
                return {
                    ...state,
                    shop: {
                        ...state.shop,
                        delivery: {
                            ...state.shop.delivery,
                            charge: {
                                forEveryKmAboveGivenDistance: action.data.charge.forEveryKmAboveGivenDistance,
                                upToGivenDistance: action.data.charge.upToGivenDistance
                            },
                            distance: {
                                from: action.data.distance.from,
                                to: action.data.distance.to
                            },
                            lateNightDelivery: {
                                chargeForEveryKm: action.data.lateNightDelivery.chargeForEveryKm,
                                timing: {
                                    from: action.data.lateNightDelivery.timing.from,
                                    to: action.data.lateNightDelivery.timing.to
                                }
                            },
                            orderValueForFreeDelivery: {
                                aboveGivenDistance: action.data.orderValueForFreeDelivery.aboveGivenDistance,
                                upToGivenDistance: action.data.orderValueForFreeDelivery.upToGivenDistance
                            }
                        }
                    }
                }
            }
            return null
        }

        case AuthAdminActionTypes.UPDATE_DELIVERY_API: {
            if (state) {
                return {
                    ...state,
                    shop: {
                        ...state.shop,
                        delivery: {
                            ...state.shop.delivery,
                            api: {
                                authToken: action.data.authToken,
                                merchantId: action.data.merchantId
                            }
                        }
                    }
                }
            }
            return null
        }

        case AuthAdminActionTypes.UPDATE_PAYTRAIL: {
            if (state) {
                return {
                    ...state,
                    shop: {
                        ...state.shop,
                        paytrail: {
                            merchantId: action.data.merchantId,
                            secretKey: action.data.secretKey,
                            commission: action.data.commission,
                            paytrailType: action.data.paytrailType,
                            status: action.data.status
                        }
                    }
                }
            }
            return null
        }

        case AuthAdminActionTypes.UPDATE_PROCESS_TIMES: {
            if (state) {
                return {
                    ...state,
                    shop: {
                        ...state.shop,
                        processTimes: [...action.data]
                    }
                }
            }
            return null
        }

        case AuthAdminActionTypes.UPDATE_SHOP_THEME: {
            if (state) {
                return {
                    ...state,
                    shop: {
                        ...state.shop,
                        theme: {
                            ...action.data,
                            slides: action.data.slides.map(slide => ({ ...slide }))
                        }
                    }
                }
            }
            return null
        }

        case AuthAdminActionTypes.UPDATE_SHOP_PREVIEW_THEME: {
            if (state) {
                return {
                    ...state,
                    shop: {
                        ...state.shop,
                        previewTheme: {
                            ...action.data
                        }
                    }
                }
            }
            return null
        }


        case AuthAdminActionTypes.UPDATE_SHOP_VAT_ID: {
            if (state) {
                return {
                    ...state,
                    shop: {
                        ...state.shop,
                        vatId: action.data
                    }
                }
            }
            return null
        }

        default: {
            return state
        }
    }
}

export default authAdminReducer