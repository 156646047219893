/* eslint-disable no-throw-literal */
import { Dispatch } from 'redux'
import { api, apiErrorHandler, getApiRequestConfig, Response } from '../../api'
import { GetState } from '../reducers'
import { Topping } from '../reducers/topping.reducer'
import { AddLanguagesAction, LanguageActionTypes } from './language.actions'

export enum ToppingActionTypes {
    ADD_TOPPING = 'ADD_TOPPING',
    UPDATE_TOPPING = 'UPDATE_TOPPING',
    FETCH_SHOP_TOPPINGS = 'FETCH_SHOP_TOPPINGS'
}

export interface AddToppingAction {
    type: ToppingActionTypes.ADD_TOPPING
    data: Topping
}

export interface UpdateToppingAction {
    type: ToppingActionTypes.UPDATE_TOPPING
    data: Topping
}

export interface FetchShopToppingsAction {
    type: ToppingActionTypes.FETCH_SHOP_TOPPINGS
    data: Topping[]
}

export type ToppingActions = AddToppingAction | FetchShopToppingsAction | UpdateToppingAction

interface ToppingRequestName {
    language: string
    name: string
}

interface ToppingRequestType {
    language: string
    type: string
}

interface AddTopping {
    names: ToppingRequestName[]
    types: ToppingRequestType[]
}

export const addTopping = (input: AddTopping) => async (dispatch: Dispatch, getState: GetState) => {
    const authAdmin = getState().authAdmin
    const config = getApiRequestConfig(authAdmin?.['auth-token'])

    return api.post<Response<Topping>>('topping', input, config).then(response => {
        if (response.status === 200) {
            dispatch<AddToppingAction>({
                type: ToppingActionTypes.ADD_TOPPING,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || 'Added')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to add'))
    })
}

interface UpdateTopping {
    names: ToppingRequestName[]
    types: ToppingRequestType[]
}

export const updateTopping = (input: UpdateTopping, toppingId: string) => async (dispatch: Dispatch, getState: GetState) => {
    const authAdmin = getState().authAdmin
    const config = getApiRequestConfig(authAdmin?.['auth-token'])

    return api.put<Response<Topping>>(`topping/${toppingId}`, input, config).then(response => {
        if (response.status === 200) {
            dispatch<UpdateToppingAction>({
                type: ToppingActionTypes.UPDATE_TOPPING,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || 'Updated')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to update'))
    })
}

export const fetchShopToppings = () => async (dispatch: Dispatch, getState: GetState) => {
    const authAdmin = getState().authAdmin
    const config = getApiRequestConfig(authAdmin?.['auth-token'])

    return api.get<Response<Topping[]>>('toppings', config).then(response => {
        if (response.status === 200) {
            dispatch<FetchShopToppingsAction>({
                type: ToppingActionTypes.FETCH_SHOP_TOPPINGS,
                data: response.data.data
            })

            if (getState().authAdmin) {
                const languages: string[] = []

                response.data.data.forEach(d => {
                    d.names && d.names.forEach(p => {
                        languages.push(p.language)
                    })
                })

                dispatch<AddLanguagesAction>({
                    type: LanguageActionTypes.ADD_LANGUAGES,
                    data: languages
                })
            }

            return Promise.resolve<string>(response.data.message || '')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to fetch'))
    })
}