import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { UserContext, UserContextType } from '../../context/UserProvider'
import { useT } from '../../i18n'
import { addFavourite, removeFavourite } from '../../redux/actions'
import { StoreState } from '../../redux/reducers'
import { Event } from '../../redux/reducers/event.reducer'
import { Favourite } from '../../redux/reducers/favourite.reducer'
import { AppDispatch } from '../../redux/store'
import { getEventOfferPrice, getEventPrice, monthNames } from '../../utils'
import Card from './Card'

export interface ShopEventCategorySectionCardProps {
    event?: Event
}

export const ResponsiveShopEventCard = (props: ShopEventCategorySectionCardProps) => {
    return <div className='col-sm-6 col-lg-4 col-xxl-3 mb-3'>
        <div className='d-flex align-items-center justify-content-center'>
            <ShopEventCard {...props} />
        </div>
    </div>
}

const ShopEventCard = (props: ShopEventCategorySectionCardProps) => {
    const userContext = React.useContext<UserContextType | null>(UserContext)
    const favourites = useSelector<StoreState, Favourite[]>(state => state.favourites.filter(fav => fav.type === 'item' && fav.shopId === props.event?.shopId))
    const dispatch = useDispatch<AppDispatch>()
    const t = useT()
    const [eventDate, setEventDate] = useState<string>("")
    //const includes = props.event?.toppings.map(topping => topping.names[0].name).join(', ')
    const price = getEventPrice(props.event)
    const offerPrice = getEventOfferPrice(props.event)

    const isFavourite = favourites.some(fav => fav.itemId && fav.itemId === props.event?.id)
    const navigate = useNavigate()

    const onAddToCartClickHandler = () => {
        // if (props.event) {
        //     userContext?.openCartEventModal?.(props.event)
        // }

        if (props.event) {
            navigate(`/event/${props.event?.shopId}/${props.event?.id}`)
        }

    }
    useEffect(() => {
        const fromDate = props.event?.fromDate;
        const from = new Date(fromDate ? fromDate : '');
        const date = monthNames[from.getMonth()] + ' ' + from.getDate() + ', ' + from.getFullYear();
        setEventDate(date)
    }, [props])
    const favouritesClickHandler = () => {
        if (props.event) {
            if (isFavourite) {
                dispatch(removeFavourite({
                    shopId: props.event?.shopId,
                    type: 'item',
                    itemId: props.event?.id
                }))
            } else {
                dispatch(addFavourite({
                    shopId: props.event?.shopId,
                    type: 'item',
                    itemId: props.event?.id
                }))
            }
        }
    }

    return <Card
        img={props.event?.image}
        offerLabel={props.event?.offerLabel || ''}
        favourites
        isFavourite={isFavourite}
        name={props.event?.names[0].name}
        includes={""}
        eventDate={eventDate}
        locationPrefix={props.event?.location}
        buttonText={t('View Event')}
        onClick={onAddToCartClickHandler}
        onFavouritesClick={favouritesClickHandler}
        startsFrom={false}
        from={false}
    />
}

export default ShopEventCard