type Props={
    stroke?:string
}

const CheckboxOn = (props:Props) => {
    return <svg xmlns='http://www.w3.org/2000/svg' width='19' height='19' viewBox='0 0 19 19'>
        <g id='Component_102_5' data-name='Component 102 – 5' transform='translate(0.5 0.5)'>
            <rect id='checkbox_light' width='18' height='18' rx='4' fill='none' />
            <path id='check_on_light' d='M19,3H5A2.006,2.006,0,0,0,3,5V19a2.006,2.006,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V5a2.006,2.006,0,0,0-2-2ZM10,17,5,12.192l1.4-1.346L10,14.308,17.6,7,19,8.346,10,17Z' transform='translate(-3 -3)' fill='#404040' stroke='rgba(0,0,0,0)' strokeWidth='1' fillRule='evenodd' />
            <g id='Rectangle_67' data-name='Rectangle 67' fill='none' stroke={props.stroke?props.stroke:'#404040'} strokeWidth='2'>
                <rect width='18' height='18' rx='2' stroke='none' />
                <rect x='1' y='1' width='16' height='16' rx='1' fill='none' />
            </g>
        </g>
    </svg>
}

export default CheckboxOn