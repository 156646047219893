import { nanoid } from '@reduxjs/toolkit'
import React from 'react'
import { api } from '../../api'
import { useT } from '../../i18n'
import { toString } from '../../utils'
import ModalWithHeader from '../modals/ModalWithHeader'
import Camera from '../svgs/Camera'
import Edit from '../svgs/Edit'
import FormInput from '../utils/FormInput'

export interface Slide {
    id: string
    image: string
    productUrl: string
    title?: string
    description?: string
}

interface SlideFormProps {
    id?: string
    onClose?: () => void
    onSave?: (slide: Slide) => void
}

const SlideForm = (props: SlideFormProps) => {
    const t = useT()
    const [image, setImage] = React.useState<any>('')
    const [imageError, setImageError] = React.useState<string>('')
    const [productUrl, setProductUrl] = React.useState<string>('')
    const [productUrlError, setProductUrlError] = React.useState<string>('')
    const [title, setTitle] = React.useState<any>('')
    const [titleError, setTitleError] = React.useState<string>('')
    const [description, setDescription] = React.useState<any>('')
    const [descriptionError, setDescriptionError] = React.useState<string>('')
    const [onCamera, setOnCamera] = React.useState(false)
    const imageChangeHandler: React.ChangeEventHandler<HTMLInputElement | HTMLFormElement> = async (e) => {
        let response: any
        if (onCamera) {
            const formData: any = new FormData()
            formData.append("file", e.target.files[0]);
            response = await api.post<Response>(`upload-img/bannerSlide/${props.id}`, formData);
        }
        onCamera ? setImage(response.data.data) : setImage(e.target.value)
        setImageError('')
    }

    const productUrlChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setProductUrl(e.target.value)
        setProductUrlError('')
    }

    const titleChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setTitle(e.target.value)
        setTitleError('')
    }

    const descriptionChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setDescription(e.target.value)
        setDescriptionError('')
    }

    const saveClickHandler: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault()
        let error = false

        if (toString(image) === '') {
            setImageError(t('Image required'))
            error = true
        }

        if (toString(productUrl) === '') {
            setProductUrlError(t('Product URL required'))
            error = true
        }

        if (!error) {
            props.onSave?.({
                id: nanoid(),
                image: toString(image),
                productUrl: toString(productUrl),
                title: title,
                description: description
            })
            props.onClose?.()
        }
    }

    return <ModalWithHeader
        buttonText={t('Save')}
        headerText={t('Add Slide')}
        onClose={props.onClose}
        onSave={saveClickHandler}
        small
    >
        <FormInput
            type={onCamera ? 'file' : 'text'}
            label={t('Image')}
            placeholder={t('Image')}
            onChange={imageChangeHandler}
            errorText={imageError}
            value={onCamera ? image.filename : image}
            rightRenderer={!onCamera ? <Camera strokeWidth={2} /> : <Edit />}
            onRightRendererClick={() => setOnCamera(onCamera === true ? false : true)}
            containerClass='mb-4'
        />
        <FormInput
            type='text'
            label={t('Product URL')}
            placeholder={t('Product URL')}
            onChange={productUrlChangeHandler}
            errorText={productUrlError}
            value={productUrl}
            containerClass='mb-4'
        />
        <FormInput
            type='text'
            label={t('Title')}
            placeholder={t('Title')}
            onChange={titleChangeHandler}
            errorText={titleError}
            value={title}
            containerClass='mb-4'
        />
        <FormInput
            type='text'
            label={t('Description')}
            placeholder={t('Description')}
            onChange={descriptionChangeHandler}
            errorText={descriptionError}
            value={description}
            containerClass='mb-4'
        />
    </ModalWithHeader>
}

export default SlideForm