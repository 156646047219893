import { DEFAULT_LANGUAGES } from '../../config'
import { Action, LanguageActionTypes } from '../actions'

const initialState: string[] = DEFAULT_LANGUAGES

const languageReducer = (state: string[] = initialState, action: Action): string[] => {
    const newState = [...state]

    switch (action.type) {
        case LanguageActionTypes.ADD_LANGUAGE: {
            return newState.includes(action.data)
                ? newState
                : [
                    ...newState,
                    action.data.toLowerCase()
                ]
        }

        case LanguageActionTypes.ADD_LANGUAGES: {
            const languages: string[] = [...newState]

            action.data.forEach(s => {
                if (!languages.includes(s)) {
                    languages.push(s)
                }
            })

            return languages
        }

        default: {
            return state
        }
    }
}

export default languageReducer