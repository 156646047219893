/* eslint-disable no-throw-literal */
import { Dispatch } from 'redux'
import { api, apiErrorHandler, getApiRequestConfig, Response } from '../../api'
import { GetState } from '../reducers'
import { Category } from '../reducers/category.reducer'
import { AddLanguagesAction, LanguageActionTypes } from './language.actions'

export enum CategoryActionTypes {
    ADD_CATEGORY = 'ADD_CATEGORY',
    UPDATE_CATEGORY = 'UPDATE_CATEGORY',
    REORDER_CATEGORY = 'REORDER_CATEGORY',
    FETCH_SHOP_CATEGORIES = 'FETCH_SHOP_CATEGORIES'
}

export interface AddCategoryAction {
    type: CategoryActionTypes.ADD_CATEGORY
    data: Category
}

export interface UpdateCategoryAction {
    type: CategoryActionTypes.UPDATE_CATEGORY
    data: Category
}

export interface FetchShopCategoriesAction {
    type: CategoryActionTypes.FETCH_SHOP_CATEGORIES
    data: Category[]
}

export interface ReorderShopCategoriesAction {
    type: CategoryActionTypes.REORDER_CATEGORY
    data: Category[]
}

export type CategoryActions = AddCategoryAction | FetchShopCategoriesAction | UpdateCategoryAction | ReorderShopCategoriesAction

interface CategoryRequestName {
    language: string
    name: string
}

interface AddCategory {
    names: CategoryRequestName[]
    offerLabel: string | null
}

export const addCategory = (input: AddCategory) => async (dispatch: Dispatch, getState: GetState) => {
    const authAdmin = getState().authAdmin
    const config = getApiRequestConfig(authAdmin?.['auth-token'])

    return api.post<Response<Category>>('category', input, config).then(response => {
        if (response.status === 200) {
            dispatch<AddCategoryAction>({
                type: CategoryActionTypes.ADD_CATEGORY,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || 'Added')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to add'))
    })
}

interface UpdateCategory {
    names: CategoryRequestName[]
    offerLabel: string | null
}

export const updateCategory = (input: UpdateCategory, categoryId: string) => async (dispatch: Dispatch, getState: GetState) => {
    const authAdmin = getState().authAdmin
    const config = getApiRequestConfig(authAdmin?.['auth-token'])

    return api.put<Response<Category>>(`category/${categoryId}`, input, config).then(response => {
        if (response.status === 200) {
            dispatch<UpdateCategoryAction>({
                type: CategoryActionTypes.UPDATE_CATEGORY,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || 'Updated')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to updated'))
    })
}

export const fetchShopCategories = () => async (dispatch: Dispatch, getState: GetState) => {
    const authAdmin = getState().authAdmin
    const config = getApiRequestConfig(authAdmin?.['auth-token'])

    return api.get<Response<Category[]>>('categories', config).then(response => {
        if (response.status === 200) {
            dispatch<FetchShopCategoriesAction>({
                type: CategoryActionTypes.FETCH_SHOP_CATEGORIES,
                data: response.data.data
            })

            if (getState().authAdmin) {
                const languages: string[] = []

                response.data.data.forEach(d => {
                    d.names && d.names.forEach(p => {
                        languages.push(p.language)
                    })
                })

                dispatch<AddLanguagesAction>({
                    type: LanguageActionTypes.ADD_LANGUAGES,
                    data: languages
                })
            }

            return Promise.resolve<string>(response.data.message || '')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to fetch'))
    })
}

export const reorderShopCategories = (categories: Category[]) => async (dispatch: Dispatch, getState: GetState) => {
    dispatch<ReorderShopCategoriesAction>({
        type: CategoryActionTypes.REORDER_CATEGORY,
        data: categories
    })

    const authAdmin = getState().authAdmin
    const config = getApiRequestConfig(authAdmin?.['auth-token'])
    const data = categories.map(c => ({ id: c.id, seqNo: c.seqNo }))

    return api.put<Response<Category[]>>('categories/reorder', data, config).then(response => {
        if (response.status === 200) {
            dispatch<ReorderShopCategoriesAction>({
                type: CategoryActionTypes.REORDER_CATEGORY,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || '')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to update'))
    })
}