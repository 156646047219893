import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Response, api, getApiRequestConfig } from '../../api'
import HomeSectionCard from '../../components/cards/HomeSectionCard'
import Confetti from '../../components/lottie/Confetti'
import HomeSection from '../../components/sections/HomeSection'
import UserLayout from '../../components/sections/UserLayout'
import { UserContext } from '../../context/UserProvider'
import { useT } from '../../i18n'
import { eventRemoveCartItem } from '../../redux/actions/eventCart.actions'
import { StoreState } from '../../redux/reducers'
import { AuthUser } from '../../redux/reducers/authUser.reducer'
import { Booking } from '../../redux/reducers/booking.reducer'
import { Rating } from '../../redux/reducers/rating.reducer'
import { Shop } from '../../redux/reducers/shop.reducer'
import { AppDispatch } from '../../redux/store'
import style from '../../styles/pages/order-placed.module.scss'

type OrderPlacedParams = {
    bookingNumber?: string
}

const EventBooked = () => {
    const { bookingNumber } = useParams<OrderPlacedParams>()
    const t = useT()
    const dispatch = useDispatch<AppDispatch>()
    const shops = useSelector<StoreState, Shop[]>(state => state.shops).filter(s => s.active === true)
    const authUser = useSelector<StoreState, AuthUser | null>(state => state.authUser)
    const userContext = React.useContext(UserContext)
    const booking = userContext?.bookings.filter((b) => b.bookingNumber === bookingNumber)[0];
    const getUserBookings = () => {
        const config = getApiRequestConfig(authUser?.['auth-token'])
        api.get<Response<Booking[]>>('user/bookings', config).then(response => {
            if (response.status === 200) {
                userContext?.setBookings?.(response.data.data)
            } else {
                // eslint-disable-next-line no-throw-literal
                throw { response }
            }
        }).catch(() => { })
    }
    React.useEffect(() => {
        if (authUser) {
            getUserBookings()
        }
    }, [authUser])
    const [showAnimation, setShowAnimation] = React.useState<boolean>(true)

    React.useEffect(() => {
        dispatch(eventRemoveCartItem())
    }, [dispatch])

    const getRating = (rating: Rating[]) => {
        let sum = rating.reduce((prev, current) => {
            return prev + current.rating
        }, 0)
        const value = sum / rating.length
        return rating.length > 0 ? value : 0
    }
    return <React.Fragment>
        {showAnimation && <Confetti onClose={() => setShowAnimation(false)} />}
        <UserLayout>
            <div className={style.thankyou}>{t("Thank you for your booking with us!")}</div>
            <div className={style.orderPlaced}>{t("Your booking successfully registered")}.</div>
            {booking?.eventId.type !== "free" && <div className={style.qrText}>{t("QR code will be generated after Payment Verification.")}</div>}
            <div className={style.orderPlaced}>{t("Please check my bookings and check mail in spam folder")}</div>
            <div className={style.orderNumber}>{t("Booking number")}: {bookingNumber}</div>
            {shops.length > 0 && <HomeSection
                titleLeft
                items={shops}
                title={t('Shops')}
                renderItemGetter={item => {
                    return <HomeSectionCard
                        img={item.theme.bannerImage || ''}
                        locationPrefix={item.address.city}
                        locationSuffix={item.address.landmark || ''}
                        name={item.name}
                        rating={getRating(item.ratings || [])}
                        subText={item.businessName || ''}
                        to={`/shop/${item.id}`}
                        itemId={item.id}

                    />
                }}
            />}
        </UserLayout>
    </React.Fragment>
}

export default EventBooked;