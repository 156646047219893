import { Action, AuthUserActionTypes } from '../actions'

export interface UserAddress {
    addressLine1: string
    addressLine2: string
    city: string
    pincode: number
    state: string
    landmark: string | null
}

export interface ReferralCode{
    code:string | null
    count:string | null
    emailCount?:string | null
}

export interface AuthUser {
    'auth-token': string
    firstName: string
    lastName: string
    email: string
    phoneNumber: string
    countryCode?:string |null
    referralCode?:ReferralCode | null
    address: UserAddress
    id: string
}

const initialState: AuthUser | null = null

const authUserReducer = (state: AuthUser | null = initialState, action: Action): AuthUser | null => {
    switch (action.type) {
        case AuthUserActionTypes.LOGIN_USER: {
            return action.data
        }

        case AuthUserActionTypes.LOGOUT_USER: {
            return null
        }

        case AuthUserActionTypes.UPDATE_USER_CONTACT_DETAILS: {
            if (state) {
                return {
                    ...state,

                        firstName: action.data.firstName!==null?action.data.firstName:'',
                        lastName: action.data.lastName!==null?action.data.lastName:'',
                        countryCode: action.data.countryCode!==null?action.data.countryCode:'',
                        phoneNumber: action.data.phoneNumber!==null?action.data.phoneNumber:'',
                        referralCode:action.data.referral!==null?action.data.referral:null,
                        address: {
                            ...action.data.address
                        }
                    
                }
            }
            return null
        }

        default: {
            return state
        }
    }
}

export default authUserReducer