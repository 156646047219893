/* eslint-disable no-throw-literal */
import { Dispatch } from 'redux'
import { api, apiErrorHandler, Response } from '../../api'
import { GetState } from '../reducers'
import { CartItemExtraTopping, CartItemName, CartStatus } from '../reducers/cart.reducer'
import { Food, FoodType } from '../reducers/food.reducer'

export enum CartActionTypes {
    ADD_CART_ITEM = 'ADD_CART_ITEM',
    UPDATE_CART_ITEM = 'UPDATE_CART_ITEM',
    REMOVE_CART_ITEM = 'REMOVE_CART_ITEM',
    REMOVE_ALL_CART_ITEMS = 'REMOVE_ALL_CART_ITEMS',
    INCREASE_CART_ITEM_QUANTITY = 'INCREASE_CART_ITEM_QUANTITY',
    DECREASE_CART_ITEM_QUANTITY = 'DECREASE_CART_ITEM_QUANTITY',
    SYNC_CART_WITH_DB = 'SYNC_CART_WITH_DB',
}

interface AddToCart {
    foodId: string
    names: CartItemName[]
    amount: number
    size: string | null
    image: string
    alternateToppings: string[]
    extraToppings: CartItemExtraTopping[]
    spices: string[]
    tax: number | null
    type?: FoodType
    stock: number | null
    comment: string
    status: CartStatus
    category: string
}

export interface AddCartItemAction {
    type: CartActionTypes.ADD_CART_ITEM
    data: {
        shopId: string
        item: AddToCart
    }
}

export interface UpdateCartItemAction {
    type: CartActionTypes.UPDATE_CART_ITEM
    data: {
        item: AddToCart
        cartId: string
    }
}

export interface RemoveAllCartItemsAction {
    type: CartActionTypes.REMOVE_ALL_CART_ITEMS
}

export interface RemoveCartItemAction {
    type: CartActionTypes.REMOVE_CART_ITEM
    data: string
}

export interface IncreaseCartItemQuantityAction {
    type: CartActionTypes.INCREASE_CART_ITEM_QUANTITY
    data: string
}

export interface DecreaseCartItemQuantityAction {
    type: CartActionTypes.DECREASE_CART_ITEM_QUANTITY
    data: string
}

export interface SyncCartWithDBAction {
    type: CartActionTypes.SYNC_CART_WITH_DB
    data: Food[]
}

export type CartActions = AddCartItemAction | RemoveAllCartItemsAction | IncreaseCartItemQuantityAction | DecreaseCartItemQuantityAction | SyncCartWithDBAction | RemoveCartItemAction | UpdateCartItemAction

export const addToCart = (item: AddToCart, shopId: string): AddCartItemAction => {
    return {
        type: CartActionTypes.ADD_CART_ITEM,
        data: {
            item,
            shopId
        }
    }
}

export const updateCartItem = (item: AddToCart, cartId: string): UpdateCartItemAction => {
    return {
        type: CartActionTypes.UPDATE_CART_ITEM,
        data: {
            cartId: cartId,
            item: item
        }
    }
}

export const increaseCartItemQuantity = (cartId: string): IncreaseCartItemQuantityAction => {
    return {
        type: CartActionTypes.INCREASE_CART_ITEM_QUANTITY,
        data: cartId
    }
}


export const decreaseCartItemQuantity = (cartId: string): DecreaseCartItemQuantityAction => {
    return {
        type: CartActionTypes.DECREASE_CART_ITEM_QUANTITY,
        data: cartId
    }
}

export const removeCartItem = (cartId: string): RemoveCartItemAction => {
    return {
        type: CartActionTypes.REMOVE_CART_ITEM,
        data: cartId
    }
}

export const removeAllCartItems = (): RemoveAllCartItemsAction => {
    return {
        type: CartActionTypes.REMOVE_ALL_CART_ITEMS
    }
}

export const syncCartWithDB = (foodIds: string) => async (dispatch: Dispatch, getState: GetState) => {
    const shopId = getState().cart.shopId

    return api.get<Response<Food[]>>(`foods/in/${foodIds}/${shopId}`).then(response => {
        if (response.status === 200) {
            dispatch<SyncCartWithDBAction>({
                type: CartActionTypes.SYNC_CART_WITH_DB,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || '')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to fetch'))
    })
}