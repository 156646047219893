import React from 'react'
import { CheckoutContext, CheckoutContextType } from '../../context/CheckoutProvider'
import { useT } from '../../i18n'
import FormInput from '../utils/FormInput'
import SectionWithTitle from './SectionWithTitle'

const CheckoutCustomerInformation = () => {
    const checkoutContext = React.useContext<CheckoutContextType | null>(CheckoutContext)
    const t = useT()

    return <SectionWithTitle title={t("CUSTOMER INFORMATION")} className='mb-4' withRow>
        <div className='col-sm-6 col-lg-12 col-xl-6'>
            <FormInput
                containerClass='mb-3'
                type='text'
                label={t('First Name')}
                placeholder={t('First Name')}
                onChange={checkoutContext?.firstNameChangeHandler}
                value={checkoutContext?.firstName || ''}
                errorText={checkoutContext?.firstNameError}
            />
        </div>
        <div className='col-sm-6 col-lg-12 col-xl-6'>
            <FormInput
                containerClass='mb-3'
                type='text'
                label={t('Last Name')}
                placeholder={t('Last Name')}
                onChange={checkoutContext?.lastNameChangeHandler}
                value={checkoutContext?.lastName || ''}
                errorText={checkoutContext?.lastNameError}
            />
        </div>
        <div className='col-sm-6 col-lg-12 col-xl-6'>
            <FormInput
                containerClass='mb-3'
                type='email'
                label={t('Email')}
                placeholder={t('Email')}
                onChange={checkoutContext?.emailChangeHandler}
                value={checkoutContext?.email || ''}
                errorText={checkoutContext?.emailError}
            />
        </div>
        <div className='col-sm-6 col-lg-12 col-xl-6'>
            <FormInput
                containerClass='mb-3'
                type='number'
                label={t('Phone Number')}
                placeholder={t('Phone Number')}
                onChange={checkoutContext?.phoneNumberChangeHandler}
                value={checkoutContext?.phoneNumber || ''}
                errorText={checkoutContext?.phoneNumberError}
            />
        </div>
        {checkoutContext?.orderType === 'home' && <React.Fragment>
            <div className='col-12'>
                <FormInput
                    containerClass='mb-3'
                    type='text'
                    label={t('Address Line 1')}
                    placeholder={t('Address Line 1')}
                    onChange={checkoutContext?.addressLine1ChangeHandler}
                    value={checkoutContext?.addressLine1 || ''}
                    errorText={checkoutContext?.addressLine1Error}
                />
            </div>
            <div className='col-12'>
                <FormInput
                    containerClass='mb-3'
                    type='text'
                    label={t('Address Line 2')}
                    placeholder={t('Address Line 2')}
                    onChange={checkoutContext?.addressLine2ChangeHandler}
                    value={checkoutContext?.addressLine2 || ''}
                    errorText={checkoutContext?.addressLine2Error}
                />
            </div>
            <div className='col-sm-6 col-lg-12 col-xl-6'>
                <FormInput
                    containerClass='mb-3'
                    type='text'
                    label={t('City')}
                    placeholder={t('City')}
                    onChange={checkoutContext?.cityChangeHandler}
                    value={checkoutContext?.city || ''}
                    errorText={checkoutContext?.cityError}
                />
            </div>
            <div className='col-sm-6 col-lg-12 col-xl-6'>
                <FormInput
                    containerClass='mb-3'
                    type='text'
                    label={t('Pincode')}
                    placeholder={t('Pincode')}
                    onChange={checkoutContext?.pincodeChangeHandler}
                    value={checkoutContext?.pincode || ''}
                    errorText={checkoutContext?.pincodeError}
                />
            </div>
            <div className='col-sm-6 col-lg-12 col-xl-6'>
                <FormInput
                    containerClass='mb-3'
                    type='text'
                    label={t('State')}
                    placeholder={t('State')}
                    onChange={checkoutContext?.stateChangeHandler}
                    value={checkoutContext?.state || ''}
                    errorText={checkoutContext?.stateError}
                />
            </div>
            <div className='col-12'>
                <FormInput
                    containerClass='mb-3'
                    type='text'
                    label={t('Additional details (optional)')}
                    placeholder={t('Landmarks, etc.')}
                    onChange={checkoutContext?.landmarkChangeHandler}
                    value={checkoutContext?.landmark || ''}
                    errorText={checkoutContext?.landmarkError}
                />
            </div>
        </React.Fragment>}
    </SectionWithTitle>
}

export default CheckoutCustomerInformation