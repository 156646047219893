import style from '../../styles/components/plus.module.scss'

interface PlusProps {
    onClick?: () => void
}

const Plus = (props: PlusProps) => {
    return <div className={style.container} onClick={props.onClick}>
        <div className={style.line1} />
        <div className={style.line2} />
    </div>
}

export default Plus