import React from 'react'
import style from '../../styles/components/close.module.scss'

interface CloseProps {
    onClose?: React.MouseEventHandler<HTMLDivElement>
    small?: boolean
    white?: boolean
}

const Close = (props: CloseProps) => {
    const small = props.small ? style.small : ''
    const white = props.white ? style.white : ''

    return <div className={`${style.container}`} onClick={props.onClose}>
        <div className={`${style.line1} ${small} ${white}`} />
        <div className={`${style.line2} ${small} ${white}`} />
    </div>
}

export default Close