import React from 'react'
import style from '../../styles/components/tooltip.module.scss'

interface TooltipProps {
    children?: React.ReactNode
}

const Tooltip = (props: TooltipProps) => {
    return <div className={style.tooltip}>
        <div className={style.arrow} />
        {props.children}
    </div>
}

export default Tooltip