import { combineReducers } from 'redux'
import appointmentReducer from './appointment.reducer'
import appointmentCartReducer from './appointmentCart.reducer'
import authAdminReducer from './authAdmin.reducer'
import authDivisionReducer from './authDivision.reducer'
import authSuperAdminReducer from './authSuperAdmin.reducer'
import authUserReducer from './authUser.reducer'
import blogReducer from './blog.reducer'
import bookingReducer from './booking.reducer'
import otherBookingReducer from './otherBooking.reducer'
import bookingReportReducer from './bookingReport.reducer'
import otherBookingReportReducer from './otherBookingReport.reducer'
import cartReducer from './cart.reducer'
import categoryReducer from './category.reducer'
import ContactUsReducer from './contactUs.reducer'
import countReportReducer from './countReport.reducer'
import divisionReducer from './division.reducer'
import eventReducer from './event.reducer'
import eventCartReducer from './eventCart.reducer'
import extraToppingReducer from './extraTopping.reducer'
import favouriteReducer from './favourite.reducer'
import feedbackReducer from './feedback.reducer'
import foodReducer from './food.reducer'
import languageReducer from './language.reducer'
import orderReducer from './order.reducer'
import orderReportReducer from './orderReport.reducer'
import ratingReducer from './rating.reducer'
import reservationReducer from './reservation.reducer'
import shopReducer from './shop.reducer'
import sizeReducer from './size.reducer'
import spiceReducer from './spice.reducer'
import toppingReducer from './topping.reducer'
import translationLanguageReducer from './translationLanguage.reducer'
import userReducer from './user.reducer'
import voucherReducer from './voucher.reducer'
import reservationReportReducer from './reservationReport.reducer'
import appointmentBooking from './appointmentBookings.reducer'
const reducers = combineReducers({
    authAdmin: authAdminReducer,
    authSuperAdmin: authSuperAdminReducer,
    authUser: authUserReducer,
    cart: cartReducer,
    eventCart: eventCartReducer,
    appointmentCart: appointmentCartReducer,
    categories: categoryReducer,
    extraToppings: extraToppingReducer,
    favourites: favouriteReducer,
    foods: foodReducer,
    events: eventReducer,
    languages: languageReducer,
    orders: orderReducer,
    bookings: bookingReducer,
    otherBookings:otherBookingReducer,
    shops: shopReducer,
    sizes: sizeReducer,
    spices: spiceReducer,
    toppings: toppingReducer,
    translationLanguage: translationLanguageReducer,
    vouchers: voucherReducer,
    users: userReducer,
    blog: blogReducer,
    orderReports: orderReportReducer,
    bookingReports: bookingReportReducer,
    otherBookingReports:otherBookingReportReducer,
    feedback: feedbackReducer,
    rating: ratingReducer,
    contactUs: ContactUsReducer,
    authDivision: authDivisionReducer,
    division: divisionReducer,
    countReports: countReportReducer,
    appointment: appointmentReducer,
    reservation: reservationReducer,
    reservationReports: reservationReportReducer,
    appointmentBooking:appointmentBooking
})

export type StoreState = ReturnType<typeof reducers>
export type GetState = () => StoreState

export default reducers