import { Action, AppointmentActionTypes, AppointmentName, OrderActionTypes } from '../actions'
import { AppointmentBookingsActionTypes } from '../actions/appointmentBookings.actions'
import { BookingStatus } from './booking.reducer'
import { OtherPayments } from './shop.reducer'

export type OrderType = 'home' | 'pick_up'
export type PaymentMode = 'card' | 'upi' | 'cash' | 'paytrail' | 'bank-transfer' | 'free'
export type PaymentStatus = 'pending' | 'paid' | 'failed'
export type OrderStatus = 'ordered' | 'processing' | 'ready' | 'delivered'



export interface Buyer {
    firstName: string
    lastName: string
    email: string
    phoneNumber: string
    address: {
        addressLine1: string
        addressLine2: string
        city: string
        pincode: number
        state: string
        landmark: string | null
    }
}

export interface AppointmentBooking {
    id: string
    reservationNumber: string
    reservationDate: string
    appointmentDate: string
    shopId: { id: string, otherPayments: OtherPayments[] }
    user: Buyer
    userId: string
    totalAmount: number
    totalDiscount: number | null
    totalTax: number | null
    billAmount: number
    paymentMode: PaymentMode
    paymentStatus: PaymentStatus
    paidAt: string | null
    reservationStatus: BookingStatus
    fromTime:string
    toTime:string
    totalHours:string
    hourType:string
    additionalInfo: string | null
    createdAt: string
    updatedAt: string
    appointmentId:{id:string,names:AppointmentName[],type:string}
    slotTime:string
}

const initialState: AppointmentBooking[] = []

const appointmentBookingReducer = (state: AppointmentBooking[] = initialState, action: Action): AppointmentBooking[] => {
    switch (action.type) {
        case AppointmentBookingsActionTypes.FETCH_APPOINTMENT_BOOKINGS: {
            return action.data
        }

        case AppointmentBookingsActionTypes.REPLACE_APPOINTMENT_BOOKINGS: {
            const newState = [...state]
            const index = newState.findIndex(s => s.id === action.data.id)

            if (index > -1) {
                newState[index] = action.data
            }

            return newState
        }

        default: {
            return state
        }
    }
}

export default appointmentBookingReducer