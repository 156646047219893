import { useEffect } from 'react'
import { Carousel } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import ShopDetails from '../../components/sections/ShopDetails'
import ShopProductDetails from '../../components/sections/ShopProductDetails'
import UserLayout from '../../components/sections/UserLayout'
import Button from '../../components/utils/Button'
import ShopProvider from '../../context/ShopProvider'
import { fetchShops } from '../../redux/actions'
import { StoreState } from '../../redux/reducers'
import { Shop as Shops } from '../../redux/reducers/shop.reducer'
import { AppDispatch } from '../../redux/store'
import style from '../../styles/pages/defaultShop.module.scss'

export type ShopURLParams = {
    shopId: string
}

const Shop = () => {
    const { shopId } = useParams<ShopURLParams>()
    const shops = useSelector<StoreState, Shops | null>(state => state.shops.find(s => s.id === shopId) || null);
    const dispatch = useDispatch<AppDispatch>()

    const slides = shops?.theme?.slides || [];

    const ProductLinkHandler = (productUrl: string | URL | undefined) => {
        if (productUrl) {
            window.open(productUrl.toString(), '_blank');
        } else {
            console.error('Product URL is undefined');
        }
    };

    useEffect(() => {
        dispatch(fetchShops())
    }, [dispatch])

    return (
        <UserLayout>
            {shopId && (
                <ShopProvider shopId={shopId}>
                    {(!slides || slides.length === 0) && <ShopDetails />}
                    {slides && slides.length > 0 && (
                        <Carousel className={style.customCarousel} fade={true} slide={false}>
                            {slides.map((slide, index) => (
                                <Carousel.Item key={index} interval={1000}>
                                    <div className={style.imageWrapper}>
                                        <img
                                            src={slide.image}
                                            alt={`${index} slide`}
                                            onError={(e) => {
                                                const target = e.target as HTMLImageElement;
                                                target.style.display = 'none';
                                            }}
                                        />
                                    </div>
                                    <Carousel.Caption>
                                        <h3 className={style.caption}>
                                            {slide.title}
                                        </h3>
                                        {slide.description !== "" && (
                                            <Button size='sm' onClick={() => ProductLinkHandler(slide.productUrl)}>
                                                {slide.description}
                                            </Button>
                                        )}
                                    </Carousel.Caption>
                                </Carousel.Item>
                            ))}
                        </Carousel>

                    )}
                    <ShopProductDetails />
                </ShopProvider>
            )}
        </UserLayout>
    )
}

export default Shop
