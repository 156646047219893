/* eslint-disable no-throw-literal */
import { Dispatch } from 'redux'
import { AddSizesAction, SizeActionTypes } from '.'
import { api, apiErrorHandler, getApiRequestConfig, Response } from '../../api'
import { GetState } from '../reducers'
import { ExtraTopping } from '../reducers/extraTopping.reducer'
import { AddLanguagesAction, LanguageActionTypes } from './language.actions'

export enum ExtraToppingActionTypes {
    ADD_EXTRA_TOPPING = 'ADD_EXTRA_TOPPING',
    UPDATE_EXTRA_TOPPING = 'UPDATE_EXTRA_TOPPING',
    FETCH_SHOP_EXTRA_TOPPINGS = 'FETCH_SHOP_EXTRA_TOPPINGS'
}

export interface AddExtraToppingAction {
    type: ExtraToppingActionTypes.ADD_EXTRA_TOPPING
    data: ExtraTopping
}

export interface UpdateExtraToppingAction {
    type: ExtraToppingActionTypes.UPDATE_EXTRA_TOPPING
    data: ExtraTopping
}

export interface FetchShopExtraToppingsAction {
    type: ExtraToppingActionTypes.FETCH_SHOP_EXTRA_TOPPINGS
    data: ExtraTopping[]
}

export type ExtraToppingActions = AddExtraToppingAction | UpdateExtraToppingAction | FetchShopExtraToppingsAction

interface ExtraToppingRequestName {
    language: string
    name: string
}

interface ExtraToppingRequestType {
    language: string
    type: string
}

interface ExtraToppingRequestPrice {
    price: number
    size: string
}

interface AddExtraTopping {
    names: ExtraToppingRequestName[]
    types: ExtraToppingRequestType[]
    prices: ExtraToppingRequestPrice[]
}

export const addExtraTopping = (input: AddExtraTopping) => async (dispatch: Dispatch, getState: GetState) => {
    const authAdmin = getState().authAdmin
    const config = getApiRequestConfig(authAdmin?.['auth-token'])

    return api.post<Response<ExtraTopping>>('extra-topping', input, config).then(response => {
        if (response.status === 200) {
            dispatch<AddExtraToppingAction>({
                type: ExtraToppingActionTypes.ADD_EXTRA_TOPPING,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || 'Added')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to add'))
    })
}

interface UpdateExtraTopping {
    names: ExtraToppingRequestName[]
    types: ExtraToppingRequestType[]
    prices: ExtraToppingRequestPrice[]
}

export const updateExtraTopping = (input: UpdateExtraTopping, extraToppingId: string) => async (dispatch: Dispatch, getState: GetState) => {
    const authAdmin = getState().authAdmin
    const config = getApiRequestConfig(authAdmin?.['auth-token'])

    return api.put<Response<ExtraTopping>>(`extra-topping/${extraToppingId}`, input, config).then(response => {
        if (response.status === 200) {
            dispatch<UpdateExtraToppingAction>({
                type: ExtraToppingActionTypes.UPDATE_EXTRA_TOPPING,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || 'Updated')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to update'))
    })
}

export const fetchShopExtraToppings = () => async (dispatch: Dispatch, getState: GetState) => {
    const authAdmin = getState().authAdmin
    const config = getApiRequestConfig(authAdmin?.['auth-token'])

    return api.get<Response<ExtraTopping[]>>('extra-toppings', config).then(response => {
        if (response.status === 200) {
            dispatch<FetchShopExtraToppingsAction>({
                type: ExtraToppingActionTypes.FETCH_SHOP_EXTRA_TOPPINGS,
                data: response.data.data
            })

            if (getState().authAdmin) {
                const sizes: string[] = []
                const languages: string[] = []

                response.data.data.forEach(d => {
                    d.prices && d.prices.forEach(p => {
                        sizes.push(p.size)
                    })

                    d.names && d.names.forEach(p => {
                        languages.push(p.language)
                    })
                })

                dispatch<AddSizesAction>({
                    type: SizeActionTypes.ADD_SIZES,
                    data: sizes
                })

                dispatch<AddLanguagesAction>({
                    type: LanguageActionTypes.ADD_LANGUAGES,
                    data: languages
                })
            }

            return Promise.resolve<string>(response.data.message || '')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to fetch'))
    })
}