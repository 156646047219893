import Card from './Card'

interface HomeSectionCardProps {
    img?: string
    rating?: number
    name?: string
    subText?: string
    locationPrefix?: string
    locationSuffix?: string
    to?: string
    itemId?: string
}

const HomeSectionCard = (props: HomeSectionCardProps) => {
    // const dispatch = useDispatch<AppDispatch>()
    // const shopRatings = useSelector<StoreState, Rating[]>(state => state.rating)

    // const TotalAvgShopRating = useMemo<number>(() => {
    //     let sum = shopRatings.reduce((prev, current) => {
    //         return prev + current.rating
    //     }, 0)
    //     const value = sum / shopRatings.length
    //     return shopRatings.length > 0 ? value : 0
    // }, [shopRatings, props.itemId])

    // useEffect(() => {
    //     if (props.itemId) {
    //         dispatch(fetchRatings(props.itemId))
    //     }
    // }, [props.itemId])

    return <Card
        img={props.img}
        rating={props.rating}
        name={props.name}
        subText={props.subText}
        locationPrefix={props.locationPrefix}
        locationSuffix={props.locationSuffix}
        to={props.to}
        itemId={props.itemId}
        from={false}
    />
}

export default HomeSectionCard