import React, { useEffect, useMemo, useState } from 'react'
import { CSVLink } from 'react-csv'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { useToast } from '../../components/ToastProvider'
import Confetti from '../../components/lottie/Confetti'
import EventRegisterModal from '../../components/modals/EventRegisterModal'
import QRCodeScanner from '../../components/modals/QrCodeScanner'
import AdminLayout from '../../components/sections/AdminLayout'
import AdminPanelHeader from '../../components/sections/AdminPanelHeader'
import AdminOtherReservation from '../../components/sections/OtherReservation'
import AdminReservation from '../../components/sections/Reservation'
import { QrCode } from '../../components/svgs/QrCode'
import Button from '../../components/utils/Button'
import Search from '../../components/utils/Search'
import Select from '../../components/utils/Select'
import Spinner from '../../components/utils/Spinner'
import { AdminContext, AdminContextType } from '../../context/AdminProvider'
import { AppContext } from '../../context/AppProvider'
import { PersonTypes } from '../../context/CheckoutProvider'
import { useT } from '../../i18n'
import { resendEamilBookingPayment, resendEamilOtherBookingPayment, updateShopBookingParticipantStatus, updateShopBookingPayment, updateShopOtherBookingParticipantStatus, updateShopOtherBookingPayment } from '../../redux/actions'
import { StoreState } from '../../redux/reducers'
import { AuthAdmin } from '../../redux/reducers/authAdmin.reducer'
import { Booking } from '../../redux/reducers/booking.reducer'
import { Event as EventType } from '../../redux/reducers/event.reducer'
import { OtherBooking } from '../../redux/reducers/otherBooking.reducer'
import { AppDispatch } from '../../redux/store'
import styleProduct from '../../styles/components/shop-product-details.module.scss'
import style from '../../styles/pages/admin-orders.module.scss'
import { date } from '../../utils'

type BookingMenu = 'BOOKED' | 'ATTENDED' | 'CANCELLED' | 'PAYMENT_PENDING' | 'FAILED'

const getBookingMenu = (booking: Booking): BookingMenu | null => {
    if (booking.paymentStatus === 'pending' && booking.bookingStatus !== "cancelled" && booking.paymentMode !== "free") {
        return 'PAYMENT_PENDING'
    }

    if (booking.bookingStatus === 'booked') {

        if (booking.shopId.otherPayments.findIndex(x => x.title === booking.paymentMode) !== -1) {
            return 'BOOKED'
        }

        if (booking.paymentMode === 'paytrail' && booking.paymentStatus === 'paid') {
            return 'BOOKED'
        }

        if (booking.paymentMode === 'free') {
            return 'BOOKED'
        }
    }
    if (booking.bookingStatus === 'attended') {
        return 'ATTENDED'
    }

    if (booking.bookingStatus === 'cancelled') {
        return 'CANCELLED'
    }

    if (booking.bookingStatus === 'failed') {
        return 'FAILED'
    }

    return null
}

const filterBookingHandler = (booking: Booking, searchTerm: string) => {
    return searchTerm !== '' ? new RegExp(`^${searchTerm}|${searchTerm}`, 'gi').test(booking.bookingNumber + booking.bookingDetails.map((bd) => bd.title).join(',') + booking.user.firstName + booking.user.lastName) : true || []
}

const Reservations = () => {
    const adminContext = React.useContext<AdminContextType | null>(AdminContext)
    const appContext = React.useContext(AppContext);
    const authAdmin = useSelector<StoreState, AuthAdmin | null>(state => state.authAdmin)
    const t = useT()
    const toast = useToast()
    const [showAnimation, setShowAnimation] = React.useState<boolean>(false)
    const [selectedUser, setSelectedUser] = useState<string>("vshop_user")
    const [pages, setPages] = React.useState(Array.from({ length: 0 }))
    const [showModal, setShowModal] = React.useState<boolean>(false)
    const [bookingId, setBookingId] = React.useState('')
    const [bookingObj, setBookingObj] = React.useState<Booking | null>(null)
    const [bookingDetails, setBookingDetails] = React.useState<any>(null)
    const [hasMore, setHasMore] = React.useState(true)
    const [width, setWidth] = React.useState<number>(window.innerWidth);
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    React.useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    const isMobile = width <= 760


    const dispatch = useDispatch<AppDispatch>();
    const updateStatusClickHandler = (data: any) => {
        appContext?.showOverlay?.()

        selectedUser === "vshop_user" ?
            dispatch(updateShopBookingParticipantStatus(data, bookingId)).finally(() => {
                setShowModal(false);
                setShowAnimation(true);
                setBookingMenu("ATTENDED")
                appContext?.hideOverlay?.()
            })
            :
            dispatch(updateShopOtherBookingParticipantStatus(data, bookingId)).finally(() => {
                setShowModal(false);
                setShowAnimation(true);
                setBookingMenu("ATTENDED")
                appContext?.hideOverlay?.()
            })
    }
    const updatePaymentClickHandler = (status: string, bookingId: string) => {
        appContext?.showOverlay?.()
        selectedUser === "vshop_user" ?
            dispatch(updateShopBookingPayment(status, authAdmin?.shop.id, bookingId)).finally(() => {
                appContext?.hideOverlay?.()
            })
            :
            dispatch(updateShopOtherBookingPayment(status, authAdmin?.shop.id, bookingId)).finally(() => {
                appContext?.hideOverlay?.()
            })
    }

    const resendEmailClickHandler = (bookingId: string) => {
        appContext?.showOverlay?.()
        selectedUser === "vshop_user" ?
            dispatch(resendEamilBookingPayment(authAdmin?.shop.id, bookingId)).finally(() => {
                appContext?.hideOverlay?.()
            })
            :
            dispatch(resendEamilOtherBookingPayment(authAdmin?.shop.id, bookingId)).finally(() => {
                appContext?.hideOverlay?.()
            })
    }
    const events = useSelector<StoreState, EventType[]>(state => state.events)
    const bookings = useSelector<StoreState, Booking[]>(state => state.bookings)
    const otherBookings = useSelector<StoreState, OtherBooking[]>(state => state.otherBookings)

    const mainContentRef = React.useRef<HTMLDivElement>(null)
    const searchRef = React.useRef<HTMLInputElement>(null)
    const [searchQuery, setSearchQuery] = useSearchParams()
    const [selectedEvent, setSelectedEvent] = useState<string>("")
    const [withoutRegistration, setWithoutRegistraion] = useState<string>('No');

    const searchTerm = searchQuery.get('search') || ''
    const [bookingMenu, setBookingMenu] = React.useState<BookingMenu | null>('PAYMENT_PENDING')
    const [openQrScan, setOpenQrScan] = React.useState(false);
    const [scanResult, setScanResult] = React.useState<any>(null);
    const filteredBookings: any = selectedUser === "vshop_user" ? bookings.filter(booking => booking?.eventId?.id === selectedEvent) : otherBookings.filter(booking => booking?.eventId?.id === selectedEvent)
    const searchTermChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchQuery({ search: e.target.value })
    }
    React.useEffect(() => {
        if (searchTerm !== '') {
            searchRef.current?.focus()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const openQrScanner = () => {
        setOpenQrScan(true)
    }
    React.useEffect(() => {
        if (scanResult) {
            const booking: any = selectedUser === "vshop_user" ? bookings.filter(booking => booking?.eventId?.id === scanResult?.eventId && booking?.bookingNumber === scanResult?.bookingNumber)[0] : otherBookings.filter(booking => booking?.eventId?.id === scanResult?.eventId && booking?.bookingNumber === scanResult?.bookingNumber)[0]
            setBookingObj(booking)
            setBookingDetails(booking.bookingDetails.filter((bd: any) => bd.status === false))
            setBookingId(scanResult?.bookingId)
            setSelectedEvent(scanResult?.eventId);
            setSearchQuery({ search: scanResult?.bookingNumber })
            setShowModal(true);
        }

    }, [scanResult])

    useEffect(() => {
        if (selectedEvent) {
            const event = events.filter((event) => event.id === selectedEvent)[0];
            if (event.withoutRegistration)
                setWithoutRegistraion(event.withoutRegistration)
        }
    }, [selectedEvent])

    const fetchMoreData = () => {
        if (pages.length <= filteredBookings.length) {
            setTimeout(() => {
                setPages(pages.concat(Array.from({ length: 20 })))
            }, 1000)
            return
        }
        setHasMore(false)
    }

    React.useEffect(() => {
        fetchMoreData()
    }, [])

    const data = useMemo<string[][]>(() => {
        let arr: string[][] = []
        const numberOfUsers = (users: PersonTypes[]) => {
            const persons = users?.map((x: any) => x.count).reduce((a, b) => a + b, 0)
            return persons;
        }
        selectedUser === "vshop_user" ?
            bookings.filter((f) => selectedEvent !== "" ? f.eventId?.id?.toString() === selectedEvent?.toString() : f).forEach((u: any, i) => {
                u.bookingDetails.map((bd: any) => {
                    const paymentMode = u.cultureVoucher === "no" ? u.paymentMode : u.cultureVoucher;
                    return arr.push([date('D-Mn-Y', u.createdAt), u.bookingNumber, numberOfUsers(u.personTypes), bd.title, bd.size.toString(), u.user.phoneNumber, u.user.email, bd.foodPreference, paymentMode, u.bookingStatus, u.paymentStatus, u.volunteers.join(',')])
                })
            }) :
            otherBookings.filter((f) => selectedEvent !== "" ? f.eventId?.id?.toString() === selectedEvent?.toString() : f).forEach((u: any, i) => {
                u.bookingDetails.map((bd: any) => {
                    const paymentMode = u.cultureVoucher === "no" ? u.paymentMode : u.cultureVoucher;
                    return arr.push([date('D-Mn-Y', u.createdAt), u.bookingNumber, numberOfUsers(u.personTypes), bd.title, bd.size.toString(), u.user.phoneNumber, u.user.email, bd.foodPreference, paymentMode, u.bookingStatus, u.paymentStatus, u.volunteers.join(',')])
                })
            })
        return arr
    }, [bookings, selectedEvent, selectedUser])

    const csvData = [
        ["Registred Date", "Booking Number", "Number of participant", "Name", "Registrant Tag", "Phone Number", "Email", "Food Preference", "Payment Method", "Booking Status", "PaymentStatus", "Volunteering"],
        ...data
    ];

    const bookingStatusChange = (status: boolean, index: number) => {
        if (bookingDetails)
            setBookingDetails(bookingDetails.map((bd: any, i: number) => i === index ? { ...bd, status } : bd))
    }

    const updateStatusHandler = () => {
        const data = {
            bookingDetails: bookingObj?.bookingDetails?.filter((bd) => bd.status === true).map((bd: any) => { return bd }).concat(bookingDetails),
            bookingStatus: "attended"
        }
        updateStatusClickHandler(data)
    }

    const updateBookingStatusHandler = (status: string, bookingId: string) => {
        const booking: any = selectedUser === "vshop_user" ? bookings.filter(booking => booking?.eventId?.id === selectedEvent && booking?.id === bookingId)[0] : otherBookings.filter(booking => booking?.eventId?.id === selectedEvent && booking?.id === bookingId)[0];
        setBookingObj(booking)
        setBookingDetails(booking.bookingDetails.filter((bd: any) => bd.status === false))
        setBookingId(bookingId);
        setSelectedEvent(selectedEvent);
        setShowModal(true);
    }

    return <AdminLayout>
        <div className='mb-4 mt-2'>
            <AdminPanelHeader title={t('Reservations')} />
            {showAnimation && <Confetti onClose={() => setShowAnimation(false)} />}
            {openQrScan && <QRCodeScanner openQrScan={openQrScan} closeQrCode={setOpenQrScan} setScanResult={setScanResult} />}
            {showModal && <EventRegisterModal bookingDetails={bookingDetails} onSave={updateStatusHandler} onClose={() => setShowModal(false)} booking={bookingObj} bookingStatusChangeHandler={bookingStatusChange} />}
            {/* <div className='d-flex align-items-center justify-content-between mb-3'>
                <Select
                    containerClass='mb-3'
                    value={selectedEvent}
                    placeholder={t('Select Event')}
                    options={events.map((event) => { return { text: event.names[0].name, value: event.id } })}
                    onChange={(e) => setSelectedEvent(e.target.value)}
                />
                <div className='hstack gap-2'>
                    <span onClick={openQrScanner} className='mb-3 px-5' style={{ cursor: "pointer" }}><QrCode width={isMobile?150:null} height={isMobile?150:null} /></span>
                    <span className='d-flex align-items-center mb-3 justify-content-end'>
                        <CSVLink data={csvData} filename={"UserDetail"}><Button>Download CSV</Button></CSVLink>
                    </span>
                </div>
            </div> */}
            <div className='row'>
                <div className={`col-lg-${withoutRegistration === "Yes" ? '4' : '6'} order-2 order-lg-2`}>
                    <Select
                        containerClass='mb-3'
                        value={selectedEvent}
                        placeholder={t('Select Event')}
                        options={events.map((event) => { return { text: event.names[0].name, value: event.id } })}
                        onChange={(e) => setSelectedEvent(e.target.value)}
                    />
                </div>
                {withoutRegistration === "Yes" &&
                    <div className='col-lg-4 order-2 order-lg-2'>
                        <Select
                            containerClass='mb-3'
                            value={selectedUser}
                            placeholder={t('Select User')}
                            options={[{ text: 'Vshops User', value: 'vshop_user' }, { text: 'Non User', value: 'non_user' }]}
                            onChange={(e) => setSelectedUser(e.target.value)}
                        />
                    </div>
                }
                <div className='col-lg-2 order-3 order-lg-2 mb-3 text-end'>
                    <CSVLink data={csvData} filename={"Reservation reports"}><Button className={style.csvDownloadBtn}>{t("Download CSV")}</Button></CSVLink>
                </div>
                <div className='col-lg-2 order-1 order-lg-2 mb-3 mt-2 text-start'>
                    <span onClick={openQrScanner} className='mb-2 px-5' style={{ cursor: "pointer" }}><QrCode width={isMobile ? 150 : null} height={isMobile ? 150 : null} /></span>
                </div>
            </div>

            {filteredBookings.length !== 0 &&
                <React.Fragment>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-2 col-xl-2 mb-2'>
                            <OrderMenuLink active={bookingMenu === 'PAYMENT_PENDING'} onClick={() => setBookingMenu('PAYMENT_PENDING')}>{t("PAYMENT PENDING")} ({filteredBookings.reverse().filter((booking: any) => getBookingMenu(booking) === "PAYMENT_PENDING").length})</OrderMenuLink>
                        </div>
                        <div className='col-sm-12 col-lg-2 col-xl-2 mb-2'>
                            <OrderMenuLink active={bookingMenu === 'BOOKED'} onClick={() => setBookingMenu('BOOKED')}>{t("BOOKED")} ({filteredBookings.reverse().filter((booking: any) => getBookingMenu(booking) === "BOOKED").length})</OrderMenuLink>
                        </div>
                        <div className='col-sm-12 col-lg-2 col-xl-2 mb-2'>
                            <OrderMenuLink active={bookingMenu === 'ATTENDED'} onClick={() => setBookingMenu('ATTENDED')}>{t("ATTENDED")} ({filteredBookings.reverse().filter((booking: any) => getBookingMenu(booking) === "ATTENDED").length})</OrderMenuLink>
                        </div>
                        <div className='col-sm-12 col-lg-2 col-xl-2 mb-2'>
                            <OrderMenuLink active={bookingMenu === 'CANCELLED'} onClick={() => setBookingMenu('CANCELLED')}>{t("CANCELLED")} ({filteredBookings.reverse().filter((booking: any) => getBookingMenu(booking) === "CANCELLED").length})</OrderMenuLink>
                        </div>
                        <div className='col-sm-12 col-lg-2 col-xl-2 mb-2'>
                            <OrderMenuLink active={bookingMenu === 'FAILED'} onClick={() => setBookingMenu('FAILED')}>{t("FAILED")} ({filteredBookings.reverse().filter((booking: any) => getBookingMenu(booking) === "FAILED").length})</OrderMenuLink>
                        </div>
                    </div>
                    <br />
                    <div className={styleProduct.searchInputContainer}>
                        <Search
                            onChange={searchTermChangeHandler}
                            placeholder='Search...'
                            value={searchTerm}
                            onFocus={() => {
                                mainContentRef?.current?.scrollTo({
                                    behavior: 'smooth',
                                    top: 270
                                })
                            }}
                            innerRef={searchRef}
                        />
                    </div>
                </React.Fragment>
            }
            {adminContext?.fetchingOrders
                ? <Spinner size={25} color='#ec3b50' className='mt-3' />
                :
                filteredBookings.length !== 0 && <InfiniteScroll dataLength={pages.length} scrollableTarget="scrollableDiv" next={fetchMoreData} hasMore={hasMore} loader={<p className={style.infiniteScrollText}>{filteredBookings?.filter((booking: any) => getBookingMenu(booking) === bookingMenu).length > 0 ? t("Fetching reservations...") : t("No reservations here!")}</p>} endMessage={<p className={style.infiniteScrollText}>{t("All reservations fetched")}</p>}>
                    {filteredBookings.reverse().filter((booking: any) => getBookingMenu(booking) === bookingMenu && filterBookingHandler(booking, searchTerm)).map((booking: any) => {
                        if (selectedUser === "vshop_user")
                            return <AdminReservation
                                key={booking.id}
                                booking={booking}
                                role='ADMIN'
                                resendEmailHandler={resendEmailClickHandler}
                                updateBooking={bookingMenu === 'PAYMENT_PENDING' ? updatePaymentClickHandler : updateBookingStatusHandler}
                                statusLable={{ text: bookingMenu === 'PAYMENT_PENDING' ? t('Payment Verify') : t('Attend'), value: bookingMenu === 'PAYMENT_PENDING' ? t('paid') : t('attended') }}
                            />
                        if (selectedUser === "non_user")
                            return <AdminOtherReservation
                                key={booking.id}
                                booking={booking}
                                role='ADMIN'
                                resendEmailHandler={resendEmailClickHandler}
                                updateBooking={bookingMenu === 'PAYMENT_PENDING' ? updatePaymentClickHandler : updateBookingStatusHandler}
                                statusLable={{ text: bookingMenu === 'PAYMENT_PENDING' ? t('Payment Verify') : t('Attend'), value: bookingMenu === 'PAYMENT_PENDING' ? t('paid') : t('attended') }}
                            />
                    })}
                </InfiniteScroll>
            }
        </div>
    </AdminLayout>
}

interface MenuLinkProps {
    children?: React.ReactNode
    active?: boolean
    onClick?: () => void
}

const OrderMenuLink = (props: MenuLinkProps) => {
    return <div className={`${style.orderMenuLink} ${props.active ? style.active : ''}`} children={props.children} onClick={props.onClick} />
}

export default Reservations