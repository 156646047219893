import React from 'react'
import { useSelector } from 'react-redux'
import BlogCard from '../../components/cards/BlogCard'
import AdminPanelHeader from '../../components/sections/AdminPanelHeader'
import SuperAdminLayout from '../../components/sections/SuperAdminLayout'
import { SuperAdminContext } from '../../context/SuperAdminProvider'
import { useT } from '../../i18n'
import { StoreState } from '../../redux/reducers'
import { Blog } from '../../redux/reducers/blog.reducer'

const Blogs = () => {
    const t = useT()
    const superAdminContext = React.useContext(SuperAdminContext)
    const blogList = useSelector<StoreState, Array<Blog>>((state => state.blog))

    return <SuperAdminLayout>
        <div className='row mb-4'>
            <AdminPanelHeader
                btnText={t('Add Blog')}
                title={t('Blogs')}
                onBtnClick={superAdminContext?.onAddBlog}
            />
        </div>
        {blogList.map(blog => {

            return <BlogCard
                blog={blog}

            />
        })}

    </SuperAdminLayout>
}

export default Blogs