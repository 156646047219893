import { Action, ShopActionTypes } from '../actions'
import { Rating } from './rating.reducer'

export type ShopCategory = 'Booking' | 'Event' | 'Food' | 'General' | 'Product' | 'Software' | 'Weekly Subscription'
export type ShopCurrency = 'EUR' | 'SEK'
export type AdminPlan = 'Starter' | 'Economy' | 'Standard' | 'Premium'

export interface ShopAddress {
    addressLine1: string
    addressLine2: string
    city: string
    pincode: number
    state: string
    landmark: string | null
}

export interface Paytrail {
    merchantId: string | null
    secretKey: string | null
    commission: string | null
    paytrailType: string | null
    status: string | null
}

export interface Slide {
    id: string
    image: string
    description?: string
    title?: string
    productUrl: string
    createdAt: string
    updatedAt: string
}

export interface Pickup {
    id?: string
    addressLine1?: string
    addressLine2?: string
    state?: string
    city?: string
    pincode?: string
    phoneNumber?: string
    landmark?: string
    createdAt?: string
    updatedAt?: string
}

export interface Theme {
    bannerImage: string | null
    primaryColor: string | null
    secondaryColor: string | null
    slides: Slide[]
}

interface Price {
    worsePrice: string | null
    bestPrice: string | null
}

export interface PreviewTheme {
    animation: string | null
    fontStyle: string | null
    backgroundColor: string | null
    color: string | null
    priceColor: Price
}

export interface LateNightDeliveryTiming {
    from: string
    to: string
}

export interface LateNightDelivery {
    chargeForEveryKm: number
    timing: LateNightDeliveryTiming
}

export interface DeliveryCharge {
    upToGivenDistance: number
    forEveryKmAboveGivenDistance: number
}

export interface DeliveryDistance {
    from: number
    to: number
}

export interface BankDetails {
    accountName: string | null
    accountNumber: string | null
    bankName: string | null
}

export interface OtherPayments {
    title: string,
    detail: string,
    transactionNeeded?: boolean
}

export interface DeliveryAPI {
    merchantId: string | null
    authToken: string | null
}

export interface OpeningAndClosingTime {
    opening: string
    closing: string
}


export interface Feedback {
    id: string
    userId: string
    feedback: string
    status: string
}

export interface Timing {
    sunday: OpeningAndClosingTime
    monday: OpeningAndClosingTime
    tuesday: OpeningAndClosingTime
    wednesday: OpeningAndClosingTime
    thursday: OpeningAndClosingTime
    friday: OpeningAndClosingTime
    saturday: OpeningAndClosingTime
}

export interface OrderValueForFreeDelivery {
    upToGivenDistance: number
    aboveGivenDistance: number
}

export interface Delivery {
    timing: Timing
    enabled: boolean
    api: DeliveryAPI
    distance: DeliveryDistance
    charge: DeliveryCharge
    lateNightDelivery: LateNightDelivery
    orderValueForFreeDelivery: OrderValueForFreeDelivery
    dates: string[]
}

export interface Shop {
    shopNumber: number
    divisionId?: string
    name: string
    countryCode: string
    currency: string
    plan?: AdminPlan
    description: string
    category: ShopCategory
    otherPayments: OtherPayments[]
    paytrail: Paytrail
    phoneNumber: string | null
    businessName: string | null
    address: ShopAddress
    active: boolean
    delivery: Delivery
    tax: number
    createdAt: string
    updatedAt: string
    vatId: string | null
    theme: Theme
    previewTheme?: PreviewTheme
    shopTiming: Timing
    processTimes: number[]
    printCopy: string | null
    featured: boolean
    private: boolean
    id: string
    admins?: [{ name: string, username: string, id: string }]
    feedbacks?: Feedback[]
    ratings?: Rating[]
    pickup?: Pickup[]
}

const initialState: Shop[] = []

const shopReducer = (state: Shop[] = initialState, action: Action): Shop[] => {
    const newState = [...state]

    switch (action.type) {
        case ShopActionTypes.ADD_SHOP: {
            return [
                ...state,
                action.data
            ]
        }

        case ShopActionTypes.FETCH_SHOPS: {
            return action.data
        }

        case ShopActionTypes.SET_TO_FEATURED_SHOP: {
            const index = newState.findIndex(s => s.id === action.data)

            if (index > -1) {
                newState[index].featured = true
            }

            return newState
        }

        case ShopActionTypes.REMOVE_FROM_FEATURED_SHOP: {
            const index = newState.findIndex(s => s.id === action.data)

            if (index > -1) {
                newState[index].featured = false
            }

            return newState
        }

        case ShopActionTypes.SET_TO_PRIVATE_SHOP: {
            const index = newState.findIndex(s => s.id === action.data)

            if (index > -1) {
                newState[index].private = true
            }

            return newState
        }

        case ShopActionTypes.REMOVE_FROM_PRIVATE_SHOP: {
            const index = newState.findIndex(s => s.id === action.data)

            if (index > -1) {
                newState[index].private = false
            }

            return newState
        }


        default: {
            return state
        }
    }
}

export default shopReducer