import React from 'react'
import { useSelector } from 'react-redux'
import VoucherCard, { VoucherCardProps } from '../../components/cards/VoucherCard'
import AdminLayout from '../../components/sections/AdminLayout'
import AdminPanelHeader from '../../components/sections/AdminPanelHeader'
import { AdminContext } from '../../context/AdminProvider'
import { useT } from '../../i18n'
import { StoreState } from '../../redux/reducers'
import { Voucher as VoucherType } from '../../redux/reducers/voucher.reducer'

const Vouchers = () => {
    const t = useT()
    const adminContext = React.useContext(AdminContext)
    const vouchers = useSelector<StoreState, VoucherType[]>(state => state.vouchers)

    return <AdminLayout>
        <div className='row mb-4'>
            <AdminPanelHeader
                btnText={t('Add Voucher')}
                title={t('Vouchers')}
                onBtnClick={adminContext?.onAddVoucher}
            />
            {vouchers.map(voucher => {
                return <Voucher
                    key={voucher.id}
                    voucher={voucher}
                />
            })}
        </div>
    </AdminLayout>
}

const Voucher = (props: VoucherCardProps) => {
    return <div className='col-md-6 col-xl-4 my-2 d-flex align-items-center justify-content-center'>
        <VoucherCard
            voucher={props.voucher}
        />
    </div>
}

export default Vouchers