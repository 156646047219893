import React from 'react'
import style from '../../styles/components/section-with-title.module.scss'
import Switch from '../utils/Switch'

interface SettingsSectionProps {
    title?: string
    children?: React.ReactNode | React.ReactNode[]
    className?: string
    titleClassName?: string
    withRow?: boolean
    toggleSwitch?: () => void
    switchState?: boolean
    switchLabel?: string
}

const SectionWithTitle = (props: SettingsSectionProps) => {
    return <div className={props.className}>
        <div className='container-fluid p-0'>
            <div className='hstack mb-3 gap-4'>
                <div className={`${style.title} ${props.titleClassName || ''}`}>{props.title}</div>
                {props.toggleSwitch && <Switch
                    onClick={props.toggleSwitch}
                    checked={props.switchState}
                    label={props.switchLabel}
                />}
            </div>
            {props.withRow
                ? <div className='row'>
                    {props.children}
                </div>
                : props.children}
        </div>
    </div>
}

export default SectionWithTitle