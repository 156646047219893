import { t } from 'i18next'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { ShopContext, ShopContextType } from '../../context/ShopProvider'
import { useT } from '../../i18n'
import { addFavourite, fetchRatings, removeFavourite } from '../../redux/actions'
import { StoreState } from '../../redux/reducers'
import { Event } from '../../redux/reducers/event.reducer'
import { Favourite } from '../../redux/reducers/favourite.reducer'
import { Rating } from '../../redux/reducers/rating.reducer'
import { Shop, Timing } from '../../redux/reducers/shop.reducer'
import { AppDispatch } from '../../redux/store'
import style from '../../styles/components/shop-details.module.scss'
import { to12Hour, toCurrencyFormat } from '../../utils'
import ModalWithHeader from '../modals/ModalWithHeader'
import ReadReview from '../modals/ReadReview'
import Info from '../svgs/Info'
import IosHeart from '../svgs/IosHeart'
import LocationOn from '../svgs/LocationOn'
import StarDark from '../svgs/StarDark'
import Whatsapp from '../svgs/Whatsapp'
import OfferLabel from '../utils/OfferLabel'
import SectionWithTitle from './SectionWithTitle'

const ShopDetails = () => {
    const shopContext = React.useContext<ShopContextType | null>(ShopContext)
    const dispatch = useDispatch<AppDispatch>()

    const event = useSelector<StoreState, Event[]>(state => state.events)
    const shopRatings = useSelector<StoreState, Rating[]>(state => state.rating)

    const shop = useSelector<StoreState, Shop | null>(state => state.shops.find(s => s.id === shopContext?.shopId) || null)
    const favourite = useSelector<StoreState, Favourite | null>(state => state.favourites.find(fav => fav.shopId === shopContext?.shopId && fav.type === 'shop') || null)

    const [showShopInformation, setShowShopInformation] = React.useState<boolean>(false)
    const [showVoucher, setShowVoucher] = useState<boolean>(true);
    const [showShopReviews, setShowShopReviews] = useState<boolean>(false);

    const TotalAvgShopRating = useMemo<number>(() => {
        let sum = shopRatings.reduce((prev, current) => {
            return prev + current.rating
        }, 0)
        const value = sum / shopRatings.length
        return value
    }, [shopRatings])

    const reviewsClickHandler = () => {
        setShowShopReviews(!showShopReviews)
    }

    const onCloseHandler = () => {
        setShowShopReviews(false)
    }

    const favouriteClickHandler: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
        e.preventDefault()
        if (shopContext?.shopId) {
            if (favourite) {
                dispatch(removeFavourite({
                    shopId: shopContext?.shopId,
                    type: 'shop'
                }))
            } else {
                dispatch(addFavourite({
                    shopId: shopContext?.shopId,
                    type: 'shop'
                }))
            }
        }
    }
    function gmapHandler() {
        window.open("https://www.google.com/maps/place/" + shop?.address.addressLine1 + " " + shop?.address.city || '', '_blank')
    }

    function openWhatsappHandler(number: string) {
        window.open(`https://wa.me/+358${number}`);
    }

    useEffect(() => {
        if (shopContext?.shopId) {
            dispatch(fetchRatings(shopContext?.shopId))
        }

    }, [shopContext?.shopId])

    return <React.Fragment>
        {showShopReviews && <ModalWithHeader
            headerText={shop?.name}
            onClose={onCloseHandler}
        >
            <ReadReview
                shopId={shopContext?.shopId}
                showbutton={true}
                from='User'
            />
        </ModalWithHeader>}

        {showShopInformation && <ShopInformation onClose={() => setShowShopInformation(false)} />}
        <div className='row mb-3'>
            <div className='col-md-5 col-lg-4 col-xl-3'>
                <div className='mb-3'>
                    <div className={style.imageWrapper}>
                        <img src={shop?.theme.bannerImage || ''} alt='Shop' />
                    </div>
                </div>
            </div>
            <div className='col-md-7 col-lg-8 col-xl-9'>
                <div className='ps-2'>
                    <div className='vstack gap-1'>
                        <div className='hstack gap-3'>
                            <div className={style.shopName}>{shop?.name}</div>

                            <div className='hstack gap-3'>
                                {shop?.category !== 'Event' && <div className={style.infoIcon} onClick={() => setShowShopInformation(true)}>
                                    <Info />
                                </div>}
                                <Link to='#' className={style.infoIcon} onClick={favouriteClickHandler}>
                                    <IosHeart
                                        fill={favourite ? '#ec3b50' : 'none'}
                                        stroke={favourite ? '#ec3b50' : '#404040'}
                                    />
                                    {/* <div className={style.label}>{favourite ? 'Remove from' : 'Add to'} favourites</div> */}
                                </Link>
                            </div>
                        </div>

                        <div className={style.description}>{shop?.businessName}</div>

                        <div className='hstack gap-1'>
                            <LocationOn />
                            <span onClick={gmapHandler} className={style.city}>{shop?.address.addressLine1 || ''},{shop?.address.city || ''}, </span>
                            {shop?.phoneNumber && <div className='hstack gap-1' onClick={() => openWhatsappHandler(shop?.phoneNumber || '')}>
                                <Whatsapp />
                                <div className={style.phoneNumber}> {shop?.countryCode || +358} {shop?.phoneNumber}</div>
                            </div>}
                            {/* <div className={style.landmark}>{shop?.address.landmark || ''}</div> */}
                        </div>



                        <div className='hstack flex-wrap my-2'>
                            <div className='pe-4'>
                                <div className='hstack gap-2'>
                                    <div className='hstack gap-1'>
                                        <div className={style.star}>
                                            <StarDark />
                                        </div>
                                        <div className={style.rating}>{TotalAvgShopRating ? TotalAvgShopRating : 0}</div>
                                    </div>
                                    <Link to='#' className={style.readReviews} onClick={reviewsClickHandler}>{t("Read Reviews")}</Link>


                                </div>
                            </div>

                            {/* <div className={style.barrier} />

                            <div className='pe-4'>
                                <Link to='#' className={style.addToFavourites} onClick={favouriteClickHandler}>
                                    <div className='d-flex align-items-center gap-1'>
                                        <IosHeart
                                            fill={favourite ? '#ec3b50' : 'none'}
                                            stroke={favourite ? '#ec3b50' : '#404040'}
                                        />
                                        <div className={style.label}>{favourite ? 'Remove from' : 'Add to'} favourites</div>
                                    </div>
                                </Link>
                            </div> */}
                        </div>
                    </div>

                    {shopContext?.vouchers && shopContext?.vouchers.length > 0 && <React.Fragment>
                        <div className={style.offers}>{t("Offers")}:</div>
                        <div className='vstack gap-2'>
                            <span className='float-start' onClick={() => setShowVoucher(!showVoucher)}><OfferLabel>{t("Vouchers Available")}</OfferLabel></span>

                            {!showVoucher && <div className='border rounded p-2 hstack gap-3 flex-wrap'>
                                {shopContext?.vouchers?.map(voucher => {
                                    return <React.Fragment key={voucher.id}>
                                        {voucher.title && <OfferLabel>{voucher.title}</OfferLabel>}
                                    </React.Fragment>
                                })}
                            </div>}
                        </div>
                    </React.Fragment>}
                </div>
            </div>
        </div>
    </React.Fragment>
}

interface ShopInformationProps {
    onClose: () => void
}

const ShopInformation = (props: ShopInformationProps) => {
    const t = useT()
    const shopContext = React.useContext<ShopContextType | null>(ShopContext)
    const shop = useSelector<StoreState, Shop | null>(state => state.shops.find(s => s.id === shopContext?.shopId) || null)

    return <ModalWithHeader
        headerText={t('Shop Information')}
        onClose={props.onClose}
    >
        <div className={style.shopInformation}>
            <SectionWithTitle title={t('Shop Timing')}>
                <table className='table table-bordered'>
                    <thead>
                        <tr>
                            <th>{t("Day")}</th>
                            <td>{t("Opening Time")}</td>
                            <td>{t("Closing Time")}</td>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(shop?.shopTiming || {}).map(key => {
                            return <tr key={key}>
                                <th className='text-capitalize'>{key}</th>
                                <td>{to12Hour(shop?.shopTiming[key as keyof Timing]?.opening)}</td>
                                <td>{to12Hour(shop?.shopTiming[key as keyof Timing]?.closing)}</td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </SectionWithTitle>
            {shop?.delivery.enabled
                ? <React.Fragment>
                    <SectionWithTitle title={t("Delivery charge and distance details")}>
                        <p className={style.description}>
                            {t("From")} {shop?.delivery.distance.from} {t("to")} {shop?.delivery.distance.to} kms : {t("Fixed delivery charge")} : {toCurrencyFormat(shop?.delivery.charge.upToGivenDistance)}.
                            <br />
                            {t("Free delivery for orders of")} {toCurrencyFormat(shop?.delivery.orderValueForFreeDelivery.upToGivenDistance)}.
                            <br />
                            <br />
                            {("Above")} {shop?.delivery.distance.to} kms: {t("Delivery charge for every")} km: {toCurrencyFormat(shop?.delivery.charge.forEveryKmAboveGivenDistance)}/km.
                            <br />
                            {t("Free delivery for orders of")}{toCurrencyFormat(shop?.delivery.orderValueForFreeDelivery.aboveGivenDistance)}.
                        </p>
                        <div className={style.subHeader}>{t("Late night delivery")}:</div>
                        <p className={style.description}>
                            {t("From")} {to12Hour(shop?.delivery.lateNightDelivery.timing.from)} to {to12Hour(shop?.delivery.lateNightDelivery.timing.to)} {t("Delivery charge for every")} km: {toCurrencyFormat(shop?.delivery.lateNightDelivery.chargeForEveryKm)}.
                        </p>
                    </SectionWithTitle>
                    <SectionWithTitle title={t("Delivery timing")}>
                        <table className='table table-bordered'>
                            <thead>
                                <tr>
                                    <th>{t("Day")}</th>
                                    <td>{t("Start Time")}</td>
                                    <td>{t("End Time")}</td>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(shop?.delivery.timing || {}).map(key => {
                                    return <tr key={key}>
                                        <th className='text-capitalize'>{key}</th>
                                        <td>{to12Hour(shop?.delivery.timing[key as keyof Timing]?.opening)}</td>
                                        <td>{to12Hour(shop?.delivery.timing[key as keyof Timing]?.closing)}</td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </SectionWithTitle>
                </React.Fragment>
                : <div className={style.error}>* {t("Delivery not available")}</div>}
        </div>
    </ModalWithHeader >
}

export default ShopDetails