import { Action, VoucherActionTypes } from '../actions'

export type VoucherMode = 'percentage' | 'amount'

export interface Voucher {
    organization: string
    title: string
    description: string | null
    code: string
    value: number
    minOrderValue: number
    count: number
    maxCount: number
    mode: VoucherMode
    expiryDate: string
    email: string
    shopId: string
    createdAt: string
    updatedAt: string
    id: string
}

const initialState: Voucher[] = []

const voucherReducer = (state: Voucher[] = initialState, action: Action): Voucher[] => {
    switch (action.type) {
        case VoucherActionTypes.ADD_VOUCHER: {
            return [
                ...state,
                action.data
            ]
        }

        case VoucherActionTypes.UPDATE_VOUCHER: {
            const vouchers = [...state]
            const index = vouchers.findIndex(c => c.id === action.data.id)

            if (index > -1) {
                vouchers[index] = {
                    ...action.data
                }
            }

            return vouchers
        }

        case VoucherActionTypes.FETCH_SHOP_VOUCHERS: {
            return action.data
        }

        case  VoucherActionTypes.DELETE_VOUCHER: {
            return state.filter(c => c.id !== action.data)
        }

        default: {
            return state
        }
    }
}

export default voucherReducer