import React from 'react'
import { Link, LinkProps } from 'react-router-dom'
import style from '../../styles/components/button-link.module.scss'
import Button from './Button'

type ButtonLinkProps = {
    loading?: boolean
    disabled?: boolean
} & LinkProps & React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> & React.RefAttributes<HTMLAnchorElement>

const ButtonLink = ({ className, loading, disabled, ...props }: ButtonLinkProps) => {
    return loading || disabled
        ? <Button loading={loading} disabled={disabled} className={className}>{props.children}</Button>
        : <Link className={`${style.buttonLink} shadow-sm ${className || ''}`} {...props} />
}

export default ButtonLink