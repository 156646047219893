import { FeedbackActionTypes } from '../actions/feedback.actions'

export interface Feedback {
    // createdAt: "2023-07-13T06:25:18.093Z"
    // feedback: "kjhi"
    // id: "64af98ce34692e4b71318e62"
    // status: "active"
    // userId: "64a3c115cbf9e6fad950c306"
    // updatedAt: "2023-07-13T06:25:18.093Z"
    id?: string
    userId: string
    feedback: string
    status: string
    createdAt?: string
    updatedAt?: string
}

const initialState: Feedback[] = []

const feedbackReducer = (state: Feedback[] = initialState, action: any): Feedback[] => {
    switch (action.type) {
        case FeedbackActionTypes.ADD_FEEDBACK: {
            return [
                ...state,
                action.data
            ]
        }

        case FeedbackActionTypes.UPDATE_FEEDBACK: {
            const Feedbacks = [...state]
            const index = Feedbacks.findIndex(c => c.userId === action.data.userId)

            if (index > -1) {
                Feedbacks[index] = {
                    ...action.data
                }
            }

            return Feedbacks
        }

        case FeedbackActionTypes.FETCH_FEEDBACKS: {
            return action.data
        }

        case FeedbackActionTypes.DELETE_FEEDBACK: {
            return state.filter(c => c.userId !== action.data)
        }

        default: {
            return state
        }
    }
}

export default feedbackReducer