import { t } from 'i18next'
import { Link } from 'react-router-dom'
import assets from '../../assets'
import style from '../../styles/components/header.module.scss'
import AppMenu from './AppMenu'

interface DivisionHeaderProps {
    onMenuClick?: () => void
}

const DivisionHeader = (props: DivisionHeaderProps) => {
    return <header className={`container py-2 ${style.container}`}>
        <div className='d-flex h-100 align-items-center justify-content-between'>
            <div className='hstack gap-2'>
                <Link to='/division' className='d-block text-decoration-none'>
                    <div className='hstack gap-3'>
                        <div className={style.logo}>
                            <img src={assets.images.logo} alt='V-Shops logo' />
                        </div>
                        <div className={style.logoCaption}>{t("Virtual shopping")},<br />{t("Real value")}!</div>
                    </div>
                </Link>
            </div>
            <AppMenu role='division' />
        </div>
    </header>
}

export default DivisionHeader