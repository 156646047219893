import { nanoid } from '@reduxjs/toolkit'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useT } from '../../i18n'
import { updateProcessTimes } from '../../redux/actions'
import { StoreState } from '../../redux/reducers'
import { AuthAdmin } from '../../redux/reducers/authAdmin.reducer'
import { AppDispatch } from '../../redux/store'
import form from '../../styles/components/form.module.scss'
import style from '../../styles/components/process-time.module.scss'
import { isPositiveFloat, toNumber, toString } from '../../utils'
import { ToastType, useToast } from '../ToastProvider'
import Trash from '../svgs/Trash'
import Button from '../utils/Button'
import FormInput from '../utils/FormInput'

interface Time {
    id: string
    time: string
}

const ProcessTime = () => {
    const dispatch = useDispatch<AppDispatch>()
    const toast = useToast()
    const t = useT()

    const authAdmin = useSelector<StoreState, AuthAdmin | null>(state => state.authAdmin)

    const [processTimes, setProcessTimes] = React.useState<Time[]>([])
    const [newTime, setNewTime] = React.useState<string>('')
    const [newTimeError, setNewTimeError] = React.useState<string>('')

    const [loading, setLoading] = React.useState<boolean>(false)

    const newTimeChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setNewTime(e.target.value)
        const time = toString(e.target.value)
        if (time !== '') {
            if (!isPositiveFloat()(toNumber(time))) {
                setNewTimeError(t('Invalid time'))
            } else if (processTimes.some(t => t.time === time)) {
                setNewTimeError(t('Time already exists'))
            } else {
                setNewTimeError('')
            }
        } else {
            setNewTimeError('')
        }
    }

    const addClickHandler = () => {
        let error = false

        if (toString(newTime) === '') {
            setNewTimeError(t('New time required'))
            error = true
        } else if (!isPositiveFloat()(toNumber(newTime))) {
            setNewTimeError(t('Invalid time'))
            error = true
        } else if (processTimes.some(t => t.time === toString(newTime))) {
            setNewTimeError(t('Time already exists'))
            error = true
        }

        if (!error) {
            setProcessTimes(prev => {
                return [
                    ...prev,
                    {
                        id: nanoid(),
                        time: toString(newTime)
                    }
                ].sort((a, b) => toNumber(a.time) - toNumber(b.time))
            })
        }
    }

    const addNewProcessTimeSubmitHandler: React.FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault()
        addClickHandler()
    }

    const removeClickHandler = (id: string) => {
        setProcessTimes(prev => prev.filter(p => p.id !== id))
    }

    const saveClickHandler = () => {
        let error = false

        if (processTimes.length === 0) {
            setNewTimeError(t('Please add process time'))
            error = true
        } else {
            processTimes.forEach(v => {
                if (toNumber(v.time) <= 0) {
                    setNewTimeError(t('Please remove invalid time'))
                    error = true
                }
            })
        }

        if (!error) {
            setLoading(true)
            dispatch(updateProcessTimes(processTimes.map(t => toNumber(t.time)))).then(() => {
                toast(t('Process time updated'))
            }).catch(text => {
                toast(text, ToastType.ERROR)
            }).finally(() => {
                setLoading(false)
            })
        }
    }

    React.useEffect(() => {
        setProcessTimes(authAdmin?.shop.processTimes.map(pt => {
            return {
                id: nanoid(),
                time: pt.toString()
            }
        }) || [])
    }, [authAdmin])

    return <div className='col-12 mb-4'>
        <form onSubmit={addNewProcessTimeSubmitHandler}>
            <FormInput
                type='number'
                label={t('Add new process time')}
                placeholder={t('Add new process time')}
                value={newTime}
                onChange={newTimeChangeHandler}
                rightRenderer='Add'
                onRightRendererClick={addClickHandler}
                autoComplete='off'
                errorText={newTimeError}
                containerClass='mb-4'
            />
        </form>
        {processTimes.length > 0 && <div className='hstack flex-wrap gap-1 mb-2'>
            {processTimes.map(t => {
                return <ProcessTimeCell
                    key={t.id}
                    time={t.time}
                    onClick={() => removeClickHandler(t.id)}
                />
            })}
        </div>}
        <Button className={form.saveButton} onClick={saveClickHandler} loading={loading}>{t("Save")}</Button>
    </div>
}

interface ProcessTimeCellProps {
    time?: string
    onClick?: () => void
}

const ProcessTimeCell = (props: ProcessTimeCellProps) => {
    return <div className={style.cell} onClick={props.onClick}>
        <span>{props.time}</span>
        <div className={style.trash}>
            <Trash stroke='#ffffff' />
        </div>
    </div>
}

export default ProcessTime