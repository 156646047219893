/* eslint-disable no-throw-literal */
import { AxiosRequestConfig } from 'axios'
import React from 'react'
import { useSelector } from 'react-redux'
import { api, apiErrorHandler, getApiRequestConfig } from '../../api'
import { useT } from '../../i18n'
import { StoreState } from '../../redux/reducers'
import { AuthSuperAdmin } from '../../redux/reducers/authSuperAdmin.reducer'
import formStyle from '../../styles/components/form.module.scss'
import { testPassword } from '../../utils'
import { ToastType, useToast } from '../ToastProvider'
import Eye from '../svgs/Eye'
import EyeOff from '../svgs/EyeOff'
import Button from '../utils/Button'
import FormInput from '../utils/FormInput'

interface PasswordResetFormProps {
    endPoint: string
    apiConfig?: AxiosRequestConfig
    afterSave?: () => void,
    admin: { id: string, name: string, username: string }
}

const PasswordResetForm = (props: PasswordResetFormProps) => {
    const toast = useToast()
    const t = useT()

    const [newPassword, setNewPassword] = React.useState<string>('')
    const [confirmNewPassword, setConfirmNewPassword] = React.useState<string>('')
    const [newPasswordError, setNewPasswordError] = React.useState<string>('')
    const [confirmNewPasswordError, setConfirmNewPasswordError] = React.useState<string>('')

    const [showPassword, setShowPassword] = React.useState<boolean>(false)
    const [loading, setLoading] = React.useState<boolean>(false)

    const authAdmin = useSelector<StoreState, AuthSuperAdmin | null>(state => state.authSuperAdmin)
    const apiRequestConfig = getApiRequestConfig(authAdmin?.['auth-token'])

    const newPasswordChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setNewPassword(e.target.value)
        setNewPasswordError('')
        setConfirmNewPasswordError('')
    }

    const confirmNewPasswordChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setConfirmNewPassword(e.target.value)
        setNewPasswordError('')
        setConfirmNewPasswordError('')
    }

    const clearState = () => {
        setNewPassword('')
        setConfirmNewPassword('')
        setNewPasswordError('')
        setConfirmNewPasswordError('')

        setShowPassword(false)
        setLoading(false)
    }

    const saveClickHandler: React.MouseEventHandler<HTMLButtonElement> = () => {
        let error = false

        if (newPassword === '') {
            setNewPasswordError(t('New password required'))
            error = true
        } else if (newPassword.length < 8) {
            setNewPasswordError(t('Password is too short - should be 8 chars minimum'))
            error = true
        } else if (newPassword.length > 15) {
            setNewPasswordError(t('Password is too long - should be 15 chars maximum'))
            error = true
        } else if (!testPassword(newPassword)) {
            setNewPasswordError(t('Password can only contain alphanumeric characters, underscore and first character must be a letter'))
            error = true
        }

        if (confirmNewPassword === '') {
            setConfirmNewPasswordError(t('Confirm new password required'))
            error = true
        }

        if (newPassword !== '' && confirmNewPassword !== '' && newPassword !== confirmNewPassword) {
            setConfirmNewPasswordError(t('Both passwords are not equal'))
            error = true
        }

        if (!error) {
            const data = {
                id: props.admin.id,
                newPassword
            }
            setLoading(true)
            api.post(props.endPoint, data, apiRequestConfig).then(response => {

                if (response.status === 200) {
                    toast(t('Password changed'))
                    clearState()
                    props.afterSave?.()
                } else {
                    throw { response }
                }
            }).catch(error => {
                toast(apiErrorHandler(error, t('Unable to change password')), ToastType.ERROR)
            }).finally(() => {
                setLoading(false)
            })
        }
    }

    return <React.Fragment>

        <div className='col-12'>
            <FormInput
                type={showPassword ? 'text' : 'password'}
                label={t('New password')}
                placeholder={t('New password')}
                value={newPassword}
                onChange={newPasswordChangeHandler}
                errorText={newPasswordError}
                containerClass='mb-4'
                rightRenderer={showPassword ? <EyeOff /> : <Eye />}
                onRightRendererClick={() => setShowPassword(!showPassword)}
            />
        </div>
        <div className='col-12'>
            <FormInput
                type={showPassword ? 'text' : 'password'}
                label={t('Confirm new password')}
                placeholder={t('Confirm new password')}
                value={confirmNewPassword}
                onChange={confirmNewPasswordChangeHandler}
                errorText={confirmNewPasswordError}
                containerClass='mb-4'
                rightRenderer={showPassword ? <EyeOff /> : <Eye />}
                onRightRendererClick={() => setShowPassword(!showPassword)}
            />
        </div>
        <div className='mb-4'>
            <Button onClick={saveClickHandler} loading={loading} className={formStyle.saveButton}>{t("Save")}</Button>
        </div>
    </React.Fragment>
}

export default PasswordResetForm