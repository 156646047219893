import React from 'react'
import { useSelector } from 'react-redux'
import ShopCard, { ShopCardProps } from '../../components/cards/ShopCard'
import AdminPanelHeader from '../../components/sections/AdminPanelHeader'
import SuperAdminLayout from '../../components/sections/SuperAdminLayout'
import { SuperAdminContext } from '../../context/SuperAdminProvider'
import { useT } from '../../i18n'
import { StoreState } from '../../redux/reducers'
import { Shop as ShopType } from '../../redux/reducers/shop.reducer'

const Shops = () => {
    const t = useT()
    const superAdminContext = React.useContext(SuperAdminContext)
    const shops = useSelector<StoreState, ShopType[]>(state => state.shops)

    return <SuperAdminLayout>
        <div className='row mb-4'>
            <AdminPanelHeader
                btnText={t('Add Shop')}
                title={t('shops')}
                onBtnClick={superAdminContext?.onAddShop}
            />
            {/* {shops.map(shop => {
                return <Shop
                    key={shop.id}
                    shop={shop}
                    onMore={() => shop.id && superAdminContext?.onMoreClick?.(shop.id)}
                />
            })} */}
            <Shop
                shops={shops}
                from='super'
            />
        </div>
    </SuperAdminLayout>
}

export type ShopProps = ShopCardProps

export const Shop = (props: ShopProps) => {
    return <div className='mb-2'>
        <ShopCard {...props} />
    </div>
}

export default Shops