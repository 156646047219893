import { ContactUsActionTypes } from '../actions/contactUs.actions'

export interface ContactUs {
    name: string
    phoneNumber: string
    email: string
    message: string
    id: string
}

const initialState: ContactUs[] = []

const ContactUsReducer = (state: ContactUs[] = initialState, action:any): ContactUs[] => {
    switch (action.type) {
        case ContactUsActionTypes.ADD_CONTACTUS: {
            return [
                ...state,
                action.data
            ]
        }

        case ContactUsActionTypes.UPDATE_CONTACTUS: {
            const contactUs = [...state]
            const index = contactUs.findIndex(c => c.id === action.data.id)

            if (index > -1) {
                contactUs[index] = {
                    ...action.data
                }
            }

            return contactUs
        }

        case ContactUsActionTypes.FETCH_CONTACTUS: {
            return action.data
        }

        case ContactUsActionTypes.DELETE_CONTACTUS: {
            return state.filter(c => c.id !== action.data)
        }

        default: {
            return state
        }
    }
}

export default ContactUsReducer