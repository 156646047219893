import { t } from 'i18next'
import assets from '../../assets'
import style from '../../styles/components/added-to-cart.module.scss'
import Lottie from '../lottie/Lottie'
import Modal from './Modal'

interface AddedToCartProps {
    onClose?: () => void
    itemName?: string
}

const AddedToCart = (props: AddedToCartProps) => {
    return <Modal animate>
        <div className={style.container}>
            <div className={style.logoContainer}>
                <Lottie onComplete={props.onClose} data={assets.json.done} />
            </div>
            <div className={style.label}>{props.itemName || 'Item'} {t("added to your cart")}</div>
            <div className={style.buttonContainer}>
                <button className={style.button} onClick={props.onClose}>{t("Close")}</button>
            </div>
        </div>
    </Modal>
}

export default AddedToCart