import { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';
import { toCurrencyFormat } from '../../utils';

interface dataSet {
    name: string,
    data: {count:number,price:number}[],
}

interface Iprops {
    datasets: dataSet[]
    labels: string[]
    title: string
    colors: string[]
}
// simple bar chart
const ReportBarChart = (props: Iprops) => {
    // default options
    const { colors, datasets, labels, title } = props

    const series = datasets.map((ds)=>{
        let count = ds.data.map((d)=>d.count)
        let price = ds.data.map((d)=>d.price)
        return title==="count"?{name:ds.name,data:count}:{name:ds.name,data:price}
    })

    const tooltip ={
        style: {
            fontSize: '12px',
          },
          x: {
            format: 'dd.M.yyyy'
          },
          y: {
            formatter: function (val:number) {
              return title==="price"?toCurrencyFormat(val):val+""
            },
          },
    }
    const apexBarChartOpts: ApexOptions = {
        chart: {
            height: 380,
            type: 'bar',
            toolbar: {
                show: true,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 4,
                columnWidth: '30%',
                dataLabels: {
                    position: 'top',
                },
            },
        },
        dataLabels: {
            enabled: false,
            offsetX: -6,
            style: {
                fontSize: '12px',
                colors: ['#fff'],
            },
        },
        colors: colors,
        stroke: {
            show: true,
            width: 1,
            colors: ['#fff'],
            curve: 'smooth'
        },

        xaxis: {
            type: "datetime",
            categories: labels,
            axisBorder: {
                show: true,
            },
            axisTicks: {
                show: true,
            },
            tickAmount: 6,
            labels: {
                format: 'dd-M-y'
            },
            crosshairs: {
                position: 'front',
                stroke: {
                    width: 1,
                    dashArray: 3,
                },
            },
            tooltip: {
                enabled: false,
                formatter: undefined,
                offsetY: 0,
                style: {
                    fontSize: '12px',
                },
            },
        },
        yaxis: {
            labels: {
              formatter: function (value) {
                return title==="price"?toCurrencyFormat(value):value+""
              }
            },
          },
        legend: {
            offsetY: 10,
        },
        states: {
            hover: {
                filter: {
                    type: 'none',
                },
            },
        },
        tooltip: tooltip,
        grid: {
            borderColor: '#f1f3fa',
        },
    };

    return (
        <>
            <h4 className="header-title mb-3">{}</h4>
            <Chart options={apexBarChartOpts} series={series} type="bar" className="apex-charts" height={400} />
        </>
    );
};

export default ReportBarChart;
