interface ShoppingBagHand {
    stroke?: string
    strokeWidth?: number
}


const ShoppingBagHand = (props:ShoppingBagHand) => {
    return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="24" width="24"><g id="shopping-bag-hand-bag-2--shopping-bag-purse-goods-item-products"><path id="Vector" stroke={props.stroke?props.stroke:"#404040"} stroke-linecap="round" stroke-linejoin="round" d="M11.96 11.505c0.014671428571428573 0.13027857142857144 0.0014857142857142857 0.2621357142857143 -0.03853571428571429 0.38702857142857144 -0.04002142857142857 0.1248 -0.10595 0.23975714285714286 -0.19360714285714287 0.33725714285714287 -0.08784285714285715 0.09731428571428573 -0.19537142857142858 0.1750357142857143 -0.31534285714285715 0.2277785714285714 -0.12006428571428572 0.052835714285714284 -0.24997142857142857 0.07967142857142857 -0.3810857142857143 0.07865H1.9685807142857146c-0.13114214285714285 0.0010214285714285716 -0.26100285714285715 -0.025814285714285713 -0.38103928571428575 -0.07865 -0.12003642857142857 -0.05274285714285715 -0.22752785714285714 -0.13046428571428573 -0.3153892857142857 -0.2277785714285714 -0.08762928571428572 -0.0975 -0.15361357142857143 -0.21245714285714287 -0.1936257142857143 -0.33725714285714287 -0.040012142857142864 -0.12489285714285715 -0.05314214285714286 -0.25675000000000003 -0.03851714285714286 -0.38702857142857144l0.8171428571428572 -7.326428571428571H11.142857142857142l0.8171428571428572 7.326428571428571Z" stroke-width={props.strokeWidth?props.strokeWidth:'1'}></path><path id="Vector_2" stroke="#000" stroke-linecap="round" stroke-linejoin="round" d="M4.178571428571429 4.178571428571429V2.7857142857142856c0 -0.61568 0.24457642857142858 -1.2061492857142857 0.6799278571428572 -1.6415007142857143C5.293850714285714 0.7088639999999999 5.884320000000001 0.4642857142857143 6.5 0.4642857142857143c0.61568 0 1.2061492857142857 0.24457828571428575 1.6415007142857143 0.6799278571428572C8.576852142857144 1.579565 8.821428571428571 2.170034285714286 8.821428571428571 2.7857142857142856v1.3928571428571428" stroke-width={'1'}></path></g></svg>
}

export default ShoppingBagHand