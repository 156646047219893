import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './components/App'
import './i18n/index'
import reportWebVitals from './reportWebVitals'
import './styles/index.scss'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(process.env.NODE_ENV === 'development'
    ? <App />
    : <React.StrictMode>
        <App />
    </React.StrictMode>)

reportWebVitals()
