import assets from '../../assets'
import { useT } from '../../i18n'
import style from '../../styles/components/register-to-sell-banner.module.scss'
import ButtonLink from '../utils/ButtonLink'

const RegisterToSellBanner = () => {
    const t = useT()
    return <div className={style.container}>
        <div className='row'>
            <div className='col-lg-6 d-flex flex-column justify-content-between'>
                <div>
                    <div className={style.title}>{t("Register to sell at Vshop")}</div>
                    <p className={style.description}>{t("Always Deliver more than Excepted!")}</p>
                    <ButtonLink to='/admin' className={style.registerButton} target='_blank' rel='noopener noreferrer'>{t("Register")}</ButtonLink>
                </div>

            </div>
            <div className='col-lg-6 d-none d-lg-block'>
                <div className={style.imageWrapper}>
                    <img src={assets.images.registerToSellAtVshop} alt='Register to sell at vshop' />
                </div>
            </div>
        </div>
    </div>
}

export default RegisterToSellBanner