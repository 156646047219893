import { nanoid } from '@reduxjs/toolkit'
import { getFoodPrice } from '../../utils'
import { Action, CartActionTypes } from '../actions'
import { FoodType } from './food.reducer'

export type CartStatus = 'available' | 'unavailable'

export interface CartItemExtraTopping {
    price: number
    id: string
}

export interface CartItemName {
    name: string
    language: string
}

export interface CartItem {
    foodId: string
    names: CartItemName[]
    amount: number
    size: string | null
    image: string
    alternateToppings: string[]
    extraToppings: CartItemExtraTopping[]
    spices: string[]
    tax: number | null
    stock: number | null
    comment: string
    quantity: number
    type?: FoodType
    id: string
    status: CartStatus
    category: string
}

export interface Cart {
    shopId: string | null
    items: CartItem[]
}

const initialState: Cart = {
    items: [],
    shopId: null
}

const cartReducer = (state: Cart = initialState, action: Action): Cart => {
    const newState = {
        ...state,
        items: [
            ...state.items
        ]
    }

    switch (action.type) {
        case CartActionTypes.ADD_CART_ITEM: {
            if (newState.shopId !== action.data.shopId) {
                return {
                    shopId: action.data.shopId,
                    items: [
                        {
                            ...action.data.item,
                            id: nanoid(),
                            quantity: 1
                        }
                    ]
                }
            }
            const index = newState.items.findIndex(i => i.foodId === action.data.item.foodId && i.size === action.data.item.size)

            if (index > -1) {

                newState.items[index] = {
                    ...newState.items[index],
                    ...action.data.item, quantity: newState.items[index].quantity + 1
                }
                return newState;
            }

            return {
                ...newState,
                items: [
                    ...newState.items,
                    {
                        ...action.data.item,
                        id: nanoid(),
                        quantity: 1
                    }
                ],
                shopId: action.data.shopId
            }
        }

        case CartActionTypes.UPDATE_CART_ITEM: {
            const index = newState.items.findIndex(i => i.id === action.data.cartId)

            if (index > -1) {
                newState.items[index] = {
                    ...newState.items[index],
                    ...action.data.item
                }
            }

            return newState
        }

        case CartActionTypes.INCREASE_CART_ITEM_QUANTITY: {
            const index = newState.items.findIndex(i => i.id === action.data)

            if (index > -1) {
                const quantity = newState.items[index].quantity + 1

                const items = [
                    ...newState.items
                ]

                items[index] = {
                    ...newState.items[index],
                    quantity: quantity
                }

                return {
                    ...newState,
                    items: [
                        ...items
                    ]
                }
            }

            return newState
        }

        case CartActionTypes.DECREASE_CART_ITEM_QUANTITY: {
            const index = newState.items.findIndex(i => i.id === action.data)

            if (index > -1 && newState.items[index].quantity > 1) {
                const quantity = newState.items[index].quantity - 1

                const items = [
                    ...newState.items
                ]

                items[index] = {
                    ...newState.items[index],
                    quantity: quantity
                }

                return {
                    ...newState,
                    items: [
                        ...items
                    ]
                }
            }

            return newState
        }

        case CartActionTypes.SYNC_CART_WITH_DB: {
            newState.items = newState.items.map<CartItem>(item => {
                const food = action.data.find(d => d.id === item.foodId)

                if (food) {
                    const foodPriceIndex = food.prices.findIndex(p => p.size === item.size) ?? -1
                    const price = getFoodPrice(food, foodPriceIndex)
                    const extraToppings: CartItemExtraTopping[] = []
                    const spices: string[] = []
                    const alternateToppings: string[] = []

                    item.extraToppings.forEach(et => {
                        const extraTopping = food.extraToppings.find(s => s.id === et.id)
                        const extraToppingPrice = extraTopping?.prices.find(p => p.size === item.size)

                        if (extraTopping && extraToppingPrice) {
                            extraToppings.push({
                                id: extraTopping.id,
                                price: extraToppingPrice.price
                            })
                        }
                    })

                    item.spices.forEach(sid => {
                        const spice = food.spices.find(s => s.id === sid)

                        if (spice) {
                            spices.push(spice.id)
                        }
                    })

                    item.alternateToppings.forEach(atid => {
                        const alternateTopping = food.alternateToppings.find(s => s.id === atid)

                        if (alternateTopping) {
                            alternateToppings.push(alternateTopping.id)
                        }
                    })

                    const extraPrice = extraToppings.reduce((p, c) => p + c.price, 0)
                    const status: CartStatus = food.status === 'active' ? 'available' : 'unavailable'

                    const isAlternateToppingExist = food.alternateToppingsCount !== null && alternateToppings.length > 0 && alternateToppings.slice(0, food.alternateToppingsCount).length > 0
                    return {
                        ...item,
                        names: food.names.map(n => ({ language: n.language, name: n.name })),
                        amount: price + extraPrice,
                        image: food.image,
                        tax: food.tax,
                        status: status,
                        extraToppings: extraToppings,
                        spices: spices,
                        alternateToppings: food.alternateToppingsCount !== null && alternateToppings.length > 0 && isAlternateToppingExist
                            ? alternateToppings.slice(0, food.alternateToppingsCount)
                            : []
                    }
                }

                return {
                    ...item,
                    status: 'unavailable'
                }
            })

            return newState
        }

        case CartActionTypes.REMOVE_CART_ITEM: {
            const items = newState.items.filter(i => i.id !== action.data)

            return {
                ...newState,
                items: items
            }
        }

        case CartActionTypes.REMOVE_ALL_CART_ITEMS: {
            return {
                items: [],
                shopId: null
            }
        }

        default: {
            return state
        }
    }
}

export default cartReducer