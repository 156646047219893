import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { VOUCHER_MODES } from '../../config'
import { useT } from '../../i18n'
import { addVoucher } from '../../redux/actions'
import { fetchShopVouchers, updateVoucher } from '../../redux/actions/voucher.actions'
import { StoreState } from '../../redux/reducers'
import { Voucher, VoucherMode } from '../../redux/reducers/voucher.reducer'
import { AppDispatch } from '../../redux/store'
import style from '../../styles/components/voucher-form.module.scss'
import { date, isPositiveFloat, isPositiveInteger, toNumber, toString } from '../../utils'
import { ToastType, useToast } from '../ToastProvider'
import ModalWithHeader from '../modals/ModalWithHeader'
import FormInput from '../utils/FormInput'
import FormTextArea from '../utils/FormTextArea'

interface VoucherFormProps {
    onClose?: () => void
    id?: string | null
}

const VoucherForm = (props: VoucherFormProps) => {
    const dispatch = useDispatch<AppDispatch>()
    const toast = useToast()
    const t = useT()

    const voucher = useSelector<StoreState, Voucher | null>(state => props.id ? state.vouchers.find(s => s.id === props.id) || null : null)

    const [title, setTitle] = React.useState<string>('')
    const [titleError, setTitleError] = React.useState<string>('')
    const [description, setDescription] = React.useState<string>('')
    const [descriptionError, setDescriptionError] = React.useState<string>('')
    const [code, setCode] = React.useState<string>('')
    const [codeError, setCodeError] = React.useState<string>('')
    const [mode, setMode] = React.useState<string>('percentage')
    const [modeError, setModeError] = React.useState<string>('')
    const [value, setValue] = React.useState<string>('')
    const [valueError, setValueError] = React.useState<string>('')
    const [minOrderValue, setMinOrderValue] = React.useState<string>('')
    const [minOrderValueError, setMinOrderValueError] = React.useState<string>('')
    const [expiryDate, setExpiryDate] = React.useState<string>('')
    const [expiryDateError, setExpiryDateError] = React.useState<string>('')
    const [maxCount, setMaxCount] = React.useState<string>('')
    const [maxCountError, setMaxCountError] = React.useState<string>('')

    const [loading, setLoading] = React.useState<boolean>(false)

    const resetStates = () => {
        setTitle('')
        setTitleError('')
        setDescription('')
        setDescriptionError('')
        setCode('')
        setCodeError('')
        setMode('')
        setModeError('')
        setValue('')
        setValueError('')
        setMinOrderValue('')
        setMinOrderValueError('')
        setExpiryDate('')
        setExpiryDateError('')
        setMaxCount('')
        setMaxCountError('')
    }

    const titleChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setTitle(e.target.value)
        setTitleError('')
    }

    const descriptionChangeHandler: React.ChangeEventHandler<HTMLTextAreaElement> = (e) => {
        setDescription(e.target.value)
        setDescriptionError('')
    }

    const codeChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setCode(e.target.value.toUpperCase())
        setCodeError('')
    }

    const modeChangeHandler: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
        setMode(e.target.value)
        setModeError('')
    }

    const valueChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setValue(e.target.value)
        setValueError('')
    }

    const minOrderValueChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setMinOrderValue(e.target.value)
        setMinOrderValueError('')
    }

    const maxCountChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setMaxCount(e.target.value)
        setMaxCountError('')
    }

    const expiryDateChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setExpiryDate(e.target.value)
        setExpiryDateError('')
    }

    const handleClear = () => {
        resetStates()
    }

    const submitHandler: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault()

        const expDate = new Date(expiryDate).getTime()
        const currentDate = new Date().getTime()

        let error = false

        if (toString(title) === '') {
            setTitleError(t('Title required'))
            error = true
        }

        if (toString(code) === '') {
            setCodeError(t('Code required'))
            error = true
        }

        if (toString(mode) === '') {
            setModeError(t('Mode required'))
            error = true
        } else if (VOUCHER_MODES.indexOf(toString(mode) as VoucherMode) === -1) {
            setModeError(t('Invalid mode'))
            error = true
        }

        if (toString(value) === '') {
            setValueError(t('Value required'))
            error = true
        } else if (!isPositiveFloat()(toNumber(value))) {
            setValueError(t('Invalid value'))
            error = true
        }

        if (toString(minOrderValue) === '') {
            setMinOrderValueError(t('Min order value required'))
            error = true
        } else if (!isPositiveFloat()(toNumber(minOrderValue))) {
            setMinOrderValueError(t('Invalid min order value'))
            error = true
        }

        if (toString(expiryDate) === '') {
            setExpiryDateError(t('Expiry date required'))
            error = true
        }
        else if ((expDate < currentDate)) {
            setExpiryDateError(t('Previous dates are not allowed'))
            error = true
        }

        if (toString(maxCount) === '') {
            setMaxCountError(t('Max count required'))
            error = true
        } else if (!isPositiveInteger()(toNumber(maxCount))) {
            setMaxCountError(t('Invalid max count'))
            error = true
        }

        if (!error) {
            if (props.id !== "" && voucher) {

                setLoading(true)
                dispatch(updateVoucher({
                    code: toString(code).toUpperCase(),
                    description: toString(description) || null,
                    expiryDate: toString(expiryDate),
                    maxCount: toNumber(maxCount),
                    minOrderValue: toNumber(minOrderValue),
                    mode: toString(mode) as VoucherMode,
                    title: toString(title),
                    value: toNumber(value)
                }, voucher.id)).then(() => {
                    toast(t('Voucher updated'))
                    dispatch(fetchShopVouchers())
                    props.onClose?.()
                }).catch(text => {
                    toast(text, ToastType.ERROR)
                }).finally(() => {
                    setLoading(false)
                })
            } else {
                setLoading(true)
                dispatch(addVoucher({

                    code: toString(code).toUpperCase(),
                    description: toString(description) || null,
                    expiryDate: toString(expiryDate),
                    maxCount: toNumber(maxCount),
                    minOrderValue: toNumber(minOrderValue),
                    mode: toString(mode) as VoucherMode,
                    title: toString(title),
                    value: toNumber(value)
                })).then(() => {
                    toast(t('Voucher added'))
                    props.onClose?.()
                }).catch(text => {
                    toast(text, ToastType.ERROR)
                }).finally(() => {
                    setLoading(false)
                })
            }
        }
    }

    useEffect(() => {
        if (voucher && props.id !== '') {
            setTitle(voucher.title)
            setDescription(voucher.description!)
            setCode(voucher.code)
            setMode(voucher.mode)
            setValue(voucher.value.toString())
            setMinOrderValue(voucher.minOrderValue.toString())
            setExpiryDate(voucher.expiryDate)
            setMaxCount(voucher.maxCount.toString())
        }
    }, [voucher])

    return <ModalWithHeader
        buttonText={t('Save')}
        headerText={props.id ? t('Edit Voucher') : t('Add Voucher')}
        loading={loading}
        onClose={props.onClose}
        onSave={submitHandler}
    >
        <div className='row'>
            <div className='col-12'>
                <FormInput
                    type='text'
                    label={t('Title')}
                    placeholder={t('Title')}
                    errorText={titleError}
                    value={title}
                    onChange={titleChangeHandler}
                    containerClass='mb-4'
                />
            </div>
            <div className='col-12'>
                <FormTextArea
                    label={t('Description')}
                    placeholder={t('Description')}
                    errorText={descriptionError}
                    value={description}
                    onChange={descriptionChangeHandler}
                    containerClass='mb-4'
                />
            </div>
            <div className='col-sm-6 col-md-4'>
                <FormInput
                    type='text'
                    label={t('Code')}
                    placeholder={t('Code')}
                    errorText={codeError}
                    value={code}
                    onChange={codeChangeHandler}
                    containerClass='mb-4'
                    rightRendererClassName={style.generate}
                />
            </div>
            <div className='col-sm-6 col-md-4'>
                <FormInput
                    type='number'
                    label={t('Value')}
                    placeholder={t('Value')}
                    errorText={valueError || modeError}
                    value={value}
                    onChange={valueChangeHandler}
                    containerClass='mb-4'
                    rightRenderer={<select value={mode} onChange={modeChangeHandler}>
                        <option value='percentage'>%</option>
                        <option value='amount'>&euro;</option>
                    </select>}
                    rightRendererClassName={style.modeContainer}
                />
            </div>
            <div className='col-sm-6 col-md-4'>
                <FormInput
                    type='number'
                    label={t('Minimum order value')}
                    placeholder={t('Minimum order value')}
                    errorText={minOrderValueError}
                    value={minOrderValue}
                    onChange={minOrderValueChangeHandler}
                    containerClass='mb-4'
                />
            </div>
            <div className='col-sm-6 col-md-4'>
                <FormInput
                    type='number'
                    label={t('Max count')}
                    placeholder={t('Max count')}
                    errorText={maxCountError}
                    value={maxCount}
                    onChange={maxCountChangeHandler}
                    containerClass='mb-4'
                />
            </div>
            <div className='col-sm-6 col-md-4'>
                <FormInput
                    type='date'
                    label={t('Expiry date')}
                    placeholder={t('Expiry date')}
                    errorText={expiryDateError}
                    value={date('Y-Mn-D', expiryDate)}
                    onChange={expiryDateChangeHandler}
                    containerClass='mb-4'
                    min={date('Y-Mn-D')}
                />
            </div>
            {/* {props.id && <Button className="btn btn-secondary" onClick={handleClear}>Clear</Button>} */}
        </div>
    </ModalWithHeader>
}

export default VoucherForm