import React from 'react'
import style from '../../styles/components/accordion.module.scss'
import ChevronDownDark from '../svgs/ChevronDownDark'

interface Data {
    id: string
}

interface AccordionProps<I extends Data> {
    items?: I[]
    renderItemGetter?: (item: I) => React.ReactNode
    showItem?: (item: I) => boolean
    titleGetter?: (item: I) => string
}

const Accordion = <D extends Data>(props: AccordionProps<D>) => {
    const [collapsed, setCollapsed] = React.useState<string | null>(null)

    const toggle = (id: string) => {
        setCollapsed(prev => prev !== id ? id : null)
    }

    // Get the id of first item and set it to the collapsed state to show the first item when open the shop page
    React.useEffect(() => {
        const id = (props.items?.at(0)?.id !== "favourites" ? props.items?.at(0)?.id : props.items?.at(1)?.id)
        setCollapsed(id || null)
        // eslint-disable-next-line react-hooks/exhaustive-deps        
    }, [])

    return <div className={style.accordion}>
        {props.items?.map(item => {
            const children = props.renderItemGetter?.(item)
            const title = props.titleGetter?.(item)
            const show = props.showItem?.(item)

            return <React.Fragment key={item.id}>
                {show && <AccordionItem
                    title={title}
                    onClick={() => toggle(item.id)}
                    collapse={collapsed === item.id}
                >{children}</AccordionItem>}
            </React.Fragment>
        })}
    </div>
}

interface AccordionItemProps {
    title?: string
    onClick?: () => void
    children?: React.ReactNode
    collapse?: boolean
}

const AccordionItem = (props: AccordionItemProps) => {
    const contentRef = React.useRef<HTMLDivElement>(null)

    return <div className={`${style.item} ${props.collapse ? style.collapse : ''}`}>
        <div className={style.header} onClick={props.onClick}>
            <div className={style.title}>{props.title}</div>
            <div className={style.chevronWrapper}>
                <div className={style.chevron}>
                    <ChevronDownDark />
                </div>
            </div>
        </div>
        <div className={style.content} ref={contentRef} style={{ maxHeight: props.collapse ? contentRef.current?.scrollHeight || 0 : 0 }}>{props.children}</div>
    </div>
}

export default Accordion