/* eslint-disable no-throw-literal */
import { Dispatch } from 'redux'
import { api, apiErrorHandler, getApiRequestConfig, Response } from '../../api'
import { GetState } from '../reducers'
import { AuthUser, ReferralCode, UserAddress } from '../reducers/authUser.reducer'

export enum AuthUserActionTypes {
    LOGIN_USER = 'LOGIN_USER',
    LOGOUT_USER = 'LOGOUT_USER',
    UPDATE_USER_CONTACT_DETAILS = 'UPDATE_USER_CONTACT_DETAILS',
}

export interface LoginUserAction {
    type: AuthUserActionTypes.LOGIN_USER
    data: AuthUser
}

export interface LogoutUserAction {
    type: AuthUserActionTypes.LOGOUT_USER
}

export interface UpdateUserContactDetailsAction {
    type: AuthUserActionTypes.UPDATE_USER_CONTACT_DETAILS
    data: UpdateUserContactDetailsResponse
}

export type AuthUserActions = LoginUserAction | LogoutUserAction | UpdateUserContactDetailsAction

export interface RegisterAuthUser {
    firstName: string
    lastName: string
    email: string
    password: string
    countryCode?:string | null
    phoneNumber: string
    addressLine1: string
    addressLine2: string
    city: string
    pincode: string
    state: string
    referralCode?:string
    privacyPolicy:string
    emailConcent?:string
    landmark: string | null
}

export const registerUser = (input: RegisterAuthUser) => async () => {
    return api.post<Response<AuthUser>>('user', input).then(response => {
        if (response.status === 200) {
            return Promise.resolve<string>(response.data.message || 'Registered')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Failed to register'))
    })
}

export interface LoginAuthUser {
    email: string
    password: string
}

export const loginUser = (input: LoginAuthUser) => async (dispatch: Dispatch) => {
    return api.post<Response<AuthUser>>('user/login', input).then(response => {
        if (response.status === 200) {
            dispatch<LoginUserAction>({
                type: AuthUserActionTypes.LOGIN_USER,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || 'Logged in')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to login'))
    })
}

export const logoutUser = (): LogoutUserAction => {
    return {
        type: AuthUserActionTypes.LOGOUT_USER
    }
}

interface UpdateUserContactDetails {
    firstName: string
    lastName: string
    Email: string
    addressLine1: string
    addressLine2: string
    city: string
    pincode: string
    state: string
    landmark: string | null
    referral:ReferralCode
    countryCode?:string | null
    phoneNumber: string | null
}

interface UpdateUserContactDetailsResponse {
    firstName: string | null
    lastName: string | null
    Email: string
    countryCode:string
    phoneNumber: string | null
    referral?:ReferralCode | null
    address: UserAddress
}

export const updateUserContactDetails = (input: UpdateUserContactDetails) => async (dispatch: Dispatch, getState: GetState) => {
    const authUser = getState().authUser
    const config = getApiRequestConfig(authUser?.['auth-token'])

    return api.put<Response<UpdateUserContactDetailsResponse>>('user/', input, config).then(response => {
        if (response.status === 200) {
            dispatch<UpdateUserContactDetailsAction>({
                type: AuthUserActionTypes.UPDATE_USER_CONTACT_DETAILS,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || 'Updated')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to update'))
    })
}


export const generateReferralLink = () => async (dispatch: Dispatch, getState: GetState) => {
    const authUser = getState().authUser
    const config = getApiRequestConfig(authUser?.['auth-token'])

    return api.put<Response<UpdateUserContactDetailsResponse>>('user/generate-referral-link','', config).then(response => {
        if (response.status === 200) {
            dispatch<UpdateUserContactDetailsAction>({
                type: AuthUserActionTypes.UPDATE_USER_CONTACT_DETAILS,
                data: response.data.data
            })
            return Promise.resolve<string>(response.data.message || 'Generated Referral Link')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to generate referral link'))
    })
}