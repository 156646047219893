import React from "react";
import CommonLayout from '../components/sections/CommonLayout'
import style from '../styles/pages/blog.module.scss'
import { useParams } from "react-router-dom";
import { date } from "../utils";
// import { UserContext, UserContextType } from "../context/UserProvider";
// import { stripHTML } from "../components/cards/BlogCard";
import { useSelector } from "react-redux";
import { StoreState } from "../redux/reducers";
import { Blog } from "../redux/reducers/blog.reducer";

export type BlogURLParams = {
    blogId: string
}

const BlogPost = () => {
    const { blogId } = useParams<BlogURLParams>()

    const blog = useSelector<StoreState, Blog[]>(state => state.blog).find(s => s.id === blogId)

    return <CommonLayout>
        <div className="container">
            {/* <div className="row"> */}
            {/* <div className="col-lg-12 col-sm-12 mb-5"> */}
            <div className="row">
                <div className='col-lg-6 col-sm-12 col-xs-12 col-md-6'>
                    <img src={blog?.imageUrl} className={style.blogImage} alt={'blog name'} />
                </div>
                <div className='col-lg-6 col-sm-12 col-xs-12 col-md-6'>
                    <div className="vstack gap-4">
                        <h4 className="text-info fw-bold text-decoration-underline">{blog?.title}</h4>
                        <h3>
                            {blog?.description}
                        </h3>

                        <div>By <span className={style.blogAuthor + ' mt-2'}>{blog?.author}</span> | <span className={style.blogDate + ' mt-2'}>{blog?.createdAt !== undefined ? date('MON d,Y', blog?.createdAt) : ''}</span>
                        </div>

                    </div>
                </div>
            </div>
            <hr />
            <div className="mt-4">
                <div dangerouslySetInnerHTML={{ __html: blog?.content! }} className={style.content}>
                </div>
            </div>
            {/* </div> */}
            {/* </div> */}
        </div>
    </CommonLayout>
}

export default BlogPost;