import React from 'react'
import inputStyle from '../../styles/components/input.module.scss'
import style from '../../styles/components/select.module.scss'
import ErrorText from './ErrorText'

export interface OptionProps<T = string, V = string> {
    text?: T
    value?: V
}

interface SelectProps {
    value?: string
    name?: string
    onChange?: React.ChangeEventHandler<HTMLSelectElement>
    options?: OptionProps[]
    containerClass?: string
    label?: string
    errorText?: string
    placeholder?: string
    required?: boolean
    disabled?:boolean
}

const Select = (props: SelectProps) => {
    const options: OptionProps[] = props.options
        ? [
            {
                text: props.placeholder,
                value: ''
            },
            ...props.options
        ] : [
            {
                text: props.placeholder,
                value: ''
            }
        ]

    return <div className={`${inputStyle.container} ${props.containerClass || ''}`}>
        <label className={inputStyle.label} htmlFor={props.label}>{props.label} {props.required && <span style={{ color: "red" }}>*</span>}</label>
        <select value={props.value} name={props.name} onChange={props.onChange} className={`${style.select} ${typeof props.errorText === 'string' && props.errorText !== '' ? style.error : 'form-select'}`} id={props.label} disabled={props.disabled?props.disabled:false}>
            {options.map((o, i) => {
                return <Option
                    key={o.value}
                    text={o.text}
                    value={o.value}
                    disabled={i === 0}
                />
            })}
        </select>
        <ErrorText errorText={props.errorText} />
    </div>
}

const Option = (props: OptionProps & { disabled?: boolean }) => {
    return <option value={props.value} disabled={props.disabled}>{props.text}</option>
}

export default Select