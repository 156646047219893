import React, { useEffect, useState } from 'react'
import { Carousel, Col, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { UserContext, UserContextType } from '../../context/UserProvider'
import { useT } from '../../i18n'
import { eventAddToCart } from '../../redux/actions/eventCart.actions'
import { StoreState } from '../../redux/reducers'
import { CartItem } from '../../redux/reducers/cart.reducer'
import { Shop } from '../../redux/reducers/shop.reducer'
import { AppDispatch } from '../../redux/store'
import style from '../../styles/components/add-to-cart.module.scss'
import formStyle from '../../styles/components/form.module.scss'
import { dayNames, getEventOfferPrice, getEventPrice, monthNames, to12Hour, toCurrencyFormat, toString } from '../../utils'
import { useToast } from '../ToastProvider'
import Calender from '../svgs/Calender'
import Clock from '../svgs/Clock'
import LocationOn from '../svgs/LocationOn'
import Button from '../utils/Button'
import Close from '../utils/Close'
import Modal from './Modal'

interface AddToCartEventProps {
    onClose?: () => void
    cartId: string | null
    event: any
}

const EventAddToCart = (props: AddToCartEventProps) => {
    const userProvider = React.useContext<UserContextType | null>(UserContext)
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>()
    const toast = useToast()
    const t = useT()
    const { shopId } = useParams()
    const cartItem = useSelector<StoreState, CartItem | null>(state => state.cart.items.find(c => c.id === props.cartId) || null)
    const shop = useSelector<StoreState, Shop | null>(state => state.shops.find(s => s.id === shopId) || null)

    const [actualImage, setActualImage] = useState<string>(props.event.image);
    const [showFullDescription, setShowFullDescription] = useState<boolean>(false);
    const descriptionLength = (props.event.descriptions[0].description ? props.event.descriptions[0].description.length : 0)
    const [eventDate, setEventDate] = useState<string>("")
    const [width, setWidth] = useState(window.innerWidth);
    const [comment, setComment] = React.useState<string>('')
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    function gmapHandler() {
        window.open("https://www.google.com/maps/place/" + props.event.location, '_blank')
    }

    useEffect(() => {
        const fromDate = props.event?.fromDate;
        const from = new Date(fromDate ? fromDate : '');
        const date = dayNames[from.getDay()] + ', ' + from.getDate() + 'th ' + monthNames[from.getMonth()] + ', ' + from.getFullYear();
        setEventDate(date)
    }, [props])

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const eventAddToCartClickHandler = () => {
        dispatch(eventAddToCart({
            eventId: props.event.id,
            eventType: props.event.type,
            names: props.event.names.map((n: { language: string, name: string }) => ({ language: toString(n.language), name: toString(n.name) })),
            prices: props.event.prices,
            foodPreferences: props.event.foodPreferences,
            volunteers: props.event.volunteers,
            eventRule: props.event.eventRule,
            cultureVouchers: props.event.cultureVouchers,
            category: props.event.categories[0].id,
            withoutRegistration: props.event.withoutRegistration
        }, props.event.shopId))
        navigate("/checkout-event");
        props.onClose?.()
    }

    return <><Modal animate>

        <div className={style.container}>
            <div className={style.container}>

                <div className='px-4 pt-4'>
                    <div className='d-flex align-items-center justify-content-between mb-3'>

                        <div className={style.headerText}>{t("Book Event")}</div>
                        <Close onClose={props.onClose} />

                    </div>

                    <div className='row mb-3 '>
                        <div className='col-lg-6 col-sm-12 col-xs-12 col-md-6'>
                            {/* <div className=''> */}
                            {/* <div className={style.imageContainer}>
                                <img src={props.food.image} alt={props.food.names[0].name} />
                            </div> */}

                            {/* </div> */}
                            <Row>
                                {width > 500 &&
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        {props.event.additionalImages.length !== 0 ?

                                            <Row>
                                                <Col lg={3} md={3} sm={3} xs={3}>
                                                    <p>
                                                        <img
                                                            src={props.event.image}
                                                            style={{ cursor: "pointer" }}
                                                            onMouseOver={() => setActualImage(props.event.image)}
                                                            alt=""
                                                            width="70px" height="70px" className="img-thumbnail"
                                                        /></p>
                                                    {props.event.additionalImages.map((additionalimage: string, index: number) => (


                                                        <p><img
                                                            src={additionalimage !== undefined ? additionalimage : ""}
                                                            onMouseOver={() => setActualImage(additionalimage)}
                                                            style={{ cursor: "pointer" }}
                                                            alt="" width="70px" height="70px" className="img-thumbnail" /></p>

                                                    ))}
                                                </Col>
                                                <Col lg={9} md={9} sm={9} xs={9}>


                                                    <img src={actualImage ? actualImage : ''} alt="icon" className={style.bigImage} />

                                                </Col>
                                            </Row>
                                            :
                                            <img src={props.event.image ? props.event.image : ''} alt="icon" className={style.bigImage} />
                                        }

                                    </Col>
                                }
                                {width <= 500 &&
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        {props.event.additionalImages.length !== 0 ?
                                            <Carousel fade={true} slide={false}>

                                                <Carousel.Item key={0} interval={3000} >

                                                    <img
                                                        className="d-block w-100"
                                                        src={(props.event.image && props.event.image !== null) ? props.event.image : ''}
                                                        alt={`${0} slide`}
                                                    />

                                                    <Carousel.Caption />

                                                </Carousel.Item>

                                                {props.event.additionalImages.map((additionalImage: string, index: number) => (
                                                    <Carousel.Item key={index + 1} interval={3000} >

                                                        <img
                                                            className="d-block w-100"
                                                            src={(additionalImage && additionalImage !== null) ? additionalImage : ''}
                                                            alt={`${index + 1} slide`}
                                                        />

                                                        <Carousel.Caption />

                                                    </Carousel.Item>
                                                ))}

                                            </Carousel>
                                            :
                                            <img src={props.event.image ? props.event.image : ''} alt="icon" className={style.bigImage} />
                                        }

                                    </Col>
                                }
                            </Row>
                        </div>
                        <div className='col-lg-6 col-sm-12 col-xs-12 col-md-6'>
                            <div className={`text-capitalize ${style.productName}`}>{props.event.names[0].name}</div>
                            <div className='mt-1'>
                                <Calender /> {eventDate}
                            </div>
                            <div className='mt-1'>
                                <Clock /> {to12Hour(props.event.fromTime)} to {to12Hour(props.event.toTime)}
                            </div>
                            <div className='mt-1'>
                                <LocationOn /> <span className={style.location} onClick={gmapHandler}>{props.event.location}</span>
                            </div>
                        </div>

                        <p className={`my-1 ${style.description} ${!showFullDescription ? style.less : ''}`} dangerouslySetInnerHTML={{ __html: props.event.descriptions[0].description || '' }} >
                        </p>

                        {descriptionLength > 150 && <span className='text-primary float-end cursor-pointer' style={{
                            fontSize: '14px',
                            fontWeight: '400',
                        }} role='button' onClick={() => setShowFullDescription(!showFullDescription)}>{showFullDescription ? 'Less' : 'More'}</span>}
                    </div>
                </div>
                <div className={`${style.editOrderContent} px-4`}>
                    {props.event.prices.length > 0 && <div className='mb-4'>
                        <div className={formStyle.sectionHeader}>{t("Event Fees")}:</div>
                        {props.event.prices?.map((p: any) => {
                            const priceIndex = props.event.prices.findIndex((fp: any) => fp.size === p.size)
                            const price = getEventPrice(props.event, priceIndex)
                            const offerPrice = getEventOfferPrice(props.event, priceIndex)
                            return (
                                <div className='hstack gap-2 justify-content-between'>
                                    <span className='text-capitalize'>{p.size}</span>
                                    <div className='hstack gap-2'>
                                        {offerPrice > 0
                                            ? <React.Fragment>
                                                <span>
                                                    {'('}
                                                    <span className={style.offerPrice}>{toCurrencyFormat(price, shop?.currency)}</span>
                                                </span>
                                                <span>
                                                    {toCurrencyFormat(offerPrice, shop?.currency)}{')'}
                                                </span>
                                            </React.Fragment>
                                            : <span>{'('}{toCurrencyFormat(price, shop?.currency)}{')'}</span>}
                                    </div>
                                </div>)
                        })}
                    </div>}
                    {/* <div className={formStyle.sectionHeader}>{t("Comments")}:</div>
                    <TextArea
                        onChange={e => setComment(e.target.value)}
                        value={comment}
                    /> */}
                    {props.event.eventStatus === "inactive" && <p className={`${style.stock} mb-3`}>Reservation has been closed.</p>}
                </div>
            </div>
            <div className='px-4 pb-4'>
                <div className='mb-3' />
                <Button className={formStyle.saveButton} onClick={eventAddToCartClickHandler} disabled={props.event.eventStatus === "active" ? false : true}>{t("Book Now")}</Button>
                <div className='mb-3' />

            </div>
        </div>
    </Modal ></>
}

export default EventAddToCart