import style from '../../styles/components/slider-placeholder.module.scss'

const SlideCardPlaceholder = () => {
    return <div className={style.container}>
        <div className={style.image}></div>
        <div className={style.bottom}>
            <div className='placeholder-glow'>
                <div className='vstack gap-2'>
                    <div className='placeholder placeholder-xs' style={{ width: '80%' }} />
                    <div className='placeholder placeholder-xs' style={{ width: '50%' }} />
                    <div className='placeholder placeholder-xs' style={{ width: '100%' }} />
                    <div className='placeholder placeholder-xs' style={{ width: '90%' }} />
                    <div className='placeholder placeholder-xs' style={{ width: '40%' }} />
                </div>
            </div>
        </div>
    </div>
}

export default SlideCardPlaceholder