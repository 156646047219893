/* eslint-disable no-throw-literal */
import { Dispatch } from 'redux'
import { api, apiErrorHandler, getApiRequestConfig, Response } from '../../api'
import { GetState } from '../reducers'
import { AppointmentBooking } from '../reducers/appointmentBookings.reducer'
// import {OrderReport} from '../reducers/orderReport.reducer'

export enum AppointmentBookingsActionTypes {
    FETCH_APPOINTMENT_BOOKINGS = 'FETCH_APPOINTMENT_BOOKINGS',
    REPLACE_APPOINTMENT_BOOKINGS = 'REPLACE_APPOINTMENT_BOOKINGS',
    FETCH_APPOINTMENT_BOOKINGS_REPORTS = 'FETCH_APPOINTMENT_BOOKINGS_REPORTS'
}


export interface FetchAppointmentBookingAction {
    type: AppointmentBookingsActionTypes.FETCH_APPOINTMENT_BOOKINGS
    data: AppointmentBooking[]
}

// export interface FetchAppointmentBookingReportAction {
//     type: AppointmentBookingsActionTypes.REPLACE_APPOINTMENT_BOOKINGS
//     data: OrderReport[]
// }

export interface ReplaceAppointmentBookingAction {
    type: AppointmentBookingsActionTypes.REPLACE_APPOINTMENT_BOOKINGS
    data: AppointmentBooking
}

export type AppointmentBookingsActions = FetchAppointmentBookingAction | ReplaceAppointmentBookingAction


export const fetchShopAppointmentBookings = () => async (dispatch: Dispatch, getState: GetState) => {
    const token = getState().authAdmin?.['auth-token']
    const config = getApiRequestConfig(token)

    return api.get<Response<AppointmentBooking[]>>('shop/appointment/bookings', config).then(response => {
        if (response.status === 200) {
            dispatch<FetchAppointmentBookingAction>({
                type: AppointmentBookingsActionTypes.FETCH_APPOINTMENT_BOOKINGS,
                data: response.data.data
            })

            return Promise.resolve<string>(response.data.message || '')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to fetch'))
    })
}

export const updateShopAppointmentPayment = (status: string, shopId: string | undefined, reservationId: string) => async (dispatch: Dispatch, getState: GetState) => {
    const token = getState().authAdmin?.['auth-token']
    const config = getApiRequestConfig(token)

    return api.put<Response<AppointmentBooking>>(`shop/appointment/update-payment/${reservationId}`, { paymentStatus: status, shopId }, config).then(response => {
        if (response.status === 200) {
            dispatch<ReplaceAppointmentBookingAction>({
                type: AppointmentBookingsActionTypes.REPLACE_APPOINTMENT_BOOKINGS,
                data: response.data.data
            })

            return Promise.resolve<string>(response.data.message || 'Reservation is updated')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to update'))
    })
}

export const updateShopAppointmentStatus = (status: string, bookingId: string) => async (dispatch: Dispatch, getState: GetState) => {
    const token = getState().authAdmin?.['auth-token']
    const config = getApiRequestConfig(token)

    return api.put<Response<AppointmentBooking>>(`shop/appointment/update-status/${bookingId}`, { appointmentStatus: status }, config).then(response => {
        if (response.status === 200) {
            dispatch<ReplaceAppointmentBookingAction>({
                type: AppointmentBookingsActionTypes.REPLACE_APPOINTMENT_BOOKINGS,
                data: response.data.data
            })

            return Promise.resolve<string>(response.data.message || 'Booking is updated')
        } else {
            throw { response }
        }
    }).catch((error) => {
        return Promise.reject<string>(apiErrorHandler(error, 'Unable to update'))
    })
}