interface PieChartProps {
    stroke?: string
    strokeWidth?: number
}

const PieChart = (props: PieChartProps) => {
    return <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke={props.stroke || '#ffffff'} strokeWidth={props.strokeWidth || 2} strokeLinecap='round' strokeLinejoin='round' className='feather feather-pie-chart'>
        <path d='M21.21 15.89A10 10 0 1 1 8 2.83'></path>
        <path d='M22 12A10 10 0 0 0 12 2v10z'></path>
    </svg>
}

export default PieChart