import AdminPanelHeader from '../../components/sections/AdminPanelHeader'
import DivisionLayout from '../../components/sections/DivisionLayout'
import { useT } from '../../i18n'

const Home = () => {
    const t = useT()
    return <DivisionLayout>
        <AdminPanelHeader title={t('Welcome Division Admin!')} />
    </DivisionLayout>
}

export default Home