import React from 'react'
import { useSelector } from 'react-redux'
import InitialLoader from '../components/modals/InitialLoader'
import OverlayLoader from '../components/modals/OverlayLoader'
import i18n, { TranslationLanguage } from '../i18n'
import { StoreState } from '../redux/reducers'

export interface AppContextType {
    showOverlay?: () => void
    hideOverlay?: () => void
}

export const AppContext = React.createContext<AppContextType | null>(null)

interface AppProviderProps {
    children?: React.ReactNode
}

const AppProvider = (props: AppProviderProps) => {
    const translationLanguage = useSelector<StoreState, TranslationLanguage>(state => state.translationLanguage)

    const [showOverlayLoader, setShowOverlayLoader] = React.useState<boolean>(false)

    const showOverlay = () => {
        setShowOverlayLoader(true)
    }

    const hideOverlay = () => {
        setShowOverlayLoader(false)
    }

    React.useEffect(() => {
        i18n.changeLanguage(translationLanguage)
    }, [translationLanguage])

    return <AppContext.Provider value={{ hideOverlay, showOverlay }}>
        <InitialLoader />
        {showOverlayLoader && <OverlayLoader />}
        {props.children}
    </AppContext.Provider>
}

export default AppProvider