import { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';
import { toCurrencyFormat } from '../../utils';

interface dataSet {
    name: string,
    data: number[],
}

interface Iprops {
    datasets: dataSet[]
    labels: string[]
    title: string
    colors: string[]
}
// simple line chart
const ReportLineChart = (props: Iprops) => {
    // default options
    const { colors, datasets, labels, title } = props
    const tooltip ={
        style: {
            fontSize: '12px',
          },
          x: {
            format: 'dd.M.yyyy'
          },
          y: {
            formatter: function (val:number) {
              return title==="price"?toCurrencyFormat(val):val+""
            },
          },
    }
    const apexLineChartWithLables: ApexOptions = {
        chart: {
            height: 380,
            type: 'area',
            zoom: {
                enabled: false,
            },
            toolbar: {
                show: true,
            },
        },
        colors: colors,
        dataLabels: {
            enabled: false,
        },
        stroke: {
            width: [3, 3],
            curve: 'smooth',
        },
        title: {
            text: '',
            align: 'left',
            style: {
                fontSize: '14px',
            },
        },
        grid: {
            row: {
                colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.2,
            },
            borderColor: '#f1f3fa',
        },
        markers: {
            size: 0,
        },
        xaxis: {
            type: "datetime",
            categories: labels,
            axisBorder: {
                show: true,
            },
            axisTicks: {
                show: true,
            },
            tickAmount: 6,
            labels: {
                format: 'dd-M-y'
            },
            crosshairs: {
                position: 'front',
                stroke: {
                    width: 1,
                    dashArray: 3,
                },
            },
            tooltip: {
                enabled: false,
                formatter: undefined,
                offsetY: 0,
                style: {
                    fontSize: '12px',
                },
            },
        },
        yaxis: {
            labels: {
              formatter: function (value) {
                return title==="price"?toCurrencyFormat(value):value+""
              }
            },
        },
        tooltip: tooltip,
        legend: {
            offsetY: 0,
        },
        responsive: [
            {
                options: {
                    chart: {
                        toolbar: {
                            show: false,
                        },
                    },
                    legend: {
                        show: false,
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                },
            },
        ],
    };

    // chart data
    // const apexLineChartWithLablesData = [
    //     {
    //         name: 'High - 2018',
    //         data: [403540.35, 584287.1, 294573.87, 176220.78, 487164.73, 314826.53, 1995.23],
    //     },
    //     {
    //         name: 'Low - 2018',
    //         data: [584287, 176220, 1995.23, 487164.73, 584287, 314826.53, 1995.23],
    //     },
    // ];
    return (
        <>
            <h4 className="header-title mb-3">{}</h4>
            <Chart
                options={apexLineChartWithLables}
                series={datasets}
                type="area"
                className="apex-charts"
                height={400}
            />
        </>
    );
};

export default ReportLineChart;
