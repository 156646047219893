import Lottie from 'lottie-web'

interface AnimateConfig {
    loop?: boolean
    data: string
    container: Element
}

export const animate = (config: AnimateConfig, onComplete?: () => void) => {
    const animation = Lottie.loadAnimation({
        container: config.container,
        renderer: 'svg',
        loop: config.loop ?? false,
        autoplay: true,
        animationData: config.data
    })

    animation.addEventListener('complete', () => {
        onComplete?.()
    })

    return animation
}