import React, { ChangeEvent } from "react";
import { AlertCircle } from "react-feather";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import assets from "../../assets";
import { useT } from "../../i18n";
import { registerUser } from "../../redux/actions";
import { AppDispatch } from "../../redux/store";
import style from '../../styles/components/pre-regsiter.module.scss';
import { countryCode, testNumber, toString } from '../../utils';
import { ToastType, useToast } from "../ToastProvider";
import Eye from "../svgs/Eye";
import EyeOff from "../svgs/EyeOff";
import Button from "../utils/Button";
import Checkbox from "../utils/Checkbox";
import Close from "../utils/Close";
import Modal from "./Modal";

interface UserDetail {
    firstName: string
    lastName: string
    countryCode: string
    phoneNumber: string
    address1: string
    address2: string
    city: string
    state: string
    pincode: string
    landmark: string
    email: string
    password: string
    referralCode: string
    privacyPolicy: boolean
    emailConcent: boolean
}

interface Props {
    setRegister?: () => void
}
const PreRegisterPage = (props: Props) => {
    const dispatch = useDispatch<AppDispatch>()
    const toast = useToast()
    const t = useT()
    const [userDetail, setUserDetail] = React.useState<UserDetail>({
        firstName: '',
        lastName: '',
        countryCode: '+358',
        phoneNumber: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        pincode: '',
        landmark: '',
        email: '',
        password: '',
        referralCode: window.location.href.split('=')[1] ? window.location.href.split('=')[1] : '',
        privacyPolicy: false,
        emailConcent: false
    })

    const [userDetailErrors, setUserDetailErrors] = React.useState({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        city: '',
        email: '',
        password: '',
        privacyPolicy: ''
    })
    const [showPassword, setShowPassword] = React.useState<boolean>(false)
    const [steps, setSteps] = React.useState({ one: true, two: false, three: false });
    const [leftView, setLeftView] = React.useState(true);
    const [itemCount, setItemCount] = React.useState<number>(0)
    const [errorMessage, setErrorMessage] = React.useState<string>('')
    const [country, setCountry] = React.useState<boolean>(false)

    const [loading, setLoading] = React.useState<boolean>(false)

    const onInputChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setErrorMessage('');
        setUserDetail({ ...userDetail, [name]: value })
        setUserDetailErrors({ ...userDetailErrors, [name]: '' });
    }


    const privacyPolicyChangeHandler = (check: boolean) => {
        setErrorMessage('');
        setUserDetail({ ...userDetail, privacyPolicy: check ? false : true })
        if (check === true)
            setUserDetailErrors({ ...userDetailErrors, privacyPolicy: '' });
    }

    const emailConcentChangeHandler = (check: boolean) => {
        setUserDetail({ ...userDetail, emailConcent: check ? false : true })
    }

    const closeHandler = () => {
        props.setRegister?.();
        localStorage.setItem('registerModal', 'close');
    }

    const buttonChangeHandler = (value: string) => {


        if (value === "one") {
            setSteps({ ...steps, one: true, two: false, three: false });
        }
        if (value === "two") {
            if (toString(userDetail.firstName) === '') {
                setUserDetailErrors({ ...userDetailErrors, firstName: 'First Name is Required' });
                setErrorMessage('First Name is Required')
                return
            }
            if (toString(userDetail.lastName) === '') {
                setUserDetailErrors({ ...userDetailErrors, lastName: 'Last Name is Required' });
                setErrorMessage('Last Name is Required')
                return
            }
            if (toString(userDetail.phoneNumber) === '') {
                setUserDetailErrors({ ...userDetailErrors, phoneNumber: 'Phone Number is Required' });
                setErrorMessage('Phone Number is Required')
                return
            } else if ((userDetail.phoneNumber).length > 15) {
                setUserDetailErrors({ ...userDetailErrors, phoneNumber: 'Phone Number should be within 15 digits' });
                setErrorMessage('Phone Number should be within 15 digits')
                return
            } else if (!testNumber(userDetail.phoneNumber)) {
                setUserDetailErrors({ ...userDetailErrors, phoneNumber: 'Phone Number must be a number' });
                setErrorMessage('Phone Number must be a number')
                return
            }
            if (toString(userDetail.email) === '') {
                setUserDetailErrors({ ...userDetailErrors, email: 'Email is Required' });
                setErrorMessage('Email is Required')
                return
            }
            if (toString(userDetail.password) === '') {
                setUserDetailErrors({ ...userDetailErrors, password: 'Password is Required' });
                setErrorMessage('Password is Required')
                return
            }
            else if (userDetail.password.length < 8) {
                setUserDetailErrors({ ...userDetailErrors, password: 'Password should be 8 character minimum' });
                setErrorMessage('Password should be 8 character minimum')
                return
            }

            setSteps({ ...steps, one: false, two: true, three: false });
        }
        if (value === "three") {
            if (toString(userDetail.city) === '') {
                setUserDetailErrors({ ...userDetailErrors, city: 'City is Required' });
                setErrorMessage('City is Required')
                return
            }
            setSteps({ ...steps, one: false, two: false, three: true });
        }
    }

    const skipHandler = () => {
        setLeftView(false);
    }

    const countryCodeClickHandler = () => {
        country ? setCountry(false) : setCountry(true);
    }

    const countryCodeChangeHandler = (value: string) => {
        setCountry(false);
        setUserDetail({ ...userDetail, countryCode: value })
    }

    const registerHandler = () => {
        if (userDetail.privacyPolicy === false) {
            setUserDetailErrors({ ...userDetailErrors, privacyPolicy: 'Privacy policy is required' });
            setErrorMessage('Privacy policy is required')
            return
        }
        setLoading(true)
        dispatch(registerUser({
            email: toString(userDetail.email).toLowerCase(),
            firstName: toString(userDetail.firstName),
            lastName: toString(userDetail.lastName),
            password: userDetail.password,
            phoneNumber: toString(userDetail.phoneNumber),
            addressLine1: toString(userDetail.address1),
            addressLine2: toString(userDetail.address2),
            landmark: toString(userDetail.landmark) || null,
            pincode: toString(userDetail.pincode),
            state: toString(userDetail.state),
            privacyPolicy: toString("Agreed"),
            emailConcent: userDetail.emailConcent ? toString("Yes") : toString("No"),
            referralCode: toString(userDetail.referralCode),
            city: toString(userDetail.city)
        })).then(text => {
            props.setRegister?.();
            localStorage.setItem('registerModal', 'close');
            toast(text)
        }).catch(text => {
            toast(text, ToastType.ERROR)
        }).finally(() => {
            setLoading(false)
        })
    }

    React.useEffect(() => {
        setTimeout(() => {
            setItemCount(itemCount < 2 ? itemCount + 1 : 0)
        }, 5000);
    }, [itemCount])

    return (
        <Modal animate>
            <div className={style.container}>
                <div className={`${style.cardContainer}`}>
                    <div className={`${style.closeButton} ${!leftView ? style.leftView : ''}`}><Close onClose={closeHandler} /></div>
                    <div className={`row`}>
                        <div className={`col-lg-7 ${style.leftSection} ${leftView ? style.active : ''}`}>
                            <div className={style.topContainer}>
                                <div className={`${style.topSection} ${itemCount === 0 ? style.active : ''}`}>
                                    <div className={`${style.imageSection} ${itemCount === 0 ? style.animation : ''}`}>
                                        <div className={style.image1Container1}>
                                            <img className={style.image1} src={assets.images.onBoardImage1} />
                                            <div className={style.ellipse}></div>

                                        </div>
                                        <div className={style.image1Container2}>
                                            <img className={style.image2} src={assets.images.onBoardImage2} />
                                        </div>
                                    </div>
                                    <div className={`${style.contentSection} ${itemCount === 0 ? style.animation : ''}`}>
                                        <h1 className={style.headerText}>Discover shops near you</h1>
                                        <p className={style.text}>Explore products from hundreds of businesses around you in Finland to shop easily online</p>
                                    </div>
                                </div>
                                <div className={`${style.topSection} ${itemCount === 1 ? style.active : ''}`}>
                                    <div className={`${style.imageSection} ${itemCount === 1 ? style.animation : ''}`}>
                                        <div className={style.image1Container}>
                                            <img className={style.image1} src={assets.images.shoppingBag} />
                                            <div className={style.ellipse1}></div>
                                        </div>

                                    </div>
                                    <div className={`${style.contentSection} ${itemCount === 1 ? style.animation : ''}`}>
                                        <h1 className={style.headerText}>Personalized shopping</h1>
                                        <p className={style.text}>Connect directly with individual shop owners through our virtual marketplace</p>
                                    </div>
                                </div>
                                <div className={`${style.topSection} ${itemCount === 2 ? style.active : ''}`}>
                                    <div className={`${style.imageSection} ${itemCount === 2 ? style.animation : ''}`}>
                                        <div className={style.image1Container}>
                                            <img className={style.image1} src={assets.images.priceTag} />
                                            <div className={style.ellipse2}></div>
                                        </div>
                                    </div>
                                    <div className={`${style.contentSection} ${itemCount === 2 ? style.animation : ''}`}>
                                        <h1 className={style.headerText}>Best deals guranteed!</h1>
                                        <p className={style.text}>Our modern marketplace offers you App only deals and fairly priced products and services</p>
                                    </div>
                                </div>
                            </div>
                            <div className={`hstack ${style.bottomContainer}`}>
                                <div className={style.skipButton} onClick={skipHandler}>Skip</div>
                                <div className={`hstack gap-1 ${style.pointerContainer}`}>
                                    <div className={`${style.pointer} ${style.active}`}></div>
                                    <div className={`${style.pointer} ${itemCount === 1 || itemCount === 2 ? style.active : ''}`}></div>
                                    <div className={`${style.pointer} ${itemCount === 2 ? style.active : ''}`}></div>
                                </div>
                            </div>
                        </div>
                        {steps.one &&
                            <div className={`col-lg-5 ${style.rightSection} ${!leftView ? style.rightSectionActive : ''}`}>
                                {/* <div className={style.logoContainer}>
                                    <img className={style.image} alt="logo" src={assets.images.logo} />
                                </div> */}
                                <div className='d-flex justify-content-between'>
                                    <div className='hstack gap-2'>
                                        <div className={style.logoWrapper}>
                                            <img src={assets.images.logo} alt='V-Shops logo' />
                                        </div>
                                        <div className={`d-none d-sm-block ${style.logoCaption}`}>Virtual shopping,<br />Real value!</div>
                                    </div>
                                    <div className="mt-4 d-md-none" onClick={closeHandler}>Skip</div>
                                </div>
                                <div className={style.topContainerRight}>

                                    <div className="row py-2">
                                        <div className={`col-12 ${style.formContainer}`}>
                                            <div className={`${style.alertIcon} ${userDetailErrors.firstName ? style.error : ''}`}><AlertCircle stroke="#ec3b50" strokeWidth={1} /></div>
                                            <input
                                                name="firstName"
                                                className={`${style.input} ${userDetailErrors.firstName ? style.error : ''}`}
                                                placeholder="First name"
                                                value={userDetail?.firstName}
                                                onChange={(e) => onInputChangeHandler(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="row py-2">
                                        <div className={`col-12 ${style.formContainer}`}>
                                            <div className={`${style.alertIcon} ${userDetailErrors.lastName ? style.error : ''}`}><AlertCircle stroke="#ec3b50" strokeWidth={1} /></div>
                                            <input
                                                name="lastName"
                                                className={`${style.input} ${userDetailErrors.lastName ? style.error : ''}`}
                                                placeholder="Last name"
                                                value={userDetail?.lastName}
                                                onChange={(e) => onInputChangeHandler(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="row py-2">
                                        <div className={`col-12 ${style.formContainer}`}>
                                            <div className={`${style.alertIcon} ${userDetailErrors.phoneNumber ? style.error : ''}`}><AlertCircle stroke="#ec3b50" strokeWidth={1} /></div>
                                            <div className={`hstack`} style={{ position: 'relative' }}>
                                                <div className={`${style.leftRenderer} ${userDetailErrors.phoneNumber ? style.error : ''}`} onClick={countryCodeClickHandler}>{userDetail?.countryCode}</div>
                                                <input
                                                    name="phoneNumber"
                                                    className={`${style.input} ${style.withLeftRenderer} ${userDetailErrors.phoneNumber ? style.error : ''}`}
                                                    placeholder="Phone number"
                                                    value={userDetail?.phoneNumber}
                                                    onChange={(e) => onInputChangeHandler(e)}
                                                />
                                                <div className={`${style.codeContainer} ${country ? style.active : ''}`}>
                                                    {countryCode.map((ctry) => (
                                                        <div className={style.code} onClick={() => countryCodeChangeHandler(ctry.value)}>{ctry.title}</div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row py-2">
                                        <div className={`col-12 ${style.formContainer}`}>
                                            <div className={`${style.alertIcon} ${userDetailErrors.email ? style.error : ''}`}><AlertCircle stroke="#ec3b50" strokeWidth={1} /></div>
                                            <input
                                                name="email"
                                                className={`${style.input} ${userDetailErrors.email ? style.error : ''}`}
                                                placeholder="Email"
                                                value={userDetail?.email}
                                                onChange={(e) => onInputChangeHandler(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="row py-2">
                                        <div className={`col-12 ${style.formContainer}`}>
                                            <div className={`${style.alertIcon} ${userDetailErrors.password ? style.error : ''}`}><AlertCircle stroke="#ec3b50" strokeWidth={1} /></div>
                                            <div className="hstack">
                                                <input
                                                    type={showPassword ? 'text' : 'password'}
                                                    name="password"
                                                    className={`${style.input} ${style.withRightRenderer} ${userDetailErrors.password ? style.error : ''}`}
                                                    placeholder="Password"
                                                    value={userDetail?.password}
                                                    onChange={(e) => onInputChangeHandler(e)}
                                                />
                                                <div className={`${style.rightRenderer} ${userDetailErrors.password ? style.error : ''}`} onClick={() => setShowPassword(!showPassword)}>{showPassword ? <EyeOff /> : <Eye />}</div>
                                            </div>
                                        </div>
                                    </div>

                                    {errorMessage && <div className={style.errorMessage}>{errorMessage}</div>}
                                </div>
                                <div className={style.buttonContainerRight}>
                                    <Button className={style.button} onClick={() => buttonChangeHandler('two')}>Next</Button>
                                </div>

                            </div>
                        }
                        {steps.two &&
                            <div className={`col-lg-5 order-2 ${style.rightSection} ${!leftView ? style.rightSectionActive : ''}`}>
                                {/* <div className={style.logoContainer}>
                                    <img className={style.image} alt="logo" src={assets.images.logo} />
                                </div> */}
                                <div className='hstack gap-2'>
                                    <div className={style.logoWrapper}>
                                        <img src={assets.images.logo} alt='V-Shops logo' />
                                    </div>
                                    <div className={`d-none d-sm-block ${style.logoCaption}`}>Virtual shopping,<br />Real value!</div>
                                </div>
                                <div className={style.topContainerRight2}>
                                    <div className="row py-2">
                                        <div className={`col-12 ${style.formContainer}`}>
                                            <input
                                                name="address1"
                                                className={style.input}
                                                placeholder="Address 1"
                                                value={userDetail?.address1}
                                                onChange={(e) => onInputChangeHandler(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="row py-2">
                                        <div className={`col-12 ${style.formContainer}`}>
                                            <input
                                                name="address2"
                                                className={style.input}
                                                placeholder="Address 2"
                                                value={userDetail?.address2}
                                                onChange={(e) => onInputChangeHandler(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="row py-2">
                                        <div className={`col-12 ${style.formContainer}`}>
                                            <div className={`${style.alertIcon} ${userDetailErrors.city ? style.error : ''}`}><AlertCircle stroke="#ec3b50" strokeWidth={1} /></div>
                                            <input
                                                name="city"
                                                className={`${style.input} ${userDetailErrors.city ? style.error : ''}`}
                                                placeholder="City"
                                                value={userDetail?.city}
                                                onChange={(e) => onInputChangeHandler(e)}
                                            />
                                        </div>
                                    </div>
                                    {errorMessage && <div className={style.errorMessage}>{errorMessage}</div>}

                                </div>
                                <div className={style.buttonContainerRight}>
                                    <Button className={style.prevbutton} onClick={() => buttonChangeHandler('one')}>Previous</Button>
                                    <Button className={style.button} onClick={() => buttonChangeHandler('three')}>Next</Button>
                                </div>
                            </div>
                        }
                        {steps.three &&
                            <React.Fragment>
                                <div className={`col-lg-5 order-2 ${style.rightSection} ${!leftView ? style.rightSectionActive : ''}`}>
                                    {/* <div className={style.logoContainer}>
                                        <img className={style.image} alt="logo" src={assets.images.logo} />
                                    </div> */}
                                    <div className='hstack gap-2'>
                                        <div className={style.logoWrapper}>
                                            <img src={assets.images.logo} alt='V-Shops logo' />
                                        </div>
                                        <div className={`d-none d-sm-block ${style.logoCaption}`}>Virtual shopping,<br />Real value!</div>
                                    </div>
                                    <div className={style.topContainerRight2}>
                                        <div className="row pb-2">
                                            <div className={`col-12 ${style.formContainer}`}>
                                                <input
                                                    name="state"
                                                    className={style.input}
                                                    placeholder="State"
                                                    value={userDetail?.state}
                                                    onChange={(e) => onInputChangeHandler(e)}
                                                />
                                            </div>
                                        </div>
                                        <div className="row py-2">
                                            <div className={`col-12 ${style.formContainer}`}>
                                                <input
                                                    name="pincode"
                                                    className={style.input}
                                                    placeholder="Pincode"
                                                    value={userDetail?.pincode}
                                                    onChange={(e) => onInputChangeHandler(e)}
                                                />
                                            </div>
                                        </div>
                                        <div className="row py-2">
                                            <div className={`col-12 ${style.formContainer}`}>
                                                <input
                                                    name="landmark"
                                                    className={style.input}
                                                    placeholder="Landmark"
                                                    value={userDetail?.landmark}
                                                    onChange={(e) => onInputChangeHandler(e)}
                                                />
                                            </div>
                                        </div>
                                        <Checkbox
                                            className='mb-1'
                                            onClick={() => privacyPolicyChangeHandler(userDetail.privacyPolicy)}
                                            checked={userDetail.privacyPolicy}
                                            required={userDetailErrors.privacyPolicy ? true : false}
                                        >I have read, understand and agree to vshops <Link to='/terms-and-conditions' target='_blank' >{t("Terms and conditions")}</Link> and <Link to='/privacy-policy' target='_blank'>{t("Privacy policy")}</Link> <span className={style.required}>*</span>
                                        </Checkbox>
                                        <Checkbox
                                            className='mb-1'
                                            onClick={() => emailConcentChangeHandler(userDetail.emailConcent)}
                                            checked={userDetail.emailConcent}
                                        >Opt in for email updates on exclusive offers and news from vshops.fi. You can unsubscribe anytime.
                                        </Checkbox>
                                        {errorMessage && <div className={style.errorMessage}>{errorMessage}</div>}

                                    </div>

                                    {/* <div className={style.buttonContainer}>
                                        <Button className={style.prevbutton} onClick={() => buttonChangeHandler('two')}>Previous</Button>
                                        <Button className={style.button} onClick={registerHandler}>Register</Button>
                                    </div> */}

                                    <div className={style.buttonContainerRight}>
                                        <Button className={style.prevbutton} onClick={() => buttonChangeHandler('two')}>Previous</Button>
                                        <Button className={style.button} onClick={registerHandler}>Register</Button>
                                    </div>
                                </div>
                            </React.Fragment>
                        }
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default PreRegisterPage;